import {
  GET_PROFITLOSS_PROFILE,
  GET_PROFITLOSS_PROFILE_FAIL,
  GET_PROFITLOSS_PROFILE_SUCCESS,
  GET_PROFITLOSSS,
  GET_PROFITLOSSS_FAIL,
  GET_PROFITLOSSS_SUCCESS,
  ADD_NEW_PROFITLOSS,
  ADD_PROFITLOSS_SUCCESS,
  ADD_PROFITLOSS_FAIL,
  UPDATE_PROFITLOSS,
  UPDATE_PROFITLOSS_SUCCESS,
  UPDATE_PROFITLOSS_FAIL,
  DELETE_PROFITLOSS,
  DELETE_PROFITLOSS_SUCCESS,
  DELETE_PROFITLOSS_FAIL,
} from "./actionTypes"

export const getProfitLossProjects = project_id => ({
  type: GET_PROFITLOSSS,
  payload: project_id,
})

export const getProfitLossProjectsSuccess = profitlossprojects => ({
  type: GET_PROFITLOSSS_SUCCESS,
  payload: profitlossprojects,
})

export const addNewProfitLossProject = profitlossproject => ({
  type: ADD_NEW_PROFITLOSS,
  payload: profitlossproject,
})

export const addProfitLossProjectSuccess = profitlossproject => ({
  type: ADD_PROFITLOSS_SUCCESS,
  payload: profitlossproject,
})

export const addProfitLossProjectFail = error => ({
  type: ADD_PROFITLOSS_FAIL,
  payload: error,
})

export const getProfitLossProjectsFail = error => ({
  type: GET_PROFITLOSSS_FAIL,
  payload: error,
})

export const getProfitLossProjectProfile = (id, type) => ({
  type: GET_PROFITLOSS_PROFILE,
  payload: { id, type },
})

export const getProfitLossProjectProfileSuccess = profitlossprojectProfile => ({
  type: GET_PROFITLOSS_PROFILE_SUCCESS,
  payload: profitlossprojectProfile,
})

export const getProfitLossProjectProfileFail = error => ({
  type: GET_PROFITLOSS_PROFILE_FAIL,
  payload: error,
})

export const updateProfitLossProject = profitlossproject => ({
  type: UPDATE_PROFITLOSS,
  payload: profitlossproject,
})

// export const updateProfitLossProject = profitlossproject => console.log(profitlossproject);

export const updateProfitLossProjectSuccess = profitlossproject => ({
  type: UPDATE_PROFITLOSS_SUCCESS,
  payload: profitlossproject,
})

export const updateProfitLossProjectFail = error => ({
  type: UPDATE_PROFITLOSS_FAIL,
  payload: error,
})

export const deleteProfitLossProject = profitlossproject => ({
  type: DELETE_PROFITLOSS,
  payload: profitlossproject,
})

export const deleteProfitLossProjectSuccess = profitlossproject => ({
  type: DELETE_PROFITLOSS_SUCCESS,
  payload: profitlossproject,
})

export const deleteProfitLossProjectFail = error => ({
  type: DELETE_PROFITLOSS_FAIL,
  payload: error,
})
