import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_CUSTOM_PROJECTS,
  GET_CUSTOM_PROJECT_PROFILE,
  ADD_NEW_CUSTOM_PROJECT,
  DELETE_CUSTOM_PROJECT,
  UPDATE_CUSTOM_PROJECT,
} from "./actionTypes"

import {
  getCustomProjectsSuccess,
  getCustomProjectsFail,
  getCustomProjectProfileSuccess,
  getCustomProjectProfileFail,
  addCustomProjectFail,
  addCustomProjectSuccess,
  updateCustomProjectSuccess,
  updateCustomProjectFail,
  deleteCustomProjectSuccess,
  deleteCustomProjectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCustomProjects,
  getCustomProjectProfile,
  addNewCustomProject,
  updateCustomProject,
  deleteCustomProject,
} from "../../helpers/fakebackend_helper"

function* fetchCustomProjects({ payload: id }) {
  try {
    const response = yield call(getCustomProjects, id)
    // console.log(response);
    yield put(getCustomProjectsSuccess(response))
  } catch (error) {
    yield put(getCustomProjectsFail(error))
  }
}

function* fetchCustomProjectProfile({ payload: id }) {
  // console.log(id)updateCustomProject
  try {
    const response = yield call(getCustomProjectProfile, id)
    // console.log("Profile => ", response)
    yield put(getCustomProjectProfileSuccess(response))
  } catch (error) {
    yield put(getCustomProjectProfileFail(error))
  }
}

function* onUpdateCustomProject({ payload: customproject }) {
  try {
    // let x = customproject.get('formData')
    // console.log(x)
    const response = yield call(updateCustomProject, customproject)
    yield put(updateCustomProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateCustomProjectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteCustomProject({ payload: customproject }) {
  try {
    const response = yield call(deleteCustomProject, customproject)
    // console.log(response);
    // yield put(deleteCustomProjectSuccess(response))
    yield put(getCustomProjectsSuccess(response))
  } catch (error) {
    yield put(deleteCustomProjectFail(error))
  }
}

function* onAddNewCustomProject({ payload: customproject }) {
  try {
    const response = yield call(addNewCustomProject, customproject)
    yield put(addCustomProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addCustomProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* listStructure() {
  yield takeEvery(GET_CUSTOM_PROJECTS, fetchCustomProjects)
  yield takeEvery(GET_CUSTOM_PROJECT_PROFILE, fetchCustomProjectProfile)
  yield takeEvery(ADD_NEW_CUSTOM_PROJECT, onAddNewCustomProject)
  yield takeEvery(UPDATE_CUSTOM_PROJECT, onUpdateCustomProject)
  yield takeEvery(DELETE_CUSTOM_PROJECT, onDeleteCustomProject)
}

export default listStructure
