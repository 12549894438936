import React from "react"
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
import { Notifications } from "../../../components/Common/Notifications"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
  postJwtLogout,
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    localStorage.clear()
    const response = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
    })
    yield put(loginSuccess(response))
    async function doSomting() {
      localStorage.setItem("authUser", JSON.stringify(response))
    }
    doSomting().then()
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ระบบล็อกอิน",
      message: "เข้าสู่ระบบสำเร็จ",
    })
    const obj = JSON.parse(localStorage.getItem("authUser"))
    if (obj != undefined) {
      if (obj.group != undefined) {
        if (obj.group.permission == 6) {
          history.push("/audit")
        } else {
          history.push("/dashboard")
        }
      }
    }
  } catch (error) {
    async function doSomting() {
      localStorage.clear()
    }
    doSomting().then()
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ระบบล็อกอิน",
      message: "ไม่สามารถเข้าสู้ระบบ !",
    })
    // console.log(error)
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(postJwtLogout)

    async function doSomting() {
      localStorage.clear()
    }
    doSomting().then()

    history.push("/login")
  } catch (error) {
    async function doSomting() {
      localStorage.clear()
    }
    doSomting().then()

    history.push("/login")
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      // const fireBaseBackend = getFirebaseBackend()
      // const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      // localStorage.setItem("authUser", JSON.stringify(response))
      // yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
