import {
  GET_MATERIAL_PRICE_PROFILE,
  GET_MATERIAL_PRICE_PROFILE_FAIL,
  GET_MATERIAL_PRICE_PROFILE_SUCCESS,
  GET_MATERIAL_PRICES,
  GET_MATERIAL_PRICES_FAIL,
  GET_MATERIAL_PRICES_SUCCESS,
  ADD_NEW_MATERIAL_PRICE,
  ADD_MATERIAL_PRICE_SUCCESS,
  ADD_MATERIAL_PRICE_FAIL,
  UPDATE_MATERIAL_PRICE,
  UPDATE_MATERIAL_PRICE_SUCCESS,
  UPDATE_MATERIAL_PRICE_FAIL,
  DELETE_MATERIAL_PRICE,
  DELETE_MATERIAL_PRICE_SUCCESS,
  DELETE_MATERIAL_PRICE_FAIL,
} from "./actionTypes"

export const getMaterialPrices = material_id => ({
  type: GET_MATERIAL_PRICES,
  payload: material_id,
})

export const getMaterialPricesSuccess = materialshops => ({
  type: GET_MATERIAL_PRICES_SUCCESS,
  payload: materialshops,
})

export const addNewMaterialPrice = materialshop => ({
  type: ADD_NEW_MATERIAL_PRICE,
  payload: materialshop,
})

export const addMaterialPriceSuccess = materialshop => ({
  type: ADD_MATERIAL_PRICE_SUCCESS,
  payload: materialshop,
})

export const addMaterialPriceFail = error => ({
  type: ADD_MATERIAL_PRICE_FAIL,
  payload: error,
})

export const getMaterialPricesFail = error => ({
  type: GET_MATERIAL_PRICES_FAIL,
  payload: error,
})

export const getMaterialPriceProfile = id => ({
  type: GET_MATERIAL_PRICE_PROFILE,
  payload: id,
})

export const getMaterialPriceProfileSuccess = materialshopProfile => ({
  type: GET_MATERIAL_PRICE_PROFILE_SUCCESS,
  payload: materialshopProfile,
})

export const getMaterialPriceProfileFail = error => ({
  type: GET_MATERIAL_PRICE_PROFILE_FAIL,
  payload: error,
})

export const updateMaterialPrice = materialshop => ({
  type: UPDATE_MATERIAL_PRICE,
  payload: materialshop,
})

// export const updateMaterialPrice = materialshop => console.log(materialshop);

export const updateMaterialPriceSuccess = materialshop => ({
  type: UPDATE_MATERIAL_PRICE_SUCCESS,
  payload: materialshop,
})

export const updateMaterialPriceFail = error => ({
  type: UPDATE_MATERIAL_PRICE_FAIL,
  payload: error,
})

export const deleteMaterialPrice = materialshop => ({
  type: DELETE_MATERIAL_PRICE,
  payload: materialshop,
})

export const deleteMaterialPriceSuccess = materialshop => ({
  type: DELETE_MATERIAL_PRICE_SUCCESS,
  payload: materialshop,
})

export const deleteMaterialPriceFail = error => ({
  type: DELETE_MATERIAL_PRICE_FAIL,
  payload: error,
})
