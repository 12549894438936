import {
  GET_EMPLOYMENTCONPROJECT_PROFILE,
  GET_EMPLOYMENTCONPROJECT_PROFILE_FAIL,
  GET_EMPLOYMENTCONPROJECT_PROFILE_SUCCESS,
  GET_EMPLOYMENTCONPROJECTS,
  GET_EMPLOYMENTCONPROJECTS_FAIL,
  GET_EMPLOYMENTCONPROJECTS_SUCCESS,
  ADD_NEW_EMPLOYMENTCONPROJECT,
  ADD_EMPLOYMENTCONPROJECT_SUCCESS,
  ADD_EMPLOYMENTCONPROJECT_FAIL,
  UPDATE_EMPLOYMENTCONPROJECT,
  UPDATE_EMPLOYMENTCONPROJECT_SUCCESS,
  UPDATE_EMPLOYMENTCONPROJECT_FAIL,
  DELETE_EMPLOYMENTCONPROJECT,
  DELETE_EMPLOYMENTCONPROJECT_SUCCESS,
  DELETE_EMPLOYMENTCONPROJECT_FAIL,
} from "./actionTypes"

// export const getEmploymentConProjects = project_id => ({
//   type: GET_EMPLOYMENTCONPROJECTS,
//   payload: project_id,
// })

export const getEmploymentConProjects = project_id => ({
  type: GET_EMPLOYMENTCONPROJECTS,
  payload: project_id,
})

export const getEmploymentConProjectsSuccess = employmentconprojects => ({
  type: GET_EMPLOYMENTCONPROJECTS_SUCCESS,
  payload: employmentconprojects,
})

export const addNewEmploymentConProject = Employmentconproject => ({
  type: ADD_NEW_EMPLOYMENTCONPROJECT,
  payload: Employmentconproject,
})

export const addEmploymentConProjectSuccess = Employmentconproject => ({
  type: ADD_EMPLOYMENTCONPROJECT_SUCCESS,
  payload: Employmentconproject,
})

export const addEmploymentConProjectFail = error => ({
  type: ADD_EMPLOYMENTCONPROJECT_FAIL,
  payload: error,
})
export const getEmploymentConProjectsFail = error => ({
  type: GET_EMPLOYMENTCONPROJECTS_FAIL,
  payload: error,
})

export const getEmploymentConProjectProfile = (id, type) => ({
  type: GET_EMPLOYMENTCONPROJECT_PROFILE,
  payload: { id, type },
})

export const getEmploymentConProjectProfileSuccess =
  EmploymentconprojectProfile => ({
    type: GET_EMPLOYMENTCONPROJECT_PROFILE_SUCCESS,
    payload: EmploymentconprojectProfile,
  })

export const getEmploymentConProjectProfileFail = error => ({
  type: GET_EMPLOYMENTCONPROJECT_PROFILE_FAIL,
  payload: error,
})

export const updateEmploymentConProject = Employmentconproject => ({
  type: UPDATE_EMPLOYMENTCONPROJECT,
  payload: Employmentconproject,
})

// export const updateEmploymentConProject = Employmentconproject => console.log(Employmentconproject);

export const updateEmploymentConProjectSuccess = Employmentconproject => ({
  type: UPDATE_EMPLOYMENTCONPROJECT_SUCCESS,
  payload: Employmentconproject,
})

export const updateEmploymentConProjectFail = error => ({
  type: UPDATE_EMPLOYMENTCONPROJECT_FAIL,
  payload: error,
})

export const deleteEmploymentConProject = Employmentconproject => ({
  type: DELETE_EMPLOYMENTCONPROJECT,
  payload: Employmentconproject,
})

export const deleteEmploymentConProjectSuccess = Employmentconproject => ({
  type: DELETE_EMPLOYMENTCONPROJECT_SUCCESS,
  payload: Employmentconproject,
})

export const deleteEmploymentConProjectFail = error => ({
  type: DELETE_EMPLOYMENTCONPROJECT_FAIL,
  payload: error,
})
