import {
  GET_WITHDRAW_MATERIAL_PROFILE,
  GET_WITHDRAW_MATERIAL_PROFILE_FAIL,
  GET_WITHDRAW_MATERIAL_PROFILE_SUCCESS,
  GET_WITHDRAW_MATERIALS,
  GET_WITHDRAW_MATERIALS_FAIL,
  GET_WITHDRAW_MATERIALS_SUCCESS,
  GET_WITHDRAW_MATERIALS_SUCCESS_SUMMARIZE,
  ADD_NEW_WITHDRAW_MATERIAL,
  ADD_WITHDRAW_MATERIAL_SUCCESS,
  ADD_WITHDRAW_MATERIAL_FAIL,
  UPDATE_WITHDRAW_MATERIAL,
  UPDATE_WITHDRAW_MATERIAL_SUCCESS,
  UPDATE_WITHDRAW_MATERIAL_FAIL,
  DELETE_WITHDRAW_MATERIAL,
  DELETE_WITHDRAW_MATERIAL_SUCCESS,
  DELETE_WITHDRAW_MATERIAL_FAIL,
} from "./actionTypes"

export const getWithdrawMaterials = data => ({
  type: GET_WITHDRAW_MATERIALS,
  payload: data,
})

export const getWithdrawMaterialsSuccess = withdrawmaterials => ({
  type: GET_WITHDRAW_MATERIALS_SUCCESS,
  payload: withdrawmaterials,
})
export const getWithdrawMaterialsSuccessSummarize = withdrawmaterials => ({
  type: GET_WITHDRAW_MATERIALS_SUCCESS_SUMMARIZE,
  payload: withdrawmaterials,
})


export const addNewWithdrawMaterial = withdrawmaterial => ({
  type: ADD_NEW_WITHDRAW_MATERIAL,
  payload: withdrawmaterial,
})

export const addWithdrawMaterialSuccess = withdrawmaterial => ({
  type: ADD_WITHDRAW_MATERIAL_SUCCESS,
  payload: withdrawmaterial,
})

export const addWithdrawMaterialFail = error => ({
  type: ADD_WITHDRAW_MATERIAL_FAIL,
  payload: error,
})

export const getWithdrawMaterialsFail = error => ({
  type: GET_WITHDRAW_MATERIALS_FAIL,
  payload: error,
})

export const getWithdrawMaterialProfile = id => ({
  type: GET_WITHDRAW_MATERIAL_PROFILE,
  payload: id,
})

export const getWithdrawMaterialProfileSuccess = withdrawmaterialProfile => ({
  type: GET_WITHDRAW_MATERIAL_PROFILE_SUCCESS,
  payload: withdrawmaterialProfile,
})

export const getWithdrawMaterialProfileFail = error => ({
  type: GET_WITHDRAW_MATERIAL_PROFILE_FAIL,
  payload: error,
})

export const updateWithdrawMaterial = data => ({
  type: UPDATE_WITHDRAW_MATERIAL,
  payload: data,
})

// export const updateWithdrawMaterial = withdrawmaterial => console.log(withdrawmaterial);

export const updateWithdrawMaterialSuccess = withdrawmaterial => ({
  type: UPDATE_WITHDRAW_MATERIAL_SUCCESS,
  payload: withdrawmaterial,
})

export const updateWithdrawMaterialFail = error => ({
  type: UPDATE_WITHDRAW_MATERIAL_FAIL,
  payload: error,
})

export const deleteWithdrawMaterial = withdrawmaterial => ({
  type: DELETE_WITHDRAW_MATERIAL,
  payload: withdrawmaterial,
})

export const deleteWithdrawMaterialSuccess = withdrawmaterial => ({
  type: DELETE_WITHDRAW_MATERIAL_SUCCESS,
  payload: withdrawmaterial,
})

export const deleteWithdrawMaterialFail = error => ({
  type: DELETE_WITHDRAW_MATERIAL_FAIL,
  payload: error,
})
