import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
} from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  getAttendProfile as onGetAttendProfile,
  addNewAttend as onAddNewAttend,
  updateAttend as onUpdateAttend,
} from "store/attends/actions"

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()

  const { attendProfile } = useSelector(state => ({
    attendProfile: state.attends.attendProfile,
  }))

  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState({})

  async function checkAction() {
    setFormData({})

    if (actionModal.action == "Add") {
      const obj = JSON.parse(localStorage.getItem("authUser"))

      setFormData({
        user_id: obj.me.id,
        status: 3,
        note: "",
      })
    } else if (actionModal.action == "Edit") {
      dispatch(onGetAttendProfile(actionModal.id))
    }

    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true)
    if (errors.length > 0) {
    } else {
      if (action == "Add") {
        // save new contractor
        dispatch(onAddNewAttend(formData))
      } else if (action == "Edit") {
        // update contractor
        dispatch(onUpdateAttend(formData))
      }
      setModal(!modal)
    }
    setButtonSubmit(false)
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      setFormData(attendProfile)
      // console.log(attendProfile)
    }
    return () => {}
  }, [attendProfile]) // Only re-run the effect if count changes

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
      size="md"
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}ใบลา{" "}
      </ModalHeader>
      <AvForm onSubmit={handleSubmit} model={formData}>
        <ModalBody>
          <Row>
            <Col sm="12" lg="6">
              <AvField
                type="select"
                name="status_select"
                id="status_select"
                value={formData.status}
                onChange={e =>
                  setFormData({ ...formData, status: e.target.value })
                }
                label="เลือกประเภท"
                validate={{
                  required: { value: true },
                }}
              >
                <option value={3}>ลา</option>
                <option value={4}>หน้างาน</option>
                <option value={5}>ส่งของ</option>
              </AvField>
            </Col>
            <Col sm="12" lg="6">
              <FormGroup className="mb-3">
                <AvField
                  name="note"
                  type="textarea"
                  label="หมายเหตุ"

                  placeholder="หมายเหตุ"
                  value={formData.note}
                  onChange={e =>
                    setFormData({ ...formData, note: e.target.value })
                  }
                  validate={{
                    required: { value: false },
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            Save
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
