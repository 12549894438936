import { GET_USERGROUPS_SUCCESS, GET_USERGROUPS_FAIL } from "./actionTypes"

const INIT_STATE = {
  userGroups: [],
  userProfile: {},
  error: {},
}

const userGroups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERGROUPS_SUCCESS:
      return {
        ...state,
        userGroups: action.payload,
      }

    case GET_USERGROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default userGroups
