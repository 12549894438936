import React from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  Input,
  FormGroup,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import { isEmpty } from "lodash"
import Dropzone from "react-dropzone"
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import imageCompression from "browser-image-compression"
import { useHistory } from "react-router-dom"

//Import actions
// import { getProductDetail as onGetDesignHomeProfile } from "store/actions"
import home from "./IMG_0302.jpg"

import {
  getDesignHomeProfile as onGetDesignHomeProfile,
  updateDesignHome as onUpdateDesignHome,
  uploadDesignHomeImg as onUploadImgHomeImg,
  changeFirstDesignHome as onChangeFirstDesignHome,
  delImgDesignHome as onDelImgDesignHome,
} from "store/design-homes/actions"

// import RecentProducts from "./RecentProducts"
import Navbar from "../Navbar/Navbar"
import Footer from "../../Crypto/CryptoIcoLanding/Footer/footer"
import Editable from "react-bootstrap-editable"
//redux
import { useSelector, useDispatch } from "react-redux"

const HomeProductDetail = props => {
  const [activeTab, setActiveTab] = React.useState(0)
  const [product, setProduct] = React.useState({})
  const [option, setOption] = React.useState([])
  const [slide_imgs, setSlide_imgs] = React.useState([])
  const [imgFirst, setImgFirst] = React.useState("")
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [photoIndex, setphotoIndex] = React.useState(0)
  const [isGallery, setisGallery] = React.useState(false)
  const [isUpload, setIsUpload] = React.useState(false)
  const history = useHistory()
  const {
    match: { params },
  } = props

  const dispatch = useDispatch()

  const obj = JSON.parse(localStorage.getItem("authUser"))
  const { designhomeProfile } = useSelector(state => ({
    designhomeProfile: state.designHomes.designhomeProfile,
  }))

  React.useEffect(() => {
    if (params && params.id) {
      dispatch(onGetDesignHomeProfile(params.id))
    } else {
    }
  }, [params])

  React.useEffect(() => {
    // console.log(designhomeProfile)
    if (!isEmpty(designhomeProfile)) {
      // if (!isEmpty(designhomeProfile.slide_img)) {
      // console.log(designhomeProfile.slide_imgs[0])
      // }
      if (!isEmpty(designhomeProfile.slide_imgs)) {
        setImgFirst(designhomeProfile.slide_imgs[0])
      }
      setSlide_imgs(designhomeProfile.slide_imgs)
      setOption(designhomeProfile.option)
      let { slide_imgs, option, ...rest } = designhomeProfile
      setProduct(rest)
      setIsUpload(false)
      setisGallery(false)
    }
    return () => {}
  }, [designhomeProfile]) // Only re-run the effect if count changes

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  function handleSubmit() {
    // setButtonSubmit(true)
    const data = new FormData()
    data.append("formData", JSON.stringify(product))
    data.append("option", JSON.stringify(option))
    dispatch(onUpdateDesignHome(data))
    history.push("/home-products")

    // setButtonSubmit(false)
  }

  async function onUploadImg(e) {
    if (e.target.files[0]) {
      setIsUpload(true)
      const files = e.target.files
      const data = new FormData()
      data.append("slidershop_id", product.id)
      for (let i = 0; i < files.length; i++) {
        data.append(`slide_imgs[${i}]`, await resizeImageFn(files[i]))
      }
      dispatch(onUploadImgHomeImg(data))
    }
  }
  async function resizeImageFn(file) {
    // console.log("originalFile instanceof Blob", file instanceof Blob) // true
    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options)
      // console.log(
      //   "compressedFile instanceof Blob",
      //   compressedFile instanceof Blob
      // ) // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

      // await uploadToServer(compressedFile) // write your own logic
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }
  function addRow() {
    setOption([...option, { key: option.length, name: "null" }])
  }
  function handleRemoveItem(index) {
    // assigning the list to temp variable
    const temp = [...option]
    // removing the element using splice
    temp.splice(index, 1)
    // updating the list
    setOption(temp)
  }

  async function handleAcceptedFiles(files) {
    setIsUpload(true)
    const data = new FormData()
    data.append("slidershop_id", product.id)
    for (let i = 0; i < files.length; i++) {
      // data.append(`slide_imgs[${i}]`, files[i])
      data.append(`slide_imgs[${i}]`, await resizeImageFn(files[i]))
    }
    dispatch(onUploadImgHomeImg(data))
  }

  function changeImgFirst(id) {
    dispatch(onChangeFirstDesignHome(id))
  }

  function delImg(id) {
    dispatch(onDelImgDesignHome(id))
  }
  return (
    <React.Fragment>
      <style scoped>
        {
          "\
          .form-inline{\
            display: flex;\
          }\
          .mt-auto {\
            margin-left: 10px;\
          }\
          .upload-photo {\
            opacity: 0;\
            position: absolute;\
            z-index: -1;\
          }\
          .showme {\
            display: none;\
          }\
          .showhim:hover .showme {\
            display: block;\
          }\
          .upload-loding{\
            height: 100vh;\
            display: flex;\
            justify-content: center;\
            align-items: center;\
            }\
          "
        }
      </style>

      <div className="page-content">
        <MetaTags>
          <title>Home Details | {process.env.REACT_APP_NAMECOMPANY}</title>

          {!isEmpty(imgFirst) ? (
            <div>
              <meta
                id="og-description"
                name="description"
                content={process.env.REACT_APP_DETAILCOMPANY}
              />
              <meta
                id="og-title"
                property="og:title"
                content={process.env.REACT_APP_NAMECOMPANY}
              />
              <meta
                id="og-image"
                property="og:image"
                content={process.env.REACT_APP_SERVER + imgFirst}
              />
            </div>
          ) : null}
        </MetaTags>
        <Navbar />
        <Container fluid>
          {/* <Breadcrumbs title="Home" breadcrumbItem="Product Detail" /> */}

          {isGallery ? (
            <Lightbox
              mainSrc={
                process.env.REACT_APP_SERVER + slide_imgs[photoIndex].path_img
              }
              nextSrc={
                process.env.REACT_APP_SERVER +
                slide_imgs[(photoIndex + 1) % slide_imgs.length].path_img
              }
              prevSrc={
                process.env.REACT_APP_SERVER +
                slide_imgs[
                  (photoIndex + slide_imgs.length - 1) % slide_imgs.length
                ].path_img
              }
              enableZoom={false}
              toolbarButtons={
                !isEmpty(obj)
                  ? [
                      <div key={photoIndex}>
                        <h5
                          className="pb-1"
                          // onClick={() => console.log(slide_imgs[photoIndex].id)}
                          onClick={() => {
                            changeImgFirst(slide_imgs[photoIndex].id)
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            style={{ fontSize: "20px", color: "white" }}
                            className="bx bx-images"
                          ></i>
                        </h5>
                      </div>,
                      <div key={photoIndex}>
                        <h5
                          className="pb-1"
                          // onClick={() => console.log(slide_imgs[photoIndex].id)}
                          onClick={() => {
                            delImg(slide_imgs[photoIndex].id)
                          }}
                          style={{
                            cursor: "pointer",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <i
                            style={{ fontSize: "20px", color: "white" }}
                            className="bx bxs-trash-alt"
                          ></i>
                        </h5>
                      </div>,
                    ]
                  : null
              }
              onCloseRequest={() => {
                setisGallery(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex(
                  (photoIndex + slide_imgs.length - 1) % slide_imgs.length
                )
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % slide_imgs.length)
              }}
              imageCaption={
                process.env.REACT_APP_NAMECOMPANY +
                ": " +
                parseFloat(photoIndex + 1)
              }
            />
          ) : null}

          {!isEmpty(product) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      {/* {isUpload == true ? (
                        <Col xl="6">
                          <Spinner className="ms-2 " color="primary" />
                        </Col>
                      ) : ( */}
                      <Col xl="6">
                        {isUpload == true ? (
                          <div className="upload-loding">
                            <Spinner className="ms-2 " color="primary" />
                          </div>
                        ) : (
                          <div className="product-detai-imgs">
                            <Row>
                              <Col md="12" xs="12">
                                <TabContent activeTab={activeTab}>
                                  {slide_imgs &&
                                    slide_imgs.map((item, i) => (
                                      <TabPane tabId={i} key={i}>
                                        <img
                                          src={
                                            process.env.REACT_APP_SERVER +
                                            item.path_img
                                          }
                                          style={{ cursor: "pointer" }}
                                          alt={
                                            process.env.REACT_APP_NAMECOMPANY
                                          }
                                          id={"expandedImg" + i}
                                          onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(i)
                                          }}
                                          width="100%"
                                          className="img-fluid mx-auto d-block"
                                        />
                                      </TabPane>
                                    ))}
                                </TabContent>
                              </Col>

                              {slide_imgs.length == 0 ? (
                                <Col md="12" xs="12">
                                  <Dropzone
                                    multiple={true}
                                    accept="image/*"
                                    inputContent={(files, extra) =>
                                      extra.reject
                                        ? "Image files only"
                                        : "Drag Files"
                                    }
                                    onDrop={acceptedFiles => {
                                      handleAcceptedFiles(acceptedFiles)
                                    }}
                                    styles={{
                                      dropzoneReject: {
                                        borderColor: "red",
                                        backgroundColor: "#DAA",
                                      },
                                      inputLabel: (files, extra) =>
                                        extra.reject ? { color: "red" } : {},
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="dz-message needsclick">
                                            <div className="mb-3">
                                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                                            </div>
                                            <h4>
                                              Drop files here or click to
                                              upload.
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                </Col>
                              ) : (
                                <Col
                                  md="12"
                                  xs="12"
                                  // style={{
                                  //   marginLeft: "-5px",
                                  // }}
                                >
                                  <Nav className="flex-row pl-10" pills>
                                    {slide_imgs &&
                                      slide_imgs.map((item, i) => (
                                        <NavItem key={i}>
                                          <NavLink
                                            className={classnames({
                                              active: activeTab == i,
                                            })}
                                            style={{
                                              padding: "3px",
                                              margin: "0xp",
                                            }}
                                            onClick={() => {
                                              toggleTab(i)
                                            }}
                                          >
                                            <img
                                              src={
                                                process.env.REACT_APP_SERVER +
                                                item.path_img
                                              }
                                              alt={
                                                process.env
                                                  .REACT_APP_NAMECOMPANY
                                              }
                                              width="50px"
                                              height="50px"
                                              className="mx-auto d-block rounded"
                                            />
                                          </NavLink>
                                        </NavItem>
                                      ))}
                                    {!isEmpty(obj) ? (
                                      <NavItem>
                                        <NavLink>
                                          <Label
                                            htmlFor="formrow-InputZip"
                                            style={{ cursor: "pointer" }}
                                            color="primary"
                                            className="font-16 btn-block btn btn-primary"
                                          >
                                            <i className="mdi mdi-plus-circle-outline" />
                                          </Label>
                                          <Input
                                            style={{ display: "none" }}
                                            id="formrow-InputZip"
                                            accept="image/*"
                                            type="file"
                                            multiple={true}
                                            name="file_img"
                                            onChange={onUploadImg}
                                          />
                                        </NavLink>
                                      </NavItem>
                                    ) : null}
                                  </Nav>
                                </Col>
                              )}
                            </Row>
                          </div>
                        )}
                      </Col>

                      {!isEmpty(obj) ? (
                        <Col xl="6">
                          <div className="mt-4 mt-xl-3">
                            {/* {product.hashtag} */}

                            <p
                              className="mt-1 mb-3"
                              style={{ fontSize: "18px", fontWeight: "100" }}
                            >
                              {/* {product.code} */}
                              <div>
                                <Editable
                                  id="product.name"
                                  initialValue={product.name}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      name: e,
                                    })
                                  }}
                                  placement="top"
                                  label="Name"
                                  showText
                                  type="textfield"
                                />
                              </div>
                            </p>

                            <hr />

                            <p
                              className="mt-1 mb-3"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              {/* {product.code} */}
                              <div>
                                <Editable
                                  id="product.code"
                                  initialValue={product.code}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      code: e,
                                    })
                                  }}
                                  placement="top"
                                  label="Code"
                                  showText
                                  type="textfield"
                                />
                              </div>
                            </p>

                            <p
                              className="mt-1 mb-3"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              <div>
                                <Editable
                                  id="product.hashtag"
                                  initialValue={product.hashtag}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      hashtag: e,
                                    })
                                  }}
                                  placement="top"
                                  label="Hashtag"
                                  showText
                                  type="textfield"
                                />
                              </div>
                            </p>

                            <p
                              className="mt-1 mb-3"
                              style={{ fontSize: "16px", fontWeight: "100" }}
                            >
                              <div>
                                <Editable
                                  id="product.price"
                                  initialValue={product.price}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      price: e,
                                    })
                                  }}
                                  placement="top"
                                  label="Price"
                                  showText
                                  type="textfield"
                                />
                              </div>
                            </p>

                            <p
                              className="mt-1 mb-3"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              <div>
                                <Editable
                                  id="product.offer"
                                  initialValue={product.offer}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      offer: e,
                                    })
                                  }}
                                  placement="top"
                                  label="Offer (%)"
                                  showText
                                  type="textfield"
                                />
                              </div>
                            </p>

                            <p
                              className="mt-1 mb-3"
                              style={{ fontSize: "16px", fontWeight: "100" }}
                            >
                              <div>
                                <span>
                                  After reduced % : &nbsp;
                                  {Number(
                                    (parseInt(product.price) / 100) *
                                      (100 - product.offer)
                                  ).toLocaleString("th-TH", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })}
                                </span>

                                <Editable
                                  id="product.reduced"
                                  initialValue={product.reduced}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      reduced: e,
                                    })
                                  }}
                                  placement="top"
                                  label="Reduced"
                                  showText
                                  type="textfield"
                                />
                              </div>
                            </p>

                            <p
                              className="mt-1 mb-3"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              <div>
                                <Editable
                                  id="product.offer"
                                  initialValue={product.detail}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      detail: e,
                                    })
                                  }}
                                  placement="top"
                                  label="Detail"
                                  showText
                                  type="textarea"
                                />
                              </div>
                            </p>
                            <hr />
                            <Row className="mt2-3">
                              {option &&
                                option.map((item, i) => (
                                  <Col
                                    md="4"
                                    key={i}
                                    className="form-inline mt-1 mb-2"
                                  >
                                    <i
                                      className={classnames(
                                        "fa fa-caret-right",
                                        "font-size-16 align-middle text-primary me-2"
                                      )}
                                    />
                                    {/* {item.name} */}
                                    <div
                                      className="showhim"
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <Editable
                                        id="option.name"
                                        initialValue={item.name}
                                        editText={
                                          <i className="fas fa-pen"></i>
                                        }
                                        mode="inline"
                                        onSubmit={e => {
                                          let newArr = [...option]
                                          newArr[i].name = e
                                          setOption(newArr)
                                        }}
                                        placement="top"
                                        label="เพิ่มเติม"
                                        showText
                                        type="textfield"
                                      />
                                      <b
                                        className="text-danger showme"
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "2px",
                                          fontWeight: "100",
                                        }}
                                        onClick={() => handleRemoveItem(i)}
                                      >
                                        ลบ
                                      </b>
                                    </div>

                                    {/* </p> */}
                                  </Col>
                                ))}

                              <Col
                                md="4"
                                style={{ marginBottom: "-20px" }}
                                className="mb-1"
                              >
                                <i
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "13px",
                                  }}
                                  onClick={() => addRow()}
                                  className="mdi mdi-plus-circle-outline text-primary"
                                />
                              </Col>
                            </Row>
                          </div>
                          <hr />
                          <Row>
                            <Col xs={12} sm={12} md={8} lg={8}>
                              <FormGroup className="mt-2">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioPrice_type"
                                      checked={product.poisson == 1}
                                      value={1}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          poisson: e.target.value,
                                        })
                                      }
                                    />
                                    <i className="fas fa-angle-up"></i>
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioPrice_type"
                                      checked={product.poisson == 2}
                                      value={2}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          poisson: e.target.value,
                                        })
                                      }
                                    />
                                    <i className="fas fa-angle-down"></i>
                                  </Label>
                                </FormGroup>

                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioPrice_type"
                                      checked={product.poisson == 3}
                                      value={3}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          poisson: e.target.value,
                                        })
                                      }
                                    />
                                    <i className="fas fa-angle-right"></i>
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>

                            {/* <Col xs={12} sm={12} md={6} lg={6}>
                              <FormGroup className="mt-2">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioPrice_type"
                                      checked={product.price_type == 1}
                                      value={1}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          price_type: e.target.value,
                                        })
                                      }
                                    />
                                    ไม่ลดราคา
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioPrice_type"
                                      checked={product.price_type == 2}
                                      value={2}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          price_type: e.target.value,
                                        })
                                      }
                                    />
                                    ลด
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col> */}

                            <Col xs={12} sm={12} md={4} lg={4}>
                              <FormGroup className="mt-2">
                                {/* <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioActive"
                                      checked={product.active == 1}
                                      value={1}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          active: e.target.value,
                                        })
                                      }
                                    />
                                    แสดงหน้าร้าน
                                  </Label>
                                </FormGroup> */}
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioActive"
                                      checked={product.active == 2}
                                      value={2}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          active: e.target.value,
                                        })
                                      }
                                    />
                                    <i className="fas fa-trash-alt"></i>
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div style={{ textAlign: "right" }}>
                            <Button
                              onClick={() => handleSubmit()}
                              type="button"
                              disabled={buttonSubmit}
                              color="secondary"
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      ) : (
                        <Col xl="6">
                          <div className="mt-4 mt-xl-3">
                            <p
                              className="mt-1 mb-3"
                              style={{ fontSize: "18px", fontWeight: "100" }}
                            >
                              <div>
                                {product.code == "null" ? null : product.code}
                              </div>
                            </p>

                            <hr />
                            <p
                              className="mt-1 mb-3 text-primary"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              <div>
                                {product.name == "null" ? null : product.name}
                              </div>
                            </p>

                            <p
                              className="mt-1 mb-3 text-primary"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              {product.hashtag == "null"
                                ? null
                                : product.hashtag}
                            </p>

                            <p
                              className="mt-1 mb-3"
                              style={{ fontSize: "18px", fontWeight: "100" }}
                            >
                              {product.price_type == "2" ? (
                                <div>
                                  <b
                                    className="text-muted me-2"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "100",
                                    }}
                                  >
                                    <del>
                                      {parseFloat(product.price).toLocaleString(
                                        "th-TH",
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                        }
                                      )}
                                      &nbsp; บ.
                                    </del>
                                  </b>
                                  {!isEmpty(product.price) ? (
                                    <b
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {parseFloat(
                                        (parseFloat(product.price) / 100) *
                                          (100 - product.offer)
                                      ).toLocaleString("th-TH", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      })}
                                      &nbsp; บ.
                                    </b>
                                  ) : (
                                    0
                                  )}
                                </div>
                              ) : (
                                <p
                                  className="text-muted me-2"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "100",
                                  }}
                                >
                                  <div>
                                    {parseFloat(product.reduced).toLocaleString(
                                      "th-TH",
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }
                                    )}
                                    &nbsp; บ.
                                  </div>
                                </p>
                              )}
                            </p>
                            <p
                              className="text-muted mt-1 mb-1"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              <div>
                                {product.detail == "null"
                                  ? null
                                  : product.detail}
                              </div>
                            </p>
                            <hr />
                            <Row className="mt2-3">
                              {option &&
                                option.map((item, i) => (
                                  <Col md="4" key={i}>
                                    <p
                                      className="text-muted"
                                      style={{
                                        fontWeight: "100",
                                      }}
                                    >
                                      <div>
                                        <i
                                          className={classnames(
                                            "fa fa-caret-right",
                                            "font-size-16 align-middle text-primary me-2"
                                          )}
                                        />
                                        {item.name}
                                      </div>
                                    </p>
                                  </Col>
                                ))}
                            </Row>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {/* <RecentProducts recentProducts={product.recentProducts} /> */}
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  )
}

HomeProductDetail.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  onGetDesignHomeProfile: PropTypes.func,
}

export default HomeProductDetail
