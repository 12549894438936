import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Table,
  Row,
  ModalFooter,
  Card,
  Spinner,
} from "reactstrap"
import { isEmpty } from "lodash"
import imageCompression from "browser-image-compression"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useScreenshot, createFileName } from "use-react-screenshot"
import {
  updateSlipproject as onUpdateSlipproject,
  uploadImgInfoSlip as onUploadImgInfoSlip,
} from "store/slips-project/actions"

import { getMaterialShops as onGetMaterialShops } from "store/material-shops/actions"
import { getUsers as onGetUsers } from "store/actions"
import { getProjects as onGetProjects } from "store/actions"
import { getContractors as onGetContractors } from "store/actions"

import {
  getBoqTitleHomes as onGetBoqTitleHomes,
  getBoqTitleHomeProfile as onGetBoqTitleHomeProfile,
} from "store/boq-title-homes/actions"

import { getMaterialStocks as onGetMaterialStocks } from "store/material-stocks/actions"
import { getMaterialTypes as onGetMaterialTypes } from "store/material-types/actions"
import Dropzone from "react-dropzone"
import Select from "react-select"
import moment from "moment"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import ExampleModalShop from "components/Dialogs/dialogMaterial_shop"
import ExampleModalMaterialStock from "components/Dialogs/dialogMaterial_stock"

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const { materialShops } = useSelector(state => ({
    materialShops: state.materialShops.materialShops,
  }))

  const { users } = useSelector(state => ({
    users: state.users.users,
  }))

  const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }))
  const { boqTitleHomes } = useSelector(state => ({
    boqTitleHomes: state.boqTitleHomes.boqTitleHomes,
  }))

  const { boqTitleHomeProfile } = useSelector(state => ({
    boqTitleHomeProfile: state.boqTitleHomes.boqTitleHomeProfile,
  }))

  const { contractors } = useSelector(state => ({
    contractors: state.contractors.contractors,
  }))

  const { materialStocks } = useSelector(state => ({
    materialStocks: state.materialStocks.materialStocks,
  }))

  const { materialTypes } = useSelector(state => ({
    materialTypes: state.materialTypes.materialTypes,
  }))

  const ref = React.createRef(null)
  const [material_type_id, setMaterial_type_id] = React.useState(0)
  const [selectType, setSelectType] = React.useState(1)
  const [selectedFiles, setselectedFiles] = React.useState([])
  const [add_money_details, setAdd_money_details] = React.useState([])
  const [tableDataMaterialStock, setTableDataMaterialStock] = React.useState([]) // set campaign as default
  const [img_slip, setImg_slip] = React.useState(null)
  const [dataImg_slip, setDataImg_slip] = React.useState({})
  const [boqMaterials_index, setBoqMaterials_index] = React.useState(null)
  const [action, setAction] = React.useState("")
  const [id, setID] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [hideUploadImg, setHideUploadImg] = React.useState(false)
  const [buttonCapture, setButtonCapture] = React.useState(false)
  const [styleCapture, setStyleCapture] = React.useState({
    padding: 5,
    width: "100%",
  })

  const [formData, setFormData] = React.useState({})
  const [groups, setGroups] = React.useState([])

  const [optionSelect, setOptionSelect] = React.useState([
    { value: 1, label: "เงินสำรองจ่าย" },
    { value: 2, label: "เบิกค่าแรง" },
    { value: 3, label: "ค่าใข้จ่าย" },
    { value: 4, label: "รายจ่ายพนักงาน" },
  ])

  const [actionModalMaterialStock, setActionModelMaterialStock] =
    React.useState({
      id: null,
      action: null,
      modal: false,
      showQtaMoney: "Q",
    })

  const [optionSelectProjects, setOptionSelectProjects] = React.useState([])

  const [optionSelectContractors, setOptionSelectContractors] = React.useState(
    []
  )

  const [optionSelectUsers, setOptionSelectUsers] = React.useState([])

  const [optionSelectShops, setOptionSelectShops] = React.useState([])

  const [optionSelectWorks, setOptionSelectWorks] = React.useState([])

  const [optionSelectWorkSubs, setOptionSelectWorkSubs] = React.useState([])
  const [modal_materialStock, setModal_materialStock] = React.useState(false)

  const [optionSelectPays, setOptionSelectPays] = React.useState([
    { value: 1, label: "ซื้อวัสดุ (ต้นทุนการก่อสร้าง)" },
    { value: 2, label: "น้ำมัน" },
    { value: 3, label: "ค่าใช้จ่ายสำนักงาน" },
  ])

  const [tableMaterialStockColumns, setTableMaterialStockColumns] =
    React.useState([
      {
        dataField: "name",
        text: "ชื่อ",
        sort: true,
      },
      {
        dataField: "material_type",
        text: "ประเภท",
        sort: true,
        headerStyle: () => {
          return { width: "100px" }
        },
        formatter: function action(cell, row) {
          return cell == 1 ? "วัสดุหลัก" : "วัสดุสิ้นเปลือง"
        },
      },
      // {
      //   dataField: "unit",
      //   text: "หน่วย",
      //   sort: true,
      //   headerStyle: () => {
      //     return { width: "80px" }
      //   },
      // },
      // {
      //   dataField: "qta",
      //   text: "คงเหลือ",
      //   sort: true,
      //   headerStyle: () => {
      //     return { width: "80px" }
      //   },
      //   formatter: function action(cell, row) {
      //     return (
      //       <span>
      //         {parseFloat(cell).toLocaleString("th-TH", {
      //           minimumFractionDigits: 0,
      //           maximumFractionDigits: 0,
      //         })}
      //       </span>
      //     )
      //   },
      // },
      // {
      //   dataField: "price",
      //   text: "ราคา (ขาย)",
      //   sort: true,
      //   headerStyle: () => {
      //     return { width: "80px" }
      //   },
      //   formatter: function action(cell, row) {
      //     return (
      //       <span>
      //         {parseFloat(cell).toLocaleString("th-TH", {
      //           minimumFractionDigits: 2,
      //           maximumFractionDigits: 2,
      //         })}
      //       </span>
      //     )
      //   },
      // },
      {
        dataField: "id",
        text: "จัดการ",
        sort: true,
        headerStyle: () => {
          return { width: "100px" }
        },
        formatter: function action(cell, row) {
          return (
            <div>
              <Button
                onClick={() => eventActionMaterialStock("Edit", cell, true)}
                className="mr-1 mb-1"
                color="primary"
                outline
              >
                <i className="bx bx-pencil"></i>
              </Button>
            </div>
          )
        },
      },
    ])

  const [actionModalShop, setActionModelShop] = React.useState({
    id: null,
    action: null,
    modal: false,
  })

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableDataMaterialStock.length, // replace later with size(customers),
    custom: true,
  }

  // React.useEffect(() => {
  //   console.log("formData", formData)
  // }, [formData])

  const [image, takeScreenShot] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  })

  React.useEffect(async () => {
    if (buttonCapture == true) {
      await takeScreenShot(ref.current).then(download)
      setHideUploadImg(false)
      setButtonCapture(false)
      setStyleCapture({
        padding: 5,
        width: "100%",
      })
      if (action == "Add") {
        setFormData({})
        setModal(!modal)
      }
    }
  }, [buttonCapture])

  const downloadScreenshot = async () => {
    if (action == "Add" || formData.type == 2) {
      setHideUploadImg(true)
      setStyleCapture({
        padding: 5,
        width: 650,
      })
      setTimeout(() => {
        setButtonCapture(true)
      }, 1000)
    } else {
      setHideUploadImg(true)
      await takeScreenShot(ref.current).then(download)
      setHideUploadImg(false)
    }
  }
  const download = async (
    image,
    {
      name = "ตั้งเบิกวันที่ " + moment().format("YYYY-MM-DD HH:mm"),
      extension = "png",
    } = {}
  ) => {
    const a = document.createElement("a")
    a.href = image
    a.download = createFileName(extension, name)
    a.click()
  }

  async function checkAction() {
    dispatch(onGetMaterialShops(1))
    dispatch(onGetUsers(1))
    dispatch(onGetProjects("list", null))
    dispatch(onGetContractors(1))
    dispatch(onGetMaterialTypes(1))
    setselectedFiles([])
    setAdd_money_details([])
    setFormData({})
    if (actionModal.action == "Add") {
      setFormData({
        ...formData,
        type: 2,
        project_id: 0,
        comment: "ตั้งเบิก",
        money: 0,
        vat: null,
        date_add: moment().format("YYYY-MM-DD"),
        check: 0,
        vat_type: 3,
        action: "Add",
      })
    } else if (actionModal.action == "Edit") {
      // console.log(actionModal)
      if (!isEmpty(actionModal.data.img_path)) {
        setImg_slip(actionModal.data.img_path)
      }
      await change_project(
        actionModal.data.project_id,
        actionModal.data.project_name
      )
      await change_boq_title_id(actionModal.data.boq_title_id)
      setFormData(actionModal.data)
      if (!isEmpty(actionModal.data.add_money_details)) {
        setAdd_money_details(actionModal.data.add_money_details)
      }
    } else if (actionModal.action == "Wage") {
      setFormData({
        ...formData,
        type: 3,
        pay_id: 1,
        project_id: 0,
        comment: "สั่งซื้อวัสดุ",
        installments: 1,
        discount: 0,
        money: 0,
        vat: 0,
        check: 0,
        date_add: moment().format("YYYY-MM-DD"),
        check: 0,
        vat_type: 3,
        vat_material_type: 2,
        pay_type: 1,
        money_after: 0,
        action: "Wage",
      })
      addRow()
    }
    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
    // console.log(groups);
  }

  function eventActionMaterialStock(action, id, modal) {
    setActionModelMaterialStock({
      id: id,
      action: action,
      modal: modal,
      showQtaMoney: "Q",
    })
  }

  React.useEffect(async () => {
    if (materialTypes != undefined) {
      const options = await materialTypes.map((d, index) => ({
        value: d.id,
        label: d.name,
      }))
      let newOpeion = [
        {
          value: 0,
          label: "ประเภททั้งหมด",
        },
      ].concat(options)

      setGroups(newOpeion)
    }

    return () => {}
  }, [materialTypes]) // Only re-run the effect if count changes

  React.useEffect(() => {
    dispatch(onGetMaterialStocks(selectType, material_type_id))
  }, [selectType, material_type_id])

  React.useEffect(() => {
    // console.log(materialStocks);
    if (!isEmpty(materialStocks)) {
      setTableDataMaterialStock(materialStocks)
    } else {
      setTableDataMaterialStock([])
    }
  }, [materialStocks])

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true)
    // console.log("errors", errors)
    // console.log("1111", 1111)
    const data = new FormData()
    data.append("formData", JSON.stringify(formData))
    data.append("add_money_details", JSON.stringify(add_money_details))
    data.append("img", dataImg_slip)

    if (action == "Wage") {
      dispatch(onUploadImgInfoSlip(data))
      setModal(!modal)
    } else {
      if (errors.length > 0) {
        console.log("errors", errors)
      } else {
        if (action == "Add") {
          dispatch(onUploadImgInfoSlip(data))
          await downloadScreenshot()
        } else if (action == "Edit") {
          dispatch(onUpdateSlipproject(data))
        }
        setModal(!modal)
      }
    }

    setButtonSubmit(false)
  }

  async function handleAcceptedFiles(file) {
    file.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setDataImg_slip(await resizeImageFn(file[0]))
    setselectedFiles(file)
  }
  async function change_project(id, name) {
    // console.log("change_project", id)
    if (id != null) {
      dispatch(onGetBoqTitleHomes(id, "wage"))
      setFormData({
        ...formData,
        project_id: id,
        project_name: !isEmpty(name) ? name : "",
      })
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseInt((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  async function change_boq_title_id(id) {
    // console.log("change_boq_title_id", id)
    if (id != null) {
      dispatch(onGetBoqTitleHomeProfile(id))
      setFormData({
        ...formData,
        boq_title_id: id,
      })
    }
  }

  async function resizeImageFn(file) {
    // console.log("originalFile instanceof Blob", file instanceof Blob) // true
    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options)
      // console.log(
      //   "compressedFile instanceof Blob",
      //   compressedFile instanceof Blob
      // ) // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

      // await uploadToServer(compressedFile) // write your own logic
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  function addRow() {
    setAdd_money_details([
      ...add_money_details,
      {
        key: add_money_details.length,
        add_money_id: formData.id,
        material_id: null,
        material_name: "กรุณาเลือกวัสดุ",
        material_unit: null,
        price: 0,
        qta: 0,
        price_vat: 0,
        qta_stock: 0,
        price_sum: 0,
        vat: 0,
        del: 1,
      },
    ])
  }
  function handleRemoveItem(item) {
    const temp = [...add_money_details]
    for (let index = 0; index < temp.length; index++) {
      if (temp[index]["key"] != undefined) {
        // console.log("exists", temp[index])
        if (temp[index]["key"] == item.key) {
          temp[index].del = 0
        }
      } else {
        if (temp[index].id == item.id) {
          temp[index].del = 0
        }
      }
    }
    setAdd_money_details(temp)
  }

  React.useEffect(() => {
    // console.log("materialShops", materialShops)
    if (!isEmpty(materialShops)) {
      let temp = []
      for (let index = 0; index < materialShops.length; index++) {
        temp.push({
          value: materialShops[index].id,
          label: materialShops[index].name,
          shop_bank_name: materialShops[index].bank,
          shop_bank_no: materialShops[index].bank_no,
          shop_vat_no: materialShops[index].vat_no,
          shop_address: materialShops[index].address,
        })
      }

      let newOption = [
        {
          value: 0,
          label: "เพิ่มใหม่",
        },
      ].concat(temp)
      setOptionSelectShops(newOption)
    } else {
      setOptionSelectShops([])
    }
  }, [materialShops])

  React.useEffect(() => {
    if (!isEmpty(users)) {
      let temp = []
      for (let index = 0; index < users.length; index++) {
        temp.push({
          value: users[index].id,
          label: users[index].name,
          employee_name: users[index].name + " " + users[index].lastname,
          employee_address: users[index].address,
          employee_bank_name: users[index].bank,
          employee_bank_no: users[index].bank_no,
          employee_id_card: users[index].card_number,

          money_per_month: users[index].money,
        })
      }
      setOptionSelectUsers(temp)
    } else {
      setOptionSelectUsers([])
    }
  }, [users])

  React.useEffect(() => {
    // console.log(boqTitleHomes);
    if (!isEmpty(boqTitleHomes)) {
      let temp = []
      let data = boqTitleHomes.boqTitleHomes
      for (let index = 0; index < data.length; index++) {
        temp.push({
          value: data[index].id,
          label:
            isEmpty(data[index].quotation_list_detail) == true
              ? data[index].quotation_list_name
              : data[index].quotation_list_name +
                "(" +
                data[index].quotation_list_detail +
                ")",
        })
      }
      setOptionSelectWorks(temp)
    } else {
      setOptionSelectWorks([])
    }
  }, [boqTitleHomes])

  React.useEffect(() => {
    // console.log("boqTitleHomeProfile", boqTitleHomeProfile)
    if (!isEmpty(boqTitleHomeProfile)) {
      let temp = []
      let data = boqTitleHomeProfile.boq_title_detail
      for (let index = 0; index < data.length; index++) {
        temp.push({
          value: data[index].id,
          label: data[index].list_structure_name,
          list_structure_price: data[index].list_structure_price,
          list_structure_pay: data[index].list_structure_pay,
          contractor_id: boqTitleHomeProfile.contractor_id,
          contractor_address: boqTitleHomeProfile.address_contractor,
          contractor_name: boqTitleHomeProfile.name_contractor,
          bank_contractor: boqTitleHomeProfile.bank_contractor,
          bank_no_contractor: boqTitleHomeProfile.bank_no_contractor,
          contractor_id_card: boqTitleHomeProfile.idcard_contractor,
        })
      }
      setOptionSelectWorkSubs(temp)
    } else {
      setOptionSelectWorkSubs([])
    }
  }, [boqTitleHomeProfile])

  React.useEffect(() => {
    if (!isEmpty(projects)) {
      let temp = []
      for (let index = 0; index < projects.length; index++) {
        temp.push({
          value: projects[index].id,
          label: projects[index].name,
        })
      }
      let newOption = [
        {
          value: 0,
          label: "อื่น ๆ",
        },
      ].concat(temp)

      setOptionSelectProjects(newOption)
    } else {
      setOptionSelectProjects([])
    }
  }, [projects])

  React.useEffect(() => {
    // console.log("contractors", contractors)
    if (!isEmpty(contractors)) {
      let temp = []
      for (let index = 0; index < contractors.length; index++) {
        temp.push({
          value: contractors[index].id,
          label: contractors[index].name + " " + contractors[index].lastname,
          contractor_bank_name: contractors[index].bank,
          contractor_bank_no: contractors[index].bank_no,
          contractor_id_card: contractors[index].card_number,
        })
      }
      setOptionSelectContractors(temp)
    } else {
      setOptionSelectContractors([])
    }
  }, [contractors])

  const selectRow = {
    mode: "radio",
    clickToSelect: false,
    // hideSelectAll: true,
    selectionHeaderRenderer: () => "เลือก",
    headerColumnStyle: {
      width: 80,
    },
    selectionRenderer: ({ mode, ...rest }) => <input type={mode} {...rest} />,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        // console.log(row)
        let temp = [...add_money_details]
        temp[boqMaterials_index].add_money_id = formData.id
        temp[boqMaterials_index].material_id = row.id
        temp[boqMaterials_index].material_name = row.name
        temp[boqMaterials_index].material_unit = row.unit
        temp[boqMaterials_index].price = row.price
        temp[boqMaterials_index].qta = 1
        temp[boqMaterials_index].discount = 0
        temp[boqMaterials_index].qta_stock = 0
        temp[boqMaterials_index].price_sum = parseFloat(
          parseFloat(1 * parseInt(row.price)) +
            parseFloat(parseFloat(1 * parseInt(row.price)) * 0.07)
        )
        temp[boqMaterials_index].vat =
          parseFloat(1 * parseInt(row.price)) * 0.07
        temp[boqMaterials_index].del = 1
        setAdd_money_details(temp)

        setTimeout(() => {
          let money = 0
          let vat = 0

          for (let index = 0; index < temp.length; index++) {
            money += parseFloat(temp[index].price_sum)
            vat += parseFloat(temp[index].vat)
          }
          setFormData({
            ...formData,
            money: parseFloat(money) + parseFloat(vat),
            vat: vat,
          })
        }, 1000)

        setModal_materialStock(!modal_materialStock)
        setBoqMaterials_index(false)
      } else {
        setAdd_money_details(
          selectMaterials.filter(x =>
            x.id == null ? x.key !== row.key : x.id !== row.id
          )
        )
      }
    },
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const customStyles = {
    control: provided => ({
      ...provided,
      borderColor: "orange !important",
      boxShadow: "none", // no box-shadow
    }),
  }

  return (
    <>
      <ExampleModalShop actionModal={actionModalShop} />
      <ExampleModalMaterialStock actionModal={actionModalMaterialStock} />

      <Modal
        size="xl"
        centered
        isOpen={modal_materialStock}
        toggle={() => {
          setModal_materialStock(!modal_materialStock)
        }}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={tableMaterialStockColumns}
                data={tableDataMaterialStock}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={tableMaterialStockColumns}
                    data={tableDataMaterialStock}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="12" className="mb-2">
                            <Row>
                              <Col sm="6" style={{ textAlign: "left" }}>
                                <Select
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name="material_type_id"
                                  value={groups.filter(function (option) {
                                    return (
                                      option.value == material_type_id || ""
                                    )
                                  })}
                                  onChange={e => setMaterial_type_id(e.value)}
                                  options={groups.map((item, i) => ({
                                    value: item.value,
                                    label: item.label,
                                  }))}
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col sm="8">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="text-sm-end">
                              <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={() =>
                                  eventActionMaterialStock("Add", null, true)
                                }
                              >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                เพิ่มวัสดุใหม่
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered
                                striped
                                selectRow={selectRow}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModal_materialStock(!modal_materialStock)
            }}
            className="btn btn-danger "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        centered={true}
        size="lg"
        style={{ maxWidth: "90%" }}
      >
        <ModalHeader toggle={() => setModal(!modal)}>
          {action == "Edit" ? "แก้ไข" : "เพิ่ม"}หลักฐานการเงิน{" "}
        </ModalHeader>
        <AvForm onSubmit={handleSubmit} model={formData}>
          {action == "Add" ? (
            <>
              <ModalBody>
                <div ref={ref} style={styleCapture}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Row>
                        <Col xs={6} sm={6} md={6} lg={6} className="mb-2"></Col>

                        <Col xs={6} sm={6} md={6} lg={6} className="mb-2">
                          <AvField
                            value={formData.date_add}
                            placeholder="วันที่โอน"
                            onChange={e =>
                              setFormData({
                                ...formData,
                                date_add: e.target.value,
                              })
                            }
                            validate={{
                              required: {
                                value: formData.del == 0 ? false : true,
                              },
                            }}
                            name="date_add"
                            type="date"
                          />
                        </Col>
                      </Row>

                      {formData.type == undefined ? null : (
                        <Row className="mt-1">
                          {formData.type == 2 ? (
                            <>
                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                className="mt-1"
                              >
                                <Select
                                  styles={customStyles}
                                  name="project_id"
                                  isSearchable
                                  value={optionSelectProjects.filter(
                                    option =>
                                      option.value == formData.project_id
                                  )}
                                  onChange={e =>
                                    change_project(e.value, e.label)
                                  }
                                  placeholder="โปรเจค"
                                  options={optionSelectProjects}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>

                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Select
                                  styles={customStyles}
                                  isSearchable={false}
                                  name="boq_title_id"
                                  value={optionSelectWorks.filter(
                                    option =>
                                      option.value == formData.boq_title_id
                                  )}
                                  onChange={e => change_boq_title_id(e.value)}
                                  placeholder="หมวดงานหลัก"
                                  options={optionSelectWorks}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>

                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Select
                                  styles={customStyles}
                                  isSearchable={false}
                                  name="boq_title_detail_id"
                                  value={optionSelectWorkSubs.filter(
                                    option =>
                                      option.value ==
                                      formData.boq_title_detail_id
                                  )}
                                  onChange={e => {
                                    // console.log("e", e)

                                    setFormData({
                                      ...formData,
                                      boq_title_detail_id: e.value,
                                      contractor_id: e.contractor_id,
                                      contractor_bank_name: e.bank_contractor,
                                      contractor_name: e.contractor_name,
                                      contractor_address: e.contractor_address,
                                      contractor_bank_no: e.bank_no_contractor,
                                      contractor_id_card: e.contractor_id_card,
                                      money_total_wages: e.list_structure_price,
                                      money_total_pay: e.list_structure_pay,
                                      money_total_balance:
                                        parseInt(e.list_structure_price) -
                                        parseInt(e.list_structure_pay),
                                      money:
                                        parseInt(e.list_structure_price) -
                                        parseInt(e.list_structure_pay),
                                      money_after: parseFloat(
                                        parseFloat(
                                          e.list_structure_price -
                                            e.list_structure_pay
                                        ) -
                                          parseFloat(
                                            parseFloat(
                                              (e.list_structure_price -
                                                e.list_structure_pay) *
                                                0.03
                                            ) * 100
                                          ) /
                                            100
                                      ),
                                      vat:
                                        parseFloat(
                                          parseFloat(
                                            (parseInt(e.list_structure_price) -
                                              parseInt(e.list_structure_pay)) *
                                              0.03
                                          ) * 100
                                        ) / 100,

                                      comment: "เบิกค่าแรง" + e.label,
                                    })
                                    // setList_structure_price(
                                    //   e.list_structure_price
                                    // )
                                    // setList_structure_pay(e.list_structure_pay)
                                  }}
                                  placeholder="หมวดงานย่อย"
                                  options={optionSelectWorkSubs}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>

                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Select
                                  isDisabled
                                  name="contractor_id"
                                  value={optionSelectContractors.filter(
                                    option =>
                                      option.value == formData.contractor_id
                                  )}
                                  onChange={e =>
                                    setFormData({
                                      ...formData,
                                      contractor_id: e.value,
                                      contractor_bank_name:
                                        e.contractor_bank_name,
                                      contractor_name: e.label,
                                      contractor_bank_no: e.contractor_bank_no,
                                      contractor_id_card: e.contractor_id_card,
                                    })
                                  }
                                  placeholder="ช่าง"
                                  options={optionSelectContractors}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6}>
                                <FormGroup className="mb-3">
                                  <AvField
                                    name="contractor_bank_name"
                                    type="text"
                                    readOnly
                                    placeholder="ธนาคาร"
                                    value={formData.contractor_bank_name}
                                    validate={{
                                      required: { value: false },
                                    }}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        contractor_bank_name: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6}>
                                <FormGroup className="mb-3">
                                  <AvField
                                    name="contractor_bank_no"
                                    type="text"
                                    readOnly
                                    placeholder="เลขบัญชี"
                                    value={formData.contractor_bank_no}
                                    validate={{
                                      required: { value: false },
                                    }}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        contractor_bank_no: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          ) : null}

                          <Col
                            xs={6}
                            sm={6}
                            md={4}
                            lg={4}
                            className="mt-1 text-center"
                          >
                            <span>
                              ค่างานทั้งหมด:&nbsp;
                              <span style={{ color: "red" }}>
                                {parseFloat(
                                  formData.money_total_wages
                                ).toLocaleString("th-TH", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </span>
                              &nbsp; บ.
                            </span>
                          </Col>

                          <Col
                            xs={6}
                            sm={6}
                            md={4}
                            lg={4}
                            className="mt-1 text-center"
                          >
                            <span>
                              ค่างานรวมจ่ายก่อนหน้า:&nbsp;
                              <span style={{ color: "red" }}>
                                {parseFloat(
                                  formData.money_total_pay
                                ).toLocaleString("th-TH", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </span>
                              &nbsp; บ.
                            </span>
                          </Col>

                          <Col
                            xs={6}
                            sm={6}
                            md={4}
                            lg={4}
                            className="mt-1 text-center"
                          >
                            <span>
                              ค่างานคงเหลือ:&nbsp;
                              <span style={{ color: "red" }}>
                                {parseFloat(
                                  formData.money_total_balance
                                ).toLocaleString("th-TH", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </span>
                              &nbsp; บ.
                            </span>
                          </Col>

                          <hr />

                          {formData.type == 3 ? (
                            <>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Select
                                  isSearchable={false}
                                  styles={customStyles}
                                  name="pay_id"
                                  value={optionSelectPays.filter(
                                    option => option.value == formData.pay_id
                                  )}
                                  onChange={e =>
                                    setFormData({
                                      ...formData,
                                      pay_id: e.value,
                                    })
                                  }
                                  placeholder="ประเภทค่าใช้จ่าย"
                                  options={optionSelectPays}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Select
                                  styles={customStyles}
                                  name="shop_id"
                                  value={optionSelectShops.filter(
                                    option => option.value == formData.shop_id
                                  )}
                                  onChange={e => {
                                    // console.log(e)
                                    if (e.value == 0) {
                                      setActionModelShop({
                                        action: "Add",
                                        id: null,
                                        modal: true,
                                      })
                                    } else {
                                      setFormData({
                                        ...formData,
                                        shop_id: e.value,
                                        shop_name: e.label,
                                        shop_bank_name: e.shop_bank_name,
                                        shop_bank_no: e.shop_bank_no,
                                        shop_vat_no: e.shop_vat_no,
                                        shop_address: e.shop_address,
                                        comment:
                                          formData.pay_id == 1
                                            ? "ซื้อวัสดุร้าน" + e.label
                                            : formData.pay_id == 2
                                            ? "ค่าน้ำมัน" + e.label
                                            : "ค่าใช้จ่ายสำนักงาน" + e.label,
                                      })
                                    }
                                  }}
                                  placeholder="ร้านค้า"
                                  options={optionSelectShops}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6}>
                                <FormGroup className="mb-3">
                                  <AvField
                                    name="shop_bank_name"
                                    type="text"
                                    readOnly
                                    placeholder="ธนาคาร"
                                    value={formData.shop_bank_name}
                                    validate={{
                                      required: { value: false },
                                    }}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        shop_bank_name: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6}>
                                <FormGroup className="mb-3">
                                  <AvField
                                    name="shop_bank_no"
                                    type="text"
                                    readOnly
                                    placeholder="เลขบัญชี"
                                    value={formData.shop_bank_no}
                                    validate={{
                                      required: { value: false },
                                    }}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        shop_bank_no: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </>
                          ) : null}

                          <Col xs={12} sm={12} md={12} lg={4}>
                            <FormGroup>
                              <AvField
                                name="comment"
                                type="text"
                                style={{
                                  border:
                                    formData.type == 3
                                      ? "1px orange solid"
                                      : null,
                                }}
                                placeholder="เรื่อง"
                                value={formData.comment}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    comment: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={4}>
                            <FormGroup className="mb-3">
                              <AvField
                                name="money"
                                type="number"
                                style={{
                                  border: "1px orange solid",
                                }}
                                placeholder="จำนวนเงิน"
                                value={formData.money}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    money: e.target.value,
                                    money_after: parseFloat(
                                      parseFloat(e.target.value) -
                                        parseFloat(
                                          parseFloat(e.target.value * 0.03) *
                                            100
                                        ) /
                                          100
                                    ),
                                    vat:
                                      parseFloat(
                                        parseFloat(e.target.value * 0.03) * 100
                                      ) / 100,
                                  })
                                }
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={4}>
                            <FormGroup className="mb-3">
                              <AvField
                                name="vat"
                                type="number"
                                placeholder="หักภาษี ณ ที่จ่าย"
                                value={formData.vat}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    vat: e.target.value,
                                  })
                                }
                                validate={{
                                  required: { value: false },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup className="mb-3">
                              <AvField
                                name="note"
                                type="text"
                                placeholder="เพิ่มเติม"
                                value={formData.note}
                                validate={{
                                  required: { value: false },
                                }}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    note: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>

                          {formData.type == 2 ? (
                            <Col xs={12} sm={12} md={6} lg={6}>
                              <FormGroup className="mt-1">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioVat_type"
                                      checked={formData.vat_type == 1}
                                      value={1}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          vat_type: e.target.value,
                                          vat: 0,
                                        })
                                      }
                                    />
                                    ไม่หัก ณ ที่จ่าย
                                  </Label>
                                </FormGroup>

                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioVat_type"
                                      checked={formData.vat_type == 3}
                                      value={3}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          vat_type: e.target.value,
                                          vat:
                                            parseFloat(
                                              parseFloat(
                                                formData.money * 0.03
                                              ) * 100
                                            ) / 100,
                                        })
                                      }
                                    />
                                    หัก ณ ที่จ่าย
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>
                          ) : null}
                          <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            style={{ textAlign: "right" }}
                          >
                            <FormGroup className="mt-1">
                              <FormGroup check inline>
                                <Label>
                                  <Input
                                    type="radio"
                                    name="radioDel"
                                    checked={formData.del == 0}
                                    value={0}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        del: e.target.value,
                                      })
                                    }
                                  />
                                  <span style={{ color: "red" }}>ลบ</span>
                                </Label>
                              </FormGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                    </Col>

                    <hr />
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ textAlign: "center" }}
                    >
                      <h1 className="text-16">
                        จำนวนเงินที่ต้องโอน :&nbsp;
                        <span style={{ color: "red" }}>
                          {formData.vat_type == 1
                            ? formData.money
                            : formData.vat_type == 2
                            ? parseFloat(
                                parseFloat(formData.money) +
                                  parseFloat(formData.vat)
                              ).toLocaleString("th-TH", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : formData.vat_type == 3
                            ? parseFloat(
                                parseFloat(formData.money) -
                                  parseFloat(formData.vat)
                              ).toLocaleString("th-TH", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : null}
                        </span>
                        &nbsp; บาท
                      </h1>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                {formData.money_total_balance <= 0 ? (
                  <span style={{ color: "#DAA" }}>เบิกค่าแรงครบแล้ว</span>
                ) : (
                  <Button
                    type="submit"
                    disabled={buttonSubmit}
                    color="secondary"
                  >
                    Save
                  </Button>
                )}

                {/* {formData.del == 0 ? (
                <Button
                  type="submit"
                  disabled={buttonSubmit}
                  color="secondary"
                >
                  Save
                </Button>
              ) : isEmpty(formData.money) == true ? (
                <p style={{ color: "red", textAlign: "center" }}>
                  กรุณาใส่จำนวนเงิน
                </p>
              ) : (
                <Button
                  type="submit"
                  disabled={buttonSubmit}
                  onClick={downloadScreenshot}
                  color="secondary"
                >
                  Save
                </Button>
              )} */}
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalBody>
                <Row>
                  <Col
                    xs={formData.pay_id == 1 ? 12 : 12}
                    sm={formData.pay_id == 1 ? 12 : 12}
                    md={formData.pay_id == 1 ? 12 : 6}
                    lg={formData.pay_id == 1 ? 12 : 6}
                    style={{ textAlign: "center" }}
                  >
                    {img_slip == undefined ? null : (
                      <a
                        href={process.env.REACT_APP_SERVER + img_slip}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          // src={slip}
                          src={process.env.REACT_APP_SERVER + img_slip}
                          // className="img-responsive"
                          style={{
                            width: formData.pay_id == 1 ? "50%" : "100%",
                          }}
                          alt={process.env.REACT_APP_NAMECOMPANY}
                          title={process.env.REACT_APP_NAMECOMPANY}
                        />
                      </a>
                    )}
                  </Col>
                  <Col
                    xs={formData.pay_id == 1 ? 12 : 6}
                    sm={formData.pay_id == 1 ? 12 : 6}
                    md={formData.pay_id == 1 ? 12 : 6}
                    lg={formData.pay_id == 1 ? 12 : 6}
                  >
                    <div ref={ref} style={styleCapture}>
                      <Row>
                        <Col xs={6} sm={6} md={6} lg={6} className="mb-2"></Col>

                        <Col xs={6} sm={6} md={6} lg={6} className="mb-2">
                          <AvField
                            value={formData.date_add}
                            placeholder="วันที่โอน"
                            onChange={e =>
                              setFormData({
                                ...formData,
                                date_add: e.target.value,
                              })
                            }
                            validate={{
                              required: {
                                value: formData.del == 0 ? false : true,
                              },
                            }}
                            name="date_add"
                            type="date"
                          />
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} className="mb-2">
                          {actionModal.action == "Wage" ? null : (
                            <Select
                              styles={customStyles}
                              isSearchable={false}
                              name="selectUpload"
                              style={{
                                border: "1px orange solid",
                              }}
                              // value={selectUploadID }
                              value={optionSelect.filter(
                                option => option.value == formData.type
                              )}
                              onChange={e => {
                                setFormData({})
                                setTimeout(() => {
                                  // setFormData(actionModal.data)
                                  if (actionModal.action == "Edit") {
                                    setFormData({
                                      id: actionModal.data.id,
                                      date_add: actionModal.data.date_add,
                                      img_path: actionModal.data.img_path,
                                      discount: actionModal.data.discount,
                                      installments:
                                        actionModal.data.installments,
                                      type: e.value,
                                      vat_type:
                                        e.value == 2 ? 3 : e.value == 3 ? 2 : 1,
                                      vat_material_type: e.value == 3 ? 2 : 0,
                                      project_id: actionModal.data.project_id,
                                      pay_type: 1,
                                      money: 0,
                                      vat: 0,
                                      check: 0,
                                    })
                                  } else {
                                    setFormData({
                                      date_add: moment().format("YYYY-MM-DD"),
                                      discount: 0,
                                      installments: 1,
                                      type: e.value,
                                      vat_type:
                                        e.value == 2 ? 3 : e.value == 3 ? 2 : 1,
                                      vat_material_type: e.value == 3 ? 2 : 0,
                                      project_id: null,
                                      pay_type: 1,
                                      money: 0,
                                      vat: 0,
                                      check: 0,
                                    })
                                  }
                                }, 1000)
                              }}
                              placeholder="โปรดเลือก"
                              options={optionSelect}
                              classNamePrefix="select2-selection"
                            />
                          )}
                        </Col>
                      </Row>

                      {formData.type == undefined ||
                      formData.type == 0 ? null : formData.type == 1 ? (
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup>
                              <AvField
                                name="comment"
                                type="text"
                                style={{
                                  border:
                                    formData.type == 3
                                      ? "1px orange solid"
                                      : null,
                                }}
                                placeholder="เรื่อง"
                                value={formData.comment}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    comment: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup>
                              <AvField
                                name="note"
                                type="text"
                                placeholder="เพิ่มเติม"
                                value={formData.note}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    note: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: false,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup>
                              <AvField
                                name="no"
                                type="text"
                                style={{
                                  border: "1px orange solid",
                                }}
                                placeholder="เลขอ้างอิง (5 ตัวหลัง)"
                                value={formData.no}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    no: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup>
                              <AvField
                                name="money"
                                type="number"
                                placeholder="จำนวนเงิน"
                                style={{
                                  border: "1px orange solid",
                                }}
                                value={formData.money}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    money: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : formData.type == 2 ? (
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12} className="mt-1">
                            <Select
                              styles={customStyles}
                              name="project_id"
                              isSearchable
                              value={optionSelectProjects.filter(
                                option => option.value == formData.project_id
                              )}
                              onChange={e => change_project(e.value, e.label)}
                              placeholder="โปรเจค"
                              options={optionSelectProjects}
                              classNamePrefix="select2-selection"
                            />
                          </Col>
                          {formData.project_id == 0 ? null : (
                            <>
                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                className="mt-1"
                              >
                                <Select
                                  styles={customStyles}
                                  isSearchable={false}
                                  name="boq_title_id"
                                  value={optionSelectWorks.filter(
                                    option =>
                                      option.value == formData.boq_title_id
                                  )}
                                  onChange={e => change_boq_title_id(e.value)}
                                  placeholder="หมวดงานหลัก"
                                  options={optionSelectWorks}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>

                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                className="mt-1"
                              >
                                <Select
                                  styles={customStyles}
                                  isSearchable={false}
                                  name="boq_title_detail_id"
                                  value={optionSelectWorkSubs.filter(
                                    option =>
                                      option.value ==
                                      formData.boq_title_detail_id
                                  )}
                                  onChange={e => {
                                    // console.log("e", e)
                                    setFormData({
                                      ...formData,
                                      boq_title_detail_id: e.value,
                                      contractor_id: e.contractor_id,
                                      contractor_name: e.contractor_name,
                                      contractor_address: e.contractor_address,
                                      contractor_bank_name: e.bank_contractor,
                                      contractor_bank_no: e.bank_no_contractor,
                                      contractor_id_card: e.contractor_id_card,
                                      money_total_wages: e.list_structure_price,
                                      money_total_pay: e.list_structure_pay,
                                      money_total_balance:
                                        parseInt(e.list_structure_price) -
                                        parseInt(e.list_structure_pay),
                                      money:
                                        parseInt(e.list_structure_price) -
                                        parseInt(e.list_structure_pay),
                                      money_after: parseFloat(
                                        parseFloat(
                                          e.list_structure_price -
                                            e.list_structure_pay
                                        ) -
                                          parseFloat(
                                            parseFloat(
                                              (e.list_structure_price -
                                                e.list_structure_pay) *
                                                0.03
                                            ) * 100
                                          ) /
                                            100
                                      ),
                                      vat:
                                        parseFloat(
                                          parseFloat(
                                            (parseInt(e.list_structure_price) -
                                              parseInt(e.list_structure_pay)) *
                                              0.03
                                          ) * 100
                                        ) / 100,
                                      comment: "เบิกค่าแรง" + e.label,
                                    })
                                    // setList_structure_price(
                                    //   e.list_structure_price
                                    // )
                                    // setList_structure_pay(e.list_structure_pay)
                                  }}
                                  placeholder="หมวดงานย่อย"
                                  options={optionSelectWorkSubs}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                            </>
                          )}

                          <Col xs={12} sm={12} md={12} lg={12} className="mt-1">
                            <Select
                              isDisabled={
                                formData.project_id == 0 ? false : true
                              }
                              isSearchable={false}
                              name="contractor_id"
                              value={optionSelectContractors.filter(
                                option => option.value == formData.contractor_id
                              )}
                              onChange={e =>
                                setFormData({
                                  ...formData,
                                  contractor_id: e.value,
                                  contractor_name: e.label,
                                  contractor_bank_name: e.contractor_bank_name,
                                  contractor_bank_no: e.contractor_bank_no,
                                  contractor_id_card: e.contractor_id_card,
                                })
                              }
                              placeholder="ช่าง"
                              options={optionSelectContractors}
                              classNamePrefix="select2-selection"
                            />
                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6}>
                            <FormGroup className="mb-3">
                              <AvField
                                name="contractor_bank_name"
                                type="text"
                                readOnly
                                placeholder="ธนาคาร"
                                value={formData.contractor_bank_name}
                                validate={{
                                  required: { value: false },
                                }}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    contractor_bank_name: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6}>
                            <FormGroup className="mb-3">
                              <AvField
                                name="contractor_bank_no"
                                type="text"
                                readOnly
                                placeholder="เลขบัญชี"
                                value={formData.contractor_bank_no}
                                validate={{
                                  required: { value: false },
                                }}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    contractor_bank_no: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col
                            xs={6}
                            sm={6}
                            md={4}
                            lg={4}
                            className="mt-1 text-center"
                          >
                            <span>
                              ค่างานทั้งหมด:&nbsp;
                              <span style={{ color: "red" }}>
                                {parseFloat(
                                  formData.money_total_wages
                                ).toLocaleString("th-TH", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </span>
                              &nbsp; บ.
                            </span>
                          </Col>

                          <Col
                            xs={6}
                            sm={6}
                            md={4}
                            lg={4}
                            className="mt-1 text-center"
                          >
                            <span>
                              ค่างานรวมจ่ายก่อนหน้า:&nbsp;
                              <span style={{ color: "red" }}>
                                {parseFloat(
                                  formData.money_total_pay
                                ).toLocaleString("th-TH", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </span>
                              &nbsp; บ.
                            </span>
                          </Col>

                          <Col
                            xs={6}
                            sm={6}
                            md={4}
                            lg={4}
                            className="mt-1 text-center"
                          >
                            <span>
                              ค่างานคงเหลือ:&nbsp;
                              <span style={{ color: "red" }}>
                                {parseFloat(
                                  formData.money_total_balance
                                ).toLocaleString("th-TH", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </span>
                              &nbsp; บ.
                            </span>
                          </Col>

                          <hr />
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <p>{formData.comment}</p>
                            {/* <FormGroup>
                          <AvField
                            name="comment"
                            type="text"
                            style={{
                              border: "1px orange solid",
                            }}
                            placeholder="เรื่อง"
                            value={formData.comment}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                comment: e.target.value,
                              })
                            }
                            validate={{
                              required: {
                                value: true,
                              },
                            }}
                          />
                        </FormGroup> */}
                          </Col>

                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup>
                              <AvField
                                style={{
                                  border: "1px orange solid",
                                }}
                                name="no"
                                type="text"
                                placeholder="เลขอ้างอิง (5 ตัวหลัง)"
                                value={formData.no}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    no: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: formData.del == 0 ? false : true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup>
                              <AvField
                                name="note"
                                type="text"
                                placeholder="ใส่รายละเอียดย่อย (ค่าแรงแยก)"
                                value={formData.note}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    note: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: false,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup className="mb-3">
                              <Label>จำนวนเบิก </Label>

                              <AvField
                                name="money"
                                type="number"
                                style={{
                                  border: "1px orange solid",
                                }}
                                placeholder="จำนวนเงิน"
                                value={formData.money}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    money: e.target.value,
                                    money_after: parseFloat(
                                      parseFloat(e.target.value) -
                                        parseFloat(
                                          parseFloat(e.target.value * 0.03) *
                                            100
                                        ) /
                                          100
                                    ),
                                    vat:
                                      parseFloat(
                                        parseFloat(e.target.value * 0.03) * 100
                                      ) / 100,
                                  })
                                }
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup className="mb-3">
                              <Label>หัก ณ ที่จ่าย</Label>
                              <AvField
                                name="vat"
                                type="number"
                                placeholder="ค่า vat"
                                value={formData.vat}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    vat: e.target.value,
                                  })
                                }
                                validate={{
                                  required: { value: false },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : formData.type == 3 ? (
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12} className="mt-1">
                            {hideUploadImg == true ? null : (
                              <Dropzone
                                multiple={false}
                                accept="image/*"
                                inputContent={(files, extra) =>
                                  extra.reject
                                    ? "Image files only"
                                    : "Drag Files"
                                }
                                onDrop={acceptedFiles => {
                                  handleAcceptedFiles(acceptedFiles)
                                }}
                                styles={{
                                  dropzoneReject: {
                                    borderColor: "red",
                                    backgroundColor: "#DAA",
                                  },
                                  inputLabel: (files, extra) =>
                                    extra.reject ? { color: "red" } : {},
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="dz-message needsclick">
                                        <div className="mb-3">
                                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>
                                          เลือกไฟล์หรือลากไฟล์มาตรงนี้เพื่ออัพโหลดรูป
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            )}

                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                          </Col>

                          <Col xs={12} sm={12} md={12} lg={12} className="mt-1">
                            <Select
                              styles={customStyles}
                              isSearchable={false}
                              name="pay_id"
                              value={optionSelectPays.filter(
                                option => option.value == formData.pay_id
                              )}
                              onChange={e =>
                                setFormData({
                                  ...formData,
                                  pay_id: e.value,
                                })
                              }
                              placeholder="ประเภทค่าใช้จ่าย"
                              options={optionSelectPays}
                              classNamePrefix="select2-selection"
                            />
                          </Col>

                          <Col xs={12} sm={12} md={12} lg={12}>
                            <Select
                              styles={customStyles}
                              name="shop_id"
                              value={optionSelectShops.filter(
                                option => option.value == formData.shop_id
                              )}
                              onChange={e => {
                                if (e.value == 0) {
                                  setActionModelShop({
                                    action: "Add",
                                    id: null,
                                    modal: true,
                                  })
                                } else {
                                  // console.log(e)
                                  setFormData({
                                    ...formData,
                                    shop_id: e.value,
                                    shop_name: e.label,
                                    shop_bank_name: e.shop_bank_name,
                                    shop_bank_no: e.shop_bank_no,
                                    shop_vat_no: e.shop_vat_no,
                                    shop_address: e.shop_address,
                                    comment:
                                      formData.pay_id == 1
                                        ? "ซื้อวัสดุร้าน" + e.label
                                        : formData.pay_id == 2
                                        ? "ค่าน้ำมัน" + e.label
                                        : "ค่าใช้จ่ายสำนักงาน" + e.label,
                                  })
                                }
                              }}
                              placeholder="ร้านค้า"
                              options={optionSelectShops}
                              classNamePrefix="select2-selection"
                            />
                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6}>
                            <FormGroup className="mb-3">
                              <AvField
                                name="shop_bank_name"
                                type="text"
                                readOnly
                                placeholder="ธนาคาร"
                                value={formData.shop_bank_name}
                                validate={{
                                  required: { value: false },
                                }}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    shop_bank_name: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6}>
                            <FormGroup className="mb-3">
                              <AvField
                                name="shop_bank_no"
                                type="text"
                                readOnly
                                placeholder="เลขบัญชี"
                                value={formData.shop_bank_no}
                                validate={{
                                  required: { value: false },
                                }}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    shop_bank_no: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup>
                              <AvField
                                name="comment"
                                type="text"
                                style={{
                                  border: "1px orange solid",
                                }}
                                placeholder="เรื่อง"
                                value={formData.comment}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    comment: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup>
                              <AvField
                                style={{
                                  border: "1px orange solid",
                                }}
                                name="no"
                                type="text"
                                placeholder="เลขอ้างอิง (5 ตัวหลัง)"
                                value={formData.no}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    no: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: formData.del == 0 ? false : true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup>
                              <AvField
                                name="note"
                                type="text"
                                style={{
                                  border: "1px orange solid",
                                }}
                                placeholder="เลขที่ใบเสร็จ"
                                value={formData.note}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    note: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          {formData.type == 3 && formData.pay_id == 1 ? (
                            <div>
                              <hr />

                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                className="mt-1"
                              >
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <Table size="lg" responsive>
                                    <thead>
                                      <tr>
                                        <th style={{ width: "17%" }}>รายการ</th>
                                        <th style={{ width: "12%" }}>หน่วย</th>
                                        <th style={{ width: "10%" }}>จำนวน</th>
                                        <th style={{ width: "13%" }}>
                                          ราคา/หน่วย
                                        </th>
                                        <th style={{ width: "10%" }}>
                                          vat/หน่วย
                                        </th>

                                        <th style={{ width: "10%" }}>ส่วนลด</th>

                                        <th style={{ width: "10%" }}>
                                          ราคารวมสินค้า
                                        </th>
                                        <th style={{ width: "8%" }}>Vat</th>
                                        <th style={{ width: "10%" }}></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {add_money_details.map((item, index) =>
                                        item.del == 0 ? null : (
                                          <tr key={index}>
                                            <td>
                                              <Button
                                                className="form-control"
                                                color="info"
                                                outline
                                                onClick={e => {
                                                  setBoqMaterials_index(index)
                                                  setModal_materialStock(
                                                    !modal_materialStock
                                                  )
                                                }}
                                              >
                                                {item.material_name}
                                              </Button>
                                            </td>

                                            <td>
                                              <AvField
                                                name={"material_unit" + [index]}
                                                type="text"
                                                placeholder="หน่วย"
                                                value={item.material_unit}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={e => {
                                                  let modifiedRows = [
                                                    ...add_money_details,
                                                  ]
                                                  modifiedRows[
                                                    index
                                                  ].material_unit =
                                                    e.target.value
                                                  setAdd_money_details(
                                                    modifiedRows
                                                  )
                                                }}
                                              />
                                            </td>

                                            <td>
                                              <AvField
                                                name={"qta" + [index]}
                                                type="number"
                                                placeholder="จำนวน"
                                                value={item.qta}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={e => {
                                                  let modifiedRows = [
                                                    ...add_money_details,
                                                  ]
                                                  modifiedRows[index].qta =
                                                    e.target.value
                                                  modifiedRows[
                                                    index
                                                  ].price_vat =
                                                    formData.vat_material_type ==
                                                    1
                                                      ? Math.floor(
                                                          parseFloat(
                                                            modifiedRows[index]
                                                              .price +
                                                              parseFloat(
                                                                modifiedRows[
                                                                  index
                                                                ].price * 0.07
                                                              )
                                                          ) * 100
                                                        ) / 100
                                                      : Math.floor(
                                                          parseFloat(
                                                            parseFloat(
                                                              modifiedRows[
                                                                index
                                                              ].price * 100
                                                            ) / 107
                                                          ) * 100
                                                        ) / 100

                                                  modifiedRows[
                                                    index
                                                  ].price_sum =
                                                    Math.floor(
                                                      parseFloat(
                                                        parseFloat(
                                                          modifiedRows[index]
                                                            .price_vat *
                                                            modifiedRows[index]
                                                              .qta
                                                        ) -
                                                          parseFloat(
                                                            modifiedRows[index]
                                                              .discount
                                                          )
                                                      ) * 100
                                                    ) / 100
                                                  modifiedRows[index].vat =
                                                    formData.vat_material_type ==
                                                    1
                                                      ? Math.floor(
                                                          parseFloat(
                                                            parseFloat(
                                                              parseFloat(
                                                                modifiedRows[
                                                                  index
                                                                ].price_vat -
                                                                  modifiedRows[
                                                                    index
                                                                  ].price
                                                              )
                                                            ) *
                                                              modifiedRows[
                                                                index
                                                              ].qta
                                                          ) * 100
                                                        ) / 100
                                                      : Math.floor(
                                                          parseFloat(
                                                            parseFloat(
                                                              modifiedRows[
                                                                index
                                                              ].price_vat *
                                                                modifiedRows[
                                                                  index
                                                                ].qta
                                                            ) * 0.07
                                                          ) * 100
                                                        ) / 100

                                                  setAdd_money_details(
                                                    modifiedRows
                                                  )
                                                  setFormData({
                                                    ...formData,
                                                    money:
                                                      parseFloat(
                                                        formData.vat_material_type ==
                                                          1
                                                          ? add_money_details.reduce(
                                                              function (a, b) {
                                                                return (
                                                                  a +
                                                                  parseFloat(
                                                                    b.price_sum *
                                                                      b.qta
                                                                  )
                                                                )
                                                              },
                                                              0
                                                            )
                                                          : add_money_details.reduce(
                                                              function (a, b) {
                                                                return (
                                                                  a +
                                                                  parseFloat(
                                                                    b.price *
                                                                      b.qta
                                                                  )
                                                                )
                                                              },
                                                              0
                                                            )
                                                      ) - formData.discount,

                                                    money_after:
                                                      formData.vat_material_type ==
                                                      1
                                                        ? parseFloat(
                                                            parseFloat(
                                                              formData.vat_material_type ==
                                                                1
                                                                ? add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price_sum *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                                : add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                            ) -
                                                              formData.discount
                                                          ) +
                                                          add_money_details.reduce(
                                                            function (a, b) {
                                                              return (
                                                                a +
                                                                parseFloat(
                                                                  b.vat
                                                                )
                                                              )
                                                            },
                                                            0
                                                          )
                                                        : parseFloat(
                                                            parseFloat(
                                                              formData.vat_material_type ==
                                                                1
                                                                ? add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price_sum *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                                : add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                            ) -
                                                              formData.discount
                                                          ) -
                                                          add_money_details.reduce(
                                                            function (a, b) {
                                                              return (
                                                                a +
                                                                parseFloat(
                                                                  b.vat
                                                                )
                                                              )
                                                            },
                                                            0
                                                          ),
                                                    vat: add_money_details.reduce(
                                                      function (a, b) {
                                                        return (
                                                          a + parseFloat(b.vat)
                                                        )
                                                      },
                                                      0
                                                    ),
                                                    discount:
                                                      add_money_details.reduce(
                                                        function (a, b) {
                                                          return (
                                                            a +
                                                            parseFloat(
                                                              b.discount
                                                            )
                                                          )
                                                        },
                                                        0
                                                      ),
                                                    vat: add_money_details.reduce(
                                                      function (a, b) {
                                                        return (
                                                          a + parseFloat(b.vat)
                                                        )
                                                      },
                                                      0
                                                    ),
                                                  })
                                                }}
                                              />
                                            </td>

                                            <td>
                                              <AvField
                                                name={"price" + [index]}
                                                type="text"
                                                placeholder="ราคา"
                                                value={item.price}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={e => {
                                                  let modifiedRows = [
                                                    ...add_money_details,
                                                  ]
                                                  modifiedRows[index].price =
                                                    e.target.value
                                                  modifiedRows[
                                                    index
                                                  ].price_vat =
                                                    formData.vat_material_type ==
                                                    1
                                                      ? Math.floor(
                                                          parseFloat(
                                                            modifiedRows[index]
                                                              .price +
                                                              parseFloat(
                                                                modifiedRows[
                                                                  index
                                                                ].price * 0.07
                                                              )
                                                          ) * 100
                                                        ) / 100
                                                      : Math.floor(
                                                          parseFloat(
                                                            parseFloat(
                                                              modifiedRows[
                                                                index
                                                              ].price * 100
                                                            ) / 107
                                                          ) * 100
                                                        ) / 100

                                                  modifiedRows[
                                                    index
                                                  ].price_sum =
                                                    Math.floor(
                                                      parseFloat(
                                                        parseFloat(
                                                          modifiedRows[index]
                                                            .price_vat *
                                                            modifiedRows[index]
                                                              .qta
                                                        ) -
                                                          parseFloat(
                                                            modifiedRows[index]
                                                              .discount
                                                          )
                                                      ) * 100
                                                    ) / 100
                                                  modifiedRows[index].vat =
                                                    formData.vat_material_type ==
                                                    1
                                                      ? Math.floor(
                                                          parseFloat(
                                                            parseFloat(
                                                              parseFloat(
                                                                modifiedRows[
                                                                  index
                                                                ].price_vat -
                                                                  modifiedRows[
                                                                    index
                                                                  ].price
                                                              )
                                                            ) *
                                                              modifiedRows[
                                                                index
                                                              ].qta
                                                          ) * 100
                                                        ) / 100
                                                      : Math.floor(
                                                          parseFloat(
                                                            parseFloat(
                                                              modifiedRows[
                                                                index
                                                              ].price_vat *
                                                                modifiedRows[
                                                                  index
                                                                ].qta
                                                            ) * 0.07
                                                          ) * 100
                                                        ) / 100

                                                  setAdd_money_details(
                                                    modifiedRows
                                                  )
                                                  setFormData({
                                                    ...formData,
                                                    money:
                                                      parseFloat(
                                                        formData.vat_material_type ==
                                                          1
                                                          ? add_money_details.reduce(
                                                              function (a, b) {
                                                                return (
                                                                  a +
                                                                  parseFloat(
                                                                    b.price_sum *
                                                                      b.qta
                                                                  )
                                                                )
                                                              },
                                                              0
                                                            )
                                                          : add_money_details.reduce(
                                                              function (a, b) {
                                                                return (
                                                                  a +
                                                                  parseFloat(
                                                                    b.price *
                                                                      b.qta
                                                                  )
                                                                )
                                                              },
                                                              0
                                                            )
                                                      ) - formData.discount,
                                                    discount:
                                                      add_money_details.reduce(
                                                        function (a, b) {
                                                          return (
                                                            a +
                                                            parseFloat(
                                                              b.discount
                                                            )
                                                          )
                                                        },
                                                        0
                                                      ),
                                                    money_after:
                                                      formData.vat_material_type ==
                                                      1
                                                        ? parseFloat(
                                                            parseFloat(
                                                              formData.vat_material_type ==
                                                                1
                                                                ? add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price_sum *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                                : add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                            ) -
                                                              formData.discount
                                                          ) +
                                                          add_money_details.reduce(
                                                            function (a, b) {
                                                              return (
                                                                a +
                                                                parseFloat(
                                                                  b.vat
                                                                )
                                                              )
                                                            },
                                                            0
                                                          )
                                                        : parseFloat(
                                                            parseFloat(
                                                              formData.vat_material_type ==
                                                                1
                                                                ? add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price_sum *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                                : add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                            ) -
                                                              formData.discount
                                                          ) -
                                                          add_money_details.reduce(
                                                            function (a, b) {
                                                              return (
                                                                a +
                                                                parseFloat(
                                                                  b.vat
                                                                )
                                                              )
                                                            },
                                                            0
                                                          ),
                                                    vat: add_money_details.reduce(
                                                      function (a, b) {
                                                        return (
                                                          a + parseFloat(b.vat)
                                                        )
                                                      },
                                                      0
                                                    ),
                                                  })
                                                }}
                                              />
                                            </td>

                                            <td>
                                              <p
                                                className="av-valid form-control"
                                                style={{
                                                  background:
                                                    "rgb(0 0 0 / 10%)",
                                                }}
                                              >
                                                {parseFloat(
                                                  item.price_vat
                                                ).toLocaleString("th-TH")}
                                              </p>
                                            </td>

                                            <td>
                                              <AvField
                                                name={"discount" + [index]}
                                                type="number"
                                                placeholder="ส่วนลด"
                                                defualt
                                                value={item.discount}
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                onChange={e => {
                                                  let modifiedRows = [
                                                    ...add_money_details,
                                                  ]
                                                  modifiedRows[index].discount =
                                                    e.target.value
                                                  modifiedRows[
                                                    index
                                                  ].price_vat =
                                                    formData.vat_material_type ==
                                                    1
                                                      ? Math.floor(
                                                          parseFloat(
                                                            modifiedRows[index]
                                                              .price +
                                                              parseFloat(
                                                                modifiedRows[
                                                                  index
                                                                ].price * 0.07
                                                              )
                                                          ) * 100
                                                        ) / 100
                                                      : Math.floor(
                                                          parseFloat(
                                                            parseFloat(
                                                              modifiedRows[
                                                                index
                                                              ].price * 100
                                                            ) / 107
                                                          ) * 100
                                                        ) / 100

                                                  modifiedRows[
                                                    index
                                                  ].price_sum =
                                                    Math.floor(
                                                      parseFloat(
                                                        parseFloat(
                                                          modifiedRows[index]
                                                            .price_vat *
                                                            modifiedRows[index]
                                                              .qta
                                                        ) -
                                                          parseFloat(
                                                            modifiedRows[index]
                                                              .discount
                                                          )
                                                      ) * 100
                                                    ) / 100
                                                  modifiedRows[index].vat =
                                                    formData.vat_material_type ==
                                                    1
                                                      ? Math.floor(
                                                          parseFloat(
                                                            parseFloat(
                                                              parseFloat(
                                                                modifiedRows[
                                                                  index
                                                                ].price_vat -
                                                                  modifiedRows[
                                                                    index
                                                                  ].price
                                                              )
                                                            ) *
                                                              modifiedRows[
                                                                index
                                                              ].qta
                                                          ) * 100
                                                        ) / 100
                                                      : Math.floor(
                                                          parseFloat(
                                                            parseFloat(
                                                              modifiedRows[
                                                                index
                                                              ].price_vat *
                                                                modifiedRows[
                                                                  index
                                                                ].qta
                                                            ) * 0.07
                                                          ) * 100
                                                        ) / 100

                                                  setAdd_money_details(
                                                    modifiedRows
                                                  )
                                                  setFormData({
                                                    ...formData,
                                                    money:
                                                      parseFloat(
                                                        formData.vat_material_type ==
                                                          1
                                                          ? add_money_details.reduce(
                                                              function (a, b) {
                                                                return (
                                                                  a +
                                                                  parseFloat(
                                                                    b.price_sum *
                                                                      b.qta
                                                                  )
                                                                )
                                                              },
                                                              0
                                                            )
                                                          : add_money_details.reduce(
                                                              function (a, b) {
                                                                return (
                                                                  a +
                                                                  parseFloat(
                                                                    b.price *
                                                                      b.qta
                                                                  )
                                                                )
                                                              },
                                                              0
                                                            )
                                                      ) - formData.discount,
                                                    discount:
                                                      add_money_details.reduce(
                                                        function (a, b) {
                                                          return (
                                                            a +
                                                            parseFloat(
                                                              b.discount
                                                            )
                                                          )
                                                        },
                                                        0
                                                      ),
                                                    money_after:
                                                      formData.vat_material_type ==
                                                      1
                                                        ? parseFloat(
                                                            parseFloat(
                                                              formData.vat_material_type ==
                                                                1
                                                                ? add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price_sum *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                                : add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                            ) -
                                                              formData.discount
                                                          ) +
                                                          add_money_details.reduce(
                                                            function (a, b) {
                                                              return (
                                                                a +
                                                                parseFloat(
                                                                  b.vat
                                                                )
                                                              )
                                                            },
                                                            0
                                                          )
                                                        : parseFloat(
                                                            parseFloat(
                                                              formData.vat_material_type ==
                                                                1
                                                                ? add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price_sum *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                                : add_money_details.reduce(
                                                                    function (
                                                                      a,
                                                                      b
                                                                    ) {
                                                                      return (
                                                                        a +
                                                                        parseFloat(
                                                                          b.price *
                                                                            b.qta
                                                                        )
                                                                      )
                                                                    },
                                                                    0
                                                                  )
                                                            ) -
                                                              formData.discount
                                                          ) -
                                                          add_money_details.reduce(
                                                            function (a, b) {
                                                              return (
                                                                a +
                                                                parseFloat(
                                                                  b.vat
                                                                )
                                                              )
                                                            },
                                                            0
                                                          ),
                                                    vat: add_money_details.reduce(
                                                      function (a, b) {
                                                        return (
                                                          a + parseFloat(b.vat)
                                                        )
                                                      },
                                                      0
                                                    ),
                                                  })
                                                }}
                                              />
                                            </td>

                                            <td>
                                              <p
                                                className="av-valid form-control"
                                                style={{
                                                  background:
                                                    "rgb(0 0 0 / 10%)",
                                                }}
                                              >
                                                {parseFloat(
                                                  item.price_sum
                                                ).toLocaleString("th-TH")}
                                              </p>
                                            </td>

                                            <td>
                                              <AvField
                                                name={"vat" + [index]}
                                                type="number"
                                                placeholder="หักษี"
                                                value={item.vat}
                                                validate={{
                                                  required: { value: false },
                                                }}
                                                onChange={e => {
                                                  let modifiedRows = [
                                                    ...add_money_details,
                                                  ]
                                                  modifiedRows[index].vat =
                                                    e.target.value

                                                  setFormData({
                                                    ...formData,
                                                    vat: add_money_details.reduce(
                                                      function (a, b) {
                                                        return (
                                                          a + parseFloat(b.vat)
                                                        )
                                                      },
                                                      0
                                                    ),
                                                  })
                                                }}
                                              />
                                            </td>

                                            <td>
                                              <Button
                                                type="button"
                                                name={"row_rm" + [index]}
                                                color="danger"
                                                onClick={() =>
                                                  handleRemoveItem(item)
                                                }
                                              >
                                                <i className="mdi mdi-block-helper" />
                                              </Button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                      <tr>
                                        <td colSpan={7}></td>
                                        <td>
                                          <Button
                                            color="primary"
                                            className="font-16 btn-block btn btn-primary"
                                            onClick={() => addRow()}
                                            // onClick={() => eventAction(true)}
                                          >
                                            <i className="mdi mdi-plus-circle-outline" />
                                          </Button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                              </Col>
                            </div>
                          ) : null}

                          <hr style={{ marginTop: 10 }} />

                          <Col xs={3} sm={3} md={3} lg={3}>
                            <FormGroup className="mb-3">
                              <Label>ส่วนลดรวม</Label>
                              <AvField
                                name="discount"
                                type="number"
                                placeholder="ส่วนลดรวม"
                                value={formData.discount}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    discount: e.target.value,
                                    moeny: parseFloat(
                                      formData.money - e.target.value
                                    ).toFixed(2),
                                    money_after:
                                      formData.vat_material_type == 1
                                        ? parseFloat(
                                            formData.money + formData.vat
                                          ).toFixed(2)
                                        : parseFloat(
                                            formData.money - formData.vat
                                          ).toFixed(2),
                                  })
                                }
                                validate={{
                                  required: { value: false },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={3} sm={3} md={3} lg={3}>
                            <FormGroup className="mb-3">
                              <Label>ราคาก่อน</Label>
                              <AvField
                                name="money_after"
                                type="number"
                                placeholder="ยอดก่อนมูลค่าเพิ่ม"
                                value={formData.money_after}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    money_after: e.target.value,
                                  })
                                }
                                validate={{
                                  required: { value: false },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={3} sm={3} md={3} lg={3}>
                            <FormGroup className="mb-3">
                              <Label>รวมภาษีมูลค่าเพิ่ม</Label>
                              <AvField
                                name="vat"
                                type="number"
                                placeholder="ค่า vat"
                                value={formData.vat}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    vat: e.target.value,
                                    money_after:
                                      formData.vat_material_type == 1
                                        ? formData.money + e.target.value
                                        : formData.money - e.target.value,
                                  })
                                }
                                validate={{
                                  required: { value: false },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={3} sm={3} md={3} lg={3}>
                            <FormGroup className="mb-3">
                              <Label>รวมยอดจ่าย</Label>
                              <AvField
                                name="money"
                                type="number"
                                style={{
                                  border: "1px orange solid",
                                }}
                                placeholder="ยอดจ่าย"
                                value={formData.money}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    moeny: e.target.value - formData.discount,
                                  })
                                }
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : formData.type == 4 ? (
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <Select
                              styles={customStyles}
                              name="employee_id"
                              value={optionSelectUsers.filter(
                                option => option.value == formData.employee_id
                              )}
                              onChange={e =>
                                setFormData({
                                  ...formData,
                                  employee_id: e.value,
                                  employee_bank_name: e.employee_bank_name,
                                  employee_bank_no: e.employee_bank_no,
                                  employee_name: e.employee_name,
                                  employee_address: e.employee_address,
                                  employee_id_card: e.employee_id_card,
                                  money_per_month: e.money_per_month,
                                })
                              }
                              placeholder="พนักงาน"
                              options={optionSelectUsers}
                              classNamePrefix="select2-selection"
                            />
                          </Col>

                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup>
                              <AvField
                                name="comment"
                                type="text"
                                placeholder="เรื่อง"
                                value={formData.comment}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    comment: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup>
                              <AvField
                                name="note"
                                type="text"
                                placeholder="เพิ่มเติม"
                                value={formData.note}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    note: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: false,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6}>
                            <FormGroup className="mb-3">
                              <AvField
                                name="employee_bank_name"
                                type="text"
                                readOnly
                                placeholder="ธนาคาร"
                                value={formData.employee_bank_name}
                                validate={{
                                  required: { value: false },
                                }}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    employee_bank_name: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={6} lg={6}>
                            <FormGroup className="mb-3">
                              <AvField
                                name="employee_bank_no"
                                type="text"
                                readOnly
                                placeholder="เลขบัญชี"
                                value={formData.employee_bank_no}
                                validate={{
                                  required: { value: false },
                                }}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    employee_bank_no: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <hr />
                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup className="mb-3">
                              <Label>เงินเดือน</Label>
                              <AvField
                                name="money_per_month"
                                type="text"
                                readOnly
                                placeholder={"เงินเดือน"}
                                value={formData.money_per_month}
                                validate={{
                                  required: { value: false },
                                }}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    money_per_month: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup className="mb-3">
                              <Label>เดือนนี้เบิกไปแล้ว</Label>

                              <AvField
                                name="money_balance"
                                type="text"
                                readOnly
                                placeholder={"เบิกไปแล้ว"}
                                value={formData.money_balance}
                                validate={{
                                  required: { value: false },
                                }}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    money_balance: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup>
                              <Label>เลขอ้างอิง (5 ตัวหลัง)</Label>

                              <AvField
                                name="no"
                                type="text"
                                style={{
                                  border: "1px orange solid",
                                }}
                                placeholder="เลขอ้างอิง (5 ตัวหลัง)"
                                value={formData.no}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    no: e.target.value,
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup>
                              <Label>จำนวนเบิก</Label>
                              <AvField
                                name="money"
                                type="number"
                                style={{
                                  border: "1px orange solid",
                                }}
                                placeholder="จำนวนเงิน"
                                value={formData.money}
                                onChange={e =>
                                  setFormData({
                                    ...formData,
                                    money: e.target.value,
                                    money_after: parseFloat(
                                      parseFloat(e.target.value) -
                                        parseFloat(
                                          parseFloat(e.target.value * 0.03) *
                                            100
                                        ) /
                                          100
                                    ),
                                  })
                                }
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={6}>
                            <FormGroup className="mt-1">
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="radioVat_type"
                                    checked={formData.vat_type == 1}
                                    value={1}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        vat_type: e.target.value,
                                        vat: 0,
                                        money_after: parseFloat(formData.money),
                                      })
                                    }
                                  />
                                  ไม่หัก ณ ที่จ่าย
                                </Label>
                              </FormGroup>
                              <FormGroup check inline>
                                <Label>
                                  <Input
                                    type="radio"
                                    name="radioVat_type"
                                    checked={formData.vat_type == 3}
                                    value={3}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        vat_type: e.target.value,
                                        vat:
                                          parseFloat(
                                            parseFloat(formData.money * 0.03) *
                                              100
                                          ) / 100,
                                        money_after: parseFloat(
                                          parseFloat(formData.money) -
                                            parseFloat(
                                              parseFloat(
                                                formData.money * 0.03
                                              ) * 100
                                            ) /
                                              100
                                        ),
                                      })
                                    }
                                  />
                                  หัก ณ ที่จ่าย
                                </Label>
                              </FormGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}

                      {formData.type == 1 ? (
                        <Row>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{ textAlign: "right" }}
                          >
                            <FormGroup className="mt-1">
                              <FormGroup check inline>
                                <Label>
                                  <Input
                                    type="radio"
                                    name="radioDel"
                                    checked={formData.del == 0}
                                    value={0}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        del: e.target.value,
                                      })
                                    }
                                  />
                                  <span style={{ color: "red" }}>ลบ</span>
                                </Label>
                              </FormGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : formData.type == 2 ? (
                        <Row>
                          <Col xs={12} sm={12} md={4} lg={4}>
                            <FormGroup className="mt-1">
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="radioVat_type"
                                    checked={formData.vat_type == 1}
                                    value={1}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        vat_type: e.target.value,
                                        vat: 0,
                                      })
                                    }
                                  />
                                  ไม่หัก ณ ที่จ่าย
                                </Label>
                              </FormGroup>
                              <FormGroup check inline>
                                <Label>
                                  <Input
                                    type="radio"
                                    name="radioVat_type"
                                    checked={formData.vat_type == 3}
                                    value={3}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        vat_type: e.target.value,
                                        vat:
                                          parseFloat(
                                            parseFloat(formData.money * 0.03) *
                                              100
                                          ) / 100,
                                      })
                                    }
                                  />
                                  หัก ณ ที่จ่าย
                                </Label>
                              </FormGroup>
                            </FormGroup>
                          </Col>

                          <Col xs={12} sm={12} md={4} lg={4}>
                            <FormGroup className="mt-1">
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    type="radio"
                                    name="radioPay_type"
                                    checked={formData.pay_type == 1}
                                    value={1}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        pay_type: e.target.value,
                                      })
                                    }
                                  />
                                  สำรองจ่าย
                                </Label>
                              </FormGroup>
                              <FormGroup check inline>
                                <Label>
                                  <Input
                                    type="radio"
                                    name="radioPay_type"
                                    checked={formData.pay_type == 2}
                                    value={2}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        pay_type: e.target.value,
                                      })
                                    }
                                  />
                                  โอนตรง
                                </Label>
                              </FormGroup>
                              <FormGroup check inline>
                                <Label>
                                  <Input
                                    type="radio"
                                    name="radioPay_type"
                                    checked={formData.pay_type == 3}
                                    value={3}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        pay_type: e.target.value,
                                      })
                                    }
                                  />
                                  หลักทรัพย์
                                </Label>
                              </FormGroup>
                            </FormGroup>
                          </Col>

                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            style={{ textAlign: "right" }}
                          >
                            <FormGroup className="mt-1">
                              <FormGroup check inline>
                                <Label>
                                  <Input
                                    type="radio"
                                    name="radioDel"
                                    checked={formData.del == 0}
                                    value={0}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        del: e.target.value,
                                      })
                                    }
                                  />
                                  <span style={{ color: "red" }}>ลบ</span>
                                </Label>
                              </FormGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : formData.type == 3 ? (
                        formData.pay_id == 1 ? (
                          <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                              <FormGroup className="mt-1">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioVat_material_type"
                                      checked={formData.vat_material_type == 1}
                                      value={1}
                                      onChange={e => {
                                        for (
                                          let index = 0;
                                          index < add_money_details.length;
                                          index++
                                        ) {
                                          add_money_details[index].price_vat =
                                            Math.floor(
                                              parseFloat(
                                                parseFloat(
                                                  add_money_details[index].price
                                                ) +
                                                  parseFloat(
                                                    add_money_details[index]
                                                      .price * 0.07
                                                  )
                                              ) * 100
                                            ) / 100

                                          add_money_details[index].price_sum =
                                            Math.floor(
                                              parseFloat(
                                                add_money_details[index]
                                                  .price_vat *
                                                  add_money_details[index].qta
                                              ) * 100
                                            ) / 100
                                          add_money_details[index].vat =
                                            Math.floor(
                                              parseFloat(
                                                parseFloat(
                                                  parseFloat(
                                                    add_money_details[index]
                                                      .price_vat -
                                                      add_money_details[index]
                                                        .price
                                                  )
                                                ) * add_money_details[index].qta
                                              ) * 100
                                            ) / 100
                                        }
                                        setFormData({
                                          ...formData,
                                          vat_material_type: e.target.value,
                                          money: add_money_details.reduce(
                                            function (a, b) {
                                              return a + parseFloat(b.price_sum)
                                            },
                                            0
                                          ),
                                          discount: add_money_details.reduce(
                                            function (a, b) {
                                              return a + parseFloat(b.discount)
                                            },
                                            0
                                          ),
                                          money_after:
                                            formData.vat_material_type == 1
                                              ? parseFloat(
                                                  parseFloat(
                                                    formData.vat_material_type ==
                                                      1
                                                      ? add_money_details.reduce(
                                                          function (a, b) {
                                                            return (
                                                              a +
                                                              parseFloat(
                                                                b.price_sum *
                                                                  b.qta
                                                              )
                                                            )
                                                          },
                                                          0
                                                        )
                                                      : add_money_details.reduce(
                                                          function (a, b) {
                                                            return (
                                                              a +
                                                              parseFloat(
                                                                b.price * b.qta
                                                              )
                                                            )
                                                          },
                                                          0
                                                        )
                                                  ) - formData.discount
                                                ) +
                                                add_money_details.reduce(
                                                  function (a, b) {
                                                    return a + parseFloat(b.vat)
                                                  },
                                                  0
                                                )
                                              : parseFloat(
                                                  parseFloat(
                                                    formData.vat_material_type ==
                                                      1
                                                      ? add_money_details.reduce(
                                                          function (a, b) {
                                                            return (
                                                              a +
                                                              parseFloat(
                                                                b.price_sum *
                                                                  b.qta
                                                              )
                                                            )
                                                          },
                                                          0
                                                        )
                                                      : add_money_details.reduce(
                                                          function (a, b) {
                                                            return (
                                                              a +
                                                              parseFloat(
                                                                b.price * b.qta
                                                              )
                                                            )
                                                          },
                                                          0
                                                        )
                                                  ) - formData.discount
                                                ) -
                                                add_money_details.reduce(
                                                  function (a, b) {
                                                    return a + parseFloat(b.vat)
                                                  },
                                                  0
                                                ),

                                          vat: add_money_details.reduce(
                                            function (a, b) {
                                              return a + parseFloat(b.vat)
                                            },
                                            0
                                          ),
                                        })
                                      }}
                                    />
                                    vat ไม่รวมสินค้า
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioVat_material_type"
                                      checked={formData.vat_material_type == 2}
                                      value={2}
                                      onChange={e => {
                                        for (
                                          let index = 0;
                                          index < add_money_details.length;
                                          index++
                                        ) {
                                          add_money_details[index].price_vat =
                                            Math.floor(
                                              parseFloat(
                                                add_money_details[index].price -
                                                  parseFloat(
                                                    (add_money_details[index]
                                                      .price *
                                                      7) /
                                                      107
                                                  )
                                              ) * 100
                                            ) / 100

                                          add_money_details[index].price_sum =
                                            Math.floor(
                                              parseFloat(
                                                add_money_details[index]
                                                  .price_vat *
                                                  add_money_details[index].qta
                                              ) * 100
                                            ) / 100
                                          add_money_details[index].vat =
                                            Math.floor(
                                              parseFloat(
                                                parseFloat(
                                                  add_money_details[index]
                                                    .price_vat *
                                                    add_money_details[index].qta
                                                ) * 0.07
                                              ) * 100
                                            ) / 100
                                        }
                                        setFormData({
                                          ...formData,
                                          vat_material_type: e.target.value,
                                          money: add_money_details.reduce(
                                            function (a, b) {
                                              return a + parseFloat(b.price_sum)
                                            },
                                            0
                                          ),
                                          discount: add_money_details.reduce(
                                            function (a, b) {
                                              return a + parseFloat(b.discount)
                                            },
                                            0
                                          ),
                                          money_after:
                                            formData.vat_material_type == 1
                                              ? parseFloat(
                                                  parseFloat(
                                                    formData.vat_material_type ==
                                                      1
                                                      ? add_money_details.reduce(
                                                          function (a, b) {
                                                            return (
                                                              a +
                                                              parseFloat(
                                                                b.price_sum *
                                                                  b.qta
                                                              )
                                                            )
                                                          },
                                                          0
                                                        )
                                                      : add_money_details.reduce(
                                                          function (a, b) {
                                                            return (
                                                              a +
                                                              parseFloat(
                                                                b.price * b.qta
                                                              )
                                                            )
                                                          },
                                                          0
                                                        )
                                                  ) - formData.discount
                                                ) +
                                                add_money_details.reduce(
                                                  function (a, b) {
                                                    return a + parseFloat(b.vat)
                                                  },
                                                  0
                                                )
                                              : parseFloat(
                                                  parseFloat(
                                                    formData.vat_material_type ==
                                                      1
                                                      ? add_money_details.reduce(
                                                          function (a, b) {
                                                            return (
                                                              a +
                                                              parseFloat(
                                                                b.price_sum *
                                                                  b.qta
                                                              )
                                                            )
                                                          },
                                                          0
                                                        )
                                                      : add_money_details.reduce(
                                                          function (a, b) {
                                                            return (
                                                              a +
                                                              parseFloat(
                                                                b.price * b.qta
                                                              )
                                                            )
                                                          },
                                                          0
                                                        )
                                                  ) - formData.discount
                                                ) -
                                                add_money_details.reduce(
                                                  function (a, b) {
                                                    return a + parseFloat(b.vat)
                                                  },
                                                  0
                                                ),

                                          vat: add_money_details.reduce(
                                            function (a, b) {
                                              return a + parseFloat(b.vat)
                                            },
                                            0
                                          ),
                                        })
                                      }}
                                    />
                                    vat รวมสินค้า
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={4} lg={4}>
                              <FormGroup className="mt-1">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioPay_type"
                                      checked={formData.pay_type == 1}
                                      value={1}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          pay_type: e.target.value,
                                        })
                                      }
                                    />
                                    สำรองจ่าย
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioPay_type"
                                      checked={formData.pay_type == 2}
                                      value={2}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          pay_type: e.target.value,
                                        })
                                      }
                                    />
                                    โอนตรง
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioPay_type"
                                      checked={formData.pay_type == 3}
                                      value={3}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          pay_type: e.target.value,
                                        })
                                      }
                                    />
                                    หลักทรัพย์
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>

                            <Col
                              xs={12}
                              sm={12}
                              md={2}
                              lg={2}
                              style={{ textAlign: "right" }}
                            >
                              <FormGroup className="mt-1">
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioDel"
                                      checked={formData.del == 0}
                                      value={0}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          del: e.target.value,
                                        })
                                      }
                                    />
                                    <span style={{ color: "red" }}>ลบ</span>
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                              <FormGroup className="mt-1">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioVat_material_type"
                                      checked={formData.vat_material_type == 1}
                                      value={1}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          vat_material_type: e.target.value,
                                          vat: parseFloat(
                                            formData.money * 0.07
                                          ),
                                          money_after:
                                            parseFloat(formData.money) +
                                            parseFloat(formData.money * 0.07),
                                        })
                                      }
                                    />
                                    vat ไม่รวมสินค้า
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioVat_material_type"
                                      checked={formData.vat_material_type == 2}
                                      value={2}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          vat_material_type: e.target.value,
                                          vat: parseFloat(
                                            (formData.money * 7) / 107
                                          ).toFixed(2),
                                          money_after:
                                            parseFloat(formData.money) -
                                            parseFloat(
                                              (formData.money * 7) / 107
                                            ).toFixed(2),
                                        })
                                      }
                                    />
                                    vat รวมสินค้า
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={6} lg={6}>
                              <FormGroup className="mt-1">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioVat_type"
                                      checked={formData.vat_type == 1}
                                      value={1}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          vat_type: e.target.value,
                                          vat: 0,
                                          money_after: parseFloat(
                                            formData.money
                                          ),
                                        })
                                      }
                                    />
                                    ไม่หักภาษี
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioVat_type"
                                      checked={formData.vat_type == 2}
                                      value={2}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          vat_type: e.target.value,
                                          vat: parseFloat(
                                            formData.money * 0.07
                                          ),
                                          money_after:
                                            parseFloat(formData.money) +
                                            parseFloat(formData.money * 0.07),
                                        })
                                      }
                                    />
                                    ภาษีมูลค่าเพิ่ม
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>

                            <Col xs={12} sm={12} md={6} lg={6}>
                              <FormGroup className="mt-1">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioPay_type"
                                      checked={formData.pay_type == 1}
                                      value={1}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          pay_type: e.target.value,
                                        })
                                      }
                                    />
                                    สำรองจ่าย
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioPay_type"
                                      checked={formData.pay_type == 2}
                                      value={2}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          pay_type: e.target.value,
                                        })
                                      }
                                    />
                                    โอนตรง
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioPay_type"
                                      checked={formData.pay_type == 3}
                                      value={3}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          pay_type: e.target.value,
                                        })
                                      }
                                    />
                                    หลักทรัพย์
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>

                            <Col
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              style={{ textAlign: "right" }}
                            >
                              <FormGroup className="mt-1">
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioDel"
                                      checked={formData.del == 0}
                                      value={0}
                                      onChange={e =>
                                        setFormData({
                                          ...formData,
                                          del: e.target.value,
                                        })
                                      }
                                    />
                                    <span style={{ color: "red" }}>ลบ</span>
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        )
                      ) : formData.type == 4 ? (
                        <Row>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{ textAlign: "right" }}
                          >
                            <FormGroup className="mt-1">
                              <FormGroup check inline>
                                <Label>
                                  <Input
                                    type="radio"
                                    name="radioDel"
                                    checked={formData.del == 0}
                                    value={0}
                                    onChange={e =>
                                      setFormData({
                                        ...formData,
                                        del: e.target.value,
                                      })
                                    }
                                  />
                                  <span style={{ color: "red" }}>ลบ</span>
                                </Label>
                              </FormGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : null}

                      {formData.type == 2 ? (
                        <Row>
                          <Col sm="12">
                            {formData.type == 2 ? (
                              <>
                                {hideUploadImg == true ? null : (
                                  <Dropzone
                                    multiple={false}
                                    accept="image/*"
                                    inputContent={(files, extra) =>
                                      extra.reject
                                        ? "Image files only"
                                        : "Drag Files"
                                    }
                                    onDrop={acceptedFiles => {
                                      handleAcceptedFiles(acceptedFiles)
                                    }}
                                    styles={{
                                      dropzoneReject: {
                                        borderColor: "red",
                                        backgroundColor: "#DAA",
                                      },
                                      inputLabel: (files, extra) =>
                                        extra.reject ? { color: "red" } : {},
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="dz-message needsclick">
                                            <div className="mb-3">
                                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                                            </div>
                                            <h4>
                                              เลือกไฟล์หรือลากไฟล์มาตรงนี้เพื่ออัพโหลดรูป
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                )}

                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {f.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    )
                                  })}
                                </div>
                              </>
                            ) : null}
                          </Col>
                        </Row>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                {buttonCapture == true ? null : (
                  <button
                    type="button"
                    onClick={() => {
                      downloadScreenshot()
                    }}
                    className="btn btn-warning "
                  >
                    ปริ้น
                  </button>
                )}

                {formData.type == 2 ? (
                  formData.money_total_balance <= 0 ? (
                    <span style={{ color: "#DAA" }}>เบิกค่าแรงครบแล้ว</span>
                  ) : formData.del == 0 ? (
                    <Button
                      type="submit"
                      disabled={buttonSubmit}
                      color="secondary"
                    >
                      Save
                    </Button>
                  ) : isEmpty(formData.no) == true ? (
                    <p style={{ color: "red", textAlign: "center" }}>
                      กรุณาใส่เลขอ้างอิง
                    </p>
                  ) : (
                    <Button
                      type="submit"
                      disabled={buttonSubmit}
                      color="secondary"
                    >
                      Save
                    </Button>
                  )
                ) : formData.del == 0 ? (
                  <Button
                    type="submit"
                    disabled={buttonSubmit}
                    color="secondary"
                  >
                    Save
                  </Button>
                ) : isEmpty(formData.no) == true ? (
                  <p style={{ color: "red", textAlign: "center" }}>
                    กรุณาใส่เลขอ้างอิง
                  </p>
                ) : (
                  <Button
                    type="submit"
                    disabled={buttonSubmit}
                    color="secondary"
                  >
                    Save
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </AvForm>
      </Modal>
    </>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
