import {
  GET_CONTRACTOR_PROFILE,
  GET_CONTRACTOR_PROFILE_FAIL,
  GET_CONTRACTOR_PROFILE_SUCCESS,
  GET_CONTRACTORS,
  GET_CONTRACTORS_FAIL,
  GET_CONTRACTORS_SUCCESS,
  ADD_NEW_CONTRACTOR,
  ADD_CONTRACTOR_SUCCESS,
  ADD_CONTRACTOR_FAIL,
  UPDATE_CONTRACTOR,
  UPDATE_CONTRACTOR_SUCCESS,
  UPDATE_CONTRACTOR_FAIL,
  DELETE_CONTRACTOR,
  DELETE_CONTRACTOR_SUCCESS,
  DELETE_CONTRACTOR_FAIL,
} from "./actionTypes"

export const getContractors = active => ({
  type: GET_CONTRACTORS,
  payload: active,

})

export const getContractorsSuccess = contractors => ({
  type: GET_CONTRACTORS_SUCCESS,
  payload: contractors,
})

export const addNewContractor = contractor => ({
  type: ADD_NEW_CONTRACTOR,
  payload: contractor,
})

export const addContractorSuccess = contractor => ({
  type: ADD_CONTRACTOR_SUCCESS,
  payload: contractor,
})

export const addContractorFail = error => ({
  type: ADD_CONTRACTOR_FAIL,
  payload: error,
})

export const getContractorsFail = error => ({
  type: GET_CONTRACTORS_FAIL,
  payload: error,
})

export const getContractorProfile = id => ({
  type: GET_CONTRACTOR_PROFILE,
  payload: id,
})

export const getContractorProfileSuccess = contractorProfile => ({
  type: GET_CONTRACTOR_PROFILE_SUCCESS,
  payload: contractorProfile,
})

export const getContractorProfileFail = error => ({
  type: GET_CONTRACTOR_PROFILE_FAIL,
  payload: error,
})

export const updateContractor = contractor => ({
  type: UPDATE_CONTRACTOR,
  payload: contractor,
})

// export const updateContractor = contractor => console.log(contractor);

export const updateContractorSuccess = contractor => ({
  type: UPDATE_CONTRACTOR_SUCCESS,
  payload: contractor,
})

export const updateContractorFail = error => ({
  type: UPDATE_CONTRACTOR_FAIL,
  payload: error,
})

export const deleteContractor = contractor => ({
  type: DELETE_CONTRACTOR,
  payload: contractor,
})

export const deleteContractorSuccess = contractor => ({
  type: DELETE_CONTRACTOR_SUCCESS,
  payload: contractor,
})

export const deleteContractorFail = error => ({
  type: DELETE_CONTRACTOR_FAIL,
  payload: error,
})
