import {
  GET_LIST_DESIGN_GROUP_PROFILE,
  GET_LIST_DESIGN_GROUP_PROFILE_FAIL,
  GET_LIST_DESIGN_GROUP_PROFILE_SUCCESS,
  GET_LIST_DESIGN_GROUPS,
  GET_LIST_DESIGN_GROUPS_FAIL,
  GET_LIST_DESIGN_GROUPS_SUCCESS,
  ADD_NEW_LIST_DESIGN_GROUP,
  ADD_LIST_DESIGN_GROUP_SUCCESS,
  ADD_LIST_DESIGN_GROUP_FAIL,
  UPDATE_LIST_DESIGN_GROUP,
  UPDATE_LIST_DESIGN_GROUP_SUCCESS,
  UPDATE_LIST_DESIGN_GROUP_FAIL,
  DELETE_LIST_DESIGN_GROUP,
  DELETE_LIST_DESIGN_GROUP_SUCCESS,
  DELETE_LIST_DESIGN_GROUP_FAIL,
} from "./actionTypes"

export const getListDesignGroups = active => ({
  type: GET_LIST_DESIGN_GROUPS,
  payload: active,
})

export const getListDesignGroupsSuccess = designgroups => ({
  type: GET_LIST_DESIGN_GROUPS_SUCCESS,
  payload: designgroups,
})

export const addNewListDesignGroup = designgroup => ({
  type: ADD_NEW_LIST_DESIGN_GROUP,
  payload: designgroup,
})

export const addListDesignGroupSuccess = designgroup => ({
  type: ADD_LIST_DESIGN_GROUP_SUCCESS,
  payload: designgroup,
})

export const addListDesignGroupFail = error => ({
  type: ADD_LIST_DESIGN_GROUP_FAIL,
  payload: error,
})

export const getListDesignGroupsFail = error => ({
  type: GET_LIST_DESIGN_GROUPS_FAIL,
  payload: error,
})

export const getListDesignGroupProfile = id => ({
  type: GET_LIST_DESIGN_GROUP_PROFILE,
  payload: id,
})

export const getListDesignGroupProfileSuccess = designgroupProfile => ({
  type: GET_LIST_DESIGN_GROUP_PROFILE_SUCCESS,
  payload: designgroupProfile,
})

export const getListDesignGroupProfileFail = error => ({
  type: GET_LIST_DESIGN_GROUP_PROFILE_FAIL,
  payload: error,
})

export const updateListDesignGroup = designgroup => ({
  type: UPDATE_LIST_DESIGN_GROUP,
  payload: designgroup,
})

// export const updateListDesignGroup = designgroup => console.log(designgroup);

export const updateListDesignGroupSuccess = designgroup => ({
  type: UPDATE_LIST_DESIGN_GROUP_SUCCESS,
  payload: designgroup,
})

export const updateListDesignGroupFail = error => ({
  type: UPDATE_LIST_DESIGN_GROUP_FAIL,
  payload: error,
})

export const deleteListDesignGroup = designgroup => ({
  type: DELETE_LIST_DESIGN_GROUP,
  payload: designgroup,
})

export const deleteListDesignGroupSuccess = designgroup => ({
  type: DELETE_LIST_DESIGN_GROUP_SUCCESS,
  payload: designgroup,
})

export const deleteListDesignGroupFail = error => ({
  type: DELETE_LIST_DESIGN_GROUP_FAIL,
  payload: error,
})
