import {
  GET_REFPROJECTS_SUCCESS,
  GET_REFPROJECTS_FAIL,
  ADD_REFPROJECT_SUCCESS,
  ADD_REFPROJECT_FAIL,
  UPDATE_REFPROJECT_SUCCESS,
  UPDATE_REFPROJECT_FAIL,
  DELETE_REFPROJECT_SUCCESS,
  DELETE_REFPROJECT_FAIL,
  GET_REFPROJECT_PROFILE_SUCCESS,
  GET_REFPROJECT_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  refproject: [],
  refprojectProfile: [],
  idCard: {},
  error: {},
}

const refprojects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REFPROJECTS_SUCCESS:
      return {
        ...state,
        refproject: action.payload,
      }

    case GET_REFPROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_REFPROJECT_SUCCESS:
      // console.log("payload => ", action.payload)
      if (action.payload.type == 4) {
        return {
          ...state,
          // refproject: [...state.refproject, action.payload],
          idCard: action.payload,
        }
      } else {
        return {
          ...state,
          // refproject: [...state.refproject, action.payload],
          refprojectProfile: [...state.refprojectProfile, action.payload],
        }
      }

    case ADD_REFPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_REFPROJECT_PROFILE_SUCCESS:
      return {
        ...state,
        refprojectProfile: action.payload,
      }

    case UPDATE_REFPROJECT_SUCCESS:
      return {
        ...state,
        refproject: state.refproject.map(refproject =>
          refproject.id.toString() === action.payload.id.toString()
            ? { refproject, ...action.payload }
            : refproject
        ),
      }

    case UPDATE_REFPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_REFPROJECT_SUCCESS:
      return {
        ...state,
        refprojectProfile: action.payload,
      }

    // return {
    //   ...state,
    //   refprojectProfile: state.refprojectProfile.filter(
    //     refprojectProfile =>
    //       refprojectProfile.id.toString() !== action.payload.id.toString()
    //   ),
    // }

    case DELETE_REFPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_REFPROJECT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default refprojects
