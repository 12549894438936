/* LIST_AIUMINIASS */
export const GET_LIST_AIUMINIASS = "GET_LIST_AIUMINIASS"
export const GET_LIST_AIUMINIASS_SUCCESS = "GET_LIST_AIUMINIASS_SUCCESS"
export const GET_LIST_AIUMINIASS_FAIL = "GET_LIST_AIUMINIASS_FAIL"

/* LIST_AIUMINIASS PROFILE */
export const GET_LIST_AIUMINIAS_PROFILE = "GET_LIST_AIUMINIAS_PROFILE"
export const GET_LIST_AIUMINIAS_PROFILE_SUCCESS = "GET_LIST_AIUMINIAS_PROFILE_SUCCESS"
export const GET_LIST_AIUMINIAS_PROFILE_FAIL = "GET_LIST_AIUMINIAS_PROFILE_FAIL"

/**
 * add aluminia
 */
export const ADD_NEW_LIST_AIUMINIAS = "ADD_NEW_LIST_AIUMINIAS"
export const ADD_LIST_AIUMINIAS_SUCCESS = "ADD_LIST_AIUMINIAS_SUCCESS"
export const ADD_LIST_AIUMINIAS_FAIL = "ADD_LIST_AIUMINIAS_FAIL"

/**
 * Edit aluminia
 */
export const UPDATE_LIST_AIUMINIAS = "UPDATE_LIST_AIUMINIAS"
export const UPDATE_LIST_AIUMINIAS_SUCCESS = "UPDATE_LIST_AIUMINIAS_SUCCESS"
export const UPDATE_LIST_AIUMINIAS_FAIL = "UPDATE_LIST_AIUMINIAS_FAIL"

/**
 * Delete aluminia
 */
export const DELETE_LIST_AIUMINIAS = "DELETE_LIST_AIUMINIAS"
export const DELETE_LIST_AIUMINIAS_SUCCESS = "DELETE_LIST_AIUMINIAS_SUCCESS"
export const DELETE_LIST_AIUMINIAS_FAIL = "DELETE_LIST_AIUMINIAS_FAIL"
