import {
  GET_ATTENDSS_SUCCESS,
  GET_ATTENDSS_FAIL,
  ADD_ATTENDS_SUCCESS,
  ADD_ATTENDS_FAIL,
  UPDATE_ATTENDS_SUCCESS,
  UPDATE_ATTENDS_FAIL,
  DELETE_ATTENDS_SUCCESS,
  DELETE_ATTENDS_FAIL,
  GET_ATTENDS_PROFILE_SUCCESS,
  GET_ATTENDS_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  attends: [],
  attendProfile: {},
  error: {},
}

const attends = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ATTENDSS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        attends: action.payload,
      }

    case GET_ATTENDSS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ATTENDS_SUCCESS:
      return {
        ...state,
        attends: [...state.attends, action.payload],
      }

    case ADD_ATTENDS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTENDS_PROFILE_SUCCESS:
      // console.log("GET_ATTENDS_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        attendProfile: action.payload,
      }

    case UPDATE_ATTENDS_SUCCESS:
      return {
        ...state,
        attends: state.attends.map(attend =>
          attend.id.toString() === action.payload.id.toString()
            ? { attend, ...action.payload }
            : attend
        ),
      }

    case UPDATE_ATTENDS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ATTENDS_SUCCESS:
      return {
        ...state,
        attends: state.attends.filter(
          attend =>
            attend.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_ATTENDS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ATTENDS_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default attends
