/* QUOTATIONCONPROJECTS */
export const GET_QUOTATIONCONPROJECTS = "GET_QUOTATIONCONPROJECTS"
export const GET_QUOTATIONCONPROJECTS_SUCCESS = "GET_QUOTATIONCONPROJECTS_SUCCESS"
export const GET_QUOTATIONCONPROJECTS_FAIL = "GET_QUOTATIONCONPROJECTS_FAIL"

/* QUOTATIONCONPROJECTS PROFILE */
export const GET_QUOTATIONCONPROJECT_PROFILE = "GET_QUOTATIONCONPROJECT_PROFILE"
export const GET_QUOTATIONCONPROJECT_PROFILE_SUCCESS = "GET_QUOTATIONCONPROJECT_PROFILE_SUCCESS"
export const GET_QUOTATIONCONPROJECT_PROFILE_FAIL = "GET_QUOTATIONCONPROJECT_PROFILE_FAIL"

/**
 * add quotationconproject
 */
export const ADD_NEW_QUOTATIONCONPROJECT = "ADD_NEW_QUOTATIONCONPROJECT"
export const ADD_QUOTATIONCONPROJECT_SUCCESS = "ADD_QUOTATIONCONPROJECT_SUCCESS"
export const ADD_QUOTATIONCONPROJECT_FAIL = "ADD_QUOTATIONCONPROJECT_FAIL"

/**
 * Edit quotationconproject
 */
export const UPDATE_QUOTATIONCONPROJECT = "UPDATE_QUOTATIONCONPROJECT"
export const UPDATE_QUOTATIONCONPROJECT_SUCCESS = "UPDATE_QUOTATIONCONPROJECT_SUCCESS"
export const UPDATE_QUOTATIONCONPROJECT_FAIL = "UPDATE_QUOTATIONCONPROJECT_FAIL"

/**
 * Delete quotationconproject
 */
export const DELETE_QUOTATIONCONPROJECT = "DELETE_QUOTATIONCONPROJECT"
export const DELETE_QUOTATIONCONPROJECT_SUCCESS = "DELETE_QUOTATIONCONPROJECT_SUCCESS"
export const DELETE_QUOTATIONCONPROJECT_FAIL = "DELETE_QUOTATIONCONPROJECT_FAIL"

