import toastr from "toastr"
import "toastr/build/toastr.min.css"

export function Notifications(action) {
  // console.log(action)
  toastr.options = {
    positionClass:
      action.positionClass == null ? "toast-top-right" : action.positionClass,
  }
  //Toaster Types
  setTimeout(() => {
    if (action.toastType === "info") toastr.info(action.message, action.title)
    else if (action.toastType === "warning")
      toastr.warning(action.message, action.title)
    else if (action.toastType === "error")
      toastr.error(action.message, action.title)
    else if (action.toastType === "success")
      toastr.success(action.message, action.title)
  }, 1000)
}
