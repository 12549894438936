import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

const Flow = () => {
  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)

  return (
    <React.Fragment>
      <section className="section" id="flow">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">Timeline</div>
                <h4>Work Flows</h4>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg="12">
              <div className="hori-timeline">
                <div
                  className="owl-carousel owl-theme  navs-carousel events"
                  id="timeline-carousel"
                >
                  {step1 ? (
                    <>
                      <Row>
                        <Col md={4}>
                          <Link to="#" data-toggle="tooltip" title="Facebook">
                            {""}
                          </Link>
                          <div
                            className="item event-list"
                            style={{ display: "inline-table" }}
                          >
                            <div>
                              <div className="event-date">
                                <div
                                  className="text-primary mb-1"
                                  style={{ fontSize: "18px" }}
                                >
                                  ขั้นตอนที่ 1
                                </div>
                                <h5
                                  className="mb-4"
                                  style={{ fontSize: "16px" }}
                                >
                                  มัดจำ
                                </h5>
                              </div>
                              <div className="event-down-icon">
                                <i
                                  className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"
                                  style={{ backgroundColor: "transparent" }}
                                />
                              </div>

                              <div className="mt-3 px-3">
                                <p
                                  className="text-muted"
                                  style={{ fontSize: "14px" }}
                                >
                                  ทำการมัดจำเพื่อออกแบบสถาปัตย์กรรมและทำการสำรวจหน้างาน
                                  และทำการลงพื้นที่วางเพื่อสำรวจสำรวจการขนส่ง
                                </p>

                                <Link
                                  to="#"
                                  data-toggle="tooltip"
                                  title="Facebook"
                                >
                                  {""}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <Link to="#" data-toggle="tooltip" title="Facebook">
                            {""}
                          </Link>
                          <div
                            className="item event-list"
                            style={{ display: "inline-table" }}
                          >
                            <div>
                              <div className="event-date">
                                <div
                                  className="text-primary mb-1"
                                  style={{ fontSize: "18px" }}
                                >
                                  ขั้ยตอนที่ 2
                                </div>
                                <h5
                                  className="mb-4"
                                  style={{ fontSize: "16px" }}
                                >
                                  ออกแบบ
                                </h5>
                              </div>
                              <div className="event-down-icon">
                                <i
                                  className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"
                                  style={{ backgroundColor: "transparent" }}
                                />
                              </div>

                              <div className="mt-3 px-3">
                                <p
                                  className="text-muted"
                                  style={{ fontSize: "14px" }}
                                >
                                  ทำการออกแบบสถาปัตย์และแปลนภายใน รวมถึงไฟฟ้า
                                  เผื่อให้ลูกค้าตรวจสอบความถูกต้อง
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <Link to="#" data-toggle="tooltip" title="Facebook">
                            {""}
                          </Link>
                          <div
                            className="item event-list"
                            style={{ display: "inline-table" }}
                          >
                            <div>
                              <div className="event-date">
                                <div
                                  className="text-primary mb-1"
                                  style={{ fontSize: "18px" }}
                                >
                                  ขั้นตอนที่ 3
                                </div>
                                <h5
                                  className="mb-4"
                                  style={{ fontSize: "16px" }}
                                >
                                  ทำสัญญาจ้าง
                                </h5>
                              </div>
                              <div className="event-down-icon">
                                <i
                                  className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"
                                  style={{ backgroundColor: "transparent" }}
                                />
                              </div>

                              <div className="mt-3 px-3">
                                <p
                                  className="text-muted"
                                  style={{ fontSize: "14px" }}
                                >
                                  เมื่อลูกค้าพอใจในแบบแล้วจึงทำสัญญาจ้างบริษัทจะจัดหาวัสดุ
                                  และทีมงานเข้าดำเนินการในระยะเวลา 7-15 วัน
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  {step2 ? (
                    <>
                      <Row>
                        <Col md={4}>
                          <Link to="#" data-toggle="tooltip" title="Facebook">
                            {""}
                          </Link>
                          <div
                            className="item event-list"
                            style={{ display: "inline-table" }}
                          >
                            <div>
                              <div className="event-date">
                                <div
                                  className="text-primary mb-1"
                                  style={{ fontSize: "18px" }}
                                >
                                  ขั้นตอนที่ 4
                                </div>
                                <h5
                                  className="mb-4"
                                  style={{ fontSize: "16px" }}
                                >
                                  เริ่มทำการก่อนสร้าง
                                </h5>
                              </div>
                              <div className="event-down-icon">
                                <i
                                  className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"
                                  style={{ backgroundColor: "transparent" }}
                                />
                              </div>

                              <div className="mt-3 px-3">
                                <p
                                  className="text-muted"
                                  style={{ fontSize: "14px" }}
                                >
                                  ทำการก่อสร้างภายในรูปแบบขอและวัสดุงบริษัทเท่านั้นและเบิกเงินตามงวดกรุผนัง
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <Link to="#" data-toggle="tooltip" title="Facebook">
                            {""}
                          </Link>
                          <div
                            className="item event-list"
                            style={{ display: "inline-table" }}
                          >
                            <div>
                              <div className="event-date">
                                <div
                                  className="text-primary mb-1"
                                  style={{ fontSize: "18px" }}
                                >
                                  ขั้นตอนที่ 5
                                </div>
                                <h5
                                  className="mb-4"
                                  style={{ fontSize: "16px" }}
                                >
                                  ตรวจรับ
                                </h5>
                              </div>
                              <div className="event-down-icon">
                                <i
                                  className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"
                                  style={{ backgroundColor: "transparent" }}
                                />
                              </div>

                              <div className="mt-3 px-3">
                                <p
                                  className="text-muted"
                                  style={{ fontSize: "14px" }}
                                >
                                  ทางทีมงานจะเข้าไปตรวจสอบผลงานก่อนหากเป็นไปตามมาตรฐานแล้วจึงจัดหมายเจ้าของเผื่อเข้ามารับบ้านต่อไป
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <Link to="#" data-toggle="tooltip" title="Facebook">
                            {""}
                          </Link>
                          <div
                            className="item event-list"
                            style={{ display: "inline-table" }}
                          >
                            <div>
                              <div className="event-date">
                                <div
                                  className="text-primary mb-1"
                                  style={{ fontSize: "18px" }}
                                >
                                  ขั้นตอนที่ 6
                                </div>
                                <h5
                                  className="mb-4"
                                  style={{ fontSize: "16px" }}
                                >
                                  ส่งมอบงาน
                                </h5>
                              </div>
                              <div className="event-down-icon">
                                <i
                                  className="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"
                                  style={{ backgroundColor: "transparent" }}
                                />
                              </div>

                              <div className="mt-3 px-3">
                                <p
                                  className="text-muted"
                                  style={{ fontSize: "14px" }}
                                >
                                  เมื่อลูกค้าพึงพอใจและเป็นตามแบบมาตรแล้วบริษัทจะออกใบตรวจรับและใบรับประกันผลงาน
                                  เป็นเวลา 1 ปี
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  <div className="owl-nav" style={{ textAlign: "center" }}>
                    <button
                      type="button"
                      onClick={() => {
                        setStep1(true)
                        setStep2(false)
                      }}
                      className="border-0"
                      disabled={step1}
                    >
                      <i className="mdi mdi-chevron-left" />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setStep1(false)
                        setStep2(true)
                      }}
                      className="border-0"
                      disabled={step2}
                    >
                      <i className="mdi mdi-chevron-right" />
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Flow
