import {
  GET_CONTRACTORS_SUCCESS,
  GET_CONTRACTORS_FAIL,
  ADD_CONTRACTOR_SUCCESS,
  ADD_CONTRACTOR_FAIL,
  UPDATE_CONTRACTOR_SUCCESS,
  UPDATE_CONTRACTOR_FAIL,
  DELETE_CONTRACTOR_SUCCESS,
  DELETE_CONTRACTOR_FAIL,
  GET_CONTRACTOR_PROFILE_SUCCESS,
  GET_CONTRACTOR_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  contractors: [],
  contractorProfile: {},
  error: {},
}

const contractors = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONTRACTORS_SUCCESS:
      return {
        ...state,
        contractors: action.payload,
      }

    case GET_CONTRACTORS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CONTRACTOR_SUCCESS:

      return {
        ...state,
        contractors: [...state.contractors, action.payload],
      }

    case ADD_CONTRACTOR_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CONTRACTOR_PROFILE_SUCCESS:
      return {
        ...state,
        contractorProfile: action.payload,
      }

      case UPDATE_CONTRACTOR_SUCCESS:
        return {
          ...state,
          contractors: state.contractors.map(contractor =>
            contractor.id.toString() === action.payload.id.toString()
              ? { contractor, ...action.payload }
              : contractor
          ),
        }
  
      case UPDATE_CONTRACTOR_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case DELETE_CONTRACTOR_SUCCESS:
        return {
          ...state,
          contractors: state.contractors.filter(
            contractor => contractor.id.toString() !== action.payload.id.toString()
          ),
        }
  
      case DELETE_CONTRACTOR_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    case GET_CONTRACTOR_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contractors
