/* MESSAGE_LISTS */
export const GET_MESSAGE_LISTS = "GET_MESSAGE_LISTS"
export const GET_MESSAGE_LISTS_SUCCESS = "GET_MESSAGE_LISTS_SUCCESS"
export const GET_MESSAGE_LISTS_FAIL = "GET_MESSAGE_LISTS_FAIL"

/* MESSAGE_LISTS PROFILE */
export const GET_MESSAGE_LIST_PROFILE = "GET_MESSAGE_LIST_PROFILE"
export const GET_MESSAGE_LIST_PROFILE_SUCCESS = "GET_MESSAGE_LIST_PROFILE_SUCCESS"
export const GET_MESSAGE_LIST_PROFILE_FAIL = "GET_MESSAGE_LIST_PROFILE_FAIL"

/**
 * add designgroup
 */
export const ADD_NEW_MESSAGE_LIST = "ADD_NEW_MESSAGE_LIST"
export const ADD_MESSAGE_LIST_SUCCESS = "ADD_MESSAGE_LIST_SUCCESS"
export const ADD_MESSAGE_LIST_FAIL = "ADD_MESSAGE_LIST_FAIL"

/**
 * Edit designgroup
 */
export const UPDATE_MESSAGE_LIST = "UPDATE_MESSAGE_LIST"
export const UPDATE_MESSAGE_LIST_SUCCESS = "UPDATE_MESSAGE_LIST_SUCCESS"
export const UPDATE_MESSAGE_LIST_FAIL = "UPDATE_MESSAGE_LIST_FAIL"

/**
 * Delete designgroup
 */
export const DELETE_MESSAGE_LIST = "DELETE_MESSAGE_LIST"
export const DELETE_MESSAGE_LIST_SUCCESS = "DELETE_MESSAGE_LIST_SUCCESS"
export const DELETE_MESSAGE_LIST_FAIL = "DELETE_MESSAGE_LIST_FAIL"
