import React from "react"

import PropTypes from "prop-types"
import { Modal, ModalHeader, ModalBody, Col, Row } from "reactstrap"
import { filter, isEmpty, map } from "lodash"

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work
import pdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

//ต้องระบุตามชื่อของ ไฟล์ font
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
}

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const [modal, setModal] = React.useState(false)
  const [dataProfitloss_wage, setDataProfitloss_wage] = React.useState([])
  const [dataProfitlossp_cost, setDataProfitlossp_cost] = React.useState([])
  const [dataProfitlossp_material, setDataProfitlossp_material] =
    React.useState([])
  const [nameProfject, setNameProject] = React.useState("")
  const [pdfListCost, setPdfListCost] = React.useState([])
  const [pdfListWage, setPdfListWage] = React.useState([])
  const [pdfListMaterial, setPdfListMaterial] = React.useState([])

  const [money_cost, setMoney_cost] = React.useState(0)
  const [money_wage, setMoney_wage] = React.useState(0)
  const [money_material, setMoney_material] = React.useState(0)

  const [tableProfitLossCosts, setTableProfitLossCosts] = React.useState([
    {
      dataField: "comment",
      text: "เรื่อง",
      sort: false,
    },
    {
      dataField: "note",
      text: "หมายเหตุ",
      sort: false,
    },
    {
      dataField: "date_add",
      text: "วันที่",
      sort: false,
      formatter: (cell, row, rowIndex) => {
        return (
          <span>{moment(cell).add(543, "year").format("DD/MM/YYYY")} </span>
        )
      },
    },

    {
      dataField: "money",
      text: "จำนวนเงิน",
      sort: false,
      headerStyle: () => {
        return { width: "180px" }
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <span>
            {parseFloat(cell).toLocaleString("th-Th", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        )
      },
    },
  ])

  const [tableProfitLossWages, setTableProfitLossWages] = React.useState([
    {
      dataField: "contractor_name",
      text: "ช่าง",
      sort: false,
    },
    {
      dataField: "comment",
      text: "งาน",
      sort: false,
    },
    {
      dataField: "note",
      text: "หมายเหตุ",
      sort: false,
    },
    {
      dataField: "date_add",
      text: "วันที่",
      sort: false,
      formatter: (cell, row, rowIndex) => {
        return (
          <span>{moment(cell).add(543, "year").format("DD/MM/YYYY")} </span>
        )
      },
    },

    {
      dataField: "money",
      text: "ยอดเบิก",
      sort: false,
      headerStyle: () => {
        return { width: "180px" }
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <span>
            {parseFloat(cell).toLocaleString("th-Th", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        )
      },
    },
  ])

  const [tableProfitLossMaterials, setTableProfitLossMateirals] =
    React.useState([
      {
        dataField: "boq_title_name",
        text: "งานหลัก",
        sort: false,
      },
      {
        dataField: "boq_title_detail_name",
        text: "งานย่อย",
        sort: false,
      },
      {
        dataField: "note",
        text: "หมายเหตุ",
        sort: false,
      },

      {
        dataField: "created_at",
        text: "วันที่",
        sort: false,
        formatter: (cell, row, rowIndex) => {
          return (
            <span>{moment(cell).add(543, "year").format("DD/MM/YYYY")} </span>
          )
        },
      },
      {
        dataField: "price_sum",
        text: "รวมราคา",
        sort: false,
        headerStyle: () => {
          return { width: "180px" }
        },
        formatter: (cell, row, rowIndex) => {
          return (
            <span>
              {parseFloat(cell).toLocaleString("th-Th", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          )
        },
      },
    ])

  React.useMemo(() => {
    if (actionModal.modal) {
      //   console.log("actionModal", actionModal)
      setModal(actionModal.modal)
      setDataProfitlossp_cost(actionModal.details_costs)
      newDataListsCost(actionModal.details_costs)
      setDataProfitloss_wage(actionModal.details_wages)
      newDataListsWage(actionModal.details_wages)
      setDataProfitlossp_material(actionModal.details_materials)
      newDataListsMaterial(actionModal.details_materials)
      setNameProject(actionModal.project_name)
    }
    return () => {}
  }, [actionModal]) // Only re-run the effect if count changes

  React.useMemo(() => {
    if (!isEmpty(dataProfitloss_wage)) {
      let sum_money = dataProfitloss_wage.reduce(function (a, b) {
        return a + parseFloat(b.money)
      }, 0)
      setMoney_wage(sum_money)
    }
    return () => {}
  }, [dataProfitloss_wage]) // Only re-run the effect if count changes

  React.useMemo(() => {
    if (!isEmpty(dataProfitlossp_cost)) {
      let sum_money = dataProfitlossp_cost.reduce(function (a, b) {
        return a + parseFloat(b.money)
      }, 0)
      setMoney_cost(sum_money)
    }
    return () => {}
  }, [dataProfitlossp_cost]) // Only re-run the effect if count changes

  React.useMemo(() => {
    if (!isEmpty(dataProfitlossp_material)) {
      let sum_money = dataProfitlossp_material.reduce(function (a, b) {
        return a + parseFloat(b.price_sum)
      }, 0)
      setMoney_material(sum_money)
    }
    return () => {}
  }, [dataProfitlossp_material]) // Only re-run the effect if count changes

  async function printInvoice() {
    var docDefinition = {
      watermark: {
        text: process.env.REACT_APP_NAMECOMPANY,
        color: "#e6e6e6",
        opacity: 0.5,
      },
      content: [
        //page 1
        {
          columns: [
            {
              style: "header",
              alignment: "center",
              text: "สรุปกำไร/ขาดทุน " + nameProfject,
              width: "100%",
            },
          ],
        },
        {
          columns: [
            {
              style: "header",
              alignment: "center",
              width: "25%",
              text:
                "รวมยอดขาย\n" +
                parseFloat(money_cost).toLocaleString("th-Th", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) +
                " บ.",
            },
            {
              style: "header",
              alignment: "center",
              width: "25%",
              text:
                "รวมยอดเบิกช่าง\n" +
                parseFloat(money_wage).toLocaleString("th-Th", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) +
                " บ.",
            },
            {
              style: "header",
              alignment: "center",
              width: "25%",
              text:
                "รวมวัสดุ\n" +
                parseFloat(money_material).toLocaleString("th-Th", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) +
                " บ.",
            },
            {
              style: "header",
              alignment: "center",
              width: "25%",
              text:
                parseFloat(money_cost - (money_wage + money_material)) > 0
                  ? "กำไร\n" +
                    parseFloat(
                      money_cost - (money_wage + money_material)
                    ).toLocaleString("th-Th", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) +
                    " บ."
                  : "ขาดทุน\n" +
                    parseFloat(
                      money_cost - (money_wage + money_material)
                    ).toLocaleString("th-Th", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) +
                    " บ.",
            },
          ],
        },
        {
          text: "ยอดขาย",
          style: "header",
        },
        {
          table: {
            style: "tableExample",
            widths: ["auto", "*", "auto", "auto", "auto"],
            headerRows: 1,
            body: pdfListCost,
          },
        },

        {
          text: "เบิกค่าแรงช่าง",
          style: "header",
          margin: [0, 10, 0, 0],
        },
        {
          table: {
            style: "tableExample",
            widths: ["auto", "auto", "*", "auto", "auto", "auto"],
            headerRows: 1,
            body: pdfListWage,
          },
        },
        {
          text: "ค่าวัสดุ",
          style: "header",
          margin: [0, 10, 0, 0],
        },
        {
          table: {
            style: "tableExample",
            widths: ["auto", "auto", "*", "auto", "auto", "auto"],
            headerRows: 1,
            body: pdfListMaterial,
          },
        },
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        subheader: {
          fontSize: 13,
          decoration: "underline",
          decorationStyle: "solid",
          decorationColor: "black",
        },
        tableExample: {
          fontSize: 13,
        },
        tableHeader: {
          bold: true,
          fontSize: 14,
          color: "black",
        },
      },
      defaultStyle: {
        font: "THSarabunNew",
      },
      pageSize: "A4",
    }
    let namePDF = "กำไร/ขาดทุน " + nameProfject + ".pdf"
    // console.log(namePDF)
    // pdfMake.createPdf(docDefinition).open()
    pdfMake.createPdf(docDefinition).download(namePDF)
  }

  function newDataListsCost(data) {
    var headers = {
      fila_0: {
        col_0: {
          text: "ลำดับ",
          style: "tableHeader",
        },
        col_1: {
          text: "เรื่อง",
          style: "tableHeader",
        },
        col_2: {
          text: "หมายเหตุ",
          style: "tableHeader",
        },
        col_3: {
          text: "วันที่",
          style: "tableHeader",
        },
        col_4: {
          text: "จำนวนเงิน",
          style: "tableHeader",
        },
      },
    }

    var body = []
    for (var key in headers) {
      if (headers.hasOwnProperty(key)) {
        var header = headers[key]
        var row = new Array()
        row.push(header.col_0)
        row.push(header.col_1)
        row.push(header.col_2)
        row.push(header.col_3)
        row.push(header.col_4)
        body.push(row)
      }
    }
    // console.log(data)
    for (let key = 0; key < data.length; key++) {
      var row = new Array()
      row.push(key + 1)
      row.push(data[key].comment)
      row.push({ text: data[key].note })
      row.push({
        text: moment(data[key].date_add).add(543, "year").format("DD/MM/YYYY"),
      })
      row.push({
        text: parseFloat(data[key].money).toLocaleString("th-TH", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      })

      body.push(row)
    }

    setPdfListCost(body)
  }

  function newDataListsWage(data) {
    var headers = {
      fila_0: {
        col_0: {
          text: "ลำดับ",
          style: "tableHeader",
        },
        col_1: {
          text: "ช่าง",
          style: "tableHeader",
        },
        col_2: {
          text: "งาน",
          style: "tableHeader",
        },
        col_3: {
          text: "หมายเหตุ",
          style: "tableHeader",
        },
        col_4: {
          text: "วันที่",
          style: "tableHeader",
        },
        col_5: {
          text: "ยอดเบิก",
          style: "tableHeader",
        },
      },
    }

    var body = []
    for (var key in headers) {
      if (headers.hasOwnProperty(key)) {
        var header = headers[key]
        var row = new Array()
        row.push(header.col_0)
        row.push(header.col_1)
        row.push(header.col_2)
        row.push(header.col_3)
        row.push(header.col_4)
        row.push(header.col_5)

        body.push(row)
      }
    }
    // console.log(data)
    for (let key = 0; key < data.length; key++) {
      var row = new Array()
      row.push(key + 1)
      row.push(data[key].contractor_name)
      row.push(data[key].comment)
      row.push({ text: data[key].note })
      row.push({
        text: moment(data[key].date_add).add(543, "year").format("DD/MM/YYYY"),
      })
      row.push({
        text: parseFloat(data[key].money).toLocaleString("th-TH", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      })

      body.push(row)
    }

    setPdfListWage(body)
  }
  function newDataListsMaterial(data) {
    var headers = {
      fila_0: {
        col_0: {
          text: "ลำดับ",
          style: "tableHeader",
        },
        col_1: {
          text: "งานหลัก",
          style: "tableHeader",
        },
        col_2: {
          text: "งานย่อย",
          style: "tableHeader",
        },
        col_3: {
          text: "หมายเหตุ",
          style: "tableHeader",
        },
        col_4: {
          text: "วันที่",
          style: "tableHeader",
        },
        col_5: {
          text: "รวมราคา",
          style: "tableHeader",
        },
      },
    }

    var body = []
    for (var key in headers) {
      if (headers.hasOwnProperty(key)) {
        var header = headers[key]
        var row = new Array()
        row.push(header.col_0)
        row.push(header.col_1)
        row.push(header.col_2)
        row.push(header.col_3)
        row.push(header.col_4)
        row.push(header.col_5)

        body.push(row)
      }
    }
    // console.log(data)
    for (let key = 0; key < data.length; key++) {
      var row = new Array()
      row.push(key + 1)
      row.push(data[key].boq_title_name)
      row.push(data[key].boq_title_detail_name)
      row.push({ text: data[key].note })
      row.push({
        text: moment(data[key].created_at)
          .add(543, "year")
          .format("DD/MM/YYYY"),
      })
      row.push({
        text: parseFloat(data[key].price_sum).toLocaleString("th-TH", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      })

      body.push(row)
    }

    setPdfListMaterial(body)
  }

  return (
    <Modal
      size="xl"
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        <h2
          style={{ color: "blue", cursor: "pointer" }}
          onClick={e => printInvoice()}
        >
          ดาว์นโหลด กำไร/ขาดทุน
        </h2>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col sm="12" md="12" lg="3">
            <h5>
              รวมยอดขาย
              <br />
              {parseFloat(money_cost).toLocaleString("th-Th", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              &nbsp; บ.
            </h5>
          </Col>
          <Col sm="12" md="12" lg="3">
            <h5>
              รวมยอดเบิกช่าง
              <br />
              {parseFloat(money_wage).toLocaleString("th-Th", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              &nbsp; บ.
            </h5>
          </Col>
          <Col sm="12" md="12" lg="3">
            <h5>
              รวมวัสดุ
              <br />
              {parseFloat(money_material).toLocaleString("th-Th", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              &nbsp; บ.
            </h5>
          </Col>
          <Col sm="12" md="12" lg="3">
            {parseFloat(money_cost - (money_wage + money_material)) > 0 ? (
              <h5 style={{ color: "green" }}>
                กำไร
                <br />
                {parseFloat(
                  money_cost - (money_wage + money_material)
                ).toLocaleString("th-Th", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                &nbsp; บ.
              </h5>
            ) : (
              <h5 style={{ color: "red" }}>
                ขาดทุน
                <br />
                {parseFloat(
                  money_cost - (money_wage + money_material)
                ).toLocaleString("th-Th", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                &nbsp; บ.
              </h5>
            )}
          </Col>
        </Row>
        <hr />
        <h6>ยอดขาย</h6>
        <BootstrapTable
          keyField="id"
          bordered
          data={dataProfitlossp_cost}
          columns={tableProfitLossCosts}
          // bootstrap4
          pagination={paginationFactory({
            sizePerPage: 30,
            sizePerPageList: [30, 50, 70, 100],
          })}
          wrapperClasses="table-responsive" // This is the style provided by bootstrap 4, this will set the parent div with that class
        />
        <hr />

        <h6>เบิกค่าแรงช่าง</h6>
        <BootstrapTable
          keyField="id"
          bordered
          data={dataProfitloss_wage}
          columns={tableProfitLossWages}
          // bootstrap4
          pagination={paginationFactory({
            sizePerPage: 30,
            sizePerPageList: [30, 50, 70, 100],
          })}
          wrapperClasses="table-responsive" // This is the style provided by bootstrap 4, this will set the parent div with that class
        />
        <hr />

        <h6>รายการเบิกวัสดุ</h6>
        <BootstrapTable
          keyField="id"
          bordered
          data={dataProfitlossp_material}
          columns={tableProfitLossMaterials}
          // bootstrap4
          pagination={paginationFactory({
            sizePerPage: 30,
            sizePerPageList: [30, 50, 70, 100],
          })}
          wrapperClasses="table-responsive" // This is the style provided by bootstrap 4, this will set the parent div with that class
        />
      </ModalBody>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
