import {
  GET_DESIGNHOMES_SUCCESS,
  GET_DESIGNACHIEVEMENTHOMES_SUCCESS,
  GET_DESIGNPROMOTIONHOMES_SUCCESS,
  GET_DESIGNHOMES_FAIL,
  ADD_DESIGNHOME_SUCCESS,
  ADD_DESIGNHOME_FAIL,
  UPDATE_DESIGNHOME_SUCCESS,
  UPDATE_DESIGNHOME_FAIL,
  DELETE_DESIGNHOME_SUCCESS,
  DELETE_DESIGNHOME_FAIL,
  GET_DESIGNHOME_PROFILE_SUCCESS,
  GET_DESIGNHOME_PROFILE_FAIL,
  CHANGEFIRST_DESIGNHOMEIMG_SUCCESS,
  DELETE_DESIGNHOMEIMG_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  designhomes: [],
  designAchievementhomes: [],
  designPromotionhome: [],
  designhomeProfile: {},
  error: {},
}

const designhomes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DESIGNHOMES_SUCCESS:
      return {
        ...state,
        designhomes: action.payload,
      }

    case GET_DESIGNACHIEVEMENTHOMES_SUCCESS:
      return {
        ...state,
        designAchievementhomes: action.payload,
      }

    case GET_DESIGNPROMOTIONHOMES_SUCCESS:
      return {
        ...state,
        designPromotionhome: action.payload,
      }

    case GET_DESIGNHOMES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DESIGNHOME_SUCCESS:
      return {
        ...state,
        designhomes: [...state.designhomes, action.payload],
      }

    case ADD_DESIGNHOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DESIGNHOME_PROFILE_SUCCESS:
      // console.log(action.payload.slide_imgs)
      return {
        ...state,
        designhomeProfile: action.payload,
      }

    case UPDATE_DESIGNHOME_SUCCESS:
      return {
        ...state,
        designhomes: state.designhomes.map(designhome =>
          designhome.id.toString() === action.payload.id.toString()
            ? { designhome, ...action.payload }
            : designhome
        ),
      }

    case UPDATE_DESIGNHOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_DESIGNHOME_SUCCESS:
      return {
        ...state,
        designhomes: state.designhomes.filter(
          designhome =>
            designhome.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_DESIGNHOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DESIGNHOME_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CHANGEFIRST_DESIGNHOMEIMG_SUCCESS:
      // console.log(action.payload);
      return {
        ...state,
        designhomeProfile: action.payload,
      }

    case DELETE_DESIGNHOMEIMG_SUCCESS:
      return {
        ...state,
        designhomeProfile: action.payload,
      }

    default:
      return state
  }
}

export default designhomes
