import {
  GET_MATERIAL_PRICES_SUCCESS,
  GET_MATERIAL_PRICES_FAIL,
  ADD_MATERIAL_PRICE_SUCCESS,
  ADD_MATERIAL_PRICE_FAIL,
  UPDATE_MATERIAL_PRICE_SUCCESS,
  UPDATE_MATERIAL_PRICE_FAIL,
  DELETE_MATERIAL_PRICE_SUCCESS,
  DELETE_MATERIAL_PRICE_FAIL,
  GET_MATERIAL_PRICE_PROFILE_SUCCESS,
  GET_MATERIAL_PRICE_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  materialPrices: [],
  materialPriceProfile: {},
  error: {},
}

const materialPrices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MATERIAL_PRICES_SUCCESS:
      // console.log(action)
      return {
        ...state,
        materialPrices: action.payload,
      }

    case GET_MATERIAL_PRICES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_MATERIAL_PRICE_SUCCESS:
      return {
        ...state,
        materialPrices: [...state.materialPrices, action.payload],
      }

    case ADD_MATERIAL_PRICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MATERIAL_PRICE_PROFILE_SUCCESS:
      // console.log("GET_MATERIAL_PRICE_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        materialPriceProfile: action.payload,
      }

    case UPDATE_MATERIAL_PRICE_SUCCESS:
      return {
        ...state,
        materialPrices: state.materialPrices.map(materialshop =>
          materialshop.id.toString() === action.payload.id.toString()
            ? { materialshop, ...action.payload }
            : materialshop
        ),
      }

    case UPDATE_MATERIAL_PRICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_MATERIAL_PRICE_SUCCESS:
      return {
        ...state,
        materialPrices: state.materialPrices.filter(
          materialshop =>
            materialshop.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_MATERIAL_PRICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MATERIAL_PRICE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default materialPrices
