import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_PROJECTS,
  GET_PROJECT_PROFILE,
  ADD_NEW_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
} from "./actionTypes"

import {
  getProjectsSuccess,
  getCheckProjectBoqsSuccess,
  getProjectsFail,
  getProjectProfileSuccess,
  getProjectProfileFail,
  addProjectFail,
  addProjectSuccess,
  updateProjectSuccess,
  updateProjectFail,
  deleteProjectSuccess,
  deleteProjectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProjects,
  getProjectProfile,
  addNewProject,
  updateProject,
  deleteProject,
} from "../../helpers/fakebackend_helper"

function* fetchProjects({ payload: data }) {
  try {
    const response = yield call(getProjects, data)
    if (data.type == "check_boq") {
      yield put(getCheckProjectBoqsSuccess(response))
    } else {
      yield put(getProjectsSuccess(response))
    }
  } catch (error) {
    yield put(getProjectsFail(error))
  }
}

function* fetchProjectProfile({ payload: id }) {
  // console.log(id)updateProject
  try {
    const response = yield call(getProjectProfile, id)
    yield put(getProjectProfileSuccess(response))
  } catch (error) {
    yield put(getProjectProfileFail(error))
  }
}

function* onUpdateProject({ payload: project }) {
  try {
    const response = yield call(updateProject, project)
    // console.log("response", response)
    if (response.type == "update" || response.type == "cancel") {
      yield put(deleteProjectSuccess(response))
    } else {
      yield put(updateProjectSuccess(response))
    }
    if (response.type == "cancel") {
      1
      Notifications({
        alert: true,
        positionClass: "toast-top-right",
        toastType: "warning",
        title: "ยกเลิก",
        message: "ยกเลิกสำเร็จ",
      })
    } else {
      Notifications({
        alert: true,
        positionClass: "toast-top-right",
        toastType: "success",
        title: "อัพเดต",
        message: "อัพเดตสำเร็จ",
      })
    }
  } catch (error) {
    yield put(updateProjectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
    console.log("Error", error)
  }
}

function* onDeleteProject({ payload: project }) {
  try {
    const response = yield call(deleteProject, project)
    yield put(deleteProjectSuccess(response))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ลบ",
      message: "ลบสำเร็จ",
    })
  } catch (error) {
    yield put(deleteProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ลบ",
      message: "ลบล้มเหลว",
    })
  }
}

function* onAddNewProject({ payload: project }) {
  try {
    const response = yield call(addNewProject, project)
    yield put(addProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* projectsSaga() {
  yield takeEvery(GET_PROJECTS, fetchProjects)
  yield takeEvery(GET_PROJECT_PROFILE, fetchProjectProfile)
  yield takeEvery(ADD_NEW_PROJECT, onAddNewProject)
  yield takeEvery(UPDATE_PROJECT, onUpdateProject)
  yield takeEvery(DELETE_PROJECT, onDeleteProject)
}

export default projectsSaga
