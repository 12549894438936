import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_BOQS,
  GET_BOQ_PROFILE,
  ADD_NEW_BOQ,
  DELETE_BOQ,
  UPDATE_BOQ,
  UPLOAD_IMG_CHECK_BOQS,
  CONFIRM_BUY_MATERIAL,
  CONFIRM_SELECT_MATERIAL,
} from "./actionTypes"

import {
  getSumBoqsSuccess,
  getMateiralBoqSuccess,
  getCheckBoqsSuccess,
  getBoqsSuccess,
  getBoqsFail,
  getBoqProfileSuccess,
  getBoqProfileFail,
  addBoqFail,
  addBoqSuccess,
  updateBoqSuccess,
  updateBoqFail,
  deleteBoqSuccess,
  deleteBoqFail,
  uploadImgCheckBoqs,
} from "./actions"

//Include Both Helper File with needed methods
import {
  confirmBuyMaterial,
  getBoqs,
  uploadImgCheckBoq,
  getBoqProfile,
  addNewBoq,
  updateBoq,
  deleteBoq,
} from "../../helpers/fakebackend_helper"

function* fetchBoqs({ payload: data }) {
  try {
    const response = yield call(getBoqs, data)
    // console.log("response", response)
    if (data.type == "list") {
      yield put(getBoqsSuccess(response))
    } else if (data.type == "check_boq") {
      yield put(getCheckBoqsSuccess(response))
    } else if (data.type == "sum_boq") {
      yield put(getSumBoqsSuccess(response))
    }
  } catch (error) {
    yield put(getBoqsFail(error))
  }
}

function* fetchBoqProfile({ payload: id }) {
  // console.log(id)updateBoq
  try {
    const response = yield call(getBoqProfile, id)
    yield put(getBoqProfileSuccess(response))
  } catch (error) {
    yield put(getBoqProfileFail(error))
  }
}

function* onUpdateBoq({ payload: boqs }) {
  try {
    // let x = boqs.get('formData')
    // console.log(boqs)
    const response = yield call(updateBoq, boqs)
    // console.log("response", response)

    // yield put(getBoqsSuccess(response))
    // yield put(updateBoqSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateBoqFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
    // console.log("Error")
  }
}

function* onDeleteBoq({ payload: boqs }) {
  try {
    const response = yield call(deleteBoq, boqs)
    yield put(deleteBoqSuccess(response))
  } catch (error) {
    yield put(deleteBoqFail(error))
  }
}

function* onAddNewBoq({ payload: boqs }) {
  try {
    const response = yield call(addNewBoq, boqs)
    // console.log("response", response)
    yield put(getBoqsSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ทำรายการ",
      message: "สำเร็จ",
    })
  } catch (error) {
    yield put(addBoqFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ทำรายการ",
      message: "ล้มเหลว",
    })
  }
}

function* onConfirmSelectMaterial({ payload: material }) {
    // console.log("material", material)
  try {
    const response = yield call(confirmBuyMaterial, material)
    // console.log("response", response)
    yield put(getMateiralBoqSuccess(response))
    // Notifications({
    //   alert: true,
    //   positionClass: "toast-top-right",
    //   toastType: "success",
    //   title: "ทำรายการ",
    //   message: "สำเร็จ",
    // })
  } catch (error) {
    yield put(addBoqFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ทำรายการ",
      message: "ล้มเหลว",
    })
  }
}

function* onConfirmBuyMaterial({ payload: boqs }) {
  try {
    const response = yield call(confirmBuyMaterial, boqs)
    // console.log("response", response)
    yield put(getSumBoqsSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ทำรายการ",
      message: "สำเร็จ",
    })
  } catch (error) {
    yield put(addBoqFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ทำรายการ",
      message: "ล้มเหลว",
    })
  }
}

function* uploadImgCheck({ payload: data }) {
  try {
    const response = yield call(uploadImgCheckBoq, data)
    // console.log("response", response)
    yield put(getCheckBoqsSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ทำรายการ",
      message: "สำเร็จ",
    })
  } catch (error) {
    yield put(getBoqsFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ทำรายการ",
      message: "ล้มเหลว",
    })
  }
}

function* contactsSaga() {
  yield takeEvery(GET_BOQS, fetchBoqs)
  yield takeEvery(GET_BOQ_PROFILE, fetchBoqProfile)
  yield takeEvery(ADD_NEW_BOQ, onAddNewBoq)
  yield takeEvery(UPDATE_BOQ, onUpdateBoq)
  yield takeEvery(DELETE_BOQ, onDeleteBoq)
  yield takeEvery(UPLOAD_IMG_CHECK_BOQS, uploadImgCheck)
  yield takeEvery(CONFIRM_BUY_MATERIAL, onConfirmBuyMaterial)
  yield takeEvery(CONFIRM_SELECT_MATERIAL, onConfirmSelectMaterial)
}

export default contactsSaga
