/* BOQ_HOMES */
export const GET_BOQ_HOMES = "GET_BOQ_HOMES"
export const GET_BOQ_HOMES_SUCCESS = "GET_BOQ_HOMES_SUCCESS"
export const GET_BOQ_HOMES_FAIL = "GET_BOQ_HOMES_FAIL"

/* BOQ_HOMES PROFILE */
export const GET_BOQ_HOME_PROFILE = "GET_BOQ_HOME_PROFILE"
export const GET_BOQ_HOME_PROFILE_SUCCESS = "GET_BOQ_HOME_PROFILE_SUCCESS"
export const GET_BOQ_HOME_PROFILE_FAIL = "GET_BOQ_HOME_PROFILE_FAIL"

/**
 * add boqhome
 */
export const ADD_NEW_BOQ_HOME = "ADD_NEW_BOQ_HOME"
export const ADD_BOQ_HOME_SUCCESS = "ADD_BOQ_HOME_SUCCESS"
export const ADD_BOQ_HOME_FAIL = "ADD_BOQ_HOME_FAIL"

/**
 * Edit boqhome
 */
export const UPDATE_BOQ_HOME = "UPDATE_BOQ_HOME"
export const UPDATE_BOQ_HOME_SUCCESS = "UPDATE_BOQ_HOME_SUCCESS"
export const UPDATE_BOQ_HOME_FAIL = "UPDATE_BOQ_HOME_FAIL"

/**
 * Delete boqhome
 */
export const DELETE_BOQ_HOME = "DELETE_BOQ_HOME"
export const DELETE_BOQ_HOME_SUCCESS = "DELETE_BOQ_HOME_SUCCESS"
export const DELETE_BOQ_HOME_FAIL = "DELETE_BOQ_HOME_FAIL"
