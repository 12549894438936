import {
  GET_DESIGN_PROFILE,
  GET_DESIGN_PROFILE_FAIL,
  GET_DESIGN_PROFILE_SUCCESS,
  GET_DESIGNS,
  GET_DESIGNS_FAIL,
  GET_DESIGNS_SUCCESS,
  ADD_NEW_DESIGN,
  ADD_DESIGN_SUCCESS,
  ADD_DESIGN_FAIL,
  UPDATE_DESIGN,
  UPDATE_DESIGN_SUCCESS,
  UPDATE_DESIGN_FAIL,
  DELETE_DESIGN,
  DELETE_DESIGN_SUCCESS,
  DELETE_DESIGN_FAIL,
} from "./actionTypes"

export const getDesigns = id => ({
  type: GET_DESIGNS,
  payload: id,
})

export const getDesignsSuccess = designs => ({
  type: GET_DESIGNS_SUCCESS,
  payload: designs,
})

export const addNewDesign = design => ({
  type: ADD_NEW_DESIGN,
  payload: design,
})

export const addDesignSuccess = design => ({
  type: ADD_DESIGN_SUCCESS,
  payload: design,
})

export const addDesignFail = error => ({
  type: ADD_DESIGN_FAIL,
  payload: error,
})

export const getDesignsFail = error => ({
  type: GET_DESIGNS_FAIL,
  payload: error,
})

export const getDesignProfile = id => ({
  type: GET_DESIGN_PROFILE,
  payload: id,
})

export const getDesignProfileSuccess = designProfile => ({
  type: GET_DESIGN_PROFILE_SUCCESS,
  payload: designProfile,
})

export const getDesignProfileFail = error => ({
  type: GET_DESIGN_PROFILE_FAIL,
  payload: error,
})

export const updateDesign = design => ({
  type: UPDATE_DESIGN,
  payload: design,
})

// export const updateDesign = design => console.log(design);

export const updateDesignSuccess = design => ({
  type: UPDATE_DESIGN_SUCCESS,
  payload: design,
})

export const updateDesignFail = error => ({
  type: UPDATE_DESIGN_FAIL,
  payload: error,
})

export const deleteDesign = design => ({
  type: DELETE_DESIGN,
  payload: design,
})

export const deleteDesignSuccess = design => ({
  type: DELETE_DESIGN_SUCCESS,
  payload: design,
})

export const deleteDesignFail = error => ({
  type: DELETE_DESIGN_FAIL,
  payload: error,
})
