import {
  GET_BOQ_HOMES_SUCCESS,
  GET_BOQ_HOMES_FAIL,
  ADD_BOQ_HOME_SUCCESS,
  ADD_BOQ_HOME_FAIL,
  UPDATE_BOQ_HOME_SUCCESS,
  UPDATE_BOQ_HOME_FAIL,
  DELETE_BOQ_HOME_SUCCESS,
  DELETE_BOQ_HOME_FAIL,
  GET_BOQ_HOME_PROFILE_SUCCESS,
  GET_BOQ_HOME_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  boqHomes: [],
  boqHomeProfile: {},
  error: {},
}

const boqHomes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BOQ_HOMES_SUCCESS:
      // console.log(action)
      return {
        ...state,
        boqHomes: action.payload,
      }

    case GET_BOQ_HOMES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_BOQ_HOME_SUCCESS:
      return {
        ...state,
        boqHomes: [...state.boqHomes, action.payload],
      }

    case ADD_BOQ_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BOQ_HOME_PROFILE_SUCCESS:
      // console.log("GET_BOQ_HOME_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        boqHomeProfile: action.payload,
      }

    case UPDATE_BOQ_HOME_SUCCESS:
      return {
        ...state,
        boqHomes: state.boqHomes.map(boqhome =>
          boqhome.id.toString() === action.payload.id.toString()
            ? { boqhome, ...action.payload }
            : boqhome
        ),
      }

    case UPDATE_BOQ_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BOQ_HOME_SUCCESS:
      return {
        ...state,
        boqHomes: state.boqHomes.filter(
          boqhome =>
            boqhome.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_BOQ_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BOQ_HOME_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default boqHomes
