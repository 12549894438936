import React from "react"
import { Container, Row, Col } from "reactstrap"

//Import Components
import FeatureBox from "./price-box"

//Import images
import feature2 from "../../../../assets/images/crypto/features-img/img-2.jpg"

const Prices = () => {
  const features1 = [
    { id: 1, desc: "ขนาด 4x3 เมตร 179,000 บาท" },
    { id: 2, desc: "ขนาด 6x3 เมตร 229,000 บาท" },
    { id: 3, desc: "ขนาด 6x4 เมตร 290,000 บาท" },
    { id: 4, desc: "ขนาด 8x4 เมตร 395,000 บาท" },
    { id: 5, desc: "ขนาด 7x4 เมตร 360,000 บาท" },
    { id: 6, desc: "ขนาด 8x6 เมตร 560,000 บาท ( โปรโมชั่นพิเศษจำนวนจำกัด )" },
    { id: 7, desc: "ขนาด 10x4 เมตร 490,000 บาท" },
  ]

  return (
    <React.Fragment>
      <section className="section bg-white" id="prices">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">Prices</div>
                <h4>Key prices of the product</h4>
              </div>
            </Col>
          </Row>

          {/* <Row className="align-items-center pt-4">
            <Col md="6" sm="8">
              <div>
                <img
                  src={feature1}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
            <Col md="5" className="ms-auto">
              featurebox
              <FeatureBox
                num="01"
                title="Lending"
                features={features1}
                desc="If several languages coalesce, the grammar of the resulting language is more simple and regular than of the individual will be more simple and regular than the existing."
              />
            </Col>
          </Row> */}

          <Row className="align-items-center mt-5 pt-4">
            <Col md="5">
              <FeatureBox
                num="02"
                title="Prices"
                features={features1}
                desc="เราขายคุณภาพและดีไซย์แต่มีทัศนคติที่ว่าเราจะขายบ้านที่สวยและมีคุณภาพดีในราคาที่เข้าถึงได้ ฉนั้นเราจึงรับประกันโครงสร้างและการรั่วซึมหากไม่ได้เกิดจากการกระทำ เปนระยะเวลานานถึง 1 ปี ลูกค้าสามารถออกแบบดีไซย์ได้ให้เหมาะกับการใช้งานเรามีทีมงานสถาปนิกออกแบบตามความ ต้องของลูกค้าโดยใช้วัสดุพื้นฐานตามรูปแบบบริษัท ราคาไม่รวมค่าขนส่ง หากสร้างหน้างาน 10% +ตามระยะทาง"
              />
            </Col>
            <Col md="6" sm="8" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={feature2}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Prices
