import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_WITHDRAW_MATERIALS,
  GET_WITHDRAW_MATERIAL_PROFILE,
  ADD_NEW_WITHDRAW_MATERIAL,
  DELETE_WITHDRAW_MATERIAL,
  UPDATE_WITHDRAW_MATERIAL,
} from "./actionTypes"

import {
  getWithdrawMaterialsSuccess,
  getWithdrawMaterialsSuccessSummarize,
  getWithdrawMaterialsFail,
  getWithdrawMaterialProfileSuccess,
  getWithdrawMaterialProfileFail,
  addWithdrawMaterialFail,
  addWithdrawMaterialSuccess,
  updateWithdrawMaterialSuccess,
  updateWithdrawMaterialFail,
  deleteWithdrawMaterialSuccess,
  deleteWithdrawMaterialFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getWithdrawMaterials,
  getWithdrawMaterialProfile,
  addNewWithdrawMaterial,
  updateWithdrawMaterial,
  deleteWithdrawMaterial,
} from "../../helpers/fakebackend_helper"

function* fetchWithdrawMaterials({ payload: data }) {
  // console.log("active", 111)
  // console.log("material_type_id", data.material_type_id)
  // console.log("11231231")

  try {
    // console.log("fetchWithdrawMaterials", data)
    const response = yield call(getWithdrawMaterials, data)
    // console.log("getWithdrawMaterials", response)
    if (response.type == "select") {
      yield put(getWithdrawMaterialsSuccess(response.query))
    } else if (response.type == "summarize") {
      yield put(getWithdrawMaterialsSuccessSummarize(response.query))
    }
  } catch (error) {
    yield put(getWithdrawMaterialsFail(error))
  }
}

function* fetchWithdrawMaterialProfile({ payload: id }) {
  // console.log(id)
  try {
    const response = yield call(getWithdrawMaterialProfile, id)
    // console.log("fetchWithdrawMaterialProfil", response)
    yield put(getWithdrawMaterialProfileSuccess(response))
  } catch (error) {
    yield put(getWithdrawMaterialProfileFail(error))
  }
}

function* onUpdateWithdrawMaterial({ payload: withdrawmaterial }) {
  try {
    // let x = withdrawmaterial.get('formData')
    // console.log(x)
    const response = yield call(updateWithdrawMaterial, withdrawmaterial)
    // yield put(updateWithdrawMaterialSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateWithdrawMaterialFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteWithdrawMaterial({ payload: withdrawmaterial }) {
  try {
    const response = yield call(deleteWithdrawMaterial, withdrawmaterial)
    yield put(deleteWithdrawMaterialSuccess(response))
  } catch (error) {
    yield put(deleteWithdrawMaterialFail(error))
  }
}

function* onAddNewWithdrawMaterial({ payload: withdrawmaterial }) {
  try {
    const response = yield call(addNewWithdrawMaterial, withdrawmaterial)
    // console.log("onAddNewWithdrawMaterial", response)
    let x = withdrawmaterial.get("action")
    // console.log(x)
    if (x == "Add") {
      yield put(addWithdrawMaterialSuccess(response))
    } else {
      yield put(updateWithdrawMaterialSuccess(response))
    }
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addWithdrawMaterialFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* withdrawMaterials() {
  yield takeEvery(GET_WITHDRAW_MATERIALS, fetchWithdrawMaterials)
  yield takeEvery(GET_WITHDRAW_MATERIAL_PROFILE, fetchWithdrawMaterialProfile)
  yield takeEvery(ADD_NEW_WITHDRAW_MATERIAL, onAddNewWithdrawMaterial)
  yield takeEvery(UPDATE_WITHDRAW_MATERIAL, onUpdateWithdrawMaterial)
  yield takeEvery(DELETE_WITHDRAW_MATERIAL, onDeleteWithdrawMaterial)
}

export default withdrawMaterials
