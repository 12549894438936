import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
  Collapse,
} from "reactstrap"
import classnames from "classnames"

import { AvForm, AvInput } from "availity-reactstrap-validation"
import {
  getQuotationConProjects as onGetgetQuotationConProjects,
  updateQuotationConProject as onUpdateQuotationConProject,
} from "store/quotations-con-project/actions"
// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()

  const { quotationconprojects } = useSelector(state => ({
    quotationconprojects: state.quotationsConProject.quotationconprojects,
  }))

  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [material, setMaterial] = React.useState([])
  const [note, setNote] = React.useState([])
  const [standard, setStandard] = React.useState([])
  const [annotation, setAnnotation] = React.useState([])

  const [col1, setCol1] = React.useState(false)
  const [col2, setCol2] = React.useState(false)
  const [col3, setCol3] = React.useState(false)
  const [col4, setCol4] = React.useState(false)

  async function checkAction() {
    setMaterial([])
    setNote([])
    setStandard([])
    setAnnotation([])

    if (actionModal.action == "Edit") {
      // console.log('1');
      dispatch(onGetgetQuotationConProjects(actionModal.id))
    }

    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
  }

  const handleSubmit = async (event, errors, values) => {
    // console.log("1", 1)
    setButtonSubmit(true)
    if (errors.length > 0) {
    } else {
      if (action == "Add") {
      } else if (action == "Edit") {
        const data = new FormData()
        data.append("material", JSON.stringify(material))
        data.append("note", JSON.stringify(note))
        data.append("standard", JSON.stringify(standard))
        data.append("annotation", JSON.stringify(annotation))

        // update contractor
        // console.log("2", 2)

        dispatch(onUpdateQuotationConProject(data))
      }
      setModal(!modal)
    }
    setButtonSubmit(false)
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      // setFormData(quotationconprojects)
      let { material, note, standard, annotation, ...rest } =
        quotationconprojects
      // console.log("material", material)
      // console.log("note", note)
      // console.log("standard", standard)
      setMaterial(material)
      setNote(note)
      setStandard(standard)
      setAnnotation(annotation)
    }
    return () => {}
  }, [quotationconprojects]) // Only re-run the effect if count changes

  function addRow_material() {
    const modifiedRows = [...material]
    modifiedRows.push({
      project_id: id,
      name: "",
      group: 1,
      del: 1,
    })
    setMaterial(modifiedRows)
  }
  function addRow_note() {
    const modifiedRows = [...note]
    modifiedRows.push({
      project_id: id,
      name: "",
      group: 2,
      del: 1,
    })
    setNote(modifiedRows)
  }
  function addRow_standard() {
    const modifiedRows = [...standard]
    modifiedRows.push({
      project_id: id,
      name: "",
      group: 3,
      del: 1,
    })
    setStandard(modifiedRows)
  }

  function addRow_annotation() {
    const modifiedRows = [...annotation]
    modifiedRows.push({
      project_id: id,
      name: "",
      group: 4,
      del: 1,
    })
    setAnnotation(modifiedRows)
  }
  const t_col1 = () => {
    setCol1(!col1)
    setCol2(false)
    setCol3(false)
    setCol4(false)
  }

  const t_col2 = () => {
    setCol2(!col2)
    setCol1(false)
    setCol3(false)
    setCol4(false)
  }

  const t_col3 = () => {
    setCol3(!col3)
    setCol1(false)
    setCol2(false)
    setCol4(false)
  }
  const t_col4 = () => {
    setCol4(!col4)
    setCol1(false)
    setCol2(false)
    setCol3(false)
  }

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
      size="lg"
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}เงื่อนไขใบเสนอราคา{" "}
      </ModalHeader>
      <AvForm onSubmit={handleSubmit}>
        <ModalBody>
          <h5>สามารถใส่ได้สูงสุด 1,000 อักษร</h5>
          <hr />
          <div className="accordion" id="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: !col4,
                  })}
                  type="button"
                  onClick={t_col4}
                  style={{ cursor: "pointer" }}
                >
                  ข้อความใต้ตาราง
                </button>
              </h2>

              <Collapse isOpen={col4} className="accordion-collapse">
                <div className="accordion-body">
                  <Row>
                    {(annotation || []).map((item, index) =>
                      item.del == 0 ? null : (
                        <Col xs={12} key={index}>
                          <div className="hstack gap-3">
                            <div className="text-center">
                              <h4 style={{ width: "50px" }}>{index + 1}</h4>
                            </div>

                            <div style={{ width: "100%" }}>
                              <AvInput
                                name={"annotation_name_" + index}
                                type="textarea"
                                className="form-control me-auto"
                                placeholder="หัวข้อเรื่อง"
                                value={annotation[index].name}
                                onChange={e => {
                                  let select = [...annotation]
                                  select[index].name = e.target.value
                                  setMaterial(select)
                                }}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="vr"></div>
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={e => {
                                let select = [...annotation]
                                select[index].del = 0
                                setMaterial(select)
                              }}
                            >
                              X
                            </button>
                          </div>
                        </Col>
                      )
                    )}
                    <Button
                      color="primary"
                      className="btn btn-primary "
                      size="sm"
                      onClick={e => addRow_annotation()}
                    >
                      เพิ่มใหม่
                    </Button>
                  </Row>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: !col1,
                  })}
                  type="button"
                  onClick={t_col1}
                  style={{ cursor: "pointer" }}
                >
                  วัสดุพื้นฐาน รายละเอียดวัสดุที่ใช้ในการดําเนินการโดยประมาณ
                </button>
              </h2>

              <Collapse isOpen={col1} className="accordion-collapse">
                <div className="accordion-body">
                  <Row>
                    {(material || []).map((item, index) =>
                      item.del == 0 ? null : (
                        <Col xs={12} key={index}>
                          <div className="hstack gap-3">
                            <div className="text-center">
                              <h4 style={{ width: "50px" }}>{index + 1}</h4>
                            </div>

                            <div style={{ width: "100%" }}>
                              <AvInput
                                name={"material_name_" + index}
                                type="textarea"
                                className="form-control me-auto"
                                placeholder="หัวข้อเรื่อง"
                                value={material[index].name}
                                onChange={e => {
                                  let select = [...material]
                                  select[index].name = e.target.value
                                  setMaterial(select)
                                }}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="vr"></div>
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={e => {
                                let select = [...material]
                                select[index].del = 0
                                setMaterial(select)
                              }}
                            >
                              X
                            </button>
                          </div>
                        </Col>
                      )
                    )}
                    <Button
                      color="primary"
                      className="btn btn-primary "
                      size="sm"
                      onClick={e => addRow_material()}
                    >
                      เพิ่มใหม่
                    </Button>
                  </Row>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: !col2,
                  })}
                  type="button"
                  onClick={t_col2}
                  style={{ cursor: "pointer" }}
                >
                  กรณีมีห้องน้ํา
                </button>
              </h2>

              <Collapse isOpen={col2} className="accordion-collapse">
                <div className="accordion-body">
                  <Row>
                    {(note || []).map((item, index) =>
                      item.del == 0 ? null : (
                        <Col xs={12} key={index}>
                          <div className="hstack gap-3">
                            <div className="text-center">
                              <h4 style={{ width: "50px" }}>{index + 1}</h4>
                            </div>
                            <div style={{ width: "100%" }}>
                              <AvInput
                                name={"material_note_" + index}
                                type="textarea"
                                className="form-control me-auto"
                                placeholder="หัวข้อเรื่อง"
                                value={note[index].name}
                                onChange={e => {
                                  let select = [...note]
                                  select[index].name = e.target.value
                                  setMaterial(select)
                                }}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="vr"></div>
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={e => {
                                let select = [...note]
                                select[index].del = 0
                                setMaterial(select)
                              }}
                            >
                              X
                            </button>
                          </div>
                        </Col>
                      )
                    )}
                    <Button
                      color="primary"
                      className="btn btn-primary "
                      size="sm"
                      onClick={e => addRow_note()}
                    >
                      เพิ่มใหม่
                    </Button>
                  </Row>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: !col3,
                  })}
                  type="button"
                  onClick={t_col3}
                  style={{ cursor: "pointer" }}
                >
                  มาตรฐานในการก่อสร้าง
                </button>
              </h2>
              <Collapse isOpen={col3} className="accordion-collapse">
                <div className="accordion-body">
                  <Row>
                    {(standard || []).map((item, index) =>
                      item.del == 0 ? null : (
                        <Col xs={12} key={index}>
                          <div className="hstack gap-3">
                            <div className="text-center">
                              <h4 style={{ width: "50px" }}>{index + 1}</h4>
                            </div>

                            <div style={{ width: "100%" }}>
                              <AvInput
                                name={"material_standard_" + index}
                                type="textarea"
                                className="form-control me-auto"
                                placeholder="หัวข้อเรื่อง"
                                value={standard[index].name}
                                onChange={e => {
                                  let select = [...standard]
                                  select[index].name = e.target.value
                                  setMaterial(select)
                                }}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="vr"></div>
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={e => {
                                let select = [...standard]
                                select[index].del = 0
                                setMaterial(select)
                              }}
                            >
                              X
                            </button>
                          </div>
                        </Col>
                      )
                    )}
                    <Button
                      color="primary"
                      className="btn btn-primary "
                      size="sm"
                      onClick={e => addRow_standard()}
                    >
                      เพิ่มใหม่
                    </Button>
                  </Row>
                </div>
              </Collapse>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            Save
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
