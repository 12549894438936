/* QUOTATIONPROJECTS */
export const GET_QUOTATIONPROJECTS = "GET_QUOTATIONPROJECTS"
export const GET_QUOTATIONPROJECTS_SUCCESS = "GET_QUOTATIONPROJECTS_SUCCESS"
export const GET_QUOTATIONPROJECTS_FAIL = "GET_QUOTATIONPROJECTS_FAIL"

/* QUOTATIONPROJECTS PROFILE */
export const GET_QUOTATIONPROJECT_PROFILE = "GET_QUOTATIONPROJECT_PROFILE"
export const GET_QUOTATIONPROJECT_PROFILE_SUCCESS = "GET_QUOTATIONPROJECT_PROFILE_SUCCESS"
export const GET_QUOTATIONPROJECT_PROFILE_FAIL = "GET_QUOTATIONPROJECT_PROFILE_FAIL"

/**
 * add quotationproject
 */
export const ADD_NEW_QUOTATIONPROJECT = "ADD_NEW_QUOTATIONPROJECT"
export const ADD_QUOTATIONPROJECT_SUCCESS = "ADD_QUOTATIONPROJECT_SUCCESS"
export const ADD_QUOTATIONPROJECT_FAIL = "ADD_QUOTATIONPROJECT_FAIL"

/**
 * Edit quotationproject
 */
export const UPDATE_QUOTATIONPROJECT = "UPDATE_QUOTATIONPROJECT"
export const UPDATE_QUOTATIONPROJECT_SUCCESS = "UPDATE_QUOTATIONPROJECT_SUCCESS"
export const UPDATE_QUOTATIONPROJECT_FAIL = "UPDATE_QUOTATIONPROJECT_FAIL"

/**
 * Delete quotationproject
 */
export const DELETE_QUOTATIONPROJECT = "DELETE_QUOTATIONPROJECT"
export const DELETE_QUOTATIONPROJECT_SUCCESS = "DELETE_QUOTATIONPROJECT_SUCCESS"
export const DELETE_QUOTATIONPROJECT_FAIL = "DELETE_QUOTATIONPROJECT_FAIL"

