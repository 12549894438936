import {
  GET_LIST_QC_PROFILE,
  GET_LIST_QC_PROFILE_FAIL,
  GET_LIST_QC_PROFILE_SUCCESS,
  GET_LIST_QCS,
  GET_LIST_QCS_FAIL,
  GET_LIST_QCS_SUCCESS,
  ADD_NEW_LIST_QC,
  ADD_LIST_QC_SUCCESS,
  ADD_LIST_QC_FAIL,
  UPDATE_LIST_QC,
  UPDATE_LIST_QC_SUCCESS,
  UPDATE_LIST_QC_FAIL,
  DELETE_LIST_QC,
  DELETE_LIST_QC_SUCCESS,
  DELETE_LIST_QC_FAIL,
} from "./actionTypes"

export const getListQcs = active => ({
  type: GET_LIST_QCS,
  payload: active,
})

export const getListQcsSuccess = qcs => ({
  type: GET_LIST_QCS_SUCCESS,
  payload: qcs,
})

export const addNewListQc = qc => ({
  type: ADD_NEW_LIST_QC,
  payload: qc,
})

export const addListQcSuccess = qc => ({
  type: ADD_LIST_QC_SUCCESS,
  payload: qc,
})

export const addListQcFail = error => ({
  type: ADD_LIST_QC_FAIL,
  payload: error,
})

export const getListQcsFail = error => ({
  type: GET_LIST_QCS_FAIL,
  payload: error,
})

export const getListQcProfile = id => ({
  type: GET_LIST_QC_PROFILE,
  payload: id,
})

export const getListQcProfileSuccess = qcProfile => ({
  type: GET_LIST_QC_PROFILE_SUCCESS,
  payload: qcProfile,
})

export const getListQcProfileFail = error => ({
  type: GET_LIST_QC_PROFILE_FAIL,
  payload: error,
})

export const updateListQc = qc => ({
  type: UPDATE_LIST_QC,
  payload: qc,
})

// export const updateListQc = qc => console.log(qc);

export const updateListQcSuccess = qc => ({
  type: UPDATE_LIST_QC_SUCCESS,
  payload: qc,
})

export const updateListQcFail = error => ({
  type: UPDATE_LIST_QC_FAIL,
  payload: error,
})

export const deleteListQc = qc => ({
  type: DELETE_LIST_QC,
  payload: qc,
})

export const deleteListQcSuccess = qc => ({
  type: DELETE_LIST_QC_SUCCESS,
  payload: qc,
})

export const deleteListQcFail = error => ({
  type: DELETE_LIST_QC_FAIL,
  payload: error,
})
