/* PROFITLOSSS */
export const GET_PROFITLOSSS = "GET_PROFITLOSSS"
export const GET_PROFITLOSSS_SUCCESS = "GET_PROFITLOSSS_SUCCESS"
export const GET_PROFITLOSSS_FAIL = "GET_PROFITLOSSS_FAIL"

/* PROFITLOSSS PROFILE */
export const GET_PROFITLOSS_PROFILE = "GET_PROFITLOSS_PROFILE"
export const GET_PROFITLOSS_PROFILE_SUCCESS = "GET_PROFITLOSS_PROFILE_SUCCESS"
export const GET_PROFITLOSS_PROFILE_FAIL = "GET_PROFITLOSS_PROFILE_FAIL"

/**
 * add profitlossproject
 */
export const ADD_NEW_PROFITLOSS = "ADD_NEW_PROFITLOSS"
export const ADD_PROFITLOSS_SUCCESS = "ADD_PROFITLOSS_SUCCESS"
export const ADD_PROFITLOSS_FAIL = "ADD_PROFITLOSS_FAIL"

/**
 * Edit profitlossproject
 */
export const UPDATE_PROFITLOSS = "UPDATE_PROFITLOSS"
export const UPDATE_PROFITLOSS_SUCCESS = "UPDATE_PROFITLOSS_SUCCESS"
export const UPDATE_PROFITLOSS_FAIL = "UPDATE_PROFITLOSS_FAIL"

/**
 * Delete profitlossproject
 */
export const DELETE_PROFITLOSS = "DELETE_PROFITLOSS"
export const DELETE_PROFITLOSS_SUCCESS = "DELETE_PROFITLOSS_SUCCESS"
export const DELETE_PROFITLOSS_FAIL = "DELETE_PROFITLOSS_FAIL"

