import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_DESIGNHOMES,
  GET_DESIGNHOME_PROFILE,
  ADD_NEW_DESIGNHOME,
  DELETE_DESIGNHOME,
  UPDATE_DESIGNHOME,
  UPLOAD_DESIGNHOMEIMG,
  CHANGEFIRST_DESIGNHOMEIMG,
  DELETE_DESIGNHOMEIMG,
} from "./actionTypes"

import {
  getDesignHomesSuccess,
  getDesignHomesFail,
  getDesignHomeProfileSuccess,
  getDesignHomeProfileFail,
  addDesignHomeFail,
  addDesignHomeSuccess,
  updateDesignHomeSuccess,
  updateDesignHomeFail,
  deleteDesignHomeSuccess,
  deleteDesignHomeFail,
  getChangeFirstDesignHomeSuccess,
  getdelDesignHomeSuccess,
  getDesignAchievementHomesSuccess,
  getDesignPromotionHomesSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDesignHomes,
  getDesignHomeProfile,
  addNewDesignHome,
  updateDesignHome,
  deleteDesignHome,
  uploadDesigmHomeImg,
  changeFirstDesignHome,
  delImgDesignHome,
} from "../../helpers/fakebackend_helper"

function* fetchDesignHomes({ payload: type }) {
  try {
    const response = yield call(getDesignHomes, type)
    if (type == "backend") {
      yield put(getDesignHomesSuccess(response.product_all))
    } else if (type == "info") {
      yield put(getDesignHomesSuccess(response.product_all))
    } else {
      yield put(getDesignHomesSuccess(response.product_all))
      yield put(getDesignAchievementHomesSuccess(response.product_achievement))
      yield put(getDesignPromotionHomesSuccess(response.product_promotion))
    }
  } catch (error) {
    yield put(getDesignHomesFail(error))
  }
}

function* fetchDesignHomeProfile({ payload: id }) {
  try {
    const response = yield call(getDesignHomeProfile, id)
    // console.log(response);
    yield put(getDesignHomeProfileSuccess(response))
  } catch (error) {
    yield put(getDesignHomeProfileFail(error))
  }
}

function* onUpdateDesignHome({ payload: designhome }) {
  try {
    // let x = designhome.get('formData')
    // console.log(x)
    const response = yield call(updateDesignHome, designhome)
    // console.log(response);
    yield put(updateDesignHomeSuccess(response))
    if (response.insert_status == 2) {
      Notifications({
        alert: true,
        positionClass: "toast-top-right",
        toastType: "success",
        title: "เพิ่ม",
        message: "เพิ่มสำเร็จ",
      })
    } else {
      Notifications({
        alert: true,
        positionClass: "toast-top-right",
        toastType: "success",
        title: "อัพเดต",
        message: "อัพเดตสำเร็จ",
      })
    }
  } catch (error) {
    console.log(error)
    yield put(updateDesignHomeFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteDesignHome({ payload: designhome }) {
  try {
    const response = yield call(deleteDesignHome, designhome)
    yield put(deleteDesignHomeSuccess(response))
  } catch (error) {
    yield put(deleteDesignHomeFail(error))
  }
}

function* onAddNewDesignHome({ payload: designhome }) {
  try {
    const response = yield call(addNewDesignHome, designhome)
    yield put(addDesignHomeSuccess(response))
    yield put(
      getDesignHomeProfileSuccess({
        id: response.id,
      })
    )

    // Notifications({
    //   alert: true,
    //   positionClass: "toast-top-right",
    //   toastType: "success",
    //   title: "เพิ่ม",
    //   message: "เพิ่มสำเร็จ",
    // })
  } catch (error) {
    yield put(addDesignHomeFail(error))

    // Notifications({
    //   alert: true,
    //   positionClass: "toast-top-right",
    //   toastType: "error",
    //   title: "เพิ่ม",
    //   message: "เพิ่มล้มเหลว",
    // })
  }
}

function* onUploadDesignHomeImg({ payload: img }) {
  try {
    const response = yield call(uploadDesigmHomeImg, img)
    yield put(getDesignHomeProfileSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพโหลด",
      message: "อัพโหลดสำเร็จ",
    })
  } catch (error) {
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพโหลด",
      message: "อัพโหลดล้มเหลว",
    })
  }
}

function* onUpChangeFirstDesignHomeImg({ payload: id }) {
  // console.log(1)

  try {
    const response = yield call(changeFirstDesignHome, id)
    // console.log(response);
    yield put(getDesignHomeProfileSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เปลื่ยนรูปภาหน้าปก",
      message: "เปลื่ยนรูปสำเร็จ",
    })
  } catch (error) {
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เปลื่ยนรูปภาหน้าปก",
      message: "เปลื่ยนรูปล้มเหลว",
    })
  }
}
function* onDeleteDesignHomeImg({ payload: id }) {
  // console.log(1)
  try {
    const response = yield call(delImgDesignHome, id)
    yield put(getDesignHomeProfileSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ลบรูป",
      message: "ลบสำเร็จ",
    })
  } catch (error) {
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ลบรูป",
      message: "ลบรูปล้มเหลว",
    })
  }
}

function* contactsSaga() {
  yield takeEvery(GET_DESIGNHOMES, fetchDesignHomes)
  yield takeEvery(GET_DESIGNHOME_PROFILE, fetchDesignHomeProfile)
  yield takeEvery(ADD_NEW_DESIGNHOME, onAddNewDesignHome)
  yield takeEvery(UPDATE_DESIGNHOME, onUpdateDesignHome)
  yield takeEvery(DELETE_DESIGNHOME, onDeleteDesignHome)
  yield takeEvery(UPLOAD_DESIGNHOMEIMG, onUploadDesignHomeImg)
  yield takeEvery(CHANGEFIRST_DESIGNHOMEIMG, onUpChangeFirstDesignHomeImg)
  yield takeEvery(DELETE_DESIGNHOMEIMG, onDeleteDesignHomeImg)
}

export default contactsSaga
