import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_CONTRACTORS,
  GET_CONTRACTOR_PROFILE,
  ADD_NEW_CONTRACTOR,
  DELETE_CONTRACTOR,
  UPDATE_CONTRACTOR,
} from "./actionTypes"

import {
  getContractorsSuccess,
  getContractorsFail,
  getContractorProfileSuccess,
  getContractorProfileFail,
  addContractorFail,
  addContractorSuccess,
  updateContractorSuccess,
  updateContractorFail,
  deleteContractorSuccess,
  deleteContractorFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getContractors,
  getContractorProfile,
  addNewContractor,
  updateContractor,
  deleteContractor,
} from "../../helpers/fakebackend_helper"

function* fetchContractors({ payload: active }) {
  try {
    const response = yield call(getContractors, active)
    // console.log(response);
    yield put(getContractorsSuccess(response))
  } catch (error) {
    yield put(getContractorsFail(error))
  }
}

function* fetchContractorProfile({ payload: id }) {
  // console.log(id)updateContractor
  try {
    const response = yield call(getContractorProfile, id)
    yield put(getContractorProfileSuccess(response))
  } catch (error) {
    yield put(getContractorProfileFail(error))
  }
}

function* onUpdateContractor({ payload: contractor }) {
  try {
    // let x = contractor.get('formData')
    // console.log(x)
    const response = yield call(updateContractor, contractor)
    yield put(updateContractorSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateContractorFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
    console.log("Error")
  }
}

function* onDeleteContractor({ payload: contractor }) {
  try {
    const response = yield call(deleteContractor, contractor)
    yield put(deleteContractorSuccess(response))
  } catch (error) {
    yield put(deleteContractorFail(error))
  }
}

function* onAddNewContractor({ payload: contractor }) {
  try {
    const response = yield call(addNewContractor, contractor)
    yield put(addContractorSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addContractorFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* contactsSaga() {
  yield takeEvery(GET_CONTRACTORS, fetchContractors)
  yield takeEvery(GET_CONTRACTOR_PROFILE, fetchContractorProfile)
  yield takeEvery(ADD_NEW_CONTRACTOR, onAddNewContractor)
  yield takeEvery(UPDATE_CONTRACTOR, onUpdateContractor)
  yield takeEvery(DELETE_CONTRACTOR, onDeleteContractor)
}

export default contactsSaga
