import {
  GET_CUSTOM_PROJECTS_SUCCESS,
  GET_CUSTOM_PROJECTS_FAIL,
  ADD_CUSTOM_PROJECT_SUCCESS,
  ADD_CUSTOM_PROJECT_FAIL,
  UPDATE_CUSTOM_PROJECT_SUCCESS,
  UPDATE_CUSTOM_PROJECT_FAIL,
  DELETE_CUSTOM_PROJECT_SUCCESS,
  DELETE_CUSTOM_PROJECT_FAIL,
  GET_CUSTOM_PROJECT_PROFILE_SUCCESS,
  GET_CUSTOM_PROJECT_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  customprojects: [],
  customprojectProfile: {},
  error: {},
}

const customprojects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOM_PROJECTS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        customprojects: action.payload,
      }

    case GET_CUSTOM_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CUSTOM_PROJECT_SUCCESS:
      return {
        ...state,
        customprojects: [...state.customprojects, action.payload],
      }

    case ADD_CUSTOM_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOM_PROJECT_PROFILE_SUCCESS:
      // console.log("GET_CUSTOM_PROJECT_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        customprojectProfile: action.payload,
      }

    case UPDATE_CUSTOM_PROJECT_SUCCESS:
      return {
        ...state,
        customprojects: state.customprojects.map(customproject =>
          customproject.id.toString() === action.payload.id.toString()
            ? { customproject, ...action.payload }
            : customproject
        ),
      }

    case UPDATE_CUSTOM_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CUSTOM_PROJECT_SUCCESS:
      return {
        ...state,
        customprojects: state.customprojects.filter(
          customproject =>
            customproject.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_CUSTOM_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOM_PROJECT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default customprojects
