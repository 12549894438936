import {
  GET_COMMENT_HOMES_SUCCESS,
  GET_COMMENT_HOMES_FAIL,
  ADD_COMMENT_HOME_SUCCESS,
  ADD_COMMENT_HOME_FAIL,
  UPDATE_COMMENT_HOME_SUCCESS,
  UPDATE_COMMENT_HOME_FAIL,
  DELETE_COMMENT_HOME_SUCCESS,
  DELETE_COMMENT_HOME_FAIL,
  GET_COMMENT_HOME_PROFILE_SUCCESS,
  GET_COMMENT_HOME_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  commentHomes: [],
  commentHomeProfile: {},
  error: {},
}

const commentHomes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMENT_HOMES_SUCCESS:
      // console.log(action)
      return {
        ...state,
        commentHomes: action.payload,
      }

    case GET_COMMENT_HOMES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_COMMENT_HOME_SUCCESS:
      return {
        ...state,
        commentHomes: [...state.commentHomes, action.payload],
      }

    case ADD_COMMENT_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMMENT_HOME_PROFILE_SUCCESS:
      // console.log("GET_COMMENT_HOME_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        commentHomeProfile: action.payload,
      }

    case UPDATE_COMMENT_HOME_SUCCESS:
      return {
        ...state,
        commentHomes: state.commentHomes.map(commentHome =>
          commentHome.id.toString() === action.payload.id.toString()
            ? { commentHome, ...action.payload }
            : commentHome
        ),
      }

    case UPDATE_COMMENT_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_COMMENT_HOME_SUCCESS:
      return {
        ...state,
        commentHomes: state.commentHomes.filter(
          commentHome =>
            commentHome.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_COMMENT_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMMENT_HOME_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default commentHomes
