import React from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "../Tables/datatables.scss"

import ExampleModal from "../../components/Dialogs/dialogList_DesignGroup"
// API store
import { getListDesignGroups as onGetList_designgroups } from "store/actions"

const ListDesignGroups = () => {
  const dispatch = useDispatch()

  const { listDesignGroups } = useSelector(state => ({
    listDesignGroups: state.listDesigngroup.listDesignGroups,
  }))

  const [selectType, setSelectType] = React.useState(1)
  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "name",
      text: "ชื่อ",
      sort: true,
    },

    {
      dataField: "id",
      text: "จัดการ",
      sort: true,
      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return (
          <div>
            <Button
              onClick={() => eventAction("Edit", cell, true)}
              className="mr-1 mb-1"
              color="primary"
              outline
            >
              <i className="bx bx-pencil"></i>
            </Button>
            {/* <Button
              onClick={() => eventAction("Del", cell)}
              className="mr-1 mb-1"
              color="danger"
              outline
            >
              <Trash className="align-middle" size={18} />
            </Button> */}
          </div>
        )
      },
    },
  ])

  // Table Data
  const [tableData, setTableData] = React.useState(listDesignGroups) // set campaign as default

  const [actionModal, setActionModel] = React.useState({
    id: null,
    action: null,
    modal: false,
  })

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  React.useEffect(() => {
    dispatch(onGetList_designgroups(selectType))
  }, [dispatch])

  React.useEffect(() => {
    dispatch(onGetList_designgroups(selectType))
  }, [selectType])

  React.useEffect(() => {
    if (!isEmpty(listDesignGroups)) {
      setTableData(listDesignGroups)
    } else {
      setTableData([])
    }
  }, [listDesignGroups])

  function eventAction(action, id, modal) {
    setActionModel({
      id: id,
      action: action,
      modal: modal,
    })
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            ListDesignGroups Tables | {process.env.REACT_APP_NAMECOMPANY}
          </title>
        </MetaTags>
        <div className="container-fluid">
          <ExampleModal actionModal={actionModal} />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    ListDesignGroups Datatable{" "}
                  </CardTitle>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={tableColumns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={tableColumns}
                        data={tableData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="12" style={{ textAlign: "right" }}>
                                <FormGroup>
                                  <FormGroup check inline>
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="radioActive"
                                        checked={selectType == 1}
                                        value={1 || ""}
                                        onChange={e =>
                                          setSelectType(e.target.value)
                                        }
                                      />
                                      เปิดใช้งาน
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check inline>
                                    <Label>
                                      <Input
                                        type="radio"
                                        name="radioActive"
                                        checked={selectType == 2}
                                        value={2 || ""}
                                        onChange={e =>
                                          setSelectType(e.target.value)
                                        }
                                      />
                                      ปิดใช้งาน
                                    </Label>
                                  </FormGroup>
                                </FormGroup>
                              </Col>

                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={() =>
                                      eventAction("Add", null, true)
                                    }
                                  >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    Add
                                  </Button>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered
                                    striped
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ListDesignGroups)
