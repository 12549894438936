import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_CONTRACTCONPROJECTS,
  GET_CONTRACTCONPROJECT_PROFILE,
  ADD_NEW_CONTRACTCONPROJECT,
  DELETE_CONTRACTCONPROJECT,
  UPDATE_CONTRACTCONPROJECT,
} from "./actionTypes"

import {
  getContactConProjectsSuccess,
  getContactConProjectsFail,
  getContactConProjectProfileSuccess,
  getContactConProjectProfileFail,
  addContactConProjectFail,
  addContactConProjectSuccess,
  updateContactConProjectSuccess,
  updateContactConProjectFail,
  deleteContactConProjectSuccess,
  deleteContactConProjectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getContactConProjects,
  getContactConProjectProfile,
  addNewContactConProject,
  updateContactConProject,
  deleteContactConProject,
} from "../../helpers/fakebackend_helper"

function* fetchContactConProjects({ payload: project_id }) {
  // console.log("fetchContactConProjects", project_id)
  try {
    // console.log(1);
    const response = yield call(getContactConProjects, project_id)
    // console.log("fetchContactConProjects", response)
    yield put(getContactConProjectsSuccess(response))
  } catch (error) {
    yield put(getContactConProjectsFail(error))
  }
}

function* fetchContactConProjectProfile({ payload: data }) {
  try {
    const response = yield call(getContactConProjectProfile, data)
    // console.log(response);
    yield put(getContactConProjectProfileSuccess(response))
  } catch (error) {
    yield put(getContactConProjectProfileFail(error))
  }
}

function* onUpdateContactConProject({ payload: contactconproject }) {
  try {
    // let x = contactconproject.get('formData')
    // console.log(x)
    const response = yield call(updateContactConProject, contactconproject)
    // console.log(response)
    // yield put(updateContactConProjectSuccess({}))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateContactConProjectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteContactConProject({ payload: contactconproject }) {
  try {
    const response = yield call(deleteContactConProject, contactconproject)
    yield put(deleteContactConProjectSuccess(response))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ลบ",
      message: "ลบสำเร็จ",
    })
  } catch (error) {
    yield put(deleteContactConProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ลบ",
      message: "ลบล้มเหลว",
    })
  }
}

function* onAddNewContactConProject({ payload: contactconproject }) {
  try {
    const response = yield call(addNewContactConProject, contactconproject)
    // console.log(response);
    yield put(addContactConProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addContactConProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* contactconProjectsSaga() {
  yield takeEvery(GET_CONTRACTCONPROJECTS, fetchContactConProjects)
  yield takeEvery(
    GET_CONTRACTCONPROJECT_PROFILE,
    fetchContactConProjectProfile
  )
  yield takeEvery(ADD_NEW_CONTRACTCONPROJECT, onAddNewContactConProject)
  yield takeEvery(UPDATE_CONTRACTCONPROJECT, onUpdateContactConProject)
  yield takeEvery(DELETE_CONTRACTCONPROJECT, onDeleteContactConProject)
}

export default contactconProjectsSaga
