import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
} from "reactstrap"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "../../pages/Tables/datatables.scss"
import cellEditFactory from "react-bootstrap-table2-editor"

import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  getMaterialShopProfile as onGetMaterialShopProfile,
  addNewMaterialShop as onAddNewMaterialShop,
  updateMaterialShop as onUpdateMaterialShop,
} from "store/material-shops/actions"

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const { SearchBar } = Search
  const dispatch = useDispatch()

  const { materialShopProfile } = useSelector(state => ({
    materialShopProfile: state.materialShops.materialShopProfile,
  }))

  const [material_type_id, setMaterial_type_id] = React.useState(0)

  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState({})

  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "material_type.name",
      text: "ประเภท",
      sort: true,
      editable: false,
      headerStyle: () => {
        return { width: "80px" }
      },
    },
    {
      dataField: "material.name",
      text: "วัสดุ",
      editable: false,
      sort: true,
    },
    {
      dataField: "material_code",
      text: "รหัส",
      editable: true,
      sort: true,
      headerStyle: () => {
        return { width: "150px" }
      },
    },
    {
      dataField: "material_price",
      text: "ราคา (ซื้อ)",
      editable: true,
      sort: true,
      headerStyle: () => {
        return { width: "150px" }
      },
      formatter: function action(cell, row) {
        return (
          <span>
            {parseFloat(cell).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        )
      },
    },
  ])

  // Table Data
  const [tableData, setTableData] = React.useState([]) // set campaign as default

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  async function checkAction() {
    setFormData({})
    setTableData([])
    if (actionModal.action == "Add") {
      setFormData({
        active: 1,
      })
    } else if (actionModal.action == "Edit") {
      dispatch(onGetMaterialShopProfile(actionModal.id))
    }

    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true)
    if (errors.length > 0) {
    } else {
      const data = new FormData()
      data.append("formData", JSON.stringify(formData))
      data.append("material_price", JSON.stringify(tableData))

      if (action == "Add") {
        // save new contractor
        dispatch(onAddNewMaterialShop(data))
      } else if (action == "Edit") {
        // update contractor
        dispatch(onUpdateMaterialShop(data))
      }
      setModal(!modal) 
    }
    setButtonSubmit(false)
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      let { material_price, ...reset } = materialShopProfile
      setFormData(reset)
      setTableData(material_price)
    }
    return () => {}
  }, [materialShopProfile]) // Only re-run the effect if count changes

  function updateStock(value) {
    let temp = [...tableData]
    temp.map((v, k) => {
      if (v.id == value.id) {
        v.code = value.code
        v.material_price = value.material_price
      }
    })
    // console.log("value", value)
  }

  return (
    <>
      <style scoped>
        {
          "\
          .react-bootstrap-table table { table-layout: auto !important; }\
          "
        }
      </style>
      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        centered={true}
        size="lg"
        style={{ maxWidth: "70%" }}
      >
        <ModalHeader toggle={() => setModal(!modal)}>
          {action == "Edit" ? "แก้ไข" : "เพิ่ม"}ร้านค้าวัสดุ{" "}
        </ModalHeader>
        <AvForm onSubmit={handleSubmit} model={formData}>
          <ModalBody>
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <FormGroup>
                  <Label for="Name">ชื่อร้าน </Label>
                  <AvField
                    name="name"
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Name"
                    value={formData.name}
                    onChange={e =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    validate={{
                      required: { value: true },
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <FormGroup>
                  <Label for="Name">คนติดต่อ </Label>
                  <AvField
                    name="person_name"
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Person name"
                    value={formData.person_name}
                    onChange={e =>
                      setFormData({ ...formData, person_name: e.target.value })
                    }
                    validate={{
                      required: { value: true },
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <FormGroup>
                  <Label for="number_phone">เบอร์ติดต่อ </Label>
                  <AvField
                    name="number_phone"
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Number phone"
                    value={formData.number_phone}
                    onChange={e =>
                      setFormData({ ...formData, number_phone: e.target.value })
                    }
                    validate={{
                      required: { value: true },
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <FormGroup>
                  <Label for="bank">ธนาคาร </Label>
                  <AvField
                    name="bank"
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Bank"
                    value={formData.bank}
                    onChange={e =>
                      setFormData({ ...formData, bank: e.target.value })
                    }
                    validate={{
                      required: { value: true },
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <FormGroup>
                  <Label for="bank_no">เลช บช. </Label>
                  <AvField
                    name="bank_no"
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Bank no"
                    value={formData.bank_no}
                    onChange={e =>
                      setFormData({ ...formData, bank_no: e.target.value })
                    }
                    validate={{
                      required: { value: true },
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <FormGroup>
                  <Label for="vat_no">เลขที่เสียภาษี </Label>
                  <AvField
                    name="vat_no"
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Vat no"
                    value={formData.vat_no}
                    onChange={e =>
                      setFormData({ ...formData, vat_no: e.target.value })
                    }
                    validate={{
                      required: { value: true },
                    }}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={12} md={4} lg={4}>
                <FormGroup>
                  <Label for="Address">ที่อยู่ </Label>
                  <AvField
                    name="address"
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Address"
                    value={formData.address}
                    onChange={e =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                    validate={{
                      required: { value: true },
                    }}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={12} md={4} lg={4}>
                <FormGroup>
                  <Label for="Location">สถานที่ </Label>
                  <AvField
                    name="location"
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Location"
                    value={formData.location}
                    onChange={e =>
                      setFormData({ ...formData, location: e.target.value })
                    }
                    validate={{
                      required: { value: false },
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <hr />
            {actionModal.action == "Add" ? null : (
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={tableColumns}
                data={tableData}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={tableColumns}
                    data={tableData}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <p> Material Price Datatable</p>
                          </Col>

                          <Col sm="8" style={{ textAlign: "right" }}>
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                cellEdit={cellEditFactory({
                                  mode: "click",
                                  afterSaveCell: (
                                    oldValue,
                                    newValue,
                                    row,
                                    column
                                  ) => {
                                    updateStock(row)
                                  },
                                })}
                                responsive
                                bordered
                                striped
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="submit" disabled={buttonSubmit} color="secondary">
              Save
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
