import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_SERWAYROJECTS,
  GET_SERWAYROJECT_PROFILE,
  ADD_NEW_SERWAYROJECT,
  DELETE_SERWAYROJECT,
  UPDATE_SERWAYROJECT,
  UPLOAD_INFOPROJECTFILESERWAY,
} from "./actionTypes"

import {
  getSerwayprojectsSuccess,
  getSerwayprojectsFail,
  getSerwayprojectProfileSuccess,
  getSerwayprojectProfileFail,
  addSerwayprojectFail,
  addSerwayprojectSuccess,
  updateSerwayprojectSuccess,
  updateSerwayprojectFail,
  deleteSerwayprojectSuccess,
  deleteSerwayprojectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSerwayprojects,
  getSerwayprojectProfile,
  addNewSerwayproject,
  updateSerwayproject,
  deleteSerwayproject,
  uploadImgSerwayInfoProject,
} from "../../helpers/fakebackend_helper"

function* fetchSerwayprojects({ payload: id }) {
  try {
    const response = yield call(getSerwayprojects, id)
    // console.log(response);
    yield put(getSerwayprojectsSuccess(response))
  } catch (error) {
    yield put(getSerwayprojectsFail(error))
  }
}

function* fetchSerwayprojectProfile({ payload: id }) {
  try {
    const response = yield call(getSerwayprojectProfile, id)
    yield put(getSerwayprojectProfileSuccess(response))
  } catch (error) {
    yield put(getSerwayprojectProfileFail(error))
  }
}

function* onUpdateSerwayproject({ payload: serwayproject }) {
  try {
    // let x = serwayproject.get('formData')
    // console.log(x)
    const response = yield call(updateSerwayproject, serwayproject)
    yield put(updateSerwayprojectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateSerwayprojectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
    // console.log("Error")
  }
}

function* onDeleteSerwayproject({ payload: serwayproject }) {
  try {
    const response = yield call(deleteSerwayproject, serwayproject)
    // console.log("response", response)
    yield put(getSerwayprojectsSuccess(response))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ลบ",
      message: "ลบสำเร็จ",
    })
  } catch (error) {
    yield put(deleteSerwayprojectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ลบ",
      message: "ลบล้มเหลว",
    })
  }
}

function* onAddNewSerwayproject({ payload: serwayproject }) {
  try {
    const response = yield call(addNewSerwayproject, serwayproject)
    yield put(addSerwayprojectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addSerwayprojectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* onUploadFileHomeSerway({ payload: img }) {
  // console.log("load =>", img)
  try {
    const response = yield call(uploadImgSerwayInfoProject, img)
    // console.log(response)
    yield put(addSerwayprojectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพโหลด",
      message: "อัพโหลดสำเร็จ",
    })
  } catch (error) {
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพโหลด",
      message: "อัพโหลดล้มเหลว",
    })
  }
}

function* serwayProjectsSaga() {
  yield takeEvery(GET_SERWAYROJECTS, fetchSerwayprojects)
  yield takeEvery(GET_SERWAYROJECT_PROFILE, fetchSerwayprojectProfile)
  yield takeEvery(ADD_NEW_SERWAYROJECT, onAddNewSerwayproject)
  yield takeEvery(UPDATE_SERWAYROJECT, onUpdateSerwayproject)
  yield takeEvery(DELETE_SERWAYROJECT, onDeleteSerwayproject)
  yield takeEvery(UPLOAD_INFOPROJECTFILESERWAY, onUploadFileHomeSerway)
}

export default serwayProjectsSaga
