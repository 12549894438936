import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
  Table,
  InputGroup,
  Card,
} from "reactstrap"
import { isEmpty } from "lodash"
import { AvForm, AvField } from "availity-reactstrap-validation"
import imageCompression from "browser-image-compression"

import {
  getListAluminiaProjects as onGetListAluminiaProjectProfile,
  addNewListAluminiaProjects as onAddNewListAluminiaProject,
  updateListAluminiaProjects as onUpdateListAluminiaProject,
} from "store/list-aluminias-projects/actions"
import { getMaterialStocks as onGetMaterialStocks } from "store/material-stocks/actions"

import Select from "react-select"

const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()

  const { listAluminiaProfile } = useSelector(state => ({
    listAluminiaProfile: state.listAluminias.listAluminiaProfile,
  }))
  const { materialStocks } = useSelector(state => ({
    materialStocks: state.materialStocks.materialStocks,
  }))

  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState(listAluminiaProfile)
  const [dataMaterialStocks, setDataMaterialStocks] = React.useState([])
  const [img_slip, setImg_slip] = React.useState(null)
  const [img_preview, setImg_preview] = React.useState(null)
  const [headerTitle, setHeaderTitle] = React.useState([])
  const [subHeaderTitle, setSubHeaderTitle] = React.useState([])
  const [dataType_1, setDataType_1] = React.useState([])
  const [dataCalType_name_1, setDataCalType_name_1] = React.useState([])

  const [dataEquations, setDataEquations] = React.useState([
    {
      value: 0,
      label: "ไม่มี",
    },
    {
      value: 1,
      label: "+ (บวก)",
    },
    {
      value: 2,
      label: "- (ลบ)",
    },
    {
      value: 3,
      label: "* (คูณ)",
    },
    {
      value: 4,
      label: "/ (หาร)",
    },
  ])

  async function checkAction() {
    setFormData({})
    setHeaderTitle([])
    setSubHeaderTitle([])
    setImg_preview(null)
    setImg_slip(null)
    if (actionModal.action == "Add") {
      if (!isEmpty(actionModal.detail)) {
        // console.log(actionModal)
        let { header_title, sub_header_title, ...reset } = actionModal.detail
        if (reset.img != null) {
          setImg_slip(reset.path_img)
        }
        // console.log("reset", reset)
        // console.log("header_title", header_title)
        // console.log("sub_header_title", sub_header_title)
        setHeaderTitle(header_title)
        setSubHeaderTitle(sub_header_title)
        setFormData({ ...reset, project_id: actionModal.project_id, qta: 0 })
      }
    } else if (actionModal.action == "Edit") {
      // console.log(actionModal)
      let { header_title, sub_header_title, ...reset } = actionModal.detail
      if (reset.img != null) {
        setImg_slip(reset.path_img)
      }
      // console.log("reset", reset)
      // console.log("header_title", header_title)
      // console.log("sub_header_title", sub_header_title)
      setHeaderTitle(header_title)
      setSubHeaderTitle(sub_header_title)
      setFormData(reset)
    }
    dispatch(onGetMaterialStocks(1, 0))
    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true)

    if (errors.length > 0) {
    } else {
      const data = new FormData()
      data.append("formData", JSON.stringify(formData))
      data.append("headerTitle", JSON.stringify(headerTitle))
      data.append("subHeaderTitle", JSON.stringify(subHeaderTitle))
      if (action == "Add") {
        // save new contractor
        dispatch(onAddNewListAluminiaProject(data))
      } else if (action == "Edit") {
        // update contractor
        dispatch(onUpdateListAluminiaProject(data))
      }
      setModal(!modal)
    }
    setButtonSubmit(false)
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      let { headerTitle, subHeaderTitle, ...reset } = listAluminiaProfile
      // console.log("listAluminiaProfile", process.env.REACT_APP_SERVER + reset.path_img)
      if (reset.img != null) {
        setImg_slip(reset.path_img)
      }
      setHeaderTitle(headerTitle)
      setSubHeaderTitle(subHeaderTitle)
      setFormData(reset)
    }
    return () => {}
  }, [listAluminiaProfile]) // Only re-run the effect if count changes

  React.useEffect(async () => {
    // console.log(materialStocks)
    if (!isEmpty(materialStocks)) {
      let options = [
        {
          value: 0,
          label: "เพิ่มใหม่",
        },
      ]
      for (let index = 0; index < materialStocks.length; index++) {
        if (materialStocks[index].material_type_id == 13) {
          options.push({
            value: materialStocks[index].id,
            label: materialStocks[index].name,
            material_price: materialStocks[index].price,
            unit: materialStocks[index].unit,
            material_type: materialStocks[index].material_type,
          })
        }
      }
      // console.log("options", options)
      // const options = await materialStocks.map((d, index) =>
      //   d.material_type_id != 13
      //     ? null
      //     : {
      //         value: d.id,
      //         label: d.name,
      //         material_price: d.price,
      //         unit: d.unit,
      //         material_type: d.material_type,
      //       }
      // )

      setDataMaterialStocks(options)
    }
    return () => {}
  }, [materialStocks]) // Only re-run the effect if count changes

  React.useEffect(async () => {
    // console.log(materialStocks)
    if (!isEmpty(headerTitle)) {
      const options = await headerTitle.map((d, index) => ({
        value: d.key,
        label: d.type_name,
      }))
      setDataType_1(options)
    }
    return () => {}
  }, [headerTitle]) // Only re-run the effect if count changes

  React.useEffect(async () => {
    // console.log(materialStocks)
    if (!isEmpty(subHeaderTitle)) {
      // console.log("subHeaderTitle", subHeaderTitle)
      const options = await subHeaderTitle.map((d, index) => ({
        value: d.name,
        label: d.name,
      }))
      setDataCalType_name_1(options)
    }
    return () => {}
  }, [subHeaderTitle]) // Only re-run the effect if count changes

  async function calAluminiaTitle() {
    await calAluminiaTitleSub()
  }

  async function calAluminiaTitleSub() {
    for (let index = 0; index < subHeaderTitle.length; index++) {
      if (subHeaderTitle[index].equation_type != 0) {
        if (subHeaderTitle[index].caltype_name == 0) {
          if (subHeaderTitle[index].equation == 0) {
            if (subHeaderTitle[index].type_name_2 == "") {
              let newData = [...subHeaderTitle]
              newData[index].qta = subHeaderTitle[index].qta
              setSubHeaderTitle(newData)
            }
          } else {
            if (!subHeaderTitle[index].type_name_2 == "") {
              if (isNaN(subHeaderTitle[index].type_name_2)) {
                if (subHeaderTitle[index].equation == 1) {
                  const even_1 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })

                  const even_2 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(formData.qta) *
                      (Number(even_1[0].qta) + Number(even_2[0].qta))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 2) {
                  const even_1 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })

                  const even_2 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(formData.qta) *
                      (Number(even_1[0].qta) - Number(even_2[0].qta))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 3) {
                  const even_1 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })

                  const even_2 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(formData.qta) *
                      (Number(even_1[0].qta) * Number(even_2[0].qta))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 4) {
                  const even_1 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })

                  const even_2 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(formData.qta) *
                      (Number(even_1[0].qta) / Number(even_2[0].qta))
                    setSubHeaderTitle(newData)
                  }
                }
              } else {
                if (subHeaderTitle[index].equation == 1) {
                  const even = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(formData.qta) *
                      (Number(even[0].qta) +
                        Number(subHeaderTitle[index].type_name_2))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 2) {
                  const even = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(formData.qta) *
                      (Number(even[0].qta) -
                        Number(subHeaderTitle[index].type_name_2))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 3) {
                  const even = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(formData.qta) *
                      (Number(even[0].qta) *
                        Number(subHeaderTitle[index].type_name_2))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 4) {
                  const even = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(formData.qta) *
                      (Number(even[0].qta) /
                        Number(subHeaderTitle[index].type_name_2))
                    setSubHeaderTitle(newData)
                  }
                }
              }
            }
          }
        } else if (subHeaderTitle[index].caltype_name == 1) {
          if (subHeaderTitle[index].equation == 0) {
            if (subHeaderTitle[index].type_name_2 == "") {
              let newData = [...subHeaderTitle]
              newData[index].qta = subHeaderTitle[index].qta
              setSubHeaderTitle(newData)
            }
          } else {
            if (!subHeaderTitle[index].type_name_2 == "") {
              if (isNaN(subHeaderTitle[index].type_name_2)) {
                if (subHeaderTitle[index].equation == 1) {
                  const even_1 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  const even_2 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.name
                  })
                  const even_3 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) + Number(even_2[0].qta)
                    setSubHeaderTitle(newData)
                  } else if (even_1.length > 0 && even_3.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) + Number(even_3[0].qta)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 2) {
                  const even_1 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  const even_2 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.name
                  })
                  const even_3 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) - Number(even_2[0].qta)
                    setSubHeaderTitle(newData)
                  } else if (even_1.length > 0 && even_3.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) - Number(even_3[0].qta)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 3) {
                  const even_1 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  const even_2 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.name
                  })
                  const even_3 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) * Number(even_2[0].qta)
                    setSubHeaderTitle(newData)
                  } else if (even_1.length > 0 && even_3.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) * Number(even_3[0].qta)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 4) {
                  const even_1 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  const even_2 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.name
                  })
                  const even_3 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) / Number(even_2[0].qta)
                    setSubHeaderTitle(newData)
                  } else if (even_1.length > 0 && even_3.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) / Number(even_3[0].qta)
                    setSubHeaderTitle(newData)
                  }
                }
              } else {
                if (subHeaderTitle[index].equation == 1) {
                  const even = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even[0].qta) +
                      Number(subHeaderTitle[index].type_name_2)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 2) {
                  const even = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even[0].qta) -
                      Number(subHeaderTitle[index].type_name_2)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 3) {
                  // console.log(1)
                  const even = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  // console.log("even", even)
                  if (even.length > 0) {
                    // console.log(2)
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even[0].qta) *
                      Number(subHeaderTitle[index].type_name_2)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 4) {
                  const even = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even[0].qta) /
                      Number(subHeaderTitle[index].type_name_2)
                    setSubHeaderTitle(newData)
                  }
                }
              }
            }
          }
        } else if (subHeaderTitle[index].caltype_name == 2) {
          if (subHeaderTitle[index].equation == 0) {
            if (subHeaderTitle[index].type_name_2 == "") {
              let newData = [...subHeaderTitle]
              newData[index].qta = subHeaderTitle[index].qta
              setSubHeaderTitle(newData)
            }
          } else {
            if (!subHeaderTitle[index].type_name_2 == "") {
              if (isNaN(subHeaderTitle[index].type_name_2)) {
              } else {
                if (subHeaderTitle[index].equation == 1) {
                  let newData = [...subHeaderTitle]
                  newData[index].qta =
                    Number(formData.qta) +
                    Number(subHeaderTitle[index].type_name_2)
                  setSubHeaderTitle(newData)
                } else if (subHeaderTitle[index].equation == 2) {
                  let newData = [...subHeaderTitle]
                  newData[index].qta =
                    Number(formData.qta) -
                    Number(subHeaderTitle[index].type_name_2)
                  setSubHeaderTitle(newData)
                } else if (subHeaderTitle[index].equation == 3) {
                  let newData = [...subHeaderTitle]
                  newData[index].qta =
                    Number(formData.qta) *
                    Number(subHeaderTitle[index].type_name_2)
                  setSubHeaderTitle(newData)
                } else if (subHeaderTitle[index].equation == 4) {
                  let newData = [...subHeaderTitle]
                  newData[index].qta =
                    Number(formData.qta) /
                    Number(subHeaderTitle[index].type_name_2)
                  setSubHeaderTitle(newData)
                }
              }
            }
          }
        }
      }
    }
  }

  async function calAluminiaMany(value) {
    for (let index = 0; index < subHeaderTitle.length; index++) {
      if (subHeaderTitle[index].equation_type != 0) {
        if (subHeaderTitle[index].caltype_name == 0) {
          if (subHeaderTitle[index].equation == 0) {
            if (subHeaderTitle[index].type_name_2 == "") {
              let newData = [...subHeaderTitle]
              newData[index].qta = subHeaderTitle[index].qta
              setSubHeaderTitle(newData)
            }
          } else {
            if (!subHeaderTitle[index].type_name_2 == "") {
              if (isNaN(subHeaderTitle[index].type_name_2)) {
                if (subHeaderTitle[index].equation == 1) {
                  const even_1 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })

                  const even_2 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(value) *
                      (Number(even_1[0].qta) + Number(even_2[0].qta))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 2) {
                  const even_1 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })

                  const even_2 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(value) *
                      (Number(even_1[0].qta) - Number(even_2[0].qta))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 3) {
                  const even_1 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })

                  const even_2 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(value) *
                      (Number(even_1[0].qta) * Number(even_2[0].qta))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 4) {
                  const even_1 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })

                  const even_2 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(value) *
                      (Number(even_1[0].qta) / Number(even_2[0].qta))
                    setSubHeaderTitle(newData)
                  }
                }
              } else {
                if (subHeaderTitle[index].equation == 1) {
                  const even = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(value) *
                      (Number(even[0].qta) +
                        Number(subHeaderTitle[index].type_name_2))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 2) {
                  const even = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(value) *
                      (Number(even[0].qta) -
                        Number(subHeaderTitle[index].type_name_2))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 3) {
                  const even = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(value) *
                      (Number(even[0].qta) *
                        Number(subHeaderTitle[index].type_name_2))
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 4) {
                  const even = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.type_name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(value) *
                      (Number(even[0].qta) /
                        Number(subHeaderTitle[index].type_name_2))
                    setSubHeaderTitle(newData)
                  }
                }
              }
            }
          }
        } else if (subHeaderTitle[index].caltype_name == 1) {
          if (subHeaderTitle[index].equation == 0) {
            if (subHeaderTitle[index].type_name_2 == "") {
              let newData = [...subHeaderTitle]
              newData[index].qta = subHeaderTitle[index].qta
              setSubHeaderTitle(newData)
            }
          } else {
            if (!subHeaderTitle[index].type_name_2 == "") {
              if (isNaN(subHeaderTitle[index].type_name_2)) {
                if (subHeaderTitle[index].equation == 1) {
                  const even_1 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  const even_2 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.name
                  })
                  const even_3 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) + Number(even_2[0].qta)
                    setSubHeaderTitle(newData)
                  } else if (even_1.length > 0 && even_3.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) + Number(even_3[0].qta)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 2) {
                  const even_1 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  const even_2 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.name
                  })
                  const even_3 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) - Number(even_2[0].qta)
                    setSubHeaderTitle(newData)
                  } else if (even_1.length > 0 && even_3.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) - Number(even_3[0].qta)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 3) {
                  const even_1 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  const even_2 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.name
                  })
                  const even_3 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) * Number(even_2[0].qta)
                    setSubHeaderTitle(newData)
                  } else if (even_1.length > 0 && even_3.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) * Number(even_3[0].qta)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 4) {
                  const even_1 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  const even_2 = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.name
                  })
                  const even_3 = headerTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_2 == v.type_name
                  })

                  if (even_1.length > 0 && even_2.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) / Number(even_2[0].qta)
                    setSubHeaderTitle(newData)
                  } else if (even_1.length > 0 && even_3.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even_1[0].qta) / Number(even_3[0].qta)
                    setSubHeaderTitle(newData)
                  }
                }
              } else {
                if (subHeaderTitle[index].equation == 1) {
                  const even = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even[0].qta) +
                      Number(subHeaderTitle[index].type_name_2)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 2) {
                  const even = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even[0].qta) -
                      Number(subHeaderTitle[index].type_name_2)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 3) {
                  // console.log(1)
                  const even = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  // console.log("even", even)
                  if (even.length > 0) {
                    // console.log(2)
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even[0].qta) *
                      Number(subHeaderTitle[index].type_name_2)
                    setSubHeaderTitle(newData)
                  }
                } else if (subHeaderTitle[index].equation == 4) {
                  const even = subHeaderTitle.filter(function (v) {
                    return subHeaderTitle[index].type_name_1 == v.name
                  })
                  if (even.length > 0) {
                    let newData = [...subHeaderTitle]
                    newData[index].qta =
                      Number(even[0].qta) /
                      Number(subHeaderTitle[index].type_name_2)
                    setSubHeaderTitle(newData)
                  }
                }
              }
            }
          }
        } else if (subHeaderTitle[index].caltype_name == 2) {
          if (subHeaderTitle[index].equation == 0) {
            if (subHeaderTitle[index].type_name_2 == "") {
              let newData = [...subHeaderTitle]
              newData[index].qta = subHeaderTitle[index].qta
              setSubHeaderTitle(newData)
            }
          } else {
            if (!subHeaderTitle[index].type_name_2 == "") {
              if (isNaN(subHeaderTitle[index].type_name_2)) {
              } else {
                if (subHeaderTitle[index].equation == 1) {
                  let newData = [...subHeaderTitle]
                  newData[index].qta =
                    Number(value) + Number(subHeaderTitle[index].type_name_2)
                  setSubHeaderTitle(newData)
                } else if (subHeaderTitle[index].equation == 2) {
                  let newData = [...subHeaderTitle]
                  newData[index].qta =
                    Number(value) - Number(subHeaderTitle[index].type_name_2)
                  setSubHeaderTitle(newData)
                } else if (subHeaderTitle[index].equation == 3) {
                  let newData = [...subHeaderTitle]
                  newData[index].qta =
                    Number(value) * Number(subHeaderTitle[index].type_name_2)
                  setSubHeaderTitle(newData)
                } else if (subHeaderTitle[index].equation == 4) {
                  let newData = [...subHeaderTitle]
                  newData[index].qta =
                    Number(value) / Number(subHeaderTitle[index].type_name_2)
                  setSubHeaderTitle(newData)
                }
              }
            }
          }
        }
      }
    }
  }

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
      style={{ maxWidth: "90%" }}
      size="lg"
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}คำนวณอลูมิเนียมในโปรเจค{" "}
      </ModalHeader>
      <AvForm onSubmit={handleSubmit} model={formData}>
        <ModalBody>
          <Row>
            <Col xs={12} sm={12} md={12}>
              {img_preview == null ? (
                img_slip == null ? null : (
                  <a
                    href={process.env.REACT_APP_SERVER + img_slip}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* คลิก */}
                    <img
                      // src={slip}
                      src={process.env.REACT_APP_SERVER + img_slip}
                      // className="img-responsive"
                      style={{
                        width: "100%",
                      }}
                      alt={process.env.REACT_APP_NAMECOMPANY}
                      title={process.env.REACT_APP_NAMECOMPANY}
                    />
                  </a>
                )
              ) : (
                <a href={img_preview} target="_blank" rel="noreferrer">
                  <img
                    // src={slip}
                    src={img_preview}
                    // className="img-responsive"
                    style={{
                      width: "100%",
                    }}
                    alt={process.env.REACT_APP_NAMECOMPANY}
                    title={process.env.REACT_APP_NAMECOMPANY}
                  />
                </a>
              )}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="formDataName">ชื่องาน</Label>
                <Input
                  type="text"
                  name={"formDataName"}
                  id={"formDataName"}
                  value={formData.name}
                  placeholder="ชื่องาน"
                  onChange={e => {
                    setFormData({ ...formData, name: e.target.value })
                  }}
                  validate={{
                    required: {
                      value: true,
                    },
                  }}
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="formDataqta">ภายในบ้านใช้ทั้งหมด (ชุด)</Label>
                <Input
                  type="number"
                  name={"formDataqta"}
                  id={"formDataqta"}
                  value={formData.qta}
                  placeholder="จำนวนที่ใช้"
                  min={1}
                  onChange={e => {
                    setFormData({ ...formData, qta: e.target.value })
                    calAluminiaMany(e.target.value)
                  }}
                  validate={{
                    required: {
                      value: true,
                    },
                  }}
                />
              </FormGroup>
            </Col>

            {headerTitle.length > 0 ? (
              <Col sm="12">
                <Table className="table mb-0" responsive>
                  <thead>
                    <tr>
                      <th style={{ width: "120px" }}>ตัวแปร</th>
                      <th>ชื่อ</th>
                      <th>ระยะ (cm)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {headerTitle.map((v, k) => (
                      <tr key={k}>
                        {v.del == 0 ? null : (
                          <>
                            <th>
                              <Input
                                readOnly
                                type="text"
                                name={"type_name" + k}
                                id={"type_name" + k}
                                value={v.type_name}
                                placeholder="ตัวแปร"
                                onChange={e => {
                                  let newData = [...headerTitle]
                                  newData[k].type_name = e.target.value
                                  setHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </th>
                            <th>
                              <Input
                                readOnly
                                type="text"
                                name={"name" + k}
                                id={"name" + k}
                                value={v.name}
                                placeholder="ชื่อ"
                                onChange={e => {
                                  let newData = [...headerTitle]
                                  newData[k].name = e.target.value
                                  setHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </th>
                            <th>
                              <Input
                                type="number"
                                name={"qta" + k}
                                id={"qta" + k}
                                value={v.qta}
                                placeholder="ระยะ"
                                min={1}
                                onChange={e => {
                                  let newData = [...headerTitle]
                                  newData[k].qta = e.target.value
                                  setHeaderTitle(newData)
                                  calAluminiaTitle()
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </th>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            ) : null}

            {subHeaderTitle.length > 0 ? (
              <Col sm="12">
                <Table className="table mb-0" responsive>
                  <thead>
                    <tr>
                      <th style={{ width: "120px" }}>ชื่อ</th>
                      <th>วัสดุ</th>
                      <th style={{ width: "120px" }}>ตัวแปร 1</th>
                      <th style={{ width: "150px" }}>สมาการ</th>
                      <th style={{ width: "120px" }}>ตัวแปร 2</th>
                      <th style={{ width: "120px" }}>คำนวณได้ (cm)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subHeaderTitle.map((v, k) => (
                      <tr key={k}>
                        {v.del == 0 ? null : subHeaderTitle[k + 1] == null ? (
                          <>
                            <th>
                              <Input
                                readOnly
                                type="text"
                                name={"name" + k}
                                id={"name" + k}
                                value={v.name}
                                placeholder="ชื่อ"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].name = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </th>

                            <th>
                              <Select
                                isDisabled
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"material_id" + k}
                                value={dataMaterialStocks.filter(function (
                                  option
                                ) {
                                  return option.value == v.material_id
                                })}
                                onChange={e => {
                                  if (e.value == 0) {
                                    setActionModalMaterialStock({
                                      id: null,
                                      action: "Add",
                                      modal: true,
                                    })
                                  } else {
                                    let newData = [...subHeaderTitle]
                                    newData[k].material_id = e.value
                                    newData[k].material_name = e.label
                                    setSubHeaderTitle(newData)
                                  }
                                }}
                                options={dataMaterialStocks}
                              />
                            </th>

                            <th style={{ textAlign: "center" }}>
                              {v.caltype_name == 0 ? (
                                <Select
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name={"type_name_1" + k}
                                  value={dataType_1.filter(function (option) {
                                    return option.label == v.type_name_1
                                  })}
                                  onChange={e => {
                                    let newData = [...subHeaderTitle]
                                    newData[k].type_name_1 = e.label
                                    setSubHeaderTitle(newData)
                                  }}
                                  options={dataType_1}
                                />
                              ) : v.caltype_name == 1 ? (
                                <Select
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name={"type_name_1" + k}
                                  value={dataCalType_name_1.filter(function (
                                    option
                                  ) {
                                    return option.label == v.type_name_1
                                  })}
                                  onChange={e => {
                                    let newData = [...subHeaderTitle]
                                    newData[k].type_name_1 = e.label
                                    setSubHeaderTitle(newData)
                                  }}
                                  options={dataCalType_name_1}
                                />
                              ) : (
                                <p>{formData.qta}</p>
                              )}
                            </th>

                            <th>
                              <Select
                                isDisabled
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"equation" + k}
                                value={dataEquations.filter(function (option) {
                                  return option.value == v.equation
                                })}
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].equation = e.value
                                  setSubHeaderTitle(newData)
                                }}
                                options={dataEquations}
                              />

                              {/* <Input
                              type="tereadOnlyxt"
                              name={"equation" + k}
                              id={"equation" + k}
                              value={v.equation}
                              placeholder="สมาการ"
                              onChange={e => {
                                let newData = [...subHeaderTitle]
                                newData[k].equation = e.target.value
                                setSubHeaderTitle(newData)
                              }}
                            /> */}
                            </th>

                            <th>
                              <Input
                                readOnly
                                type="text"
                                name={"type_name_2" + k}
                                id={"type_name_2" + k}
                                value={v.type_name_2}
                                placeholder="ตัวแปร 2"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].type_name_2 = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: v.equation == 0 ? false : true,
                                  },
                                }}
                              />
                            </th>

                            <th>
                              <Input
                                readOnly
                                type="number"
                                name={"qta" + k}
                                id={"qta" + k}
                                value={v.qta}
                                placeholder="จำนวน"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].qta = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: v.equation == 0 ? false : true,
                                  },
                                }}
                              />
                            </th>
                          </>
                        ) : subHeaderTitle[k].caltype_name == 2 ? (
                          <>
                            <th>
                              <Input
                                readOnly
                                type="text"
                                name={"name" + k}
                                id={"name" + k}
                                value={v.name}
                                placeholder="ชื่อ"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].name = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </th>

                            <th>
                              <Select
                                isDisabled
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"material_id" + k}
                                value={dataMaterialStocks.filter(function (
                                  option
                                ) {
                                  return option.value == v.material_id
                                })}
                                onChange={e => {
                                  if (e.value == 0) {
                                    setActionModalMaterialStock({
                                      id: null,
                                      action: "Add",
                                      modal: true,
                                    })
                                  } else {
                                    let newData = [...subHeaderTitle]
                                    newData[k].material_id = e.value
                                    newData[k].material_name = e.label
                                    setSubHeaderTitle(newData)
                                  }
                                }}
                                options={dataMaterialStocks}
                              />
                            </th>

                            <th style={{ textAlign: "center" }}>
                              {v.caltype_name == 0 ? (
                                <Select
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name={"type_name_1" + k}
                                  value={dataType_1.filter(function (option) {
                                    return option.label == v.type_name_1
                                  })}
                                  onChange={e => {
                                    let newData = [...subHeaderTitle]
                                    newData[k].type_name_1 = e.label
                                    setSubHeaderTitle(newData)
                                  }}
                                  options={dataType_1}
                                />
                              ) : v.caltype_name == 1 ? (
                                <Select
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name={"type_name_1" + k}
                                  value={dataCalType_name_1.filter(function (
                                    option
                                  ) {
                                    return option.label == v.type_name_1
                                  })}
                                  onChange={e => {
                                    let newData = [...subHeaderTitle]
                                    newData[k].type_name_1 = e.label
                                    setSubHeaderTitle(newData)
                                  }}
                                  options={dataCalType_name_1}
                                />
                              ) : (
                                <p>{formData.qta}</p>
                              )}
                            </th>

                            <th>
                              <Select
                                isDisabled
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"equation" + k}
                                value={dataEquations.filter(function (option) {
                                  return option.value == v.equation
                                })}
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].equation = e.value
                                  setSubHeaderTitle(newData)
                                }}
                                options={dataEquations}
                              />
                            </th>

                            <th>
                              <Input
                                readOnly
                                type="text"
                                name={"type_name_2" + k}
                                id={"type_name_2" + k}
                                value={v.type_name_2}
                                placeholder="ตัวแปร 2"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].type_name_2 = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: v.equation == 0 ? false : true,
                                  },
                                }}
                              />
                            </th>

                            <th>
                              <Input
                                readOnly
                                type="number"
                                name={"qta" + k}
                                id={"qta" + k}
                                value={v.qta}
                                placeholder="จำนวน"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].qta = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: v.equation == 0 ? false : true,
                                  },
                                }}
                              />
                            </th>
                          </>
                        ) : subHeaderTitle[k].caltype_name == 1 ? (
                          <>
                            <th>
                              <Input
                                readOnly
                                type="text"
                                name={"name" + k}
                                id={"name" + k}
                                value={v.name}
                                placeholder="ชื่อ"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].name = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </th>

                            <th>
                              <Select
                                isDisabled
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"material_id" + k}
                                value={dataMaterialStocks.filter(function (
                                  option
                                ) {
                                  return option.value == v.material_id
                                })}
                                onChange={e => {
                                  if (e.value == 0) {
                                    setActionModalMaterialStock({
                                      id: null,
                                      action: "Add",
                                      modal: true,
                                    })
                                  } else {
                                    let newData = [...subHeaderTitle]
                                    newData[k].material_id = e.value
                                    newData[k].material_name = e.label
                                    setSubHeaderTitle(newData)
                                  }
                                }}
                                options={dataMaterialStocks}
                              />
                            </th>

                            <th style={{ textAlign: "center" }}>
                              {v.caltype_name == 0 ? (
                                <Select
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name={"type_name_1" + k}
                                  value={dataType_1.filter(function (option) {
                                    return option.label == v.type_name_1
                                  })}
                                  onChange={e => {
                                    let newData = [...subHeaderTitle]
                                    newData[k].type_name_1 = e.label
                                    setSubHeaderTitle(newData)
                                  }}
                                  options={dataType_1}
                                />
                              ) : v.caltype_name == 1 ? (
                                <Select
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name={"type_name_1" + k}
                                  value={dataCalType_name_1.filter(function (
                                    option
                                  ) {
                                    return option.label == v.type_name_1
                                  })}
                                  onChange={e => {
                                    let newData = [...subHeaderTitle]
                                    newData[k].type_name_1 = e.label
                                    setSubHeaderTitle(newData)
                                  }}
                                  options={dataCalType_name_1}
                                />
                              ) : (
                                <p>{formData.qta}</p>
                              )}
                            </th>

                            <th>
                              <Select
                                isDisabled
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"equation" + k}
                                value={dataEquations.filter(function (option) {
                                  return option.value == v.equation
                                })}
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].equation = e.value
                                  setSubHeaderTitle(newData)
                                }}
                                options={dataEquations}
                              />
                            </th>

                            <th>
                              <Input
                                readOnly
                                type="text"
                                name={"type_name_2" + k}
                                id={"type_name_2" + k}
                                value={v.type_name_2}
                                placeholder="ตัวแปร 2"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].type_name_2 = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: v.equation == 0 ? false : true,
                                  },
                                }}
                              />
                            </th>

                            <th>
                              <Input
                                readOnly
                                type="number"
                                name={"qta" + k}
                                id={"qta" + k}
                                value={v.qta}
                                placeholder="จำนวน"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].qta = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: v.equation == 0 ? false : true,
                                  },
                                }}
                              />
                            </th>
                          </>
                        ) : subHeaderTitle[k + 1].caltype_name == 1 ? null : (
                          <>
                            <th>
                              <Input
                                readOnly
                                type="text"
                                name={"name" + k}
                                id={"name" + k}
                                value={v.name}
                                placeholder="ชื่อ"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].name = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </th>

                            <th>
                              <Select
                                isDisabled
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"material_id" + k}
                                value={dataMaterialStocks.filter(function (
                                  option
                                ) {
                                  return option.value == v.material_id
                                })}
                                onChange={e => {
                                  if (e.value == 0) {
                                    setActionModalMaterialStock({
                                      id: null,
                                      action: "Add",
                                      modal: true,
                                    })
                                  } else {
                                    let newData = [...subHeaderTitle]
                                    newData[k].material_id = e.value
                                    newData[k].material_name = e.label
                                    setSubHeaderTitle(newData)
                                  }
                                }}
                                options={dataMaterialStocks}
                              />
                            </th>

                            <th style={{ textAlign: "center" }}>
                              {v.caltype_name == 0 ? (
                                <Select
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name={"type_name_1" + k}
                                  value={dataType_1.filter(function (option) {
                                    return option.label == v.type_name_1
                                  })}
                                  onChange={e => {
                                    let newData = [...subHeaderTitle]
                                    newData[k].type_name_1 = e.label
                                    setSubHeaderTitle(newData)
                                  }}
                                  options={dataType_1}
                                />
                              ) : v.caltype_name == 1 ? (
                                <Select
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name={"type_name_1" + k}
                                  value={dataCalType_name_1.filter(function (
                                    option
                                  ) {
                                    return option.label == v.type_name_1
                                  })}
                                  onChange={e => {
                                    let newData = [...subHeaderTitle]
                                    newData[k].type_name_1 = e.label
                                    setSubHeaderTitle(newData)
                                  }}
                                  options={dataCalType_name_1}
                                />
                              ) : (
                                <p>{formData.qta}</p>
                              )}
                            </th>

                            <th>
                              <Select
                                isDisabled
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"equation" + k}
                                value={dataEquations.filter(function (option) {
                                  return option.value == v.equation
                                })}
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].equation = e.value
                                  setSubHeaderTitle(newData)
                                }}
                                options={dataEquations}
                              />

                              {/* <Input
                              type="tereadOnlyxt"
                              name={"equation" + k}
                              id={"equation" + k}
                              value={v.equation}
                              placeholder="สมาการ"
                              onChange={e => {
                                let newData = [...subHeaderTitle]
                                newData[k].equation = e.target.value
                                setSubHeaderTitle(newData)
                              }}
                            /> */}
                            </th>

                            <th>
                              <Input
                                readOnly
                                type="text"
                                name={"type_name_2" + k}
                                id={"type_name_2" + k}
                                value={v.type_name_2}
                                placeholder="ตัวแปร 2"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].type_name_2 = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: v.equation == 0 ? false : true,
                                  },
                                }}
                              />
                            </th>

                            <th>
                              <Input
                                readOnly
                                type="number"
                                name={"qta" + k}
                                id={"qta" + k}
                                value={v.qta}
                                placeholder="จำนวน"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].qta = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: v.equation == 0 ? false : true,
                                  },
                                }}
                              />
                            </th>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            ) : null}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            Save
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
