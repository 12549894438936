import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactDrawer from "react-drawer"
import "react-drawer/lib/react-drawer.css"

import { connect } from "react-redux"

import { Link } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "store/actions"

// Import menuDropdown
import NotificationDropdown from "components/CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from "components/CommonForBoth/RightSidebar"

import logo from "../../assets/images/logo.svg"
import logoLight from "../../assets/images/logo-light.png"
import logoLightSvg from "../../assets/images/logo-light.svg"
import logoDark from "../../assets/images/logo-dark.png"

import { getProjects as onGetProjects } from "store/projects/actions"
import { useSelector, useDispatch } from "react-redux"
import Select, { components } from "react-select"
//i18n
import { withTranslation } from "react-i18next"

const Header = props => {
  const dispatch = useDispatch()
  const [menu, setMenu] = useState(false)
  const [isSearch, setSearch] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const [position, setPosition] = useState()
  const [open, setOpen] = useState(false)

  const [project_id, setProject_id] = useState(false)
  const [dataProjects, setDataProjects] = useState([])
  const [searchName, setSearchName] = useState("")
  const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }))

  const toggleTopDrawer = () => {
    setPosition("right")
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false)
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  React.useEffect(() => {
    // console.log(projects)
    setDataProjects(projects)
  }, [projects])

  React.useEffect(() => {
    if (searchName.length > 0) {
      dispatch(onGetProjects("search", searchName))
    }
  }, [searchName])

  const Menu = MenuProps => {
    return (
      <React.Fragment>
        <components.Option {...MenuProps}>
          <span
            style={{ cursor: "pointer", textDecoration: "none" }}
            onClick={() => goToPorject(MenuProps.data.value)}
          >
            {MenuProps.children}
          </span>
        </components.Option>
      </React.Fragment>
    )
  }

  async function goToPorject(id) {
    setProject_id(id)
    const win = window.open(`/projects-overview/${id}`, "_blank")
    win.focus()
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img
                    src={logo}
                    alt={process.env.REACT_APP_NAMECOMPANY}
                    height="22"
                    style={{ transform: "scale(1.5)" }}
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={logoDark}
                    alt={process.env.REACT_APP_NAMECOMPANY}
                    height="17"
                    style={{ transform: "scale(1.5)" }}
                  />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src={logoLightSvg}
                    alt={process.env.REACT_APP_NAMECOMPANY}
                    height="22"
                    style={{ transform: "scale(1.5)" }}
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={logoLight}
                    alt={process.env.REACT_APP_NAMECOMPANY}
                    height="19"
                    style={{ transform: "scale(1.5)" }}
                  />
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu)
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <form
              className="app-search d-none d-lg-block"
              style={{ width: "20vw" }}
            >
              <div className="position-relative">
                <Select
                  type="text"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    Option: Menu,
                  }}
                  // components={{ Menu }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  onInputChange={e => setSearchName(e)}
                  // onChange={(e) => goToPorject(e)}
                  placeholder="ค้นหาโปรเจค"
                  value={dataProjects.filter(function (option) {
                    return option.value == project_id
                  })}
                  options={dataProjects.map((item, i) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </div>
            </form>
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3" style={{ width: "100%" }}>
                  <Select
                    type="text"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      Option: Menu,
                    }}
                    // components={{ Menu }}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: base => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    onInputChange={e => setSearchName(e)}
                    // onChange={(e) => goToPorject(e)}
                    placeholder="ค้นหาโปรเจค"
                    value={dataProjects.filter(function (option) {
                      return option.value == project_id
                    })}
                    options={dataProjects.map((item, i) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </form>
              </div>
            </div>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon "
                onClick={() => {
                  toggleFullscreen()
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            {/* <NotificationDropdown /> */}

            <ProfileMenu />

            <div className="dropdown d-inline-block">
              <button
                onClick={toggleTopDrawer}
                disabled={open}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div>
          </div>
        </div>
      </header>
      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
