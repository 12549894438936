import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_INFOPROJECTS,
  GET_INFOPROJECT_PROFILE,
  ADD_NEW_INFOPROJECT,
  DELETE_INFOPROJECT,
  UPDATE_INFOPROJECT,
  UPLOAD_INFOPROJECTFILEHOME,
} from "./actionTypes"

import {
  getInfoProjectsSuccess,
  getInfoProjectsFail,
  getInfoProjectProfileSuccess,
  getInfoProjectProfileFail,
  addInfoProjectFail,
  addInfoProjectSuccess,
  updateInfoProjectSuccess,
  updateInfoProjectFail,
  deleteInfoProjectSuccess,
  deleteInfoProjectFail,
  uploadImgHomeSuccess,
  uploadImgHomeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getInfoProjects,
  getInfoProjectProfile,
  addNewInfoProject,
  updateInfoProject,
  deleteInfoProject,
  uploadImgInfoProject,
} from "../../helpers/fakebackend_helper"

function* fetchInfoProjects() {
  try {
    const response = yield call(getInfoProjects)
    // console.log(response);
    yield put(getInfoProjectsSuccess(response))
  } catch (error) {
    yield put(getInfoProjectsFail(error))
  }
}

function* fetchInfoProjectProfile({ payload: id }) {
  try {
    const response = yield call(getInfoProjectProfile, id)
    yield put(getInfoProjectProfileSuccess(response))
  } catch (error) {
    yield put(getInfoProjectProfileFail(error))
  }
}

function* onUpdateInfoProject({ payload: infoproject }) {
  try {
    // let x = infoproject.get('formData')
    // console.log(infoproject)
    const response = yield call(updateInfoProject, infoproject)
    // console.log("response", response)
    yield put(getInfoProjectProfileSuccess(response))
    // yield put(updateInfoProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateInfoProjectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteInfoProject({ payload: infoproject }) {
  try {
    const response = yield call(deleteInfoProject, infoproject)
    yield put(deleteInfoProjectSuccess(response))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ลบ",
      message: "ลบสำเร็จ",
    })
  } catch (error) {
    yield put(deleteInfoProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ลบ",
      message: "ลบล้มเหลว",
    })
  }
}

function* onAddNewInfoProject({ payload: infoproject }) {
  try {
    const response = yield call(addNewInfoProject, infoproject)
    yield put(addInfoProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addInfoProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* onUploadFileHome({ payload: img }) {
  yield put(uploadImgHomeSuccess(true))
  try {
    const response = yield call(uploadImgInfoProject, img)
    // console.log("response", response)
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพโหลด",
      message: "อัพโหลดสำเร็จ",
    })
  } catch (error) {
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพโหลด",
      message: "อัพโหลดล้มเหลว",
    })
  }
  yield put(uploadImgHomeSuccess(false))
}

function* infoProjectsSaga() {
  yield takeEvery(GET_INFOPROJECTS, fetchInfoProjects)
  yield takeEvery(GET_INFOPROJECT_PROFILE, fetchInfoProjectProfile)
  yield takeEvery(ADD_NEW_INFOPROJECT, onAddNewInfoProject)
  yield takeEvery(UPDATE_INFOPROJECT, onUpdateInfoProject)
  yield takeEvery(DELETE_INFOPROJECT, onDeleteInfoProject)
  yield takeEvery(UPLOAD_INFOPROJECTFILEHOME, onUploadFileHome)
}

export default infoProjectsSaga
