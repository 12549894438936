import {
  GET_MATERIAL_STOCK_PROFILE,
  GET_MATERIAL_STOCK_PROFILE_FAIL,
  GET_MATERIAL_STOCK_PROFILE_SUCCESS,
  GET_MATERIAL_STOCKS,
  GET_MATERIAL_STOCKS_FAIL,
  GET_MATERIAL_STOCKS_SUCCESS,
  ADD_NEW_MATERIAL_STOCK,
  ADD_MATERIAL_STOCK_SUCCESS,
  ADD_MATERIAL_STOCK_FAIL,
  UPDATE_MATERIAL_STOCK,
  UPDATE_MATERIAL_STOCK_SUCCESS,
  UPDATE_MATERIAL_STOCK_FAIL,
  DELETE_MATERIAL_STOCK,
  DELETE_MATERIAL_STOCK_SUCCESS,
  DELETE_MATERIAL_STOCK_FAIL,
} from "./actionTypes"

export const getMaterialStocks = ( active, material_type_id ) => ({
  type: GET_MATERIAL_STOCKS,
  payload: { active, material_type_id },
})

export const getMaterialStocksSuccess = materialstocks => ({
  type: GET_MATERIAL_STOCKS_SUCCESS,
  payload: materialstocks,
})

export const addNewMaterialStock = materialstock => ({
  type: ADD_NEW_MATERIAL_STOCK,
  payload: materialstock,
})

export const addMaterialStockSuccess = materialstock => ({
  type: ADD_MATERIAL_STOCK_SUCCESS,
  payload: materialstock,
})

export const addMaterialStockFail = error => ({
  type: ADD_MATERIAL_STOCK_FAIL,
  payload: error,
})

export const getMaterialStocksFail = error => ({
  type: GET_MATERIAL_STOCKS_FAIL,
  payload: error,
})

export const getMaterialStockProfile = id => ({
  type: GET_MATERIAL_STOCK_PROFILE,
  payload: id,
})

export const getMaterialStockProfileSuccess = materialstockProfile => ({
  type: GET_MATERIAL_STOCK_PROFILE_SUCCESS,
  payload: materialstockProfile,
})

export const getMaterialStockProfileFail = error => ({
  type: GET_MATERIAL_STOCK_PROFILE_FAIL,
  payload: error,
})

export const updateMaterialStock = materialstock => ({
  type: UPDATE_MATERIAL_STOCK,
  payload: materialstock,
})

// export const updateMaterialStock = materialstock => console.log(materialstock);

export const updateMaterialStockSuccess = materialstock => ({
  type: UPDATE_MATERIAL_STOCK_SUCCESS,
  payload: materialstock,
})

export const updateMaterialStockFail = error => ({
  type: UPDATE_MATERIAL_STOCK_FAIL,
  payload: error,
})

export const deleteMaterialStock = materialstock => ({
  type: DELETE_MATERIAL_STOCK,
  payload: materialstock,
})

export const deleteMaterialStockSuccess = materialstock => ({
  type: DELETE_MATERIAL_STOCK_SUCCESS,
  payload: materialstock,
})

export const deleteMaterialStockFail = error => ({
  type: DELETE_MATERIAL_STOCK_FAIL,
  payload: error,
})
