/* LIST_QCS */
export const GET_LIST_QCS = "GET_LIST_QCS"
export const GET_LIST_QCS_SUCCESS = "GET_LIST_QCS_SUCCESS"
export const GET_LIST_QCS_FAIL = "GET_LIST_QCS_FAIL"

/* LIST_QCS PROFILE */
export const GET_LIST_QC_PROFILE = "GET_LIST_QC_PROFILE"
export const GET_LIST_QC_PROFILE_SUCCESS = "GET_LIST_QC_PROFILE_SUCCESS"
export const GET_LIST_QC_PROFILE_FAIL = "GET_LIST_QC_PROFILE_FAIL"

/**
 * add qc
 */
export const ADD_NEW_LIST_QC = "ADD_NEW_LIST_QC"
export const ADD_LIST_QC_SUCCESS = "ADD_LIST_QC_SUCCESS"
export const ADD_LIST_QC_FAIL = "ADD_LIST_QC_FAIL"

/**
 * Edit qc
 */
export const UPDATE_LIST_QC = "UPDATE_LIST_QC"
export const UPDATE_LIST_QC_SUCCESS = "UPDATE_LIST_QC_SUCCESS"
export const UPDATE_LIST_QC_FAIL = "UPDATE_LIST_QC_FAIL"

/**
 * Delete qc
 */
export const DELETE_LIST_QC = "DELETE_LIST_QC"
export const DELETE_LIST_QC_SUCCESS = "DELETE_LIST_QC_SUCCESS"
export const DELETE_LIST_QC_FAIL = "DELETE_LIST_QC_FAIL"
