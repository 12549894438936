import {
  GET_LIST_STRUCTURES_SUCCESS,
  GET_LIST_STRUCTURES_FAIL,
  ADD_LIST_STRUCTURE_SUCCESS,
  ADD_LIST_STRUCTURE_FAIL,
  UPDATE_LIST_STRUCTURE_SUCCESS,
  UPDATE_LIST_STRUCTURE_FAIL,
  DELETE_LIST_STRUCTURE_SUCCESS,
  DELETE_LIST_STRUCTURE_FAIL,
  GET_LIST_STRUCTURE_PROFILE_SUCCESS,
  GET_LIST_STRUCTURE_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  listStructures: [],
  listStructureProfile: {},
  error: {},
}

const listStructures = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_STRUCTURES_SUCCESS:
      // console.log(action)
      return {
        ...state,
        listStructures: action.payload,
      }

    case GET_LIST_STRUCTURES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_LIST_STRUCTURE_SUCCESS:
      return {
        ...state,
        listStructures: [...state.listStructures, action.payload],
      }

    case ADD_LIST_STRUCTURE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_STRUCTURE_PROFILE_SUCCESS:
      // console.log("GET_LIST_STRUCTURE_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        listStructureProfile: action.payload,
      }

    case UPDATE_LIST_STRUCTURE_SUCCESS:
      return {
        ...state,
        listStructures: state.listStructures.map(structure =>
          structure.id.toString() === action.payload.id.toString()
            ? { structure, ...action.payload }
            : structure
        ),
      }

    case UPDATE_LIST_STRUCTURE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_LIST_STRUCTURE_SUCCESS:
      return {
        ...state,
        listStructures: state.listStructures.filter(
          structure =>
            structure.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_LIST_STRUCTURE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_STRUCTURE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default listStructures
