import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_DESIGNS,
  GET_DESIGN_PROFILE,
  ADD_NEW_DESIGN,
  DELETE_DESIGN,
  UPDATE_DESIGN,
} from "./actionTypes"

import {
  getDesignsSuccess,
  getDesignsFail,
  getDesignProfileSuccess,
  getDesignProfileFail,
  addDesignFail,
  addDesignSuccess,
  updateDesignSuccess,
  updateDesignFail,
  deleteDesignSuccess,
  deleteDesignFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDesigns,
  getDesignProfile,
  addNewDesign,
  updateDesign,
  deleteDesign,
} from "../../helpers/fakebackend_helper"

function* fetchDesigns({ payload: id }) {
  try {
    const response = yield call(getDesigns, id)
    // console.log(response);
    yield put(getDesignsSuccess(response))
  } catch (error) {
    yield put(getDesignsFail(error))
  }
}

function* fetchDesignProfile({ payload: id }) {
  // console.log(id)updateDesign
  try {
    const response = yield call(getDesignProfile, id)
    // console.log("Profile => ", response)
    yield put(getDesignProfileSuccess(response))
  } catch (error) {
    yield put(getDesignProfileFail(error))
  }
}

function* onUpdateDesign({ payload: design }) {
  try {
    // let x = design.get('formData')
    // console.log(x)
    const response = yield call(updateDesign, design)
    yield put(updateDesignSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateDesignFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteDesign({ payload: design }) {
  try {
    const response = yield call(deleteDesign, design)
    // console.log(response);
    // yield put(deleteDesignSuccess(response))
    yield put(getDesignsSuccess(response))
  } catch (error) {
    yield put(deleteDesignFail(error))
  }
}

function* onAddNewDesign({ payload: design }) {
  try {
    const response = yield call(addNewDesign, design)
    yield put(addDesignSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addDesignFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* listStructure() {
  yield takeEvery(GET_DESIGNS, fetchDesigns)
  yield takeEvery(GET_DESIGN_PROFILE, fetchDesignProfile)
  yield takeEvery(ADD_NEW_DESIGN, onAddNewDesign)
  yield takeEvery(UPDATE_DESIGN, onUpdateDesign)
  yield takeEvery(DELETE_DESIGN, onDeleteDesign)
}

export default listStructure
