import {
  GET_CONTRACTCONPROJECTS_SUCCESS,
  GET_CONTRACTCONPROJECTS_FAIL,
  ADD_CONTRACTCONPROJECT_SUCCESS,
  ADD_CONTRACTCONPROJECT_FAIL,
  UPDATE_CONTRACTCONPROJECT_SUCCESS,
  UPDATE_CONTRACTCONPROJECT_FAIL,
  DELETE_CONTRACTCONPROJECT_SUCCESS,
  DELETE_CONTRACTCONPROJECT_FAIL,
  GET_CONTRACTCONPROJECT_PROFILE_SUCCESS,
  GET_CONTRACTCONPROJECT_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  contactconprojects: [
    {
      group_1: [],
      group_2: [],
      group_3: [],
    },
  ],
  contactconprojectProfile: {},
  error: {},
}

const contactconprojects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONTRACTCONPROJECTS_SUCCESS:
      return {
        ...state,
        contactconprojects: action.payload,
      }

    case GET_CONTRACTCONPROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CONTRACTCONPROJECT_SUCCESS:
      return {
        ...state,
        contactconprojects: [...state.contactconprojects, action.payload],
      }

    case ADD_CONTRACTCONPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CONTRACTCONPROJECT_PROFILE_SUCCESS:
      return {
        ...state,
        contactconprojectProfile: action.payload,
      }

    case UPDATE_CONTRACTCONPROJECT_SUCCESS:
      return {
        ...state,
        contactconprojects: state.contactconprojects.map(contactconproject =>
          contactconproject.id.toString() === action.payload.id.toString()
            ? { contactconproject, ...action.payload }
            : contactconproject
        ),
      }

    case UPDATE_CONTRACTCONPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CONTRACTCONPROJECT_SUCCESS:
      return {
        ...state,
        contactconprojects: state.contactconprojects.filter(
          contactconproject =>
            contactconproject.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_CONTRACTCONPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CONTRACTCONPROJECT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contactconprojects
