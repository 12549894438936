import PropTypes from "prop-types"
import React from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Col,
  Row,
  Card,
  CardBody,
  Label,
  Modal,
  FormGroup,
  Button,
  Input,
  InputGroup,
  InputGroupText,
  UncontrolledDropdown,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Container,
  Table,
  Spinner,
} from "reactstrap"
import { filter, isEmpty, map } from "lodash"
import NumberFormat from "react-number-format"
import imageCompression from "browser-image-compression"
import Dropzone from "react-dropzone"
//Lightbox

import THBText from "thai-baht-text" // for ES6

import pdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work
import Select from "react-select"
import ExampleModal from "components/Dialogs/dialogMoney-product"
import ExampleModalQuotation_condition_lists from "components/Dialogs/dialogQuotation_condition_lists"
import ExampleModalContact_condition_lists from "components/Dialogs/dialogContact_condition_lists"
import ExampleModalEmployment_contract_lists from "components/Dialogs/dialogEmployment_contract_lists"

import ExampleModalBank from "components/Dialogs/dialogBanks"
import ExampleModalProfitLoss from "components/Dialogs/dialogProfitLoss"

import { AvForm, AvField } from "availity-reactstrap-validation"

import signature_manager from "assets/images/signature_manager.png"

import logo_s from "assets/images/logo_s.png"

import { getUsers as onGetUsers } from "store/actions"

import {
  getInfoProjectProfile as onGetInfoProjectProfile,
  addNewInfoProject as onAddNewInfoProject,
  updateInfoProject as onUpdateInfoProject,
  uploadImgHome as onUploadImgHome,
} from "store/info-project/actions"

import { getProfitLossProjects as onGetProfitLossProjects } from "store/profit-loss/actions"

import {
  // getRefprojectProfile as onGetRefprojectProfile,
  uploadImgInfoRef as onUploadImgInfoRef,
} from "store/refs-project/actions"

import {
  getSerwayprojects as onGetSerwayprojects,
  getSerwayprojectProfile as onGetSerwayprojectProfile,
  uploadImgInfoSerway as onUploadImgInfoSerway,
  deleteSerwayproject as onDeleteSerwayproject,
} from "store/serways-project/actions"

import {
  getSlipprojects as onGetSlipprojects,
  updateSlipproject as onUpdateSlipproject,
  uploadImgInfoSlip as onUploadImgInfoSlip,
} from "store/slips-project/actions"

import { getDesignHomes as onGetDesignHomes } from "store/actions"

import {
  getQuotationProjects as onGetQuotationProjects,
  getQuotationProjectProfile as onGetQuotationProjectProfile,
  addNewQuotationProject as onAddNewQuotationProject,
  updateQuotationProject as onUpdateQuotationProject,
} from "store/quotations-project/actions"
import { getBanks as onGetBanks } from "store/banks/actions"

//ต้องระบุตามชื่อของ ไฟล์ font
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
}

const InfoProject = ({ projectID }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { infoprojectProfile } = useSelector(state => ({
    infoprojectProfile: state.infoprojects.infoprojectProfile,
  }))

  const { loadingUploadImg } = useSelector(state => ({
    loadingUploadImg: state.infoprojects.loadingUploadImg,
  }))

  const { idCard } = useSelector(state => ({
    idCard: state.refprojects.idCard,
  }))

  const { banks } = useSelector(state => ({
    banks: state.banks.banks,
  }))

  const { serwayproject } = useSelector(state => ({
    serwayproject: state.serwayprojects.serwayproject,
  }))

  const { slipproject } = useSelector(state => ({
    slipproject: state.slipprojects.slipproject,
  }))

  const { slip_money_sum } = useSelector(state => ({
    slip_money_sum: state.slipprojects.slip_money_sum,
  }))

  const { quotationproject } = useSelector(state => ({
    quotationproject: state.quotationprojects.quotationprojects,
  }))

  const { quotationprojectProfile } = useSelector(state => ({
    quotationprojectProfile: state.quotationprojects.quotationprojectProfile,
  }))

  const { designhomes } = useSelector(state => ({
    designhomes: state.designHomes.designhomes,
  }))

  const { users } = useSelector(state => ({
    users: state.users.users,
  }))

  const { profitlossprojects } = useSelector(state => ({
    profitlossprojects: state.profitlossprojects.profitlossprojects,
  }))

  const [dataQuotationproject, setDataQuotationproject] = React.useState([])

  const [settingProjectMenus, setSettingProjectMenus] = React.useState(false)
  const [settingCondition, setSettingCondition] = React.useState(false)

  const [formData, setFormData] = React.useState({})
  const [formDataQuo, setFormDataQuo] = React.useState({})
  const [checkPreview, setCheckPreview] = React.useState(false)

  const [formDataSlip, setFormDataSlip] = React.useState({})
  const [reqCallBack, setReqCallBack] = React.useState(null)
  const [actionModal, setActionModel] = React.useState({
    id: null,
    action: null,
    modal: false,
  })
  const [
    actionModalQuotation_condition_list,
    setActionModalQuotation_condition_list,
  ] = React.useState({
    id: projectID,
    action: "Edit",
    modal: false,
  })

  const [
    actionModalContact_condition_list,
    setActionModalContact_condition_list,
  ] = React.useState({
    id: projectID,
    action: "Edit",
    modal: false,
  })
  const [
    actionModalEmployment_contract_list,
    setActionModalEmployment_contract_list,
  ] = React.useState({
    id: projectID,
    action: "Edit",
    modal: false,
  })

  const [actionModalBank, setActionModalBank] = React.useState({
    id: null,
    action: null,
    modal: false,
  })
  const [checkRouteModal, setCheckRouteModal] = React.useState({
    action: null,
    id: null,
  })
  const [actionModalProfitLoss, setActionModalProfitLoss] = React.useState({
    modal: false,
    details_costs: [],
    details_wages: [],
    details_materials: [],
  })

  const [Img_quo, setImg_quo] = React.useState(null)
  const [dataUsers, setDataUsers] = React.useState([])
  const [dataImg_quo, setDataImg_quo] = React.useState(null)
  const [Img_slip, setImg_slip] = React.useState(null)
  const [dataImg_slip, setDataImg_slip] = React.useState({})
  const [groups, setGroups] = React.useState([])
  const [quotation_lists, setQuotation_lists] = React.useState([])
  const [quotation_notes, setQuotation_notes] = React.useState([])
  const [quotation_cons, setQuotation_cons] = React.useState([])
  const [priceProduct, setPriceProduct] = React.useState([])
  const [modal_idCard, setModal_idCard] = React.useState(false)
  const [modal_quoVat, setModal_quoVat] = React.useState(false)

  const [Img, setImg] = React.useState(null)
  const [selectedFiles, setselectedFiles] = React.useState([])
  const [fileSerway, setFileSerway] = React.useState([])
  const [fileSlip, setFileSlip] = React.useState([])
  const [dataSlipSum, setDataSlipSum] = React.useState(0)
  const [dataSlipSumAll, setDataSlipSumAll] = React.useState(0)
  const [installments, setInstallments] = React.useState(1)
  const [indexQuo, setIndexQuo] = React.useState(null)
  const [formData_quoVat, setFormData_quoVat] = React.useState({
    name: "",
    tel: "",
    quo_date: "",
    id_card: "",
    address: "",
    book_no: "",
    detail: "",
    price: "",
    vat: "",
  })

  const [modal_xlarge_quo, setModal_xlarge_quo] = React.useState(false)
  const [modal_xlarge_slip, setModal_xlarge_slip] = React.useState(false)

  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [photoIndex, setphotoIndex] = React.useState(0)
  const [isGallery, setisGallery] = React.useState(false)
  const [comment, setComment] = React.useState("")
  const [money, setMoney] = React.useState("")
  const [bank_id, setBank_id] = React.useState(1)
  const [actionQuo, setActionQuo] = React.useState("")

  const [permission, setPermission] = React.useState(null)
  const [loadingUploadHome, setLoadingUploadHome] = React.useState(null)
  const [selectUploadID, setSelectUploadID] = React.useState(null)
  const [optionSelectUpload, setOptionSelectUpload] = React.useState([
    { value: 1, label: "อ้างอิง" },
    { value: 2, label: "สำรวจ" },
    { value: 3, label: "สลิปเงินของ ลค." },
    { value: 4, label: "บัตรประชาชน ลค." },
  ])
  const [money_all, setMoney_all] = React.useState(0)

  React.useEffect(() => {
    if (!isEmpty(projectID)) {
      const data = new FormData()
      data.append("project_id", projectID)
      data.append("type", "project")

      const obj = JSON.parse(localStorage.getItem("authUser"))
      setPermission(obj.group.permission)
      // console.log(projectID)
      dispatch(onGetInfoProjectProfile(projectID))
      dispatch(onGetUsers(1))
      // dispatch(onGetRefprojectProfile(projectID))
      dispatch(onGetSerwayprojects(projectID))
      dispatch(onGetSlipprojects(data))
      dispatch(onGetQuotationProjects(projectID))
      dispatch(onGetDesignHomes("info"))
      dispatch(onGetBanks(1))
      // console.log("open", 1)
      // if (
      //   JSON.parse(localStorage.getItem("model_quo")) == true &&
      //   dataQuotationproject.length > 0
      // ) {
      //   AddQuo("Edit", dataQuotationproject[0].id)
      // }
    }
  }, [projectID])

  React.useEffect(() => {
    // if (actionModal.modal) {
    // } else {
    if (!isEmpty(designhomes)) {
      // console.log("designhomes_1", designhomes)
      let optionGroup = [
        {
          label: "จัดการ",
          options: [
            {
              type: -1,
              value: -1,
              label: "เพิ่มใหม่",
            },
          ],
        },
        {
          label: "แบบบ้าน",
          options: [],
        },
        {
          label: "บ้านสำเร็จรูป",
          options: [],
        },
        {
          label: "อื่น ๆ",
          options: [],
        },
      ]
      let styles = []
      let finisheds = []
      let etcs = []

      // let array = []
      for (let index = 0; index < designhomes.length; index++) {
        for (let j = 0; j < quotation_lists.length; j++) {
          if (
            quotation_lists[j].list_product_id == designhomes[index].id &&
            designhomes[index].price != quotation_lists[j].price
          ) {
            // console.log('designhomes[index]',designhomes[index].price)
            // console.log('quotation_lists[j]',quotation_lists[j].price)
            let newData = [...quotation_lists]
            if (designhomes[index].type == 1) {
              newData[j].name = designhomes[index].code

              newData[j].offer = designhomes[index].offer
              newData[j].cost = designhomes[index].cost
              newData[j].type = designhomes[index].type
              newData[j].price = designhomes[index].price
              // newData[j].price_sum = designhomes[index].price_sum
              newData[j].qta = quotation_lists[j].qta
              // newData[j].price_sum_be = designhomes[index].price_sum_be
              newData[j].price_sum =
                parseInt(quotation_lists[j].qta) *
                ((parseInt(designhomes[index].price) / 100) *
                  (100 - designhomes[index].offer))
              newData[j].price_sum_be =
                parseInt(quotation_lists[j].qta) *
                parseInt(designhomes[index].price)
              newData[j].price_reduced =
                parseInt(quotation_lists[j].qta) *
                parseInt(designhomes[index].reduced)
              newData[j].reduced = designhomes[index].reduced
              newData[j].unit = designhomes[index].unit
            } else if (designhomes[index].type == 2) {
              newData[j].name = designhomes[index].name
              newData[j].cost = designhomes[index].cost
              newData[j].type = designhomes[index].type
              newData[j].offer = designhomes[index].offer
              newData[j].qta = quotation_lists[j].qta
              newData[j].price = designhomes[index].price
              newData[j].price_sum =
                parseInt(quotation_lists[j].qta) *
                ((parseInt(designhomes[index].price) / 100) *
                  (100 - designhomes[index].offer))
              newData[j].price_sum_be =
                parseInt(quotation_lists[j].qta) *
                parseInt(designhomes[index].price)
              newData[j].price_reduced =
                parseInt(quotation_lists[j].qta) *
                parseInt(designhomes[index].reduced)
              newData[j].reduced = designhomes[index].reduced
              newData[j].unit = designhomes[index].unit
            } else if (designhomes[index].type == 3) {
              newData[j].name = designhomes[index].name
              newData[j].type = designhomes[index].type
              newData[j].cost = designhomes[index].cost
              newData[j].offer = designhomes[index].offer
              newData[j].qta = quotation_lists[j].qta
              newData[j].price = designhomes[index].price
              newData[j].price_sum =
                parseInt(quotation_lists[j].qta) *
                ((parseInt(designhomes[index].price) / 100) *
                  (100 - designhomes[index].offer))
              newData[j].price_sum_be =
                parseInt(quotation_lists[j].qta) *
                parseInt(designhomes[index].price)
              newData[j].price_reduced =
                parseInt(quotation_lists[j].qta) *
                parseInt(designhomes[index].reduced)
              newData[j].reduced = designhomes[index].reduced
              newData[j].unit = designhomes[index].unit
            }
            setQuotation_lists(newData)
          }
        }

        if (designhomes[index].type == 1) {
          let newOpt = {
            value: designhomes[index].id,
            label: designhomes[index].code,
            unit: designhomes[index].unit,
            price: designhomes[index].price,
            cost: designhomes[index].cost,
            reduced: designhomes[index].reduced,
            offer: designhomes[index].offer,
            type: designhomes[index].type,
            option: designhomes[index].option,
          }
          styles.push(newOpt)
          // console.log("newOpt", newOpt)
        } else if (designhomes[index].type == 2) {
          let newOpt = {
            value: designhomes[index].id,
            label: designhomes[index].name,
            unit: designhomes[index].unit,
            cost: designhomes[index].cost,
            price: designhomes[index].price,
            reduced: designhomes[index].reduced,
            offer: designhomes[index].offer,
            type: designhomes[index].type,
          }
          finisheds.push(newOpt)
        } else if (designhomes[index].type == 3) {
          let newOpt = {
            value: designhomes[index].id,
            label: designhomes[index].name,
            cost: designhomes[index].cost,
            unit: designhomes[index].unit,
            price: designhomes[index].price,
            reduced: designhomes[index].reduced,
            offer: designhomes[index].offer,
            type: designhomes[index].type,
          }
          etcs.push(newOpt)
        }
      }
      let temp = [...optionGroup]
      // console.log("styles", styles)
      // temp[1].options = styles
      // temp[2].options = finisheds
      // temp[3].options = etcs
      setPriceProduct(temp)
    }
    // }
  }, [designhomes])

  function tog_modalIDCARD() {
    setModal_idCard(!modal_idCard)
    removeBodyCss()
  }

  React.useEffect(async () => {
    // console.log(banks);
    if (!isEmpty(banks)) {
      const options = [
        {
          value: "-1",
          label: "เพิ่มใหม่",
        },
      ]
      for (let index = 0; index < banks.length; index++) {
        options.push({
          value: banks[index].id,
          label: banks[index].name + "(" + banks[index].owen_nick_name + ")",
          name: banks[index].name,
          ower_name: banks[index].ower_name,
          no: banks[index].no,
        })
      }
      setGroups(options)
    } else {
      setGroups([])
    }
  }, [banks])

  React.useEffect(async () => {
    // console.log(banks);
    if (!isEmpty(users)) {
      const options = await users.map(d => ({
        value: d.id,
        label: d.name,
      }))
      setDataUsers(options)
    } else {
      setDataUsers([])
    }
  }, [users])

  React.useEffect(() => {
    // console.log('projectID',projectID);
    // console.log('infoprojectProfile',infoprojectProfile);

    if (!isEmpty(infoprojectProfile)) {
      if (infoprojectProfile.project_id == projectID) {
        setFormData(infoprojectProfile)
      } else {
        setFormData({})
      }
    } else {
      setFormData({})
    }
  }, [infoprojectProfile])

  React.useEffect(() => {
    // console.log('idCard',idCard);
    if (idCard.type == 4) {
      setModal_idCard(!modal_idCard)
    }
  }, [idCard])

  React.useEffect(() => {
    // console.log(loadingUploadImg)
    setLoadingUploadHome(loadingUploadImg)
  }, [loadingUploadImg])

  // React.useEffect(() => {
  //   // console.log(refprojectProfile.length)
  //   if (!isEmpty(refprojectProfile)) {
  //     setFileRefImg(refprojectProfile)
  //   } else {
  //     setFileRefImg([])
  //   }
  //   // setFormData(infoprojectProfile)
  // }, [refprojectProfile])

  React.useEffect(() => {
    if (!isEmpty(reqCallBack)) {
      // console.log("reqCallBack", reqCallBack)
      // console.log("index", indexQuo)
      if (indexQuo == null) {
        if (reqCallBack.type == 1) {
          setQuotation_lists([
            ...quotation_lists,
            {
              key: quotation_lists.length,
              quotation_id: null,
              list_product_id: reqCallBack.id,
              name: reqCallBack.code,
              qta: 1,
              unit: reqCallBack.unit,
              price: parseInt(reqCallBack.price),
              offer: parseInt(reqCallBack.offer),
              detail: null,
              price_sum:
                1 *
                ((parseInt(reqCallBack.price) / 100) *
                  (100 - reqCallBack.offer)),
              price_sum_be: 1 * parseInt(reqCallBack.price),
              price_reduced: 1 * parseInt(reqCallBack.reduced),
              reduced: reqCallBack.reduced,
              type: reqCallBack.type,
              del: 1,
            },
          ])
          addNoteAuto(reqCallBack.option)
        } else if (reqCallBack.type == 2) {
          setQuotation_lists([
            ...quotation_lists,
            {
              key: quotation_lists.length,
              quotation_id: null,
              list_product_id: reqCallBack.id,
              name: reqCallBack.code,
              qta: 1,
              unit: reqCallBack.unit,
              price: parseInt(reqCallBack.price),
              offer: parseInt(reqCallBack.offer),
              detail: null,
              price_sum:
                1 *
                ((parseInt(reqCallBack.price) / 100) *
                  (100 - reqCallBack.offer)),
              price_sum_be: 1 * parseInt(reqCallBack.price),
              price_reduced: 1 * parseInt(reqCallBack.reduced),
              reduced: reqCallBack.reduced,
              type: reqCallBack.type,
              del: 1,
            },
          ])
        } else if (reqCallBack.type == 3) {
          setQuotation_lists([
            ...quotation_lists,
            {
              key: quotation_lists.length,
              quotation_id: null,
              list_product_id: reqCallBack.id,
              name: reqCallBack.code,
              qta: 1,
              unit: reqCallBack.unit,
              price: parseInt(reqCallBack.price),
              offer: parseInt(reqCallBack.offer),
              detail: null,
              price_sum:
                1 *
                ((parseInt(reqCallBack.price) / 100) *
                  (100 - reqCallBack.offer)),
              price_sum_be: 1 * parseInt(reqCallBack.price),
              price_reduced: 1 * parseInt(reqCallBack.reduced),
              reduced: reqCallBack.reduced,
              type: reqCallBack.type,
              del: 1,
            },
          ])
        }
      } else {
        // console.log("indexQuo", quotation_lists[indexQuo].id)
        if (reqCallBack.type == 1) {
          let oldData = quotation_lists
          oldData[indexQuo] = {
            key: quotation_lists.length,
            id: quotation_lists[indexQuo].id,
            quotation_id: null,
            list_product_id: reqCallBack.id,
            name: reqCallBack.code,
            qta: 1,
            unit: reqCallBack.unit,
            price: parseInt(reqCallBack.price),
            offer: parseInt(reqCallBack.offer),
            detail: null,
            price_sum:
              1 *
              ((parseInt(reqCallBack.price) / 100) * (100 - reqCallBack.offer)),
            price_sum_be: 1 * parseInt(reqCallBack.price),
            price_reduced: 1 * parseInt(reqCallBack.reduced),
            reduced: reqCallBack.reduced,
            type: reqCallBack.type,
            del: 1,
          }
          setQuotation_lists(oldData)

          addNoteAuto(reqCallBack.option)
        } else if (reqCallBack.type == 2) {
          let oldData = quotation_lists
          oldData[indexQuo] = {
            key: quotation_lists.length,
            id: quotation_lists[indexQuo].id,
            quotation_id: null,
            list_product_id: reqCallBack.id,
            name: reqCallBack.code,
            qta: 1,
            unit: reqCallBack.unit,
            price: parseInt(reqCallBack.price),
            offer: parseInt(reqCallBack.offer),
            detail: null,
            price_sum:
              1 *
              ((parseInt(reqCallBack.price) / 100) * (100 - reqCallBack.offer)),
            price_sum_be: 1 * parseInt(reqCallBack.price),
            price_reduced: 1 * parseInt(reqCallBack.reduced),
            reduced: reqCallBack.reduced,
            type: reqCallBack.type,
            del: 1,
          }
          setQuotation_lists(oldData)
        } else if (reqCallBack.type == 3) {
          let oldData = quotation_lists
          oldData[indexQuo] = {
            key: quotation_lists.length,
            id: quotation_lists[indexQuo].id,
            quotation_id: null,
            list_product_id: reqCallBack.id,
            name: reqCallBack.code,
            qta: 1,
            unit: reqCallBack.unit,
            price: parseInt(reqCallBack.price),
            offer: parseInt(reqCallBack.offer),
            detail: null,
            price_sum:
              1 *
              ((parseInt(reqCallBack.price) / 100) * (100 - reqCallBack.offer)),
            price_sum_be: 1 * parseInt(reqCallBack.price),
            price_reduced: 1 * parseInt(reqCallBack.reduced),
            reduced: reqCallBack.reduced,
            type: reqCallBack.type,
            del: 1,
          }
          setQuotation_lists(oldData)
        }
      }
      setIndexQuo(null)
    }
    return () => {
      setReqCallBack(null)
    }
  }, [reqCallBack])
  // }, [reqCallBack, priceProduct])

  React.useEffect(() => {
    // console.log(serwayproject)
    if (!isEmpty(serwayproject)) {
      setFileSerway(serwayproject)
    } else {
      setFileSerway([])
    }
  }, [serwayproject])

  // React.useEffect(() => {
  //   console.log(formDataQuo)
  // }, [formDataQuo])

  React.useEffect(() => {
    // console.log(slipproject)
    if (!isEmpty(slipproject)) {
      setFileSlip(slipproject)
    } else {
      setFileSlip([])
    }
  }, [slipproject])

  React.useEffect(() => {
    // console.log("slip_money_sum", slip_money_sum)
    if (!isEmpty(slip_money_sum)) {
      // setFileSlip(slip_money_sum)
      setDataSlipSum(slip_money_sum.money_sum)
      setDataSlipSumAll(slip_money_sum.money_all)
    } else {
      // setFileSlip([])
      setDataSlipSum(0)
      setDataSlipSumAll(0)
    }
  }, [slip_money_sum])

  React.useEffect(() => {
    // console.log(quotationproject)
    if (!isEmpty(quotationproject)) {
      setDataQuotationproject(quotationproject)
    } else {
      setDataQuotationproject([])
    }
  }, [quotationproject])

  React.useEffect(() => {
    // console.log(quotationprojectProfile)
    if (!isEmpty(quotationprojectProfile) && actionQuo == "Edit") {
      setQuotation_cons(quotationprojectProfile.quotation_cons)
      setQuotation_lists(quotationprojectProfile.quotation_lists)
      setQuotation_notes(quotationprojectProfile.quotation_notes)
      setFormDataQuo(quotationprojectProfile.formData)
    } else {
    }
  }, [quotationprojectProfile])

  // React.useEffect(() => {
  //   console.log("formDataSlip", formDataSlip)
  // }, [formDataSlip])

  // React.useEffect(() => {
  //   console.log(formDataQuo)
  // }, [formDataQuo])

  React.useEffect(() => {
    // console.log(quotation_lists)
    if (!isEmpty(quotation_lists)) {
      // console.log("quotation_lists", quotation_lists)
      // let sum = Object.keys(quotation_lists).reduce(function (previous, key) {
      //   return previous + parseInt(quotation_lists[key].price_sum)
      // }, 0)

      // let discount = Object.keys(quotation_lists).reduce(function (
      //   previous,
      //   key
      // ) {
      //   if (
      //     quotation_lists[key].price_sum < quotation_lists[key].price_sum_be
      //   ) {
      //     return (
      //       previous +
      //       (parseInt(quotation_lists[key].price_sum_be) -
      //         parseInt(quotation_lists[key].price_sum))
      //     )
      //   }

      //   // if (
      //   //   parseInt(quotation_lists[key].price_sum) >
      //   //   parseInt(quotation_lists[key].price_sum_be)
      //   // ) {
      //   //   console.log("1", quotation_lists[key])
      //   // } else {
      //   //   console.log("2", quotation_lists[key])

      //   //   return (
      //   //     previous +
      //   //     (parseInt(quotation_lists[key].price_sum_be) -
      //   //       parseInt(quotation_lists[key].price_sum))
      //   //   )
      //   // }
      // },
      // 0)
      let sum = 0
      let discount = 0
      for (let index = 0; index < quotation_lists.length; index++) {
        sum += parseInt(quotation_lists[index].price_sum)
        if (
          parseInt(quotation_lists[index].price_sum_be) >
          parseInt(quotation_lists[index].price_sum)
        ) {
          discount +=
            parseInt(quotation_lists[index].price_sum_be) -
            parseInt(quotation_lists[index].price_sum)
          // console.log("1", quotation_lists[index])
        }
      }
      setFormDataQuo({
        ...formDataQuo,
        price_sum: isNaN(sum) ? 0 : sum,
        discount: isNaN(discount) ? 0 : discount,
      })
      let temp_con = [...quotation_cons]
      for (let index = 0; index < quotation_cons.length; index++) {
        // console.log((sum / 100) * temp_con[index].value)
        temp_con[index].sum_all = (sum / 100) * temp_con[index].value
      }

      setQuotation_cons(temp_con)
      setMoney_all(sum)
    }
  }, [quotation_lists])

  const handleSubmit = async (event, errors, values) => {
    // console.log(formData)
    setButtonSubmit(true)
    if (errors.length > 0) {
    } else {
      // console.log(formData)
      if (formData.action == "Add") {
        // save new user
        dispatch(onAddNewInfoProject(formData))
      } else if (formData.action == "Edit") {
        // update user
        dispatch(onUpdateInfoProject(formData))
      }
    }
    setModal_idCard(false)
    setButtonSubmit(false)
  }

  function fixFormSubmit(status) {
    formData.insert_status = status
    // console.log(formData);
    dispatch(onUpdateInfoProject(formData))
  }

  React.useEffect(() => {
    // console.log("setDataProfitlossprojects", profitlossprojects)
    if (!isEmpty(profitlossprojects)) {
      setActionModalProfitLoss({
        modal: true,
        details_costs: profitlossprojects.money_cost,
        details_wages: profitlossprojects.money_wage,
        details_materials: profitlossprojects.money_material,
        project_name: formData.name,
      })
    } else {
      setActionModalProfitLoss({
        modal: false,
        details_costs: [],
        details_wages: [],
        details_materials: [],
        project_name: "",
      })
    }
  }, [profitlossprojects])

  function getProfitLoss() {
    // console.log("getProfitLoss", projectID)
    dispatch(onGetProfitLossProjects(projectID))
  }

  const handleSubmit_quo = async (event, errors, values, type) => {
    // console.log(quotation_notes)
    if (errors.length > 0) {
      console.log("errors", errors)
    } else {
      const data = new FormData()
      data.append("project_id", projectID)
      data.append("formData", JSON.stringify(formDataQuo))
      data.append("img", dataImg_quo)
      data.append("quotation_lists", JSON.stringify(quotation_lists))
      data.append("quotation_notes", JSON.stringify(quotation_notes))
      data.append("quotation_cons", JSON.stringify(quotation_cons))
      if (actionQuo == "Add") {
        setModal_xlarge_quo(!modal_xlarge_quo)
        dispatch(onAddNewQuotationProject(data))
      } else if (actionQuo == "Edit") {
        dispatch(onUpdateQuotationProject(data))
      }
      // localStorage.setItem("model_quo", false)
      if (type == "print") {
        setTimeout(() => {
          history.push("/invoices-detail/" + formDataQuo.id)
        }, 3000)
      }
      setModal_xlarge_quo(false)
      setCheckPreview(false)
      removeBodyCss()
    }
  }

  const handleSubmit_slip = async (event, errors, values, type) => {
    // console.log("handleSubmit_quo")
    if (errors.length > 0) {
      console.log("errors", errors)
    } else {
      const data = new FormData()
      data.append("formData", JSON.stringify(formDataSlip))
      data.append("img", dataImg_slip)
      dispatch(onUpdateSlipproject(data))
      setModal_xlarge_slip(!modal_xlarge_slip)
      removeBodyCss()
    }
    if (type == "print") {
      setTimeout(() => {
        history.push("/invoices-slip/" + formDataSlip.id)
      }, 3000)
    }
  }

  function AddQuo(method, id) {
    setActionQuo(method)
    setQuotation_cons([])
    setQuotation_lists([])
    setQuotation_notes([])

    if (method == "Add") {
      setFormDataQuo({
        quo_date: moment().format("YYYY-MM-DD"),
        name: formData.contact,
        tel: formData.tel,
        address: formData.address,
        note: formData.highlight,
        sign_ower: formData.contact,
        bank_id: 1,
        sign_manager: "รุจ อินทสุรัช",
        book_bank: "009-8-48179-6",
        name_bank_ower: "เสริมทรัพย์ 27",
        name_bank: "ธนาคารกสิกรไทย (KBANK)",
        vat_type: 1,
        quo_type: 1,
        discount: 0,
        price_sum: 0,
        input_light: 0,
        input_light_value: 0,
        input_plug_value: 0,
        input_deposit_value: 10000,
      })

      setQuotation_cons([
        {
          name: "ก่อนเริ่มงาน",
          value: 50,
          sum_all: (parseInt(formDataQuo.price_sum) / 100) * 50,
        },
        {
          name: "กรุผนังภายใน/ภายนอก",
          value: 40,
          sum_all: (parseInt(formDataQuo.price_sum) / 100) * 30,
        },
        {
          name: "ส่งมอบงาน",
          value: 10,
          sum_all: (parseInt(formDataQuo.price_sum) / 100) * 20,
        },
        { name: "", value: 0 },
      ])

      addRow("first")
    } else if (method == "Edit") {
      setCheckRouteModal({
        action: method,
        id: id,
      })
      dispatch(onGetQuotationProjectProfile(id, null))
    }
    setModal_xlarge_quo(!modal_xlarge_quo)

    removeBodyCss()
  }

  function addRow(type) {
    if (type == "first") {
      setQuotation_lists([
        {
          key: quotation_lists.length,
          quotation_id: null,
          list_product_id: null,
          name: "คลิกเพื่อเลือกรายการ",
          qta: 1,
          unit: null,
          price: 0,
          price_sum: 0,
          price_sum_be: 0,
          price_reduced: 0,
          del: 1,
        },
      ])
    } else {
      setQuotation_lists([
        ...quotation_lists,
        {
          key: quotation_lists.length,
          quotation_id: null,
          list_product_id: null,
          name: "คลิกเพื่อเลือกรายการ",
          qta: 1,
          unit: null,
          price: null,
          price_sum: null,
          price_sum_be: 0,
          price_reduced: 0,
          del: 1,
        },
      ])
    }
    // console.log(1)

    // console.log(quotation_lists);
  }
  function handleRemoveItem(item) {
    const temp = [...quotation_lists]
    for (let index = 0; index < temp.length; index++) {
      if (temp[index]["key"] != undefined) {
        // console.log("exists", temp[index])
        if (temp[index]["key"] == item.key) {
          temp[index].del = 0
        }
      } else {
        if (temp[index].id == item.id) {
          temp[index].del = 0
        }
        // console.log(temp[index])
      }
    }
    setQuotation_lists(temp)
    // setQuotation_lists(temp)
    // // assigning the list to temp variable
    // const temp = [...quotation_lists]
    // // removing the element using splice
    // temp.splice(index, 1)
    // // updating the list
    // setQuotation_lists(temp)
  }

  function addRow_note(type) {
    if (type == "new") {
      setQuotation_notes([
        ...quotation_notes,
        {
          key: quotation_notes.length,
          name:
            "หลอดไฟภายใน/ภายนอกรวมไม่เกิน " +
            formDataQuo.input_light_value +
            " หลอด",
          del: 1,
        },
        {
          key: parseInt(quotation_notes.length) + 1,
          name: "ปลั๊กรวมไม่เกิน " + formDataQuo.input_plug_value + " จุด",
          del: 1,
        },
      ])
    } else {
      setQuotation_notes([
        ...quotation_notes,
        {
          key: quotation_notes.length,
          name: null,
          del: 1,
        },
      ])
    }

    // console.log(quotation_notes);
  }
  function handleRemoveItem_note(item) {
    const temp = [...quotation_notes]

    for (let index = 0; index < temp.length; index++) {
      if (temp[index]["key"] != undefined) {
        // console.log("exists", temp[index])
        if (temp[index]["key"] == item.key) {
          temp[index].del = 0
        }
      } else {
        if (temp[index].id == item.id) {
          temp[index].del = 0
        }
        // console.log(temp[index])
      }
    }
    setQuotation_notes(temp)
  }

  function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image()
      img.setAttribute("crossOrigin", "anonymous")

      img.onload = () => {
        var canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height

        var ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0)

        var dataURL = canvas.toDataURL("image/png")

        resolve(dataURL)
      }

      img.onerror = error => {
        reject(error)
      }

      img.src = url
    })
  }

  const handleSubmit_quoVat = async (event, errors, values, type) => {
    if (errors.length > 0) {
      console.log("errors", errors)
    } else {
      var docDefinition = {
        watermark: {
          text: process.env.REACT_APP_NAMECOMPANY,
          color: "#e6e6e6",
          opacity: 0.5,
        },
        content: [
          {
            columns: [
              {
                style: "header",
                alignment: "left",
                width: "50%",
                text: "ติดต่อ:   Line @ : @pmh-m   Tel (รุจ) : 062 640 5432	",
              },
              {
                alignment: "right",
                width: "50%",
                text: "",
              },
            ],
          },
          {
            columns: [
              {
                style: "header",
                alignment: "left",
                width: "80%",
                text: [
                  "(ต้นฉบับ / original)\n",
                  {
                    text: "ใบเสร็จรับเงิน/ใบกำกับภาษี\n",
                    fontSize: 18,
                    bold: true,
                  },
                  " วันที่: " +
                    moment(formData_quoVat.quo_date)
                      .add(543, "year")
                      .format("LL"),
                  " เลขที่: " + formData_quoVat.book_no,
                ],
              },

              {
                image: await getBase64ImageFromURL(logo_s),
                width: 40,
                height: 50,
              },
            ],
          },

          {
            margin: [0, 0, 0, 20],
            style: "tableExample",
            table: {
              headerRows: 1,
              widths: ["50%", "50%"],
              body: [
                [
                  {
                    border: [false, false, true, false],
                    text: [
                      "ชื่อผู้ขาย: ",
                      {
                        text: "หจก. เสริมทรัพย์ 27\n",
                        fontSize: 13,
                        bold: true,
                      },
                      "ที่อยู่: ซอยวัดเวฬุวนาราม 16 ถนนสรงประภา แขวงดอนเมือง เขตดอนเมือง กรุงเทพมหานคร 10210",
                    ],
                  },
                  {
                    border: [false, false, false, false],

                    text: [
                      "ชื่อผู้ซื้อ: ",
                      {
                        border: [false, false, false, false],
                        text:
                          formData_quoVat.name +
                          " (" +
                          formData_quoVat.id_card +
                          ")",
                        fontSize: 13,
                        bold: true,
                      },
                      "\nเบอร์: ",
                      {
                        text: formData_quoVat.tel,
                        fontSize: 13,
                        bold: true,
                      },

                      "\nที่อยู่: " + formData_quoVat.address,
                    ],
                  },
                ],
              ],
            },
            layout: {
              defaultBorder: false,
            },
          },

          {
            margin: [0, 0, 0, 50],
            style: "tableExample",
            table: {
              widths: ["auto", "*", "auto"],
              heights: function (row) {
                return row == 1 ? 350 : 0
              },
              body: [
                [
                  {
                    bold: true,
                    text: "ลำดับ\nOrd no",
                  },
                  {
                    bold: true,
                    text: "รายละเอียด\nDescription",
                  },
                  {
                    bold: true,
                    text: "เป็นเงิน\nAmount",
                    alignment: "right",
                  },
                ],
                [
                  {
                    text: "1",
                  },
                  {
                    text: formData_quoVat.detail,
                  },
                  {
                    alignment: "right",
                    text: parseFloat(
                      Math.floor(parseFloat(formData_quoVat.price) * 100) / 100
                    ).toLocaleString("th-TH"),
                  },
                ],
                [
                  {
                    alignment: "center",
                    text:
                      "ยอด vat 7% (" +
                      THBText(
                        Math.floor(
                          parseFloat(
                            (parseFloat(formData_quoVat.price) / 100) * 7
                          ) * 100
                        ) / 100
                      ) +
                      ")",

                    colSpan: 2,
                  },
                  {},
                  {
                    alignment: "right",
                    text: parseFloat(
                      Math.floor(
                        parseFloat(
                          (parseFloat(formData_quoVat.price) / 100) * 7
                        ) * 100
                      ) / 100
                    ).toLocaleString("th-TH"),
                  },
                ],
                [
                  {
                    alignment: "center",
                    text:
                      "ยอด ก่อน Vat (" +
                      THBText(
                        Math.floor(parseFloat(formData_quoVat.price) * 100) /
                          100
                      ) +
                      ")",
                    colSpan: 2,
                  },
                  {},
                  {
                    alignment: "right",
                    text: parseFloat(
                      Math.floor(parseFloat(formData_quoVat.price) * 100) / 100
                    ).toLocaleString("th-TH"),
                  },
                ],
                [
                  {
                    alignment: "center",
                    text:
                      "ยอดสุทธิ (" +
                      THBText(
                        Math.floor(
                          parseFloat(
                            (parseFloat(formData_quoVat.price) / 100) * 7 +
                              parseFloat(formData_quoVat.price)
                          ) * 100
                        ) / 100
                      ) +
                      ")",
                    colSpan: 2,
                  },
                  {},
                  {
                    alignment: "right",
                    text: parseFloat(
                      Math.floor(
                        parseFloat(
                          (parseFloat(formData_quoVat.price) / 100) * 7 +
                            parseFloat(formData_quoVat.price)
                        ) * 100
                      ) / 100
                    ).toLocaleString("th-TH"),
                  },
                ],
              ],
            },
          },

          {
            columns: [
              {
                style: "header",
                alignment: "left",
                width: "50%",
                text: "",
              },
              {
                alignment: "center",
                image: await getBase64ImageFromURL(signature_manager),
                width: "50%",
                fit: [100, 100],
              },
              {
                alignment: "center",
                width: "50%",
                text: "( ............................................ )\nรุจ อินทสุรัช \n(ผู้จัดการ)",
              },
            ],
          },
        ],
        styles: {
          header: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 5],
          },
          subheader: {
            fontSize: 13,
            decoration: "underline",
            decorationStyle: "solid",
            decorationColor: "black",
          },
          tableExample: {
            fontSize: 13,
          },
          tableHeader: {
            bold: true,
            fontSize: 14,
            color: "black",
          },
        },
        defaultStyle: {
          font: "THSarabunNew",
        },
        pageSize: "A4",
      }
      // pdfMake.createPdf(docDefinition).open()
      let quo_name = "ใบเสร็จรับเงิน/ใบกำกับภาษี.pdf"
      // console.log(quo_name)
      pdfMake.createPdf(docDefinition).download(quo_name)
    }
  }
  async function handleAcceptedFiles_home(e) {
    setLoadingUploadHome(true)
    // console.log(formData.project_id);
    if (e.target.files[0]) {
      // console.log("picture: ", e.target.files);
      // setSelectedFileHome(e.target.files[0])
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        setImg(reader.result)
      })
      reader.readAsDataURL(e.target.files[0])

      const data = new FormData()
      data.append("project_id", formData.project_id)
      data.append("img", await resizeImageFn(e.target.files[0]))
      dispatch(onUploadImgHome(data))
    }
  }
  async function getQuotation_condition_lists() {
    setActionModalQuotation_condition_list({
      id: projectID,
      action: "Edit",
      modal: true,
    })
  }
  async function getContact_condition_lists() {
    setActionModalContact_condition_list({
      id: projectID,
      action: "Edit",
      modal: true,
    })
  }

  async function getEmployment_contract_lists() {
    setActionModalEmployment_contract_list({
      id: projectID,
      action: "Edit",
      modal: true,
    })
  }

  async function handleAcceptedFiles_Quo(e) {
    if (e.target.files[0]) {
      // console.log("picture: ", e.target.files);
      setDataImg_quo(await resizeImageFn(e.target.files[0]))
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        setImg_quo(reader.result)
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  async function handleAcceptedFiles_Slip(e) {
    if (e.target.files[0]) {
      // console.log("picture: ", e.target.files);
      setDataImg_slip(await resizeImageFn(e.target.files[0]))
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        setImg_slip(reader.result)
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  async function actionGetSlip(data) {
    // console.log(data)
    setFormDataSlip(data)
    setModal_xlarge_slip(!modal_xlarge_slip)
    removeBodyCss()
  }

  async function handleAcceptedFiles(files) {
    // console.log(files)
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    const data = new FormData()
    data.append("comment", comment)
    data.append("add_info_id", formData.id)
    data.append("project_id", projectID)
    data.append("type", selectUploadID)
    data.append("bank_id", bank_id)
    if (selectUploadID == 1) {
      for (let i = 0; i < files.length; i++) {
        // data.append(`add_info_imgs[${i}]`, files[i])
        data.append(`add_info_imgs[${i}]`, await resizeImageFn(files[i]))
      }
      dispatch(onUploadImgInfoRef(data))
    } else if (selectUploadID == 2) {
      for (let i = 0; i < files.length; i++) {
        // data.append(`serway_details[${i}]`, files[i])
        data.append(`serway_details[${i}]`, await resizeImageFn(files[i]))
      }
      dispatch(onUploadImgInfoSerway(data))
    } else if (selectUploadID == 3) {
      data.append("money", money)
      data.append("installments", installments)

      data.append("type", 5)

      for (let i = 0; i < files.length; i++) {
        // data.append(`slip[${i}]`, files[i])
        data.append(`slip[${i}]`, await resizeImageFn(files[i]))
      }
      dispatch(onUploadImgInfoSlip(data))
    } else if (selectUploadID == 4) {
      for (let i = 0; i < files.length; i++) {
        // data.append(`add_info_imgs[${i}]`, files[i])
        data.append(`add_info_imgs[${i}]`, await resizeImageFn(files[i]))
      }
      dispatch(onUploadImgInfoRef(data))
    }

    setselectedFiles(files)
  }

  async function resizeImageFn(file) {
    // console.log("originalFile instanceof Blob", file instanceof Blob) // true
    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options)
      // console.log(
      //   "compressedFile instanceof Blob",
      //   compressedFile instanceof Blob
      // ) // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

      // await uploadToServer(compressedFile) // write your own logic
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }

  function addNoteAuto(data) {
    for (let index = 0; index < data.length; index++) {
      if (data[index].name != "null") {
        let temp = quotation_notes
        temp.push({
          key: quotation_notes.length,
          name: data[index].name,
          del: 1,
        })
        setQuotation_notes(temp)
        // x.push({
        //   key: x.length,
        //   name: data[index].name,
        //   del: 1,
        // })
        // setQuotation_notes(x)
      }
    }
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseInt((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function eventAction(modal) {
    setActionModel({
      modal: modal,
    })
  }
  function delImgSerway(id) {
    dispatch(onDeleteSerwayproject(id))
  }
  return (
    <React.Fragment>
      <style scoped>
        {
          "\
          @media (max-width: 540px) {\
            .header-table{\
                display:none\
            }\
          }\
          .header-table{\
            align-content: center;\
            border-start-start-radius: 10px;\
            border-start-end-radius: 10px;\
            background-color: #F0F0F0;\
            height: 50px;\
          }\
          "
        }
      </style>

      {!isEmpty(projectID) && (
        <div>
          {isGallery ? (
            <Lightbox
              mainSrc={
                process.env.REACT_APP_SERVER + fileSerway[photoIndex].img_path
              }
              nextSrc={
                process.env.REACT_APP_SERVER +
                fileSerway[(photoIndex + 1) % fileSerway.length].img_path
              }
              prevSrc={
                process.env.REACT_APP_SERVER +
                fileSerway[
                  (photoIndex + fileSerway.length - 1) % fileSerway.length
                ].img_path
              }
              enableZoom={true}
              toolbarButtons={[
                <div key={photoIndex}>
                  <h5
                    className="pb-1"
                    onClick={() => {
                      delImgSerway(fileSerway[photoIndex].id)
                    }}
                    style={{
                      cursor: "pointer",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <i
                      style={{ fontSize: "20px", color: "white" }}
                      className="bx bxs-trash-alt"
                    ></i>
                  </h5>
                </div>,
              ]}
              onCloseRequest={() => {
                setisGallery(false)
              }}
              onMovePrevRequest={() => {
                setphotoIndex(
                  (photoIndex + fileSerway.length - 1) % fileSerway.length
                )
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % fileSerway.length)
              }}
              imageCaption={
                process.env.REACT_APP_NAMECOMPANY +
                ":" +
                parseInt(photoIndex + 1)
              }
            />
          ) : null}

          <div>
            <Modal
              size="md"
              centered
              isOpen={modal_quoVat}
              toggle={() => {
                setModal_quoVat(!modal_quoVat)
              }}
            >
              <AvForm onSubmit={handleSubmit_quoVat} model={formData_quoVat}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    ใบกำกับภาษี
                  </h5>

                  <button
                    type="button"
                    onClick={() => {
                      setModal_quoVat(!modal_quoVat)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col sm="12" md="6">
                      <Label htmlFor="fformData_quoVat.detail">
                        รายละเอียด
                      </Label>
                      <AvField
                        type="text"
                        value={formData_quoVat.detail}
                        placeholder="รายละเอียด"
                        onChange={e =>
                          setFormData_quoVat({
                            ...formData_quoVat,
                            detail: e.target.value,
                          })
                        }
                        id="formData_quoVat.detail"
                        name="formData_quoVat.detail"
                      />
                    </Col>
                    <Col sm="12" md="6">
                      <Label htmlFor="fformData_quoVat.price">จำนวนเงิน</Label>
                      <AvField
                        type="number"
                        value={formData_quoVat.price}
                        placeholder="จำนวนเงิน"
                        onChange={e =>
                          setFormData_quoVat({
                            ...formData_quoVat,
                            price: e.target.value,
                          })
                        }
                        name="formData_quoVat.price"
                        id="formData_quoVat.price"
                      />
                    </Col>

                    <Col sm="12" md="6">
                      <Label htmlFor="fformData_quoVat.name">
                        ชื่อ-นามสกุล
                      </Label>

                      <AvField
                        type="text"
                        value={formData_quoVat.name}
                        placeholder="ชื่อ-นามสกุล"
                        onChange={e =>
                          setFormData_quoVat({
                            ...formData_quoVat,
                            name: e.target.value,
                          })
                        }
                        id="formData_quoVat.name"
                        name="formData_quoVat.name"
                      />
                    </Col>

                    <Col sm="12" md="6">
                      <Label htmlFor="fformData_quoVat.id_card">เลขบัตร</Label>

                      <AvField
                        type="text"
                        value={formData_quoVat.id_card}
                        placeholder="เลขบัตร"
                        onChange={e =>
                          setFormData_quoVat({
                            ...formData_quoVat,
                            id_card: e.target.value,
                          })
                        }
                        id="formData_quoVat.id_card"
                        name="formData_quoVat.id_card"
                      />
                    </Col>
                    <Col sm="12" md="6">
                      <Label htmlFor="fformData_quoVat.tel">เบอร์โทร</Label>

                      <AvField
                        type="text"
                        value={formData_quoVat.tel}
                        placeholder="เบอร์โทร"
                        onChange={e =>
                          setFormData_quoVat({
                            ...formData_quoVat,
                            tel: e.target.value,
                          })
                        }
                        id="formData_quoVat.tel"
                        name="formData_quoVat.tel"
                      />
                    </Col>

                    <Col sm="12" md="12">
                      <Label htmlFor="fformData_quoVat.address">ที่อยู่</Label>
                      <AvField
                        type="text"
                        value={formData_quoVat.address}
                        placeholder="ที่อยู่"
                        onChange={e =>
                          setFormData_quoVat({
                            ...formData_quoVat,
                            address: e.target.value,
                          })
                        }
                        id="formData_quoVat.address"
                        name="formData_quoVat.address"
                      />
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary "
                    data-dismiss="modal"
                  >
                    Save
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      setModal_quoVat(!modal_quoVat)
                    }}
                    className="btn btn-danger "
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </AvForm>
            </Modal>

            <Modal
              size="lg"
              style={{ maxWidth: "90%" }}
              isOpen={modal_xlarge_quo}
              toggle={() => {
                setModal_xlarge_quo(!modal_xlarge_quo)
              }}
              centered={true}
            >
              <AvForm onSubmit={handleSubmit_quo} model={formDataQuo}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                    {actionQuo == "Add" ? "เพิ่ม" : "แก้ไข"} ใบเสนอราคา
                  </h5>
                  <button
                    onClick={() => {
                      setModal_xlarge_quo(false)
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Row lg="12">
                      {actionQuo == "Add" ? null : (
                        <Col sm="12" md="12" style={{ textAlign: "right" }}>
                          <span className="font-size-18">
                            เลขที่: &nbsp;
                            {formDataQuo.code +
                              formDataQuo.year +
                              formDataQuo.month +
                              formDataQuo.day +
                              formDataQuo.term}
                          </span>
                        </Col>
                      )}

                      <Col sm="12" md="12" lg="2" xl="2">
                        <AvField
                          style={{ marginLeft: "4px" }}
                          value={formDataQuo.quotation_name}
                          placeholder="ชื่อใบเสนอราคา"
                          onChange={e =>
                            setFormDataQuo({
                              ...formDataQuo,
                              quotation_name: e.target.value,
                            })
                          }
                          name="quotation_name"
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      <Col sm="12" md="12" lg="10" xl="10">
                        <div
                          className="mb-3 d-flex justify-content-end"
                          style={{ float: "right" }}
                        >
                          <FormGroup className="mt-2">
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radioQuo_type"
                                  checked={formDataQuo.quo_type == 1}
                                  value={1}
                                  onChange={e =>
                                    setFormDataQuo({
                                      ...formDataQuo,
                                      quo_type: e.target.value,
                                    })
                                  }
                                />
                                ใช้ใบนี้
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Label>
                                <Input
                                  type="radio"
                                  name="radioQuo_type"
                                  checked={formDataQuo.quo_type == 2}
                                  value={2}
                                  onChange={e =>
                                    setFormDataQuo({
                                      ...formDataQuo,
                                      quo_type: e.target.value,
                                    })
                                  }
                                />
                                ไม่ใช้
                              </Label>
                            </FormGroup>
                          </FormGroup>

                          <AvField
                            style={{ marginLeft: "4px" }}
                            value={formDataQuo.quo_date}
                            placeholder="date"
                            onChange={e =>
                              setFormDataQuo({
                                ...formDataQuo,
                                quo_date: e.target.value,
                              })
                            }
                            name="quo_date"
                            type="date"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Col lg="12">
                      <Row>
                        <Col lg="4">
                          <div className="mb-3">
                            <p
                              style={{
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            >
                              ชื่อลูกค้า:{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                {formDataQuo.name}
                              </span>
                            </p>
                          </div>
                        </Col>

                        <Col lg="4">
                          <div className="mb-3">
                            <p
                              style={{
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            >
                              เบอร์โทรลูกค้า:{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                {formDataQuo.tel}
                              </span>
                            </p>
                          </div>
                        </Col>

                        <Col lg="12">
                          <div className="mb-3">
                            <p
                              style={{
                                fontSize: "17px",
                                fontWeight: "bold",
                              }}
                            >
                              ที่อยู่:{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                {formDataQuo.address}
                              </span>
                            </p>
                          </div>
                        </Col>

                        <Col lg="12">
                          <p
                            style={{
                              fontSize: "17px",
                              fontWeight: "bold",
                            }}
                          >
                            หมายเหตุ:{" "}
                            <span
                              style={{
                                fontWeight: "normal",
                              }}
                            >
                              {formDataQuo.note}
                            </span>
                          </p>
                        </Col>

                        <Col lg="12">
                          <div className="text-left">
                            {Img_quo == undefined ? (
                              formDataQuo.img_path ==
                              undefined ? null : formDataQuo.img_del ==
                                0 ? null : (
                                <div
                                  style={{
                                    height: "150px",
                                    width: "150px",
                                    textAlign: "right",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={e =>
                                      setFormDataQuo({
                                        ...formDataQuo,
                                        img_del: 0,
                                      })
                                    }
                                  >
                                    ลบ
                                  </p>
                                  <a
                                    href={
                                      process.env.REACT_APP_SERVER +
                                      formDataQuo.img_path
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_SERVER +
                                        formDataQuo.img_path
                                      }
                                      alt={process.env.REACT_APP_NAMECOMPANY}
                                      style={{
                                        objectFit: "contain",
                                        height: "150px",
                                        width: "150px",
                                      }}
                                    />
                                  </a>
                                </div>
                              )
                            ) : (
                              <img
                                src={Img_quo}
                                alt={process.env.REACT_APP_NAMECOMPANY}
                                style={{
                                  objectFit: "contain",
                                  height: "150px",
                                  width: "150px",
                                }}
                              />
                            )}
                          </div>

                          <Label
                            htmlFor="formFileSm_quo"
                            className="form-label"
                          >
                            File Quo
                          </Label>
                          <input
                            className="form-control form-control-sm"
                            id="formFileSm_quo"
                            type="file"
                            accept="image/*"
                            multiple={false}
                            onChange={handleAcceptedFiles_Quo}
                          />
                        </Col>
                      </Row>
                      <hr />

                      <Row>
                        <Col sm={6}>
                          <h5>รายการ </h5>
                        </Col>
                        <Col sm={6}>
                          <div className="float-end ms-2">
                            <UncontrolledDropdown
                              className="mb-2"
                              direction="left"
                            >
                              <DropdownToggle
                                color="white"
                                className="btn btn-link text-muted mt-n2"
                              >
                                <i className="mdi mdi-dots-horizontal"></i>
                              </DropdownToggle>

                              <DropdownMenu>
                                <DropdownItem onClick={() => eventAction(true)}>
                                  เพิ่ม/แก้ไขสินค้า
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Table size="lg" responsive>
                        <thead>
                          <tr>
                            <th style={{ width: "15%" }}>ชื่อ</th>
                            <th style={{ width: "27%" }}>รายละเอียด</th>
                            <th style={{ width: "8%" }}>จำนวน</th>
                            <th style={{ width: "8%" }}>หน่วย</th>
                            <th style={{ width: "10%" }}>ราคาต่อหน่วย</th>
                            <th style={{ width: "10%" }}>ราคาหลังลด</th>
                            <th style={{ width: "10%" }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {quotation_lists.map((item, index) =>
                            item.del == 0 ? null : (
                              <tr key={index}>
                                <td>
                                  <Button
                                    className="form-control"
                                    color="info"
                                    outline
                                    onClick={e => {
                                      // console.log(e)
                                      eventAction(true)
                                      setIndexQuo(index)

                                      // handleRemoveItem(item)
                                    }}
                                  >
                                    {item.name}
                                  </Button>
                                </td>

                                <td>
                                  <AvField
                                    name={"detail" + [index]}
                                    type="text"
                                    placeholder="รายละเอียดชื่อ"
                                    value={quotation_lists[index].detail}
                                    onChange={e => {
                                      let select = [...quotation_lists]
                                      select[index].detail = e.target.value
                                      setQuotation_lists(select)
                                    }}
                                    validate={{
                                      required: { value: false },
                                    }}
                                  />
                                </td>

                                <td>
                                  <AvField
                                    name={"qta" + [index]}
                                    type="number"
                                    placeholder="จำนวน"
                                    onChange={e => {
                                      let select = [...quotation_lists]
                                      select[index].qta = e.target.value
                                      select[index].price_sum_be =
                                        e.target.value *
                                        parseInt(select[index].price)

                                      select[index].price_sum =
                                        e.target.value *
                                        ((parseInt(select[index].price) / 100) *
                                          (100 - parseInt(select[index].offer)))

                                      select[index].price_reduced =
                                        e.target.value *
                                        parseInt(select[index].reduced)
                                      setQuotation_lists(select)
                                      setCheckPreview(true)
                                    }}
                                    value={quotation_lists[index].qta}
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </td>

                                <td>
                                  <AvField
                                    name={"unit" + [index]}
                                    type="text"
                                    placeholder="หน่วย"
                                    readOnly
                                    value={quotation_lists[index].unit}
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </td>

                                <td>
                                  <NumberFormat
                                    className="av-valid form-control"
                                    name={"price-unit" + [index]}
                                    readOnly
                                    value={quotation_lists[index].price}
                                    onValueChange={e => {
                                      let select = [...quotation_lists]
                                      select[index].price = e.value
                                      select[index].price_sum_be =
                                        parseInt(select[index].qta) * e.value
                                      select[index].price_sum =
                                        parseInt(select[index].qta) *
                                        ((e.value / 100) *
                                          (100 - parseInt(select[index].offer)))
                                      setQuotation_lists(select)
                                    }}
                                    thousandsGroupStyle="thousand"
                                    prefix=""
                                    decimalSeparator="."
                                    displayType="input"
                                    type="text"
                                    thousandSeparator={true}
                                    allowNegative={true}
                                  />
                                </td>

                                <td>
                                  <NumberFormat
                                    className="av-valid form-control"
                                    name={"price_sum" + [index]}
                                    value={quotation_lists[index].price_sum}
                                    onValueChange={e => {
                                      let select = [...quotation_lists]
                                      select[index].price_sum = e.value
                                      setQuotation_lists(select)
                                      setCheckPreview(true)
                                    }}
                                    thousandsGroupStyle="thousand"
                                    prefix=""
                                    decimalSeparator="."
                                    displayType="input"
                                    type="text"
                                    thousandSeparator={true}
                                    allowNegative={true}
                                  />

                                  <span style={{ color: "#556ee6" }}>
                                    ลดได้:&nbsp;
                                    {quotation_lists[index].price_reduced !=
                                    undefined
                                      ? parseInt(
                                          quotation_lists[index].price_reduced
                                        ).toLocaleString("th-TH", {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                        })
                                      : 0}
                                  </span>
                                </td>
                                <td>
                                  <Button
                                    name={"row_rm" + [index]}
                                    color="danger"
                                    onClick={() => handleRemoveItem(item)}
                                  >
                                    <i className="mdi mdi-block-helper" />
                                  </Button>
                                </td>
                              </tr>
                            )
                          )}
                          <tr>
                            <td colSpan={6}></td>
                            <td>
                              <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                // onClick={() => addRow()}
                                onClick={() => eventAction(true)}
                              >
                                <i className="mdi mdi-plus-circle-outline" />
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Row className="mt-2">
                        <Col xs={10} sm={10} md={11} lg={11}>
                          <FormGroup className="mt-2">
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radioVatType"
                                  checked={formDataQuo.vat_type == 1}
                                  value={1}
                                  onChange={e =>
                                    setFormDataQuo({
                                      ...formDataQuo,
                                      vat_type: e.target.value,
                                    })
                                  }
                                />
                                ไม่เอา Vat
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Label>
                                <Input
                                  type="radio"
                                  name="radioVatType"
                                  checked={formDataQuo.vat_type == 2}
                                  value={2}
                                  onChange={e =>
                                    setFormDataQuo({
                                      ...formDataQuo,
                                      vat_type: e.target.value,
                                    })
                                  }
                                />
                                เอา Vat
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </Col>

                        <Col xs={2} sm={2} md={1} lg={1}></Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm="12" md="3">
                      <FormGroup>
                        <Label for="input_deposit_value">ค่ามัดจำ</Label>
                        <AvField
                          name="input_deposit_value"
                          value={formDataQuo.input_deposit_value}
                          placeholder="มัดจำ"
                          onChange={e =>
                            setFormDataQuo({
                              ...formDataQuo,
                              input_deposit_value: e.target.value,
                            })
                          }
                          validate={{
                            required: { value: true },
                          }}
                          type="number"
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="12" md="3">
                      <FormGroup>
                        <Label for="input_light">พื้นที่บ้านรวม (ตร.ม.)</Label>
                        <AvField
                          name="input_light"
                          value={formDataQuo.input_light}
                          placeholder="พื้นที่บ้าน"
                          onChange={e =>
                            setFormDataQuo({
                              ...formDataQuo,
                              input_light: e.target.value,
                              input_light_value:
                                e.target.value > 32
                                  ? Math.round(e.target.value / 5)
                                  : Math.round(e.target.value / 4),
                              input_plug_value: Math.round(e.target.value / 7),
                            })
                          }
                          validate={{
                            required: { value: true },
                          }}
                          type="number"
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="12" md="3">
                      <FormGroup>
                        <Label for="input_light_value">
                          จำนวนหลอดไฟ (หลอด)
                        </Label>

                        <p className="form-control" style={{ border: 0 }}>
                          {formDataQuo.input_light_value}
                        </p>
                        {/* <AvField
                          name="input_light_value"
                          value={formDataQuo.input_light_value}
                          placeholder="จำนวนหลอดไฟ"
                          onChange={e =>
                            setFormDataQuo({
                              ...formDataQuo,
                              input_light_value: e.target.value,
                            })
                          }
                          validate={{
                            required: { value: true },
                          }}
                          type="number"
                        /> */}
                      </FormGroup>
                    </Col>

                    <Col sm="12" md="3">
                      <FormGroup>
                        <Label for="input_plug_value">จำนวนปลั๊ก (จุด)</Label>
                        <p className="form-control" style={{ border: 0 }}>
                          {formDataQuo.input_plug_value}
                        </p>
                        {/* <AvField
                          name="input_plug_value"
                          value={formDataQuo.input_plug_value}
                          placeholder="จำนวนปลั๊ก"
                          onChange={e =>
                            setFormDataQuo({
                              ...formDataQuo,
                              input_plug_value: e.target.value,
                            })
                          }
                          validate={{
                            required: { value: true },
                          }}
                          type="number"
                        /> */}
                      </FormGroup>
                    </Col>
                    <Row className="mt-2">
                      <Col xs={0} sm={0} md={9} lg={9}></Col>

                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Button
                          style={{ width: "100%" }}
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={() => addRow_note("new")}
                        >
                          เพิ่มการคำนวณไปยังรายละเอียด
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm={12}>
                      <h5>รายละเอียด</h5>
                    </Col>
                  </Row>
                  {quotation_notes.map((item, index) =>
                    item.del == 0 ? null : (
                      <Row key={index} className="mt-2">
                        <Col xs={10} sm={10} md={11} lg={11}>
                          <AvField
                            name={"name_note" + [index]}
                            type="text"
                            placeholder="รายละเอียด"
                            onChange={e => {
                              let select = [...quotation_notes]
                              select[index].name = e.target.value
                              setQuotation_notes(select)
                            }}
                            value={quotation_notes[index].name}
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>

                        <Col xs={2} sm={2} md={1} lg={1}>
                          <Button
                            name={"row_rm" + [index]}
                            color="danger"
                            onClick={() => handleRemoveItem_note(item)}
                          >
                            <i className="mdi mdi-block-helper" />
                          </Button>
                        </Col>
                      </Row>
                    )
                  )}
                  <Row className="mt-2">
                    <Col xs={10} sm={10} md={11} lg={11}></Col>

                    <Col xs={2} sm={2} md={1} lg={1}>
                      <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={() => addRow_note("basic")}
                      >
                        <i className="mdi mdi-plus-circle-outline" />
                      </Button>
                    </Col>
                  </Row>

                  <hr />

                  <Row>
                    <Col lg="12">
                      <h5>เงื่อนไขการชำระเงิน</h5>

                      <Select
                        type="select"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        name="quoBank_id"
                        value={groups.filter(function (option) {
                          return option.value == formDataQuo.bank_id
                        })}
                        isSearchable={false}
                        onChange={e =>
                          e.value == "-1"
                            ? setActionModalBank({
                                action: "Add",
                                id: null,
                                modal: true,
                              })
                            : setFormDataQuo({
                                ...formDataQuo,
                                bank_id: e.value,
                                book_bank: e.no,
                                name_bank_ower: e.ower_name,
                                name_bank: e.name,
                              })
                        }
                        options={groups}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm="12" lg="4">
                      <h5>เลขบัญชี: {formDataQuo.book_bank}</h5>

                      {/* <AvField
                        value={formDataQuo.book_bank }
                        placeholder="เลขบัญชี"
                        onChange={e =>
                          setFormDataQuo({
                            ...formDataQuo,
                            book_bank: e.target.value,
                          })
                        }
                        validate={{
                          required: { value: true },
                        }}
                        name="book_bank"
                        type="text"
                      /> */}
                    </Col>

                    <Col sm="12" lg="4">
                      <h5>ชื่อบัญชี: {formDataQuo.name_bank_ower}</h5>

                      {/* <AvField
                        value={formDataQuo.name_bank_ower }
                        placeholder="ชื่อแบงค์"
                        onChange={e =>
                          setFormDataQuo({
                            ...formDataQuo,
                            name_bank_ower: e.target.value,
                          })
                        }
                        validate={{
                          required: { value: true },
                        }}
                        name="name_bank_ower"
                        type="text"
                      /> */}
                    </Col>

                    <Col sm="12" lg="4">
                      <h5>ชื่อธนาคาร: {formDataQuo.name_bank}</h5>

                      {/* <AvField
                        value={formDataQuo.name_bank }
                        placeholder="ชื่อธนาคาร"
                        onChange={e =>
                          setFormDataQuo({
                            ...formDataQuo,
                            name_bank: e.target.value,
                          })
                        }
                        validate={{
                          required: { value: true },
                        }}
                        name="name_bank"
                        type="text"
                      /> */}
                    </Col>
                  </Row>
                  <Row>
                    {quotation_cons.map((item, index) => (
                      <Col xs="12" className="mb-1" key={index}>
                        <InputGroup>
                          <InputGroupText style={{ width: "70px" }}>
                            งวดที่ {Number(index) + 1} :
                          </InputGroupText>
                          <Input
                            type="text"
                            placeholder="ชื่องวด"
                            style={{ width: "100px" }}
                            value={item.name}
                            onChange={e => {
                              let data = [...quotation_cons]
                              quotation_cons[index].name = e.target.value
                              setQuotation_cons(data)
                            }}
                          />
                          <Input
                            type="number"
                            placeholder="%"
                            style={{ width: "100px" }}
                            value={item.value}
                            onChange={e => {
                              let data = [...quotation_cons]
                              quotation_cons[index].value = e.target.value
                              quotation_cons[index].sum_all =
                                (parseInt(formDataQuo.price_sum) / 100) *
                                parseInt(e.target.value)

                              setQuotation_cons(data)
                            }}
                          />
                          {formDataQuo.vat_type == 1 ? (
                            <InputGroupText style={{ width: "500px" }}>
                              รวมเป็น:&nbsp;
                              {quotation_cons.length - 1 == index
                                ? parseInt(
                                    (parseInt(formDataQuo.price_sum) / 100) *
                                      parseInt(quotation_cons[index].value) -
                                      formDataQuo.input_deposit_value
                                  ).toLocaleString("th-TH")
                                : parseInt(
                                    (parseInt(formDataQuo.price_sum) / 100) *
                                      parseInt(quotation_cons[index].value)
                                  ).toLocaleString("th-TH")}
                              {quotation_cons.length - 1 == index
                                ? " (รวมหักค่ามัดจำแล้ว)"
                                : ""}
                            </InputGroupText>
                          ) : (
                            <InputGroupText style={{ width: "500px" }}>
                              รวมเป็น:&nbsp;
                              {quotation_cons.length - 1 == index
                                ? parseFloat(
                                    (parseFloat(
                                      parseFloat(formDataQuo.price_sum) +
                                        (parseFloat(formDataQuo.price_sum) /
                                          100) *
                                          7
                                    ) /
                                      100) *
                                      parseFloat(quotation_cons[index].value) -
                                      formDataQuo.input_deposit_value
                                  ).toLocaleString("th-TH", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : parseFloat(
                                    (parseFloat(
                                      parseFloat(formDataQuo.price_sum) +
                                        (parseFloat(formDataQuo.price_sum) /
                                          100) *
                                          7
                                    ) /
                                      100) *
                                      parseFloat(quotation_cons[index].value)
                                  ).toLocaleString("th-TH", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                              {quotation_cons.length - 1 == index
                                ? " (รวมหักค่ามัดจำแล้ว)"
                                : ""}
                            </InputGroupText>
                          )}
                        </InputGroup>
                      </Col>
                    ))}
                  </Row>

                  <Row>
                    <Col xs="12" className="mb-1">
                      <InputGroup>
                        <InputGroupText
                          style={{ width: "100%", justifyContent: "right" }}
                        >
                          ส่วนลดรวม&emsp;
                          <Input
                            type="number"
                            placeholder="ส่วนลดรวม (บ.)"
                            style={{ width: "250px" }}
                            value={formDataQuo.discount}
                            onChange={e => {
                              setFormDataQuo({
                                ...formDataQuo,
                                discount: e.target.value,
                                price_sum: money_all - e.target.value,
                              })
                            }}
                          />
                          &emsp; รวมเป็น:{" "}
                          {formDataQuo.vat_type == 1
                            ? parseInt(formDataQuo.price_sum).toLocaleString(
                                "th-TH"
                              )
                            : parseFloat(
                                parseFloat(
                                  parseFloat(formDataQuo.price_sum) +
                                    (parseFloat(formDataQuo.price_sum) / 100) *
                                      7
                                )
                              ).toLocaleString("th-TH")}
                        </InputGroupText>
                      </InputGroup>
                    </Col>
                  </Row>
                </div>

                <div className="modal-footer">
                  {isEmpty(formDataQuo.quo_date) == true &&
                  formDataQuo.bank_id == undefined ? (
                    <span style={{ color: "red", marginRight: "5" }}>
                      กรุณาใส่วันที่/เลือกธนาคาร
                    </span>
                  ) : actionQuo == "Add" ? (
                    <div className="d-flex">
                      {isEmpty(formDataQuo.quotation_name) ==
                      true ? null : checkPreview == true ? (
                        <span style={{ color: "red", marginRight: "5" }}>
                          กรุณาบันทึกก่อน
                        </span>
                      ) : (
                        <Button
                          onClick={e => {
                            handleSubmit_quo(null, 0, null, "print")
                          }}
                          color="primary"
                        >
                          Preview
                        </Button>
                      )}
                      {isEmpty(formDataQuo.quotation_name) == true ? (
                        <span style={{ color: "red", marginRight: "5" }}>
                          กรุณาใส่ชื่อใบเสนอราคา
                        </span>
                      ) : (
                        <Button type="submit" color="secondary">
                          Save
                        </Button>
                      )}
                    </div>
                  ) : isEmpty(quotation_cons[quotation_cons.length - 1]) ==
                    true ? null : parseInt(
                      quotation_cons[quotation_cons.length - 1].sum_all
                    ) -
                      parseInt(formDataQuo.input_deposit_value) <
                    0 ? (
                    <span style={{ color: "red", marginRight: "5" }}>
                      กรุณาเปลื่ยน % ใหม่เพื่อไม่ให้งวดสุดท้ายติดลบ
                    </span>
                  ) : (
                    <div className="d-flex">
                      <Button
                        onClick={e => {
                          setModal_quoVat(!modal_quoVat)
                        }}
                        color="warning"
                      >
                        Vat
                      </Button>
                      &nbsp;
                      {isEmpty(formDataQuo.quotation_name) ==
                      true ? null : checkPreview == true ? (
                        <span style={{ color: "red", marginRight: "5" }}>
                          กรุณาบันทึกก่อน
                        </span>
                      ) : (
                        <Button
                          onClick={e => {
                            handleSubmit_quo(null, 0, null, "print")
                          }}
                          color="primary"
                        >
                          Preview
                        </Button>
                      )}
                      &nbsp;
                      {isEmpty(formDataQuo.quotation_name) == true ? (
                        <span style={{ color: "red", marginRight: "5" }}>
                          กรุณาใส่ชื่อใบเสนอราคา
                        </span>
                      ) : (
                        <Button type="submit" color="secondary">
                          Save
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </AvForm>
            </Modal>

            <Modal
              size="lg"
              style={{ maxWidth: "90%" }}
              isOpen={modal_xlarge_slip}
              toggle={() => {
                setModal_xlarge_slip(!modal_xlarge_slip)
              }}
              centered={true}
            >
              <AvForm onSubmit={handleSubmit_slip} model={formDataSlip}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                    แก้ไขใบเสร็จรับเงิน
                  </h5>
                  <button
                    onClick={() => {
                      setModal_xlarge_slip(false)
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col lg="12">
                      <div className="text-center">
                        {Img_slip == undefined ? (
                          formDataSlip.img_path != undefined ? (
                            <img
                              src={
                                process.env.REACT_APP_SERVER +
                                formDataSlip.img_path
                              }
                              alt={process.env.REACT_APP_NAMECOMPANY}
                              height="100%"
                              width="350px"
                            />
                          ) : null
                        ) : (
                          <img
                            src={Img_slip}
                            alt={process.env.REACT_APP_NAMECOMPANY}
                            height="350px"
                            width="350px"
                          />
                        )}
                      </div>

                      <Label htmlFor="formFileSm_Slip" className="form-label">
                        File Slip
                      </Label>
                      <input
                        className="form-control form-control-sm"
                        id="formFileSm_Slip"
                        type="file"
                        accept="image/*"
                        multiple={false}
                        onChange={handleAcceptedFiles_Slip}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg="3">
                      <AvField
                        name={"no"}
                        type="text"
                        placeholder="เลขอ้างอิง"
                        value={formDataSlip.no}
                        onChange={e => {
                          setFormDataSlip({
                            ...formDataSlip,
                            no: e.target.value,
                          })
                        }}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </Col>
                    <Col lg="3">
                      <AvField
                        value={formDataSlip.date_add}
                        placeholder="วันที่โอน"
                        onChange={e =>
                          setFormDataSlip({
                            ...formDataSlip,
                            date_add: e.target.value,
                          })
                        }
                        validate={{
                          required: { value: true },
                        }}
                        name="date_add"
                        type="date"
                      />
                    </Col>

                    <Col lg="6">
                      <FormGroup className="mt-2">
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="radioVatType"
                              checked={formDataSlip.vat_type == 1}
                              value={1}
                              onChange={e =>
                                setFormDataSlip({
                                  ...formDataSlip,
                                  vat_type: e.target.value,
                                })
                              }
                            />
                            ไม่เอา Vat
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label>
                            <Input
                              type="radio"
                              name="radioVatType"
                              checked={formDataSlip.vat_type == 2}
                              value={2}
                              onChange={e =>
                                setFormDataSlip({
                                  ...formDataSlip,
                                  vat_type: e.target.value,
                                })
                              }
                            />
                            เอา Vat
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>

                    <Col sm={10}>
                      <FormGroup className="mt-2">
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="radioInstallmentsFormData"
                              checked={formDataSlip.installments == 1}
                              value={1}
                              onChange={e =>
                                setFormDataSlip({
                                  ...formDataSlip,
                                  installments: e.target.value,
                                })
                              }
                            />
                            มัดจำ
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="radioInstallmentsFormData"
                              checked={formDataSlip.installments == 2}
                              value={2}
                              onChange={e =>
                                setFormDataSlip({
                                  ...formDataSlip,
                                  installments: e.target.value,
                                })
                              }
                            />
                            งวดที่ 1
                          </Label>
                        </FormGroup>

                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="radioInstallmentsFormData"
                              checked={formDataSlip.installments == 3}
                              value={3}
                              onChange={e =>
                                setFormDataSlip({
                                  ...formDataSlip,
                                  installments: e.target.value,
                                })
                              }
                            />
                            งวดที่ 2
                          </Label>
                        </FormGroup>

                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="radioInstallmentsFormData"
                              checked={formDataSlip.installments == 4}
                              value={4}
                              onChange={e =>
                                setFormDataSlip({
                                  ...formDataSlip,
                                  installments: e.target.value,
                                })
                              }
                            />
                            งวดที่ 3
                          </Label>
                        </FormGroup>

                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="radioInstallmentsFormData"
                              checked={formDataSlip.installments == 5}
                              onChange={e =>
                                setFormDataSlip({
                                  ...formDataSlip,
                                  installments: e.target.value,
                                })
                              }
                              value={5}
                            />
                            งวดที่ 4
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="radioInstallmentsFormData"
                              checked={formDataSlip.installments == 6}
                              onChange={e =>
                                setFormDataSlip({
                                  ...formDataSlip,
                                  installments: e.target.value,
                                })
                              }
                              value={6}
                            />
                            ทั้งหมด
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="radioInstallmentsFormData"
                              checked={formDataSlip.installments == 7}
                              onChange={e =>
                                setFormDataSlip({
                                  ...formDataSlip,
                                  installments: e.target.value,
                                })
                              }
                              value={7}
                            />
                            อื่น ๆ
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>

                    <Col sm={2} style={{ textAlign: "right" }}>
                      <FormGroup className="mt-2">
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="radioDel"
                              value={1}
                              onChange={e =>
                                setFormDataSlip({
                                  ...formDataSlip,
                                  del: 1,
                                })
                              }
                            />
                            <span style={{ color: "red" }}>ลบ</span>
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>

                    <Col lg="12" className="mt-3">
                      <h5>รายการ </h5>

                      <Table size="lg" responsive>
                        <thead>
                          <tr>
                            <th>รายละเอียดขื่อ</th>
                            <th style={{ textAlign: "right", width: "130px" }}>
                              ส่วนลด
                            </th>
                            <th style={{ textAlign: "right", width: "130px" }}>
                              จำนวน (บาท)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <AvField
                                name={"formDataSlip_comment"}
                                type="text"
                                placeholder="รายละเอียดชื่อ"
                                value={formDataSlip.comment}
                                onChange={e => {
                                  setFormDataSlip({
                                    ...formDataSlip,
                                    comment: e.target.value,
                                  })
                                }}
                                validate={{
                                  required: { value: false },
                                }}
                              />
                            </td>
                            <td>
                              <AvField
                                name={"formDataSlip_discount"}
                                type="text"
                                placeholder="ส่วนลด"
                                value={formDataSlip.discount}
                                onChange={e => {
                                  setFormDataSlip({
                                    ...formDataSlip,
                                    discount: e.target.value,
                                  })
                                }}
                                validate={{
                                  required: { value: false },
                                }}
                              />
                            </td>

                            <td>
                              {/* <AvField
                                name={"formDataSlip_money"}
                                type="text"
                                placeholder="ราคา"
                                value={formDataSlip.money }
                                onChange={e => {
                                  setFormDataSlip({
                                    ...formDataSlip,
                                    money: e.target.value,
                                  })
                                }}
                                validate={{
                                  required: { value: false },
                                }}
                              /> */}

                              <NumberFormat
                                className="av-valid form-control"
                                name={"formDataSlip_money"}
                                value={formDataSlip.money}
                                onValueChange={e => {
                                  setFormDataSlip({
                                    ...formDataSlip,
                                    money: e.value,
                                  })
                                }}
                                thousandsGroupStyle="thousand"
                                prefix=""
                                decimalSeparator="."
                                displayType="input"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={true}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>

                <div className="modal-footer">
                  {isEmpty(formDataSlip.no) == true &&
                  isEmpty(formDataSlip.date_add) == true ? (
                    <p style={{ color: "red" }}>กรุณาใส่เลขอ้างอิง/วันที่</p>
                  ) : (
                    <>
                      <Button
                        type="button"
                        color="primary"
                        onClick={e => {
                          handleSubmit_slip(null, 0, null, "print")
                        }}
                      >
                        Preview
                      </Button>

                      <Button type="submit" color="secondary">
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </AvForm>
            </Modal>

            <Modal
              size="lg"
              isOpen={modal_idCard}
              toggle={() => {
                tog_modalIDCARD()
              }}
              backdrop={"static"}
              centered
            >
              <AvForm onSubmit={handleSubmit} model={formData}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    แก้ไขบัตรประชาชน/ที่อยู่ ลค.
                  </h5>
                  {/* <button
                    type="button"
                    onClick={() => {
                      setModal_idCard(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                </div>
                <div className="modal-body">
                  {!isEmpty(idCard) ? (
                    <Row>
                      <Col sm="12">
                        <img
                          src={process.env.REACT_APP_SERVER + idCard.img_path}
                          alt={process.env.REACT_APP_NAMECOMPANY}
                          style={{ objectFit: "contain" }}
                          height="257px"
                          width="100%"
                        />
                      </Col>
                      <Col sm="6" md="6">
                        <FormGroup>
                          <Label for="contact">ชื่อ/นามสกุล ลูกค้า</Label>
                          <AvField
                            name="contact"
                            type="text"
                            placeholder="ชื่อนามสกุลลูกค้า"
                            //   value={formData.contact}
                            value={formData.contact}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                contact: e.target.value,
                              })
                            }
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="6" md="6">
                        <FormGroup>
                          <Label for="id_card">เลขบัตรประชาชน</Label>
                          <AvField
                            name="id_card"
                            type="text"
                            placeholder="เลขบัตรประชาชน"
                            value={formData.id_card}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                id_card: e.target.value,
                              })
                            }
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="12" md="12">
                        <FormGroup>
                          <Label for="address">ที่อยู่</Label>
                          <AvField
                            name="address"
                            type="textarea"
                            placeholder="ที่อยู่"
                            //   value={formData.address}
                            value={formData.address}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                address: e.target.value,
                              })
                            }
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary ">
                    Save changes
                  </button>
                </div>
              </AvForm>
            </Modal>
          </div>
          <ExampleModal actionModal={actionModal} callBack={setReqCallBack} />
          <ExampleModalBank actionModal={actionModalBank} />
          <ExampleModalQuotation_condition_lists
            actionModal={actionModalQuotation_condition_list}
          />

          <ExampleModalContact_condition_lists
            actionModal={actionModalContact_condition_list}
          />
          <ExampleModalEmployment_contract_lists
            actionModal={actionModalEmployment_contract_list}
          />

          <ExampleModalProfitLoss actionModal={actionModalProfitLoss} />

          <Card>
            <CardBody>
              <Row>
                <Col sm="6">
                  <h5>ข้อมูลลูกค้า</h5>
                </Col>

                <Col sm="6">
                  <div className="clearfix mt-4 mt-lg-0">
                    <Dropdown
                      isOpen={settingProjectMenus}
                      toggle={() =>
                        setSettingProjectMenus(!settingProjectMenus)
                      }
                      style={{ cursor: "pointer" }}
                      className="float-end"
                    >
                      <DropdownToggle tag="div">
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem onClick={() => getProfitLoss()}>
                          สรุปกำไร/ขาดทุน
                        </DropdownItem>

                        <DropdownItem onClick={() => fixFormSubmit(11)}>
                          นำโปรเจคกลับมา
                        </DropdownItem>
                        <DropdownItem onClick={() => fixFormSubmit(10)}>
                          โปรเจคเสร็จแล้ว
                        </DropdownItem>
                        <DropdownItem onClick={() => fixFormSubmit(-1)}>
                          ยกเลิกโปรจค
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>

                  {/* <div
                    className="float-end ms-2"
                    style={{ marginRight: "30px" }}
                  >
                    <UncontrolledDropdown direction="left">
                      <DropdownToggle
                        color="white"
                        className="btn btn-link text-muted"
                      >
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle>

                      <DropdownMenu>
                        <DropdownItem onClick={() => fixFormSubmit(11)}>
                          นำโปรเจคกลับมา
                        </DropdownItem>
                        <DropdownItem onClick={() => fixFormSubmit(10)}>
                          โปรเจคเสร็จแล้ว
                        </DropdownItem>
                        <DropdownItem onClick={() => fixFormSubmit(-1)}>
                          ยกเลิกโปรจค
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div> */}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm="12" md="8">
                  <AvForm onSubmit={handleSubmit} model={formData}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="Name">ชื่อโปรเจค </Label>
                          <AvField
                            name="name"
                            type="text"
                            placeholder="ชื่อโปรเจค"
                            value={formData.name}
                            onChange={e =>
                              setFormData({ ...formData, name: e.target.value })
                            }
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="tel">
                            {formData.tel == null ? (
                              "tel"
                            ) : (
                              <a
                                href={formData.tel}
                                target="_blank"
                                rel="noreferrer"
                              >
                                เบอร์โทรลูกค้า
                              </a>
                            )}
                          </Label>

                          <AvField
                            name="tel"
                            type="text"
                            placeholder="เบอร์โทรลูกค้า"
                            //   value={formData.tel}
                            value={formData.tel}
                            onChange={e =>
                              setFormData({ ...formData, tel: e.target.value })
                            }
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label for="contact">ชื่อ/นามสกุล ลูกค้า</Label>
                          <AvField
                            name="contact"
                            type="text"
                            placeholder="ชื่อนามสกุลลูกค้า"
                            //   value={formData.contact}
                            value={formData.contact}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                contact: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup>
                          <Label for="id_card">เลขบัตรประชาชน</Label>
                          <AvField
                            name="id_card"
                            type="text"
                            placeholder="เลขบัตรประชาชน"
                            value={formData.id_card}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                id_card: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup>
                          <Label for="size">ขนาด</Label>
                          <AvField
                            name="size"
                            type="text"
                            placeholder="ขนาดบ้าน"
                            value={formData.size}
                            onChange={e =>
                              setFormData({ ...formData, size: e.target.value })
                            }
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="highlight">
                            สร้างโรงงาน/หน้างาน/อื่น ๆ
                          </Label>
                          <AvField
                            name="highlight"
                            type="text"
                            placeholder="ความต้องการของลูกค้า"
                            //   value={formData.highlight}
                            value={formData.highlight}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                highlight: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8} md={8} lg={10}>
                        <FormGroup>
                          <Label for="location">
                            {formData.location == null ? (
                              "Location"
                            ) : (
                              <a
                                href={formData.location}
                                target="_blank"
                                rel="noreferrer"
                              >
                                ดู Location
                              </a>
                            )}
                          </Label>
                          <AvField
                            name="location"
                            type="text"
                            placeholder="สถานที่"
                            //   value={formData.location}
                            value={formData.location}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                location: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col sm={4} md={4} lg={2}>
                        <FormGroup>
                          <Label for="dateBuild">เวลาสร้าง</Label>
                          <AvField
                            name="dateBuild"
                            type="text"
                            placeholder="เวลาก่อนสร้าง (วัน)"
                            //   value={formData.dateBuild}
                            value={formData.dateBuild}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                dateBuild: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="address">ที่อยู่</Label>
                          <AvField
                            name="address"
                            type="textarea"
                            placeholder="ที่อยู่"
                            //   value={formData.address}
                            value={formData.address}
                            onChange={e =>
                              setFormData({
                                ...formData,
                                address: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      {formData.insert_status == 3 ? (
                        permission == 1 ? (
                          <>
                            <Col sm="6">
                              <Button
                                style={{ width: "100%" }}
                                color="primary"
                                type="button"
                                name="buttonSubmit_confirm"
                                className="font-16 btn-block btn btn-primary mt-1"
                                onClick={() => fixFormSubmit(4)}
                                disabled={buttonSubmit}
                              >
                                Confirm
                              </Button>
                            </Col>

                            <Col sm="6">
                              <Button
                                name="buttonSubmit_cancel"
                                style={{ width: "100%" }}
                                color="danger"
                                className="font-16 btn-block btn btn-danger mt-1"
                                type="button"
                                onClick={() => fixFormSubmit(6)}
                                disabled={buttonSubmit}
                              >
                                Cancel
                              </Button>
                            </Col>
                          </>
                        ) : null
                      ) : formData.insert_status == 6 ? null : (
                        <Col sm="12" className="d-flex">
                          {/* <Button
                            style={{ width: "100%" }}
                            color="success"
                            className="font-16 btn-block btn btn-success mt-1"
                            type="button"
                            onClick={() => fixFormSubmit(10)}
                            disabled={buttonSubmit}
                          >
                            Project success/end
                          </Button>
                          &nbsp;
                          <Button
                            style={{ width: "100%" }}
                            color="danger"
                            className="font-16 btn-block btn btn-danger mt-1"
                            type="button"
                            onClick={() => fixFormSubmit(-1)}
                            disabled={buttonSubmit}
                          >
                            Project cancel/del
                          </Button>
                          &nbsp; */}
                          <Button
                            style={{ width: "100%" }}
                            color="primary"
                            className="font-16 btn-block btn btn-primary mt-1"
                            type="button"
                            onClick={() =>
                              fixFormSubmit(
                                formData.insert_status == 1
                                  ? 2
                                  : formData.insert_status
                              )
                            }
                            disabled={buttonSubmit}
                          >
                            Save
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </AvForm>
                </Col>
                <Col sm="12" md="4" style={{ alignSelf: "center" }}>
                  {loadingUploadHome == true ? (
                    <div className="mt-3" style={{ textAlign: "center" }}>
                      <Spinner className="ms-2" color="primary" />
                      <p>กำลังโหลดรูปภาพ</p>
                    </div>
                  ) : (
                    <div className="mt-3">
                      <div className="text-center">
                        {Img == undefined ? (
                          formData.img != undefined ? (
                            <a
                              href={
                                process.env.REACT_APP_SERVER + formData.img_path
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={
                                  process.env.REACT_APP_SERVER +
                                  formData.img_path
                                }
                                alt={process.env.REACT_APP_NAMECOMPANY}
                                style={{ objectFit: "contain" }}
                                height="257px"
                                width="100%"
                              />
                            </a>
                          ) : null
                        ) : (
                          <a href={Img} target="_blank" rel="noreferrer">
                            <img
                              style={{ objectFit: "contain" }}
                              src={Img}
                              alt={process.env.REACT_APP_NAMECOMPANY}
                              height="257px"
                              width="100%"
                            />
                          </a>
                        )}
                      </div>

                      <Label htmlFor="formFileSm" className="form-label">
                        รูปภาพโปรเจค
                      </Label>
                      <input
                        className="form-control form-control-sm"
                        id="formFileSm"
                        type="file"
                        accept="image/*"
                        onChange={handleAcceptedFiles_home}
                      />
                      <hr />
                      <Select
                        name="user_id"
                        value={dataUsers.filter(
                          option => option.value == formData.user_id
                        )}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            user_id: e.value,
                          })
                        }
                        placeholder="เลือกผู้รับผิดชอบ"
                        options={dataUsers}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
          {formData.insert_status >= 4 ? (
            <>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="12">
                      <Row>
                        <Col lg="6">
                          {isEmpty(formData.contact) == true ||
                          isEmpty(formData.tel) == true ||
                          isEmpty(formData.address) == true ? (
                            <span style={{ color: "red" }}>
                              กรุณาใส่ ชื่อลูกค้า/เบอร์/ที่อยู่
                            </span>
                          ) : (
                            <span
                              style={{
                                color: "#007bff",
                                cursor: "pointer",
                              }}
                              onClick={() => AddQuo("Add")}
                            >
                              ใบเสนอราคา (สร้าง/เพิ่ม)
                            </span>
                          )}
                        </Col>

                        <Col lg="6">
                          <div className="clearfix mt-4 mt-lg-0">
                            <Dropdown
                              isOpen={settingCondition}
                              toggle={() =>
                                setSettingCondition(!settingCondition)
                              }
                              style={{ cursor: "pointer" }}
                              className="float-end"
                            >
                              <DropdownToggle tag="div">
                                <i className="mdi mdi-dots-horizontal"></i>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                  onClick={() => getQuotation_condition_lists()}
                                >
                                  รายละเอียดใบเสนอราคา
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => getContact_condition_lists()}
                                >
                                  รายละเอียดสัญญา
                                </DropdownItem>

                                <DropdownItem
                                  onClick={() => getEmployment_contract_lists()}
                                >
                                  รายละเอียดสัญญา (ช่าง)
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col lg="12">
                      {dataQuotationproject.map((f, i) => (
                        <span
                          onClick={() => {
                            AddQuo("Edit", f.id)
                            setFormData_quoVat({
                              name: f.name,
                              tel: f.tel,
                              quo_date: f.quo_date,
                              id_card: f.id_card,
                              address: f.address,
                              book_no:
                                f.code + f.year + f.month + f.day + f.term,
                              detail: "บ้านสำเร็จรูป",
                              price: f.price_sum,
                              vat: 7,
                            })
                          }}
                          key={i}
                          style={{
                            cursor: "pointer",
                            color: f.quo_type == 1 ? "#bb2124" : "#aaaaaa",
                          }}
                        >
                          {f.quotation_name} (
                          {f.code + f.year + f.month + f.day + f.term}
                          )&nbsp;:&nbsp;
                          {moment(f.quo_date)
                            .add(543, "year")
                            .format("DD/MM/YYYY")}
                          &emsp;
                        </span>
                      ))}

                      {/* <span>Files,</span>
                  <span>Files,</span>
                  <span>Files,</span>
                  <span>Files</span> */}
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <h5>รวมอัพรูป / อ้างอิง / สำรวจ / สลิปเงิน / บัตรประชาชน </h5>
                  <hr />
                  <Row>
                    <Col lg="8">
                      <Row>
                        <Col sm={12} md={12} lg={4}>
                          <Select
                            name="selectUpload"
                            // value={selectUploadID }
                            value={optionSelectUpload.filter(
                              option => option.value == selectUploadID
                            )}
                            onChange={e => {
                              setSelectUploadID(e.value)
                              setComment("")
                              setMoney("")
                            }}
                            isSearchable={false}
                            placeholder="ประเภทการอัพ"
                            options={optionSelectUpload}
                            classNamePrefix="select2-selection"
                          />
                        </Col>

                        <Col sm={12} md={6} lg={4}>
                          <input
                            className="form-control"
                            style={{ padding: "8px" }}
                            placeholder="กรุณาใส่หัวข้อ"
                            id="comment"
                            name="comment"
                            type="text"
                            value={comment}
                            onChange={e => {
                              setComment(e.target.value)
                            }}
                          />
                        </Col>
                        <Col
                          sm={12}
                          md={6}
                          lg={4}
                          hidden={selectUploadID == 3 ? false : true}
                        >
                          <input
                            className="form-control"
                            style={{ padding: "8px" }}
                            placeholder="ระบุจำนวนเงิน"
                            id="money"
                            name="money"
                            type="number"
                            value={money}
                            onChange={e => {
                              setMoney(e.target.value)
                            }}
                          />
                        </Col>

                        <Col
                          sm={12}
                          hidden={selectUploadID == 3 ? false : true}
                        >
                          <FormGroup className="mt-2">
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radioInstallments"
                                  checked={installments == 1}
                                  value={1}
                                  onChange={e =>
                                    setInstallments(e.target.value)
                                  }
                                />
                                มัดจำ
                              </Label>
                            </FormGroup>

                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radioInstallments"
                                  checked={installments == 2}
                                  value={2}
                                  onChange={e =>
                                    setInstallments(e.target.value)
                                  }
                                />
                                งวดที่ 1
                              </Label>
                            </FormGroup>

                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radioInstallments"
                                  checked={installments == 3}
                                  value={3}
                                  onChange={e =>
                                    setInstallments(e.target.value)
                                  }
                                />
                                งวดที่ 2
                              </Label>
                            </FormGroup>

                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radioInstallments"
                                  checked={installments == 4}
                                  value={4}
                                  onChange={e =>
                                    setInstallments(e.target.value)
                                  }
                                />
                                งวดที่ 3
                              </Label>
                            </FormGroup>

                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radioInstallments"
                                  checked={installments == 5}
                                  value={5}
                                  onChange={e =>
                                    setInstallments(e.target.value)
                                  }
                                />
                                งวดที่ 4
                              </Label>
                            </FormGroup>

                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radioInstallments"
                                  checked={installments == 6}
                                  value={6}
                                  onChange={e =>
                                    setInstallments(e.target.value)
                                  }
                                />
                                ทั้งหมด
                              </Label>
                            </FormGroup>

                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="radioInstallments"
                                  checked={installments == 7}
                                  value={7}
                                  onChange={e =>
                                    setInstallments(e.target.value)
                                  }
                                />
                                อื่น ๆ
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </Col>

                        <Col
                          sm={12}
                          md={12}
                          lg={12}
                          hidden={selectUploadID == 3 ? false : true}
                        >
                          <Select
                            type="select"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="bank_id"
                            value={groups.filter(function (option) {
                              return option.value == bank_id
                            })}
                            onChange={e =>
                              e.value == "-1"
                                ? setActionModalBank({
                                    action: "Add",
                                    id: null,
                                    modal: true,
                                  })
                                : setBank_id(e.value)
                            }
                            options={groups}
                          />
                        </Col>
                      </Row>
                      {selectUploadID != 3 ? null : comment.length > 0 &&
                        money.length > 0 ? null : (
                        <p style={{ color: "red" }}>
                          ใส่ข้อให้ครบเพื่อแสดงตัวอัพโหลด
                        </p>
                      )}
                      <br />
                      {(selectUploadID == 1 && comment.length > 0) ||
                      (selectUploadID == 2 && comment.length > 0) ||
                      (selectUploadID == 4 && comment.length > 0) ||
                      (selectUploadID == 3 &&
                        comment.length > 0 &&
                        money.length > 0) ? (
                        <div>
                          <Dropzone
                            multiple={
                              selectUploadID == 1 || selectUploadID == 3
                                ? false
                                : true
                            }
                            accept="image/*"
                            inputContent={(files, extra) =>
                              extra.reject ? "Image files only" : "Drag Files"
                            }
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                            styles={{
                              dropzoneReject: {
                                borderColor: "red",
                                backgroundColor: "#DAA",
                              },
                              inputLabel: (files, extra) =>
                                extra.reject ? { color: "red" } : {},
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>
                                      เลือกไฟล์หรือลากไฟล์มาตรงนี้เพื่ออัพโหลดรูป
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>

                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </div>
                      ) : comment.length > 0 && money.length > 0 ? (
                        <div>
                          <Dropzone
                            multiple={
                              selectUploadID == 1 || selectUploadID == 3
                                ? false
                                : true
                            }
                            accept="image/*"
                            inputContent={(files, extra) =>
                              extra.reject ? "Image files only" : "Drag Files"
                            }
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                            styles={{
                              dropzoneReject: {
                                borderColor: "red",
                                backgroundColor: "#DAA",
                              },
                              inputLabel: (files, extra) =>
                                extra.reject ? { color: "red" } : {},
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>
                                      เลือกไฟล์หรือลากไฟล์มาตรงนี้เพื่ออัพโหลดรูป
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>

                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </div>
                      ) : null}
                    </Col>
                    <Col lg="4">
                      <p>สำรวจหน้างาน</p>

                      <div className="popup-gallery d-flex flex-wrap">
                        {fileSerway.map((f, i) => (
                          <div
                            className="img-fluid float-left p-1"
                            key={i}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              // src={fileSerway}
                              src={process.env.REACT_APP_SERVER + f.img_path}
                              onClick={() => {
                                setisGallery(true)
                                setphotoIndex(i)
                              }}
                              alt={process.env.REACT_APP_NAMECOMPANY}
                              className="avatar-md rounded-circle img-thumbnail"
                            />
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  {/* <hr /> */}
                  {/* <Row>
                <Col sm="12" lg="12">
                  <Label className="form-label">รูปภาพอ้างอิง</Label>
                </Col>

                <Col sm="12" lg="12">
                  {fileRefImg.map((f, i) => (
                    <a
                      key={i}
                      style={{
                        color:
                          i == Number(fileRefImg.length - 1)
                            ? "#bb2124"
                            : "#aaaaaa",
                      }}
                      href={process.env.REACT_APP_SERVER + f.img_path}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {f.comment}:&nbsp;
                      {moment(f.created_at).format("DD/MM/YYYY HH:mm")} &emsp;
                    </a>
                  ))}
                </Col>
              </Row> */}

                  <hr />

                  <Row>
                    <Col lg="6">
                      <Label className="form-label">สลิปเงิน</Label>
                    </Col>

                    <Col lg="6" style={{ textAlign: "right" }}>
                      <span className="form-label">
                        รวมยอด จ่าย/ต้องจ่าย:&nbsp;
                        <span style={{ color: "#42ba96" }}>
                          {parseInt(dataSlipSum).toLocaleString("th-TH")}&nbsp;
                        </span>
                        /
                        <span style={{ color: "#556ee6" }}>
                          &nbsp;
                          {parseInt(dataSlipSumAll).toLocaleString("th-TH")}
                        </span>
                      </span>
                    </Col>

                    <Col lg="12">
                      <div className="d-flex">
                        {fileSlip.map((f, i) => (
                          <div
                            key={i}
                            style={{
                              padding: "2px",
                            }}
                          >
                            <a
                              href={process.env.REACT_APP_SERVER + f.img_path}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                // src={fileSerway}
                                src={process.env.REACT_APP_SERVER + f.img_path}
                                alt={process.env.REACT_APP_NAMECOMPANY}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                }}
                              />
                            </a>
                            <p
                              onClick={() => {
                                actionGetSlip(f)
                              }}
                              style={{
                                cursor: "pointer",
                                color:
                                  i == Number(fileSlip.length - 1)
                                    ? "#bb2124"
                                    : "#aaaaaa",
                              }}
                            >
                              {/* ปริ้น&nbsp;{f.comment}:&nbsp; */}
                              ใบเสร็จรับเงิน:&nbsp;
                              {Number(f.money).toLocaleString("th-TH")}
                            </p>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          ) : null}
        </div>
      )}
    </React.Fragment>
  )
}

InfoProject.propTypes = {
  projectID: PropTypes.string,
}

export default InfoProject
