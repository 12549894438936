import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

//Import Components
import Accordian from "./accordian"

const FAQs = () => {
  const [activeTab, setactiveTab] = useState("1")

  return (
    <React.Fragment>
      <section className="section" id="faqs">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">FAQs</div>
                <h4>Frequently asked questions</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="vertical-nav">
                <Row>
                  <Col lg="2" sm="4">
                    <Nav pills className="flex-column">
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          setactiveTab("1")
                        }}
                      >
                        <i className="bx bx bx-message-square-minus nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">Construction</p>
                      </NavLink>

                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          setactiveTab("2")
                        }}
                      >
                        <i className="bx bx bx-wallet nav-icon d-block mb-2" />
                        <p className="font-weight-bold mb-0">Pay</p>
                      </NavLink>
                    </Nav>
                  </Col>
                  <Col lg="10" sm="8">
                    <Card>
                      <CardBody>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1" id="buy">
                            <h4 className="card-title mb-4">
                              Construction Questions
                            </h4>

                            {/* accoridan */}
                            <Accordian
                              question1="ต้องกดเข็มไหม ?"
                              answer1="ขึ้นอยู่กับพื้นที่ถ้าเปนดินถมใหม่ให้กดเข็มให้ลุึกกว่าดินเดิม"
                              question2="ต้องมีฐานรากไหม ?"
                              answer2="โดยปกติบ้านสำเร็จรูปไม่ได้มีน้ำหนักมากนักจึกแนะนำเป็นเทพื้นแล้วนำบ้านไปวางได้เลย"
                              question3="ถ้ารถเข้าไม่ได้ทำอย่างไร ?"
                              answer3="สามารถสร้างหน้างานได้โดยบวกค่าดำเนินการ 10% จากราคาบ้าน  และระยะทาง"
                              question4="บ้านสำเร็จรูปทนไหม ?"
                              answer4="บ้านสำเร็จรูปเหมือนบ้านทั่วไปหากมีการบำรุงรักษาสามารถอยู่ได้เท่ากับบ้านปกติ"
                              question5="ดูแลรักษาอย่างไร ?"
                              answer5="ควรหมั่นทาสีบ้าน ทุกๆ 5 ปี เปลี่ยนซีลิโคนเหมือนเริ่มมีการชำรุด"
                              question6="ระยะเวลาสร้างนานไหม ?"
                              answer6="ปกติแล้วจะใช้เวลา 30-60 วัน"
                            />
                          </TabPane>

                          <TabPane tabId="2">
                            <h4 className="card-title mb-4">Pay Questions</h4>

                            <Accordian
                              question1="ค่าฐานรากราคาเท่าไร ?"
                              answer1="ส่วนมากราคาฐานรากประมาณ 10-20% ของราคาบ้านขึ้นอยู่กับความต้องการของลูกค้า ขึ้นอยู่กับหน้างานและความต้องการของลูกค้า"
                              question2="ค่าขนส่งเท่าไร ?"
                              answer2="ค่าขนส่งราคาเริ่มต้นที่ 6000 บาทต่อคัน บวกตามระยะทาง หากต้องใช้รถเครนในการจะต้องเสียค่ารถเครนตามพื้นที่นั้นๆ"
                              question3="จ่ายเงินอย่างไร ?"
                              answer3="โดยปกติแล้วจะทำการมัดจำเพื่อ ทำแบบ 10,000 ก่อนเริ่มงาน 50%-40%-10% ตามลำดับ"
                              question4="บ้านสำเร็จรูปสามารถกู้ได้ไหม ?"
                              answer4="ได้ครับ โดยลูกค้าจะต้องเสียค่าแบบขออนุญาติก่อนสร้างก่อนและค่าดำเนินการก่อน"
                            />
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default FAQs
