import {
  GET_BOQ_TITLE_HOME_PROFILE,
  GET_BOQ_TITLE_HOME_PROFILE_FAIL,
  GET_BOQ_TITLE_HOME_PROFILE_SUCCESS,
  GET_BOQ_TITLE_HOMES,
  GET_BOQ_TITLE_HOMES_FAIL,
  GET_BOQ_TITLE_HOMES_SUCCESS,
  ADD_NEW_BOQ_TITLE_HOME,
  ADD_BOQ_TITLE_HOME_SUCCESS,
  ADD_BOQ_TITLE_HOME_FAIL,
  UPDATE_BOQ_TITLE_HOME,
  UPDATE_BOQ_TITLE_HOME_SUCCESS,
  UPDATE_BOQ_TITLE_HOME_FAIL,
  DELETE_BOQ_TITLE_HOME,
  DELETE_BOQ_TITLE_HOME_SUCCESS,
  DELETE_BOQ_TITLE_HOME_FAIL,
} from "./actionTypes"

export const getBoqTitleHomes = (project_id, type) => ({
  type: GET_BOQ_TITLE_HOMES,
  payload: { project_id, type },
})

export const getBoqTitleHomesSuccess = boqtitlehomes => ({
  type: GET_BOQ_TITLE_HOMES_SUCCESS,
  payload: boqtitlehomes,
})

export const addNewBoqTitleHome = boqtitlehome => ({
  type: ADD_NEW_BOQ_TITLE_HOME,
  payload: boqtitlehome,
})

export const addBoqTitleHomeSuccess = boqtitlehome => ({
  type: ADD_BOQ_TITLE_HOME_SUCCESS,
  payload: boqtitlehome,
})

export const addBoqTitleHomeFail = error => ({
  type: ADD_BOQ_TITLE_HOME_FAIL,
  payload: error,
})

export const getBoqTitleHomesFail = error => ({
  type: GET_BOQ_TITLE_HOMES_FAIL,
  payload: error,
})

export const getBoqTitleHomeProfile = id => ({
  type: GET_BOQ_TITLE_HOME_PROFILE,
  payload: id,
})

export const getBoqTitleHomeProfileSuccess = boqtitlehomeProfile => ({
  type: GET_BOQ_TITLE_HOME_PROFILE_SUCCESS,
  payload: boqtitlehomeProfile,
})

export const getBoqTitleHomeProfileFail = error => ({
  type: GET_BOQ_TITLE_HOME_PROFILE_FAIL,
  payload: error,
})


export const updateBoqTitleHome = boqtitlehome => ({
  type: UPDATE_BOQ_TITLE_HOME,
  payload: boqtitlehome,
})

// export const updateBoqTitleHome = boqtitlehome => console.log(boqtitlehome);

export const updateBoqTitleHomeSuccess = boqtitlehome => ({
  type: UPDATE_BOQ_TITLE_HOME_SUCCESS,
  payload: boqtitlehome,
})

export const updateBoqTitleHomeFail = error => ({
  type: UPDATE_BOQ_TITLE_HOME_FAIL,
  payload: error,
})

export const deleteBoqTitleHome = boqtitlehome => ({
  type: DELETE_BOQ_TITLE_HOME,
  payload: boqtitlehome,
})

export const deleteBoqTitleHomeSuccess = boqtitlehome => ({
  type: DELETE_BOQ_TITLE_HOME_SUCCESS,
  payload: boqtitlehome,
})

export const deleteBoqTitleHomeFail = error => ({
  type: DELETE_BOQ_TITLE_HOME_FAIL,
  payload: error,
})
