import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_PRODUCT_GROUPS,
  GET_PRODUCT_GROUP_PROFILE,
  ADD_NEW_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  UPDATE_PRODUCT_GROUP,
} from "./actionTypes"

import {
  getProductGroupsSuccess,
  getProductGroupsFail,
  getProductGroupProfileSuccess,
  getProductGroupProfileFail,
  addProductGroupFail,
  addProductGroupSuccess,
  updateProductGroupSuccess,
  updateProductGroupFail,
  deleteProductGroupSuccess,
  deleteProductGroupFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProductGroups,
  getProductGroupProfile,
  addNewProductGroup,
  updateProductGroup,
  deleteProductGroup,
} from "../../helpers/fakebackend_helper"

function* fetchProductGroups({ payload: active }) {
  try {
    const response = yield call(getProductGroups, active)
    // console.log(active);
    yield put(getProductGroupsSuccess(response))
  } catch (error) {
    yield put(getProductGroupsFail(error))
  }
}

function* fetchProductGroupProfile({ payload: id }) {
  // console.log(id)updateProductGroup
  try {
    const response = yield call(getProductGroupProfile, id)
    // console.log("Profile => ", response)
    yield put(getProductGroupProfileSuccess(response))
  } catch (error) {
    yield put(getProductGroupProfileFail(error))
  }
}

function* onUpdateProductGroup({ payload: designgroup }) {
  try {
    // let x = designgroup.get('formData')
    // console.log(x)
    const response = yield call(updateProductGroup, designgroup)
    yield put(updateProductGroupSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateProductGroupFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteProductGroup({ payload: designgroup }) {
  try {
    const response = yield call(deleteProductGroup, designgroup)
    yield put(deleteProductGroupSuccess(response))
  } catch (error) {
    yield put(deleteProductGroupFail(error))
  }
}

function* onAddNewProductGroup({ payload: designgroup }) {
  try {
    const response = yield call(addNewProductGroup, designgroup)
    yield put(addProductGroupSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addProductGroupFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* productGroup() {
  yield takeEvery(GET_PRODUCT_GROUPS, fetchProductGroups)
  yield takeEvery(GET_PRODUCT_GROUP_PROFILE, fetchProductGroupProfile)
  yield takeEvery(ADD_NEW_PRODUCT_GROUP, onAddNewProductGroup)
  yield takeEvery(UPDATE_PRODUCT_GROUP, onUpdateProductGroup)
  yield takeEvery(DELETE_PRODUCT_GROUP, onDeleteProductGroup)
}

export default productGroup
