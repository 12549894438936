import {
  GET_CHECK_BOQS_SUCCESS,
  GET_SUM_BOQS_SUCCESS,
  GET_MATRAIAL_BOQS_SUCCESS,
  GET_BOQS_SUCCESS,
  GET_BOQS_FAIL,
  ADD_BOQ_SUCCESS,
  ADD_BOQ_FAIL,
  UPDATE_BOQ_SUCCESS,
  UPDATE_BOQ_FAIL,
  DELETE_BOQ_SUCCESS,
  DELETE_BOQ_FAIL,
  GET_BOQ_PROFILE_SUCCESS,
  GET_BOQ_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  boqs: [],
  boqProfile: {},
  checkBoqs: [],
  sumBoqs: [],
  materialBoqs: [],
  error: {},
}

const boqs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BOQS_SUCCESS:
      return {
        ...state,
        boqs: action.payload,
      }
    case GET_CHECK_BOQS_SUCCESS:
      return {
        ...state,
        checkBoqs: action.payload,
      }

    case GET_SUM_BOQS_SUCCESS:
      return {
        ...state,
        sumBoqs: action.payload,
      }

    case GET_MATRAIAL_BOQS_SUCCESS:
      return {
        ...state,
        materialBoqs: action.payload,
      }

    case GET_BOQS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_BOQ_SUCCESS:
      return {
        ...state,
        boqs: [...state.boqs, action.payload],
      }

    case ADD_BOQ_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BOQ_PROFILE_SUCCESS:
      return {
        ...state,
        boqProfile: action.payload,
      }

    case UPDATE_BOQ_SUCCESS:
      return {
        ...state,
        boqs: state.boqs.map(boq =>
          boq.id.toString() === action.payload.id.toString()
            ? { boq, ...action.payload }
            : boq
        ),
      }

    case UPDATE_BOQ_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BOQ_SUCCESS:
      return {
        ...state,
        boqs: state.boqs.filter(
          boq => boq.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_BOQ_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BOQ_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default boqs
