import PropTypes from "prop-types"
import React from "react"
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Media,
  Modal,
  Input,
  Table,
  Button,
  InputGroup,
  InputGroupText,
  FormGroup,
  Label,
} from "reactstrap"
import Select from "react-select"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { isEmpty, map } from "lodash"
import Editable from "react-bootstrap-editable"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work
import { useSelector, useDispatch } from "react-redux"
import ExampleModalContractor from "components/Dialogs/dialogContractor"
import ExampleModalListStructure from "components/Dialogs/dialogList_Structure"
import { List, arrayMove } from "react-movable"
import avatar from "assets/images/users/avatar-1.jpg"
// import slip from "assets/images/slip.png"
import pay_matrial from "assets/images/pay_matrial.png"
import Dropzone from "react-dropzone"
//Lightbox
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { useScreenshot, createFileName } from "use-react-screenshot"
import "react-image-lightbox/style.css"
import { getContractors as onGetContractors } from "store/actions"
import {
  getListStructures as onGetList_structures,
  updateListStructure as onUpdateListStructure,
} from "store/actions"
import {
  getBoqTitleHomes as onGetBoqTitleHomes,
  getBoqTitleHomeProfile as onGetBoqTitleHomeProfile,
  addNewBoqTitleHome as onAddNewBoqTitleHome,
  updateBoqTitleHome as onUpdateBoqTitleHome,
  deleteBoqTitleHome as onDeleteBoqTitleHome,
} from "store/boq-title-homes/actions"

import {
  getBoqs as onGetBoqs,
  addNewBoq as onAddNewBoq,
} from "store/boqs/actions"

import ExampleModalMaterialStock from "components/Dialogs/dialogMaterial_stock"
import ExampleModalProjectAluminia from "components/Dialogs/dialogList_ProjectAluminia"
import ExampleModalCustomProjectAluminia from "components/Dialogs/dialogList_CustomProjectAluminia"

// API store
import { getMaterialStocks as onGetMaterialStocks } from "store/material-stocks/actions"
import { getMaterialTypes as onGetMaterialTypes } from "store/material-types/actions"
import { getSlipOnProject as onGetSlipOnProject } from "store/slips-project/actions"

const Boq = ({ projectID }) => {
  const { SearchBar } = Search
  const ref = React.createRef(null)
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = React.useState("1")
  const [activeTab1, setactiveTab1] = React.useState("wage")
  const [obj, setObj] = React.useState("wage")

  const [image, takeScreenShot] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  })

  const [modal_fullscreen, setmodal_fullscreen] = React.useState(false)
  const [modal_materialStock, setModal_materialStock] = React.useState(false)
  const [modal_formDataGroupBoqTitle, setModal_formDataGroupBoqTitle] =
    React.useState(false)
  const [formDataGroupBoqTitle, setFormDataGroupBoqTitle] = React.useState({
    quotation_list_name: "",
    quotation_list_detail: "",
    quotation_list_price: 0,
  })

  const [selectFormDataGroupBoqTitle, setSelectFormDataGroupBoqTitle] =
    React.useState([])
  const [openDetail, setOpenDetail] = React.useState(null)
  const [dataOnSlips, setDataOnSlips] = React.useState([])
  const [dataDetailOnSlips, setDataDetailOnSlips] = React.useState({})
  const [key_boq_title_detail, setKey_boq_title_detail] = React.useState(null)
  const [boqMaterials_index, setBoqMaterials_index] = React.useState(null)
  const [modal_selectShop, setModal_selectShop] = React.useState(false)
  const [listStructures, setListStructures] = React.useState(false)
  const [modal_listStructures, setModal_listStructures] = React.useState(false)
  const [modal_noteListStruck, setModal_noteListStruck] = React.useState(false)
  const [f_boq_titile_id, setF_Boq_titile_id] = React.useState(null)
  const [modal_checkPayMaterial, setModal_checkPayMaterial] =
    React.useState(false)
  const [selectedFiles, setselectedFiles] = React.useState([])
  const [isFits, setisFits] = React.useState(false)
  const [dataBoqTitleHomeProfile, setDataBoqTitleHomeProfile] = React.useState({
    boq_title_detail: [],
  })

  const [buttonCapture, setButtonCapture] = React.useState(false)
  const [styleCapture, setStyleCapture] = React.useState({
    padding: 5,
    width: "100%",
  })

  const [img_pay_material, setImg_pay_material] = React.useState(null)
  const [contractor, setContractor] = React.useState([])
  const [sumWagesContractor, setSumWagesContractor] = React.useState([])
  const [sumWagesContractor_id, setSumWagesContractor_id] = React.useState(null)
  const [dataSumWagesContractor, setDataSumWagesContractor] = React.useState([])
  const [boqTitleFavorite, setBoqTitleFavorite] = React.useState([])
  const [boqMaterialFavorite, setBoqMaterialFavorite] = React.useState([])
  const [modal_favoriteListStruck, setModal_favoriteListStruck] =
    React.useState(false)
  const [modal_favoriteBoq, setModal_favoriteBoq] = React.useState(false)

  const [ecommerceOrderColumns, setEcommerceOrderColumns] = React.useState([
    {
      dataField: "path_img",
      text: "รูปภาพ",
      sort: false,
      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return (
          <img
            onClick={() =>
              setActionModalCustomProjectAluminia({
                id: row.id,
                action: "Edit",
                modal: true,
                detail: row,
                project_id: null,
              })
            }
            src={process.env.REACT_APP_SERVER + cell}
            alt={process.env.REACT_APP_NAMECOMPANY}
            title={process.env.REACT_APP_NAMECOMPANY}
            className="avatar-sm"
          />
        )
      },
    },
    {
      dataField: "name",
      text: "ชื่องาน",
      sort: false,
      headerStyle: () => {},
      formatter: function action(cell, row) {
        return cell
      },
    },
    {
      dataField: "action",
      text: "จัดการ",
      sort: true,
      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return (
          <Button
            // onClick={() => eventAction("Edit", v.id, true)}
            className="mr-1 mb-1"
            color="primary"
            outline
            onClick={() =>
              setActionModalCustomProjectAluminia({
                id: row.id,
                action: "Edit",
                modal: true,
                detail: row,
                project_id: null,
              })
            }
          >
            <i className="bx bx-pencil"></i>
          </Button>
        )
      },
    },
  ])

  const [materials, setMaterials] = React.useState([])
  const [boqMaterials, setBoqMaterial] = React.useState([])
  const [selectEvent, setSelectEvent] = React.useState(false)
  const [selectEventBoq, setSelectEventBoq] = React.useState(false)
  const [aluminias, setAluminias] = React.useState([])
  const [qta_sum_price, setQta_sum_price] = React.useState(0)
  const [reqCallBack, setReqCallBack] = React.useState(null)
  const [selectMaterials, setSelectMaterials] = React.useState([])
  const [dataBoq_title_detail, setDataBoq_title_detail] = React.useState({})
  const [boqTitles, setBoqTitles] = React.useState([])
  const [modal_boqFavorite, setModal_boqFavorite] = React.useState(false)
  const [modal_boqMaterialFavorite, setModal_boqMaterialFavorite] =
    React.useState(false)

  const [list_structure_name, setList_structure_name] = React.useState({
    id: null,
    name: null,
  })
  const [material_type_id, setMaterial_type_id] = React.useState(0)
  const [selectType, setSelectType] = React.useState(1)
  const [groups, setGroups] = React.useState([])

  const [tableMaterialStockColumns, setTableMaterialStockColumns] =
    React.useState([
      {
        dataField: "name",
        text: "ชื่อ",
        sort: true,
      },
      {
        dataField: "material_type",
        text: "ประเภท",
        sort: true,
        headerStyle: () => {
          return { width: "100px" }
        },
        formatter: function action(cell, row) {
          return cell == 1 ? "วัสดุหลัก" : "วัสดุสิ้นเปลือง"
        },
      },
      // {
      //   dataField: "unit",
      //   text: "หน่วย",
      //   sort: true,
      //   headerStyle: () => {
      //     return { width: "80px" }
      //   },
      // },
      // {
      //   dataField: "qta",
      //   text: "คงเหลือ",
      //   sort: true,
      //   headerStyle: () => {
      //     return { width: "80px" }
      //   },
      //   formatter: function action(cell, row) {
      //     return (
      //       <span>
      //         {parseFloat(cell).toLocaleString("th-TH", {
      //           minimumFractionDigits: 0,
      //           maximumFractionDigits: 0,
      //         })}
      //       </span>
      //     )
      //   },
      // },
      // {
      //   dataField: "price",
      //   text: "ราคา (ขาย)",
      //   sort: true,
      //   headerStyle: () => {
      //     return { width: "80px" }
      //   },
      //   formatter: function action(cell, row) {
      //     return (
      //       <span>
      //         {parseFloat(cell).toLocaleString("th-TH", {
      //           minimumFractionDigits: 2,
      //           maximumFractionDigits: 2,
      //         })}
      //       </span>
      //     )
      //   },
      // },
      {
        dataField: "id",
        text: "จัดการ",
        sort: true,
        headerStyle: () => {
          return { width: "100px" }
        },
        formatter: function action(cell, row) {
          return (
            <div>
              <Button
                onClick={() => eventActionMaterialStock("Edit", cell, true)}
                className="mr-1 mb-1"
                color="primary"
                outline
              >
                <i className="bx bx-pencil"></i>
              </Button>
            </div>
          )
        },
      },
    ])

  // Table Data
  const [tableDataMaterialStock, setTableDataMaterialStock] = React.useState([]) // set campaign as default

  const [actionModalMaterialStock, setActionModelMaterialStock] =
    React.useState({
      id: null,
      action: null,
      modal: false,
      showQtaMoney: "Q",
    })
  const [actionModalProjectAluminia, setActionModelProjectAluminia] =
    React.useState({
      modal: false,
    })
  const [
    actionModalCustomProjectAluminia,
    setActionModalCustomProjectAluminia,
  ] = React.useState({
    id: null,
    modal: false,
    detail: [],
    project_id: projectID,
  })

  const { ListStructures } = useSelector(state => ({
    ListStructures: state.listStructures.listStructures,
  }))

  const { materialStocks } = useSelector(state => ({
    materialStocks: state.materialStocks.materialStocks,
  }))

  const { materialTypes } = useSelector(state => ({
    materialTypes: state.materialTypes.materialTypes,
  }))

  const { boqs } = useSelector(state => ({
    boqs: state.boqs.boqs,
  }))

  const { boqTitleHomes } = useSelector(state => ({
    boqTitleHomes: state.boqTitleHomes.boqTitleHomes,
  }))

  const { boqTitleHomeProfile } = useSelector(state => ({
    boqTitleHomeProfile: state.boqTitleHomes.boqTitleHomeProfile,
  }))

  const { slip_on_project } = useSelector(state => ({
    slip_on_project: state.slipprojects.slip_on_project,
  }))

  const [actionModalContractor, setActionModalContractor] = React.useState({
    id: null,
    action: null,
    modal: false,
  })

  const [actionModalListStructure, setActionModalListStructure] =
    React.useState({
      id: null,
      action: null,
      modal: false,
    })

  const { contractors } = useSelector(state => ({
    contractors: state.contractors.contractors,
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableDataMaterialStock.length, // replace later with size(customers),
    custom: true,
  }

  //pagination customization
  const pageAlumnOptions = {
    sizePerPage: 10,
    totalSize: aluminias.length, // replace later with size(orders),
    custom: true,
  }

  React.useEffect(async () => {
    if (materialTypes != undefined) {
      const options = await materialTypes.map((d, index) => ({
        value: d.id,
        label: d.name,
      }))
      let newOpeion = [
        {
          value: 0,
          label: "ประเภททั้งหมด",
        },
      ].concat(options)

      setGroups(newOpeion)
    }

    return () => {}
  }, [materialTypes]) // Only re-run the effect if count changes

  React.useEffect(() => {
    dispatch(onGetMaterialTypes(1))
  }, [dispatch])

  React.useEffect(async () => {
    if (buttonCapture == true) {
      await takeScreenShot(ref.current).then(download)

      setButtonCapture(false)
      setStyleCapture({
        padding: 5,
        width: "100%",
      })
    }
  }, [buttonCapture])

  const downloadScreenshot = async () => {
    setStyleCapture({
      padding: 5,
      width: 650,
    })
    setTimeout(() => {
      setButtonCapture(true)
    }, 1000)
  }
  const download = async (
    image,
    { name = "ค่าแรงช่าง", extension = "png" } = {}
  ) => {
    const a = document.createElement("a")
    a.href = image
    a.download = createFileName(extension, name)
    a.click()
  }

  React.useEffect(() => {
    // console.log("boqs", boqs.boq)
    if (!isEmpty(boqs)) {
      // setBoqs(boqs)
      setBoqMaterial(boqs.boq)
      setDataBoq_title_detail(boqs.boq_title_detail)
      if (!isEmpty(boqs.boqTitleDetailFavorite)) {
        setBoqMaterialFavorite(boqs.boqTitleDetailFavorite)
      }
    } else {
      setBoqMaterial([])
      setDataBoq_title_detail({})
      // setBoqs(boqs)
    }
    // console.log("boqs", boqs)
  }, [boqs])

  React.useEffect(() => {
    // console.log("slip_on_project", slip_on_project)
    if (!isEmpty(slip_on_project)) {
      setDataOnSlips(slip_on_project.img)
      setDataDetailOnSlips(slip_on_project.boq_title_detail)
    } else {
      setDataOnSlips([])
      setDataDetailOnSlips({})
    }
  }, [slip_on_project])

  React.useEffect(() => {
    dispatch(onGetMaterialStocks(selectType, material_type_id))
  }, [selectType, material_type_id])

  React.useEffect(() => {
    // console.log(materialStocks);
    if (!isEmpty(materialStocks)) {
      setTableDataMaterialStock(materialStocks)
    } else {
      setTableDataMaterialStock([])
    }
  }, [materialStocks])

  function eventActionMaterialStock(action, id, modal) {
    setActionModelMaterialStock({
      id: id,
      action: action,
      modal: modal,
      showQtaMoney: "Q",
    })
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab == 1) {
        setList_structure_name(null)
      }
      setActiveTab(tab.toString())
    }
  }

  React.useEffect(async () => {
    if (!isEmpty(contractors)) {
      const options = await contractors.map(d => ({
        value: d.id,
        label: d.name,
        lastname: d.lastname,
        card_number: d.card_number,
        number_phone: d.number_phone,
        bank: d.bank,
        bank_no: d.bank_no,
        address: d.address,
      }))
      let newOption = [
        {
          value: 0,
          label: "เพิ่มใหม่",
        },
        {
          value: "del",
          label: "ยังไม่เลือกช่าง",
        },
      ].concat(options)

      setContractor(newOption)
    } else {
      setContractor([])
    }
  }, [contractors])

  // React.useEffect(async () => {
  // console.log('dataSumWagesContractor',dataSumWagesContractor)
  // }, [dataSumWagesContractor])

  React.useEffect(async () => {
    // console.log("boqTitleHomes", boqTitleHomes)
    if (!isEmpty(boqTitleHomes)) {
      // setDataSumWagesContractor(boqTitleHomes.sumWages)
      let newSumWages = boqTitleHomes.sumWages
      let newOptionSumWagesContractor = []
      for (let index = 0; index < newSumWages.length; index++) {
        if (newSumWages[index].contractor_id != null) {
          if (newOptionSumWagesContractor.length == 0) {
            newOptionSumWagesContractor.push({
              value: newSumWages[index].contractor_id,
              label: newSumWages[index].name_contractor,
            })
          } else {
            for (let j = 0; j < newOptionSumWagesContractor.length; j++) {
              if (
                newOptionSumWagesContractor[j].value !=
                newSumWages[index].contractor_id
              ) {
                newOptionSumWagesContractor.push({
                  value: newSumWages[index].contractor_id,
                  label: newSumWages[index].name_contractor,
                })
              }
            }
          }
        }
      }

      let newOption = [
        {
          value: 0,
          label: "ทั้งหมด",
        },
      ].concat(newOptionSumWagesContractor)
      setSumWagesContractor(newOption)
      setBoqTitles(boqTitleHomes.boqTitleHomes)
      setBoqTitleFavorite(boqTitleHomes.boqTitleFavorite)
      setBoqMaterialFavorite(boqTitleHomes.boqTitleDetailFavorite)
      let check_boqTitleHomes_materials = boqTitleHomes.materials
      let check_price_sum = 0
      for (
        let index = 0;
        index < check_boqTitleHomes_materials.length;
        index++
      ) {
        check_price_sum =
          check_price_sum + check_boqTitleHomes_materials[index].qta_sum_price
      }
      setQta_sum_price(check_price_sum)
      setMaterials(boqTitleHomes.materials)
      setAluminias(boqTitleHomes.aluminias)
    }
  }, [boqTitleHomes])

  React.useEffect(async () => {
    // console.log("boqTitleHomeProfile", boqTitleHomeProfile)
    if (!isEmpty(boqTitleHomeProfile)) {
      setDataBoqTitleHomeProfile(boqTitleHomeProfile)
    } else {
      setDataBoqTitleHomeProfile({
        boq_title_detail: [],
      })
    }

    // console.log("ListStructures", ListStructures)
    if (!isEmpty(ListStructures)) {
      const options = await ListStructures.map(d => ({
        value: d.id,
        label: d.name,
        check_pay: 0,
        del: 0,
        boq_title_id: dataBoqTitleHomeProfile.id,
        list_structure_price:
          parseFloat(
            parseFloat(
              parseFloat(dataBoqTitleHomeProfile.quotation_list_price / 100) *
                parseFloat(dataBoqTitleHomeProfile.boq_title_persen)
            ) / 100
          ) * parseFloat(d.persen),
        list_structure_persen: d.persen,
      }))
      let newOption = [
        {
          value: 0,
          label: "เพิ่มหมวดงานใหม่",
        },
      ].concat(options)
      setListStructures(newOption)
    } else {
      setListStructures([])
    }
  }, [boqTitleHomeProfile, ListStructures])

  // React.useEffect(async () => {
  //   console.log("boqTitleHomeProfile", boqTitleHomeProfile)
  // }, [boqTitleHomeProfile])

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
      dispatch(onGetBoqTitleHomes(projectID, tab))
    }
  }

  function tog_fullscreen(boq_titile_id) {
    // console.log(boq_titile_id)
    setF_Boq_titile_id(boq_titile_id)
    dispatch(onGetBoqTitleHomeProfile(boq_titile_id))
    setList_structure_name(null)
    setactiveTab1("wage")
    setActiveTab("1")
    setSelectEvent(false)
    setSelectMaterials([])
    dispatch(onGetBoqTitleHomes(projectID, "wage"))
    setmodal_fullscreen(!modal_fullscreen)
  }

  function tog_modalShop() {
    setModal_selectShop(!modal_selectShop)
  }

  function tog_checkPayMaterial() {
    setModal_checkPayMaterial(!modal_checkPayMaterial)
  }

  React.useEffect(() => {
    if (!isEmpty(projectID)) {
      dispatch(onGetContractors(1))
      dispatch(onGetMaterialTypes(1))
      dispatch(onGetList_structures(1, 0))
      dispatch(onGetBoqTitleHomes(projectID, activeTab1))
      setObj(JSON.parse(localStorage.getItem("authUser")))
    }
  }, [projectID])

  // React.useEffect(() => {
  //   console.log("selectFormDataGroupBoqTitle", selectFormDataGroupBoqTitle)
  // }, [selectFormDataGroupBoqTitle])

  async function selectBoqTitleGroups(id, type) {
    // console.log(id)
    if (type == 1) {
      setSelectFormDataGroupBoqTitle(selectFormDataGroupBoqTitle => [
        ...selectFormDataGroupBoqTitle,
        id,
      ])
    } else if (type == 0) {
      var modifiedRows = [...selectFormDataGroupBoqTitle]
      modifiedRows = modifiedRows.filter(x => x !== id)
      setSelectFormDataGroupBoqTitle(modifiedRows)
    }
  }

  async function handleAcceptedFiles(files) {
    // console.log(files)
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleSubmitGroupBoqTitle = () => {
    const data = new FormData()
    data.append("project_id", projectID)
    data.append("groupBoqTitle", JSON.stringify(formDataGroupBoqTitle))
    data.append(
      "selectFormDataGroupBoqTitle",
      JSON.stringify(selectFormDataGroupBoqTitle)
    )
    dispatch(onAddNewBoqTitleHome(data))

    setModal_formDataGroupBoqTitle(!modal_formDataGroupBoqTitle)
    setFormDataGroupBoqTitle({})
  }
  const handleSubmitBoqTitleHome = (action, id, dataFa, newDataArray) => {
    // console.log(action)
    // console.log("dataBoqTitleHomeProfile", dataBoqTitleHomeProfile)
    const data = new FormData()

    data.append("dataFa", JSON.stringify(dataFa))
    data.append("unFavorite", id)
    if (action == "selectUpDate") {
      let newData = dataBoqTitleHomeProfile
      newData.boq_title_detail = newDataArray
      data.append("formData", JSON.stringify(newData))
      dispatch(onUpdateBoqTitleHome(data))
      if (f_boq_titile_id != undefined) {
        setTimeout(() => {
          dispatch(onGetBoqTitleHomeProfile(f_boq_titile_id))
        }, 2000)
      }
    } else {
      if (action == "Del" || action == "DelAndUp") {
        data.append("formData", JSON.stringify(dataBoqTitleHomeProfile))
      } else {
        let newData = dataBoqTitleHomeProfile
        let dataArray = dataBoqTitleHomeProfile.boq_title_detail
        for (let index = 0; index < dataArray.length; index++) {
          if (dataArray[index].del == 1) {
            dataArray[index].del = 0
            dataArray[index].disabled = true
          }
        }
        newData.boq_title_detail = dataArray
        data.append("formData", JSON.stringify(newData))
      }
    }

    // console.log(dataBoqTitleHomeProfile);
    if (action == "Add") {
      dispatch(onAddNewBoqTitleHome(data))
    } else if (action == "Edit") {
      dispatch(onUpdateBoqTitleHome(data))
    } else if (action == "Del") {
      dispatch(onDeleteBoqTitleHome(id))
    } else if (action == "UpdateFa") {
      dispatch(onUpdateBoqTitleHome(data))
    } else if (action == "DelAndUp") {
      dispatch(onUpdateBoqTitleHome(data))
      if (f_boq_titile_id != undefined) {
        setTimeout(() => {
          dispatch(onGetBoqTitleHomeProfile(f_boq_titile_id))
        }, 2000)
      }
    }
    setTimeout(() => {
      setactiveTab1("wage")
      setActiveTab("1")
      setSelectEvent(false)
      setSelectMaterials([])
    }, 3000)
  }

  const handleSubmitBoqMaterials = (value, type) => {
    // console.log("value", value)
    // console.log("type", type)

    // console.log("boqMaterials", boqMaterials)

    const data = new FormData()
    data.append("boq_title_detail_id", list_structure_name.id)
    data.append("boq_title_detail", JSON.stringify(dataBoq_title_detail))
    data.append("dataFa", undefined)
    if (type == "UpdateFa") {
      data.append("dataFa", JSON.stringify(value))
    }

    if (type == "del") {
      // console.log("boqMaterials", boqMaterials)
      data.append("formData", JSON.stringify(boqMaterials))
    } else {
      let newData = boqMaterials
      for (let index = 0; index < newData.length; index++) {
        if (newData[index].del == 1) {
          newData[index].del = 0
        }
      }
      data.append("formData", JSON.stringify(newData))
    }

    data.append("unFavorite", value)

    dispatch(onAddNewBoq(data))
  }

  const selectRow = {
    mode: "radio",
    clickToSelect: false,
    // hideSelectAll: true,
    selectionHeaderRenderer: () => "เลือก",
    headerColumnStyle: {
      width: 80,
    },
    selectionRenderer: ({ mode, ...rest }) => <input type={mode} {...rest} />,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        // setSelectMaterials([...selectMaterials, row])
        let temp = [...boqMaterials]
        temp[boqMaterials_index].boq_title_detail_id = list_structure_name.id
        temp[boqMaterials_index].material_id = row.id
        temp[boqMaterials_index].material_name = row.name
        temp[boqMaterials_index].material_type_id = row.material_type_id
        temp[boqMaterials_index].material_type = row.material_type
        temp[boqMaterials_index].unit = row.unit
        temp[boqMaterials_index].pay = 0
        temp[boqMaterials_index].qta_sum_price = 0
        temp[boqMaterials_index].check_pay = 0
        setBoqMaterial(temp)
        setModal_materialStock(!modal_materialStock)
        // setBoqMaterial(prevArray => [
        //   ...prevArray,
        //   {
        //     boq_title_detail_id: list_structure_name.id,
        //     material_id: row.id,
        //     material_name: row.name,
        //     material_type: row.material_type,
        //     unit: row.unit,
        //     detail: "รายการ",
        //     qta: "ใส่จำนวน",
        //     pay: 0,
        //     qta_sum_price: 0,
        //     check_pay: 0,
        //   },
        // ])
        // setModal_materialStock(!modal_materialStock)
      } else {
        setSelectMaterials(selectMaterials.filter(x => x.id !== row.id))
      }
    },
  }
  function selecOnList_structure(id, name, img, img_path) {
    // console.log(img)
    if (img == null) {
      setImg_pay_material(null)
    } else {
      setImg_pay_material(img_path)
    }
    dispatch(onGetBoqs("list", id))
    dispatch(onGetSlipOnProject(id))
    toggleTab("2")
    setList_structure_name({
      id: id,
      name: name,
    })
  }

  const selectColorContractor = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // console.log(data)
      return {
        ...styles,
        color:
          data.value == 0 ? "#556ee6" : data.value == "del" ? "#f46a6a" : null,
      }
    },
  }

  React.useEffect(async () => {
    // console.log("dataBoqTitleHomeProfile", dataBoqTitleHomeProfile)
    let checkEventDel = 0
    if (!isEmpty(boqMaterials)) {
      for (let index = 0; index < boqMaterials.length; index++) {
        // console.log(boq_title_detail[index])
        if (boqMaterials[index].del == 1) {
          checkEventDel += 1
        }
      }
    }

    if (checkEventDel > 0) {
      setSelectEventBoq(true)
    } else {
      setSelectEventBoq(false)
    }
  }, [boqMaterials])

  React.useEffect(async () => {
    // console.log("dataBoqTitleHomeProfile", dataBoqTitleHomeProfile)
    let boq_title_detail = dataBoqTitleHomeProfile.boq_title_detail
    let checkEventDel = 0
    if (!isEmpty(boq_title_detail)) {
      for (let index = 0; index < boq_title_detail.length; index++) {
        // console.log(boq_title_detail[index])
        if (boq_title_detail[index].del == 1) {
          checkEventDel += 1
        }
      }
    }

    if (checkEventDel > 0) {
      setSelectEvent(true)
    } else {
      setSelectEvent(false)
    }
    // console.log(checkEventDel);
  }, [dataBoqTitleHomeProfile])

  // React.useEffect(() => {
  //   console.log("selectMaterials", selectMaterials)
  // }, [selectMaterials])

  // React.useEffect(() => {
  //   console.log("boqMaterials", boqMaterials)
  // }, [boqMaterials])

  React.useEffect(() => {
    if (!isEmpty(reqCallBack)) {
      setActionModalCustomProjectAluminia({
        id: null,
        action: "Add",
        modal: true,
        detail: reqCallBack,
        project_id: projectID,
      })
    }
    return () => {
      setReqCallBack(null)
    }
  }, [reqCallBack])

  function selectOnBoq_title_detail(data) {
    let newArray = dataBoqTitleHomeProfile.boq_title_detail
    for (let index = 0; index < data.length; index++) {
      // console.log(data[index])

      delete data[index]["id"]
      delete data[index]["favorite"]
      delete data[index]["favorite_name"]
      delete data[index]["note"]
      delete data[index]["img"]

      delete data[index]["check_pay"]

      data[index].boq_title_id = f_boq_titile_id
      data[index].list_structure_price = 0
      data[index].list_structure_pay = 0
      data[index].disabled = true
      newArray.push(data[index])
    }

    setDataBoqTitleHomeProfile({
      ...dataBoqTitleHomeProfile,
      boq_title_detail: newArray,
    })
    handleSubmitBoqTitleHome("Edit", null)
    setModal_boqFavorite(!modal_boqFavorite)
  }

  function selectOnBoq(data) {
    for (let index = 0; index < data.length; index++) {
      if (boqMaterials.length == 0) {
        delete data[index]["id"]
        delete data[index]["pay"]
        delete data[index]["check_pay"]
        data[index].project_id = Number(projectID)
        data[index].boq_title_detail_id = list_structure_name.id
        data[index].user_id = obj.id
        data[index].disabled = true
        setBoqMaterial(boqMaterials => [...boqMaterials, data[index]])
      } else {
        for (let i = 0; i < boqMaterials.length; i++) {
          if (boqMaterials[i].material_id == data[index].material_id) {
            let old_data = [...boqMaterials]
            old_data[i].qta =
              parseInt(boqMaterials[i].qta) + parseInt(data[index].qta)
            setBoqMaterial(boqMaterials => [...boqMaterials, old_data])
          } else {
            delete data[index]["id"]
            delete data[index]["pay"]
            delete data[index]["check_pay"]
            data[index].project_id = Number(projectID)
            data[index].boq_title_detail_id = list_structure_name.id
            data[index].user_id = obj.id
            data[index].disabled = true
            setBoqMaterial(boqMaterials => [...boqMaterials, data[index]])
          }
        }
      }
    }
    // setBoqMaterial(newArray2)
    setModal_boqMaterialFavorite(!modal_boqMaterialFavorite)
  }

  return (
    <React.Fragment>
      <style scoped>
        {
          "\
          .project-list-table {\
            border: 1px solid white;\
          }\
          "
        }
      </style>
      <div>
        <ExampleModalContractor actionModal={actionModalContractor} />
        <ExampleModalListStructure actionModal={actionModalListStructure} />
        <ExampleModalMaterialStock actionModal={actionModalMaterialStock} />
        <ExampleModalProjectAluminia
          actionModal={actionModalProjectAluminia}
          callBack={setReqCallBack}
        />
        <ExampleModalCustomProjectAluminia
          actionModal={actionModalCustomProjectAluminia}
        />
      </div>
      <Modal
        size="lg"
        centered
        isOpen={modal_materialStock}
        toggle={() => {
          setModal_materialStock(!modal_materialStock)
        }}
      >
        <div className="modal-body">
          <Row>
            <Col className="col-12">
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={tableMaterialStockColumns}
                data={tableDataMaterialStock}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={tableMaterialStockColumns}
                    data={tableDataMaterialStock}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="12" className="mb-2">
                            <Row>
                              <Col sm="6" style={{ textAlign: "left" }}>
                                <Select
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name="material_type_id"
                                  value={groups.filter(function (option) {
                                    return (
                                      option.value == material_type_id || ""
                                    )
                                  })}
                                  onChange={e => setMaterial_type_id(e.value)}
                                  options={groups.map((item, i) => ({
                                    value: item.value,
                                    label: item.label,
                                  }))}
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col sm="8">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="text-sm-end">
                              <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={() =>
                                  eventActionMaterialStock("Add", null, true)
                                }
                              >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                เพิ่มวัสดุใหม่
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered
                                striped
                                selectRow={selectRow}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModal_materialStock(!modal_materialStock)
            }}
            className="btn btn-danger "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        size="xl"
        centered
        isOpen={modal_favoriteListStruck}
        toggle={() => {
          setModal_favoriteListStruck(!modal_favoriteListStruck)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            บันทึก Favorite งาน
          </h5>

          <button
            type="button"
            onClick={() => {
              setModal_favoriteListStruck(!modal_favoriteListStruck)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Input
            type="text"
            className="form-control"
            placeholder="Name favorite งาน"
            value={dataBoqTitleHomeProfile.favorite_name}
            onChange={e => {
              setDataBoqTitleHomeProfile({
                ...dataBoqTitleHomeProfile,
                favorite: 1,
                favorite_name: e.target.value,
              })
            }}
            name={"favorite_name"}
          />
        </div>

        <hr />
        <Table
          responsive
          className="project-list-table table-nowrap align-middle table-bordered"
        >
          <thead>
            <tr>
              <th style={{ width: "10%", textAlign: "center" }}></th>

              {/* <th scope="col" style={{ width: "10px" }}>
  โปรเจค
</th> */}

              <th style={{ width: "90%" }}>ชื่อ</th>
              {/* <th style={{ width: "10%" }}></th> */}
            </tr>
          </thead>
          <tbody>
            {(boqTitleFavorite || []).map((v, k) => (
              <tr key={k}>
                <td style={{ paddingTop: 2, paddingBottom: 2 }}>
                  <Button
                    color="info"
                    outline
                    onClick={e => {
                      // console.log(e);
                      let temp = dataBoqTitleHomeProfile
                      temp.favorite = 1
                      temp.favorite_name = v.favorite_name
                      setDataBoqTitleHomeProfile(temp)
                      handleSubmitBoqTitleHome("Edit", v.id)
                      setModal_favoriteListStruck(!modal_favoriteListStruck)
                    }}
                  >
                    บันทึกทับ
                  </Button>
                </td>
                {/* <td>{isEmpty(v.project) == true ? "" : v.project.name}</td> */}
                <td style={{ paddingTop: 2, paddingBottom: 2 }}>
                  <Row>
                    <Col sm="10">
                      <Editable
                        id={"favorite_name" + v.id}
                        initialValue={v.favorite_name}
                        isValueClickable
                        mode="inline"
                        onSubmit={e => {
                          let edit = [...boqTitleFavorite]
                          edit[k].favorite_name = e
                          setBoqTitleFavorite(edit)
                          // console.log(boqTitleFavorite);
                          handleSubmitBoqTitleHome("UpdateFa", null, edit[k])
                        }}
                        placement="top"
                        type="textfield"
                      />
                    </Col>
                    <Col sm="2" style={{ textAlign: "right" }}>
                      {openDetail == v.id ? (
                        <i
                          onClick={e => {
                            // console.log(v);
                            setOpenDetail(openDetail == v.id ? null : v.id)
                          }}
                          className="bx bx-caret-up"
                          style={{
                            cursor: "pointer",
                          }}
                        ></i>
                      ) : (
                        <i
                          onClick={e => {
                            // console.log(v);
                            setOpenDetail(openDetail == v.id ? null : v.id)
                          }}
                          className="bx bx-caret-down"
                          style={{
                            cursor: "pointer",
                          }}
                        ></i>
                      )}
                    </Col>
                  </Row>

                  <Collapse isOpen={openDetail == v.id ? true : false}>
                    <hr />
                    {(v.boq_title_detail || []).map((j, i) => (
                      <p key={i}>{j.list_structure_name}</p>
                    ))}
                  </Collapse>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              handleSubmitBoqTitleHome("Edit", null)
              setModal_favoriteListStruck(!modal_favoriteListStruck)
            }}
            className="btn btn-primary "
            data-dismiss="modal"
          >
            Save
          </button>
        </div>
      </Modal>
      <Modal
        size="xl"
        centered
        isOpen={modal_favoriteBoq}
        toggle={() => {
          setModal_favoriteBoq(!modal_favoriteBoq)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            บันทึก Favorite วัสดุ
          </h5>

          <button
            type="button"
            onClick={() => {
              setModal_favoriteBoq(!modal_favoriteBoq)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Input
            type="text"
            className="form-control"
            placeholder="Name favorite วัสดุ"
            value={dataBoq_title_detail.favorite_name}
            onChange={e => {
              setDataBoq_title_detail({
                ...dataBoq_title_detail,
                favorite: 1,
                favorite_name: e.target.value,
              })
            }}
            name={"favorite_name_material"}
          />
          <hr />
          <Table
            responsive
            className="project-list-table table-nowrap align-middle table-bordered"
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{ width: "10%", textAlign: "center" }}
                ></th>

                {/* <th scope="col" style={{ width: "30px" }}>
                  โปรเจค
                </th> */}
                <th scope="col" style={{ width: "30%" }}>
                  หมวดงาน
                </th>
                <th scope="col" style={{ width: "60%" }}>
                  ชื่อ
                </th>
              </tr>
            </thead>
            <tbody>
              {(boqMaterialFavorite || []).map((v, k) => (
                <tr key={k}>
                  <td>
                    <Button
                      color="info"
                      outline
                      onClick={e => {
                        let temp = dataBoq_title_detail
                        temp.favorite = 1
                        temp.favorite_name = v.favorite_name
                        setDataBoq_title_detail(temp)
                        handleSubmitBoqMaterials(v.id)
                        setModal_favoriteBoq(!modal_favoriteBoq)
                      }}
                    >
                      บันทึกทับ
                    </Button>
                  </td>

                  <td>{v.list_structure_name}</td>
                  <td>
                    <Row>
                      <Col sm="10">
                        <Editable
                          id={"favorite_name" + v.id}
                          initialValue={v.favorite_name}
                          isValueClickable
                          mode="inline"
                          onSubmit={e => {
                            let edit = [...boqMaterialFavorite]
                            edit[k].favorite_name = e
                            setBoqMaterial(edit)
                            // console.log(boqMaterialFavorite);
                            handleSubmitBoqMaterials(edit[k], "UpdateFa")
                          }}
                          placement="top"
                          type="textfield"
                        />
                      </Col>
                      <Col sm="2" style={{ textAlign: "right" }}>
                        {openDetail == v.id ? (
                          <i
                            onClick={e => {
                              // console.log(v);
                              setOpenDetail(openDetail == v.id ? null : v.id)
                            }}
                            className="bx bx-caret-up"
                            style={{
                              cursor: "pointer",
                            }}
                          ></i>
                        ) : (
                          <i
                            onClick={e => {
                              // console.log(v);
                              setOpenDetail(openDetail == v.id ? null : v.id)
                            }}
                            className="bx bx-caret-down"
                            style={{
                              cursor: "pointer",
                            }}
                          ></i>
                        )}
                      </Col>
                    </Row>

                    <Collapse isOpen={openDetail == v.id ? true : false}>
                      <hr />
                      {(v.boqs || []).map((j, i) => (
                        <p key={i}>
                          {j.material_name} ({j.detail})
                        </p>
                      ))}
                    </Collapse>
                  </td>
                  {/* <td>{v.favorite_name}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              handleSubmitBoqMaterials()
              setModal_favoriteBoq(!modal_favoriteBoq)
            }}
            className="btn btn-primary "
            data-dismiss="modal"
          >
            Save
          </button>
        </div>
      </Modal>
      <Modal
        size="lg"
        centered
        isOpen={modal_formDataGroupBoqTitle}
        toggle={() => {
          setModal_formDataGroupBoqTitle(!modal_formDataGroupBoqTitle)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            รวมหมวดงาน
          </h5>

          <button
            type="button"
            onClick={() => {
              setModal_formDataGroupBoqTitle(!modal_formDataGroupBoqTitle)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col sm="12" md="4">
              <InputGroup>
                <InputGroupText>ชื่อหมวดงาน</InputGroupText>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="ชื่อหมวดงาน"
                  value={formDataGroupBoqTitle.quotation_list_name}
                  onChange={e => {
                    setFormDataGroupBoqTitle({
                      ...formDataGroupBoqTitle,
                      quotation_list_name: e.target.value,
                    })
                  }}
                  name={"quotation_list_name"}
                />
              </InputGroup>
            </Col>
            <Col sm="12" md="4">
              <InputGroup>
                <InputGroupText>เพิ่มเติม</InputGroupText>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="เพิ่มเติม"
                  value={formDataGroupBoqTitle.quotation_list_detail}
                  onChange={e => {
                    setFormDataGroupBoqTitle({
                      ...formDataGroupBoqTitle,
                      quotation_list_detail: e.target.value,
                    })
                  }}
                  name={"quotation_list_detail"}
                />
              </InputGroup>
            </Col>
            <Col sm="12" md="4">
              <InputGroup>
                <InputGroupText>ราคางาน</InputGroupText>
                <Input
                  type="number"
                  className="form-control"
                  placeholder="ราคางาน"
                  value={formDataGroupBoqTitle.quotation_list_price}
                  onChange={e => {
                    setFormDataGroupBoqTitle({
                      ...formDataGroupBoqTitle,
                      quotation_list_price: e.target.value,
                    })
                  }}
                  name={"quotation_list_price"}
                />
              </InputGroup>
            </Col>
            <hr className="mt-2" />
            <Col sm="12">
              {(boqTitles || []).map((v, k) => (
                <Row key={k}>
                  <Col sm="1" style={{ textAlign: "center" }}>
                    {v.selectGroup == 0 ? (
                      <i
                        className="bx bx-comment-detail font-16"
                        style={{ color: "#f46a6a" }}
                        onClick={() => {
                          let temp = [...boqTitles]
                          temp[k].selectGroup = 1
                          setBoqTitles(temp)
                          // console.log(
                          //   parseFloat(
                          //     formDataGroupBoqTitle.quotation_list_price
                          //   ) + parseFloat(v.quotation_list_price)
                          // )
                          selectBoqTitleGroups(v.id, 1)
                          setFormDataGroupBoqTitle({
                            ...formDataGroupBoqTitle,
                            quotation_list_price:
                              parseFloat(
                                formDataGroupBoqTitle.quotation_list_price
                              ) + parseFloat(v.quotation_list_price),
                          })
                        }}
                      ></i>
                    ) : (
                      <i
                        className="bx bx-check font-16"
                        style={{ color: "#34c38f" }}
                        onClick={() => {
                          let temp = [...boqTitles]
                          temp[k].selectGroup = 0
                          setBoqTitles(temp)
                          // console.log(v)
                          selectBoqTitleGroups(v.id, 0)

                          setFormDataGroupBoqTitle({
                            ...formDataGroupBoqTitle,
                            quotation_list_price:
                              parseFloat(
                                formDataGroupBoqTitle.quotation_list_price
                              ) - parseFloat(v.quotation_list_price),
                          })
                        }}
                      ></i>
                    )}
                  </Col>
                  <Col
                    sm="8"
                    style={{
                      color:
                        v.active_group == 1 || v.active_group == 2
                          ? "#556ee6"
                          : null,
                    }}
                  >
                    <span>{v.quotation_list_name} </span>
                    {!isEmpty(v.quotation_list_detail) && (
                      <span>&nbsp;{v.quotation_list_detail})</span>
                    )}
                    {!isEmpty(v.group_name) && (
                      <span style={{ color: "#f46a6a" }}>
                        &nbsp;({v.group_name})
                      </span>
                    )}
                  </Col>
                  <Col sm="3">
                    <Row>
                      <Col sm="5">ราคางาน: </Col>
                      <Col sm="7" style={{ textAlign: "right" }}>
                        {parseFloat(v.quotation_list_price).toLocaleString(
                          "th-TH"
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          {!isEmpty(formDataGroupBoqTitle.quotation_list_name) && (
            <button
              type="button"
              onClick={() => {
                handleSubmitGroupBoqTitle()
              }}
              className="btn btn-primary "
              data-dismiss="modal"
            >
              Save
            </button>
          )}

          <button
            type="button"
            onClick={() => {
              setModal_formDataGroupBoqTitle(!modal_formDataGroupBoqTitle)
            }}
            className="btn btn-danger "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>

      <Modal
        size="md"
        centered
        isOpen={modal_selectShop}
        toggle={() => {
          tog_modalShop()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            เหล็กคาน (4x2&quot;)
          </h5>

          <button
            type="button"
            onClick={() => {
              setModal_selectShop(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col sm="6">คลังมี: 40 </Col>
            <Col sm="6">
              <Input
                type="number"
                className="form-control"
                placeholder="ใช้วัสดุจากคลังจำนวน"
                // value={formData.persen}
                // onChange={e => {
                //   setFormData({ ...formData, persen: e.target.value })
                // }}
                name={"prepare_material"}
              />
            </Col>

            <Col sm="6">ซื้อ</Col>
            <Col sm="6">
              <Input
                type="number"
                className="form-control"
                placeholder="ซื้อวัสดุจำนวน"
                // value={formData.persen}
                // onChange={e => {
                //   setFormData({ ...formData, persen: e.target.value })
                // }}
                name={"buy_material"}
              />
            </Col>
          </Row>
          <hr />
          <Table className="table table-bordered mb-0" responsive>
            <thead>
              <tr>
                <th>ชื่อร้าน</th>
                <th style={{ width: "120px", textAlign: "right" }}>ราคา</th>
                <th style={{ width: "80px", textAlign: "right" }}>จัดการ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ร้านทวีโชค (0635686700)</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {(50).toLocaleString("th-TH")}
                </td>

                <td
                  style={{
                    textAlign: "right",
                  }}
                  onClick={e => tog_modalShop()}
                >
                  <i
                    className="bx bx bx-comment-error"
                    style={{
                      fontSize: "16px",
                      color: "#556ee6",
                    }}
                  ></i>
                </td>
              </tr>

              <tr>
                <td>ไทยวัสดุ (0635686700)</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {(40).toLocaleString("th-TH")}
                </td>

                <td
                  onClick={e => tog_modalShop()}
                  style={{
                    textAlign: "right",
                  }}
                ></td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_modalShop()
            }}
            className="btn btn-danger "
            data-dismiss="modal"
          >
            Close
          </button>

          <button
            type="button"
            onClick={() => {
              tog_modalShop()
            }}
            className="btn btn-primary "
            data-dismiss="modal"
          >
            Save
          </button>
        </div>
      </Modal>
      <Modal
        size="md"
        centered
        isOpen={modal_checkPayMaterial}
        toggle={() => {
          tog_checkPayMaterial()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            หลักฐานการส่งวัสดุ
          </h5>

          <button
            type="button"
            onClick={() => {
              setModal_checkPayMaterial(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            <Dropzone
              multiple={true}
              accept="image/*"
              inputContent={(files, extra) =>
                extra.reject ? "Image files only" : "Drag Files"
              }
              onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
              }}
              styles={{
                dropzoneReject: {
                  borderColor: "#f46a6a",
                  backgroundColor: "#DAA",
                },
                inputLabel: (files, extra) =>
                  extra.reject ? { color: "#f46a6a" } : {},
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dz-message needsclick">
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>ลากไฟล์เพื่ออัพโหลดใหม่.</h4>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>

            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )
              })}
            </div>
          </div>
          <hr />
          <div className="d-flex">
            <div
              style={{
                textAlign: "center",
                padding: 5,
              }}
            >
              <img
                src={pay_matrial}
                alt={process.env.REACT_APP_NAMECOMPANY}
                style={{
                  objectFit: "cover",
                }}
                onClick={() => {
                  setisFits(true)
                }}
                className="avatar-md rounded-circle img-thumbnail"
              />
            </div>

            <div
              style={{
                textAlign: "center",
                padding: 5,
              }}
            >
              <img
                src={pay_matrial}
                alt={process.env.REACT_APP_NAMECOMPANY}
                style={{
                  objectFit: "cover",
                }}
                onClick={() => {
                  setisFits(true)
                }}
                className="avatar-md rounded-circle img-thumbnail"
              />
            </div>

            <div
              style={{
                textAlign: "center",
                padding: 5,
              }}
            >
              <img
                src={pay_matrial}
                alt={process.env.REACT_APP_NAMECOMPANY}
                style={{
                  objectFit: "cover",
                }}
                onClick={() => {
                  setisFits(true)
                }}
                className="avatar-md rounded-circle img-thumbnail"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModal_checkPayMaterial(false)
            }}
            className="btn btn-danger "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        size="xl"
        centered
        isOpen={modal_boqFavorite}
        toggle={() => {
          setModal_boqFavorite(!modal_boqFavorite)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            รายการ Favorite งาน
          </h5>

          <button
            type="button"
            onClick={() => {
              setModal_boqFavorite(!modal_boqFavorite)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Table
            responsive
            className="project-list-table table-nowrap align-middle table-bordered"
          >
            <thead>
              <tr>
                <th style={{ width: "10%", textAlign: "center" }}></th>

                {/* <th scope="col" style={{ width: "10px" }}>
                  โปรเจค
                </th> */}

                <th style={{ width: "80%" }}>ชื่อ</th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              {(boqTitleFavorite || []).map((v, k) => (
                <tr key={k}>
                  <td style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Button
                      color="info"
                      outline
                      onClick={e => {
                        selectOnBoq_title_detail(v.boq_title_detail)
                      }}
                    >
                      เลือก
                    </Button>
                  </td>
                  {/* <td>{isEmpty(v.project) == true ? "" : v.project.name}</td> */}
                  <td style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Row>
                      <Col sm="10">
                        <Editable
                          id={"favorite_name" + v.id}
                          initialValue={v.favorite_name}
                          isValueClickable
                          mode="inline"
                          onSubmit={e => {
                            let edit = [...boqTitleFavorite]
                            edit[k].favorite_name = e
                            setBoqTitleFavorite(edit)
                            // console.log(boqTitleFavorite);
                            handleSubmitBoqTitleHome("UpdateFa", null, edit[k])
                          }}
                          placement="top"
                          type="textfield"
                        />
                        {/* {v.favorite_name} */}
                      </Col>
                      <Col sm="2" style={{ textAlign: "right" }}>
                        {openDetail == v.id ? (
                          <i
                            onClick={e => {
                              // console.log(v);
                              setOpenDetail(openDetail == v.id ? null : v.id)
                            }}
                            className="bx bx-caret-up"
                            style={{
                              cursor: "pointer",
                            }}
                          ></i>
                        ) : (
                          <i
                            onClick={e => {
                              // console.log(v);
                              setOpenDetail(openDetail == v.id ? null : v.id)
                            }}
                            className="bx bx-caret-down"
                            style={{
                              cursor: "pointer",
                            }}
                          ></i>
                        )}
                      </Col>
                    </Row>

                    <Collapse isOpen={openDetail == v.id ? true : false}>
                      <hr />
                      {(v.boq_title_detail || []).map((j, i) => (
                        <p key={i}>{j.list_structure_name}</p>
                      ))}
                    </Collapse>
                  </td>
                  <td style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Button
                      color="danger"
                      outline
                      onClick={e => {
                        handleSubmitBoqTitleHome("Del", v.id)
                      }}
                    >
                      ลบ
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModal_boqFavorite(!modal_boqFavorite)
            }}
            className="btn btn-danger "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        size="xl"
        centered
        isOpen={modal_boqMaterialFavorite}
        toggle={() => {
          setModal_boqMaterialFavorite(!modal_boqMaterialFavorite)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            รายการ Favorite วัสดุ
          </h5>

          <button
            type="button"
            onClick={() => {
              setModal_boqMaterialFavorite(!modal_boqMaterialFavorite)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Table
            responsive
            className="project-list-table table-nowrap align-middle table-bordered"
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{ width: "10%", textAlign: "center" }}
                ></th>

                {/* <th scope="col" style={{ width: "30px" }}>
                  โปรเจค
                </th> */}
                <th scope="col" style={{ width: "30%" }}>
                  หมวดงาน
                </th>
                <th scope="col" style={{ width: "50%" }}>
                  ชื่อ
                </th>
                <th scope="col" style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              {(boqMaterialFavorite || []).map((v, k) => (
                <tr key={k}>
                  <td>
                    <Button
                      color="info"
                      outline
                      onClick={e => {
                        selectOnBoq(v.boqs)
                      }}
                    >
                      เลือก
                    </Button>
                  </td>
                  <td>{v.list_structure_name}</td>
                  <td>
                    <div>
                      <Row>
                        <Col sm="10">
                          <Editable
                            id={"favorite_name" + v.id}
                            initialValue={v.favorite_name}
                            isValueClickable
                            mode="inline"
                            onSubmit={e => {
                              let edit = [...boqMaterialFavorite]
                              edit[k].favorite_name = e
                              setBoqMaterial(edit)
                              // console.log(boqMaterialFavorite);
                              handleSubmitBoqMaterials(edit[k], "UpdateFa")
                            }}
                            placement="top"
                            type="textfield"
                          />
                        </Col>
                        <Col sm="2" style={{ textAlign: "right" }}>
                          {openDetail == v.id ? (
                            <i
                              onClick={e => {
                                // console.log(v);
                                setOpenDetail(openDetail == v.id ? null : v.id)
                              }}
                              className="bx bx-caret-up"
                              style={{
                                cursor: "pointer",
                              }}
                            ></i>
                          ) : (
                            <i
                              onClick={e => {
                                // console.log(v);
                                setOpenDetail(openDetail == v.id ? null : v.id)
                              }}
                              className="bx bx-caret-down"
                              style={{
                                cursor: "pointer",
                              }}
                            ></i>
                          )}
                        </Col>
                      </Row>

                      <Collapse isOpen={openDetail == v.id ? true : false}>
                        <hr />
                        {(v.boqs || []).map((j, i) => (
                          <p key={i}>
                            {j.material_name} ({j.detail})
                          </p>
                        ))}
                      </Collapse>
                    </div>
                  </td>
                  {/* <td>
                    
                  </td> */}
                  <td>
                    <Button
                      color="danger"
                      outline
                      onClick={e => {
                        handleSubmitBoqMaterials(v.id, "del")
                      }}
                    >
                      ลบ
                    </Button>
                  </td>
                  {/* <td>{isEmpty(v.project) == true ? "" : v.project.name}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModal_boqMaterialFavorite(!modal_boqMaterialFavorite)
            }}
            className="btn btn-danger "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        size="xl"
        centered
        isOpen={modal_listStructures}
        toggle={() => {
          setModal_listStructures(!modal_listStructures)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            รายการ Boq
          </h5>

          <button
            type="button"
            onClick={() => {
              setModal_listStructures(!modal_listStructures)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Table
            responsive
            className="project-list-table table-nowrap align-middle table-bordered"
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  style={{ width: "10%", textAlign: "center", border: 0 }}
                ></th>
                <th scope="col" style={{ width: "80%" }}>
                  รายการ
                </th>
                <th
                  scope="col"
                  style={{ width: "10%", textAlign: "center" }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {(listStructures || []).map((v, k) => (
                <tr key={k}>
                  {v.value == 0 ? null : (
                    <td>
                      <Button
                        color="info"
                        outline
                        onClick={e => {
                          // console.log(v)
                          const modifiedRows = [
                            ...dataBoqTitleHomeProfile.boq_title_detail,
                          ]
                          modifiedRows.push({
                            boq_title_id: v.boq_title_id,
                            list_structure_name: v.label,
                            list_structure_persen: v.list_structure_persen,
                            list_structure_price: v.list_structure_price,
                            list_structure_pay: 0,
                            check_pay: v.check_pay,
                            del: v.del,
                            disabled: true,
                          })
                          // setDataBoqTitleHomeProfile({
                          //   ...dataBoqTitleHomeProfile,
                          //   boq_title_detail: modifiedRows,
                          // })
                          handleSubmitBoqTitleHome(
                            "selectUpDate",
                            null,
                            undefined,
                            modifiedRows
                          )
                          setModal_listStructures(!modal_listStructures)
                        }}
                      >
                        เลือก
                      </Button>
                    </td>
                  )}

                  {v.value == 0 ? (
                    <td
                      colSpan={3}
                      style={{ textAlign: "center", color: "#556ee6" }}
                      onClick={e => {
                        setActionModalListStructure({
                          action: "Add",
                          modal: true,
                        })
                      }}
                    >
                      {v.label}
                    </td>
                  ) : (
                    <>
                      <td>
                        <Editable
                          id={"listStructures" + v.value}
                          initialValue={v.label}
                          isValueClickable
                          mode="inline"
                          onSubmit={e => {
                            const data = new FormData()
                            data.append(
                              "formData",
                              JSON.stringify({
                                id: v.value,
                                name: e,
                              })
                            )

                            dispatch(onUpdateListStructure(data))
                            setTimeout(() => {
                              dispatch(onGetList_structures(1, 0))
                            }, 1000)
                          }}
                          placement="top"
                          type="textfield"
                        />
                      </td>

                      <td>
                        <Button
                          color="danger"
                          outline
                          onClick={e => {
                            const data = new FormData()
                            data.append(
                              "formData",
                              JSON.stringify({
                                id: v.value,
                                active: 2,
                              })
                            )

                            dispatch(onUpdateListStructure(data))
                            setTimeout(() => {
                              dispatch(onGetList_structures(1, 0))
                            }, 1000)
                          }}
                        >
                          ลบ
                        </Button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setModal_listStructures(!modal_listStructures)
            }}
            className="btn btn-danger "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        style={{ maxWidth: "90%", zIndex: "0 important" }}
        size="lg"
        centered
        isOpen={modal_fullscreen}
        toggle={() => {
          setmodal_fullscreen(!modal_fullscreen)
        }}
      >
        {!isEmpty(dataBoqTitleHomeProfile.project) && (
          <>
            {/* <AvForm
  onSubmit={handleSubmitBoqTitleHome}
  model={dataBoqTitleHomeProfile}
> */}
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {dataBoqTitleHomeProfile.project.name} &nbsp;/&nbsp;งาน&nbsp;
                {dataBoqTitleHomeProfile.quotation_list_name}&nbsp;
                {list_structure_name == null ? "" : list_structure_name.name}
              </h5>

              <button
                type="button"
                onClick={() => {
                  setmodal_fullscreen(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col sm="12" md="6">
                  <InputGroup>
                    <InputGroupText style={{ width: "250px" }}>
                      ช่างผู้รับผิดชอบงาน
                    </InputGroupText>
                    <div style={{ width: "355px" }}>
                      <Select
                        styles={selectColorContractor}
                        name={"contractor_id"}
                        value={contractor.filter(function (option) {
                          // console.log(option)
                          return (
                            option.value ==
                            dataBoqTitleHomeProfile.contractor_id
                          )
                        })}
                        onChange={e => {
                          // console.log(e)
                          if (e.value == 0) {
                            setDataBoqTitleHomeProfile({
                              ...dataBoqTitleHomeProfile,
                              contractor_id: null,
                              name_contractor: null,
                              lastname_contractor: null,
                              idcard_contractor: null,
                              number_phone_contractor: null,
                              bank_contractor: null,
                              bank_no_contractor: null,
                              address_contractor: null,
                            })
                            setActionModalContractor({
                              action: "Add",
                              modal: true,
                            })
                          } else if (e.value == "del") {
                            setDataBoqTitleHomeProfile({
                              ...dataBoqTitleHomeProfile,
                              contractor_id: null,
                              name_contractor: null,
                              lastname_contractor: null,
                              idcard_contractor: null,
                              number_phone_contractor: null,
                              bank_contractor: null,
                              bank_no_contractor: null,
                              address_contractor: null,
                            })
                          } else {
                            setDataBoqTitleHomeProfile({
                              ...dataBoqTitleHomeProfile,
                              contractor_id: e.value,
                              name_contractor: e.label,
                              lastname_contractor: e.lastname,
                              idcard_contractor: e.card_number,
                              number_phone_contractor: e.number_phone,
                              bank_contractor: e.bank,
                              bank_no_contractor: e.bank_no,
                              address_contractor: e.address,
                            })
                          }
                        }}
                        placeholder="กรุณาเลือกช่าง"
                        options={contractor}
                      />
                    </div>
                  </InputGroup>
                  <InputGroup className="mt-2">
                    <InputGroupText style={{ width: "150px" }}>
                      ค่างานเต็ม
                    </InputGroupText>

                    <Input
                      aria-label="งาน"
                      type="number"
                      className="form-control"
                      placeholder="ค่างานเต็ม"
                      value={dataBoqTitleHomeProfile.quotation_list_price}
                      onChange={e => {
                        // cal_structure(e.target.value)
                        let temp = dataBoqTitleHomeProfile.boq_title_detail
                        for (let index = 0; index < temp.length; index++) {
                          temp[index].list_structure_price = parseFloat(
                            parseFloat(
                              parseFloat(
                                parseFloat(
                                  dataBoqTitleHomeProfile.boq_title_persen / 100
                                ) * parseFloat(e.target.value)
                              ) / 100
                            ) * parseFloat(temp[index].list_structure_persen)
                          )
                        }

                        setDataBoqTitleHomeProfile({
                          ...dataBoqTitleHomeProfile,
                          quotation_list_price: e.target.value,
                          boq_title_detail: temp,
                        })
                      }}
                      name={"quotation_list_price"}
                    />

                    <InputGroupText style={{ width: "150px" }}>
                      เบิกไปแล้ว
                    </InputGroupText>

                    <Input
                      aria-label="งาน"
                      type="number"
                      className="form-control"
                      placeholder="เบิกไปแล้ว"
                      value={dataBoqTitleHomeProfile.quotation_list_price_pay}
                      onChange={e => {
                        setDataBoqTitleHomeProfile({
                          ...dataBoqTitleHomeProfile,
                          quotation_list_price_pay: e.target.value,
                        })
                      }}
                      name={"quotation_list_price_pay"}
                    />
                  </InputGroup>
                </Col>

                <Col sm="12" md="6">
                  <InputGroup>
                    <InputGroupText style={{ width: "120px" }}>
                      % งาน ({dataBoqTitleHomeProfile.boq_title_persen})
                    </InputGroupText>
                    <Input
                      aria-label="งาน"
                      type="number"
                      className="form-control"
                      placeholder="กรุณาใส่ % งานให้ผู้รับเหมา"
                      value={dataBoqTitleHomeProfile.boq_title_persen}
                      onChange={e => {
                        // cal_structure(e.target.value)
                        let temp = dataBoqTitleHomeProfile.boq_title_detail
                        for (let index = 0; index < temp.length; index++) {
                          temp[index].list_structure_price = parseFloat(
                            parseFloat(
                              parseFloat(
                                parseFloat(
                                  dataBoqTitleHomeProfile.quotation_list_price /
                                    100
                                ) * parseFloat(e.target.value)
                              ) / 100
                            ) * parseFloat(temp[index].list_structure_persen)
                          )
                        }

                        setDataBoqTitleHomeProfile({
                          ...dataBoqTitleHomeProfile,
                          boq_title_persen: e.target.value,
                          boq_title_detail: temp,
                        })
                      }}
                      name={"boq_title_persen"}
                    />
                    <InputGroupText style={{ width: "180px" }}>
                      ค่างานที่ช่างได้:&nbsp;
                      {(
                        Math.floor(
                          parseFloat(
                            parseFloat(
                              dataBoqTitleHomeProfile.quotation_list_price / 100
                            ) * dataBoqTitleHomeProfile.boq_title_persen
                          ) * 100
                        ) / 100
                      ).toLocaleString("th-TH")}
                    </InputGroupText>
                  </InputGroup>
                  <InputGroup
                    className="mt-2"
                    style={{
                      justifyContent: "right",
                    }}
                  >
                    <Button
                      color={
                        dataBoqTitleHomeProfile.del == 0 ? "danger" : "primary"
                      }
                      onClick={e => {
                        setDataBoqTitleHomeProfile({
                          ...dataBoqTitleHomeProfile,
                          del: dataBoqTitleHomeProfile.del == 0 ? 1 : 0,
                        })
                      }}
                    >
                      {dataBoqTitleHomeProfile.del == 0 ? "ลบ" : "เรียกคืน"}
                    </Button>
                  </InputGroup>
                </Col>

                {/* <Col sm="6" md="4">
                  <InputGroup>
                    <InputGroupText style={{ width: "180px" }}>
                      ค่างานที่ช่างได้:{" "}
                      {parseFloat(
                        dataBoqTitleHomeProfile.quotation_list_price_mechanic
                      ).toLocaleString("th-TH")}
                    </InputGroupText>

                    <Input
                      type="number"
                      className="form-control"
                      placeholder="กรุณาใส่ค่าแรงของช่าง"
                      value={
                        dataBoqTitleHomeProfile.quotation_list_price_mechanic
                      }
                      onChange={e => {
                        setDataBoqTitleHomeProfile({
                          ...dataBoqTitleHomeProfile,
                          quotation_list_price_mechanic: e.target.value,
                        })
                      }}
                      name={"quotation_list_price_mechanic"}
                    />
                  </InputGroup>
                </Col> */}
              </Row>
              <hr />

              <div className="crypto-buy-sell-nav">
                <Nav tabs className="nav-tabs-custom" role="tablist">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggleTab("1")
                      }}
                    >
                      หมวดงาน
                    </NavLink>
                  </NavItem>
                  {list_structure_name == null ? null : (
                    <>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2")
                          }}
                        >
                          วัสดุ
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "3",
                          })}
                          onClick={() => {
                            toggleTab("3")
                          }}
                        >
                          ภาพส่งของ
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "4",
                          })}
                          onClick={() => {
                            toggleTab("4")
                          }}
                        >
                          รวมสลิปค่าแรง
                        </NavLink>
                      </NavItem>
                    </>
                  )}
                </Nav>

                <TabContent
                  activeTab={activeTab}
                  className="crypto-buy-sell-nav-content p-4"
                >
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="6">
                        {/* <Input
                  type="text"
                  className="form-control"
                  placeholder="Name favorite งาน"
                  value={dataBoqTitleHomeProfile.favorite_name}
                  onChange={e => {
                    setDataBoqTitleHomeProfile({
                      ...dataBoqTitleHomeProfile,
                      favorite_name: e.target.value,
                    })
                  }}
                  name={"favorite_name"}
                /> */}
                      </Col>
                      <Col sm="6" style={{ textAlign: "right" }}>
                        {/* <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                        >
                          ปริ้น
                        </Button> */}
                      </Col>
                    </Row>

                    <List
                      values={dataBoqTitleHomeProfile.boq_title_detail}
                      onChange={({ oldIndex, newIndex }) => {
                        let edit = [...dataBoqTitleHomeProfile.boq_title_detail]
                        // console.log("oldIndex", edit[oldIndex])
                        // console.log("newIndex", edit[newIndex])

                        edit[oldIndex].index_key = newIndex
                        edit[newIndex].index_key = oldIndex

                        // console.log("edit", edit)
                        setDataBoqTitleHomeProfile({
                          ...dataBoqTitleHomeProfile,
                          boq_title_detail: edit,
                        })

                        setDataBoqTitleHomeProfile({
                          ...dataBoqTitleHomeProfile,
                          boq_title_detail: arrayMove(
                            dataBoqTitleHomeProfile.boq_title_detail,
                            oldIndex,
                            newIndex
                          ),
                        })
                      }}
                      renderList={({ children, props }) => (
                        <Table
                          responsive
                          className="project-list-table table-nowrap align-middle table-bordered"
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  width: "10px",
                                  cursor: "pointer",
                                  textAlign: "center",
                                  color: "#556ee6",
                                }}
                                onClick={e => {
                                  let edit = [
                                    ...dataBoqTitleHomeProfile.boq_title_detail,
                                  ]
                                  for (
                                    let index = 0;
                                    index < edit.length;
                                    index++
                                  ) {
                                    edit[index].del =
                                      edit[index].del == 0 ? 1 : 0

                                    edit[index].disabled =
                                      edit[index].disabled == true
                                        ? false
                                        : true
                                  }
                                  setDataBoqTitleHomeProfile({
                                    ...dataBoqTitleHomeProfile,
                                    boq_title_detail: edit,
                                  })
                                }}
                              >
                                เลือกทั้งหมด
                              </th>

                              <th scope="col">
                                <Row>
                                  <Col sm="8">หมวดงาน / % งาน</Col>
                                  <Col sm="4" style={{ textAlign: "right" }}>
                                    หมายเหตุ
                                  </Col>
                                </Row>
                              </th>
                              <th scope="col" style={{ textAlign: "right" }}>
                                ค่างาน
                              </th>

                              <th scope="col" style={{ textAlign: "right" }}>
                                เบิกแล้ว
                              </th>
                            </tr>
                          </thead>
                          <tbody {...props}>{children}</tbody>
                        </Table>
                      )}
                      renderItem={({ value, props, isDragged }) => (
                        <tr {...props}>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                            onClick={e => {
                              if (value.id != null) {
                                let getkey = { ...props }
                                let edit = [
                                  ...dataBoqTitleHomeProfile.boq_title_detail,
                                ]
                                edit[getkey.key].del = value.del == 1 ? 0 : 1
                                edit[getkey.key].disabled =
                                  value.disabled == true ? false : true

                                setDataBoqTitleHomeProfile({
                                  ...dataBoqTitleHomeProfile,
                                  boq_title_detail: edit,
                                })
                              }
                            }}
                          >
                            {value.id == null ? null : value.del == 1 ? (
                              <i
                                className="bx bx bx-comment-error"
                                style={{
                                  fontSize: "16px",
                                  color: "#f46a6a",
                                }}
                              ></i>
                            ) : (
                              <i
                                className="bx bx-credit-card-front"
                                style={{
                                  fontSize: "16px",
                                }}
                              ></i>
                            )}
                          </td>

                          <td>
                            <Row>
                              <Col sm="8">
                                <div className="d-flex">
                                  {value.id == null ? (
                                    <span style={{ color: "#f46a6a" }}>
                                      {value.list_structure_name}&nbsp;(
                                      กรุณาบันทึกก่อน )
                                    </span>
                                  ) : (
                                    <span
                                      style={{ color: "#556ee6" }}
                                      onClick={e => {
                                        selecOnList_structure(
                                          value.id,
                                          value.list_structure_name,
                                          value.img,
                                          value.img_path
                                        )
                                      }}
                                    >
                                      {value.list_structure_name}
                                    </span>
                                  )}
                                  &nbsp;/
                                  <span style={{ color: "#556ee6" }}>
                                    <Editable
                                      id={"list_structure_persen" + value.id}
                                      initialValue={value.list_structure_persen}
                                      isValueClickable
                                      mode="inline"
                                      onSubmit={e => {
                                        let getkey = { ...props }

                                        let edit = [
                                          ...dataBoqTitleHomeProfile.boq_title_detail,
                                        ]
                                        edit[getkey.key].list_structure_persen =
                                          e
                                        edit[getkey.key].list_structure_price =
                                          parseFloat(
                                            parseFloat(
                                              parseFloat(
                                                parseFloat(
                                                  dataBoqTitleHomeProfile.quotation_list_price /
                                                    100
                                                ) *
                                                  parseFloat(
                                                    dataBoqTitleHomeProfile.boq_title_persen
                                                  )
                                              ) / 100
                                            ) * parseFloat(e)
                                          )

                                        setDataBoqTitleHomeProfile({
                                          ...dataBoqTitleHomeProfile,

                                          quotation_list_price_mechanic:
                                            dataBoqTitleHomeProfile.boq_title_detail.reduce(
                                              function (a, b) {
                                                // console.log(b)
                                                return (
                                                  a +
                                                  parseFloat(
                                                    parseFloat(
                                                      parseFloat(
                                                        parseFloat(
                                                          dataBoqTitleHomeProfile.quotation_list_price /
                                                            100
                                                        ) *
                                                          parseFloat(
                                                            dataBoqTitleHomeProfile.boq_title_persen
                                                          )
                                                      ) / 100
                                                    ) *
                                                      parseFloat(
                                                        b.list_structure_persen
                                                      )
                                                  )
                                                )
                                              },
                                              0
                                            ),
                                          boq_title_detail: edit,
                                        })
                                      }}
                                      placement="top"
                                      type="textfield"
                                    />
                                  </span>
                                  %
                                </div>
                              </Col>
                              <Col sm="4" style={{ textAlign: "right" }}>
                                {value.id == null ? null : (
                                  <i
                                    style={{
                                      color: "#556ee6",
                                    }}
                                    onClick={e => {
                                      let getkey = { ...props }
                                      setKey_boq_title_detail(getkey.key)
                                      setModal_noteListStruck(
                                        !modal_noteListStruck
                                      )
                                      // let edit = [
                                      //   ...dataBoqTitleHomeProfile.boq_title_detail,
                                      // ]
                                      // edit[getkey.key].note = e

                                      // setDataBoqTitleHomeProfile({
                                      //   ...dataBoqTitleHomeProfile,
                                      //   boq_title_detail: edit,
                                      // })
                                    }}
                                    className="mdi mdi-dots-horizontal"
                                  ></i>
                                )}
                              </Col>
                            </Row>
                          </td>

                          <td>
                            {value.id == null ? null : (
                              <Row>
                                <Col sm="6">
                                  {value.del == 1 ? (
                                    <span
                                      data-movable-handle
                                      style={{
                                        cursor: "pointer",
                                        color: "#556ee6",
                                      }}
                                    >
                                      <i className="bx bx-up-arrow-alt" />
                                      <i className="bx bx-down-arrow-alt" />
                                    </span>
                                  ) : null}
                                </Col>
                                <Col sm="6" style={{ textAlign: "right" }}>
                                  {parseFloat(
                                    value.list_structure_price
                                  ).toLocaleString("th-TH")}
                                </Col>
                              </Row>
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {value.id == null ? null : (
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: "#556ee6",
                                }}
                                onClick={() => {
                                  selecOnList_structure(
                                    value.id,
                                    value.list_structure_name,
                                    value.img,
                                    value.img_path
                                  )
                                  toggleTab("4")
                                }}
                              >
                                {parseFloat(
                                  value.list_structure_pay
                                ).toLocaleString("th-TH")}
                              </span>
                            )}
                          </td>
                        </tr>
                      )}
                    />

                    <Row className="mt-2">
                      <Col sm="6">
                        {selectEvent == true ? (
                          <div className="d-flex">
                            <button
                              name="deleteSelectEvent"
                              onClick={e => {
                                handleSubmitBoqTitleHome("DelAndUp", null)
                              }}
                              className="btn btn-danger "
                            >
                              ลบ
                            </button>
                          </div>
                        ) : null}
                      </Col>
                      <Col sm="6" style={{ textAlign: "right" }}>
                        <Button
                          color="primary"
                          type="button"
                          onClick={e => {
                            setModal_listStructures(!modal_listStructures)
                          }}
                          outline
                        >
                          <i className="bx bx-plus"></i> เพิ่มหมวดงาน
                        </Button>
                      </Col>

                      <Col
                        sm="12"
                        style={{ textAlign: "right", marginTop: 20 }}
                      >
                        <p>
                          ให้ค่าแรงรวม: &nbsp;
                          <span style={{ color: "#556ee6" }}>
                            {dataBoqTitleHomeProfile.boq_title_detail.reduce(
                              function (a, b) {
                                return a + parseFloat(b.list_structure_persen)
                              },
                              0
                            )}
                          </span>
                          % ของงาน
                        </p>
                      </Col>

                      <Col sm="12" style={{ textAlign: "right" }}>
                        <p>
                          รวมค่าแรง (บ.): &nbsp;
                          <span style={{ color: "#556ee6" }}>
                            {dataBoqTitleHomeProfile.boq_title_detail
                              .reduce(function (a, b) {
                                // console.log(b)

                                return (
                                  a +
                                  Math.floor(
                                    parseFloat(
                                      parseFloat(
                                        parseFloat(
                                          parseFloat(
                                            dataBoqTitleHomeProfile.quotation_list_price /
                                              100
                                          ) *
                                            parseFloat(
                                              dataBoqTitleHomeProfile.boq_title_persen
                                            )
                                        ) / 100
                                      ) * parseFloat(b.list_structure_persen)
                                    ) * 100
                                  ) /
                                    100
                                )
                              }, 0)
                              .toLocaleString("th-TH")}
                          </span>
                        </p>
                      </Col>

                      <Col sm="12" style={{ textAlign: "right" }}>
                        <p>
                          รวมยอดเบิก: &nbsp;
                          <span style={{ color: "#556ee6" }}>
                            {dataBoqTitleHomeProfile.boq_title_detail
                              .reduce(function (a, b) {
                                return a + parseFloat(b.list_structure_pay)
                              }, 0)
                              .toLocaleString("th-TH")}
                          </span>
                        </p>
                      </Col>

                      <Col sm="12" style={{ textAlign: "right" }}>
                        <p>
                          ค่าวัสดุ (บ.): &nbsp;
                          <span style={{ color: "#556ee6" }}>0</span>
                        </p>
                      </Col>

                      <Col sm="6" md="6">
                        <Button
                          type="button"
                          color="primary"
                          outline
                          onClick={e => {
                            setModal_boqFavorite(!modal_boqFavorite)
                            setOpenDetail(null)
                          }}
                        >
                          List Favorite
                        </Button>
                        &nbsp;
                        {/* <Button
                          color="primary"
                          outline
                          type="button"
                          onClick={e => {
                            setModal_favoriteListStruck(
                              !modal_favoriteListStruck
                            )
                            // setDataBoqTitleHomeProfile({
                            //   ...dataBoqTitleHomeProfile,
                            //   favorite: e.target.value,
                          }}
                        >
                          Save Favorite งาน
                        </Button> */}
                        {dataBoqTitleHomeProfile.favorite == 1 ? (
                          <Button
                            color="danger"
                            outline
                            type="button"
                            value={0}
                            onClick={e => {
                              setDataBoqTitleHomeProfile({
                                ...dataBoqTitleHomeProfile,
                                favorite: 0,
                              })
                              setOpenDetail(null)
                              handleSubmitBoqTitleHome("Edit", null)
                            }}
                          >
                            Cancel Favorite งาน
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            outline
                            type="button"
                            onClick={e => {
                              setOpenDetail(null)
                              setModal_favoriteListStruck(
                                !modal_favoriteListStruck
                              )
                              // setDataBoqTitleHomeProfile({
                              //   ...dataBoqTitleHomeProfile,
                              //   favorite: e.target.value,
                            }}
                          >
                            Save Favorite งาน
                          </Button>
                        )}
                      </Col>

                      {/* <Col sm="6" md="6" style={{ textAlign: "right" }}>
                 <Button
                   color="primary"
                   type="button"
                   onClick={e => {
                     handleSubmitBoqTitleHome("Edit",null)
                   }}
                   className="font-16 btn-block btn btn-primary"
                 >
                   Save change
                 </Button>
               </Col> */}
                    </Row>
                  </TabPane>

                  <TabPane tabId="2">
                    <Row>
                      <Col sm="6">
                        <Select
                          type="select"
                          className="react-select-container"
                          classNamePrefix="react-select"
                          name="material_type_id"
                          onChange={e => {
                            if (e.value == 0) {
                              setBoqMaterial(boqs.boq)
                            } else {
                              // boqs.boq.filter((v, k) => console.log(v))
                              setBoqMaterial(
                                boqs.boq.filter(
                                  (v, k) => e.value == v.material_id
                                  // (v, k) => {
                                  //   console.log("v", v)
                                  //   console.log("e", e)
                                  // }
                                  // isEmpty(v.material_id)
                                  //   ? null
                                  //   : v.material_id == e.value
                                )
                              )
                            }
                          }}
                          options={groups.map((item, i) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                        />
                      </Col>
                      <Col sm="6" style={{ textAlign: "right" }}>
                        {/* <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                        >
                          ปริ้น
                        </Button> */}
                      </Col>
                    </Row>
                    <List
                      values={boqMaterials}
                      onChange={({ oldIndex, newIndex }) => {
                        let edit = [...boqMaterials]
                        edit[oldIndex].index_key = newIndex
                        edit[newIndex].index_key = oldIndex
                        setBoqMaterial(edit)
                        // setTimeout(() => {
                        setBoqMaterial(
                          arrayMove(boqMaterials, oldIndex, newIndex)
                        )
                        // }, 1000)
                      }}
                      renderList={({ children, props }) => (
                        <Table
                          responsive
                          className="project-list-table table-nowrap align-middle table-bordered"
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  width: "10px",
                                  cursor: "pointer",
                                  textAlign: "center",
                                  color: "#556ee6",
                                }}
                                onClick={e => {
                                  let edit = [...boqMaterials]
                                  for (
                                    let index = 0;
                                    index < edit.length;
                                    index++
                                  ) {
                                    edit[index].del =
                                      edit[index].del == 0 ? 1 : 0

                                    edit[index].disabled =
                                      edit[index].disabled == true
                                        ? false
                                        : true
                                  }
                                  setBoqMaterial(edit)
                                }}
                              >
                                เลือกทั้งหมด
                              </th>

                              <th>รายการ</th>
                              <th>ประเภท</th>
                              <th>ชื่อวัสดุ</th>
                              <th
                                style={{
                                  width: 120,
                                  marginLeft: 10,
                                  textAlign: "center",
                                }}
                              >
                                ใช้
                              </th>
                              <td style={{ width: 80, border: 0 }}></td>

                              {/* <th
                                scope="col"
                                style={{
                                  width: "10px",
                                  cursor: "pointer",
                                  textAlign: "center",
                                  color: "#556ee6",
                                }}
                                onClick={e => {
                                  let edit = [...boqMaterials]
                                  for (
                                    let index = 0;
                                    index < edit.length;
                                    index++
                                  ) {
                                    edit[index].check_pay =
                                      edit[index].check_pay == 0 ? 1 : 0
                                  }
                                  setBoqMaterial(edit)
                                }}
                              >
                                สั่งจ่ายทั้งหมด
                              </th> */}
                              <th style={{ textAlign: "right", width: 100 }}>
                                จ่ายวัสดุแล้ว
                              </th>
                            </tr>
                          </thead>
                          <tbody {...props}>{children}</tbody>
                        </Table>
                      )}
                      renderItem={({ value, props, isDragged }) => (
                        <tr {...props}>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                            onClick={e => {
                              let getkey = { ...props }
                              let edit = [...boqMaterials]
                              edit[getkey.key].del = value.del == 1 ? 0 : 1
                              edit[getkey.key].disabled =
                                value.disabled == true ? false : true

                              setBoqMaterial(edit)
                            }}
                          >
                            {value.del == 1 ? (
                              <i
                                className="bx bx bx-comment-error"
                                style={{
                                  fontSize: "16px",
                                  color: "#f46a6a",
                                }}
                              ></i>
                            ) : (
                              <i
                                className="bx bx-credit-card-front"
                                style={{
                                  fontSize: "16px",
                                }}
                              ></i>
                            )}
                          </td>

                          <td>
                            <Input
                              name={"material_detail"}
                              type="text"
                              style={{
                                border: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                              }}
                              className="form-control"
                              placeholder="ใส่หัวข้อ"
                              value={value.detail || ""}
                              onChange={e => {
                                let getkey = { ...props }

                                let modifiedRows = [...boqMaterials]
                                modifiedRows[getkey.key].detail = e.target.value
                                setBoqMaterial(modifiedRows)
                              }}
                            />
                          </td>
                          <td>
                            <span>
                              {!isEmpty(value.material) &&
                                !isEmpty(value.material.material_type) &&
                                value.material.material_type.name}
                            </span>
                          </td>

                          <td>
                            <span
                              onClick={e => {
                                let getkey = { ...props }

                                setBoqMaterials_index(getkey.key)
                                setModal_materialStock(!modal_materialStock)
                              }}
                              style={{ color: "#556ee6" }}
                            >
                              {value.material_name}
                            </span>
                          </td>

                          <td style={{ textAlign: "right" }}>
                            <Input
                              style={{
                                border: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                                textAlign: "right",
                              }}
                              name={"material_qta"}
                              type="number"
                              className="form-control"
                              placeholder="จำนวน"
                              value={value.qta || ""}
                              onChange={e => {
                                let getkey = { ...props }
                                let modifiedRows = [...boqMaterials]
                                modifiedRows[getkey.key].qta = e.target.value
                                setBoqMaterial(modifiedRows)
                              }}
                            />
                          </td>
                          <td style={{ border: 0, textAlign: "center" }}>
                            {value.del == 1 ? (
                              <span
                                data-movable-handle
                                style={{
                                  cursor: "pointer",
                                  color: "#556ee6",
                                }}
                              >
                                <i className="bx bx-up-arrow-alt" />
                                <i className="bx bx-down-arrow-alt" />
                              </span>
                            ) : null}
                          </td>
                          {/* <td
                            style={{ textAlign: "center" }}
                            onClick={e => {
                              let getkey = { ...props }
                              let edit = [...boqMaterials]
                              edit[getkey.key].check_pay =
                                value.check_pay == 0 ? 1 : 0
                              setBoqMaterial(edit)
                            }}
                          >
                            {value.check_pay == 0 ? (
                              <i
                                style={{ color: "#f46a6a" }}
                                className="bx bx-x"
                              ></i>
                            ) : (
                              <i
                                style={{ color: "#556ee6" }}
                                className="bx bx-check-circle"
                              ></i>
                            )}
                          </td> */}
                          <td style={{ textAlign: "right", color: "#556ee6" }}>
                            <Input
                              name={"pay"}
                              type="text"
                              style={{
                                border: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                                textAlign: "right",
                              }}
                              className="form-control"
                              placeholder="จำนวน"
                              value={value.pay || ""}
                              onChange={e => {
                                let getkey = { ...props }

                                let modifiedRows = [...boqMaterials]
                                modifiedRows[getkey.key].pay = e.target.value
                                setBoqMaterial(modifiedRows)
                              }}
                            />
                          </td>
                        </tr>
                      )}
                    />

                    <Row className="mt-2">
                      <Col sm="6">
                        {selectEventBoq == true ? (
                          <div className="d-flex">
                            <button
                              name="deleteSelectEventBoq"
                              onClick={e => {
                                handleSubmitBoqMaterials(null, "del")
                              }}
                              className="btn btn-danger "
                            >
                              ลบ
                            </button>
                          </div>
                        ) : null}
                      </Col>
                      <Col sm="6" style={{ textAlign: "right" }}>
                        <Button
                          onClick={
                            e =>
                              setBoqMaterial(prevArray => [
                                ...prevArray,
                                {
                                  boq_title_detail_id: list_structure_name.id,
                                  material_id: null,
                                  material_type_id: null,
                                  material_name: "คลิกเพื่อเปลื่ยน",
                                  material_type: null,
                                  unit: null,
                                  detail: "รายการ",
                                  qta: 0,
                                  pay: 0,
                                  disabled: true,
                                  del: 0,
                                  qta_sum_price: 0,
                                  check_pay: 0,
                                },
                              ])
                            // setModal_materialStock(!modal_materialStock)
                          }
                          type="button"
                          color="primary"
                          outline
                        >
                          <i className="bx bx-plus"></i> เพิ่มวัสดุ
                        </Button>
                      </Col>
                      {/* <Col sm="12" className="mb-2">
                        <Label className="form-label">Note</Label>
                        <textarea
                          onChange={
                            // e => console.log(e.target.value)
                            e =>
                              setDataBoq_title_detail({
                                ...dataBoq_title_detail,
                                note: e.target.value,
                              })
                          }
                          className="input-large form-control"
                          id="note"
                          rows="3"
                          value={dataBoq_title_detail.note}
                          placeholder="กรุณาใส่หมายเหตุที่ต้องขอ"
                        />
                      </Col> */}
                      <Col sm="6" md="6">
                        <Button
                          color="primary"
                          outline
                          onClick={e => {
                            setModal_boqMaterialFavorite(
                              !modal_boqMaterialFavorite
                            )
                            setOpenDetail(null)
                          }}
                        >
                          List Favorite
                        </Button>
                        &emsp;
                        {/* <Button
                          color="primary"
                          outline
                          type="button"
                          value={1}
                          onClick={e => {
                            setModal_favoriteBoq(!modal_favoriteBoq)
                          }}
                        >
                          Save Favorite วัสดุ
                        </Button> */}
                        {dataBoq_title_detail.favorite == 1 ? (
                          <Button
                            color="danger"
                            outline
                            type="button"
                            value={0}
                            onClick={e => {
                              // console.log(e.target.value)
                              let temp = dataBoq_title_detail
                              temp.favorite = e.target.value
                              setDataBoq_title_detail(e.target.value)
                              // console.log(dataBoq_title_detail)
                              handleSubmitBoqMaterials()
                              setOpenDetail(null)
                            }}
                          >
                            Cancel Favorite วัสดุ
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            outline
                            type="button"
                            value={1}
                            onClick={e => {
                              setModal_favoriteBoq(!modal_favoriteBoq)
                              setOpenDetail(null)
                            }}
                          >
                            Save Favorite วัสดุ
                          </Button>
                        )}
                      </Col>

                      {/* <Col sm="6" md="6" style={{ textAlign: "right" }}>
                 <Button
                   onClick={e => {
                     handleSubmitBoqMaterials()
                   }}
                   type="button"
                   color="primary"
                   className="font-16 btn-block btn btn-primary"
                 >
                   Save change
                 </Button>
               </Col> */}
                    </Row>
                  </TabPane>

                  <TabPane tabId="3">
                    <div style={{ textAlign: "center" }}>
                      {img_pay_material == null ? null : (
                        <img
                          src={process.env.REACT_APP_SERVER + img_pay_material}
                          alt={process.env.REACT_APP_NAMECOMPANY}
                          className="img-thumbnail"
                        />
                      )}
                    </div>
                  </TabPane>

                  <TabPane tabId="4">
                    <Row>
                      <Col sm="!2" style={{ textAlign: "right" }}>
                        <p
                          style={{
                            color: "#556ee6",
                          }}
                        >
                          <span
                            style={{
                              color: "#000000",
                            }}
                          >
                            รวมค่าแรง
                          </span>
                          &nbsp;
                          {parseInt(
                            dataOnSlips.reduce(function (a, b) {
                              return a + parseFloat(b.money)
                            }, 0)
                          ).toLocaleString("th-TH")}
                          /
                          <span
                            style={{
                              color: "#f46a6a",
                            }}
                          >
                            {parseInt(
                              dataDetailOnSlips.list_structure_price
                            ).toLocaleString("th-TH")}
                          </span>
                        </p>
                      </Col>
                    </Row>
                    <div className="d-flex">
                      {(dataOnSlips || []).map((v, k) => (
                        <div
                          key={k}
                          style={{
                            textAlign: "center",
                            padding: 5,
                          }}
                        >
                          <p>
                            {moment(v.created_at)
                              .add(543, "year")
                              .format("DD/MM/YYYY")}
                          </p>

                          <a
                            href={process.env.REACT_APP_SERVER + v.img_path}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={process.env.REACT_APP_SERVER + v.img_path}
                              alt={process.env.REACT_APP_NAMECOMPANY}
                              style={{
                                objectFit: "cover",
                              }}
                              className="avatar-md rounded-circle img-thumbnail"
                            />
                          </a>

                          <p>
                            {parseInt(v.money).toLocaleString("th-TH")} /
                            {parseInt(v.vat).toLocaleString("th-TH")} &nbsp;(3%)
                          </p>
                        </div>
                      ))}
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <div className="modal-footer">
              {list_structure_name == null ? (
                <>
                  {dataBoqTitleHomeProfile.boq_title_detail.reduce(function (
                    a,
                    b
                  ) {
                    return a + parseFloat(b.list_structure_persen)
                  },
                  0) > 100 ? (
                    <span style={{ color: "#f46a6a", marginRight: "5" }}>
                      ให้ % รวมของงานมากกว่า 100%
                    </span>
                  ) : (
                    <Button
                      onClick={e => {
                        handleSubmitBoqTitleHome("Edit", null)
                      }}
                      type="button"
                      color="primary"
                      className="font-16 btn-block btn btn-primary"
                    >
                      Save
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  color="primary"
                  type="button"
                  onClick={e => {
                    handleSubmitBoqMaterials()
                  }}
                  className="font-16 btn-block btn btn-primary"
                >
                  Save Material
                </Button>
              )}

              <button
                type="button"
                onClick={() => {
                  setmodal_fullscreen(!modal_fullscreen)
                }}
                className="btn btn-danger "
                data-dismiss="modal"
              >
                Close
              </button>
            </div>

            {/* </AvForm> */}
          </>
        )}
      </Modal>
      <Modal
        size="md"
        centered
        isOpen={modal_noteListStruck}
        toggle={() => {
          setModal_noteListStruck(!modal_noteListStruck)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            หมายเหตุหมวดงานหลัก
          </h5>

          <button
            type="button"
            onClick={() => {
              setModal_noteListStruck(!modal_noteListStruck)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {!isEmpty(dataBoqTitleHomeProfile) && key_boq_title_detail != null ? (
            <Input
              type="text"
              className="form-control"
              placeholder="หมายเหตุ"
              value={
                dataBoqTitleHomeProfile.boq_title_detail[key_boq_title_detail]
                  .note
              }
              onChange={e => {
                let edit = [...dataBoqTitleHomeProfile.boq_title_detail]
                edit[key_boq_title_detail].note = e.target.value
                setDataBoqTitleHomeProfile({
                  ...dataBoqTitleHomeProfile,
                  boq_title_detail: edit,
                })
              }}
              name={"favorite_name"}
            />
          ) : null}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              handleSubmitBoqTitleHome("Edit", null)
              setModal_noteListStruck(!modal_noteListStruck)
              setKey_boq_title_detail(null)
            }}
            className="btn btn-primary "
            data-dismiss="modal"
          >
            Save
          </button>

          <button
            type="button"
            onClick={() => {
              setModal_noteListStruck(!modal_noteListStruck)
            }}
            className="btn btn-danger "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      {/* {isFits ? (
        <Lightbox
          mainSrc={pay_matrial}
          enableZoom={false}
          imageCaption={
            "Caption. Can be aligned it to any side and contain any HTML."
          }
          onCloseRequest={() => {
            setisFits(!isFits)
          }}
        />
      ) : null} */}
      {!isEmpty(projectID) && (
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Nav pills className="navtab-bg nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "wage",
                      })}
                      onClick={() => {
                        toggle1("wage")
                      }}
                    >
                      ค่าแรงงานก่อสร้าง
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "sumWages",
                      })}
                      onClick={() => {
                        toggle1("sumWages")
                      }}
                    >
                      รวมค่าแรง
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "aluminias",
                      })}
                      onClick={() => {
                        toggle1("aluminias")
                      }}
                    >
                      รวมอลูมิเนียม
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab1 === "materials",
                      })}
                      onClick={() => {
                        toggle1("materials")
                      }}
                    >
                      รวมวัสดุ
                    </NavLink>
                  </NavItem>
                  <NavItem
                    style={{
                      background: "rgba(85, 109, 230,0.5)",
                      borderRadius: "5px",
                    }}
                  >
                    <NavLink
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setModal_formDataGroupBoqTitle(
                          !modal_formDataGroupBoqTitle
                        )
                        setFormDataGroupBoqTitle({
                          quotation_list_name:
                            formDataGroupBoqTitle.quotation_list_name,
                          quotation_list_detail:
                            formDataGroupBoqTitle.quotation_list_detail,
                          quotation_list_price:
                            formDataGroupBoqTitle.quotation_list_price,
                        })
                      }}
                    >
                      รวม หมวดงาน
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
            </Card>
            <TabContent activeTab={activeTab1} className="text-muted">
              <TabPane tabId="wage">
                {(boqTitles || []).map((v, k) => (
                  <Card
                    key={k}
                    style={{
                      background:
                        v.active_group == 1
                          ? "rgba(212,219,249,0.6)"
                          : v.active_group == 2
                          ? "rgba(52,195,143,0.3)"
                          : null,
                    }}
                  >
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-3">
                          {v.contractor_id == null ? (
                            <img
                              src={avatar}
                              alt={process.env.REACT_APP_NAMECOMPANY}
                              className="avatar-md rounded-circle img-thumbnail"
                            />
                          ) : (
                            !isEmpty(v.contractor) && (
                              <a
                                href={
                                  process.env.REACT_APP_SERVER +
                                  "/storage/app/private/assets/images/contractors/" +
                                  v.contractor.id +
                                  "/" +
                                  v.contractor.img_profile
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  // src={avatar}
                                  src={
                                    process.env.REACT_APP_SERVER +
                                    "storage/app/private/assets/images/contractors/" +
                                    v.contractor.id +
                                    "/" +
                                    v.contractor.img_profile
                                  }
                                  alt={process.env.REACT_APP_NAMECOMPANY}
                                  className="avatar-md rounded-circle img-thumbnail"
                                />
                              </a>
                            )
                          )}
                        </div>
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">
                            {v.selectGroup == 0 && v.boq_title_type == 1 ? (
                              <Link onClick={e => tog_fullscreen(v.id)} to="#">
                                ชื่องาน{" "}
                                {v.project == null ? "" : v.project.name}
                                &nbsp;/&nbsp;{v.quotation_list_name}{" "}
                                {isEmpty(v.quotation_list_detail) == true
                                  ? ""
                                  : "(" + v.quotation_list_detail + ")"}
                                {isEmpty(v.group_name) == true ? (
                                  ""
                                ) : (
                                  <span style={{ color: "#f46a6a" }}>
                                    {"(" + v.group_name + ")"}
                                  </span>
                                )}
                              </Link>
                            ) : v.boq_title_type == 2 ||
                              v.boq_title_type == 3 ? (
                              <Link onClick={e => tog_fullscreen(v.id)} to="#">
                                ชื่องาน{" "}
                                {v.project == null ? "" : v.project.name}
                                &nbsp;/&nbsp;{v.quotation_list_name}{" "}
                                {isEmpty(v.quotation_list_detail) == true
                                  ? ""
                                  : "(" + v.quotation_list_detail + ")"}
                                {isEmpty(v.group_name) == true ? (
                                  ""
                                ) : (
                                  <span style={{ color: "#f46a6a" }}>
                                    {"(" + v.group_name + ")"}
                                  </span>
                                )}
                              </Link>
                            ) : (
                              <p style={{ marginTop: 0, marginBottom: 0 }}>
                                ชื่องาน{" "}
                                {v.project == null ? "" : v.project.name}
                                &nbsp;/&nbsp;{v.quotation_list_name}{" "}
                                {isEmpty(v.quotation_list_detail) == true
                                  ? ""
                                  : "(" + v.quotation_list_detail + ")"}
                                {isEmpty(v.group_name) == true ? (
                                  ""
                                ) : (
                                  <span style={{ color: "#f46a6a" }}>
                                    {"(" + v.group_name + ")"}
                                  </span>
                                )}
                              </p>
                            )}

                            <p className="mb-1">
                              ช่าง:&nbsp;{" "}
                              {!isEmpty(v.contractor) && v.contractor.name}
                            </p>
                            <p className="mb-1">
                              โทร:&nbsp;{" "}
                              {!isEmpty(v.contractor) &&
                                v.contractor.number_phone}
                            </p>
                          </div>
                        </div>

                        <UncontrolledDropdown className="ms-2">
                          <DropdownToggle
                            className="text-muted font-size-14"
                            color="white"
                            type="button"
                          >
                            <i className="mdi mdi-dots-horizontal"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={e => tog_fullscreen(v.id)}
                            >
                              ดูข้อมูล/แก้ไข
                            </Link>
                            <Link
                              className="dropdown-item"
                              to={"/invoices-boq/" + v.id}
                            >
                              สัญญาจ้างงานก่อสร้าง
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <hr />
                      <Row>
                        <Col sm="6">
                          <div>
                            <p className="text-muted mb-2">ราคางาน</p>
                            <h5>
                              {parseFloat(
                                v.quotation_list_price
                              ).toLocaleString("th-TH")}
                            </h5>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="text-sm-end mt-4 mt-sm-0">
                            <p className="text-muted mb-2">
                              ค่าแรงงานก่อสร้าง/ยอดเบิก
                            </p>
                            <h5>
                              <span>
                                {parseFloat(
                                  v.quotation_list_price_mechanic
                                ).toLocaleString("th-TH")}
                                <span className="badge bg-success ms-2 align-bottom">
                                  {parseFloat(
                                    v.boq_title_persen
                                  ).toLocaleString("th-TH")}
                                  %
                                </span>
                              </span>
                              /
                              {parseFloat(
                                v.quotation_list_price_pay
                              ).toLocaleString("th-TH")}
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                ))}
              </TabPane>
              <TabPane tabId="sumWages">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <Select
                          name={"contractor_id"}
                          value={sumWagesContractor.filter(function (option) {
                            // console.log(option)
                            return option.value == sumWagesContractor_id
                          })}
                          onChange={e => {
                            setSumWagesContractor_id(e.value)
                            if (e.value == 0) {
                              setDataSumWagesContractor(boqTitleHomes.sumWages)
                            } else {
                              setDataSumWagesContractor(
                                boqTitleHomes.sumWages.filter(
                                  data => data.contractor_id == e.value
                                )
                              )
                            }
                          }}
                          placeholder="ค้นหาค่าแรงตามช่าง"
                          options={sumWagesContractor}
                        />
                      </Col>
                    </Row>
                    {buttonCapture == true ? null : (
                      <Row className="mt-2">
                        <Col sm="12" style={{ textAlign: "right" }}>
                          <Button
                            onClick={async () => await downloadScreenshot()}
                            className="mr-1 mb-1"
                            color="primary"
                            outline
                          >
                            ปริ้น
                          </Button>
                        </Col>
                      </Row>
                    )}
                    <div ref={ref} style={styleCapture}>
                      <hr />
                      <ul className="list-group">
                        {map(dataSumWagesContractor, (v, k) => (
                          <li className="list-group-item border-1 mt-1" key={k}>
                            <Media>
                              <Media body>
                                {v.contractor_id == null ? (
                                  ""
                                ) : (
                                  <Row>
                                    <Col sm="6">
                                      <p className="text-black mb-0 font-size-22">
                                        <i className="mdi mdi-account me-1" />
                                        {v.name_contractor}
                                        &emsp;
                                        <i className="mdi mdi-cellphone me-1" />
                                        <a
                                          href={
                                            "tel:" + v.number_phone_contractor
                                          }
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {v.number_phone_contractor}
                                        </a>
                                      </p>
                                    </Col>
                                    <Col sm="6" style={{ textAlign: "right" }}>
                                      <p className="text-black mb-0">
                                        {moment(v.updated_at)
                                          .add(543, "year")
                                          .format("LL")}
                                      </p>
                                    </Col>
                                  </Row>
                                )}

                                <Row>
                                  <Col sm="6">
                                    <h4 className="font-size-20 mt-1">
                                      {v.project == null ? "" : v.project.name}
                                      &nbsp;/&nbsp;{v.quotation_list_name}{" "}
                                      {isEmpty(v.quotation_list_detail) == true
                                        ? ""
                                        : "(" + v.quotation_list_detail + ")"}
                                    </h4>
                                  </Col>
                                  <Col sm="6" style={{ textAlign: "right" }}>
                                    {/* <h5 className="font-size-14 mt-1">
                                    <span style={{ borderBottom: "1px solid" }}>
                                      {parseFloat(
                                        v.quotation_list_price_mechanic
                                      ).toLocaleString("th-TH")}{" "}
                                      &nbsp;บ.
                                    </span>
                                  </h5> */}
                                  </Col>
                                </Row>
                                {map(v.boq_title_detail, (i, j) => (
                                  <Row>
                                    <Col sm="6">
                                      <p
                                        className="text-black ml-2 font-size-14"
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        - {i.list_structure_name}
                                        {isEmpty(i.note) ? null : (
                                          <span>
                                            <br />
                                            &emsp;* {i.note}
                                          </span>
                                        )}
                                      </p>
                                    </Col>
                                    <Col sm="6" style={{ textAlign: "right" }}>
                                      <h5 className="font-size-16 mt-1">
                                        {parseFloat(
                                          i.list_structure_price
                                        ).toLocaleString("th-TH")}{" "}
                                        &nbsp;บ.
                                      </h5>
                                    </Col>
                                    <hr />
                                  </Row>
                                ))}

                                <Row>
                                  <Col sm="6"></Col>
                                  <Col sm="6" style={{ textAlign: "right" }}>
                                    <h5 className="font-size-16 mt-1">
                                      <span
                                        style={{ borderBottom: "1px solid" }}
                                      >
                                        {parseFloat(
                                          v.quotation_list_price_mechanic
                                        ).toLocaleString("th-TH")}{" "}
                                        &nbsp;บ.
                                      </span>
                                    </h5>
                                  </Col>
                                </Row>
                              </Media>
                            </Media>
                          </li>
                        ))}
                      </ul>

                      <hr />
                      <Row>
                        <Col sm="12" style={{ textAlign: "right" }}>
                          <p className="font-size-16">
                            รวมค่าแรง:&nbsp;
                            <span style={{ color: "#556ee6" }}>
                              {parseFloat(
                                dataSumWagesContractor.reduce(function (a, b) {
                                  return (
                                    a +
                                    parseFloat(b.quotation_list_price_mechanic)
                                  )
                                }, 0)
                              ).toLocaleString("th-TH")}{" "}
                              &nbsp;บ.
                            </span>
                            &nbsp;
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>

              <TabPane tabId="materials">
                <Card>
                  <CardBody>
                    {/* <Row>
                      <Col sm="12">
                        <Select
                          name={"list_structure_name"}
                          onChange={e => {}}
                          placeholder="รวมวัสดุตามหมวดงาน"
                          options={dataList_structure}
                        />
                      </Col>
                    </Row>
                    <br /> */}
                    <Table className="table table-bordered mb-0" responsive>
                      <thead>
                        <tr>
                          <th>ชื่อ</th>
                          <th style={{ width: "80px" }}>หน่วย</th>
                          <th style={{ width: "80px", textAlign: "right" }}>
                            เบิก
                          </th>
                          <th style={{ width: "80px", textAlign: "right" }}>
                            ใช้
                          </th>
                          <th style={{ width: "80px", textAlign: "right" }}>
                            เกิน
                          </th>
                          <th style={{ width: "80px", textAlign: "right" }}>
                            คืน
                          </th>
                          <th style={{ width: "200px", textAlign: "right" }}>
                            รวมเป็นเงิน (บาท)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(materials, (v, k) => (
                          <tr key={k}>
                            <td>{v.material_name}</td>
                            <td>{v.unit}</td>

                            <td style={{ textAlign: "right" }}>{v.qta}</td>
                            <td style={{ textAlign: "right" }}>{v.qta_use}</td>
                            <td style={{ textAlign: "right" }}>{v.qta_over}</td>
                            <td style={{ textAlign: "right" }}>
                              {v.qta_return}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {parseFloat(v.qta_sum_price).toLocaleString(
                                "th-TH"
                              )}
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={5}></td>
                          <td style={{ textAlign: "right" }}>รวม</td>

                          <td style={{ textAlign: "right" }}>
                            {parseFloat(qta_sum_price).toLocaleString("th-TH")}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </TabPane>

              <TabPane tabId="aluminias">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageAlumnOptions)}
                      keyField="id"
                      columns={ecommerceOrderColumns}
                      data={aluminias}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={aluminias}
                          columns={ecommerceOrderColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={e => {
                                        setActionModelProjectAluminia({
                                          modal: true,
                                        })
                                      }}
                                    >
                                      <i className="mdi mdi-plus me-1" />
                                      Add New
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="id"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

Boq.propTypes = {
  projectID: PropTypes.string,
}

export default Boq
