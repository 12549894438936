/* MATERIAL_SHOPS */
export const GET_MATERIAL_SHOPS = "GET_MATERIAL_SHOPS"
export const GET_MATERIAL_SHOPS_SUCCESS = "GET_MATERIAL_SHOPS_SUCCESS"
export const GET_MATERIAL_SHOPS_FAIL = "GET_MATERIAL_SHOPS_FAIL"

/* MATERIAL_SHOPS PROFILE */
export const GET_MATERIAL_SHOP_PROFILE = "GET_MATERIAL_SHOP_PROFILE"
export const GET_MATERIAL_SHOP_PROFILE_SUCCESS = "GET_MATERIAL_SHOP_PROFILE_SUCCESS"
export const GET_MATERIAL_SHOP_PROFILE_FAIL = "GET_MATERIAL_SHOP_PROFILE_FAIL"

/**
 * add materialshop
 */
export const ADD_NEW_MATERIAL_SHOP = "ADD_NEW_MATERIAL_SHOP"
export const ADD_MATERIAL_SHOP_SUCCESS = "ADD_MATERIAL_SHOP_SUCCESS"
export const ADD_MATERIAL_SHOP_FAIL = "ADD_MATERIAL_SHOP_FAIL"

/**
 * Edit materialshop
 */
export const UPDATE_MATERIAL_SHOP = "UPDATE_MATERIAL_SHOP"
export const UPDATE_MATERIAL_SHOP_SUCCESS = "UPDATE_MATERIAL_SHOP_SUCCESS"
export const UPDATE_MATERIAL_SHOP_FAIL = "UPDATE_MATERIAL_SHOP_FAIL"

/**
 * Delete materialshop
 */
export const DELETE_MATERIAL_SHOP = "DELETE_MATERIAL_SHOP"
export const DELETE_MATERIAL_SHOP_SUCCESS = "DELETE_MATERIAL_SHOP_SUCCESS"
export const DELETE_MATERIAL_SHOP_FAIL = "DELETE_MATERIAL_SHOP_FAIL"
