import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_COMMENT_HOMES,
  GET_COMMENT_HOME_PROFILE,
  ADD_NEW_COMMENT_HOME,
  DELETE_COMMENT_HOME,
  UPDATE_COMMENT_HOME,
  NOTI_COMMENT_HOMES,
} from "./actionTypes"

import {
  getCommentHomesSuccess,
  getCommentHomesFail,
  getCommentHomeProfileSuccess,
  getCommentHomeProfileFail,
  addCommentHomeFail,
  addCommentHomeSuccess,
  updateCommentHomeSuccess,
  updateCommentHomeFail,
  deleteCommentHomeSuccess,
  deleteCommentHomeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCommentHomes,
  getCommentHomeProfile,
  addNewCommentHome,
  updateCommentHome,
  deleteCommentHome,
  notiLineCommentHome,
} from "../../helpers/fakebackend_helper"

function* fetchCommentHomes({ payload: id }) {
  try {
    const response = yield call(getCommentHomes, id)
    // console.log(response);
    yield put(getCommentHomesSuccess(response))
  } catch (error) {
    yield put(getCommentHomesFail(error))
  }
}

function* fetchCommentHomeProfile({ payload: id }) {
  // console.log(id)updateCommentHome
  try {
    const response = yield call(getCommentHomeProfile, id)
    // console.log("Profile => ", response)
    yield put(getCommentHomeProfileSuccess(response))
  } catch (error) {
    yield put(getCommentHomeProfileFail(error))
  }
}

function* onUpdateCommentHome({ payload: commentHome }) {
  try {
    // let x = commentHome.get('formData')
    // console.log(x)
    const response = yield call(updateCommentHome, commentHome)
    yield put(updateCommentHomeSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateCommentHomeFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onNotiLineCommentHome({ payload: commentHomes }) {
  try {
    const response = yield call(notiLineCommentHome, commentHomes)
    // console.log(response);
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "แจ้งเตือน",
      message: "แจ้งเตือนสำเร็จ",
    })
  } catch (error) {
    yield put(updateCommentHomeFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "แจ้งเตือน",
      message: "แจ้งเตือนล้มเหลว",
    })
  }
}

function* onDeleteCommentHome({ payload: commentHome }) {
  try {
    const response = yield call(deleteCommentHome, commentHome)
    yield put(deleteCommentHomeSuccess(response))
  } catch (error) {
    yield put(deleteCommentHomeFail(error))
  }
}

function* onAddNewCommentHome({ payload: commentHome }) {
  try {
    const response = yield call(addNewCommentHome, commentHome)
    // console.log(response);
    // yield put(addCommentHomeSuccess(response))
    yield put(getCommentHomesSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addCommentHomeFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* listStructure() {
  yield takeEvery(GET_COMMENT_HOMES, fetchCommentHomes)
  yield takeEvery(GET_COMMENT_HOME_PROFILE, fetchCommentHomeProfile)
  yield takeEvery(ADD_NEW_COMMENT_HOME, onAddNewCommentHome)
  yield takeEvery(UPDATE_COMMENT_HOME, onUpdateCommentHome)
  yield takeEvery(DELETE_COMMENT_HOME, onDeleteCommentHome)
  yield takeEvery(NOTI_COMMENT_HOMES, onNotiLineCommentHome)
}

export default listStructure
