/* DESIGNS */
export const GET_DESIGNS = "GET_DESIGNS"
export const GET_DESIGNS_SUCCESS = "GET_DESIGNS_SUCCESS"
export const GET_DESIGNS_FAIL = "GET_DESIGNS_FAIL"

/* DESIGNS PROFILE */
export const GET_DESIGN_PROFILE = "GET_DESIGN_PROFILE"
export const GET_DESIGN_PROFILE_SUCCESS = "GET_DESIGN_PROFILE_SUCCESS"
export const GET_DESIGN_PROFILE_FAIL = "GET_DESIGN_PROFILE_FAIL"

/**
 * add design
 */
export const ADD_NEW_DESIGN = "ADD_NEW_DESIGN"
export const ADD_DESIGN_SUCCESS = "ADD_DESIGN_SUCCESS"
export const ADD_DESIGN_FAIL = "ADD_DESIGN_FAIL"

/**
 * Edit design
 */
export const UPDATE_DESIGN = "UPDATE_DESIGN"
export const UPDATE_DESIGN_SUCCESS = "UPDATE_DESIGN_SUCCESS"
export const UPDATE_DESIGN_FAIL = "UPDATE_DESIGN_FAIL"

/**
 * Delete design
 */
export const DELETE_DESIGN = "DELETE_DESIGN"
export const DELETE_DESIGN_SUCCESS = "DELETE_DESIGN_SUCCESS"
export const DELETE_DESIGN_FAIL = "DELETE_DESIGN_FAIL"
