import {
  GET_BOQ_PROFILE,
  GET_BOQ_PROFILE_FAIL,
  GET_BOQ_PROFILE_SUCCESS,
  GET_BOQS,
  CONFIRM_BUY_MATERIAL,
  CONFIRM_SELECT_MATERIAL,
  GET_BOQS_FAIL,
  GET_BOQS_SUCCESS,
  GET_CHECK_BOQS_SUCCESS,
  GET_SUM_BOQS_SUCCESS,
  GET_MATRAIAL_BOQS_SUCCESS,
  UPLOAD_IMG_CHECK_BOQS,
  ADD_NEW_BOQ,
  ADD_BOQ_SUCCESS,
  ADD_BOQ_FAIL,
  UPDATE_BOQ,
  UPDATE_BOQ_SUCCESS,
  UPDATE_BOQ_FAIL,
  DELETE_BOQ,
  DELETE_BOQ_SUCCESS,
  DELETE_BOQ_FAIL,
} from "./actionTypes"

export const getBoqs = (type, boq_title_detail_id) => ({
  type: GET_BOQS,
  payload: { type, boq_title_detail_id },
})

export const confirmBuyMaterial = data => ({
  type: CONFIRM_BUY_MATERIAL,
  payload: data,
})

export const confirmSeleteMaterial = data => ({
  type: CONFIRM_SELECT_MATERIAL,
  payload: data,
})

export const getBoqsSuccess = boqs => ({
  type: GET_BOQS_SUCCESS,
  payload: boqs,
})

export const getCheckBoqsSuccess = boqs => ({
  type: GET_CHECK_BOQS_SUCCESS,
  payload: boqs,
})

export const getSumBoqsSuccess = boqs => ({
  type: GET_SUM_BOQS_SUCCESS,
  payload: boqs,
})

export const getMateiralBoqSuccess = boqs => ({
  type: GET_MATRAIAL_BOQS_SUCCESS,
  payload: boqs,
})

export const addNewBoq = boqs => ({
  type: ADD_NEW_BOQ,
  payload: boqs,
})

export const uploadImgCheckBoqs = boqs => ({
  type: UPLOAD_IMG_CHECK_BOQS,
  payload: boqs,
})

export const addBoqSuccess = boqs => ({
  type: ADD_BOQ_SUCCESS,
  payload: boqs,
})

export const addBoqFail = error => ({
  type: ADD_BOQ_FAIL,
  payload: error,
})

export const getBoqsFail = error => ({
  type: GET_BOQS_FAIL,
  payload: error,
})

export const getBoqProfile = id => ({
  type: GET_BOQ_PROFILE,
  payload: id,
})

export const getBoqProfileSuccess = boqProfile => ({
  type: GET_BOQ_PROFILE_SUCCESS,
  payload: boqProfile,
})

export const getBoqProfileFail = error => ({
  type: GET_BOQ_PROFILE_FAIL,
  payload: error,
})

export const updateBoq = boqs => ({
  type: UPDATE_BOQ,
  payload: boqs,
})

// export const updateBoq = boqs => console.log(boqs);

export const updateBoqSuccess = boqs => ({
  type: UPDATE_BOQ_SUCCESS,
  payload: boqs,
})

export const updateBoqFail = error => ({
  type: UPDATE_BOQ_FAIL,
  payload: error,
})

export const deleteBoq = boqs => ({
  type: DELETE_BOQ,
  payload: boqs,
})

export const deleteBoqSuccess = boqs => ({
  type: DELETE_BOQ_SUCCESS,
  payload: boqs,
})

export const deleteBoqFail = error => ({
  type: DELETE_BOQ_FAIL,
  payload: error,
})
