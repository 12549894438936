import React from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"

import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

import FullCalendar from "@fullcalendar/react"
import thLocale from "@fullcalendar/core/locales/th"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import "@fullcalendar/bootstrap/main.css"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work

import {
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getEvents as onGetEvents,
  updateEvent as onUpdateEvent,
} from "store/calendar/actions"

import { getProjects as onGetProjects } from "store/projects/actions"

//css

//redux
import { useSelector, useDispatch } from "react-redux"

const Calender = props => {
  const dispatch = useDispatch()

  const { events } = useSelector(state => ({
    events: state.calendar.events,
  }))

  const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }))

  const [modal, setModal] = React.useState(false)
  const [event, setEvent] = React.useState({})
  const [project_id, setProject_id] = React.useState(null)
  const [isEdit, setIsEdit] = React.useState(false)
  const [optionSelectProjects, setOptionSelectProjects] = React.useState([])

  React.useMemo(() => {
    dispatch(onGetEvents(0))
    dispatch(onGetProjects("list", null))
  }, [])

  React.useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])

  /**
   * Handling the modal state
   */
  const toggle = () => {
    setModal(!modal)
  }

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    // console.log("arg", arg)
    if (!isEmpty(arg)) {
      const date = arg["date"]
      const day = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()
      const currectDate = new Date()
      const currentHour = currectDate.getHours()
      const currentMin = currectDate.getMinutes()
      const currentSec = currectDate.getSeconds()
      const modifiedDate = new Date(
        year,
        month,
        day,
        currentHour,
        currentMin,
        currentSec
      )
      setEvent({
        id: "",
        title: "อื่น ๆ",
        groupId: project_id == null ? Number(-1) : project_id,
        description: "",
        start: moment(modifiedDate).format("YYYY-MM-DDThh:mm"),
        className: "bg-primary",
      })
    } else {
      setEvent({
        id: "",
        title: "อื่น ๆ",
        groupId: project_id == null ? Number(-1) : project_id,
        description: "",
        start: moment().format("YYYY-MM-DDThh:mm"),
        className: "bg-primary",
      })
    }
    toggle()
  }

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    const event = arg.event
    // console.log("handleEventClick", event)
    setEvent({
      id: event.id,
      title: event.title,
      groupId: Number(event.groupId),
      description: event.extendedProps.description,
      start: moment(event.start).format("YYYY-MM-DDThh:mm"),
      className: event.classNames,
    })
    setIsEdit(true)
    toggle()
  }
  const handleListEventClick = event => {
    // console.log("handleListEventClick", event)
    setEvent({
      id: event.id,
      title: event.title,
      groupId: Number(event.groupId),
      description: event.description,
      start: moment(event.start).format("YYYY-MM-DDThh:mm"),
      className: event.classNames,
    })
    setIsEdit(true)
    toggle()
  }

  /**
   * Handling submit event on event form
   */

  const handleValidEventSubmit = (e, errors, values) => {
    if (errors.length > 0) {
    } else {
      if (isEdit) {
        // // update event
        dispatch(onUpdateEvent(event))
      } else {
        // save new event
        dispatch(onAddNewEvent(event))
      }
      toggle()
    }
  }

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    dispatch(onDeleteEvent(event.id))
    toggle()
  }

  React.useEffect(() => {
    if (!isEmpty(projects)) {
      let temp = []
      for (let index = 0; index < projects.length; index++) {
        temp.push({
          value: projects[index].id,
          label: projects[index].name,
        })
      }
      let newOpeion = [
        {
          value: -1,
          label: "อื่น ๆ",
        },
      ].concat(temp)
      setOptionSelectProjects(newOpeion)
    } else {
      setOptionSelectProjects([])
    }
  }, [projects])

  // React.useEffect(() => {
  //   console.log("event", event)
  // }, [event])

  React.useMemo(() => {
    // console.log(window.location.href.split("/"))
    let path = window.location.href.split("/")
    if (path[3] == "projects-overview") {
      setProject_id(path[4])
    } else {
      setProject_id(null)
    }
  }, [])

  return (
    <React.Fragment>
      {/* New/Edit event modal */}
      <Modal
        isOpen={modal}
        size="md"
        centered={true}
        toggle={() => setModal(!modal)}
      >
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "แก้ไข" : "เพิ่ม"}ปฏิทิน
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidEventSubmit}>
            <Row form>
              <Col className="col-12 mb-3">
                <input
                  className="form-control"
                  type="datetime-local"
                  value={
                    event ? event.start : moment().format("YYYY-MM-DDThh:mm")
                  }
                  id="event.start"
                  onChange={e => {
                    console.log(e)
                    setEvent({
                      ...event,
                      start: e.target.value,
                    })
                  }}
                />
              </Col>
              <Col className="col-12 mb-3">
                <AvField
                  type="select"
                  name="groupId"
                  id="groupId"
                  value={event ? event.groupId : -1}
                  onChange={e => {
                    var el = document.getElementById("groupId")
                    var text = el.options[el.selectedIndex].innerHTML
                    // console.log(text)
                    setEvent({
                      ...event,
                      groupId: e.target.value,
                      title: text,
                    })
                  }}
                  label="เลือกโปรเจค"
                  validate={{
                    required: { value: true },
                  }}
                >
                  {optionSelectProjects &&
                    optionSelectProjects.map((project, i) => (
                      <option
                        value={project.value}
                        label={project.label}
                        key={project.value}
                      >
                        {project.label}
                      </option>
                    ))}
                </AvField>
              </Col>
              <Col className="col-12 mb-3">
                <AvField
                  name="note"
                  type="textarea"
                  label="หมายเหตุ"
                  value={event ? event.description : ""}
                  onChange={e =>
                    setEvent({
                      ...event,
                      description: e.target.value,
                    })
                  }
                  validate={{
                    required: { value: false },
                  }}
                />
              </Col>

              <Col className="col-12 mb-3">
                <AvField
                  type="select"
                  name="className"
                  label="เสือกแถบสี"
                  validate={{
                    required: { value: true },
                  }}
                  value={event ? event.className : "bg-primary"}
                  onChange={e =>
                    setEvent({
                      ...event,
                      className: e.target.value,
                    })
                  }
                >
                  <option value="bg-danger">Danger</option>
                  <option value="bg-success">Success</option>
                  <option value="bg-primary">Primary</option>
                  <option value="bg-info">Info</option>
                  <option value="bg-dark">Dark</option>
                  <option value="bg-warning">Warning</option>
                </AvField>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light me-2"
                    onClick={toggle}
                  >
                    ปิด
                  </button>
                  {!!isEdit && (
                    <button
                      type="button"
                      className="btn btn-danger me-2"
                      onClick={() => handleDeleteEvent()}
                    >
                      ลบ
                    </button>
                  )}
                  <button type="submit" className="btn btn-success save-event">
                    บันทึก
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

      <Col sm="12">
        <Card>
          <CardBody>
            <Row>
              <Col sm="12" lg="3" className="mt-2">
                <Row>
                  <Col sm="12" className="mb-2">
                    <div className="d-grid gap-2">
                      <Button
                        color="primary"
                        block
                        onClick={() => handleDateClick()}
                      >
                        เพิ่มรายการใหม่
                      </Button>
                    </div>
                  </Col>
                  {events &&
                    events.map((event, i) => (
                      <Col
                        sm="12"
                        style={{ cursor: "pointer" }}
                        key={event.id}
                        onClick={e => {
                          handleListEventClick(event)
                        }}
                      >
                        <h4>
                          {event.title}
                          <span style={{ fontSize: 15 }}>
                            &nbsp; ({" "}
                            {moment(event.start)
                              .add(543, "year")
                              .format("DD/MM/YYYY HH:mm")}{" "}
                            )
                          </span>
                        </h4>
                        <span>&emsp;{event.description}</span>
                        <hr />
                      </Col>
                    ))}
                </Row>
              </Col>
              <Col sm="12" lg="9" className="mt-2">
                {/* fullcalendar control */}
                <FullCalendar
                  plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                  locale={thLocale}
                  height={640}
                  slotDuration={"00:15:00"}
                  handleWindowResize={true}
                  themeSystem="bootstrap"
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,dayGridWeek,dayGridDay",
                  }}
                  events={events}
                  editable={true}
                  droppable={true}
                  selectable={true}
                  dateClick={handleDateClick}
                  eventClick={handleEventClick}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  // categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
}

export default Calender
