/* SLIPS */
export const GET_SLIPS = "GET_SLIPS"
export const GET_SLIP_ON_PROJECT = "GET_SLIP_ON_PROJECT"
export const GET_DETAIL_CARD = "GET_DETAIL_CARD"
export const GET_SLIP_SUM = "GET_SLIP_SUM"
export const GET_SLIPS_SUCCESS = "GET_SLIPS_SUCCESS"
export const GET_SLIPS_ONPROJECT_SUCCESS = "GET_SLIPS_ONPROJECT_SUCCESS"
export const GET_SLIPS_FAIL = "GET_SLIPS_FAIL"

/* SLIPS PROFILE */
export const GET_SLIP_PROFILE = "GET_SLIP_PROFILE"
export const GET_SLIP_PROFILE_SUCCESS = "GET_SLIP_PROFILE_SUCCESS"
export const GET_SLIP_PROFILE_FAIL = "GET_SLIP_PROFILE_FAIL"

/**
 * add slipproject
 */
export const ADD_NEW_SLIP = "ADD_NEW_SLIP"
export const ADD_SLIP_SUCCESS = "ADD_SLIP_SUCCESS"
export const ADD_SLIP_FAIL = "ADD_SLIP_FAIL"

/**
 * Edit slipproject
 */
export const UPDATE_SLIP = "UPDATE_SLIP"
export const UPDATE_SLIP_SUCCESS = "UPDATE_SLIP_SUCCESS"
export const UPDATE_SLIP_FAIL = "UPDATE_SLIP_FAIL"

/**
 * Delete slipproject
 */
export const DELETE_SLIP = "DELETE_SLIP"
export const DELETE_SLIP_SUCCESS = "DELETE_SLIP_SUCCESS"
export const DELETE_SLIP_FAIL = "DELETE_SLIP_FAIL"

/**
 * Upload File
 */
 export const UPLOAD_INFOPROJECTFILESLIP = "UPLOAD_INFOPROJECTFILESLIP"