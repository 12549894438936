import {
  GET_CUSTOM_PROJECT_PROFILE,
  GET_CUSTOM_PROJECT_PROFILE_FAIL,
  GET_CUSTOM_PROJECT_PROFILE_SUCCESS,
  GET_CUSTOM_PROJECTS,
  GET_CUSTOM_PROJECTS_FAIL,
  GET_CUSTOM_PROJECTS_SUCCESS,
  ADD_NEW_CUSTOM_PROJECT,
  ADD_CUSTOM_PROJECT_SUCCESS,
  ADD_CUSTOM_PROJECT_FAIL,
  UPDATE_CUSTOM_PROJECT,
  UPDATE_CUSTOM_PROJECT_SUCCESS,
  UPDATE_CUSTOM_PROJECT_FAIL,
  DELETE_CUSTOM_PROJECT,
  DELETE_CUSTOM_PROJECT_SUCCESS,
  DELETE_CUSTOM_PROJECT_FAIL,
} from "./actionTypes"

export const getCustomProjects = id => ({
  type: GET_CUSTOM_PROJECTS,
  payload: id,
})

export const getCustomProjectsSuccess = customprojects => ({
  type: GET_CUSTOM_PROJECTS_SUCCESS,
  payload: customprojects,
})

export const addNewCustomProject = customproject => ({
  type: ADD_NEW_CUSTOM_PROJECT,
  payload: customproject,
})

export const addCustomProjectSuccess = customproject => ({
  type: ADD_CUSTOM_PROJECT_SUCCESS,
  payload: customproject,
})

export const addCustomProjectFail = error => ({
  type: ADD_CUSTOM_PROJECT_FAIL,
  payload: error,
})

export const getCustomProjectsFail = error => ({
  type: GET_CUSTOM_PROJECTS_FAIL,
  payload: error,
})

export const getCustomProjectProfile = id => ({
  type: GET_CUSTOM_PROJECT_PROFILE,
  payload: id,
})

export const getCustomProjectProfileSuccess = customprojectProfile => ({
  type: GET_CUSTOM_PROJECT_PROFILE_SUCCESS,
  payload: customprojectProfile,
})

export const getCustomProjectProfileFail = error => ({
  type: GET_CUSTOM_PROJECT_PROFILE_FAIL,
  payload: error,
})

export const updateCustomProject = customproject => ({
  type: UPDATE_CUSTOM_PROJECT,
  payload: customproject,
})

// export const updateCustomProject = customproject => console.log(customproject);

export const updateCustomProjectSuccess = customproject => ({
  type: UPDATE_CUSTOM_PROJECT_SUCCESS,
  payload: customproject,
})

export const updateCustomProjectFail = error => ({
  type: UPDATE_CUSTOM_PROJECT_FAIL,
  payload: error,
})

export const deleteCustomProject = customproject => ({
  type: DELETE_CUSTOM_PROJECT,
  payload: customproject,
})

export const deleteCustomProjectSuccess = customproject => ({
  type: DELETE_CUSTOM_PROJECT_SUCCESS,
  payload: customproject,
})

export const deleteCustomProjectFail = error => ({
  type: DELETE_CUSTOM_PROJECT_FAIL,
  payload: error,
})
