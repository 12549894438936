import {
  GET_PROFITLOSSS_SUCCESS,
  GET_PROFITLOSSS_FAIL,
  ADD_PROFITLOSS_SUCCESS,
  ADD_PROFITLOSS_FAIL,
  UPDATE_PROFITLOSS_SUCCESS,
  UPDATE_PROFITLOSS_FAIL,
  DELETE_PROFITLOSS_SUCCESS,
  DELETE_PROFITLOSS_FAIL,
  GET_PROFITLOSS_PROFILE_SUCCESS,
  GET_PROFITLOSS_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  profitlossprojects: [],
  profitlossprojectProfile: {},
  error: {},
}

const profitlossprojects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFITLOSSS_SUCCESS:
      return {
        ...state,
        profitlossprojects: action.payload,
      }

    case GET_PROFITLOSSS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PROFITLOSS_SUCCESS:

      return {
        ...state,
        profitlossprojects: [...state.profitlossprojects, action.payload],
      }

    case ADD_PROFITLOSS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROFITLOSS_PROFILE_SUCCESS:
      return {
        ...state,
        profitlossprojectProfile: action.payload,
      }

      case UPDATE_PROFITLOSS_SUCCESS:
        return {
          ...state,
          profitlossprojects: state.profitlossprojects.map(profitlossproject =>
            profitlossproject.id.toString() === action.payload.id.toString()
              ? { profitlossproject, ...action.payload }
              : profitlossproject
          ),
        }
  
      case UPDATE_PROFITLOSS_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case DELETE_PROFITLOSS_SUCCESS:
        return {
          ...state,
          profitlossprojects: state.profitlossprojects.filter(
            profitlossproject => profitlossproject.id.toString() !== action.payload.id.toString()
          ),
        }
  
      case DELETE_PROFITLOSS_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    case GET_PROFITLOSS_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default profitlossprojects
