import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

//Import Components
import FooterLink from "./footer-link"

const Features = () => {
  const footerLinks = [
    {
      title: "Main",
      links: [
        { title: "Project/home", link: "/home-products" },

        {
          title: "Factory",
          link: "https://www.google.com/maps/dir//premium+house/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x30e293f69d5cab5b:0x53ecad1eaedf0835?sa=X&ved=2ahUKEwjz88Hw0J70AhVtxzgGHc3uBeAQ9Rd6BAgGEAM",
        },
      ],
    },
    {
      title: "Facebook",
      links: [
        {
          title: "Home Page",
          link: "https://www.facebook.com/PremiumHouseModular/",
        },
        { title: "Messenger", link: "https://m.me/PremiumHouseModular" },
      ],
    },
    {
      title: "Line @",
      links: [
        { title: "@pmh-m", link: "https://lin.ee/LBJJVlw" },
      ],
    },
    {
      title: "Telephone",
      links: [
        { title: "0626405432", link: "tel:+66626405432" },
        { title: "0946233998", link: "tel:+66946233998" },
      ],
    },
  ]

  return (
    <React.Fragment>
      <footer className="landing-footer">
        <Container>
          <Row>
            {footerLinks.map((footerLink, key) => (
              <Col lg="3" sm="6" key={key} className="text-center">
                <div className="mb-4 mb-lg-0">
                  <h5 className="mb-3 footer-list-title">{footerLink.title}</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {footerLink.links.map((Flink, key) => (
                      <li key={key}>
                        {/* <a to={Flink.link}>{Flink.title}</a> */}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={Flink.link}
                          className="text-muted"
                        >
                          {Flink.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>

          <hr className="footer-border my-5" />

          <FooterLink />
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Features
