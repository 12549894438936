import {
  GET_PROJECT_PROFILE,
  GET_PROJECT_PROFILE_FAIL,
  GET_PROJECT_PROFILE_SUCCESS,
  GET_PROJECTS,
  GET_CHECK_PROJECTS_BOQ_SUCCESS,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
} from "./actionTypes"

export const getProjects = (type, name) => ({
  type: GET_PROJECTS,
  payload: { type, name },
})

export const getCheckProjectBoqsSuccess = checkBoqProject => ({
  type: GET_CHECK_PROJECTS_BOQ_SUCCESS,
  payload: checkBoqProject,
})
export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})

export const addNewProject = project => ({
  type: ADD_NEW_PROJECT,
  payload: project,
})

export const addProjectSuccess = project => ({
  type: ADD_PROJECT_SUCCESS,
  payload: project,
})

export const addProjectFail = error => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
})

export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
})

export const getProjectProfile = id => ({
  type: GET_PROJECT_PROFILE,
  payload: id,
})

export const getProjectProfileSuccess = projectProfile => ({
  type: GET_PROJECT_PROFILE_SUCCESS,
  payload: projectProfile,
})

export const getProjectProfileFail = error => ({
  type: GET_PROJECT_PROFILE_FAIL,
  payload: error,
})

export const updateProject = project => ({
  type: UPDATE_PROJECT,
  payload: project,
})

// export const updateProject = project => console.log(project);

export const updateProjectSuccess = project => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: project,
})

export const updateProjectFail = error => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
})

export const deleteProject = project => ({
  type: DELETE_PROJECT,
  payload: project,
})

export const deleteProjectSuccess = project => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: project,
})

export const deleteProjectFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
})
