import {
  GET_DESIGNS_SUCCESS,
  GET_DESIGNS_FAIL,
  ADD_DESIGN_SUCCESS,
  ADD_DESIGN_FAIL,
  UPDATE_DESIGN_SUCCESS,
  UPDATE_DESIGN_FAIL,
  DELETE_DESIGN_SUCCESS,
  DELETE_DESIGN_FAIL,
  GET_DESIGN_PROFILE_SUCCESS,
  GET_DESIGN_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  designs: [],
  designProfile: {},
  showCustomHome: false,
  error: {},
}

const designs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DESIGNS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        designs: action.payload,
      }

    case GET_DESIGNS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DESIGN_SUCCESS:
      return {
        ...state,
        designs: [...state.designs, action.payload],
        showCustomHome: true,
      }

    case ADD_DESIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DESIGN_PROFILE_SUCCESS:
      // console.log("GET_DESIGN_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        designProfile: action.payload,
      }

    case UPDATE_DESIGN_SUCCESS:
      return {
        ...state,
        designs: state.designs.map(design =>
          design.id.toString() === action.payload.id.toString()
            ? { design, ...action.payload }
            : design
        ),
      }

    case UPDATE_DESIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_DESIGN_SUCCESS:
      return {
        ...state,
        designs: state.designs.filter(
          design => design.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_DESIGN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DESIGN_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default designs
