import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
} from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import cellEditFactory, { Type } from "react-bootstrap-table2-editor"
// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const [modal, setModal] = React.useState(false)
  const [tableDataShopMaterial, setTableDataShopMaterial] = React.useState([
    {
      id: 1,
      no: 1,
      name: "แสงมาลี",
      price: 1000,
    },
    {
      id: 2,
      no: 2,
      name: "ทวีโชค",
      price: 1300,
    },

    {
      id: 3,
      no: 3,
      name: "Home Pro",
      price: 800,
    },

    {
      id: 4,
      no: 4,
      name: "ไทยวัสดุ",
      price: 1500,
    },
  ])

  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "id",
      text: "ลำดับ",
      sort: true,

      headerStyle: () => {
        return { width: "80px" }
      },
    },
    {
      dataField: "name",
      text: "ชื่อร้าน",
      sort: false,
    },
    {
      dataField: "price",
      text: "ขายราคา",
      sort: false,
      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: (cell, row, rowIndex) => {
        return <span>{cell.toLocaleString("th-TH")} </span>
      },
    },
  ])

  React.useMemo(() => {
    if (actionModal.modal) {
      setModal(actionModal.modal)
    }
    return () => {}
  }, [actionModal]) // Only re-run the effect if count changes

  return (
    <Modal
      size="lg"
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        ดูข้อมูลราคาตามร้าน
      </ModalHeader>
      <AvForm onSubmit={null} model={null}>
        <ModalBody>
          <BootstrapTable
            keyField="id"
            bordered
            data={tableDataShopMaterial}
            columns={tableColumns}
            // bootstrap4
            pagination={paginationFactory({
              sizePerPage: 30,
              sizePerPageList: [30, 50, 70, 100],
            })}
            wrapperClasses="table-responsive" // This is the style provided by bootstrap 4, this will set the parent div with that class
          />
        </ModalBody>
      </AvForm>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
