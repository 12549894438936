import {
  GET_DESIGNHOME_PROFILE,
  GET_DESIGNHOME_PROFILE_FAIL,
  GET_DESIGNHOME_PROFILE_SUCCESS,
  GET_DESIGNHOMES,
  GET_DESIGNHOMES_FAIL,
  GET_DESIGNHOMES_SUCCESS,
  ADD_NEW_DESIGNHOME,
  ADD_DESIGNHOME_SUCCESS,
  ADD_DESIGNHOME_FAIL,
  UPDATE_DESIGNHOME,
  UPDATE_DESIGNHOME_SUCCESS,
  UPDATE_DESIGNHOME_FAIL,
  DELETE_DESIGNHOME,
  DELETE_DESIGNHOME_SUCCESS,
  DELETE_DESIGNHOME_FAIL,
  UPLOAD_DESIGNHOMEIMG,
  CHANGEFIRST_DESIGNHOMEIMG,
  CHANGEFIRST_DESIGNHOMEIMG_SUCCESS,
  DELETE_DESIGNHOMEIMG,
  DELETE_DESIGNHOMEIMG_SUCCESS,
  GET_DESIGNPROMOTIONHOMES_SUCCESS,
  GET_DESIGNACHIEVEMENTHOMES_SUCCESS,
} from "./actionTypes"

export const getDesignHomes = type => ({
  type: GET_DESIGNHOMES,
  payload: type,
})

export const getDesignHomesSuccess = designhomes => ({
  type: GET_DESIGNHOMES_SUCCESS,
  payload: designhomes,
})

export const getDesignPromotionHomesSuccess = designhomes => ({
  type: GET_DESIGNPROMOTIONHOMES_SUCCESS,
  payload: designhomes,
})

export const getDesignAchievementHomesSuccess = designhomes => ({
  type: GET_DESIGNACHIEVEMENTHOMES_SUCCESS,
  payload: designhomes,
})

export const addNewDesignHome = designhome => ({
  type: ADD_NEW_DESIGNHOME,
  payload: designhome,
})

export const addDesignHomeSuccess = designhome => ({
  type: ADD_DESIGNHOME_SUCCESS,
  payload: designhome,
})

export const addDesignHomeFail = error => ({
  type: ADD_DESIGNHOME_FAIL,
  payload: error,
})

export const getDesignHomesFail = error => ({
  type: GET_DESIGNHOMES_FAIL,
  payload: error,
})

export const getDesignHomeProfile = id => ({
  type: GET_DESIGNHOME_PROFILE,
  payload: id,
})

export const getDesignHomeProfileSuccess = designhomeProfile => ({
  type: GET_DESIGNHOME_PROFILE_SUCCESS,
  payload: designhomeProfile,
})

export const getDesignHomeProfileFail = error => ({
  type: GET_DESIGNHOME_PROFILE_FAIL,
  payload: error,
})

export const updateDesignHome = designhome => ({
  type: UPDATE_DESIGNHOME,
  payload: designhome,
})

// export const updateDesignHome = designhome => console.log(designhome);

export const updateDesignHomeSuccess = designhome => ({
  type: UPDATE_DESIGNHOME_SUCCESS,
  payload: designhome,
})

export const updateDesignHomeFail = error => ({
  type: UPDATE_DESIGNHOME_FAIL,
  payload: error,
})

export const deleteDesignHome = designhome => ({
  type: DELETE_DESIGNHOME,
  payload: designhome,
})

export const deleteDesignHomeSuccess = designhome => ({
  type: DELETE_DESIGNHOME_SUCCESS,
  payload: designhome,
})

export const deleteDesignHomeFail = error => ({
  type: DELETE_DESIGNHOME_FAIL,
  payload: error,
})

//Upload File
export const uploadDesignHomeImg = dataImg => ({
  type: UPLOAD_DESIGNHOMEIMG,
  payload: dataImg,
})

//change imgfirst
export const changeFirstDesignHome = id => ({
  type: CHANGEFIRST_DESIGNHOMEIMG,
  payload: id,
})

export const getChangeFirstDesignHomeSuccess = dataImg => ({
  type: CHANGEFIRST_DESIGNHOMEIMG_SUCCESS,
  payload: dataImg,
})

//del File
export const delImgDesignHome = id => ({
  type: DELETE_DESIGNHOMEIMG,
  payload: id,
})

export const getdelDesignHomeSuccess = dataImg => ({
  type: DELETE_DESIGNHOMEIMG_SUCCESS,
  payload: dataImg,
})
