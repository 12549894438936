import React from "react"
import { Container, Row, Col } from "reactstrap"

//Import Components
import MaterialBox from "./material-box"

//Import images
import feature1 from "../../../../assets/images/crypto/features-img/img-1.jpg"

const Materials = () => {
  const features1 = [
    { id: 1, desc: "ผนังภายนอกกรุไม้ตกแต่ง 6-8 มิล ผลิตภัณฑ์ในเคลือ scg" },
    { id: 2, desc: "ฉนวนกันร้อนและเสียง หนา 2 นิ้ว(ใยแก้ว)" },
    { id: 3, desc: "ผนังภายในกรุ 8 มิล" },
    { id: 4, desc: "ความสูง  3.2  เมตร (4 เมตร เพิ่ม ตรม 1,000 บาท)" },
    { id: 5, desc: "โครงสร้างคำนวนและออกแบบตามวิศวะกรใบ กว." },
    { id: 6, desc: "พื้นกรุไวนิลมีลาย" },
    { id: 7, desc: "ฝ้าเพดานฉาบเรียบ" },
    { id: 8, desc: "หน้าต่าง อลูมิเนียมดำ-ขาว" },
    { id: 9, desc: "ไฟ Led Circle 6-9 watt" },
    { id: 10, desc: "เหล็กกาวาไนซ์ กันสนิม" },
    { id: 11, desc: "ห้องน้ำ เพิ่ม 35,000 บาท กระเบื้องเซรามิก ผนังและพื้น" },
    { id: 12, desc: "ประตูหน้า หน้าต่างบานเลื่อน อลุมิเนียมดำ-ขาว" },
    { id: 13, desc: "กล่องควบคุมไฟ Schneider หรือเทียบเท่า" },
    { id: 14, desc: "เดินท่อร้อยสายไฟฟ้า สายดิน ทุกจุด" },
    { id: 15, desc: "อุปกรณ์ ไฟฟ้าประปา มาตรฐาน มอก." },
  ]

  return (
    <React.Fragment>
      <section className="section" id="material">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">Material</div>
                <h4>Key features of the product</h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pt-4">
            <Col md="6" sm="8">
              <div>
                <img
                  src={feature1}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
            <Col md="5" className="ms-auto">
              {/* featurebox */}
              <MaterialBox
                num="01"
                title="Material"
                features={features1}
                desc="เราเลือกใช้วัสดุที่เหมาะสมและใช้วิธีการการประกอบซึ่งผ่านประสบการณ์อย่างยาวนานเผื่อให้ทนทาน
                แข็งแรงและป้องกันความร้อนและเสียงสะท้อน ให้เหมาะกับสภาพอากาศและอายุการ
                ใช้งานที่ยาวนานทำให้ลูกค้าสามารถใช้งานบ้านได้อย่างตอบโจทย์และเหมาะสม วัสดุเกรดพรีเมียม"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Materials
