/* LIST_STRUCTURES */
export const GET_LIST_STRUCTURES = "GET_LIST_STRUCTURES"
export const GET_LIST_STRUCTURES_SUCCESS = "GET_LIST_STRUCTURES_SUCCESS"
export const GET_LIST_STRUCTURES_FAIL = "GET_LIST_STRUCTURES_FAIL"

/* LIST_STRUCTURES PROFILE */
export const GET_LIST_STRUCTURE_PROFILE = "GET_LIST_STRUCTURE_PROFILE"
export const GET_LIST_STRUCTURE_PROFILE_SUCCESS = "GET_LIST_STRUCTURE_PROFILE_SUCCESS"
export const GET_LIST_STRUCTURE_PROFILE_FAIL = "GET_LIST_STRUCTURE_PROFILE_FAIL"

/**
 * add structure
 */
export const ADD_NEW_LIST_STRUCTURE = "ADD_NEW_LIST_STRUCTURE"
export const ADD_LIST_STRUCTURE_SUCCESS = "ADD_LIST_STRUCTURE_SUCCESS"
export const ADD_LIST_STRUCTURE_FAIL = "ADD_LIST_STRUCTURE_FAIL"

/**
 * Edit structure
 */
export const UPDATE_LIST_STRUCTURE = "UPDATE_LIST_STRUCTURE"
export const UPDATE_LIST_STRUCTURE_SUCCESS = "UPDATE_LIST_STRUCTURE_SUCCESS"
export const UPDATE_LIST_STRUCTURE_FAIL = "UPDATE_LIST_STRUCTURE_FAIL"

/**
 * Delete structure
 */
export const DELETE_LIST_STRUCTURE = "DELETE_LIST_STRUCTURE"
export const DELETE_LIST_STRUCTURE_SUCCESS = "DELETE_LIST_STRUCTURE_SUCCESS"
export const DELETE_LIST_STRUCTURE_FAIL = "DELETE_LIST_STRUCTURE_FAIL"
