import {
  GET_DETAIL_CARD,
  GET_SLIPS_SUCCESS,
  GET_SLIPS_ONPROJECT_SUCCESS,
  GET_SLIPS_FAIL,
  ADD_SLIP_SUCCESS,
  ADD_SLIP_FAIL,
  UPDATE_SLIP_SUCCESS,
  UPDATE_SLIP_FAIL,
  DELETE_SLIP_SUCCESS,
  DELETE_SLIP_FAIL,
  GET_SLIP_PROFILE_SUCCESS,
  GET_SLIP_PROFILE_FAIL,
  GET_SLIP_SUM,
} from "./actionTypes"

const INIT_STATE = {
  slipproject: [],
  slipprojectProfile: {},
  slipDetailCard: [],
  slip_money_sum: {},
  slip_on_project: [],
  error: {},
}

const slipprojects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SLIPS_SUCCESS:
      return {
        ...state,
        slipproject: action.payload,
      }

    case GET_SLIPS_ONPROJECT_SUCCESS:
      return {
        ...state,
        slip_on_project: action.payload,
      }

    case GET_DETAIL_CARD:
      return {
        ...state,
        slipDetailCard: action.payload,
      }

    case GET_SLIP_SUM:
      return {
        ...state,
        slip_money_sum: action.payload,
      }

    case GET_SLIPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_SLIP_SUCCESS:
      // console.log("payload => ", action.payload)
      return {
        ...state,
        slipproject: [...state.slipproject, action.payload],
        // slipprojectProfile: [...state.slipprojectProfile, action.payload],
      }

    case ADD_SLIP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SLIP_PROFILE_SUCCESS:
      return {
        ...state,
        slipprojectProfile: action.payload,
      }

    case UPDATE_SLIP_SUCCESS:
      return {
        ...state,
        slipproject: state.slipproject.map(slipproject =>
          slipproject.id.toString() === action.payload.id.toString()
            ? { slipproject, ...action.payload }
            : slipproject
        ),
      }

    case UPDATE_SLIP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SLIP_SUCCESS:
      return {
        ...state,
        slipproject: state.slipproject.filter(
          slipproject =>
            slipproject.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_SLIP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SLIP_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default slipprojects
