import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
  Collapse,
} from "reactstrap"
import classnames from "classnames"

import { AvForm, AvInput } from "availity-reactstrap-validation"
import {
  getEmploymentConProjects as onGetEmploymentConProjects,
  updateEmploymentConProject as onUpdateEmploymentConProject,
} from "store/employments-con-project/actions"

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()

  const { employmentconprojects } = useSelector(state => ({
    employmentconprojects: state.employmentsConproject.employmentconprojects,
  }))

  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [employment_contract_1, setEmployment_contract_1] = React.useState([])
  const [employment_contract_2, setEmployment_contract_2] = React.useState([])

  const [col1, setCol1] = React.useState(false)
  const [col2, setCol2] = React.useState(false)

  async function checkAction() {
    setEmployment_contract_1([])
    setEmployment_contract_2([])

    if (actionModal.action == "Edit") {
      // console.log('1');
      dispatch(onGetEmploymentConProjects(actionModal.id))
    }

    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
  }

  const t_col1 = () => {
    setCol1(!col1)
    setCol2(false)
  }

  const t_col2 = () => {
    setCol2(!col2)
    setCol1(false)
  }

  const handleSubmit = async (event, errors, values) => {
    // console.log("1", 1)
    setButtonSubmit(true)
    if (errors.length > 0) {
    } else {
      if (action == "Add") {
      } else if (action == "Edit") {
        const data = new FormData()
        data.append(
          "employment_contract_1",
          JSON.stringify(employment_contract_1)
        )
        data.append(
          "employment_contract_2",
          JSON.stringify(employment_contract_2)
        )
        // update contractor
        // console.log("2", 2)

        dispatch(onUpdateEmploymentConProject(data))
      }
      setModal(!modal)
    }
    setButtonSubmit(false)
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      // console.log("employmentconprojects", employmentconprojects)

      // if (isEmpty(employmentconprojects)) {
      //   // setFormData(employmentconprojects)
      let { employment_contract_1, employment_contract_2, ...rest } =
        employmentconprojects
      // console.log("employment_contract_1", employment_contract_1)
      // console.log("employment_contract_2", employment_contract_2)
      // console.log("group_3", group_3)
      setEmployment_contract_1(employment_contract_1)
      setEmployment_contract_2(employment_contract_2)
      // }
    }
    return () => {}
  }, [employmentconprojects]) // Only re-run the effect if count changes

  function addRow_employment_contract_1() {
    const modifiedRows = [...employment_contract_1]
    modifiedRows.push({
      project_id: id,
      name: "",
      group: 1,
      del: 1,
    })
    setEmployment_contract_1(modifiedRows)
  }
  function addRow_employment_contract_2() {
    const modifiedRows = [...employment_contract_2]
    modifiedRows.push({
      project_id: id,
      name: "",
      group: 2,
      del: 1,
    })
    setEmployment_contract_2(modifiedRows)
  }

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
      size="lg"
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}เงื่อนไขใบเสนอราคา{" "}
      </ModalHeader>
      <AvForm onSubmit={handleSubmit}>
        <ModalBody>
          <h5>สามารถใส่ได้สูงสุด 1,000 อักษร</h5>
          <hr />
          <div className="accordion" id="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: !col1,
                  })}
                  type="button"
                  onClick={t_col1}
                  style={{ cursor: "pointer" }}
                >
                  ข้อที่ 1
                </button>
              </h2>

              <Collapse isOpen={col1} className="accordion-collapse">
                <div className="accordion-body">
                  <Row>
                    {(employment_contract_1 || []).map((item, index) =>
                      item.del == 0 ? null : (
                        <Col xs={12} key={index}>
                          <div className="hstack gap-3">
                            <div className="text-center">
                              <h4 style={{ width: "50px" }}>{index + 1}</h4>
                            </div>

                            <div style={{ width: "100%" }}>
                              <AvInput
                                name={"employment_contract_2_name" + index}
                                type="textarea"
                                className="form-control me-auto"
                                placeholder="หัวข้อเรื่อง"
                                value={employment_contract_1[index].name}
                                onChange={e => {
                                  let select = [...employment_contract_1]
                                  select[index].name = e.target.value
                                  setEmployment_contract_1(select)
                                }}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="vr"></div>
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={e => {
                                let select = [...employment_contract_1]
                                select[index].del = 0
                                setEmployment_contract_1(select)
                              }}
                            >
                              X
                            </button>
                          </div>
                        </Col>
                      )
                    )}
                    <Button
                      color="primary"
                      className="btn btn-primary "
                      size="sm"
                      onClick={e => addRow_employment_contract_1()}
                    >
                      เพิ่มใหม่
                    </Button>
                  </Row>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: !col2,
                  })}
                  type="button"
                  onClick={t_col2}
                  style={{ cursor: "pointer" }}
                >
                  ข้อที่ 2
                </button>
              </h2>

              <Collapse isOpen={col2} className="accordion-collapse">
                <div className="accordion-body">
                  <Row>
                    {(employment_contract_2 || []).map((item, index) =>
                      item.del == 0 ? null : (
                        <Col xs={12} key={index}>
                          <div className="hstack gap-3">
                            <div className="text-center">
                              <h4 style={{ width: "50px" }}>{index + 1}</h4>
                            </div>

                            <div style={{ width: "100%" }}>
                              <AvInput
                                name={"employment_contract_2_name" + index}
                                type="textarea"
                                className="form-control me-auto"
                                placeholder="หัวข้อเรื่อง"
                                value={employment_contract_2[index].name}
                                onChange={e => {
                                  let select = [...employment_contract_2]
                                  select[index].name = e.target.value
                                  setEmployment_contract_2(select)
                                }}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="vr"></div>
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={e => {
                                let select = [...employment_contract_2]
                                select[index].del = 0
                                setEmployment_contract_2(select)
                              }}
                            >
                              X
                            </button>
                          </div>
                        </Col>
                      )
                    )}
                    <Button
                      color="primary"
                      className="btn btn-primary "
                      size="sm"
                      onClick={e => addRow_employment_contract_2()}
                    >
                      เพิ่มใหม่
                    </Button>
                  </Row>
                </div>
              </Collapse>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            Save
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
