import {
  GET_QUOTATIONPROJECT_PROFILE,
  GET_QUOTATIONPROJECT_PROFILE_FAIL,
  GET_QUOTATIONPROJECT_PROFILE_SUCCESS,
  GET_QUOTATIONPROJECTS,
  GET_QUOTATIONPROJECTS_FAIL,
  GET_QUOTATIONPROJECTS_SUCCESS,
  ADD_NEW_QUOTATIONPROJECT,
  ADD_QUOTATIONPROJECT_SUCCESS,
  ADD_QUOTATIONPROJECT_FAIL,
  UPDATE_QUOTATIONPROJECT,
  UPDATE_QUOTATIONPROJECT_SUCCESS,
  UPDATE_QUOTATIONPROJECT_FAIL,
  DELETE_QUOTATIONPROJECT,
  DELETE_QUOTATIONPROJECT_SUCCESS,
  DELETE_QUOTATIONPROJECT_FAIL,
} from "./actionTypes"

export const getQuotationProjects = project_id => ({
  type: GET_QUOTATIONPROJECTS,
  payload: project_id,
})

export const getQuotationProjectsSuccess = quotationprojects => ({
  type: GET_QUOTATIONPROJECTS_SUCCESS,
  payload: quotationprojects,
})

export const addNewQuotationProject = quotationproject => ({
  type: ADD_NEW_QUOTATIONPROJECT,
  payload: quotationproject,
})

export const addQuotationProjectSuccess = quotationproject => ({
  type: ADD_QUOTATIONPROJECT_SUCCESS,
  payload: quotationproject,
})

export const addQuotationProjectFail = error => ({
  type: ADD_QUOTATIONPROJECT_FAIL,
  payload: error,
})

export const getQuotationProjectsFail = error => ({
  type: GET_QUOTATIONPROJECTS_FAIL,
  payload: error,
})

export const getQuotationProjectProfile = (id, type) => ({
  type: GET_QUOTATIONPROJECT_PROFILE,
  payload: { id, type },
})

export const getQuotationProjectProfileSuccess = quotationprojectProfile => ({
  type: GET_QUOTATIONPROJECT_PROFILE_SUCCESS,
  payload: quotationprojectProfile,
})

export const getQuotationProjectProfileFail = error => ({
  type: GET_QUOTATIONPROJECT_PROFILE_FAIL,
  payload: error,
})

export const updateQuotationProject = quotationproject => ({
  type: UPDATE_QUOTATIONPROJECT,
  payload: quotationproject,
})

// export const updateQuotationProject = quotationproject => console.log(quotationproject);

export const updateQuotationProjectSuccess = quotationproject => ({
  type: UPDATE_QUOTATIONPROJECT_SUCCESS,
  payload: quotationproject,
})

export const updateQuotationProjectFail = error => ({
  type: UPDATE_QUOTATIONPROJECT_FAIL,
  payload: error,
})

export const deleteQuotationProject = quotationproject => ({
  type: DELETE_QUOTATIONPROJECT,
  payload: quotationproject,
})

export const deleteQuotationProjectSuccess = quotationproject => ({
  type: DELETE_QUOTATIONPROJECT_SUCCESS,
  payload: quotationproject,
})

export const deleteQuotationProjectFail = error => ({
  type: DELETE_QUOTATIONPROJECT_FAIL,
  payload: error,
})
