import {
  GET_LIST_AIUMINIA_PROJECTS_SUCCESS,
  GET_LIST_AIUMINIA_PROJECTS_FAIL,
  ADD_LIST_AIUMINIA_PROJECTS_SUCCESS,
  ADD_LIST_AIUMINIA_PROJECTS_FAIL,
  UPDATE_LIST_AIUMINIA_PROJECTS_SUCCESS,
  UPDATE_LIST_AIUMINIA_PROJECTS_FAIL,
  DELETE_LIST_AIUMINIA_PROJECTS_SUCCESS,
  DELETE_LIST_AIUMINIA_PROJECTS_FAIL,
  GET_LIST_AIUMINIA_PROJECTS_PROFILE_SUCCESS,
  GET_LIST_AIUMINIA_PROJECTS_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  listAluminiaProjects: [],
  listAluminiaProjectProfile: {},
  error: {},
}

const listAluminiaProjects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_AIUMINIA_PROJECTS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        listAluminiaProjects: action.payload,
      }

    case GET_LIST_AIUMINIA_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_LIST_AIUMINIA_PROJECTS_SUCCESS:
      return {
        ...state,
        listAluminiaProjects: [...state.listAluminiaProjects, action.payload],
      }

    case ADD_LIST_AIUMINIA_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_AIUMINIA_PROJECTS_PROFILE_SUCCESS:
      // console.log("GET_LIST_AIUMINIA_PROJECTS_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        listAluminiaProjectProfile: action.payload,
      }

    case UPDATE_LIST_AIUMINIA_PROJECTS_SUCCESS:
      return {
        ...state,
        listAluminiaProjects: state.listAluminiaProjects.map(aluminia =>
          aluminia.id.toString() === action.payload.id.toString()
            ? { aluminia, ...action.payload }
            : aluminia
        ),
      }

    case UPDATE_LIST_AIUMINIA_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_LIST_AIUMINIA_PROJECTS_SUCCESS:
      return {
        ...state,
        listAluminiaProjects: state.listAluminiaProjects.filter(
          aluminia =>
            aluminia.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_LIST_AIUMINIA_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_AIUMINIA_PROJECTS_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default listAluminiaProjects
