import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_SLIP_ON_PROJECT,
  GET_SLIPS,
  GET_SLIP_PROFILE,
  ADD_NEW_SLIP,
  DELETE_SLIP,
  UPDATE_SLIP,
  UPLOAD_INFOPROJECTFILESLIP,
} from "./actionTypes"

import {
  getSlipprojectsSuccess,
  getSlipOnProjectsSuccess,
  getSlipDetailCard,
  getSlipSum,
  getSlipprojectsFail,
  getSlipprojectProfileSuccess,
  getSlipprojectProfileFail,
  addSlipprojectFail,
  addSlipprojectSuccess,
  updateSlipprojectSuccess,
  updateSlipprojectFail,
  deleteSlipprojectSuccess,
  deleteSlipprojectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSlipOnProject,
  getSlipprojects,
  getSlipprojectProfile,
  addNewSlipproject,
  updateSlipproject,
  deleteSlipproject,
  uploadImgSlipInfoProject,
} from "../../helpers/fakebackend_helper"

function* fetchSlipprojects({ payload: id }) {
  try {
    const response = yield call(getSlipprojects, id)
    // console.log(response);
    yield put(getSlipprojectsSuccess(response.table))
    yield put(getSlipDetailCard(response.detailCard))
    yield put(getSlipSum(response.sumData))
  } catch (error) {
    yield put(getSlipprojectsFail(error))
  }
}

function* fetchSlipOnProjects({ payload: id }) {
  // console.log("'fetchSlipOnProjects'", id)
  try {
    const response = yield call(getSlipOnProject, id)
    // console.log("response", response)
    yield put(getSlipOnProjectsSuccess(response))
  } catch (error) {
    yield put(getSlipprojectsFail(error))
  }
}

function* fetchSlipprojectProfile({ payload: id }) {
  try {
    const response = yield call(getSlipprojectProfile, id)
    // console.log(response);
    yield put(getSlipprojectProfileSuccess(response))
  } catch (error) {
    yield put(getSlipprojectProfileFail(error))
  }
}

function* onUpdateSlipproject({ payload: slipproject }) {
  try {
    // let x = slipproject.get('formData')
    // console.log(x)
    const response = yield call(updateSlipproject, slipproject)
    // console.log("response", response)
    yield put(updateSlipprojectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateSlipprojectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
    // console.log("Error")
  }
}

function* onDeleteSlipproject({ payload: slipproject }) {
  try {
    const response = yield call(deleteSlipproject, slipproject)
    yield put(deleteSlipprojectSuccess(response))
  } catch (error) {
    yield put(deleteSlipprojectFail(error))
  }
}

function* onAddNewSlipproject({ payload: slipproject }) {
  try {
    const response = yield call(addNewSlipproject, slipproject)
    yield put(addSlipprojectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addSlipprojectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* onUploadFileHomeSlip({ payload: img }) {
  // console.log("load =>", img)
  try {
    const response = yield call(uploadImgSlipInfoProject, img)
    // console.log("response", response)
    if (response.type == "quick") {
      yield put(getSlipprojectsSuccess(response.table))
      yield put(getSlipDetailCard(response.detailCard))
      yield put(getSlipSum(response.sumData))
    } else {
      yield put(addSlipprojectSuccess(response))
    }
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพโหลด",
      message: "อัพโหลดสำเร็จ",
    })
  } catch (error) {
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพโหลด",
      message: "อัพโหลดล้มเหลว",
    })
  }
}

function* slipProjectsSaga() {
  yield takeEvery(GET_SLIPS, fetchSlipprojects)
  yield takeEvery(GET_SLIP_ON_PROJECT, fetchSlipOnProjects)
  yield takeEvery(GET_SLIP_PROFILE, fetchSlipprojectProfile)
  yield takeEvery(ADD_NEW_SLIP, onAddNewSlipproject)
  yield takeEvery(UPDATE_SLIP, onUpdateSlipproject)
  yield takeEvery(DELETE_SLIP, onDeleteSlipproject)
  yield takeEvery(UPLOAD_INFOPROJECTFILESLIP, onUploadFileHomeSlip)
}

export default slipProjectsSaga
