import React from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { isEmpty } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "../../pages/Tables/datatables.scss"
import ExampleModalAluminia from "../../components/Dialogs/dialogList_Aluminia"

// API store
import { getListAluminias as onGetList_aluminias } from "store/list-aluminias/actions"

const ExampleModal = ({ actionModal, callBack }) => {
  const dispatch = useDispatch()

  const { ListAluminias } = useSelector(state => ({
    ListAluminias: state.listAluminias.listAluminias,
  }))
  const [selectType, setSelectType] = React.useState(1)
  const [actionModelAluminia, setActionModelAluminia] = React.useState({
    id: null,
    action: "Add",
    modal: false,
  })

  function eventActionAluminia(action, id, modal) {
    setActionModelAluminia({
      id: id,
      action: action,
      modal: modal,
    })
  }

  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "select",
      text: "เลือก",
      sort: false,

      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return (
          <div className="d-flex">
            <Button
              onClick={() => selectOnCallBack(row)}
              className="mr-1 mb-1 "
              color="success"
              outline
            >
              <i className="bx bx-comment-check"></i>
            </Button>
          </div>
        )
      },
    },
    {
      dataField: "path_img",
      text: "รูป",
      sort: true,
      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return !isEmpty(cell) ? (
          <img
            onClick={() => eventActionAluminia("Edit", row.id, true)}
            src={process.env.REACT_APP_SERVER + cell}
            alt={process.env.REACT_APP_NAMECOMPANY}
            title={process.env.REACT_APP_NAMECOMPANY}
            className="avatar-md"
          />
        ) : null
      },
    },
    {
      dataField: "name",
      text: "ชื่อ",
      sort: true,
    },

    // {
    //   dataField: "size",
    //   text: "ขนาด กว้าง*ยาว",
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: "200px" }
    //   },
    //   formatter: function action(cell, row) {
    //     return <span>{cell}</span>
    //   },
    // },

    {
      dataField: "id",
      text: "จัดการ",
      sort: false,
      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return (
          <div>
            <Button
              onClick={() => eventActionAluminia("Edit", cell, true)}
              className="mr-1 mb-1"
              color="primary"
              outline
            >
              <i className="bx bx-pencil"></i>
            </Button>
          </div>
        )
      },
    },
  ])

  // Table Data
  const [tableData, setTableData] = React.useState(ListAluminias) // set campaign as default
  const [modal, setModal] = React.useState(false)

  async function checkAction() {
    setModal(actionModal.modal)
  }

  function selectOnCallBack(data) {
    callBack(data)
    setModal(false)
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
      dispatch(onGetList_aluminias(selectType, "boq"))
    }
    return () => {}
  }, [actionModal]) // Only re-run the effect if count changes

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  // React.useEffect(() => {
  //   dispatch(onGetList_aluminias(selectType, 0))
  // }, [dispatch])

  React.useEffect(() => {
    dispatch(onGetList_aluminias(selectType, "boq"))
  }, [selectType])

  React.useEffect(async () => {
    if (!isEmpty(ListAluminias)) {
      setTableData(ListAluminias)
    } else {
      setTableData([])
    }
  }, [ListAluminias])

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        centered={true}
        size="lg"
        style={{ maxWidth: "90%" }}
      >
        <ModalHeader toggle={() => setModal(!modal)}>
          กลุ่ม อลูมิเนียม
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-12">
              <ExampleModalAluminia actionModal={actionModelAluminia} />

              <Card>
                <CardBody>
                  <CardTitle className="h4">ListAluminias Datatable </CardTitle>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={tableColumns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={tableColumns}
                        data={tableData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="12" style={{ textAlign: "right" }}>
                                <FormGroup>
                                  <FormGroup check inline>
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="radioActive"
                                        checked={selectType == 1}
                                        value={1 || ""}
                                        onChange={e =>
                                          setSelectType(e.target.value)
                                        }
                                      />
                                      เปิดใช้งาน
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check inline>
                                    <Label>
                                      <Input
                                        type="radio"
                                        name="radioActive"
                                        checked={selectType == 2}
                                        value={2 || ""}
                                        onChange={e =>
                                          setSelectType(e.target.value)
                                        }
                                      />
                                      ปิดใช้งาน
                                    </Label>
                                  </FormGroup>
                                </FormGroup>
                              </Col>

                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={() =>
                                      eventActionAluminia("Add", null, true)
                                    }
                                  >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    Add
                                  </Button>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered
                                    striped
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
  callBack: PropTypes.object,
}

export default ExampleModal
