/* CUSTOM_PROJECTS */
export const GET_CUSTOM_PROJECTS = "GET_CUSTOM_PROJECTS"
export const GET_CUSTOM_PROJECTS_SUCCESS = "GET_CUSTOM_PROJECTS_SUCCESS"
export const GET_CUSTOM_PROJECTS_FAIL = "GET_CUSTOM_PROJECTS_FAIL"

/* CUSTOM_PROJECTS PROFILE */
export const GET_CUSTOM_PROJECT_PROFILE = "GET_CUSTOM_PROJECT_PROFILE"
export const GET_CUSTOM_PROJECT_PROFILE_SUCCESS = "GET_CUSTOM_PROJECT_PROFILE_SUCCESS"
export const GET_CUSTOM_PROJECT_PROFILE_FAIL = "GET_CUSTOM_PROJECT_PROFILE_FAIL"

/**
 * add customproject
 */
export const ADD_NEW_CUSTOM_PROJECT = "ADD_NEW_CUSTOM_PROJECT"
export const ADD_CUSTOM_PROJECT_SUCCESS = "ADD_CUSTOM_PROJECT_SUCCESS"
export const ADD_CUSTOM_PROJECT_FAIL = "ADD_CUSTOM_PROJECT_FAIL"

/**
 * Edit customproject
 */
export const UPDATE_CUSTOM_PROJECT = "UPDATE_CUSTOM_PROJECT"
export const UPDATE_CUSTOM_PROJECT_SUCCESS = "UPDATE_CUSTOM_PROJECT_SUCCESS"
export const UPDATE_CUSTOM_PROJECT_FAIL = "UPDATE_CUSTOM_PROJECT_FAIL"

/**
 * Delete customproject
 */
export const DELETE_CUSTOM_PROJECT = "DELETE_CUSTOM_PROJECT"
export const DELETE_CUSTOM_PROJECT_SUCCESS = "DELETE_CUSTOM_PROJECT_SUCCESS"
export const DELETE_CUSTOM_PROJECT_FAIL = "DELETE_CUSTOM_PROJECT_FAIL"
