import {
  GET_LIST_AIUMINIA_PROJECTS_PROFILE,
  GET_LIST_AIUMINIA_PROJECTS_PROFILE_FAIL,
  GET_LIST_AIUMINIA_PROJECTS_PROFILE_SUCCESS,
  GET_LIST_AIUMINIA_PROJECTS,
  GET_LIST_AIUMINIA_PROJECTS_FAIL,
  GET_LIST_AIUMINIA_PROJECTS_SUCCESS,
  ADD_NEW_LIST_AIUMINIA_PROJECTS,
  ADD_LIST_AIUMINIA_PROJECTS_SUCCESS,
  ADD_LIST_AIUMINIA_PROJECTS_FAIL,
  UPDATE_LIST_AIUMINIA_PROJECTS,
  UPDATE_LIST_AIUMINIA_PROJECTS_SUCCESS,
  UPDATE_LIST_AIUMINIA_PROJECTS_FAIL,
  DELETE_LIST_AIUMINIA_PROJECTS,
  DELETE_LIST_AIUMINIA_PROJECTS_SUCCESS,
  DELETE_LIST_AIUMINIA_PROJECTS_FAIL,
} from "./actionTypes"

export const getListAluminiaProjects = (active, type) => ({
  type: GET_LIST_AIUMINIA_PROJECTS,
  payload: { active, type },
})

export const getListAluminiaProjectsSuccess = aluminias => ({
  type: GET_LIST_AIUMINIA_PROJECTS_SUCCESS,
  payload: aluminias,
})

export const addNewListAluminiaProjects = aluminia => ({
  type: ADD_NEW_LIST_AIUMINIA_PROJECTS,
  payload: aluminia,
})

export const addListAluminiaProjectsSuccess = aluminia => ({
  type: ADD_LIST_AIUMINIA_PROJECTS_SUCCESS,
  payload: aluminia,
})

export const addListAluminiaProjectsFail = error => ({
  type: ADD_LIST_AIUMINIA_PROJECTS_FAIL,
  payload: error,
})

export const getListAluminiaProjectsFail = error => ({
  type: GET_LIST_AIUMINIA_PROJECTS_FAIL,
  payload: error,
})

export const getListAluminiaProjectsProfile = id => ({
  type: GET_LIST_AIUMINIA_PROJECTS_PROFILE,
  payload: id,
})

export const getListAluminiaProjectsProfileSuccess = aluminiaProfile => ({
  type: GET_LIST_AIUMINIA_PROJECTS_PROFILE_SUCCESS,
  payload: aluminiaProfile,
})

export const getListAluminiaProjectsProfileFail = error => ({
  type: GET_LIST_AIUMINIA_PROJECTS_PROFILE_FAIL,
  payload: error,
})

export const updateListAluminiaProjects = aluminia => ({
  type: UPDATE_LIST_AIUMINIA_PROJECTS,
  payload: aluminia,
})

// export const updateListAluminiaProjects = aluminia => console.log(aluminia);

export const updateListAluminiaProjectsSuccess = aluminia => ({
  type: UPDATE_LIST_AIUMINIA_PROJECTS_SUCCESS,
  payload: aluminia,
})

export const updateListAluminiaProjectsFail = error => ({
  type: UPDATE_LIST_AIUMINIA_PROJECTS_FAIL,
  payload: error,
})

export const deleteListAluminiaProjects = aluminia => ({
  type: DELETE_LIST_AIUMINIA_PROJECTS,
  payload: aluminia,
})

export const deleteListAluminiaProjectsSuccess = aluminia => ({
  type: DELETE_LIST_AIUMINIA_PROJECTS_SUCCESS,
  payload: aluminia,
})

export const deleteListAluminiaProjectsFail = error => ({
  type: DELETE_LIST_AIUMINIA_PROJECTS_FAIL,
  payload: error,
})
