//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/auth/login"
export const POST_FAKE_JWT_LOGIN = "/auth/login"
export const POST_FAKE_JWT_LOGOUT = "/auth/logout"

export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/calendars/onGet"
export const ADD_NEW_EVENT = "/calendars"
export const UPDATE_EVENT = "/calendars"
export const DELETE_EVENT = "/calendars"
// export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/projects"
export const ADD_NEW_PROJECT = "/projects"
export const UPDATE_PROJECT = "/projects"
export const DELETE_PROJECT = "/projects"

//INFO PROJECTS
export const GET_INFOCONTRACT = "/add_infos/contract"
export const GET_INFOPROJECTS = "/add_infos"
export const GET_INFOPROJECT_PROFILE = "/add_info"
export const ADD_NEW_INFOPROJECT = "/add_infos"
export const UPDATE_INFOPROJECT = "/add_infos"
export const DELETE_INFOPROJECT = "/add_infos"
export const UPLOAD_FILEHOMEINFOPROJECT = "/add_info/img_home"

//REF PROJECTS
export const GET_REFPROJECTS = "/add_infos/get_ref_project"
export const GET_REFPROJECT_PROFILE = "/add_infos/get_ref_project"
export const ADD_NEW_REFPROJECT = "/add_infos"
export const UPDATE_REFPROJECT = "/add_infos"
export const DELETE_REFPROJECT = "/add_infos"
export const UPLOAD_INFOPROJECTFILEREF = "/add_infos/add_info_imgs"

//SERWAY PROJECTS
export const GET_SERWAYPROJECTS = "/serways/get_serway_project"
export const GET_SERWAYPROJECT_PROFILE = "/serways/get_serway_project"
export const ADD_NEW_SERWAYPROJECT = "/serways"
export const UPDATE_SERWAYPROJECT = "/serways"
export const DELETE_SERWAYPROJECT = "/serways"
export const UPLOAD_INFOPROJECTFILESERWAY = "/serways/add_imgs"

//SLIP PROJECTS
export const GET_SLIPPROJECTS = "/add_moneys/get_money_project"
export const GET_SLIPPROJECT_PROFILE = "/add_moneys"
export const ADD_NEW_SLIPPROJECT = "/add_moneys"
export const UPDATE_SLIPPROJECT = "/add_moneys"
export const DELETE_SLIPPROJECT = "/add_moneys"
export const UPLOAD_INFOPROJECTFILESLIP = "/add_moneys/add_imgs"

//QUOTATION PROJECTS
export const GET_QUOTATIONPROJECTS = "/add_quos/get_by_project"
export const GET_QUOTATIONPROJECT_PROFILE = "/add_quos"
export const ADD_NEW_QUOTATIONPROJECT = "/add_quos"
export const UPDATE_QUOTATIONPROJECT = "/add_quos"
export const DELETE_QUOTATIONPROJECT = "/add_quos"

//QUOTATION CON PROJECTS
export const GET_QUOTATIONCONPROJECTS = "/quotationconlist"
export const GET_QUOTATIONCONPROJECT_PROFILE = "/quotationconlist"
export const ADD_NEW_QUOTATIONCONPROJECT = "/quotationconlist"
export const UPDATE_QUOTATIONCONPROJECT = "/quotationconlist"
export const DELETE_QUOTATIONCONPROJECT = "/quotationconlist"

//CONTACT CON PROJECTS
export const GET_CONTACTCONPROJECTS = "/contactconlist"
export const GET_CONTACTCONPROJECT_PROFILE = "/contactconlist"
export const ADD_NEW_CONTACTCONPROJECT = "/contactconlist"
export const UPDATE_CONTACTCONPROJECT = "/contactconlist"
export const DELETE_CONTACTCONPROJECT = "/contactconlist"

//EMPLOYMENT CON PROJECTS
export const GET_EMPLOYMENTCONPROJECTS = "/employmentconlist"
export const GET_EMPLOYMENTCONPROJECT_PROFILE = "/employmentconlist"
export const ADD_NEW_EMPLOYMENTCONPROJECT = "/employmentconlist"
export const UPDATE_EMPLOYMENTCONPROJECT = "/employmentconlist"
export const DELETE_EMPLOYMENTCONPROJECT = "/employmentconlist"

//PROFIT LOSS PROJECTS
export const GET_PROFITLOSSS = "/profit_loss"
export const GET_PROFITLOSS_PROFILE = "/profit_loss"
export const ADD_NEW_PROFITLOSS = "/profit_loss"
export const UPDATE_PROFITLOSS = "/profit_loss"
export const DELETE_PROFITLOSS = "/profit_loss"

//TASKS
export const GET_TASKS = "/tasks"

// DESIGNHOMES
export const GET_DESIGNHOMES = "/slide/landing"
export const GET_DESIGNHOME_PROFILE = "/slide"
export const ADD_NEW_DESIGNHOME = "/slide"
export const UPDATE_DESIGNHOME = "/slide"
export const DELETE_DESIGNHOME = "/slide"
export const UPLOAD_DESIGNHOMEIMG = "/slide/slide_imgs"
export const CHANGEFIRST_DESIGNHOMEIMG = "/slider_shop_img/first_img"
export const DELETE_DESIGNHOMEIMG = "/slider_shop_img"

//SETTING
export const GET_USERGROUPS = "/user_groups"
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/users"
export const ADD_NEW_USER = "/users"
export const UPDATE_USER = "/users"
export const DELETE_USER = "/users"

// CONSTRUCTIONS
export const GET_CONSTRUCTIONS = "/contractors"
export const GET_CONTRACTOR_PROFILE = "/contractors"
export const ADD_NEW_CONTRACTOR = "/contractors"
export const UPDATE_CONTRACTOR = "/contractors"
export const DELETE_CONTRACTOR = "/contractors"

// LIST_STRUCTURES
export const GET_LIST_STRUCTURES = "/list_structure"
export const GET_LIST_STRUCTURE_PROFILE = "/list_structure"
export const ADD_NEW_LIST_STRUCTURE = "/list_structure"
export const UPDATE_LIST_STRUCTURE = "/list_structure"
export const DELETE_LIST_STRUCTURE = "/list_structure"

// LIST_AIUMINIASS
export const GET_LIST_AIUMINIAS = "/aluminiums"
export const GET_LIST_AIUMINIA_PROFILE = "/aluminiums"
export const ADD_NEW_LIST_AIUMINIA = "/aluminiums"
export const UPDATE_LIST_AIUMINIA = "/aluminiums"
export const DELETE_LIST_AIUMINIA = "/aluminiums"

// LIST_AIUMINIA_PROJECTS
export const GET_LIST_AIUMINIA_PROJECTS = "/aluminium_projects"
export const GET_LIST_AIUMINIA_PROJECT_PROFILE = "/aluminium_projects"
export const ADD_NEW_LIST_AIUMINIA_PROJECT = "/aluminium_projects"
export const UPDATE_LIST_AIUMINIA_PROJECT = "/aluminium_projects"
export const DELETE_LIST_AIUMINIA_PROJECT = "/aluminium_projects"

// BOQ_HOMES
export const GET_BOQ_HOMES = "/boqs"
export const GET_BOQ_HOME_PROFILE = "/boqs"
export const ADD_NEW_BOQ_HOME = "/boqs"
export const UPDATE_BOQ_HOME = "/boqs"
export const DELETE_BOQ_HOME = "/boqs"

// BOQ_TITLE_HOMES
export const GET_BOQ_TITLE_HOMES = "/boq_titles"
export const GET_BOQ_TITLE_HOME_PROFILE = "/boq_titles"
export const ADD_NEW_BOQ_TITLE_HOME = "/boq_titles"
export const UPDATE_BOQ_TITLE_HOME = "/boq_titles"
export const DELETE_BOQ_TITLE_HOME = "/boq_titles"

// LIST_PRODUCTS
export const GET_LIST_PRODUCTS = "/list_product"
export const GET_LIST_PRODUCT_PROFILE = "/list_product"
export const ADD_NEW_LIST_PRODUCT = "/list_product"
export const UPDATE_LIST_PRODUCT = "/list_product"
export const DELETE_LIST_PRODUCT = "/list_product"

// LIST_QCS
export const GET_LIST_QCS = "/check_qc"
export const GET_LIST_QC_PROFILE = "/check_qc"
export const ADD_NEW_LIST_QC = "/check_qc"
export const UPDATE_LIST_QC = "/check_qc"
export const DELETE_LIST_QC = "/check_qc"

// LIST_DESIGNGROUPS
export const GET_LIST_DESIGNGROUPS = "/design_groups"
export const GET_LIST_DESIGNGROUP_PROFILE = "/design_groups"
export const ADD_NEW_LIST_DESIGNGROUP = "/design_groups"
export const UPDATE_LIST_DESIGNGROUP = "/design_groups"
export const DELETE_LIST_DESIGNGROUP = "/design_groups"

// PRODUCT_GROUPS
export const GET_PRODUCT_GROUPS = "/product_groups"
export const GET_PRODUCT_GROUP_PROFILE = "/product_groups"
export const ADD_NEW_PRODUCT_GROUP = "/product_groups"
export const UPDATE_PRODUCT_GROUP = "/product_groups"
export const DELETE_PRODUCT_GROUP = "/product_groups"

// ATTENDS
export const GET_ATTENDS = "/attends"
export const GET_ATTEND_PROFILE = "/attends"
export const ADD_NEW_ATTEND = "/attends"
export const UPDATE_ATTEND = "/attends"
export const DELETE_ATTEND = "/attends"

// BANK
export const GET_BANKS = "/banks"
export const GET_BANK_PROFILE = "/banks"
export const ADD_NEW_BANK = "/banks"
export const UPDATE_BANK = "/banks"
export const DELETE_BANK = "/banks"

// MATERIAL_PRICES
export const GET_MATERIAL_PRICES = "/material_prices"
export const GET_MATERIAL_PRICE_PROFILE = "/material_prices"
export const ADD_NEW_MATERIAL_PRICE = "/material_prices"
export const UPDATE_MATERIAL_PRICE = "/material_prices"
export const DELETE_MATERIAL_PRICE = "/material_prices"

// MATERIAL_TYPES
export const GET_MATERIAL_TYPES = "/material_types"
export const GET_MATERIAL_TYPE_PROFILE = "/material_types"
export const ADD_NEW_MATERIAL_TYPE = "/material_types"
export const UPDATE_MATERIAL_TYPE = "/material_types"
export const DELETE_MATERIAL_TYPE = "/material_types"

// MESSAGE_LISTS
export const GET_MESSAGE_LISTS = "/messagelists"
export const GET_MESSAGE_LIST_PROFILE = "/messagelists"
export const ADD_NEW_MESSAGE_LIST = "/messagelists"
export const UPDATE_MESSAGE_LIST = "/messagelists"
export const DELETE_MESSAGE_LIST = "/messagelists"

// WITHDRAW_MATERIALS
export const GET_WITHDRAW_MATERIALS = "/withdraw_material"
export const GET_WITHDRAW_MATERIAL_PROFILE = "/withdraw_material"
export const ADD_NEW_WITHDRAW_MATERIAL = "/withdraw_material"
export const UPDATE_WITHDRAW_MATERIAL = "/withdraw_material"
export const DELETE_WITHDRAW_MATERIAL = "/withdraw_material"

// MATERIAL_STOCKS
export const GET_MATERIAL_STOCKS = "/material_stocks"
export const GET_MATERIAL_STOCK_PROFILE = "/material_stocks"
export const ADD_NEW_MATERIAL_STOCK = "/material_stocks"
export const UPDATE_MATERIAL_STOCK = "/material_stocks"
export const DELETE_MATERIAL_STOCK = "/material_stocks"

// MATERIAL_SHOPS
export const GET_MATERIAL_SHOPS = "/shops"
export const GET_MATERIAL_SHOP_PROFILE = "/shops"
export const ADD_NEW_MATERIAL_SHOP = "/shops"
export const UPDATE_MATERIAL_SHOP = "/shops"
export const DELETE_MATERIAL_SHOP = "/shops"

// DESIGNS
export const GET_DESIGNS = "/designs/get"
export const GET_DESIGN_PROFILE = "/designs"
export const ADD_NEW_DESIGN = "/designs"
export const UPDATE_DESIGN = "/designs"
export const DELETE_DESIGN = "/designs/del"

// CUSTOM_PROJECTS
export const GET_CUSTOM_PROJECTS = "/custom_projects/get"
export const GET_CUSTOM_PROJECT_PROFILE = "/custom_projects"
export const ADD_NEW_CUSTOM_PROJECT = "/custom_projects"
export const UPDATE_CUSTOM_PROJECT = "/custom_projects"
export const DELETE_CUSTOM_PROJECT = "/custom_projects/del"

// COMMENT_HOMES
export const GET_COMMENT_HOMES = "/comment_homes/get"
export const NOTI_COMMENT_HOMES = "/comment_homes/noti"
export const GET_COMMENT_HOME_PROFILE = "/comment_homes"
export const ADD_NEW_COMMENT_HOME = "/comment_homes"
export const UPDATE_COMMENT_HOME = "/comment_homes"
export const DELETE_COMMENT_HOME = "/comment_homes"

// COMMENT_HOMES
export const GET_CUSTOM_HOMES = "/custom_homes/get"
export const GET_CUSTOM_HOME_PROFILE = "/custom_homes"
export const ADD_NEW_CUSTOM_HOME = "/custom_homes"
export const UPDATE_CUSTOM_HOME = "/custom_homes"
export const DELETE_CUSTOM_HOME = "/custom_homes"

// BOQS
export const GET_BOQS = "/boqs"
export const GET_BOQ_PROFILE = "/boqs"
export const ADD_NEW_BOQ = "/boqs"
export const UPLOAD_IMG_CHECK_BOQ = "/boqs/upload_img_check_img"
export const UPDATE_BOQ = "/boqs"
export const DELETE_BOQ = "/boqs"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"
