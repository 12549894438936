/* PRODUCT_GROUPS */
export const GET_PRODUCT_GROUPS = "GET_PRODUCT_GROUPS"
export const GET_PRODUCT_GROUPS_SUCCESS = "GET_PRODUCT_GROUPS_SUCCESS"
export const GET_PRODUCT_GROUPS_FAIL = "GET_PRODUCT_GROUPS_FAIL"

/* PRODUCT_GROUPS PROFILE */
export const GET_PRODUCT_GROUP_PROFILE = "GET_PRODUCT_GROUP_PROFILE"
export const GET_PRODUCT_GROUP_PROFILE_SUCCESS = "GET_PRODUCT_GROUP_PROFILE_SUCCESS"
export const GET_PRODUCT_GROUP_PROFILE_FAIL = "GET_PRODUCT_GROUP_PROFILE_FAIL"

/**
 * add designgroup
 */
export const ADD_NEW_PRODUCT_GROUP = "ADD_NEW_PRODUCT_GROUP"
export const ADD_PRODUCT_GROUP_SUCCESS = "ADD_PRODUCT_GROUP_SUCCESS"
export const ADD_PRODUCT_GROUP_FAIL = "ADD_PRODUCT_GROUP_FAIL"

/**
 * Edit designgroup
 */
export const UPDATE_PRODUCT_GROUP = "UPDATE_PRODUCT_GROUP"
export const UPDATE_PRODUCT_GROUP_SUCCESS = "UPDATE_PRODUCT_GROUP_SUCCESS"
export const UPDATE_PRODUCT_GROUP_FAIL = "UPDATE_PRODUCT_GROUP_FAIL"

/**
 * Delete designgroup
 */
export const DELETE_PRODUCT_GROUP = "DELETE_PRODUCT_GROUP"
export const DELETE_PRODUCT_GROUP_SUCCESS = "DELETE_PRODUCT_GROUP_SUCCESS"
export const DELETE_PRODUCT_GROUP_FAIL = "DELETE_PRODUCT_GROUP_FAIL"
