import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import ExampleModal from "../../components/Dialogs/dialogAttends"
import { addNewAttend as onAddNewAttend } from "store/attends/actions"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work
const Attedns = () => {
  const dispatch = useDispatch()
  const [latitude, setLatitude] = React.useState(undefined)
  const [longitude, setLongitude] = React.useState(undefined)
  const [showErrorText, setShowErrorText] = React.useState(undefined)


  const [actionModal, setActionModel] = React.useState({
    id: null,
    action: null,
    modal: false,
  })
  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError)
    } else {
      // x.innerHTML = "Geolocation is not supported by this browser."
      setShowErrorText("กรุณาเปลื่ยนบราวเซอร์อื่น")
    }
  }
  function showPosition(position) {
    setLatitude(position.coords.latitude)
    setLongitude(position.coords.longitude)
    const obj = JSON.parse(localStorage.getItem("authUser"))

    let time_house = moment().format("HH")
    let time_minus = moment().format("mm")
    let status = 1

    if (time_house > 9) {
      status = 2
    } else if (time_house == 9) {
      if (time_minus > 0) {
        status = 2
      } else {
        status = 1
      }
    } else {
      status = 1
    }
    const data = new FormData()
    data.append("user_id", obj.me.id)
    data.append("time_house", time_house)
    data.append("time_minus", time_minus)
    data.append("status", status)
    data.append("note", "")
    data.append("latitude", position.coords.latitude)
    data.append("longitude", position.coords.longitude)
    dispatch(onAddNewAttend(data))

    setShowErrorText(undefined)
  }

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setShowErrorText("ผู้ใช้งานไม่อนุญาติให้เข้าถึงที่ตั้งตำแหน่ง")
        break
      case error.POSITION_UNAVAILABLE:
        setShowErrorText("ข้อมูลตำแหน่งไม่สามารถใช้ได้")
        break
      case error.TIMEOUT:
        setShowErrorText("หมดเวลาดึง ละติจูด/ลองจิจูด")
        break
      case error.UNKNOWN_ERROR:
        setShowErrorText("ไม่สามารถแก้ปัญหาได้")
        break
    }
  }

  function eventAction(action, id, modal) {
    setActionModel({
      id: id,
      action: action,
      modal: modal,
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Attend | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>
        <div className="container-fluid">
          <ExampleModal actionModal={actionModal} />

          <Row>
            <Col sm="12">
              <Card>
                <CardBody className="d-grid gap-2">
                  {showErrorText == undefined ? null : (
                    <div className="bg-danger p-4 text-center">
                      <p className="my-2 text-white">{showErrorText}</p>
                    </div>
                  )}

                  <Button
                    onClick={e => {
                      getLocation()
                    }}
                    block
                    color="primary"
                  >
                    เช็คเวลาเข้างาน
                  </Button>
                  <Button
                    block
                    color="warning"
                    onClick={e => {
                      eventAction("Add", null, true)
                    }}
                  >
                    เขียนใบลางาน
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

// export default Attedns
export default withRouter(Attedns)
