import React from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import Dropzone from "react-dropzone"
import { isEmpty, map } from "lodash"
import imageCompression from "browser-image-compression"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { uploadImgInfoSlip as onUploadImgInfoSlip } from "store/slips-project/actions"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work
import ExampleModal from "components/Dialogs/dialogAdd_money"
import { getCommentHomes as onGetCommentHomes } from "store/comment-homes/actions"

const MonthlyEarning = () => {
  const dispatch = useDispatch()

  const { commentHomes } = useSelector(state => ({
    commentHomes: state.commentHomes.commentHomes,
  }))

  const [timeStart, setTimeStart] = React.useState(
    moment().startOf("day").format("YYYY-MM-DD")
  )
  const [timeEnd, setTimeEnd] = React.useState(
    moment().endOf("day").format("YYYY-MM-DD")
  )

  const [actionModal, setActionModel] = React.useState({
    id: null,
    action: null,
    modal: false,
  })
  const [selectedFiles, setselectedFiles] = React.useState([])
  const [formCommentRows, setFormCommentRows] = React.useState([])
  const [settingMenus, setSettingMenus] = React.useState(false)

  async function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    const data = new FormData()
    data.append("project_id", 0)
    data.append("type_id", -1)
    data.append("action", "Add")
    data.append("date_add", moment().startOf("day").format("YYYY-MM-DD"))
    data.append("timeStart", timeStart)
    data.append("timeEnd", timeEnd)
    for (let i = 0; i < files.length; i++) {
      data.append(`slip[${i}]`, await resizeImageFn(files[i]))
    }
    dispatch(onUploadImgInfoSlip(data))
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseInt((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  React.useMemo(() => {
    dispatch(onGetCommentHomes(0))
  }, [])

  React.useEffect(async () => {
    // console.log("commentHomes", commentHomes)
    if (!isEmpty(commentHomes)) {
      setFormCommentRows(commentHomes)
    } else {
      setFormCommentRows([])
    }
  }, [commentHomes])

  async function resizeImageFn(file) {
    // console.log("originalFile instanceof Blob", file instanceof Blob) // true
    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options)
      // console.log(
      //   "compressedFile instanceof Blob",
      //   compressedFile instanceof Blob
      // ) // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

      // await uploadToServer(compressedFile) // write your own logic
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }

  function eventAction(action, id, modal, data) {
    setActionModel({
      id: id,
      action: action,
      modal: modal,
    })
  }

  return (
    <React.Fragment>
      <ExampleModal actionModal={actionModal} />

      <Row>
        <Col sm="12" md="6">
          <Card style={{ height: 350 }}>
            <CardBody>
              <CardTitle
                className="mb-4"
                // onClick={e => dispatch(onGetCommentHomes(0))}
              >
                แก้ไขด่วน
              </CardTitle>
              <CardText>
                <Table
                  responsive
                  className="project-list-table table-nowrap align-middle"
                >
                  <thead>
                    <tr>
                      <th style={{ width: "35%" }}>ชื่อโปรเจค</th>
                      <th style={{ width: "50%" }}>หัวข้อ</th>
                      <th style={{ width: "15%" }}>ให้แก้วันที่</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(formCommentRows || []).map((v, k) =>
                      !isEmpty(v.project) ? (
                        <tr key={k}>
                          <th>
                            <Link to={`/projects-overview/${v.project_id}`}>
                              {v.project.name}
                            </Link>
                          </th>
                          <th>{v.comment}</th>
                          <th>
                            {moment(v.updated_at)
                              .add(543, "year")
                              .format("DD/MM/YYYY hh:ss")}
                          </th>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </Table>
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="6">
          <Card style={{ height: 350 }}>
            <CardBody>
              <CardTitle className="mb-4">
                <Row>
                  <Col sm="6">อัพโหลดไฟล์</Col>
                  <Col sm="6">
                    <div className="clearfix mt-4 mt-lg-0">
                      <Dropdown
                        style={{ cursor: "pointer" }}
                        isOpen={settingMenus}
                        toggle={() => setSettingMenus(!settingMenus)}
                        className="float-end"
                      >
                        <DropdownToggle tag="div">
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem
                            onClick={() => eventAction("Add", null, true, null)}
                          >
                            ตั้งเบิกค่าแรง
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              eventAction("Wage", null, true, null)
                            }
                          >
                            สั่งซื้อวัสดุ
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>

                    {/* <div className="text-sm-end">
                      <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={() => eventAction("Add", null, true, null)}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        ตั้งเบิกค่าแรง
                      </Button>
                    </div> */}
                  </Col>
                </Row>
              </CardTitle>
              <CardText>
                <Row>
                  <Col sm="12">
                    <div>
                      <Dropzone
                        accept="image/*"
                        inputContent={(files, extra) =>
                          extra.reject ? "Image files only" : "Drag Files"
                        }
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                        styles={{
                          dropzoneReject: {
                            borderColor: "red",
                            backgroundColor: "#DAA",
                          },
                          inputLabel: (files, extra) =>
                            extra.reject ? { color: "red" } : {},
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>ลากไฟล์มาที่นี้หรือคลิกเพื่ออัพโหลด</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>

                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default MonthlyEarning
