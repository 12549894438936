import {
  GET_MATERIAL_TYPES_SUCCESS,
  GET_MATERIAL_TYPES_FAIL,
  ADD_MATERIAL_TYPE_SUCCESS,
  ADD_MATERIAL_TYPE_FAIL,
  UPDATE_MATERIAL_TYPE_SUCCESS,
  UPDATE_MATERIAL_TYPE_FAIL,
  DELETE_MATERIAL_TYPE_SUCCESS,
  DELETE_MATERIAL_TYPE_FAIL,
  GET_MATERIAL_TYPE_PROFILE_SUCCESS,
  GET_MATERIAL_TYPE_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  materialTypes: [],
  materialTypeProfile: {},
  error: {},
}

const materialTypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MATERIAL_TYPES_SUCCESS:
      // console.log(action)
      return {
        ...state,
        materialTypes: action.payload,
      }

    case GET_MATERIAL_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_MATERIAL_TYPE_SUCCESS:
      return {
        ...state,
        materialTypes: [...state.materialTypes, action.payload],
      }

    case ADD_MATERIAL_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MATERIAL_TYPE_PROFILE_SUCCESS:
      // console.log("GET_MATERIAL_TYPE_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        materialTypeProfile: action.payload,
      }

    case UPDATE_MATERIAL_TYPE_SUCCESS:
      return {
        ...state,
        materialTypes: state.materialTypes.map(designgroup =>
          designgroup.id.toString() === action.payload.id.toString()
            ? { designgroup, ...action.payload }
            : designgroup
        ),
      }

    case UPDATE_MATERIAL_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_MATERIAL_TYPE_SUCCESS:
      return {
        ...state,
        materialTypes: state.materialTypes.filter(
          designgroup =>
            designgroup.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_MATERIAL_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MATERIAL_TYPE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default materialTypes
