import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_QUOTATIONCONPROJECTS,
  GET_QUOTATIONCONPROJECT_PROFILE,
  ADD_NEW_QUOTATIONCONPROJECT,
  DELETE_QUOTATIONCONPROJECT,
  UPDATE_QUOTATIONCONPROJECT,
} from "./actionTypes"

import {
  getQuotationConProjectsSuccess,
  getQuotationConProjectsFail,
  getQuotationConProjectProfileSuccess,
  getQuotationConProjectProfileFail,
  addQuotationConProjectFail,
  addQuotationConProjectSuccess,
  updateQuotationConProjectSuccess,
  updateQuotationConProjectFail,
  deleteQuotationConProjectSuccess,
  deleteQuotationConProjectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getQuotationConProjects,
  getQuotationConProjectProfile,
  addNewQuotationConProject,
  updateQuotationConProject,
  deleteQuotationConProject,
} from "../../helpers/fakebackend_helper"

function* fetchQuotationConProjects({ payload: project_id }) {
  // console.log("fetchQuotationConProjects", project_id)
  try {
    // console.log(1);
    const response = yield call(getQuotationConProjects, project_id)
    // console.log("fetchQuotationConProjects", response)
    yield put(getQuotationConProjectsSuccess(response))
  } catch (error) {
    yield put(getQuotationConProjectsFail(error))
  }
}

function* fetchQuotationConProjectProfile({ payload: data }) {
  try {
    const response = yield call(getQuotationConProjectProfile, data)
    // console.log(response);
    yield put(getQuotationConProjectProfileSuccess(response))
  } catch (error) {
    yield put(getQuotationConProjectProfileFail(error))
  }
}

function* onUpdateQuotationConProject({ payload: quotationconproject }) {
  try {
    // let x = quotationconproject.get('formData')
    // console.log(x)
    const response = yield call(updateQuotationConProject, quotationconproject)
    // console.log(response)
    // yield put(updateQuotationConProjectSuccess({}))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateQuotationConProjectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteQuotationConProject({ payload: quotationconproject }) {
  try {
    const response = yield call(deleteQuotationConProject, quotationconproject)
    yield put(deleteQuotationConProjectSuccess(response))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ลบ",
      message: "ลบสำเร็จ",
    })
  } catch (error) {
    yield put(deleteQuotationConProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ลบ",
      message: "ลบล้มเหลว",
    })
  }
}

function* onAddNewQuotationConProject({ payload: quotationconproject }) {
  try {
    const response = yield call(addNewQuotationConProject, quotationconproject)
    // console.log(response);
    yield put(addQuotationConProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addQuotationConProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* quotationconProjectsSaga() {
  yield takeEvery(GET_QUOTATIONCONPROJECTS, fetchQuotationConProjects)
  yield takeEvery(
    GET_QUOTATIONCONPROJECT_PROFILE,
    fetchQuotationConProjectProfile
  )
  yield takeEvery(ADD_NEW_QUOTATIONCONPROJECT, onAddNewQuotationConProject)
  yield takeEvery(UPDATE_QUOTATIONCONPROJECT, onUpdateQuotationConProject)
  yield takeEvery(DELETE_QUOTATIONCONPROJECT, onDeleteQuotationConProject)
}

export default quotationconProjectsSaga
