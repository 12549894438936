/* LIST_PRODUCTS */
export const GET_LIST_PRODUCTS = "GET_LIST_PRODUCTS"
export const GET_LIST_PRODUCTS_SUCCESS = "GET_LIST_PRODUCTS_SUCCESS"
export const GET_LIST_PRODUCTS_FAIL = "GET_LIST_PRODUCTS_FAIL"

/* LIST_PRODUCTS PROFILE */
export const GET_LIST_PRODUCT_PROFILE = "GET_LIST_PRODUCT_PROFILE"
export const GET_LIST_PRODUCT_PROFILE_SUCCESS = "GET_LIST_PRODUCT_PROFILE_SUCCESS"
export const GET_LIST_PRODUCT_PROFILE_FAIL = "GET_LIST_PRODUCT_PROFILE_FAIL"

/**
 * add product
 */
export const ADD_NEW_LIST_PRODUCT = "ADD_NEW_LIST_PRODUCT"
export const ADD_LIST_PRODUCT_SUCCESS = "ADD_LIST_PRODUCT_SUCCESS"
export const ADD_LIST_PRODUCT_FAIL = "ADD_LIST_PRODUCT_FAIL"

/**
 * Edit product
 */
export const UPDATE_LIST_PRODUCT = "UPDATE_LIST_PRODUCT"
export const UPDATE_LIST_PRODUCT_SUCCESS = "UPDATE_LIST_PRODUCT_SUCCESS"
export const UPDATE_LIST_PRODUCT_FAIL = "UPDATE_LIST_PRODUCT_FAIL"

/**
 * Delete product
 */
export const DELETE_LIST_PRODUCT = "DELETE_LIST_PRODUCT"
export const DELETE_LIST_PRODUCT_SUCCESS = "DELETE_LIST_PRODUCT_SUCCESS"
export const DELETE_LIST_PRODUCT_FAIL = "DELETE_LIST_PRODUCT_FAIL"
