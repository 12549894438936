import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_MESSAGE_LISTS,
  GET_MESSAGE_LIST_PROFILE,
  ADD_NEW_MESSAGE_LIST,
  DELETE_MESSAGE_LIST,
  UPDATE_MESSAGE_LIST,
} from "./actionTypes"

import {
  getMessageListsSuccess,
  getMessageListsFail,
  getMessageListProfileSuccess,
  getMessageListProfileFail,
  addMessageListFail,
  addMessageListSuccess,
  updateMessageListSuccess,
  updateMessageListFail,
  deleteMessageListSuccess,
  deleteMessageListFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMessageLists,
  getMessageListProfile,
  addNewMessageList,
  updateMessageList,
  deleteMessageList,
} from "../../helpers/fakebackend_helper"

function* fetchMessageLists({ payload: active }) {
  try {
    const response = yield call(getMessageLists, active)
    // console.log(active);
    yield put(getMessageListsSuccess(response))
  } catch (error) {
    yield put(getMessageListsFail(error))
  }
}

function* fetchMessageListProfile({ payload: id }) {
  // console.log(id)updateMessageList
  try {
    const response = yield call(getMessageListProfile, id)
    // console.log("Profile => ", response)
    yield put(getMessageListProfileSuccess(response))
  } catch (error) {
    yield put(getMessageListProfileFail(error))
  }
}

function* onUpdateMessageList({ payload: messagelist }) {
  try {
    // let x = messagelist.get('formData')
    // console.log(x)
    const response = yield call(updateMessageList, messagelist)
    console.log("response", response)
    // yield put(updateMessageListSuccess(response))
    // yield put(getMessageListsSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateMessageListFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteMessageList({ payload: messagelist }) {
  try {
    const response = yield call(deleteMessageList, messagelist)
    yield put(deleteMessageListSuccess(response))
  } catch (error) {
    yield put(deleteMessageListFail(error))
  }
}

function* onAddNewMessageList({ payload: messagelist }) {
  try {
    const response = yield call(addNewMessageList, messagelist)
    yield put(addMessageListSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addMessageListFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* messageList() {
  yield takeEvery(GET_MESSAGE_LISTS, fetchMessageLists)
  yield takeEvery(GET_MESSAGE_LIST_PROFILE, fetchMessageListProfile)
  yield takeEvery(ADD_NEW_MESSAGE_LIST, onAddNewMessageList)
  yield takeEvery(UPDATE_MESSAGE_LIST, onUpdateMessageList)
  yield takeEvery(DELETE_MESSAGE_LIST, onDeleteMessageList)
}

export default messageList
