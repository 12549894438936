import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"

import projectsSaga from "./projects/saga"
import quotationsconprojectSaga from "./quotations-con-project/saga"
import contactsconprojectSaga from "./contacts-con-project/saga"
import employmentsconprojectSaga from "./employments-con-project/saga"



import infoProjectsSaga from "./info-project/saga"
import refProjectsSaga from "./refs-project/saga"
import serwayProjectsSaga from "./serways-project/saga"
import slipProjectsSaga from "./slips-project/saga"
import quotationProjectsSaga from "./quotations-project/saga"
import profitlossProjectsSaga from "./profit-loss/saga"

import tasksSaga from "./tasks/saga"
import mailsSaga from "./mails/saga"
import userSaga from "./users/saga"
import userGroups from "./user-groups/saga"

import contractors from "./contractors/saga"
import designhomes from "./design-homes/saga"
import customProject from "./custom-projects/saga"

import boqs from "./boqs/saga"
import listStructures from "./list-structures/saga"
import listAluminias from "./list-aluminias/saga"
import listAluminiasProjects from "./list-aluminias-projects/saga"


import listProducts from "./list-products/saga"
import productGroups from "./product-groups/saga"
import banks from "./banks/saga"

import materialPrices from "./material-prices/saga"
import materialTypes from "./material-types/saga"
import materialStocks from "./material-stocks/saga"
import materialShops from "./material-shops/saga"
import messageLists from "./message-lists/saga"


import withdrawMaterials from "./withdraw-materials/saga"
import attends from "./attends/saga"

import listQcs from "./list-qcs/saga"
import listDesigngroup from "./list-design-groups/saga"
import designs from "./designs/saga"
import commentHomes from "./comment-homes/saga"
import customHomes from "./custom-homes/saga"
import boqHomes from "./boq-homes/saga"
import boqTitleHomes from "./boq-title-homes/saga"
import dashboardSaga from "./dashboard/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),

    fork(projectsSaga),
    fork(quotationsconprojectSaga),
    fork(contactsconprojectSaga),
    fork(employmentsconprojectSaga),    
    fork(infoProjectsSaga),
    fork(refProjectsSaga),
    fork(serwayProjectsSaga),
    fork(slipProjectsSaga),
    fork(quotationProjectsSaga),
    fork(profitlossProjectsSaga),

    fork(tasksSaga),
    fork(userSaga),
    fork(userGroups),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),

    // money
    fork(listProducts),
    fork(productGroups),
    fork(banks),
    fork(materialPrices),
    fork(materialTypes),
    fork(materialStocks),
    fork(materialShops),
    fork(messageLists),
    fork(withdrawMaterials),
    fork(attends),

    // contractor
    fork(contractors),
    fork(boqs),
    fork(listStructures),
    fork(listAluminias),
    fork(listAluminiasProjects),


    
    fork(boqHomes),
    fork(boqTitleHomes),
    fork(listQcs),
    fork(listDesigngroup),
    fork(designs),
    fork(commentHomes),
    fork(customHomes),
    fork(customProject),

    // visitor
    fork(designhomes),
  ])
}
