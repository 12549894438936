import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
  Card,
} from "reactstrap"
import imageCompression from "browser-image-compression"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  getUserProfile as onGetUserProfile,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
} from "store/users/actions"
import { isEmpty, map } from "lodash"

import Dropzone from "react-dropzone"
import Select from "react-select"
import { getUserGroups as onGetUserGroups } from "store/user-groups/actions"

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector(state => ({
    userProfile: state.users.userProfile,
  }))

  const { userGroups } = useSelector(state => ({
    userGroups: state.userGroups.userGroups,
  }))

  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [selectedFiles, setselectedFiles] = React.useState([])
  const [dataImage_profile, setDataImage_profile] = React.useState(null)
  const [dataImage_idCard, setDataImage_idCard] = React.useState(null)

  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState(userProfile)
  const [groups, setGroups] = React.useState([])
  const [typeUpload, setTypeUpload] = React.useState(null)
  const [selectUpload, setSelectUpload] = React.useState([
    { value: 1, label: "รูปโปรไฟล์" },
    { value: 2, label: "บัตรประชาชน" },
  ])
  async function checkAction() {
    dispatch(onGetUserGroups())
    setFormData({})
    setselectedFiles([])
    setTypeUpload(null)
    setDataImage_profile(null)
    setDataImage_idCard(null)
    if (actionModal.action == "Add") {
      setFormData({
        active: 1,
      })
    } else if (actionModal.action == "Edit") {
      dispatch(onGetUserProfile(actionModal.id))
    }

    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
    // console.log(groups);
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true)
    if (errors.length > 0) {
    } else {
      const data = new FormData()
      data.append("formData", JSON.stringify(formData))
      data.append("Image_profile", dataImage_profile)
      data.append("Image_id_card", dataImage_idCard)

      if (action == "Add") {
        // save new user
        dispatch(onAddNewUser(data))
      } else if (action == "Edit") {
        // update user
        dispatch(onUpdateUser(data))
      }
      setModal(!modal)
    }
    setButtonSubmit(false)
  }

  async function handleAcceptedFiles(files) {
    files.map(files =>
      Object.assign(files, {
        preview: URL.createObjectURL(files),
        formattedSize: formatBytes(files.size),
      })
    )
    // console.log(files[0])
    if (typeUpload == 1) {
      setDataImage_profile(await resizeImageFn(files[0]))
    } else if (typeUpload == 2) {
      setDataImage_idCard(await resizeImageFn(files[0]))
    }
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseInt((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  async function resizeImageFn(file) {
    // console.log("originalFile instanceof Blob", file instanceof Blob) // true
    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options)
      // console.log(
      //   "compressedFile instanceof Blob",
      //   compressedFile instanceof Blob
      // ) // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

      // await uploadToServer(compressedFile) // write your own logic
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  React.useEffect(async () => {
    if (actionModal.modal) {
      const options = await userGroups.map(d => ({
        value: d.id,
        label: d.name,
      }))
      setGroups(options)
    }
    return () => {}
  }, [userGroups]) // Only re-run the effect if count changes

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      setFormData(userProfile)
      // console.log("userProfile", userProfile)
    }
    return () => {}
  }, [userProfile]) // Only re-run the effect if count changes

  return (
    <Modal isOpen={modal} toggle={() => setModal(!modal)} centered={true}>
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}ผู้ใช้งาน{" "}
      </ModalHeader>
      <AvForm onSubmit={handleSubmit} model={formData}>
        <ModalBody>
          <Row>
            {/* <Col
              className="text-right mb-2"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              hidden={action == "Add" ? true : false}
            >
              <Button onClick={() => fingerprint(formData.id)} color="primary">
                เพิ่มรายนิ้วมือ
              </Button>
            </Col> */}

            <Col xs={6} sm={6} md={6} lg={6}>
              <FormGroup className="mb-3">
                <AvField
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  onChange={e =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <FormGroup className="mb-3">
                <AvField
                  name="lastname"
                  type="text"
                  placeholder="Last"
                  value={formData.lastname}
                  onChange={e =>
                    setFormData({ ...formData, lastname: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <FormGroup className="mb-3">
                <AvField
                  name="email"
                  type="text"
                  placeholder="ID"
                  value={formData.email}
                  onChange={e =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                    pattern: {
                      value: "^[A-Za-z0-9-@]",
                      errorMessage: "ID must be english only",
                    },
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <FormGroup className="mb-3">
                <AvField
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={e =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  validate={{
                    required: { value: action == "Add" ? true : false },
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <FormGroup className="mb-3">
                <AvField
                  name="number_phone"
                  type="text"
                  placeholder="Number phone"
                  value={formData.number_phone}
                  onChange={e =>
                    setFormData({ ...formData, number_phone: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <FormGroup className="mb-3">
                <AvField
                  name="card_number"
                  type="text"
                  placeholder="Card number"
                  value={formData.card_number}
                  onChange={e =>
                    setFormData({ ...formData, card_number: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <FormGroup className="mb-3">
                <AvField
                  name="bank"
                  type="text"
                  placeholder="Bank"
                  value={formData.bank}
                  onChange={e =>
                    setFormData({ ...formData, bank: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
              <FormGroup className="mb-3">
                <AvField
                  name="bank_no"
                  type="text"
                  placeholder="Bank no"
                  value={formData.bank_no}
                  onChange={e =>
                    setFormData({ ...formData, bank_no: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <FormGroup className="mb-3">
                <AvField
                  name="address"
                  type="textarea"
                  placeholder="Address"
                  value={formData.address}
                  onChange={e =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={7} lg={7}>
              <FormGroup className="mb-3">
                <Select
                  type="select"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="user_group_id"
                  value={groups.filter(function (option) {
                    return option.value == formData.user_group_id
                  })}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      user_group_id: e.value,
                    })
                  }
                  options={groups.map((item, i) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5}>
              <FormGroup className="mt-2">
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 1}
                      value={1}
                      onChange={e =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    เปิดใช้งาน
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 2}
                      value={2}
                      onChange={e =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    ปิดใช้งาน
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="mt-3">
                <div
                  style={{ marginBottom: "10px", borderBottom: "1px soild" }}
                >
                  <Select
                    name="selectUpload"
                    value={selectUpload.filter(
                      option => option.value == typeUpload
                    )}
                    placeholder="ประเภทการอัพโหลด"
                    onChange={e => {
                      setTypeUpload(e.value)
                    }}
                    options={selectUpload}
                    classNamePrefix="select2-selection"
                  />
                </div>
                <hr />

                <div className="text-left">
                  <h5>รูปโปรไฟล์</h5>
                  {formData.img_profile == undefined ? null : (
                    <a
                      href={
                        process.env.REACT_APP_SERVER + formData.path_img_profile
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          process.env.REACT_APP_SERVER +
                          formData.path_img_profile
                        }
                        alt={process.env.REACT_APP_NAMECOMPANY}
                        height="257px"
                        width="257px"
                      />
                    </a>
                  )}
                  <br />
                  <h5>รูปบัตรประชาชน</h5>
                  {formData.img_id_card == undefined ? null : (
                    <a
                      href={
                        process.env.REACT_APP_SERVER + formData.path_img_id_card
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          process.env.REACT_APP_SERVER +
                          formData.path_img_id_card
                        }
                        alt={process.env.REACT_APP_NAMECOMPANY}
                        height="257px"
                        width="257px"
                      />
                    </a>
                  )}
                </div>
                <hr />
                {typeUpload == null ? null : (
                  <>
                    <Dropzone
                      multiple={false}
                      accept="image/*"
                      inputContent={(files, extra) =>
                        extra.reject ? "Image files only" : "Drag Files"
                      }
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                      styles={{
                        dropzoneReject: {
                          borderColor: "red",
                          backgroundColor: "#DAA",
                        },
                        inputLabel: (files, extra) =>
                          extra.reject ? { color: "red" } : {},
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>
                                เลือกไฟล์หรือลากไฟล์มาตรงนี้เพื่ออัพโหลดรูป
                              </h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>

                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </>
                )}

                {/* <Label htmlFor="formFileSm" className="form-label">
                  รูปภาพ
                </Label>
                <input
                  className="form-control form-control-sm"
                  id="formFileSm"
                  type="file"
                  accept="image/*"
                  onChange={handleAcceptedFiles}
                /> */}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            Save
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
