import React from "react"
import PropTypes from "prop-types"
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  Button,
  Form,
  Input,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Badge,
  Collapse,
  Label,
  Modal,
  FormGroup,
} from "reactstrap"
import Calendar from "pages/Dashboard/Calendar"
import { Link } from "react-router-dom"
import classnames from "classnames"
import { isEmpty } from "lodash"

import Dropzone from "react-dropzone"
import Editable from "react-bootstrap-editable"
import Select from "react-select"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ExampleModalContractor from "components/Dialogs/dialogContractor"
import ExampleModalShopMaterial from "components/Dialogs/dialogShopMaterial"
import ExampleModalCheckUploadWork from "components/Dialogs/dialogCheckUploadWork"
import ExampleModalList_DesignGroup from "components/Dialogs/dialogList_DesignGroup"

import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work

import {
  getRefprojectProfile as onGetRefprojectProfile,
  deleteRefproject as onDeleteRefproject,
} from "store/refs-project/actions"
import { getListDesignGroups as onGetList_designgroups } from "store/list-design-groups/actions"
import {
  getDesigns as onGetDesigns,
  addNewDesign as onAddNewDesign,
  deleteDesign as onDeleteDesign,
} from "store/designs/actions"

import {
  getCommentHomes as onGetCommentHomes,
  addNewCommentHome as onAddNewCommentHome,
  notiLineCommentHome as onNotiLineCommentHome,
} from "store/comment-homes/actions"

import {
  getCustomHomes as onGetCustomHomes,
  addNewCustomHome as onAddNewCustomHome,
} from "store/custom-homes/actions"

const Plan = ({ projectID }) => {
  const dispatch = useDispatch()

  const { refprojectProfile } = useSelector(state => ({
    refprojectProfile: state.refprojects.refprojectProfile,
  }))

  const { listDesignGroups } = useSelector(state => ({
    listDesignGroups: state.listDesigngroup.listDesignGroups,
  }))

  const { designs } = useSelector(state => ({
    designs: state.designs.designs,
  }))

  const { showCustomHome } = useSelector(state => ({
    showCustomHome: state.designs.showCustomHome,
  }))

  const { commentHomes } = useSelector(state => ({
    commentHomes: state.commentHomes.commentHomes,
  }))

  const { customHomes } = useSelector(state => ({
    customHomes: state.customHomes.customHomes,
  }))
  const [selectedFiles, setselectedFiles] = React.useState([])
  const [formCommentRows, setFormCommentRows] = React.useState([])
  const [formCustomRows, setFormCustomRows] = React.useState([])

  const [filePdfDesign, setFilePdfDesign] = React.useState([])
  const [fileRefImg, setFileRefImg] = React.useState([])
  const [fileVender, setFileVender] = React.useState([])
  const [selectUploadID, setSelectUploadID] = React.useState(null)
  const [selectUploadComment, setSelectUploadComment] = React.useState("")
  const [optionSelectUpload, setOptionSelectUpload] = React.useState([])
  const [TabComment, setTabComment] = React.useState(false)
  const [TabcustomHome, setTabcustomHome] = React.useState(true)
  const [modal_customHome, setModal_customHome] = React.useState(false)

  const [actionModalContractor, setActionModalContractor] = React.useState({
    id: null,
    action: null,
    modal: false,
  })

  const [actionModalShopMaterial, setActionModalShopMaterial] = React.useState({
    modal: false,
  })

  const [actionModalCheckUploadWork, setActionModalCheckUploadWork] =
    React.useState({
      id: null,
      modal: false,
    })

  const [actionModalList_DesignGroup, setActionModalList_DesignGroup] =
    React.useState({
      id: null,
      action: null,
      modal: false,
    })

  const t_col1 = () => {
    setTabComment(!TabComment)
    setTabcustomHome(false)
  }

  const t_col2 = () => {
    setTabcustomHome(!TabcustomHome)
    setTabComment(false)
  }

  const onAddFormCommentRow = () => {
    const modifiedRows = [...formCommentRows]
    modifiedRows.push({
      key: modifiedRows.length + 1,
      project_id: projectID,
      comment: "null",
      check: false,
      priority: 0,
      del: 1,
      check_insert: 0,
    })
    setFormCommentRows(modifiedRows)
  }

  function onDeleteFormCommentRow(key) {
    // console.log("key", key)
    // console.log("formCommentRows", formCommentRows)
    var modifiedRows = [...formCommentRows]
    for (let index = 0; index < modifiedRows.length; index++) {
      if (modifiedRows[index].key == key) {
        modifiedRows[index].del = 0
      }
    }
    setFormCommentRows(modifiedRows)
  }

  const confirmElement = (
    <button
      type="submit"
      className="btn btn-success editable-submit btn-sm me-1"
    >
      <i className="mdi mdi-check"></i>
    </button>
  )

  /** Cancel button */
  const cancelElement = (
    <button type="button" className="btn btn-danger editable-cancel btn-sm">
      <i className="mdi mdi-close"></i>
    </button>
  )

  async function handleAcceptedFiles_design(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    const data = new FormData()
    data.append("project_id", projectID)
    data.append("comment", selectUploadComment)
    data.append("design_group_id", selectUploadID)
    for (let i = 0; i < files.length; i++) {
      data.append(`pdf_files[${i}]`, files[i])
    }
    dispatch(onAddNewDesign(data))
    setSelectUploadComment("")
    setSelectUploadID(null)
    // setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  React.useEffect(() => {
    if (projectID != undefined) {
      dispatch(onGetRefprojectProfile(projectID))
      dispatch(onGetList_designgroups(1))
      dispatch(onGetDesigns(projectID))
      dispatch(onGetCommentHomes(projectID))
      dispatch(onGetCustomHomes(projectID))
    }
  }, [projectID])

  React.useEffect(() => {
    // console.log(refprojectProfile)
    if (!isEmpty(refprojectProfile)) {
      setFileRefImg(refprojectProfile.mapRef)
      setFileVender(refprojectProfile.mapVender)
    } else {
      setFileRefImg([])
      setFileVender([])
    }
    // setFormData(infoprojectProfile)
  }, [refprojectProfile])

  React.useEffect(async () => {
    // console.log(refprojectProfile.length)
    if (!isEmpty(listDesignGroups)) {
      const options = await listDesignGroups.map(d => ({
        value: d.id,
        label: d.name,
      }))
      setOptionSelectUpload(options)
    } else {
      setOptionSelectUpload([])
    }
    // setFormData(infoprojectProfile)
  }, [listDesignGroups])

  React.useEffect(async () => {
    if (!isEmpty(designs)) {
      setFilePdfDesign(designs)
    } else {
      setFilePdfDesign([])
    }
  }, [designs])

  React.useEffect(async () => {
    if (!isEmpty(showCustomHome)) {
      setModal_customHome(true)
    }
  }, [showCustomHome])

  React.useEffect(async () => {
    // console.log(commentHomes);
    if (!isEmpty(commentHomes)) {
      let newData = []
      for (let index = 0; index < commentHomes.length; index++) {
        let data = commentHomes[index]
        // data.xxx = data.check
        if (data.check == 0) {
          data.check = false
        } else {
          data.check = true
        }
        // console.log(data.check)
        newData.push(data)
      }
      setFormCommentRows(newData)
    } else {
      setFormCommentRows([
        {
          key: 1,
          project_id: projectID,
          comment: "เปลื่ยนอลูมิเนียมเป็นสีขาว (ตัวอย่าง)",
          check: false,
          priority: 0,
          del: 1,
          check_insert: 0,
        },
        {
          key: 2,
          project_id: projectID,
          comment: " ด้านหน้า (ตัวอย่าง)",
          check: false,
          priority: 0,
          del: 1,
          check_insert: 0,
        },
      ])
    }
  }, [commentHomes])

  React.useEffect(async () => {
    // console.log("customHomes", customHomes)
    if (!isEmpty(customHomes)) {
      setFormCustomRows(customHomes)
    } else {
      setFormCustomRows([])
    }
  }, [customHomes])

  function handleSubmit_commentHome() {
    const data = new FormData()
    data.append("project_id", projectID)
    data.append("formData", JSON.stringify(formCommentRows))
    dispatch(onAddNewCommentHome(data))
  }

  function handleSubmit_customHome() {
    const data = new FormData()
    data.append("project_id", projectID)
    data.append("formData", JSON.stringify(formCustomRows))
    dispatch(onAddNewCustomHome(data))
  }

  function delImgRef(id) {
    dispatch(onDeleteRefproject(id))
  }
  function delImgDesign(id) {
    dispatch(onDeleteDesign(id))
  }

  return (
    <React.Fragment>
      <div>
        <ExampleModalContractor actionModal={actionModalContractor} />
        <ExampleModalShopMaterial actionModal={actionModalShopMaterial} />
        <ExampleModalCheckUploadWork actionModal={actionModalCheckUploadWork} />
        <ExampleModalList_DesignGroup
          actionModal={actionModalList_DesignGroup}
        />

        <Modal
          size="lg"
          centered
          backdrop={"static"}
          isOpen={modal_customHome}
          toggle={() => {
            setModal_customHome(!modal_customHome)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Custom home (รายละเอียดเรื่อง บ้าน,สี,กระเบื้อง,อื่น ๆ )
            </h5>

            <button
              type="button"
              onClick={() => {
                setModal_customHome(!modal_customHome)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <Row>
                {(formCustomRows || []).map((f, k) => (
                  <Col sm="12" md="6" lg="4" xl="3" key={k}>
                    <label htmlFor={"detail" + k}>{f.title}</label>

                    <Input
                      id={"detail" + k}
                      type="text"
                      className="form-control"
                      name={"detail" + k}
                      value={f.detail}
                      onChange={e => {
                        let edit = [...formCustomRows]
                        edit[k].detail = e.target.value
                        setFormCustomRows(edit)
                      }}
                      placeholder="detail"
                    />
                    {/* </div> */}
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setModal_customHome(!modal_customHome)
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              cancel
            </button>

            <button
              type="button"
              onClick={() => {
                handleSubmit_customHome()
              }}
              className="btn btn-primary "
              data-dismiss="modal"
            >
              Save
            </button>
          </div>
        </Modal>
      </div>

      {!isEmpty(projectID) && (
        <div>
          <Row>
            <Calendar />
          </Row>

          <Row>
            <Col sm="12" lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4"> อ้างอิงลูกค้า</CardTitle>
                  <Row>
                    {(fileVender || []).map((f, i) => (
                      <Col sm="12" md="6" lg="2" key={i}>
                        <i
                          onClick={() => delImgRef(f.id)}
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                            textAlign: "right",
                            color: "red",
                            width: "100%",
                          }}
                          className="bx bx-x-circle"
                        ></i>
                        <a
                          style={{
                            color:
                              i == Number(fileRefImg.length - 1)
                                ? "#bb2124"
                                : "#aaaaaa",
                            margin: 2,
                          }}
                          href={process.env.REACT_APP_SERVER + f.img_path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            key={i}
                            className="img-fluid"
                            alt={process.env.REACT_APP_DETAILCOMPANY}
                            src={process.env.REACT_APP_SERVER + f.img_path}
                          />
                          <p style={{ marginBottom: "0px" }}>{f.comment}</p>
                          <p style={{ marginBottom: "0px" }}>
                            {moment(f.created_at).format("DD/MM/YYYY HH:mm")}
                          </p>
                        </a>
                        &nbsp;
                      </Col>
                    ))}
                  </Row>
                  <hr />

                  <CardTitle className="mb-4"> ภาพอ้างอิง</CardTitle>
                  <Row>
                    {(fileRefImg || []).map((f, i) => (
                      <Col sm="12" md="6" lg="2" key={i}>
                        <i
                          onClick={() => delImgRef(f.id)}
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                            textAlign: "right",
                            color: "red",
                            width: "100%",
                          }}
                          className="bx bx-x-circle"
                        ></i>
                        <a
                          style={{
                            color:
                              i == Number(fileRefImg.length - 1)
                                ? "#bb2124"
                                : "#aaaaaa",
                            margin: 2,
                          }}
                          href={process.env.REACT_APP_SERVER + f.img_path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            key={i}
                            className="img-fluid"
                            alt={process.env.REACT_APP_DETAILCOMPANY}
                            src={process.env.REACT_APP_SERVER + f.img_path}
                          />
                          <p style={{ marginBottom: "0px" }}>{f.comment}</p>
                          <p style={{ marginBottom: "0px" }}>
                            {moment(f.created_at).format("DD/MM/YYYY HH:mm")}
                          </p>
                        </a>
                        &nbsp;
                      </Col>
                    ))}
                  </Row>
                  <hr />
                  <Row>
                    <Col sm="12">
                      <div className="accordion" id="accordion">
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className={classnames(
                                "accordion-button",
                                "fw-medium",
                                {
                                  collapsed: !TabComment,
                                }
                              )}
                              type="button"
                              onClick={t_col1}
                              style={{ cursor: "pointer" }}
                            >
                              Comment (รายการเช็คลิสการออกแบบ)
                            </button>
                          </h2>

                          <Collapse
                            isOpen={TabComment}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <div className="float-end ms-2">
                                <UncontrolledDropdown
                                  className="mb-2"
                                  direction="left"
                                >
                                  <DropdownToggle
                                    color="white"
                                    className="btn btn-link text-muted mt-n2"
                                  >
                                    <i className="mdi mdi-dots-horizontal"></i>
                                  </DropdownToggle>

                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => onAddFormCommentRow()}
                                    >
                                      เพิ่ม
                                    </DropdownItem>
                                    {formCommentRows.length > 0 ? (
                                      <DropdownItem
                                        onClick={() => {
                                          const data = new FormData()
                                          data.append("type", "all")
                                          data.append("id", projectID)
                                          dispatch(onNotiLineCommentHome(data))

                                          // dispatch(onNotiLineCommentHome("all", $id))
                                        }}
                                      >
                                        แจ้งเตือนรวม
                                      </DropdownItem>
                                    ) : null}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>

                              <Form
                                className="repeater"
                                encType="multipart/form-data"
                              >
                                <div>
                                  <Row>
                                    {(formCommentRows || []).map((f, k) =>
                                      f.del == 0 ? null : (
                                        <Col
                                          sm="12"
                                          md="12"
                                          lg="6"
                                          xl="6"
                                          key={k}
                                        >
                                          <div className="mt-4 d-flex flex-wrap gap-2">
                                            <label
                                              className="form-check-label"
                                              htmlFor={"customCheck" + 1}
                                            >
                                              <Editable
                                                alwaysEditing={false}
                                                initialValue={f.comment}
                                                disabled={false}
                                                editText={f.comment}
                                                isValueClickable={true}
                                                mode="inline"
                                                placement="top"
                                                showText
                                                type="textfield"
                                                renderConfirmElement={
                                                  confirmElement
                                                }
                                                onSubmit={e => {
                                                  let edit = [
                                                    ...formCommentRows,
                                                  ]
                                                  edit[k].comment = e
                                                  edit[k].check_insert = 0
                                                  setFormCommentRows(edit)
                                                }}
                                                renderCancelElement={
                                                  cancelElement
                                                }
                                              />
                                            </label>
                                            <div className="form-check form-check-right">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                value={f.priority}
                                                onChange={e => {
                                                  let edit = [
                                                    ...formCommentRows,
                                                  ]
                                                  edit[k].priority = !f.priority
                                                  setFormCommentRows(edit)
                                                }}
                                                checked={f.priority}
                                                name={"customPriority" + 1}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={"customPriority" + 1}
                                              >
                                                ด่วน
                                              </label>
                                            </div>
                                            <div className="form-check form-check-right">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                value={f.check}
                                                onChange={e => {
                                                  let edit = [
                                                    ...formCommentRows,
                                                  ]
                                                  edit[k].check = !f.check
                                                  setFormCommentRows(edit)
                                                }}
                                                checked={f.check}
                                                name={"customCheck" + 1}
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={"customCheck" + 1}
                                              >
                                                เช็ค
                                              </label>
                                            </div>
                                            &emsp;
                                            {f.check_insert == 0 ? null : (
                                              <Badge
                                                pill
                                                style={{
                                                  alignSelf: "center",
                                                }}
                                                className="badge-soft-primary ma-auto mb-1"
                                                onClick={() => {
                                                  const data = new FormData()
                                                  data.append("type", "single")
                                                  data.append("id", f.id)
                                                  dispatch(
                                                    onNotiLineCommentHome(data)
                                                  )
                                                }}
                                              >
                                                <i
                                                  className="bx bxs-bell"
                                                  style={{
                                                    cursor: "pointer",
                                                    paddingTop: "inherit",
                                                  }}
                                                ></i>
                                              </Badge>
                                            )}
                                            <Badge
                                              pill
                                              style={{
                                                alignSelf: "center",
                                                marginBottom: 8,
                                              }}
                                              className="badge-soft-danger ma-auto"
                                              onClick={() =>
                                                onDeleteFormCommentRow(f.key)
                                              }
                                            >
                                              <i
                                                className="bx bx-x"
                                                style={{
                                                  cursor: "pointer",
                                                  paddingTop: "inherit",
                                                }}
                                              ></i>
                                            </Badge>
                                          </div>
                                        </Col>
                                      )
                                    )}
                                  </Row>
                                </div>
                                <div
                                  style={{
                                    marginTop: "10px",
                                    textAlign: "right",
                                  }}
                                >
                                  {formCommentRows.length > 0 ? (
                                    <Button
                                      onClick={() => {
                                        handleSubmit_commentHome()
                                      }}
                                      color="secondary"
                                    >
                                      Save
                                    </Button>
                                  ) : null}
                                </div>
                              </Form>
                            </div>
                          </Collapse>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className={classnames(
                                "accordion-button",
                                "fw-medium",
                                {
                                  collapsed: !TabcustomHome,
                                }
                              )}
                              type="button"
                              onClick={t_col2}
                              style={{ cursor: "pointer" }}
                            >
                              ทำสัญญางานก่อสร้าง (รายละเอียดเรื่อง
                              บ้าน,สี,กระเบื้อง,อื่น ๆ )
                            </button>
                          </h2>

                          <Collapse
                            isOpen={TabcustomHome}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <Form
                                className="repeater"
                                encType="multipart/form-data"
                              >
                                <div>
                                  <Row>
                                    {(formCustomRows || []).map((f, k) => (
                                      <Col sm="12" md="6" lg="4" xl="3" key={k}>
                                        {/* <div className="input-group mb-3"> */}
                                        {/* <Input
                                            style={{
                                              backgroundColor:
                                                "rgba(77,99,207,0.3)",
                                            }}
                                            value={f.title}
                                            name={"title" + k}
                                            onChange={e => {
                                              let edit = [...formCustomRows]
                                              edit[k].title = e.target.value
                                              setFormCustomRows(edit)
                                            }}
                                            type="text"
                                            className="form-control"
                                            placeholder="title"
                                          /> */}
                                        <label htmlFor={"detail" + k}>
                                          {f.title}
                                        </label>

                                        <Input
                                          id={"detail" + k}
                                          type="text"
                                          className="form-control"
                                          name={"detail" + k}
                                          value={f.detail}
                                          onChange={e => {
                                            let edit = [...formCustomRows]
                                            edit[k].detail = e.target.value
                                            setFormCustomRows(edit)
                                          }}
                                          placeholder="detail"
                                        />
                                        {/* </div> */}
                                      </Col>
                                    ))}
                                  </Row>
                                </div>
                                <hr />
                                <Row>
                                  <Col sm="12" style={{ textAlign: "right" }}>
                                    {formCustomRows.length > 0 ? (
                                      <>
                                        {fileVender.length > 0 ? (
                                          <Link
                                            to={
                                              "/invoices-contract/" + projectID
                                            }
                                          >
                                            <Button
                                              type="button"
                                              color="primary"
                                            >
                                              สัญญารับจ้างก่อสร้าง
                                            </Button>
                                          </Link>
                                        ) : (
                                          <p style={{ color: "red" }}>
                                            กรุณาอัพโหลดบัตรประชาชน ลค.
                                          </p>
                                        )}
                                        <Button
                                          style={{ marginLeft: "2px" }}
                                          onClick={() => {
                                            handleSubmit_customHome()
                                          }}
                                          color="secondary"
                                        >
                                          Save
                                        </Button>
                                      </>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12" lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <Label className="form-label">
                      <span
                        style={{
                          color: "#007bff",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setActionModalList_DesignGroup({
                            action: "Add",
                            modal: true,
                          })
                        }
                      >
                        การออกแบบ (เพิ่ม)
                      </span>
                    </Label>
                  </CardTitle>

                  <Row>
                    {selectUploadComment.length > 0 ? (
                      <p style={{ color: "red" }}>กรุณาให้หัวข้อเพื่อ</p>
                    ) : null}
                    <Col sm="12" md="12" lg="6">
                      <input
                        name={selectUploadComment}
                        onChange={e => setSelectUploadComment(e.target.value)}
                        type="text"
                        className="form-control"
                        placeholder="Comment"
                      />
                    </Col>

                    <Col sm="12" md="12" lg="6">
                      <Select
                        name="selectUpload"
                        // value={selectUploadID }
                        value={optionSelectUpload.filter(
                          option => option.value == selectUploadID
                        )}
                        placeholder="กรุณาเลือกเพื่ออัพโหลดแบบก่อสร้าง"
                        onChange={e => {
                          setSelectUploadID(e.value)
                        }}
                        options={optionSelectUpload}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                  </Row>
                  <br />
                  <Form>
                    {selectUploadID != undefined &&
                    selectUploadComment.length > 0 ? (
                      <Dropzone
                        accept="application/pdf"
                        inputContent={(files, extra) =>
                          extra.reject ? "PDF files only" : "Drag Files"
                        }
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles_design(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    ) : null}

                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>

                  <br />
                  {(filePdfDesign || []).map((k, i) => (
                    <div key={i}>
                      {k.data != undefined ? (
                        k.data.length > 0 ? (
                          <>
                            <Row>
                              <Col sm="12">
                                <h5 style={{ textDecoration: "underline" }}>
                                  {k.name}
                                </h5>
                              </Col>
                              {(k.data || []).map((f, j) => (
                                <Col sm="6" lg="3" key={j}>
                                  <p>
                                    {f.comment}&nbsp;
                                    <span>
                                      <i
                                        onClick={() => delImgDesign(f.id)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "20px",
                                          textAlign: "right",
                                          color: "red",
                                        }}
                                        className="bx bx-x-circle"
                                      ></i>
                                    </span>
                                  </p>
                                  <a
                                    style={{
                                      color:
                                        j == Number(k.data.length - 1)
                                          ? "#bb2124"
                                          : "#aaaaaa",
                                      margin: 2,
                                    }}
                                    href={
                                      process.env.REACT_APP_SERVER + f.file_path
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {moment(f.created_at)
                                      .add(543, "year")
                                      .format("LLL")}
                                    &nbsp;
                                  </a>
                                </Col>
                              ))}
                            </Row>
                            <hr />
                          </>
                        ) : null
                      ) : null}
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  )
}

Plan.propTypes = {
  projectID: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
}

export default Plan
