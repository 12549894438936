import {
  GET_LIST_DESIGN_GROUPS_SUCCESS,
  GET_LIST_DESIGN_GROUPS_FAIL,
  ADD_LIST_DESIGN_GROUP_SUCCESS,
  ADD_LIST_DESIGN_GROUP_FAIL,
  UPDATE_LIST_DESIGN_GROUP_SUCCESS,
  UPDATE_LIST_DESIGN_GROUP_FAIL,
  DELETE_LIST_DESIGN_GROUP_SUCCESS,
  DELETE_LIST_DESIGN_GROUP_FAIL,
  GET_LIST_DESIGN_GROUP_PROFILE_SUCCESS,
  GET_LIST_DESIGN_GROUP_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  listDesignGroups: [],
  listDesignGroupProfile: {},
  error: {},
}

const listDesignGroups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_DESIGN_GROUPS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        listDesignGroups: action.payload,
      }

    case GET_LIST_DESIGN_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_LIST_DESIGN_GROUP_SUCCESS:
      return {
        ...state,
        listDesignGroups: [...state.listDesignGroups, action.payload],
      }

    case ADD_LIST_DESIGN_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_DESIGN_GROUP_PROFILE_SUCCESS:
      // console.log("GET_LIST_DESIGN_GROUP_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        listDesignGroupProfile: action.payload,
      }

    case UPDATE_LIST_DESIGN_GROUP_SUCCESS:
      return {
        ...state,
        listDesignGroups: state.listDesignGroups.map(designgroup =>
          designgroup.id.toString() === action.payload.id.toString()
            ? { designgroup, ...action.payload }
            : designgroup
        ),
      }

    case UPDATE_LIST_DESIGN_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_LIST_DESIGN_GROUP_SUCCESS:
      return {
        ...state,
        listDesignGroups: state.listDesignGroups.filter(
          designgroup =>
            designgroup.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_LIST_DESIGN_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_DESIGN_GROUP_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default listDesignGroups
