/* BOQS */
export const GET_BOQS = "GET_BOQS"
export const GET_BOQS_SUCCESS = "GET_BOQS_SUCCESS"
export const GET_CHECK_BOQS_SUCCESS = "GET_CHECK_BOQS_SUCCESS"
export const GET_SUM_BOQS_SUCCESS = "GET_SUM_BOQS_SUCCESS"
export const GET_MATRAIAL_BOQS_SUCCESS = "GET_MATRAIAL_BOQS_SUCCESS"
export const GET_BOQS_FAIL = "GET_BOQS_FAIL"
/* BOQS PROFILE */
export const GET_BOQ_PROFILE = "GET_BOQ_PROFILE"
export const GET_BOQ_PROFILE_SUCCESS = "GET_BOQ_PROFILE_SUCCESS"
export const GET_BOQ_PROFILE_FAIL = "GET_BOQ_PROFILE_FAIL"
export const CONFIRM_BUY_MATERIAL = "CONFIRM_BUY_MATERIAL"
export const CONFIRM_SELECT_MATERIAL = "CONFIRM_SELECT_MATERIAL"

/**
 * add boq
 */
export const ADD_NEW_BOQ = "ADD_NEW_BOQ"
export const ADD_BOQ_SUCCESS = "ADD_BOQ_SUCCESS"
export const ADD_BOQ_FAIL = "ADD_BOQ_FAIL"

/**
 * Edit boq
 */
export const UPDATE_BOQ = "UPDATE_BOQ"
export const UPDATE_BOQ_SUCCESS = "UPDATE_BOQ_SUCCESS"
export const UPDATE_BOQ_FAIL = "UPDATE_BOQ_FAIL"

/**
 * Delete boq
 */
export const DELETE_BOQ = "DELETE_BOQ"
export const DELETE_BOQ_SUCCESS = "DELETE_BOQ_SUCCESS"
export const DELETE_BOQ_FAIL = "DELETE_BOQ_FAIL"
export const UPLOAD_IMG_CHECK_BOQS = "UPLOAD_IMG_CHECK_BOQS"
