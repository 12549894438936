/* CUSTOM_HOMES */
export const GET_CUSTOM_HOMES = "GET_CUSTOM_HOMES"
export const GET_CUSTOM_HOMES_SUCCESS = "GET_CUSTOM_HOMES_SUCCESS"
export const GET_CUSTOM_HOMES_FAIL = "GET_CUSTOM_HOMES_FAIL"

/* CUSTOM_HOMES PROFILE */
export const GET_CUSTOM_HOME_PROFILE = "GET_CUSTOM_HOME_PROFILE"
export const GET_CUSTOM_HOME_PROFILE_SUCCESS = "GET_CUSTOM_HOME_PROFILE_SUCCESS"
export const GET_CUSTOM_HOME_PROFILE_FAIL = "GET_CUSTOM_HOME_PROFILE_FAIL"

/**
 * add designgroup
 */
export const ADD_NEW_CUSTOM_HOME = "ADD_NEW_CUSTOM_HOME"
export const ADD_CUSTOM_HOME_SUCCESS = "ADD_CUSTOM_HOME_SUCCESS"
export const ADD_CUSTOM_HOME_FAIL = "ADD_CUSTOM_HOME_FAIL"

/**
 * Edit designgroup
 */
export const UPDATE_CUSTOM_HOME = "UPDATE_CUSTOM_HOME"
export const UPDATE_CUSTOM_HOME_SUCCESS = "UPDATE_CUSTOM_HOME_SUCCESS"
export const UPDATE_CUSTOM_HOME_FAIL = "UPDATE_CUSTOM_HOME_FAIL"

/**
 * Delete designgroup
 */
export const DELETE_CUSTOM_HOME = "DELETE_CUSTOM_HOME"
export const DELETE_CUSTOM_HOME_SUCCESS = "DELETE_CUSTOM_HOME_SUCCESS"
export const DELETE_CUSTOM_HOME_FAIL = "DELETE_CUSTOM_HOME_FAIL"
