import {
  GET_WITHDRAW_MATERIALS_SUCCESS_SUMMARIZE,
  GET_WITHDRAW_MATERIALS_SUCCESS,
  GET_WITHDRAW_MATERIALS_FAIL,
  ADD_WITHDRAW_MATERIAL_SUCCESS,
  ADD_WITHDRAW_MATERIAL_FAIL,
  UPDATE_WITHDRAW_MATERIAL_SUCCESS,
  UPDATE_WITHDRAW_MATERIAL_FAIL,
  DELETE_WITHDRAW_MATERIAL_SUCCESS,
  DELETE_WITHDRAW_MATERIAL_FAIL,
  GET_WITHDRAW_MATERIAL_PROFILE_SUCCESS,
  GET_WITHDRAW_MATERIAL_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  withdrawMaterials: [],
  withdrawMaterialProfile: {},
  withdrawSummarizeMaterials: [],
  error: {},
}

const withdrawMaterials = (state = INIT_STATE, action) => {
  // console.log(action)

  switch (action.type) {
    case GET_WITHDRAW_MATERIALS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        withdrawMaterials: action.payload,
      }

    case GET_WITHDRAW_MATERIALS_SUCCESS_SUMMARIZE:
      // console.log(action)
      return {
        ...state,
        withdrawSummarizeMaterials: action.payload,
      }

    case GET_WITHDRAW_MATERIALS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_WITHDRAW_MATERIAL_SUCCESS:
      return {
        ...state,
        withdrawMaterials: [...state.withdrawMaterials, action.payload],
      }

    case ADD_WITHDRAW_MATERIAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_WITHDRAW_MATERIAL_PROFILE_SUCCESS:
      // console.log("GET_WITHDRAW_MATERIAL_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        withdrawMaterialProfile: action.payload,
      }

    case UPDATE_WITHDRAW_MATERIAL_SUCCESS:
      return {
        ...state,
        withdrawMaterials: state.withdrawMaterials.map(withdrawmaterial =>
          withdrawmaterial.id.toString() === action.payload.id.toString()
            ? { withdrawmaterial, ...action.payload }
            : withdrawmaterial
        ),
      }

    case UPDATE_WITHDRAW_MATERIAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_WITHDRAW_MATERIAL_SUCCESS:
      return {
        ...state,
        withdrawMaterials: state.withdrawMaterials.filter(
          withdrawmaterial =>
            withdrawmaterial.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_WITHDRAW_MATERIAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_WITHDRAW_MATERIAL_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default withdrawMaterials
