import {
  GET_PRODUCT_GROUP_PROFILE,
  GET_PRODUCT_GROUP_PROFILE_FAIL,
  GET_PRODUCT_GROUP_PROFILE_SUCCESS,
  GET_PRODUCT_GROUPS,
  GET_PRODUCT_GROUPS_FAIL,
  GET_PRODUCT_GROUPS_SUCCESS,
  ADD_NEW_PRODUCT_GROUP,
  ADD_PRODUCT_GROUP_SUCCESS,
  ADD_PRODUCT_GROUP_FAIL,
  UPDATE_PRODUCT_GROUP,
  UPDATE_PRODUCT_GROUP_SUCCESS,
  UPDATE_PRODUCT_GROUP_FAIL,
  DELETE_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP_SUCCESS,
  DELETE_PRODUCT_GROUP_FAIL,
} from "./actionTypes"

export const getProductGroups = active => ({
  type: GET_PRODUCT_GROUPS,
  payload: active,
})

export const getProductGroupsSuccess = designgroups => ({
  type: GET_PRODUCT_GROUPS_SUCCESS,
  payload: designgroups,
})

export const addNewProductGroup = designgroup => ({
  type: ADD_NEW_PRODUCT_GROUP,
  payload: designgroup,
})

export const addProductGroupSuccess = designgroup => ({
  type: ADD_PRODUCT_GROUP_SUCCESS,
  payload: designgroup,
})

export const addProductGroupFail = error => ({
  type: ADD_PRODUCT_GROUP_FAIL,
  payload: error,
})

export const getProductGroupsFail = error => ({
  type: GET_PRODUCT_GROUPS_FAIL,
  payload: error,
})

export const getProductGroupProfile = id => ({
  type: GET_PRODUCT_GROUP_PROFILE,
  payload: id,
})

export const getProductGroupProfileSuccess = designgroupProfile => ({
  type: GET_PRODUCT_GROUP_PROFILE_SUCCESS,
  payload: designgroupProfile,
})

export const getProductGroupProfileFail = error => ({
  type: GET_PRODUCT_GROUP_PROFILE_FAIL,
  payload: error,
})

export const updateProductGroup = designgroup => ({
  type: UPDATE_PRODUCT_GROUP,
  payload: designgroup,
})

// export const updateProductGroup = designgroup => console.log(designgroup);

export const updateProductGroupSuccess = designgroup => ({
  type: UPDATE_PRODUCT_GROUP_SUCCESS,
  payload: designgroup,
})

export const updateProductGroupFail = error => ({
  type: UPDATE_PRODUCT_GROUP_FAIL,
  payload: error,
})

export const deleteProductGroup = designgroup => ({
  type: DELETE_PRODUCT_GROUP,
  payload: designgroup,
})

export const deleteProductGroupSuccess = designgroup => ({
  type: DELETE_PRODUCT_GROUP_SUCCESS,
  payload: designgroup,
})

export const deleteProductGroupFail = error => ({
  type: DELETE_PRODUCT_GROUP_FAIL,
  payload: error,
})
