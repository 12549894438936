import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
} from "reactstrap"
import Dropzone from "react-dropzone"

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import {
  getMaterialStockProfile as onGetMaterialStockProfile,
  addNewMaterialStock as onAddNewMaterialStock,
  updateMaterialStock as onUpdateMaterialStock,
} from "store/material-stocks/actions"
import { getMaterialTypes as onGetMaterialTypes } from "store/material-types/actions"
import Select from "react-select"
import ExampleModalMaterialType from "../../components/Dialogs/dialogMaterial_type"

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()

  const { materialTypes } = useSelector(state => ({
    materialTypes: state.materialTypes.materialTypes,
  }))

  const { materialStockProfile } = useSelector(state => ({
    materialStockProfile: state.materialStocks.materialStockProfile,
  }))

  const [actionModalMaterialType, setActionModalMaterialType] = React.useState({
    id: null,
    action: null,
    modal: false,
  })
  const [Img, setImg] = React.useState(null)
  const [dataImg, setDataImg] = React.useState([])
  const [groups, setGroups] = React.useState([])
  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [showQtaMoney, setShowQtaMoney] = React.useState(true)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState({})

  async function checkAction() {
    dispatch(onGetMaterialTypes(1))
    setFormData({})
    if (actionModal.action == "Add") {
      setFormData({
        material_type: 1,
        qta: 0,
        price: 0,
        active: 1,
      })
    } else if (actionModal.action == "Edit") {
      dispatch(onGetMaterialStockProfile(actionModal.id))
    }
    if (actionModal.showQtaMoney == "Q") {
      setShowQtaMoney(false)
    } else {
      setShowQtaMoney(true)
    }
    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true)
    if (errors.length > 0) {
    } else {
      const data = new FormData()
      data.append("formData", JSON.stringify(formData))
      data.append("Img", dataImg)

      if (action == "Add") {
        // save new contractor
        dispatch(onAddNewMaterialStock(data))
      } else if (action == "Edit") {
        // update contractor
        dispatch(onUpdateMaterialStock(data))
      }
      setModal(!modal)
    }
    setButtonSubmit(false)
  }

  React.useEffect(async () => {
    if (actionModal.modal) {
      const options = await materialTypes.map(d => ({
        value: d.id,
        label: d.name,
      }))
      let newOption = [
        {
          value: 0,
          label: "เพิ่มใหม่",
        },
      ].concat(options)

      setGroups(newOption)
    }
    return () => {}
  }, [materialTypes]) // Only re-run the effect if count changes

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  async function handleAcceptedFiles(e) {
    if (e.target.files[0]) {
      // console.log("picture: ", e.target.files);
      // setSelectedFileHome(e.target.files[0])
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        setImg(reader.result)
      })
      reader.readAsDataURL(e.target.files[0])

      setDataImg(await resizeImageFn(e.target.files[0]))
    }
  }

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      // console.log(materialStockProfile)
      setFormData(materialStockProfile)
    }
    return () => {}
  }, [materialStockProfile]) // Only re-run the effect if count changes

  async function resizeImageFn(file) {
    // console.log("originalFile instanceof Blob", file instanceof Blob) // true
    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options)
      // console.log(
      //   "compressedFile instanceof Blob",
      //   compressedFile instanceof Blob
      // ) // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

      // await uploadToServer(compressedFile) // write your own logic
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }

  const selectColor = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // console.log(data)
      return {
        ...styles,
        color: data.value == 0 ? "#556ee6" : null,
      }
    },
  }

  return (
    <>
      <ExampleModalMaterialType actionModal={actionModalMaterialType} />

      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        centered={true}
        size="lg"
        style={{ maxWidth: "70%" }}
      >
        <ModalHeader toggle={() => setModal(!modal)}>
          {action == "Edit" ? "แก้ไข" : "เพิ่ม"}คลังวัสดุ{" "}
        </ModalHeader>
        <AvForm onSubmit={handleSubmit} model={formData}>
          <ModalBody>
            <Row>
              <Col xs={12} sm={12} md={2} lg={2}>
                <p style={{ marginBottom: "8px" }}>ประเภท</p>
                <Select
                  type="select"
                  styles={selectColor}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="material_type_id"
                  value={groups.filter(function (option) {
                    return option.value == formData.material_type_id
                  })}
                  onChange={e => {
                    if (e.value == 0) {
                      setActionModalMaterialType({
                        id: null,
                        action: "Add",
                        modal: true,
                      })
                    } else {
                      setFormData({
                        ...formData,
                        material_type_id: e.value,
                      })
                    }
                  }}
                  options={groups}
                />
              </Col>

              <Col xs={12} sm={12} md={4} lg={4}>
                <FormGroup className="mb-3">
                  <AvField
                    name="name"
                    type="text"
                    label="ชื่อ"
                    placeholder="ชื่อ"
                    value={formData.name}
                    onChange={e =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    validate={{
                      required: { value: true },
                    }}
                  />
                </FormGroup>
              </Col>

              <Col xs={12} sm={12} md={2} lg={2}>
                <FormGroup className="mb-3">
                  <AvField
                    name="unit"
                    label="หน่วย"
                    type="text"
                    placeholder="หน่วย"
                    value={formData.unit}
                    onChange={e =>
                      setFormData({ ...formData, unit: e.target.value })
                    }
                    validate={{
                      required: { value: true },
                    }}
                  />
                </FormGroup>
              </Col>
              {showQtaMoney == true || action == "Edit" ? (
                <>
                  <Col xs={12} sm={12} md={2} lg={2}>
                    <FormGroup className="mb-3">
                      <AvField
                        label="จำนวน"
                        name="qta"
                        type="text"
                        placeholder="จำนวนที่มี"
                        value={formData.qta}
                        onChange={e =>
                          setFormData({ ...formData, qta: e.target.value })
                        }
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={2} lg={2}>
                    <FormGroup className="mb-3">
                      <AvField
                        name="price"
                        label="ราคา"
                        type="text"
                        placeholder="ราคา"
                        value={formData.price}
                        onChange={e =>
                          setFormData({ ...formData, price: e.target.value })
                        }
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </FormGroup>
                  </Col>
                </>
              ) : null}

              <Col xs={12} sm={12} md={12} lg={12}>
                <FormGroup className="mb-3">
                  <AvField
                    name="note"
                    type="textarea"
                    placeholder="Note"
                    value={formData.note}
                    onChange={e =>
                      setFormData({ ...formData, note: e.target.value })
                    }
                    validate={{
                      required: { value: false },
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <FormGroup className="mt-2">
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        name="radioMaterial_typective"
                        checked={formData.material_type == 1}
                        value={1}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            material_type: e.target.value,
                          })
                        }
                      />
                      วัสดุหลัก
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label>
                      <Input
                        type="radio"
                        name="radioMaterial_typective"
                        checked={formData.material_type == 2}
                        value={2}
                        onChange={e =>
                          setFormData({
                            ...formData,
                            material_type: e.target.value,
                          })
                        }
                      />
                      วัสดุสิ้นเปลือง
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6}>
                <FormGroup className="mt-2">
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        name="radioActive"
                        checked={formData.active == 1}
                        value={1}
                        onChange={e =>
                          setFormData({ ...formData, active: e.target.value })
                        }
                      />
                      เปิดใช้งาน
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label>
                      <Input
                        type="radio"
                        name="radioActive"
                        checked={formData.active == 2}
                        value={2}
                        onChange={e =>
                          setFormData({ ...formData, active: e.target.value })
                        }
                      />
                      ปิดใช้งาน
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>

              <Col xs={12} className="mb-2">
                <Dropzone
                  multiple={false}
                  accept="image/*"
                  inputContent={(files, extra) =>
                    extra.reject ? "Image files only" : "Drag Files"
                  }
                  onDrop={acceptedFiles => {
                    handleAcceptedFiles(acceptedFiles)
                  }}
                  styles={{
                    dropzoneReject: {
                      borderColor: "red",
                      backgroundColor: "#DAA",
                    },
                    inputLabel: (files, extra) =>
                      extra.reject ? { color: "red" } : {},
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message needsclick">
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h4>Drop files here or click to upload.</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Col>
              <hr />
              <Col xs={12}>
                <h5>รูปวัสดุ</h5>

                {Img == undefined ? (
                  formData.img_path == undefined ? null : (
                    <a
                      href={process.env.REACT_APP_SERVER + formData.img_path}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={process.env.REACT_APP_SERVER + formData.img_path}
                        alt={process.env.REACT_APP_NAMECOMPANY}
                        height="257px"
                        width="257px"
                      />
                    </a>
                  )
                ) : (
                  <img
                    src={Img}
                    alt={process.env.REACT_APP_NAMECOMPANY}
                    height="257px"
                    width="257px"
                  />
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" disabled={buttonSubmit} color="secondary">
              Save
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
