import PropTypes from "prop-types"
import React from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Row,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Col,
  Button,
  Media,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import ExampleModal from "../../components/Dialogs/dialogProject"
import { useSelector, useDispatch } from "react-redux"
import { isEmpty, map } from "lodash"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "../Tables/datatables.scss"

import {
  getProjects as onGetProjects,
  deleteProject as onDeleteProject,
} from "store/projects/actions"
const CardProject = ({ projects }) => {
  const dispatch = useDispatch()
  const { SearchBar } = Search
  const history = useHistory()

  const { lastInsertProject } = useSelector(state => ({
    lastInsertProject: state.projects.lastInsertProject,
  }))

  const [listActive, setListActive] = React.useState(false)
  const [check10day, setCheck10day] = React.useState(false)
  const [lastInsert, setLastIntert] = React.useState(null)

  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "img",
      text: "รูป",
      sort: true,
      headerStyle: () => {
        return { width: "15%" }
      },
      formatter: function action(cell, row) {
        return !isEmpty(cell) ? (
          <Link to={`/projects-overview/${row.id}`} className="text-dark">
            <img
              src={process.env.REACT_APP_SERVER + cell}
              alt={process.env.REACT_APP_NAMECOMPANY}
              title={process.env.REACT_APP_NAMECOMPANY}
              className="avatar-md"
            />
          </Link>
        ) : null
      },
    },

    {
      dataField: "add_info.contact",
      text: "ข้อมูล",
      sort: true,
      headerStyle: () => {
        return { width: "49%" }
      },
      formatter: function action(cell, row) {
        return (
          <div>
            {row.add_info == undefined ? (
              ""
            ) : (
              <div>
                <p>ชื่อโปรเจค:&nbsp; {row.name}</p>
                {/* <p>ชื่อเจ้าของ:&nbsp; {row.add_info.contact}</p> */}
                <p>
                  เบอร์ติดต่อ:&nbsp;
                  <a
                    href={"tel:" + row.add_info.tel}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {row.add_info.tel}
                  </a>
                </p>
                <p>สถานที่สร้าง: {row.add_info.highlight}</p>
              </div>
            )}
          </div>
        )
      },
    },
    {
      dataField: "user.name",
      text: "ผู้รับผิดชอบ",
      sort: true,
      headerStyle: () => {
        return { width: "13%" }
      },
      formatter: function action(cell, row) {
        return (
          <div>
            {row.user == undefined ? (
              ""
            ) : (
              <p>ผู้รับผิดชอบ:&nbsp; {row.user.name}</p>
            )}

            {row.contractor == undefined
              ? ""
              : (row.contractor || []).map((v, k) => (
                  <p key={k}>ช่าง:&nbsp; {v.name_contractor}</p>
                ))}
          </div>
        )
      },
    },
    {
      dataField: "created_at",
      text: "วันที่",
      sort: true,
      headerStyle: () => {
        return { width: "13%" }
      },
      formatter: function action(cell, row) {
        return moment(cell).add(543, "year").format("DD/MM/YYYY HH:mm")
      },
    },

    {
      dataField: "id",
      text: "จัดการ",
      sort: true,
      headerStyle: () => {
        return { width: "8%" }
      },
      formatter: function action(cell, row) {
        return (
          <div>
            <UncontrolledDropdown className="mb-2" direction="left">
              <DropdownToggle
                color="white"
                className="btn btn-link text-muted mt-n2"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>

              <DropdownMenu>
                <DropdownItem onClick={() => eventAction("Edit", cell, true)}>
                  แก้ไข
                </DropdownItem>
                <DropdownItem onClick={() => onDelete(cell)}>ลบ</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )
      },
    },
  ])

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  React.useEffect(() => {
    // console.log(lastInsertProject)
    if (lastInsertProject != null && lastInsertProject != lastInsert) {
      history.push("/projects-overview/" + lastInsertProject)
      setLastIntert(lastInsertProject)
    } else {
      setLastIntert(null)
    }
  }, [lastInsertProject])

  const pageOptions = {
    sizePerPage: 10,
    totalSize: projects.length, // replace later with size(customers),
    custom: true,
  }

  const [actionModal, setActionModel] = React.useState({
    id: null,
    action: null,
    modal: false,
  })

  function eventAction(action, id, modal) {
    setActionModel({
      id: id,
      action: action,
      modal: modal,
    })
  }

  function onDelete(id) {
    dispatch(onDeleteProject(id))
  }

  // React.useEffect(() => {
  //   console.log("projects", projects)
  // }, [projects])
  function lasy10days(e) {
    // console.log(e)
    if (e) {
      dispatch(onGetProjects("10day", null))
    } else {
      dispatch(onGetProjects("list", null))
    }
    setCheck10day(e)
  }

  return (
    <React.Fragment>
      <style scoped>
        {
          "\
          .avatar-md {\
            height: 7rem;\
            width: 7rem;\
          }\
          "
        }
      </style>
      <ExampleModal actionModal={actionModal} />

      <Row>
        <Col sm={6}>
          <Button
            color="secondary"
            className="font-16 btn-block btn btn-secondary"
            onClick={() => eventAction("Add", null, true)}
          >
            <i className="mdi mdi-plus-circle-outline me-1" />
            Add
          </Button>
        </Col>

        <Col sm={6}>
          <div className="d-flex" style={{ float: "right" }}>
            <div className="form-check form-switch form-switch-lg mt-1">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizesm"
                onClick={e => {
                  lasy10days(e.target.checked)
                }}
              />
              {check10day == true ? (
                <label
                  className="form-check-label"
                  htmlFor="customSwitchsizesm"
                >
                  ย้อนหลัง 10 วัน
                </label>
              ) : (
                <label
                  className="form-check-label"
                  htmlFor="customSwitchsizesm"
                >
                  มัดจำแล้ว
                </label>
              )}
            </div>
            &emsp;
            <Button
              color={listActive == true ? "primary" : "secondary"}
              className="font-16 btn-block btn btn-primary"
              onClick={() => setListActive(!listActive)}
            >
              <i className="mdi mdi-format-list-numbered me-1" />
            </Button>
          </div>
        </Col>
      </Row>
      <br />
      {listActive == true ? (
        <Card>
          <CardBody>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={tableColumns}
              data={projects}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={tableColumns}
                  data={projects}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row>
                        <Col sm="8"></Col>
                        <Col sm="4" style={{ textAlign: "right" }}>
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <br />
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"id"}
                              responsive
                              bordered
                              striped
                              defaultSorted={defaultSorted}
                              classes={"table align-middle"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
      ) : (
        <div className="row-product">
          {map(projects, (project, key) => (
            <div className="item-product" key={key}>
              {/* <div className="well-product"> */}
              <Card>
                <CardBody>
                  <div className="float-end ms-2">
                    <UncontrolledDropdown className="mb-2" direction="left">
                      <DropdownToggle
                        color="white"
                        className="btn btn-link text-muted mt-n2"
                      >
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle>

                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => eventAction("Edit", project.id, true)}
                        >
                          แก้ไข
                        </DropdownItem>
                        <DropdownItem onClick={() => onDelete(project.id)}>
                          ลบ
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>

                  <Link
                    to={`/projects-overview/${project.id}`}
                    className="text-dark"
                  >
                    {project.img == undefined ? null : (
                      <CardImg
                        className="img-fluid rounded"
                        src={process.env.REACT_APP_SERVER + project.img}
                        alt={process.env.REACT_APP_NAMECOMPANY}
                      />
                    )}

                    <hr />
                    <div>
                      <h5 className="text-truncate font-size-15">
                        บ้าน : {project.name}
                      </h5>

                      <p className="text-muted m-0">
                        สถานที่ทำ/อื่น ๆ :{" "}
                        {project.add_info == undefined
                          ? ""
                          : project.add_info.highlight}
                      </p>
                    </div>
                  </Link>
                  <hr />
                  <Row>
                    <Col sm={12}>
                      <p className="text-muted m-0">
                        เบอร์ติดต่อ :{" "}
                        {project.add_info == undefined ? (
                          ""
                        ) : (
                          <a
                            href={"tel:" + project.add_info.tel}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {project.add_info.tel}
                          </a>
                        )}
                      </p>
                    </Col>
                    <Col sm={12}>
                      <p className="text-muted m-0">
                        สถานที่ :{" "}
                        {project.add_info == undefined ? (
                          "ไม่มี"
                        ) : (
                          <a
                            href={project.add_info.location}
                            target="_blank"
                            rel="noreferrer"
                          >
                            คลิก
                          </a>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {project.user == undefined ? (
                        ""
                      ) : (
                        <div>
                          <hr />
                          <p>ผู้รับผิดชอบ:&nbsp; {project.user.name}</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {/* </div> */}
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}

CardProject.propTypes = {
  projects: PropTypes.array,
}

export default CardProject
