/* MATERIAL_PRICES */
export const GET_MATERIAL_PRICES = "GET_MATERIAL_PRICES"
export const GET_MATERIAL_PRICES_SUCCESS = "GET_MATERIAL_PRICES_SUCCESS"
export const GET_MATERIAL_PRICES_FAIL = "GET_MATERIAL_PRICES_FAIL"

/* MATERIAL_PRICES PROFILE */
export const GET_MATERIAL_PRICE_PROFILE = "GET_MATERIAL_PRICE_PROFILE"
export const GET_MATERIAL_PRICE_PROFILE_SUCCESS = "GET_MATERIAL_PRICE_PROFILE_SUCCESS"
export const GET_MATERIAL_PRICE_PROFILE_FAIL = "GET_MATERIAL_PRICE_PROFILE_FAIL"

/**
 * add materialshop
 */
export const ADD_NEW_MATERIAL_PRICE = "ADD_NEW_MATERIAL_PRICE"
export const ADD_MATERIAL_PRICE_SUCCESS = "ADD_MATERIAL_PRICE_SUCCESS"
export const ADD_MATERIAL_PRICE_FAIL = "ADD_MATERIAL_PRICE_FAIL"

/**
 * Edit materialshop
 */
export const UPDATE_MATERIAL_PRICE = "UPDATE_MATERIAL_PRICE"
export const UPDATE_MATERIAL_PRICE_SUCCESS = "UPDATE_MATERIAL_PRICE_SUCCESS"
export const UPDATE_MATERIAL_PRICE_FAIL = "UPDATE_MATERIAL_PRICE_FAIL"

/**
 * Delete materialshop
 */
export const DELETE_MATERIAL_PRICE = "DELETE_MATERIAL_PRICE"
export const DELETE_MATERIAL_PRICE_SUCCESS = "DELETE_MATERIAL_PRICE_SUCCESS"
export const DELETE_MATERIAL_PRICE_FAIL = "DELETE_MATERIAL_PRICE_FAIL"
