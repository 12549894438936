import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_PROFITLOSSS,
  GET_PROFITLOSS_PROFILE,
  ADD_NEW_PROFITLOSS,
  DELETE_PROFITLOSS,
  UPDATE_PROFITLOSS,
} from "./actionTypes"

import {
  getProfitLossProjectsSuccess,
  getProfitLossProjectsFail,
  getProfitLossProjectProfileSuccess,
  getProfitLossProjectProfileFail,
  addProfitLossProjectFail,
  addProfitLossProjectSuccess,
  updateProfitLossProjectSuccess,
  updateProfitLossProjectFail,
  deleteProfitLossProjectSuccess,
  deleteProfitLossProjectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProfitLossProjects,
  getProfitLossProjectProfile,
  addNewProfitLossProject,
  updateProfitLossProject,
  deleteProfitLossProject,
  uploadImgProfitLossProject,
} from "../../helpers/fakebackend_helper"

function* fetchProfitLossProjects({ payload: project_id }) {
  try {
    // console.log(1);
    const response = yield call(getProfitLossProjects, project_id)
    // console.log("fetchProfitLossProjects", response)
    yield put(getProfitLossProjectsSuccess(response))
  } catch (error) {
    yield put(getProfitLossProjectsFail(error))
  }
}

function* fetchProfitLossProjectProfile({ payload: data }) {
  try {
    const response = yield call(getProfitLossProjectProfile, data)
    // console.log(response);
    yield put(getProfitLossProjectProfileSuccess(response))
  } catch (error) {
    yield put(getProfitLossProjectProfileFail(error))
  }
}

function* onUpdateProfitLossProject({ payload: profitlossproject }) {
  try {
    // let x = profitlossproject.get('formData')
    // console.log(x)
    const response = yield call(updateProfitLossProject, profitlossproject)
    // console.log(response);
    yield put(updateProfitLossProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateProfitLossProjectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteProfitLossProject({ payload: profitlossproject }) {
  try {
    const response = yield call(deleteProfitLossProject, profitlossproject)
    yield put(deleteProfitLossProjectSuccess(response))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ลบ",
      message: "ลบสำเร็จ",
    })
  } catch (error) {
    yield put(deleteProfitLossProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ลบ",
      message: "ลบล้มเหลว",
    })
  }
}

function* onAddNewProfitLossProject({ payload: profitlossproject }) {
  try {
    const response = yield call(addNewProfitLossProject, profitlossproject)
    // console.log(response);
    yield put(addProfitLossProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addProfitLossProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* profitlossProjectsSaga() {
  yield takeEvery(GET_PROFITLOSSS, fetchProfitLossProjects)
  yield takeEvery(GET_PROFITLOSS_PROFILE, fetchProfitLossProjectProfile)
  yield takeEvery(ADD_NEW_PROFITLOSS, onAddNewProfitLossProject)
  yield takeEvery(UPDATE_PROFITLOSS, onUpdateProfitLossProject)
  yield takeEvery(DELETE_PROFITLOSS, onDeleteProfitLossProject)
}

export default profitlossProjectsSaga
