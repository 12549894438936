import PropTypes from "prop-types"
import React from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Button, Card, CardBody, Media } from "reactstrap"
import { isEmpty, map } from "lodash"
import Select from "react-select"
import ReactExport from "react-export-excel"
import { Link, useHistory } from "react-router-dom"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Thai } from "flatpickr/dist/l10n/th.js"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { updateSlipproject as onUpdateSlipproject } from "store/slips-project/actions"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// import "../Tables/datatables.scss"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
//import action
import ExampleModal from "components/Dialogs/dialogAdd_money"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work
// Pages Components
import logo_s from "assets/images/logo_s.png"
import { getSlipprojects as onGetSlipprojects } from "store/slips-project/actions"
//i18n
import THBText from "thai-baht-text" // for ES6

//redux
import { useSelector, useDispatch } from "react-redux"

import pdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

//ต้องระบุตามชื่อของ ไฟล์ font
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
}

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const AuditDashboard = props => {
  const dispatch = useDispatch()

  const { slipproject } = useSelector(state => ({
    slipproject: state.slipprojects.slipproject,
  }))

  const [optionSelectUpload, setOptionSelectUpload] = React.useState([
    { value: -1, label: "ทั้งหมด" },
    { value: 0, label: "ยังไม่บันทึก" },
    { value: 1, label: "เงินสำรองจ่าย" },
    { value: 2, label: "เบิกค่าแรง" },
    { value: 3, label: "ค่าใข้จ่าย" },
    { value: 4, label: "รายจ่ายพนักงาน" },
  ])

  const { slipDetailCard } = useSelector(state => ({
    slipDetailCard: state.slipprojects.slipDetailCard,
  }))

  const { SearchBar } = Search

  const [actionModal, setActionModel] = React.useState({
    id: null,
    action: null,
    modal: false,
  })

  const [reports, setReports] = React.useState([])
  const [rowSelect, setRowSelect] = React.useState([])

  const [type_id, setType_id] = React.useState(0)
  const [timeStart, setTimeStart] = React.useState(
    moment().startOf("day").format("YYYY-MM-DD")
  )
  const [timeEnd, setTimeEnd] = React.useState(
    moment().endOf("day").format("YYYY-MM-DD")
  )
  const [base64_logo_f, setBase64_logo_f] = React.useState(null)
  const [base64_logo_s, setBase64_logo_s] = React.useState(null)
  const [dataExcel, setDataEcel] = React.useState([])

  const [tableData, setTableData] = React.useState([])
  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "img_path",
      text: "รูป",
      select: false,
      sort: true,
      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return !isEmpty(row.img) ? (
          <div style={{ textAlign: "center" }}>
            <img
              onClick={() => eventAction("Edit", row.id, true, row)}
              src={process.env.REACT_APP_SERVER + cell}
              // src={cell}
              alt={process.env.REACT_APP_NAMECOMPANY}
              title={process.env.REACT_APP_NAMECOMPANY}
              className="avatar-md"
            />
            <p>
              {moment(row.created_at).add(543, "year").format("DD/MM/YYYY")}
            </p>
          </div>
        ) : (
          <p>รอหลักฐาน</p>
        )
      },
    },
    // {
    //   dataField: "type.name",
    //   text: "ประเภท",
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: "100px" }
    //   },
    // },
    {
      dataField: "comment",
      text: "รายละเอียด",
      sort: true,
      headerStyle: () => {
        return { width: "100px" }
      },
      formatter: function action(cell, row) {
        return (
          <div style={{ textAlign: "center" }}>
            {row.project_id == 0 ? null : (
              <Link to={`/projects-overview/${row.project_id}`}>
                {row.project_name}
              </Link>
            )}

            {!isEmpty(row.contractor_name) && (
              <p style={{ marginTop: 0, marginBottom: 0 }}>
                {row.contractor_name}
              </p>
            )}
            <p style={{ marginTop: 0, marginBottom: 0 }}>{row.comment}</p>
          </div>
        )
      },
    },
    // {
    //   dataField: "note",
    //   text: "เพิ่มเติม",
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: "100px" }
    //   },
    // },
    // {
    //   dataField: "no",
    //   text: "เลขอ้างอิง",
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: "100px" }
    //   },
    // },

    // {
    //   dataField: "discount",
    //   text: "ส่วนลด",
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: "100px" }
    //   },
    //   formatter: function action(cell, row) {
    //     // console.log(cell);
    //     return (
    //       <span>
    //         {parseInt(cell).toLocaleString("th-TH", {
    //           minimumFractionDigits: 0,
    //           maximumFractionDigits: 0,
    //         })}
    //       </span>
    //     )
    //   },
    // },

    {
      dataField: "money",
      text: "จำนวนเงิน (บ.)",
      sort: true,
      headerStyle: () => {
        return { width: "80px", textAlign: "right" }
      },
      formatter: function action(cell, row) {
        // console.log(cell);
        return (
          <div style={{ textAlign: "right" }}>
            <span>
              {parseInt(cell).toLocaleString("th-TH", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
          </div>
        )
      },
    },

    {
      dataField: "id",
      text: "จัดการ",
      sort: true,
      headerStyle: () => {
        return { width: "50px" }
      },
      formatter: function action(cell, row) {
        return row.type == 5 ? null : (
          <div className="d-flex">
            <Button
              size="md"
              onClick={() => eventAction("Edit", cell, true, row)}
              className="mr-1 mb-1"
              color="primary"
              outline
            >
              <i className="bx bx-pencil"></i>
            </Button>
            &nbsp;
            {row.check == 0 && row.no != null ? (
              <Button
                size="md"
                onClick={() => check_pay("Edit", row)}
                className="mr-1 mb-1"
                color="success"
                outline
              >
                <i className="bx bx-comment-check"></i>
              </Button>
            ) : null}
          </div>
        )
      },
    },
  ])

  const onChangeTableData = v => {
    const data = new FormData()
    data.append("project_id", 0)
    data.append("type", "all")

    if (v.type_id == undefined) {
      data.append("type_id", type_id)
      if (isEmpty(v[0])) {
        setTimeStart(moment(v[0]).format("YYYY-MM-DD"))
      }
      if (isEmpty(v[1])) {
        setTimeEnd(moment(v[1]).format("YYYY-MM-DD"))
      }
      data.append("timeStart", moment(v[0]).format("YYYY-MM-DD"))
      data.append("timeEnd", moment(v[1]).format("YYYY-MM-DD"))
    } else {
      setType_id(v.type_id)
      data.append("type_id", v.type_id)
      data.append("timeStart", v.timeStart)
      data.append("timeEnd", v.timeEnd)
    }

    dispatch(onGetSlipprojects(data))
  }

  React.useMemo(() => {
    onChangeTableData({
      type_id: type_id,
      timeStart: moment().startOf("day").format("YYYY-MM-DD"),
      timeEnd: moment().endOf("day").format("YYYY-MM-DD"),
    })
  }, [])

  React.useEffect(() => {
    // console.log(slipproject)
    if (!isEmpty(slipproject)) {
      setTableData(slipproject)
    } else {
      setTableData([])
    }
  }, [slipproject])

  React.useEffect(() => {
    // console.log("slipDetailCard", slipDetailCard)
    if (!isEmpty(slipDetailCard)) {
      setReports(slipDetailCard)
    } else {
      setReports([])
    }
  }, [slipDetailCard])

  // React.useEffect(() => {
  //   console.log("dataExcel", dataExcel)
  // }, [dataExcel])
  async function check_pay(type, dataRow) {
    if (type == "Edit") {
      // console.log(dataRow)
      let formData = dataRow
      formData.check = 1
      const data = new FormData()
      data.append("formData", JSON.stringify(formData))
      dispatch(onUpdateSlipproject(data))
    }
  }
  async function printInvoice() {
    var docDefinition = {
      watermark: {
        text: process.env.REACT_APP_NAMECOMPANY,
        color: "#e6e6e6",
        opacity: 0.5,
      },
      content: (rowSelect || []).map((v, k) => [
        {
          columns: [
            {
              alignment: "left",
              width: "50%",
              text: [
                " วันที่: " + moment(v.date_add).add(543, "year").format("LL"),
                " เลขอ้างอิง: " + v.no,
                v.type == 3 ? " เลขใบเสร็จ: " + v.note : null,
              ],
            },
            {
              alignment: "right",
              width: "50%",
              text: "",
            },
          ],
        },
        {
          columns: [
            {
              style: "header",
              alignment: "left",
              width: "80%",
              text: [
                "(ต้นฉบับ / original)\n",
                {
                  text: "ใบสำคัญจ่าย\n",
                  fontSize: 18,
                  bold: true,
                },
              ],
            },
            {
              image: base64_logo_f,
              width: 50,
              height: 50,
            },
            {
              image: base64_logo_s,
              width: 40,
              height: 50,
            },
          ],
        },
        {
          margin: [0, 0, 0, 20],
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["50%", "50%"],
            body: [
              [
                {
                  border: [false, false, true, false],
                  text: [
                    "ชื่อผู้ขาย: ",
                    {
                      text: "หจก. เสริมทรัพย์ 27\n",
                      fontSize: 13,
                      bold: true,
                    },
                    "ที่อยู่: ซอยวัดเวฬุวนาราม 16 ถนนสรงประภา แขวงดอนเมือง เขตดอนเมือง กรุงเทพมหานคร 10210\n",
                    v.vat_type == 1
                      ? null
                      : "เลขที่เสียภาษี: 0103558012762 (สำนักงานใหญ่)",
                  ],
                },
                v.type == 2
                  ? {
                      border: [false, false, false, false],

                      text: [
                        "ชื่อผู้ทำรายการ: ",
                        {
                          border: [false, false, false, false],
                          text: v.contractor_name,
                          fontSize: 13,
                          bold: true,
                        },
                        "\nที่อยู่: " + v.contractor_address,
                        "\nเลขที่ผู้เสียภาษี: " + v.contractor_id_card,
                      ],
                    }
                  : v.type == 3
                  ? {
                      border: [false, false, false, false],

                      text: [
                        "ชื่อผู้ทำรายการ: ",
                        {
                          border: [false, false, false, false],
                          text: v.shop_name,
                          fontSize: 13,
                          bold: true,
                        },
                        "\nที่อยู่: " + v.shop_address,
                        "\nเลขที่ผู้เสียภาษี: " + v.shop_vat_no,
                      ],
                    }
                  : v.type == 4
                  ? {
                      border: [false, false, false, false],

                      text: [
                        "ชื่อผู้ทำรายการ: ",
                        {
                          border: [false, false, false, false],
                          text: v.employee_name,
                          fontSize: 13,
                          bold: true,
                        },
                        "\nที่อยู่: " + v.employee_address,
                        "\nเลขที่ผู้เสียภาษี: " + v.employee_id_card,
                      ],
                    }
                  : null,
              ],
            ],
          },
          layout: {
            defaultBorder: false,
          },
        },
        v.type == 2 || v.type == 4
          ? {
              margin: [0, 0, 0, 50],
              style: "tableExample",
              table: {
                widths: ["auto", "*", "auto", "auto", "auto"],
                heights: function (row) {
                  return row == 1 ? 350 : 0
                },
                body: [
                  [
                    {
                      bold: true,
                      text: "ลำดับ\nOrd no",
                    },
                    {
                      bold: true,
                      text: "รายละเอียด\nDescription",
                    },
                    {
                      bold: true,
                      text: "ก่อน\nBefore",
                      alignment: "right",
                    },
                    {
                      bold: true,
                      text: "หัก ณ ที่จ่าย\nTax",
                      alignment: "right",
                    },
                    {
                      bold: true,
                      text: "ยอดสุทธิ\nAmount",
                      alignment: "right",
                    },
                  ],
                  [
                    {
                      text: "1",
                    },
                    {
                      text: isEmpty(v.note)
                        ? v.comment
                        : v.comment + " (" + v.note + ")",
                    },
                    {
                      alignment: "right",
                      text: parseFloat(parseFloat(v.money)).toLocaleString(
                        "th-TH",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      ),
                    },
                    {
                      alignment: "right",
                      text: parseFloat(parseFloat(v.vat)).toLocaleString(
                        "th-TH",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      ),
                    },
                    {
                      alignment: "right",
                      text: parseFloat(
                        parseFloat(v.money_after)
                      ).toLocaleString("th-TH", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    },
                  ],

                  [
                    {
                      alignment: "center",
                      text: "(" + THBText(v.money_after) + ")",
                      colSpan: 3,
                    },
                    {},
                    {},
                    {
                      alignment: "right",
                      text: "ยอดสุทธิ (บาท)",
                    },
                    {
                      alignment: "right",
                      text: parseFloat(
                        parseFloat(v.money_after)
                      ).toLocaleString("th-TH", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    },
                  ],
                ],
              },
            }
          : v.type == 3 && v.pay_id == 1
          ? rowType4(
              v.add_money_details,
              v.money,
              v.discount,
              v.vat,
              v.vat_material_type
            )
          : (v.type == 3 && v.pay_id == 2) || (v.type == 3 && v.pay_id == 3)
          ? {
              margin: [0, 0, 0, 50],
              style: "tableExample",
              table: {
                widths: ["auto", "*", "auto", "auto", "auto"],
                heights: function (row) {
                  return row == 1 ? 350 : 0
                },
                body: [
                  [
                    {
                      bold: true,
                      text: "ลำดับ\nOrd no",
                    },
                    {
                      bold: true,
                      text: "รายละเอียด\nDescription",
                    },
                    {
                      bold: true,
                      text: "ก่อน\nBefore",
                      alignment: "right",
                    },
                    {
                      bold: true,
                      text: "ภาษีมูลค่าเพิ่ม\nVat",
                      alignment: "right",
                    },
                    {
                      bold: true,
                      text: "ยอดสุทธิ\nAmount",
                      alignment: "right",
                    },
                  ],
                  [
                    {
                      text: "1",
                    },
                    {
                      text: isEmpty(v.note)
                        ? v.comment
                        : v.comment + " (" + v.note + ")",
                    },
                    {
                      alignment: "right",
                      text: parseFloat(v.money_after).toLocaleString("th-TH", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }),
                    },
                    {
                      alignment: "right",
                      text: parseFloat(parseFloat(v.vat)).toLocaleString(
                        "th-TH",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      ),
                    },
                    {
                      alignment: "right",
                      text: parseFloat(parseFloat(v.money)).toLocaleString(
                        "th-TH",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      ),
                    },
                  ],

                  [
                    {
                      alignment: "center",
                      text: "(" + THBText(v.money) + ")",
                      colSpan: 3,
                    },
                    {},
                    {},
                    {
                      alignment: "right",
                      text: "ยอดสุทธิ (บาท)",
                    },
                    {
                      alignment: "right",
                      text: parseFloat(parseFloat(v.money)).toLocaleString(
                        "th-TH",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      ),
                    },
                  ],
                ],
              },
            }
          : null,
        isEmpty(v.img)
          ? // isEmpty(v.myBase64)
            // ?
            { text: "", pageBreak: "after" }
          : { text: "", pageBreak: "after" },
        isEmpty(v.img)
          ? // isEmpty(v.myBase64)
            // ?
            null
          : {
              pageBreak: "after",
              image: v.myBase64,
              fit: [380, 380],
              alignment: "center",
              margin: [0, 20, 0, 0],
            },

        // rowSelect.length == Number(k) + 1
        //   ? { text: "", pageBreak: "before" }
        //   : null,
      ]),
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        subheader: {
          fontSize: 13,
          decoration: "underline",
          decorationStyle: "solid",
          decorationColor: "black",
        },
        tableExample: {
          fontSize: 13,
        },
        tableHeader: {
          bold: true,
          fontSize: 14,
          color: "black",
        },
      },
      defaultStyle: {
        font: "THSarabunNew",
      },
      pageSize: "A4",
    }
    // pdfMake.createPdf(docDefinition).open()
    let quo_name = "ใบสำคัญจ่าย_" + moment().format("YYYY-MM-DD hh:mm") + ".pdf"
    // console.log(quo_name)
    pdfMake.createPdf(docDefinition).download(quo_name)
  }

  function eventAction(action, id, modal, data) {
    setActionModel({
      id: id,
      action: action,
      modal: modal,
      data: data,
    })
  }

  function buildTableBody(
    rows,
    headers,
    moneyAll,
    discountAll,
    vatAll,
    vat_material_type
  ) {
    let body = []
    for (const key in headers) {
      if (headers.hasOwnProperty(key)) {
        let header = headers[key]
        let row = []
        row.push(header.col_1)
        row.push(header.col_2)
        row.push(header.col_3)
        row.push(header.col_4)
        row.push(header.col_5)
        row.push(header.col_6)
        row.push(header.col_7)
        row.push(header.col_8)
        body.push(row)
      }
    }

    for (const key in rows) {
      if (rows.hasOwnProperty(key)) {
        let data = rows[key]
        let row = []
        if (vat_material_type == 1) {
          row.push(Number(key) + 1)
          row.push(data.material_name.toString())
          row.push({
            alignment: "right",
            text: parseFloat(parseFloat(data.price)).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          })
          row.push({
            alignment: "right",
            text: parseFloat(data.qta).toLocaleString("th-TH", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          })
          row.push({
            alignment: "right",
            text: parseFloat(
              parseFloat(data.price) * parseFloat(data.qta)
            ).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          })

          row.push({
            alignment: "right",
            text: parseFloat(data.discount).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          })

          row.push({
            alignment: "right",
            text: parseFloat(data.vat).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          })
          row.push({
            alignment: "right",
            text: parseFloat(
              parseFloat(data.price_sum - data.discount)
            ).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          })
        } else {
          row.push(Number(key) + 1)
          row.push(data.material_name.toString())
          row.push({
            alignment: "right",
            text: parseFloat(data.price).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          })
          row.push({
            alignment: "right",
            text: parseFloat(data.qta).toLocaleString("th-TH", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          })
          row.push({
            alignment: "right",
            text: parseFloat(
              parseFloat(data.price_vat) * parseFloat(data.qta)
            ).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          })
          row.push({
            alignment: "right",
            text: parseFloat(data.discount).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          })
          row.push({
            alignment: "right",
            text: parseFloat(data.vat).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          })

          row.push({
            alignment: "right",
            text: parseFloat(
              parseFloat(parseFloat(data.price) * parseFloat(data.qta)) -
                data.discount
            ).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          })
        }

        body.push(row)
      }
    }

    for (let index = 0; index < 1; index++) {
      let row = []

      row.push({
        alignment: "center",
        text: "(" + THBText(discountAll) + ")",
        colSpan: 6,
      })
      row.push({})
      row.push({})
      row.push({})
      row.push({})
      row.push({})
      row.push({
        alignment: "right",
        text: "ส่วนลดดรวม",
      })

      row.push({
        alignment: "right",
        text: parseFloat(parseFloat(discountAll)).toLocaleString("th-TH", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      })

      body.push(row)
    }

    for (let index = 0; index < 1; index++) {
      let row = []

      row.push({
        alignment: "center",
        text: "(" + THBText(vatAll) + ")",
        colSpan: 6,
      })
      row.push({})
      row.push({})
      row.push({})
      row.push({})
      row.push({})
      row.push({
        alignment: "right",
        text: "ภาษีมูลค่าเพิ่ม",
      })

      row.push({
        alignment: "right",
        text: parseFloat(parseFloat(vatAll)).toLocaleString("th-TH", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      })

      body.push(row)
    }

    for (let index = 0; index < 1; index++) {
      let row = []
      row.push({
        alignment: "center",
        text: "(" + THBText(parseFloat(moneyAll)) + ")",
        colSpan: 6,
      })
      row.push({})
      row.push({})
      row.push({})
      row.push({})
      row.push({})
      row.push({
        alignment: "right",
        text: "ยอดสุทธิ (บาท)",
      })

      row.push({
        alignment: "right",
        text: parseFloat(parseFloat(moneyAll)).toLocaleString("th-TH", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      })

      body.push(row)
    }

    return body
  }

  function rowType4(data, moneyAll, discountAll, vatAll, vat_material_type) {
    var headers = {
      fila_0: {
        col_1: {
          bold: true,
          text: "ลำดับ",
        },
        col_2: {
          bold: true,
          text: "รายละเอียด",
        },
        col_3: {
          bold: true,
          alignment: "right",
          text: "ราคา/หน่วย",
        },
        col_4: {
          bold: true,
          alignment: "right",
          text: "จำนวน",
        },
        col_5: {
          bold: true,
          alignment: "right",
          text: "ก่อน",
        },
        col_6: {
          bold: true,
          alignment: "right",
          text: "ส่วนลด",
        },
        col_7: {
          bold: true,
          alignment: "right",
          text: "ภาษีมูลค่าเพิ่ม",
        },
        col_8: {
          bold: true,
          alignment: "right",
          text: "ยอดสุทธิ",
        },
      },
    }

    return {
      table: {
        widths: ["auto", "*", "auto", "auto", "auto", "auto", "auto", "auto"],
        // widths: ["auto", "*", "*", "*"],
        headerRows: 1,
        body: buildTableBody(
          data,
          headers,
          moneyAll,
          discountAll,
          vatAll,
          vat_material_type
        ),
      },
    }
  }

  const pageOptions = {
    sizePerPage: 30,
    page: 1,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  function rowStyleFormat(row, rowIdx) {
    return {
      backgroundColor:
        row.check == 0
          ? "rgba(250, 184, 184,0.2)"
          : row.check == 1
          ? "rgba(73,166,122,0.2)"
          : null,
    }
  }

  React.useMemo(async () => {
    setBase64_logo_s(await getBase64ImageFromURL(logo_s))
  }, [])

  function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest()
    xhr.onload = function () {
      var reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open("GET", url)
    xhr.responseType = "blob"
    xhr.send()
  }

  function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image()
      img.setAttribute("crossOrigin", "anonymous")

      img.onload = () => {
        var canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height

        var ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0)

        var dataURL = canvas.toDataURL("image/png")

        resolve(dataURL)
      }

      img.onerror = error => {
        reject(error)
      }

      img.src = url
    })
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    hideSelectAll: false,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        if (row.type > 1) {
        } else {
          row.row_no = dataExcel.length + 1
          if (isEmpty(row.img)) {
            setRowSelect([
              ...rowSelect,
              {
                ...row,
                myBase64: null,
              },
            ])
          } else {
            // setRowSelect([
            //   ...rowSelect,
            //   {
            //     ...row,
            //     img: null,
            //   },
            // ])
            toDataUrl(
              process.env.REACT_APP_SERVER + row.img_path,
              function (myBase64) {
                // console.log("myBase64", myBase64)
                setRowSelect([
                  ...rowSelect,
                  {
                    ...row,
                    myBase64: myBase64,
                  },
                ])
              }
            )
          }

          setDataEcel([...dataExcel, row])

          return true
        }
      } else {
        var modifiedRows = [...rowSelect]
        modifiedRows = modifiedRows.filter(x => x["id"] !== row.id)
        setRowSelect(modifiedRows)

        var modifiedDataExcel = [...dataExcel]
        modifiedDataExcel = modifiedDataExcel.filter(x => x["id"] !== row.id)
        setDataEcel(modifiedDataExcel)
        // console.log("unSelect", row)
        // console.log("modifiedRows", modifiedRows)
      }
      // console.log(rowIndex)
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        let newRows = []
        for (let index = 0; index < rows.length; index++) {
          rows[index].row_no = index + 1
          if (rows[index].type > 1) {
          } else {
            if (isEmpty(rows[index])) {
              rows[index].myBase64 = null
              newRows.push(rows[index])
            } else {
              toDataUrl(
                process.env.REACT_APP_SERVER + rows[index].img_path,
                function (myBase64) {
                  rows[index].myBase64 = myBase64
                  newRows.push(rows[index])
                }
              )
            }
          }
          newRows.push(rows[index])
        }
        // console.log("newRows", newRows)
        setRowSelect(newRows)
        setDataEcel(newRows)
      } else {
        setRowSelect([])
        setDataEcel([])
      }
    },
    // bgColor: (row, rowIndex) => (rowIndex > 1 ? null : "#556EE6"),
    style: { backgroundColor: "#556EE6" },
  }

  // const selectRow = {
  //   mode: "checkbox",
  //   clickToSelect: false,
  //   hideSelectAll: false,
  //   onSelect: (row, isSelect, rowIndex, e) => {
  //     if (isSelect) {
  //       if (row.type == 1) {
  //       } else {
  //         if (isEmpty(row.img)) {
  //           setRowSelect([
  //             ...rowSelect,
  //             {
  //               ...row,
  //               myBase64: null,
  //             },
  //           ])
  //         } else {
  //           // setRowSelect([
  //           //   ...rowSelect,
  //           //   {
  //           //     ...row,
  //           //     img: null,
  //           //   },
  //           // ])
  //           toDataUrl(
  //             process.env.REACT_APP_SERVER + row.img_path,
  //             function (myBase64) {
  //               // console.log("myBase64", myBase64)
  //               setRowSelect([
  //                 ...rowSelect,
  //                 {
  //                   ...row,
  //                   myBase64: myBase64,
  //                 },
  //               ])
  //             }
  //           )
  //         }

  //         setDataEcel([...dataExcel, row])

  //         return true
  //       }
  //     } else {
  //       var modifiedRows = [...rowSelect]
  //       modifiedRows = modifiedRows.filter(x => x["id"] !== row.id)
  //       setRowSelect(modifiedRows)

  //       var modifiedDataExcel = [...dataExcel]
  //       modifiedDataExcel = modifiedDataExcel.filter(x => x["id"] !== row.id)
  //       setDataEcel(modifiedDataExcel)
  //       // console.log("unSelect", row)
  //       // console.log("modifiedRows", modifiedRows)
  //     }
  //     // console.log(rowIndex)
  //   },
  //   onSelectAll: (isSelect, rows, e) => {
  //     if (isSelect) {
  //       let newRows = []
  //       for (let index = 0; index < rows.length; index++) {
  //         if (rows[index].type == 1) {
  //         } else {
  //           toDataUrl(
  //             process.env.REACT_APP_SERVER + rows[index].img_path,
  //             function (myBase64) {
  //               rows[index].myBase64 = myBase64
  //               newRows.push(rows[index])
  //             }
  //           )
  //         }
  //       }
  //       setRowSelect(newRows)
  //       setDataEcel(newRows)
  //     } else {
  //       setRowSelect([])
  //       setDataEcel([])
  //     }
  //   },
  //   // bgColor: (row, rowIndex) => (rowIndex > 1 ? null : "#556EE6"),
  //   style: { backgroundColor: "#556EE6" },
  // }

  // React.useEffect(() => {
  //   console.log("rowSelect", rowSelect)
  // }, [rowSelect])

  return (
    <React.Fragment>
      <ExampleModal actionModal={actionModal} />

      <style scoped></style>

      <div className="page-content">
        <MetaTags>
          <title>AuditD | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}

          <Row>
            <Col sm="12" xl="12">
              <Row>
                {/* Reports Render */}

                {map(reports, (report, key) => (
                  <Col sm="12" md="6" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p
                              style={{
                                color: report.color,
                              }}
                            >
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">การเงินภายใน บ</h4>
                  </div>
                  <div>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={tableColumns}
                      data={tableData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={tableColumns}
                          data={tableData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="12" md="6" lg="6" className="mb-2">
                                  <Select
                                    isSearchable={false}
                                    name="selectUpload"
                                    // value={selectUploadID }
                                    value={optionSelectUpload.filter(
                                      option => option.value == type_id
                                    )}
                                    onChange={e => {
                                      let x = {
                                        type_id: e.value,
                                        timeStart: timeStart,
                                        timeEnd: timeEnd,
                                      }
                                      onChangeTableData(x)
                                      setRowSelect([])
                                    }}
                                    placeholder="โปรดเลือก"
                                    options={optionSelectUpload}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                                <Col sm="12" md="6" lg="6" className="mb-2">
                                  <div style={{ textAlign: "-webkit-right" }}>
                                    <Flatpickr
                                      style={{ width: "50%" }}
                                      className="form-control"
                                      placeholder="วันที่ค้นหา"
                                      options={{
                                        locale: Thai,
                                        mode: "range",
                                        time_24hr: true,
                                        dateFormat: "d/M/Y",
                                      }}
                                      onClose={e => onChangeTableData(e)}
                                    />
                                  </div>
                                </Col>
                                {rowSelect.length > 0 ? (
                                  <Col sm={4}>
                                    <Button
                                      onClick={() => printInvoice()}
                                      className="mr-1 mb-1"
                                      color="primary"
                                      outline
                                    >
                                      ปริ้นใบสำคัญจ่าย
                                    </Button>
                                    &nbsp;
                                    <ExcelFile
                                      filename={
                                        "สรุปยอด " +
                                        moment()
                                          .add(543, "year")
                                          .format("DD/MM/YYYY")
                                          .toString()
                                      }
                                      element={
                                        <Button
                                          className="mr-1 mb-1"
                                          color="success"
                                          outline
                                        >
                                          ดาวน์โหลด EXCEL
                                        </Button>
                                      }
                                    >
                                      <ExcelSheet
                                        data={dataExcel}
                                        name="Sheet1"
                                      >
                                        <ExcelColumn
                                          label="ลำดับที่"
                                          value="row_no"
                                        />

                                        <ExcelColumn
                                          label="วันที่เอกสาร"
                                          value={col =>
                                            moment(col.date_add).format(
                                              "YYYYMMDD"
                                            )
                                          }
                                        />

                                        <ExcelColumn
                                          label="อ้างอิงถึง"
                                          value="no"
                                        />

                                        <ExcelColumn
                                          label="ผู้รับเงิน/คู่ค้า"
                                          value="contractor_name"
                                        />

                                        <ExcelColumn
                                          label="เลขทะเบียน 13 หลัก"
                                          // value="money"
                                          value=""
                                        />

                                        <ExcelColumn
                                          label="เลขสาขา 5 หลัก"
                                          value=""
                                        />

                                        <ExcelColumn
                                          label="เลขที่ใบกำกับฯ (ถ้ามี)"
                                          value=""
                                        />

                                        <ExcelColumn
                                          label="วันที่ใบกำกับฯ (ถ้ามี)"
                                          value={col =>
                                            moment(col.date_add).format(
                                              "YYYYMMDD"
                                            )
                                          }
                                        />

                                        <ExcelColumn
                                          label="ประเภทราคา"
                                          value=""
                                        />

                                        <ExcelColumn
                                          label="บัญชี"
                                          value="contractor_bank_no"
                                        />

                                        <ExcelColumn
                                          label="คำอธิบาย"
                                          value="comment"
                                        />

                                        <ExcelColumn label="จำนวน" value="1" />

                                        <ExcelColumn
                                          label="ราคาต่อหน่วย"
                                          value={col => {
                                            if (col.vat_type == 1) {
                                              return col.money
                                            } else if (col.vat_type == 2) {
                                              if (col.vat_material_type == 1) {
                                                return col.money
                                              } else {
                                                return col.money
                                              }
                                            } else if (col.vat_type == 3) {
                                              return col.money
                                            } else {
                                              return col.money
                                            }
                                          }}
                                        />

                                        <ExcelColumn
                                          label="อัตราภาษี"
                                          value={col => {
                                            if (col.vat_type == 1) {
                                              return "NO"
                                            } else if (col.vat_type == 2) {
                                              if (col.vat_material_type == 1) {
                                                return "7%"
                                              } else {
                                                return "no"
                                              }
                                            } else if (col.vat_type == 3) {
                                              return "NO"
                                            } else {
                                              return "NO"
                                            }
                                          }}
                                        />

                                        <ExcelColumn
                                          label="หัก ณ ที่จ่าย (ถ้ามี)"
                                          value={col => {
                                            if (col.vat_type == 1) {
                                              return 0
                                            } else if (col.vat_type == 2) {
                                              if (col.vat_material_type == 1) {
                                                return 0
                                              } else {
                                                return 0
                                              }
                                            } else if (col.vat_type == 3) {
                                              return "3%"
                                            } else {
                                              return 0
                                            }
                                          }}
                                        />

                                        <ExcelColumn label="ชำระโดย" value="" />

                                        <ExcelColumn
                                          label="จำนวนเงินที่ชำระ"
                                          value={col => {
                                            if (col.vat_type == 1) {
                                              return col.money
                                            } else if (col.vat_type == 2) {
                                              if (col.vat_material_type == 1) {
                                                return col.money_after
                                              } else {
                                                return col.money
                                              }
                                            } else if (col.vat_type == 3) {
                                              return col.money_after
                                            } else {
                                              return col.money
                                            }
                                          }}
                                        />
                                        <ExcelColumn
                                          label="ภ.ง.ด. (ถ้ามี)"
                                          value=""
                                        />
                                        <ExcelColumn
                                          label="หมายเหตุ"
                                          value="note"
                                        />
                                      </ExcelSheet>
                                    </ExcelFile>
                                  </Col>
                                ) : null}

                                <Col sm={rowSelect.length > 0 ? 8 : 12}>
                                  <div
                                    className="search-box d-inline-block"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered
                                      rowStyle={rowStyleFormat}
                                      classes={"table align-middle"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      selectRow={selectRow}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AuditDashboard.propTypes = {}

export default AuditDashboard
