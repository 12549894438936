import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "../Tables/datatables.scss"

// API store
import {
  getProjects as onGetProjects,
  updateProject as onUpdateProject,
} from "store/projects/actions"

//redux
const Users = () => {
  const dispatch = useDispatch()

  const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }))

  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "name",
      text: "ชื่อ",
      sort: true,
      headerStyle: () => {
        return { width: "100px" }
      },
      formatter: function action(cell, row) {
        return (
          <Link to={`/projects-overview/${row.id}`}>
            <span>{cell}</span>
          </Link>
        )
      },
    },
    {
      dataField: "project_status",
      text: "สถานะปัจจุบัน",
      sort: true,
      headerStyle: () => {
        return { width: "100px" }
      },
      formatter: function action(cell, row) {
        if (cell != undefined) {
          let project_status = JSON.parse(cell)
          // return <span>{project_status[project_status.length - 1]}</span>
          if (project_status[project_status.length - 1] == 1) {
            return <span>Check</span>
          } else if (project_status[project_status.length - 1] == 2) {
            return <span>Info</span>
          } else if (project_status[project_status.length - 1] == 3) {
            return <span>Plan</span>
          } else if (project_status[project_status.length - 1] == 4) {
            return <span>Boq</span>
          }
        }
      },
    },
    {
      dataField: "project_next_status",
      text: "สถานะต่อไป",
      sort: true,
      headerStyle: () => {
        return { width: "100px" }
      },
      formatter: function action(cell, row) {
        if (cell != undefined) {
          let project_status = JSON.parse(cell)
          if (project_status[project_status.length - 1] == 1) {
            return <span>Info</span>
          } else if (project_status[project_status.length - 1] == 2) {
            return <span>Plan</span>
          } else if (project_status[project_status.length - 1] == 3) {
            return <span>Boq</span>
          } else if (project_status[project_status.length - 1] == 4) {
            return <span>Soon</span>
          }
        }
      },
    },

    {
      dataField: "id",
      text: "จัดการ",
      sort: true,
      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return (
          <div>
            <Button
              onClick={() => eventAction(row, "update")}
              color="primary"
              outline
              size="sm"
            >
              <i className="bx bxs-caret-up-circle"></i>
            </Button>
            &nbsp;
            <Button
              onClick={() => eventAction(row, "cancel")}
              color="danger"
              outline
              size="sm"
            >
              <i className="bx bx-x"></i>
            </Button>
          </div>
        )
      },
    },
  ])

  // Table Data
  const [tableData, setTableData] = React.useState(projects) // set campaign as default

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  React.useEffect(() => {
    dispatch(onGetProjects("update", null))
  }, [dispatch])

  function eventAction(row, type) {
    let x = row
    x.type = type
    if (type == "update") {
      x.status = 3
    } else {
      x.status = 4
    }

    dispatch(onUpdateProject(x))
  }

  React.useEffect(() => {
    if (!isEmpty(projects)) {
      let newProject = []
      for (let index = 0; index < projects.length; index++) {
        projects[index].project_next_status = projects[index].project_status
        newProject.push(projects[index])
      }
      setTableData(newProject)
    } else {
      setTableData([])
    }
  }, [projects])

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Project Confirms Tables | {process.env.REACT_APP_NAMECOMPANY}
          </title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Project Confirms </CardTitle>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={tableColumns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={tableColumns}
                        data={tableData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8"></Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered
                                    striped
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

// export default Users
export default withRouter(Users)
