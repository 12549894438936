import {
  GET_EMPLOYMENTCONPROJECTS_SUCCESS,
  GET_EMPLOYMENTCONPROJECTS_FAIL,
  ADD_EMPLOYMENTCONPROJECT_SUCCESS,
  ADD_EMPLOYMENTCONPROJECT_FAIL,
  UPDATE_EMPLOYMENTCONPROJECT_SUCCESS,
  UPDATE_EMPLOYMENTCONPROJECT_FAIL,
  DELETE_EMPLOYMENTCONPROJECT_SUCCESS,
  DELETE_EMPLOYMENTCONPROJECT_FAIL,
  GET_EMPLOYMENTCONPROJECT_PROFILE_SUCCESS,
  GET_EMPLOYMENTCONPROJECT_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  employmentconprojects: [
    {
      employment_contract_1: [],
      employment_contract_2: [],
    },
  ],
  employmentconprojectProfile: {},
  error: {},
}

const employmentconprojects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYMENTCONPROJECTS_SUCCESS:
      return {
        ...state,
        employmentconprojects: action.payload,
      }

    case GET_EMPLOYMENTCONPROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_EMPLOYMENTCONPROJECT_SUCCESS:
      return {
        ...state,
        employmentconprojects: [...state.employmentconprojects, action.payload],
      }

    case ADD_EMPLOYMENTCONPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYMENTCONPROJECT_PROFILE_SUCCESS:
      return {
        ...state,
        employmentconprojectProfile: action.payload,
      }

    case UPDATE_EMPLOYMENTCONPROJECT_SUCCESS:
      return {
        ...state,
        employmentconprojects: state.employmentconprojects.map(employmentconproject =>
          employmentconproject.id.toString() === action.payload.id.toString()
            ? { employmentconproject, ...action.payload }
            : employmentconproject
        ),
      }

    case UPDATE_EMPLOYMENTCONPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_EMPLOYMENTCONPROJECT_SUCCESS:
      return {
        ...state,
        employmentconprojects: state.employmentconprojects.filter(
          employmentconproject =>
            employmentconproject.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_EMPLOYMENTCONPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYMENTCONPROJECT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default employmentconprojects
