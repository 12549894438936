import {
  GET_MATERIAL_STOCKS_SUCCESS,
  GET_MATERIAL_STOCKS_FAIL,
  ADD_MATERIAL_STOCK_SUCCESS,
  ADD_MATERIAL_STOCK_FAIL,
  UPDATE_MATERIAL_STOCK_SUCCESS,
  UPDATE_MATERIAL_STOCK_FAIL,
  DELETE_MATERIAL_STOCK_SUCCESS,
  DELETE_MATERIAL_STOCK_FAIL,
  GET_MATERIAL_STOCK_PROFILE_SUCCESS,
  GET_MATERIAL_STOCK_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  materialStocks: [],
  materialStockProfile: {},
  error: {},
}

const materialStocks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MATERIAL_STOCKS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        materialStocks: action.payload,
      }

    case GET_MATERIAL_STOCKS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_MATERIAL_STOCK_SUCCESS:
      return {
        ...state,
        materialStocks: [...state.materialStocks, action.payload],
      }

    case ADD_MATERIAL_STOCK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MATERIAL_STOCK_PROFILE_SUCCESS:
      // console.log("GET_MATERIAL_STOCK_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        materialStockProfile: action.payload,
      }

    case UPDATE_MATERIAL_STOCK_SUCCESS:
      return {
        ...state,
        materialStocks: state.materialStocks.map(materialstock =>
          materialstock.id.toString() === action.payload.id.toString()
            ? { materialstock, ...action.payload }
            : materialstock
        ),
      }

    case UPDATE_MATERIAL_STOCK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_MATERIAL_STOCK_SUCCESS:
      return {
        ...state,
        materialStocks: state.materialStocks.filter(
          materialstock =>
            materialstock.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_MATERIAL_STOCK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MATERIAL_STOCK_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default materialStocks
