import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_MATERIAL_TYPES,
  GET_MATERIAL_TYPE_PROFILE,
  ADD_NEW_MATERIAL_TYPE,
  DELETE_MATERIAL_TYPE,
  UPDATE_MATERIAL_TYPE,
} from "./actionTypes"

import {
  getMaterialTypesSuccess,
  getMaterialTypesFail,
  getMaterialTypeProfileSuccess,
  getMaterialTypeProfileFail,
  addMaterialTypeFail,
  addMaterialTypeSuccess,
  updateMaterialTypeSuccess,
  updateMaterialTypeFail,
  deleteMaterialTypeSuccess,
  deleteMaterialTypeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMaterialTypes,
  getMaterialTypeProfile,
  addNewMaterialType,
  updateMaterialType,
  deleteMaterialType,
} from "../../helpers/fakebackend_helper"

function* fetchMaterialTypes({ payload: active }) {
  try {
    const response = yield call(getMaterialTypes, active)
    // console.log(active);
    yield put(getMaterialTypesSuccess(response))
  } catch (error) {
    yield put(getMaterialTypesFail(error))
  }
}

function* fetchMaterialTypeProfile({ payload: id }) {
  // console.log(id)updateMaterialType
  try {
    const response = yield call(getMaterialTypeProfile, id)
    // console.log("Profile => ", response)
    yield put(getMaterialTypeProfileSuccess(response))
  } catch (error) {
    yield put(getMaterialTypeProfileFail(error))
  }
}

function* onUpdateMaterialType({ payload: designgroup }) {
  try {
    // let x = designgroup.get('formData')
    // console.log(x)
    const response = yield call(updateMaterialType, designgroup)
    yield put(updateMaterialTypeSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateMaterialTypeFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteMaterialType({ payload: designgroup }) {
  try {
    const response = yield call(deleteMaterialType, designgroup)
    yield put(deleteMaterialTypeSuccess(response))
  } catch (error) {
    yield put(deleteMaterialTypeFail(error))
  }
}

function* onAddNewMaterialType({ payload: designgroup }) {
  try {
    const response = yield call(addNewMaterialType, designgroup)
    yield put(addMaterialTypeSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addMaterialTypeFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* materialType() {
  yield takeEvery(GET_MATERIAL_TYPES, fetchMaterialTypes)
  yield takeEvery(GET_MATERIAL_TYPE_PROFILE, fetchMaterialTypeProfile)
  yield takeEvery(ADD_NEW_MATERIAL_TYPE, onAddNewMaterialType)
  yield takeEvery(UPDATE_MATERIAL_TYPE, onUpdateMaterialType)
  yield takeEvery(DELETE_MATERIAL_TYPE, onDeleteMaterialType)
}

export default materialType
