import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { isEmpty, map, truncate } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import logo_s from "assets/images/logo_s.png"
import { getQuotationProjectProfile as onGetQuotationProjectProfile } from "store/quotations-project/actions"
import THBText from "thai-baht-text" // for ES6
import moment from "moment"
import "moment/locale/th" // without this line it didn't work

import { getQuotationConProjects as onGetgetQuotationConProjects } from "store/quotations-con-project/actions"

import pdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

//ต้องระบุตามชื่อของ ไฟล์ font
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
}

const InvoiceDetail = props => {
  const dispatch = useDispatch()

  const { quotationprojectProfile } = useSelector(state => ({
    quotationprojectProfile: state.quotationprojects.quotationprojectProfile,
  }))
  const { quotationconprojects } = useSelector(state => ({
    quotationconprojects: state.quotationsConProject.quotationconprojects,
  }))

  const {
    match: { params },
  } = props
  const [quotation_lists, setQuotation_lists] = React.useState([])
  const [newQuotation_lists, setNewQuotation_lists] = React.useState([])

  const [quotation_notes, setQuotation_notes] = React.useState([])
  const [quotation_cons, setQuotation_cons] = React.useState([])
  const [newQuotation_cons, setNewQuotation_cons] = React.useState([])
  const [formDataQuo, setFormDataQuo] = React.useState({})
  const [refImg, setRefImg] = React.useState({})

  const [material, setMaterial] = React.useState([])

  const [note, setNote] = React.useState([])
  const [standard, setStandard] = React.useState([])
  const [annotation, setAnnotation] = React.useState([])

  function newDataLists(data) {
    var headers = {
      fila_0: {
        col_1: {
          text: "ลำดับ\nNo",
          style: "tableHeader",
          alignment: "left",
        },
        col_2: {
          text: "รายละเอียด\nDescription",
          style: "tableHeader",
          alignment: "left",
        },
        col_3: {
          text: "หน่วย\nUnit",
          style: "tableHeader",
          alignment: "center",
        },
        col_4: {
          text: "จำนวน\nQta",
          style: "tableHeader",
          alignment: "right",
        },
        col_5: {
          text: "ราคาต่อหน่วย\nQta/Unit",
          style: "tableHeader",
          alignment: "right",
        },
        col_6: {
          text: "ส่วนลด\nDiscount",
          style: "tableHeader",
          alignment: "right",
        },
        col_7: {
          text: "ราคาสุทธิ\nTotal",
          style: "tableHeader",
          alignment: "right",
        },
      },
    }

    var body = []
    for (var key in headers) {
      if (headers.hasOwnProperty(key)) {
        var header = headers[key]
        var row = new Array()
        row.push(header.col_1)
        row.push(header.col_2)
        row.push(header.col_3)
        row.push(header.col_4)
        row.push(header.col_5)
        row.push(header.col_6)
        row.push(header.col_7)
        body.push(row)
      }
    }
    // console.log(data)
    for (let key = 0; key < data.length; key++) {
      var row = new Array()
      row.push(key + 1)
      row.push(data[key].name + data[key].detail)
      row.push({ text: data[key].unit, alignment: "center" })
      row.push({ text: data[key].qta, alignment: "right" })
      row.push({
        text: parseFloat(data[key].price).toLocaleString("th-TH", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        alignment: "right",
      })
      row.push({
        text:
          data[key].price_sum_be - data[key].price_sum > 0
            ? parseFloat(
                data[key].price_sum_be - data[key].price_sum
              ).toLocaleString("th-TH", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : 0,
        alignment: "right",
      })
      row.push({
        text: parseFloat(data[key].price_sum).toLocaleString("th-TH", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        alignment: "right",
      })
      body.push(row)
    }

    if (formDataQuo.vat_type == 1) {
      for (let index = 0; index < 3; index++) {
        var row = new Array()
        row.push({
          text: index == 2 ? "(" + THBText(formDataQuo.price_sum) + ")" : {},
          alignment: "center",
          colSpan: 2,
        })
        row.push({})
        row.push({
          text:
            index == 0
              ? "ราคาเดิมก่อนลด/Amount (บาท/Bath)"
              : index == 1
              ? "ส่วนลดรวม/Discount (บาท/Bath)"
              : index == 2
              ? "ยอดสุทธิ/Total (บาท/Bath)"
              : {},
          colSpan: 4,
          color: index == 1 ? "red" : "black",
          alignment: "right",
        })
        row.push({})
        row.push({})
        row.push({})
        row.push({
          text:
            index == 0
              ? (
                  parseFloat(formDataQuo.price_sum) +
                  parseFloat(formDataQuo.discount)
                ).toLocaleString("th-TH", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : index == 1
              ? parseFloat(formDataQuo.discount).toLocaleString("th-TH", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : index == 2
              ? parseFloat(formDataQuo.price_sum).toLocaleString("th-TH", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : {},
          alignment: "right",
        })
        body.push(row)
      }
    } else if (formDataQuo.vat_type == 2) {
      for (let index = 0; index < 5; index++) {
        var row = new Array()
        row.push({
          text:
            index == 4
              ? "(" +
                THBText(
                  Number(formDataQuo.price_sum) +
                    (Number(formDataQuo.price_sum) / 100) * 7
                ) +
                ")"
              : {},
          alignment: "center",
          colSpan: 2,
        })
        row.push({})
        row.push({
          text:
            index == 0
              ? "ราคาเดิมก่อนลด/Amount (บาท/Bath)"
              : index == 1
              ? "ส่วนลดรวม/Discount (บาท/Bath)"
              : index == 2
              ? "vat 7 %"
              : index == 3
              ? "ก่อน vat / before vat(บาท/Bath)"
              : index == 4
              ? "ยอดสุทธิ/Total  (บาท/Bath)"
              : {},

          colSpan: 4,
          color: index == 1 ? "red" : "black",
          alignment: "right",
        })
        row.push({})
        row.push({})
        row.push({})
        row.push({
          text:
            index == 0
              ? (
                  parseFloat(formDataQuo.price_sum) +
                  parseFloat(formDataQuo.discount)
                ).toLocaleString("th-TH", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : index == 1
              ? parseFloat(formDataQuo.discount).toLocaleString("th-TH", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : index == 2
              ? ((Number(formDataQuo.price_sum) / 100) * 7).toLocaleString(
                  "th-TH",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )
              : index == 3
              ? parseFloat(Number(formDataQuo.price_sum)).toLocaleString(
                  "th-TH",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )
              : index == 4
              ? parseFloat(
                  Number(formDataQuo.price_sum) +
                    (Number(formDataQuo.price_sum) / 100) * 7
                ).toLocaleString("th-TH", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : {},
          alignment: "right",
        })
        body.push(row)
      }
    }

    setNewQuotation_lists(body)
  }

  function newDataCon(data) {
    var body = []

    for (let key = 0; key < data.length; key++) {
      var row = new Array()

      row.push({ text: "งวดที่ " + (key + 1) + ". " + data[key].name })

      row.push({
        text:
          "จ่าย " +
          parseFloat(data[key].value).toLocaleString("th-TH", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
          "%",
        alignment: "right",
      })
      if (formDataQuo.vat_type == 1) {
        if (Number(quotation_cons.length) - 1 == key) {
          row.push({
            text:
              "ยอด " +
              parseFloat(
                parseFloat(data[key].sum_all) -
                  parseFloat(formDataQuo.input_deposit_value)
              ).toLocaleString("th-TH", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " บาท",
            alignment: "right",
          })
        } else {
          row.push({
            text:
              "ยอด " +
              parseFloat(data[key].sum_all).toLocaleString("th-TH", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " บาท",
            alignment: "right",
          })
        }
      } else {
        if (Number(quotation_cons.length) - 1 == key) {
          row.push({
            text:
              "ยอด " +
              parseFloat(
                parseFloat(
                  parseFloat(data[key].sum_all) +
                    (parseFloat(data[key].sum_all) / 100) * 7
                ) - parseFloat(formDataQuo.input_deposit_value)
              ).toLocaleString("th-TH", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " บาท",
            alignment: "right",
          })
        } else {
          row.push({
            text:
              "ยอด " +
              parseFloat(
                parseFloat(
                  parseFloat(data[key].sum_all) +
                    (parseFloat(data[key].sum_all) / 100) * 7
                )
              ).toLocaleString("th-TH", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " บาท",
            alignment: "right",
          })
        }
      }

      row.push({
        text:
          quotation_cons.length - 1 == key
            ? " ( หักค่ามัดจำ " +
              parseFloat(formDataQuo.input_deposit_value).toLocaleString(
                "th-TH",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              ) +
              " บ. แล้ว )"
            : "",
        color: quotation_cons.length - 1 == key ? "red" : "",
      })

      body.push(row)
    }

    setNewQuotation_cons(body)
  }

  React.useEffect(() => {
    if (!isEmpty(quotationconprojects)) {
      let { material, note, standard, annotation, ...rest } =
        quotationconprojects
      // console.log('quotationconprojects',quotationconprojects)
      // console.log("material", material)
      // console.log("note", note)
      // console.log("standard", standard)
      if (!isEmpty(material)) {
        setMaterial(material)
      }
      if (!isEmpty(note)) {
        setNote(note)
      }

      if (!isEmpty(standard)) {
        setStandard(standard)
      }
      if (!isEmpty(annotation)) {
        setAnnotation(annotation)
      }

      // setNote(note)
      // setStandard(standard)
    }
    return () => {}
  }, [quotationconprojects]) // Only re-run the effect if count changes

  async function printInvoice() {
    var docDefinition = {
      // watermark: {
      //   text: process.env.REACT_APP_NAMECOMPANY,
      //   color: "#e6e6e6",
      //   opacity: 0.5,
      // },
      content: [
        //page 1
        {
          columns: [
            {
              style: "header",
              alignment: "left",
              width: "50%",
              text: "ติดต่อ:   Line @ : @pmh-m   Tel (รุจ) : 062 640 5432	",
            },
            {
              alignment: "right",
              width: "50%",
              text: "1/3",
            },
          ],
        },
        {
          columns: [
            {
              style: "header",
              alignment: "left",
              width: "80%",
              text: [
                "(ต้นฉบับ / original)\n",
                { text: "ใบเสนอราคา Quotation\n", fontSize: 18, bold: true },
                "เลขที่: " +
                  formDataQuo.code +
                  formDataQuo.year +
                  formDataQuo.month +
                  formDataQuo.day +
                  formDataQuo.term +
                  " วันที่: " +
                  moment(formDataQuo.quo_date).add(543, "year").format("LL"),
              ],
            },

            {
              image: await getBase64ImageFromURL(logo_s),
              width: 40,
              height: 50,
            },
          ],
        },

        {
          margin: [0, 0, 0, 20],
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["50%", "50%"],
            body: [
              [
                {
                  border: [false, false, true, false],
                  text: [
                    "ชื่อผู้ขาย: ",
                    {
                      text: "บจก. พรีเมียม เฮ้าส์ โมดูลาร์\n",
                      fontSize: 13,
                      bold: true,
                    },
                    "ที่อยู่: 60/1 หมู่ที่ 1 ต.คลองโยง อ.พุทธมณฑล จ.นครปฐม 73170",
                  ],
                },
                {
                  border: [false, false, false, false],

                  text: [
                    "ชื่อผู้ซื้อ: ",
                    {
                      border: [false, false, false, false],
                      text: formDataQuo.name,
                      fontSize: 13,
                      bold: true,
                    },
                    " เบอร์: ",
                    {
                      text: formDataQuo.tel,
                      fontSize: 13,
                      bold: true,
                    },

                    "\nที่อยู่: " + formDataQuo.address + "\n",
                    "สถานที่สร้าง: " + formDataQuo.note,
                  ],
                },
              ],
            ],
          },
          layout: {
            defaultBorder: false,
          },
        },

        {
          table: {
            heights: function (row) {
              return row == 1 ? (quotation_lists == 1 ? 250 : null) : null
            },
            style: "tableExample",
            widths: ["auto", "*", "auto", "auto", "auto", "auto", "auto"],
            headerRows: 1,
            body: newQuotation_lists,
          },
        },
        {
          margin: [0, 20, 0, 0],
          // ul: ["line widths", "line colors", "cell paddings"],
          ul: annotation.map((v, k) => {
            return [v.name]
          }),
        },

        {
          text: [
            {
              text: "เงื่อนไขการชำระเงิน\n",
              color: "blue",
              style: "header",
            },
            {
              text: [
                "เลขบัญชี: ",
                {
                  text: formDataQuo.book_bank,
                  fontSize: 13,
                  bold: true,
                },
                " ชื่อบัญชี: ",
                {
                  text: formDataQuo.name_bank_ower,
                  fontSize: 13,
                  bold: true,
                },
                " ชื่อธนาคาร: ",
                {
                  text: formDataQuo.name_bank,
                  fontSize: 13,
                  bold: true,
                },
                {
                  text: "\nจองคิวเพียง 10,000 บาท เผื่อออกแบบสถาปัตย์ และ 3D หรือสำรวจหน้างานในระยะไม่เกิน 80 กิโลเมตร",
                  fontSize: 13,
                  decoration: "underline",
                  decorationStyle: "solid",
                  decorationColor: "black",
                },
              ],
            },
          ],
          margin: [0, 20, 0, 0],
        },
        {
          style: "tableExample",
          table: {
            dontBreakRows: true,
            body: newQuotation_cons,
          },
          layout: "noBorders",
        },

        //page 2
        {
          pageBreak: "before",
          columns: [
            {
              alignment: "right",
              text: "2/3",
            },
          ],
        },
        refImg.length > 0
          ? {
              image: refImg,
              fit: [380, 380],
              alignment: "center",
              margin: [0, 0, 0, 20],
            }
          : {},

        quotation_notes.length > 0
          ? {
              text: "รายละเอียด (เพิ่มเติม)",
              color: "blue",
              style: "header",
            }
          : {},
        quotation_notes.length > 0
          ? {
              style: "tableExample",
              table: {
                dontBreakRows: true,
                body: quotation_notes.map((v, k) => {
                  return [{ text: k + 1 + ". " + v.name }]
                }),
              },
              layout: "noBorders",
            }
          : {},
        {
          text: "วัสดุพื้นฐาน รายละเอียดวัสดุที่ใช้ในการดำเนินการโดยประมาณ",
          color: "blue",
          style: "header",
        },
        {
          // ul: ["line widths", "line colors", "cell paddings"],
          ul: material.map((v, k) => {
            return [v.name]
          }),
        },
        //page 3
        {
          pageBreak: "before",
          columns: [
            {
              alignment: "right",
              text: "3/3",
            },
          ],
        },
        {
          text: "กรณีมีห้องน้ำ (ขนาดโดยประมาณ 1.2*3.0 เมตร จัดอยู่ภายในตัวบ้าน - แบ่งโซนเปียกแห้ง)",
          color: "blue",
          style: "header",
        },
        {
          // ul: ["line widths", "line colors", "cell paddings"],
          ul: note.map((v, k) => {
            return [v.name]
          }),
        },

        {
          text: "มาตรฐานในการก่อสร้าง",
          color: "blue",
          style: "header",
        },
        {
          // ul: ["line widths", "line colors", "cell paddings"],
          ul: standard.map((v, k) => {
            return [v.name]
          }),
        },

        {
          margin: [0, 50, 0, 0],
          alignment: "center",
          style: "header",

          columns: [
            {
              width: "50%",
              text:
                "( ............................................ )\n" +
                formDataQuo.sign_ower +
                " (ผู้ซื้อ)",
            },
            {
              width: "50%",
              text:
                "( ............................................ )\n" +
                "บจก. พรีเมียม เฮ้าส์ โมดูลาร์ โดย\n" +
                formDataQuo.sign_manager +
                "\n(ผู้จัดการ)",
            },
          ],
        },
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        subheader: {
          fontSize: 13,
          decoration: "underline",
          decorationStyle: "solid",
          decorationColor: "black",
        },
        tableExample: {
          fontSize: 13,
        },
        tableHeader: {
          bold: true,
          fontSize: 14,
          color: "black",
        },
      },
      defaultStyle: {
        font: "THSarabunNew",
      },
      pageSize: "A4",
    }
    // pdfMake.createPdf(docDefinition).open()
    let quo_name = "ใบเสนอราคา คุณ" + formDataQuo.name + ".pdf"
    // console.log(quo_name)
    pdfMake.createPdf(docDefinition).download(quo_name)
  }

  React.useEffect(() => {
    // console.log(quotationprojectProfile)
    if (params && params.id) {
      dispatch(onGetQuotationProjectProfile(params.id))
    }
  }, [params])

  function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest()
    xhr.onload = function () {
      var reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open("GET", url)
    xhr.responseType = "blob"
    xhr.send()
  }

  function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image()
      img.setAttribute("crossOrigin", "anonymous")

      img.onload = () => {
        var canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height

        var ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0)

        var dataURL = canvas.toDataURL("image/png")

        resolve(dataURL)
      }

      img.onerror = error => {
        reject(error)
      }

      img.src = url
    })
  }

  React.useEffect(async () => {
    // console.log(quotationprojectProfile)
    if (!isEmpty(quotationprojectProfile)) {
      setQuotation_cons(quotationprojectProfile.quotation_cons)
      setQuotation_lists(quotationprojectProfile.quotation_lists)
      setQuotation_notes(quotationprojectProfile.quotation_notes)
      setFormDataQuo(quotationprojectProfile.formData)
      newDataLists(quotationprojectProfile.quotation_lists)
      newDataCon(quotationprojectProfile.quotation_cons)
      if (quotationprojectProfile.formData != undefined) {
        dispatch(onGetgetQuotationConProjects(formDataQuo.project_id))
        if (quotationprojectProfile.formData.img_del != 0) {
          if (quotationprojectProfile.formData.img_path != undefined) {
            toDataUrl(
              process.env.REACT_APP_SERVER +
                quotationprojectProfile.formData.img_path,
              function (myBase64) {
                // console.log(myBase64) // myBase64 is the base64 string
                setRefImg(myBase64)
              }
            )
          }
        }
      }
    } else {
    }
  }, [quotationprojectProfile])

  return (
    <React.Fragment>
      <style scoped>
        {
          "\
          .table-hide-border{\
            border-style: hidden;\
            border-right-style: hidden;\
          }\
          .table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {\
            border: 1px solid black;\
          }\
          .table > :not(:first-child){\
            border: 1px solid black;\
           }\
          tbody tr{\
            cursor:initial;\
          }\
          .hidden {\
            overflow: hidden;\
          }\
          .td-note {\
            display: inline-block;\
            margin-bottom:1px;\
            padding-bottom:1px;\
          }\
          .hidden td {\
            display: inline-block;\
            border-style: hidden\
          }\
          @media print {\
            .pagebreak { page-break-before: always; }\
          }\
          "
        }
      </style>

      <div className="page-content">
        <MetaTags>
          <title>Quotation Detail | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>

        <Container fluid style={{ fontFamily: "sans-serif" }}>
          {/* Render Breadcrumbs */}
          {!isEmpty(quotationprojectProfile) && (
            <Row>
              <Col lg="12">
                {/* header */}
                <div className="d-print-none">
                  <div style={{ textAlignLast: "right" }}>
                    <Link
                      to="#"
                      onClick={printInvoice}
                      className="btn btn-success me-2"
                    >
                      <i className="fa fa-print" />
                    </Link>

                    <Link
                      to={`/projects-overview/${formDataQuo.project_id}`}
                      className="btn btn-secondary"
                      onClick={() => {
                        localStorage.setItem("model_quo", true)
                      }}
                    >
                      Back
                    </Link>
                  </div>
                  <hr />
                </div>
                {/* page 1 */}
                <Card className="pagebreak" style={{ boxShadow: "none" }}>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr>
                            <th
                              className="table-hide-border"
                              style={{ width: "50%" }}
                            >
                              ติดต่อ: &nbsp; Line @ : @pmh-m &nbsp; Tel (รุจ) :
                              062 640 5432
                            </th>
                            <th
                              className="table-hide-border"
                              style={{
                                textAlign: "right",
                                width: "50%",
                              }}
                            >
                              1/3
                            </th>
                          </tr>

                          <tr>
                            <th className="table-hide-border">
                              <p
                                className="font-size-13"
                                style={{
                                  textAlign: "left",
                                  fontWeight: "normal",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                ( ต้นฉบับ / original )
                              </p>
                              <p
                                className="font-size-20"
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                ใบเสนอราคา Quotation
                              </p>

                              <p
                                className="font-size-14"
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                เล่มที่:&nbsp;
                                {formDataQuo.code +
                                  formDataQuo.year +
                                  formDataQuo.month +
                                  formDataQuo.day +
                                  formDataQuo.term}
                                &nbsp;วันที่:&nbsp;
                                {moment(formDataQuo.quo_date)
                                  .add(543, "year")
                                  .format("LL")}
                              </p>
                            </th>
                            <th
                              className="table-hide-border"
                              style={{
                                textAlign: "right",
                              }}
                            >
                              <img
                                style={{ marginTop: "5px" }}
                                src={logo_s}
                                alt={process.env.REACT_APP_NAMECOMPANY}
                                height="80"
                              />
                            </th>
                          </tr>
                        </thead>
                      </Table>
                    </div>

                    <div className="table-responsive">
                      <Table className="table-nowarp" bordered>
                        <tbody>
                          <tr>
                            <th
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                width: "50%",
                                border: "1px solid white",
                                borderRight: "1px solid",
                              }}
                            >
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                ชื่อผู้ขาย:&nbsp;
                                <span
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  บจก. พรีเมียม เฮ้าส์ โมดูลาร์
                                </span>
                              </span>
                            </th>

                            <th
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                paddingLeft: "5px",
                                width: "50%",
                                border: "1px solid white",
                              }}
                            >
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                ชื่อผู้ซื้อ:&nbsp;
                                <span
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {formDataQuo.name}
                                </span>
                              </span>
                              <br />
                              {/* &emsp; &emsp; &emsp; */}
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                เบอร์:&nbsp;
                                {formDataQuo.tel}
                              </span>
                            </th>
                          </tr>

                          <tr>
                            <th
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                width: "50%",
                                border: "1px solid white",
                                borderRight: "1px solid",
                              }}
                            >
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                ที่อยู่:&nbsp;60/1 หมู่ที่ 1 ต.คลองโยง
                                อ.พุทธมณฑล จ.นครปฐม 731070
                              </span>
                            </th>

                            <th
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                paddingLeft: "5px",
                                width: "50%",
                                border: "1px solid white",
                              }}
                            >
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                ที่อยู่:&nbsp;
                                {formDataQuo.address}
                              </span>
                            </th>
                          </tr>

                          <tr>
                            <th
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                width: "50%",
                                border: "1px solid white",
                                borderRight: "1px solid",
                              }}
                            ></th>

                            <th
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                paddingLeft: "5px",
                                width: "50%",
                                border: "1px solid white",
                              }}
                            >
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                สถานที่สร้าง:&nbsp;
                                {formDataQuo.note}
                              </span>
                            </th>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <br />

                    <div className="table-responsive">
                      <Table className="table-nowarp" bordered>
                        <thead>
                          <tr>
                            <td width="70px">
                              ลำดับ
                              <br />
                              <span style={{ fontSize: "10px" }}>No</span>
                            </td>
                            <td>
                              รายละเอียด
                              <br />
                              <span style={{ fontSize: "10px" }}>
                                Description
                              </span>
                            </td>
                            <td width="30px">
                              หน่วย
                              <br />
                              <span style={{ fontSize: "10px" }}>Unit</span>
                            </td>

                            <td
                              width="50px"
                              style={{
                                textAlign: "right",
                              }}
                            >
                              จำนวน
                              <br />
                              <span style={{ fontSize: "10px" }}>Quantity</span>
                            </td>
                            <td
                              width="70px"
                              style={{
                                textAlign: "right",
                              }}
                            >
                              หน่วยละ
                              <br />
                              <span style={{ fontSize: "10px" }}>Qta/Unit</span>
                            </td>
                            <td
                              width="50px"
                              style={{
                                textAlign: "right",
                              }}
                            >
                              ส่วนลด
                              <br />
                              <span style={{ fontSize: "10px" }}>Discount</span>
                            </td>
                            <td
                              width="80px"
                              style={{
                                textAlign: "right",
                              }}
                            >
                              ราคาสุทธิ
                              <br />
                              <span style={{ fontSize: "10px" }}>Total</span>
                            </td>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            border: "1px",
                          }}
                        >
                          {quotation_lists.map((item, index) => (
                            <tr
                              key={index}
                              style={{
                                height:
                                  quotation_lists.length == 1 ? "100px" : null,
                              }}
                            >
                              <th
                                scope="row"
                                style={{
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                  borderBottom:
                                    quotation_lists.length == index + 1
                                      ? null
                                      : "1px solid white",
                                }}
                              >
                                {index + 1}
                              </th>
                              <td
                                style={{
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                  borderBottom:
                                    quotation_lists.length == index + 1
                                      ? null
                                      : "1px solid white",
                                }}
                              >
                                {item.name}&nbsp;{item.detail}
                              </td>
                              <td
                                style={{
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                  borderBottom:
                                    quotation_lists.length == index + 1
                                      ? null
                                      : "1px solid white",
                                }}
                              >
                                {item.unit}
                              </td>
                              <td
                                style={{
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                  borderBottom:
                                    quotation_lists.length == index + 1
                                      ? null
                                      : "1px solid white",

                                  textAlign: "right",
                                }}
                              >
                                {item.qta}
                              </td>
                              <td
                                style={{
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                  borderBottom:
                                    quotation_lists.length == index + 1
                                      ? null
                                      : "1px solid white",

                                  textAlign: "right",
                                }}
                              >
                                {parseFloat(item.price).toLocaleString(
                                  "th-TH",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                              <td
                                style={{
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                  borderBottom:
                                    quotation_lists.length == index + 1
                                      ? null
                                      : "1px solid white",

                                  textAlign: "right",
                                }}
                              >
                                {item.price_sum_be - item.price_sum > 0
                                  ? parseFloat(
                                      item.price_sum_be - item.price_sum
                                    ).toLocaleString("th-TH", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : 0}
                              </td>
                              <td
                                style={{
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                  borderBottom:
                                    quotation_lists.length == index + 1
                                      ? null
                                      : "1px solid white",

                                  textAlign: "right",
                                }}
                              >
                                {parseFloat(item.price_sum).toLocaleString(
                                  "th-TH",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td className="table-hide-border" colSpan={2}></td>

                            <td
                              colSpan={4}
                              style={{
                                verticalAlign: "bottom",
                                textAlign: "right",
                                paddingTop: "3px",
                                paddingBottom: "3px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                ราคาเดิมก่อนลด/Amount &nbsp;(บาท/Bath)
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: "bottom",
                                textAlign: "right",
                                paddingTop: "3px",
                                paddingBottom: "3px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                {(
                                  parseFloat(formDataQuo.price_sum) +
                                  parseFloat(formDataQuo.discount)
                                ).toLocaleString("th-TH", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td className="table-hide-border" colSpan={2}></td>

                            <td
                              colSpan={4}
                              style={{
                                verticalAlign: "bottom",
                                textAlign: "right",
                                paddingTop: "3px",
                                paddingBottom: "3px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  color: "red",
                                }}
                              >
                                ส่วนลดรวม/Discount &nbsp;(บาท/Bath)
                              </p>
                            </td>
                            <td
                              style={{
                                verticalAlign: "bottom",
                                textAlign: "right",
                                paddingTop: "3px",
                                paddingBottom: "3px",
                                color: "red",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                {parseFloat(
                                  formDataQuo.discount
                                ).toLocaleString("th-TH", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </td>
                          </tr>
                          {formDataQuo.vat_type == 1 ? (
                            <tr>
                              <td
                                colSpan={2}
                                className="table-hide-border"
                                style={{
                                  verticalAlign: "bottom",
                                  textAlign: "center",
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                }}
                              >
                                (&nbsp;{THBText(formDataQuo.price_sum)}&nbsp;)
                              </td>

                              <td
                                colSpan={4}
                                style={{
                                  verticalAlign: "bottom",
                                  textAlign: "right",
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                }}
                              >
                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                  }}
                                >
                                  ยอดสุทธิ/Total &nbsp;(บาท/Bath)
                                </p>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "bottom",
                                  textAlign: "right",
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                }}
                              >
                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                  }}
                                >
                                  {parseFloat(
                                    formDataQuo.price_sum
                                  ).toLocaleString("th-TH", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </p>
                              </td>
                            </tr>
                          ) : (
                            <>
                              <tr>
                                <td
                                  colSpan={2}
                                  className="table-hide-border"
                                  style={{
                                    verticalAlign: "bottom",
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  {/* {THBText(
                                    Number(formDataQuo.price_sum) +
                                      (Number(formDataQuo.price_sum) / 100) * 7
                                  )} */}
                                </td>

                                <td
                                  colSpan={4}
                                  style={{
                                    verticalAlign: "bottom",
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    vat 7 %
                                  </p>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "bottom",
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    {(
                                      (Number(formDataQuo.price_sum) / 100) *
                                      7
                                    ).toLocaleString("th-TH", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={2}
                                  className="table-hide-border"
                                  style={{
                                    verticalAlign: "bottom",
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  {/* {THBText(
                                    Number(formDataQuo.price_sum) +
                                      (Number(formDataQuo.price_sum) / 100) * 7
                                  )} */}
                                </td>

                                <td
                                  colSpan={4}
                                  style={{
                                    verticalAlign: "bottom",
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    ก่อน vat / before vat&nbsp;(บาท/Bath)
                                  </p>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "bottom",
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    {parseFloat(
                                      Number(formDataQuo.price_sum)
                                    ).toLocaleString("th-TH", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className="table-hide-border"
                                  colSpan={2}
                                  style={{
                                    verticalAlign: "bottom",
                                    textAlign: "center",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  (&nbsp;
                                  {THBText(
                                    Number(formDataQuo.price_sum) +
                                      (Number(formDataQuo.price_sum) / 100) * 7
                                  )}
                                  &nbsp; )
                                </td>

                                <td
                                  colSpan={4}
                                  style={{
                                    verticalAlign: "bottom",
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    ยอดสุทธิ/Total &nbsp;(บาท/Bath)
                                  </p>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "bottom",
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    {parseFloat(
                                      Number(formDataQuo.price_sum) +
                                        (Number(formDataQuo.price_sum) / 100) *
                                          7
                                    ).toLocaleString("th-TH", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </p>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>

                    <div
                      className="table-responsive"
                      style={{
                        paddingBottom: "5px",
                        borderBottom: "1px solid",
                      }}
                    >
                      <Table
                        className="table-nowarp text-center"
                        style={{
                          margin: "0px",
                        }}
                      >
                        <thead>
                          {annotation.map((f, k) => (
                            <tr className="table-hide-border" key={k}>
                              <td
                                style={{
                                  textAlign: "left",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                <p
                                  className="font-size-13"
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {f.name}
                                </p>
                              </td>
                            </tr>
                          ))}
                        </thead>
                      </Table>
                    </div>
                    <br />
                    <div className="table-responsive">
                      <p
                        className="font-size-16"
                        style={{
                          fontWeight: "bold",
                          color: "blue",
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        เงื่อนไขการชำระเงิน
                      </p>

                      <Table
                        className="table-nowarp text-center"
                        style={{
                          margin: "0px",
                        }}
                      >
                        <thead>
                          <tr className="table-hide-border">
                            <td
                              style={{
                                textAlign: "left",
                                fontWeight: "bold",
                                paddingBottom: "0px",
                              }}
                            >
                              เลขบัญชี: {formDataQuo.book_bank} &emsp;&emsp;
                              ชื่อบัญชี: {formDataQuo.name_bank_ower}{" "}
                              &emsp;&emsp; ชื่อธนาคาร​: {formDataQuo.name_bank}
                            </td>
                          </tr>

                          <tr className="table-hide-border">
                            <td
                              style={{
                                textAlign: "left",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                className="font-size-13"
                                style={{
                                  marginTop: "0px",
                                  textDecoration: "underline",
                                }}
                              >
                                จองคิวเพียง 10,000 บาท เผื่อออกแบบสถาปัตย์ และ
                                3D หรือสำรวจหน้างานในระยะไม่เกิน 80 กิโลเมตร
                              </p>
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </div>

                    <div className="table-responsive">
                      <Table className="table-nowarp">
                        <tbody>
                          {quotation_cons.map((item, index) => (
                            <tr key={index} className="table-hide-border">
                              <td
                                style={{
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                <span
                                  style={{
                                    width: "350px",
                                    display: "inline-block",
                                  }}
                                >
                                  งวดที่ {index + 1}.&nbsp;{item.name}
                                  &emsp;&emsp;
                                </span>
                                <span
                                  style={{
                                    width: "100px",
                                    display: "inline-block",
                                  }}
                                >
                                  จ่าย&nbsp;
                                  {parseFloat(item.value).toLocaleString(
                                    "th-TH",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                  &nbsp;% &emsp;&emsp;
                                </span>
                                <span>ยอด&nbsp;</span>

                                <span
                                  style={{
                                    textAlign: "right",
                                    width: "70px",
                                    display: "inline-block",
                                  }}
                                >
                                  {formDataQuo.vat_type == 1
                                    ? quotation_cons.length - 1 == index
                                      ? parseFloat(
                                          item.sum_all -
                                            formDataQuo.input_deposit_value
                                        ).toLocaleString("th-TH", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : parseFloat(item.sum_all).toLocaleString(
                                          "th-TH",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )
                                    : quotation_cons.length - 1 == index
                                    ? parseFloat(
                                        parseFloat(
                                          parseFloat(item.sum_all) +
                                            (parseFloat(item.sum_all) / 100) * 7
                                        ) - formDataQuo.input_deposit_value
                                      ).toLocaleString("th-TH", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : parseFloat(
                                        parseFloat(
                                          parseFloat(item.sum_all) +
                                            (parseFloat(item.sum_all) / 100) * 7
                                        )
                                      ).toLocaleString("th-TH", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </span>
                                <span>&nbsp;บาท&nbsp;</span>
                                <span
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  {quotation_cons.length - 1 == index
                                    ? " ( หักค่ามัดจำ " +
                                      parseFloat(
                                        formDataQuo.input_deposit_value
                                      ).toLocaleString("th-TH") +
                                      " บ. แล้ว )"
                                    : null}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>

                <Card className="pagebreak" style={{ boxShadow: "none" }}>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr>
                            <th className="table-hide-border"></th>
                            <th
                              className="table-hide-border"
                              style={{
                                textAlign: "right",
                              }}
                            >
                              2/3
                            </th>
                          </tr>
                          <tr>
                            <th
                              className="table-hide-border"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {formDataQuo.img_del ==
                              0 ? null : formDataQuo.img_path != undefined ? (
                                <img
                                  id="imageid"
                                  src={
                                    process.env.REACT_APP_SERVER +
                                    formDataQuo.img_path
                                  }
                                  alt={process.env.REACT_APP_NAMECOMPANY}
                                  style={{
                                    objectFit: "contain",
                                    // object-fit: contain;
                                    maxWidth: "100%",
                                    maxHeight: "380px",
                                    width: "auto",
                                    height: "auto",
                                  }}
                                />
                              ) : null}
                            </th>
                          </tr>
                        </thead>
                      </Table>
                    </div>

                    {quotation_notes.length > 0 ? (
                      <div className="table-responsive hidden">
                        <p
                          className="font-size-16"
                          style={{
                            fontWeight: "bold",
                            color: "blue",
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                        >
                          รายละเอียด (เพิ่มเติม)
                        </p>
                        <Table className="table-nowarp">
                          <tbody>
                            <tr className="table-hide-border">
                              {quotation_notes.map((item, index) => (
                                <td
                                  key={index}
                                  className="td-note"
                                  style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                  }}
                                >
                                  {index + 1}.&nbsp;{item.name}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    ) : null}

                    <div className="table-responsive hidden">
                      <p
                        className="font-size-13"
                        style={{
                          fontWeight: "bold",
                          marginTop: "0px",
                          color: "blue",
                          marginBottom: "0px",
                        }}
                      >
                        วัสดุพื้นฐาน
                        รายละเอียดวัสดุที่ใช้ในการดำเนินการโดยประมาณ
                      </p>
                      <Table className="table-nowarp">
                        <tbody>
                          {material.map((f, k) => (
                            <tr key={k} className="table-hide-border">
                              <td
                                style={{
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                - {f.name}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>

                <Card className="pagebreak" style={{ boxShadow: "none" }}>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr>
                            <th className="table-hide-border"></th>
                            <th
                              className="table-hide-border"
                              style={{
                                textAlign: "right",
                              }}
                            >
                              3/3
                            </th>
                          </tr>
                        </thead>
                      </Table>
                    </div>

                    <div className="table-responsive hidden">
                      <p
                        className="font-size-13"
                        style={{
                          fontWeight: "bold",
                          marginTop: "0px",
                          color: "blue",
                          marginBottom: "0px",
                        }}
                      >
                        กรณีมีห้องน้ำ (ขนาดโดยประมาณ 1.2*3.0 เมตร
                        จัดอยู่ภายในตัวบ้าน - แบ่งโซนเปียกแห้ง)
                      </p>
                      <Table className="table-nowarp">
                        <tbody>
                          {note.map((f, k) => (
                            <tr key={k} className="table-hide-border">
                              <td
                                style={{
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                - {f.name}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div className="table-responsive hidden">
                      <p
                        className="font-size-13"
                        style={{
                          fontWeight: "bold",
                          marginTop: "0px",
                          color: "blue",
                          marginBottom: "0px",
                        }}
                      >
                        มาตรฐานในการก่อสร้าง
                      </p>
                      <Table className="table-nowarp">
                        <tbody>
                          {standard.map((f, k) => (
                            <tr key={k} className="table-hide-border">
                              <td
                                style={{
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                - {f.name}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div className="table-responsive">
                      <Table
                        className="table-nowarp text-center"
                        style={{
                          margin: "0px",
                        }}
                      >
                        <thead>
                          <tr className="table-hide-border">
                            <td
                              style={{
                                textAlign: "left",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                className="font-size-13"
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                }}
                              >
                                หมายเหตุ:
                                ขอสงวนสิทธิ์ดำเนินการคืนเงินมัดจำเนื่องจาก
                                จำนวนนี้เป็นค่าดำเนินการ ค่าออกแบบ
                                ค่าสำรวจหน้างาน คอมมิตชั่นและอื่นๆ
                              </p>
                            </td>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                    <br />
                    <br />
                    <div className="table-responsive">
                      <Table className="table-nowarp text-center">
                        <thead>
                          <tr className="table-hide-border">
                            <th>
                              <p
                                style={{
                                  fontWeight: "nomal",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                ( ............................................ )
                              </p>
                              <p
                                style={{
                                  fontWeight: "nomal",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                {formDataQuo.sign_ower} &nbsp; (ผู้ซื้อ)
                              </p>
                            </th>
                            <th>
                              <p
                                style={{
                                  fontWeight: "nomal",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                ( ............................................ )
                              </p>
                              <p
                                style={{
                                  fontWeight: "nomal",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                บจก. พรีเมียม เฮ้าส์ โมดูลาร์ โดย
                                <br />
                                {formDataQuo.sign_manager} &nbsp; (ผู้จัดการ)
                              </p>
                            </th>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </CardBody>
                </Card>

                <hr />
                <div className="d-print-none">
                  <div style={{ textAlignLast: "right" }}>
                    <Link
                      to="#"
                      onClick={printInvoice}
                      className="btn btn-success me-2"
                    >
                      <i className="fa fa-print" />
                    </Link>

                    <Link
                      to={`/projects-overview/${formDataQuo.project_id}`}
                      className="btn btn-secondary"
                      onClick={() => {
                        localStorage.setItem("model_quo", true)
                      }}
                    >
                      Back
                    </Link>
                  </div>
                </div>
                <br />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoiceDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceDetail)
