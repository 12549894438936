/* MATERIAL_TYPES */
export const GET_MATERIAL_TYPES = "GET_MATERIAL_TYPES"
export const GET_MATERIAL_TYPES_SUCCESS = "GET_MATERIAL_TYPES_SUCCESS"
export const GET_MATERIAL_TYPES_FAIL = "GET_MATERIAL_TYPES_FAIL"

/* MATERIAL_TYPES PROFILE */
export const GET_MATERIAL_TYPE_PROFILE = "GET_MATERIAL_TYPE_PROFILE"
export const GET_MATERIAL_TYPE_PROFILE_SUCCESS = "GET_MATERIAL_TYPE_PROFILE_SUCCESS"
export const GET_MATERIAL_TYPE_PROFILE_FAIL = "GET_MATERIAL_TYPE_PROFILE_FAIL"

/**
 * add designgroup
 */
export const ADD_NEW_MATERIAL_TYPE = "ADD_NEW_MATERIAL_TYPE"
export const ADD_MATERIAL_TYPE_SUCCESS = "ADD_MATERIAL_TYPE_SUCCESS"
export const ADD_MATERIAL_TYPE_FAIL = "ADD_MATERIAL_TYPE_FAIL"

/**
 * Edit designgroup
 */
export const UPDATE_MATERIAL_TYPE = "UPDATE_MATERIAL_TYPE"
export const UPDATE_MATERIAL_TYPE_SUCCESS = "UPDATE_MATERIAL_TYPE_SUCCESS"
export const UPDATE_MATERIAL_TYPE_FAIL = "UPDATE_MATERIAL_TYPE_FAIL"

/**
 * Delete designgroup
 */
export const DELETE_MATERIAL_TYPE = "DELETE_MATERIAL_TYPE"
export const DELETE_MATERIAL_TYPE_SUCCESS = "DELETE_MATERIAL_TYPE_SUCCESS"
export const DELETE_MATERIAL_TYPE_FAIL = "DELETE_MATERIAL_TYPE_FAIL"
