import {
  GET_INFOPROJECTS_SUCCESS,
  GET_INFOPROJECTS_FAIL,
  ADD_INFOPROJECT_SUCCESS,
  ADD_INFOPROJECT_FAIL,
  UPDATE_INFOPROJECT_SUCCESS,
  UPDATE_INFOPROJECT_FAIL,
  DELETE_INFOPROJECT_SUCCESS,
  DELETE_INFOPROJECT_FAIL,
  GET_INFOPROJECT_PROFILE_SUCCESS,
  GET_INFOPROJECT_PROFILE_FAIL,
  LOADING_INFOPROJECTIMG_SUCCESS,
  LOADING_INFOPROJECTIMG_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  infoprojects: [],
  infoprojectProfile: {},
  loadingUploadImg: false,
  error: {},
}

const infoprojects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INFOPROJECTS_SUCCESS:
      return {
        ...state,
        infoprojects: action.payload,
      }

    case LOADING_INFOPROJECTIMG_SUCCESS:
      return {
        ...state,
        loadingUploadImg: action.payload,
      }

    case LOADING_INFOPROJECTIMG_FAIL:
      return {
        ...state,
        loadingUploadImg: action.payload,
      }

    case GET_INFOPROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_INFOPROJECT_SUCCESS:
      return {
        ...state,
        infoprojects: [...state.infoprojects, action.payload],
      }

    case ADD_INFOPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_INFOPROJECT_PROFILE_SUCCESS:
      return {
        ...state,
        infoprojectProfile: action.payload,
      }

    case UPDATE_INFOPROJECT_SUCCESS:
      return {
        ...state,
        infoprojects: state.infoprojects.map(infoproject =>
          infoproject.id.toString() === action.payload.id.toString()
            ? { infoproject, ...action.payload }
            : infoproject
        ),
      }

    case UPDATE_INFOPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_INFOPROJECT_SUCCESS:
      return {
        ...state,
        infoprojects: state.infoprojects.filter(
          infoproject =>
            infoproject.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_INFOPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_INFOPROJECT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default infoprojects
