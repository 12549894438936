import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_MATERIAL_SHOPS,
  GET_MATERIAL_SHOP_PROFILE,
  ADD_NEW_MATERIAL_SHOP,
  DELETE_MATERIAL_SHOP,
  UPDATE_MATERIAL_SHOP,
} from "./actionTypes"

import {
  getMaterialShopsSuccess,
  getMaterialShopsFail,
  getMaterialShopProfileSuccess,
  getMaterialShopProfileFail,
  addMaterialShopFail,
  addMaterialShopSuccess,
  updateMaterialShopSuccess,
  updateMaterialShopFail,
  deleteMaterialShopSuccess,
  deleteMaterialShopFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMaterialShops,
  getMaterialShopProfile,
  addNewMaterialShop,
  updateMaterialShop,
  deleteMaterialShop,
} from "../../helpers/fakebackend_helper"

function* fetchMaterialShops({ payload: active }) {
  // console.log("active", data.active)
  // console.log("material_type_id", data.material_type_id)

  try {
    const response = yield call(getMaterialShops, active)
    // console.log(response);
    yield put(getMaterialShopsSuccess(response))
  } catch (error) {
    yield put(getMaterialShopsFail(error))
  }
}

function* fetchMaterialShopProfile({ payload: id }) {
  // console.log(id)
  try {
    const response = yield call(getMaterialShopProfile, id)
    // console.log("Profile => ", response)
    yield put(getMaterialShopProfileSuccess(response))
  } catch (error) {
    yield put(getMaterialShopProfileFail(error))
  }
}

function* onUpdateMaterialShop({ payload: materialshop }) {
  try {
    // let x = materialshop.get('formData')
    // console.log(x)
    const response = yield call(updateMaterialShop, materialshop)
    yield put(updateMaterialShopSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateMaterialShopFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteMaterialShop({ payload: materialshop }) {
  try {
    const response = yield call(deleteMaterialShop, materialshop)
    yield put(deleteMaterialShopSuccess(response))
  } catch (error) {
    yield put(deleteMaterialShopFail(error))
  }
}

function* onAddNewMaterialShop({ payload: materialshop }) {
  try {
    const response = yield call(addNewMaterialShop, materialshop)
    yield put(addMaterialShopSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addMaterialShopFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* materialType() {
  yield takeEvery(GET_MATERIAL_SHOPS, fetchMaterialShops)
  yield takeEvery(GET_MATERIAL_SHOP_PROFILE, fetchMaterialShopProfile)
  yield takeEvery(ADD_NEW_MATERIAL_SHOP, onAddNewMaterialShop)
  yield takeEvery(UPDATE_MATERIAL_SHOP, onUpdateMaterialShop)
  yield takeEvery(DELETE_MATERIAL_SHOP, onDeleteMaterialShop)
}

export default materialType
