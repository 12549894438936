import React from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  FormGroup,
  Input,
  Collapse,
  CardSubname,
  CardFooter,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import classnames from "classnames"
import { AvForm, AvInput } from "availity-reactstrap-validation"
import message_bottom from "assets/images/message_bottom.png"
import message_4 from "assets/images/message_4.png"

// API store
import {
  getMessageLists as onGetMessageLists,
  updateMessageList as onUpdateMessageList,
} from "store/message-lists/actions"
import { isEmpty } from "lodash"

const Message = () => {
  const dispatch = useDispatch()

  const { messageLists } = useSelector(state => ({
    messageLists: state.messageLists.messageLists,
  }))

  const [col1, setcol1] = React.useState(false)
  const [col2, setcol2] = React.useState(false)
  const [col3, setcol3] = React.useState(false)

  const [material, setMaterial] = React.useState([])
  const [note, setNote] = React.useState([])
  const [standard, setStandard] = React.useState([])
  const [annotation, setAnnotation] = React.useState([])

  const [group_1, setGroup_1] = React.useState([])
  const [group_2, setGroup_2] = React.useState([])
  const [group_3, setGroup_3] = React.useState([])

  const [employment_contract_1, setEmployment_contract_1] = React.useState([])
  const [employment_contract_2, setEmployment_contract_2] = React.useState([])

  const [subCol1_1, setSubCol1_1] = React.useState(false)
  const [subCol1_2, setSubCol1_2] = React.useState(false)
  const [subCol1_3, setSubCol1_3] = React.useState(false)
  const [subCol1_4, setSubCol1_4] = React.useState(false)

  const [subCol2_1, setSubCol2_1] = React.useState(false)
  const [subCol2_2, setSubCol2_2] = React.useState(false)
  const [subCol2_3, setSubCol2_3] = React.useState(false)

  const [subCol3_1, setSubCol3_1] = React.useState(false)
  const [subCol3_2, setSubCol3_2] = React.useState(false)

  React.useEffect(() => {
    dispatch(onGetMessageLists())
  }, [dispatch])

  React.useEffect(() => {
    if (!isEmpty(messageLists)) {
      // console.log("messageLists", messageLists)
      setMaterial(messageLists.material)
      setNote(messageLists.note)
      setStandard(messageLists.standard)
      setAnnotation(messageLists.annotation)

      setGroup_1(messageLists.group_1)
      setGroup_2(messageLists.group_2)
      setGroup_3(messageLists.group_3)

      setEmployment_contract_1(messageLists.employment_contract_1)
      setEmployment_contract_2(messageLists.employment_contract_2)
    }
  }, [messageLists])

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
  }

  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
  }
  const t_col3 = () => {
    setcol3(!col3)
    setcol1(false)
    setcol2(false)
  }

  const subT_col1_1 = () => {
    setSubCol1_1(!subCol1_1)
    setSubCol1_2(false)
    setSubCol1_3(false)
    setSubCol1_4(false)
  }

  const subT_col1_2 = () => {
    setSubCol1_1(false)
    setSubCol1_2(!subCol1_2)
    setSubCol1_3(false)
    setSubCol1_4(false)
  }

  const subT_col1_3 = () => {
    setSubCol1_1(false)
    setSubCol1_2(false)
    setSubCol1_3(!subCol1_3)
    setSubCol1_4(false)
  }

  const subT_col1_4 = () => {
    setSubCol1_1(false)
    setSubCol1_2(false)
    setSubCol1_3(false)
    setSubCol1_4(!subCol1_4)
  }

  const subT_col2_1 = () => {
    setSubCol2_1(!subCol2_1)
    setSubCol2_2(false)
    setSubCol2_3(false)
  }

  const subT_col2_2 = () => {
    setSubCol2_1(false)
    setSubCol2_2(!subCol2_2)
    setSubCol2_3(false)
  }

  const subT_col2_3 = () => {
    setSubCol2_1(false)
    setSubCol2_2(false)
    setSubCol2_3(!subCol2_3)
  }

  const subT_col3_1 = () => {
    setSubCol3_1(!subCol3_1)
    setSubCol3_2(false)
  }
  const subT_col3_2 = () => {
    setSubCol3_2(!subCol3_2)
    setSubCol3_1(false)
  }

  const handleSubmit = async (event, errors, values) => {
    if (errors.length > 0) {
    } else {
      const data = new FormData()
      data.append("material", JSON.stringify(material))
      data.append("note", JSON.stringify(note))
      data.append("standard", JSON.stringify(standard))
      data.append("annotation", JSON.stringify(annotation))
      data.append("group_1", JSON.stringify(group_1))
      data.append("group_2", JSON.stringify(group_2))
      data.append("group_3", JSON.stringify(group_3))
      dispatch(onUpdateMessageList(data))
    }
  }

  function addRow_material() {
    const modifiedRows = [...material]
    modifiedRows.push({
      name: "",
      group: 1,
      del: 1,
      type: 1,
    })
    setMaterial(modifiedRows)
  }
  function addRow_note() {
    const modifiedRows = [...note]
    modifiedRows.push({
      name: "",
      group: 2,
      del: 1,
      type: 1,
    })
    setNote(modifiedRows)
  }
  function addRow_standard() {
    const modifiedRows = [...standard]
    modifiedRows.push({
      name: "",
      group: 3,
      del: 1,
      type: 1,
    })
    setStandard(modifiedRows)
  }

  function addRow_annotation() {
    const modifiedRows = [...annotation]
    modifiedRows.push({
      name: "",
      group: 4,
      del: 1,
      type: 1,
    })
    setAnnotation(modifiedRows)
  }

  function addRow_group_1() {
    const modifiedRows = [...group_1]
    modifiedRows.push({
      name: "",
      group: 1,
      del: 1,
      type: 2,
    })
    setGroup_1(modifiedRows)
  }
  function addRow_group_2() {
    const modifiedRows = [...group_2]
    modifiedRows.push({
      name: "",
      group: 2,
      del: 1,
      type: 2,
    })
    setGroup_2(modifiedRows)
  }
  function addRow_group_3() {
    const modifiedRows = [...group_3]
    modifiedRows.push({
      name: "",
      group: 3,
      del: 1,
      type: 2,
    })
    setGroup_3(modifiedRows)
  }

  function addRow_employment_contract_1() {
    const modifiedRows = [...employment_contract_1]
    modifiedRows.push({
      name: "",
      group: 1,
      del: 1,
      type: 3,
    })
    setEmployment_contract_1(modifiedRows)
  }

  function addRow_employment_contract_2() {
    const modifiedRows = [...employment_contract_2]
    modifiedRows.push({
      name: "",
      group: 1,
      del: 1,
      type: 3,
    })
    setEmployment_contract_2(modifiedRows)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Message | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <AvForm onSubmit={handleSubmit}>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      ข้อความเริ่มต้น ( สามารถใส่ได้สูงสุด 1,000 อักษร )
                    </CardTitle>

                    <CardBody>
                      <div className="accordion" id="accordion">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className={classnames(
                                "accordion-button",
                                "fw-medium",
                                { collapsed: !col1 }
                              )}
                              type="button"
                              onClick={t_col1}
                              style={{ cursor: "pointer" }}
                            >
                              ใบเสอนราคา
                            </button>
                          </h2>

                          <Collapse
                            isOpen={col1}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <div className="accordion" id="accordion">
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingOne"
                                  >
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        {
                                          collapsed: !subCol1_4,
                                        }
                                      )}
                                      type="button"
                                      onClick={subT_col1_4}
                                      style={{ cursor: "pointer" }}
                                    >
                                      ข้อความใต้ตาราง
                                    </button>
                                  </h2>

                                  <Collapse
                                    isOpen={subCol1_4}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <Row>
                                        {(annotation || []).map((item, index) =>
                                          item.del == 0 ? null : (
                                            <Col xs={12} key={index}>
                                              <div className="hstack gap-3">
                                                <div className="text-center">
                                                  <h4 style={{ width: "50px" }}>
                                                    {index + 1}
                                                  </h4>
                                                </div>

                                                <div style={{ width: "100%" }}>
                                                  <AvInput
                                                    name={
                                                      "annotation_name_" + index
                                                    }
                                                    type="textarea"
                                                    className="form-control me-auto"
                                                    placeholder="หัวข้อเรื่อง"
                                                    value={
                                                      annotation[index].name
                                                    }
                                                    onChange={e => {
                                                      let select = [
                                                        ...annotation,
                                                      ]
                                                      select[index].name =
                                                        e.target.value
                                                      setAnnotation(select)
                                                    }}
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  />
                                                </div>
                                                <div className="vr"></div>
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-danger"
                                                  onClick={e => {
                                                    let select = [...annotation]
                                                    select[index].del = 0
                                                    setAnnotation(select)
                                                  }}
                                                >
                                                  X
                                                </button>
                                              </div>
                                            </Col>
                                          )
                                        )}
                                        <br />
                                        <Button
                                          color="primary"
                                          className="btn btn-primary "
                                          size="sm"
                                          onClick={e => addRow_annotation()}
                                        >
                                          เพิ่มใหม่
                                        </Button>
                                      </Row>
                                    </div>
                                  </Collapse>
                                </div>

                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingTwo"
                                  >
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        {
                                          collapsed: !subCol1_1,
                                        }
                                      )}
                                      type="button"
                                      onClick={subT_col1_1}
                                      style={{ cursor: "pointer" }}
                                    >
                                      วัสดุพื้นฐาน
                                      รายละเอียดวัสดุที่ใช้ในการดําเนินการโดยประมาณ
                                    </button>
                                  </h2>

                                  <Collapse
                                    isOpen={subCol1_1}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <Row>
                                        {(material || []).map((item, index) =>
                                          item.del == 0 ? null : (
                                            <Col xs={12} key={index}>
                                              <div className="hstack gap-3">
                                                <div className="text-center">
                                                  <h4 style={{ width: "50px" }}>
                                                    {index + 1}
                                                  </h4>
                                                </div>

                                                <div style={{ width: "100%" }}>
                                                  <AvInput
                                                    name={
                                                      "material_name_" + index
                                                    }
                                                    type="textarea"
                                                    className="form-control me-auto"
                                                    placeholder="หัวข้อเรื่อง"
                                                    value={material[index].name}
                                                    onChange={e => {
                                                      let select = [...material]
                                                      select[index].name =
                                                        e.target.value
                                                      setMaterial(select)
                                                    }}
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  />
                                                </div>
                                                <div className="vr"></div>
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-danger"
                                                  onClick={e => {
                                                    let select = [...material]
                                                    select[index].del = 0
                                                    setMaterial(select)
                                                  }}
                                                >
                                                  X
                                                </button>
                                              </div>
                                            </Col>
                                          )
                                        )}
                                        <br />
                                        <Button
                                          color="primary"
                                          className="btn btn-primary "
                                          size="sm"
                                          onClick={e => addRow_material()}
                                        >
                                          เพิ่มใหม่
                                        </Button>
                                      </Row>
                                    </div>
                                  </Collapse>
                                </div>

                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingThree"
                                  >
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        {
                                          collapsed: !subCol1_2,
                                        }
                                      )}
                                      type="button"
                                      onClick={subT_col1_2}
                                      style={{ cursor: "pointer" }}
                                    >
                                      กรณีมีห้องน้ํา
                                    </button>
                                  </h2>

                                  <Collapse
                                    isOpen={subCol1_2}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <Row>
                                        {(note || []).map((item, index) =>
                                          item.del == 0 ? null : (
                                            <Col xs={12} key={index}>
                                              <div className="hstack gap-3">
                                                <div className="text-center">
                                                  <h4 style={{ width: "50px" }}>
                                                    {index + 1}
                                                  </h4>
                                                </div>
                                                <div style={{ width: "100%" }}>
                                                  <AvInput
                                                    name={
                                                      "material_note_" + index
                                                    }
                                                    type="textarea"
                                                    className="form-control me-auto"
                                                    placeholder="หัวข้อเรื่อง"
                                                    value={note[index].name}
                                                    onChange={e => {
                                                      let select = [...note]
                                                      select[index].name =
                                                        e.target.value
                                                      setNote(select)
                                                    }}
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  />
                                                </div>
                                                <div className="vr"></div>
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-danger"
                                                  onClick={e => {
                                                    let select = [...note]
                                                    select[index].del = 0
                                                    setNote(select)
                                                  }}
                                                >
                                                  X
                                                </button>
                                              </div>
                                            </Col>
                                          )
                                        )}
                                        <br />
                                        <Button
                                          color="primary"
                                          className="btn btn-primary "
                                          size="sm"
                                          onClick={e => addRow_note()}
                                        >
                                          เพิ่มใหม่
                                        </Button>
                                      </Row>
                                    </div>
                                  </Collapse>
                                </div>

                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingFour"
                                  >
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        {
                                          collapsed: !subCol1_3,
                                        }
                                      )}
                                      type="button"
                                      onClick={subT_col1_3}
                                      style={{ cursor: "pointer" }}
                                    >
                                      มาตรฐานในการก่อสร้าง
                                    </button>
                                  </h2>
                                  <Collapse
                                    isOpen={subCol1_3}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <Row>
                                        {(standard || []).map((item, index) =>
                                          item.del == 0 ? null : (
                                            <Col xs={12} key={index}>
                                              <div className="hstack gap-3">
                                                <div className="text-center">
                                                  <h4 style={{ width: "50px" }}>
                                                    {index + 1}
                                                  </h4>
                                                </div>

                                                <div style={{ width: "100%" }}>
                                                  <AvInput
                                                    name={
                                                      "material_standard_" +
                                                      index
                                                    }
                                                    type="textarea"
                                                    className="form-control me-auto"
                                                    placeholder="หัวข้อเรื่อง"
                                                    value={standard[index].name}
                                                    onChange={e => {
                                                      let select = [...standard]
                                                      select[index].name =
                                                        e.target.value
                                                      setStandard(select)
                                                    }}
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                  />
                                                </div>
                                                <div className="vr"></div>
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-danger"
                                                  onClick={e => {
                                                    let select = [...standard]
                                                    select[index].del = 0
                                                    setStandard(select)
                                                  }}
                                                >
                                                  X
                                                </button>
                                              </div>
                                            </Col>
                                          )
                                        )}
                                        <br />
                                        <Button
                                          color="primary"
                                          className="btn btn-primary "
                                          size="sm"
                                          onClick={e => addRow_standard()}
                                        >
                                          เพิ่มใหม่
                                        </Button>
                                      </Row>
                                    </div>
                                  </Collapse>
                                </div>
                                <div>
                                  <img
                                    style={{
                                      marginTop: "5px",
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    src={message_bottom}
                                    alt={process.env.REACT_APP_NAMECOMPANY}
                                  />
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className={classnames(
                                "accordion-button",
                                "fw-medium",
                                { collapsed: !col2 }
                              )}
                              type="button"
                              onClick={t_col2}
                              style={{ cursor: "pointer" }}
                            >
                              สัญญาก่อสร้าง
                            </button>
                          </h2>

                          <Collapse
                            isOpen={col2}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <div className="accordion" id="accordion">
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingOne"
                                  >
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        {
                                          collapsed: !subCol2_3,
                                        }
                                      )}
                                      type="button"
                                      onClick={subT_col2_3}
                                      style={{ cursor: "pointer" }}
                                    >
                                      เงื่อนไขสัญญา (ข้อที่ 4 เป็นต้นไป)
                                    </button>
                                  </h2>

                                  <Collapse
                                    isOpen={subCol2_3}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <Row>
                                        {(group_3 || []).map((item, index) =>
                                          item.del == 0 ? null : (
                                            <Col xs={12} key={index}>
                                              <div className="hstack gap-3">
                                                <div className="text-center">
                                                  <h4 style={{ width: "50px" }}>
                                                    {index + 3 + 1}
                                                  </h4>
                                                </div>

                                                <div style={{ width: "100%" }}>
                                                  <AvInput
                                                    name={
                                                      "group_2_name" + index
                                                    }
                                                    type="textarea"
                                                    className="form-control me-auto"
                                                    placeholder="หัวข้อเรื่อง"
                                                    value={group_3[index].name}
                                                    onChange={e => {
                                                      let select = [...group_3]
                                                      select[index].name =
                                                        e.target.value
                                                      setGroup_3(select)
                                                    }}
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                  />
                                                </div>
                                                <div className="vr"></div>
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-danger"
                                                  onClick={e => {
                                                    let select = [...group_3]
                                                    select[index].del = 0
                                                    setGroup_3(select)
                                                  }}
                                                >
                                                  X
                                                </button>
                                              </div>
                                            </Col>
                                          )
                                        )}
                                        <Button
                                          color="primary"
                                          className="btn btn-primary "
                                          size="sm"
                                          onClick={e => addRow_group_3()}
                                        >
                                          เพิ่มใหม่
                                        </Button>
                                      </Row>
                                    </div>
                                  </Collapse>
                                </div>

                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingTwo"
                                  >
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        {
                                          collapsed: !subCol2_1,
                                        }
                                      )}
                                      type="button"
                                      onClick={subT_col2_1}
                                      style={{ cursor: "pointer" }}
                                    >
                                      บ้านสําเร็จรูปตามแบบสถาปัตย์
                                    </button>
                                  </h2>

                                  <Collapse
                                    isOpen={subCol2_1}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <Row>
                                        {(group_1 || []).map((item, index) =>
                                          item.del == 0 ? null : (
                                            <Col xs={12} key={index}>
                                              <div className="hstack gap-3">
                                                <div className="text-center">
                                                  <h4 style={{ width: "50px" }}>
                                                    {index + 1}
                                                  </h4>
                                                </div>

                                                <div style={{ width: "100%" }}>
                                                  <AvInput
                                                    name={
                                                      "group_2_name" + index
                                                    }
                                                    type="textarea"
                                                    className="form-control me-auto"
                                                    placeholder="หัวข้อเรื่อง"
                                                    value={group_1[index].name}
                                                    onChange={e => {
                                                      let select = [...group_1]
                                                      select[index].name =
                                                        e.target.value
                                                      setGroup_1(select)
                                                    }}
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                  />
                                                </div>
                                                <div className="vr"></div>
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-danger"
                                                  onClick={e => {
                                                    let select = [...group_1]
                                                    select[index].del = 0
                                                    setGroup_1(select)
                                                  }}
                                                >
                                                  X
                                                </button>
                                              </div>
                                            </Col>
                                          )
                                        )}
                                        <Button
                                          color="primary"
                                          className="btn btn-primary "
                                          size="sm"
                                          onClick={e => addRow_group_1()}
                                        >
                                          เพิ่มใหม่
                                        </Button>
                                      </Row>
                                    </div>
                                  </Collapse>
                                </div>

                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingThree"
                                  >
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        {
                                          collapsed: !subCol2_2,
                                        }
                                      )}
                                      type="button"
                                      onClick={subT_col2_2}
                                      style={{ cursor: "pointer" }}
                                    >
                                      ห้องน้ําตามแบบสถาปัตย์ (แยกโซนเปียก-แห้ง)
                                    </button>
                                  </h2>

                                  <Collapse
                                    isOpen={subCol2_2}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <Row>
                                        {(group_2 || []).map((item, index) =>
                                          item.del == 0 ? null : (
                                            <Col xs={12} key={index}>
                                              <div className="hstack gap-3">
                                                <div className="text-center">
                                                  <h4 style={{ width: "50px" }}>
                                                    {index + 1}
                                                  </h4>
                                                </div>

                                                <div style={{ width: "100%" }}>
                                                  <AvInput
                                                    name={
                                                      "group_2_name" + index
                                                    }
                                                    type="textarea"
                                                    className="form-control me-auto"
                                                    placeholder="หัวข้อเรื่อง"
                                                    value={group_2[index].name}
                                                    onChange={e => {
                                                      let select = [...group_2]
                                                      select[index].name =
                                                        e.target.value
                                                      setGroup_2(select)
                                                    }}
                                                    validate={{
                                                      required: {
                                                        value: true,
                                                      },
                                                    }}
                                                  />
                                                </div>
                                                <div className="vr"></div>
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-danger"
                                                  onClick={e => {
                                                    let select = [...group_2]
                                                    select[index].del = 0
                                                    setGroup_2(select)
                                                  }}
                                                >
                                                  X
                                                </button>
                                              </div>
                                            </Col>
                                          )
                                        )}
                                        <Button
                                          color="primary"
                                          className="btn btn-primary "
                                          size="sm"
                                          onClick={e => addRow_group_2()}
                                        >
                                          เพิ่มใหม่
                                        </Button>
                                      </Row>
                                    </div>
                                  </Collapse>
                                </div>

                                <div>
                                  <img
                                    style={{
                                      marginTop: "5px",
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    src={message_4}
                                    alt={process.env.REACT_APP_NAMECOMPANY}
                                  />
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className={classnames(
                                "accordion-button",
                                "fw-medium",
                                { collapsed: !col3 }
                              )}
                              type="button"
                              onClick={t_col3}
                              style={{ cursor: "pointer" }}
                            >
                              สัญญาก่อสร้าง (ช่าง)
                            </button>
                          </h2>

                          <Collapse
                            isOpen={col3}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <div className="accordion" id="accordion">
                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingTwo"
                                  >
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        {
                                          collapsed: !subCol3_1,
                                        }
                                      )}
                                      type="button"
                                      onClick={subT_col3_1}
                                      style={{ cursor: "pointer" }}
                                    >
                                      หัวข้อที่ 1
                                    </button>
                                  </h2>

                                  <Collapse
                                    isOpen={subCol3_1}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <Row>
                                        {(employment_contract_1 || []).map(
                                          (item, index) =>
                                            item.del == 0 ? null : (
                                              <Col xs={12} key={index}>
                                                <div className="hstack gap-3">
                                                  <div className="text-center">
                                                    <h4
                                                      style={{ width: "50px" }}
                                                    >
                                                      {index + 1}
                                                    </h4>
                                                  </div>

                                                  <div
                                                    style={{ width: "100%" }}
                                                  >
                                                    <AvInput
                                                      name={
                                                        "employment_name_1" +
                                                        index
                                                      }
                                                      type="textarea"
                                                      className="form-control me-auto"
                                                      placeholder="หัวข้อเรื่อง"
                                                      value={
                                                        employment_contract_1[
                                                          index
                                                        ].name
                                                      }
                                                      onChange={e => {
                                                        let select = [
                                                          ...employment_contract_1,
                                                        ]
                                                        select[index].name =
                                                          e.target.value
                                                        setEmployment_contract_1(
                                                          select
                                                        )
                                                      }}
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="vr"></div>
                                                  <button
                                                    type="button"
                                                    className="btn btn-outline-danger"
                                                    onClick={e => {
                                                      let select = [
                                                        ...employment_contract_1,
                                                      ]
                                                      select[index].del = 0
                                                      setEmployment_contract_1(
                                                        select
                                                      )
                                                    }}
                                                  >
                                                    X
                                                  </button>
                                                </div>
                                              </Col>
                                            )
                                        )}
                                        <Button
                                          color="primary"
                                          className="btn btn-primary "
                                          size="sm"
                                          onClick={e =>
                                            addRow_employment_contract_1()
                                          }
                                        >
                                          เพิ่มใหม่
                                        </Button>
                                      </Row>
                                    </div>
                                  </Collapse>
                                </div>

                                <div className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id="headingThree"
                                  >
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        {
                                          collapsed: !subCol3_2,
                                        }
                                      )}
                                      type="button"
                                      onClick={subT_col3_2}
                                      style={{ cursor: "pointer" }}
                                    >
                                      หัวข้อที่ 2
                                    </button>
                                  </h2>

                                  <Collapse
                                    isOpen={subCol3_2}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <Row>
                                        {(employment_contract_2 || []).map(
                                          (item, index) =>
                                            item.del == 0 ? null : (
                                              <Col xs={12} key={index}>
                                                <div className="hstack gap-3">
                                                  <div className="text-center">
                                                    <h4
                                                      style={{ width: "50px" }}
                                                    >
                                                      {index + 1}
                                                    </h4>
                                                  </div>

                                                  <div
                                                    style={{ width: "100%" }}
                                                  >
                                                    <AvInput
                                                      name={
                                                        "employment_name_2" +
                                                        index
                                                      }
                                                      type="textarea"
                                                      className="form-control me-auto"
                                                      placeholder="หัวข้อเรื่อง"
                                                      value={
                                                        employment_contract_2[
                                                          index
                                                        ].name
                                                      }
                                                      onChange={e => {
                                                        let select = [
                                                          ...employment_contract_2,
                                                        ]
                                                        select[index].name =
                                                          e.target.value
                                                        setEmployment_contract_2(
                                                          select
                                                        )
                                                      }}
                                                      validate={{
                                                        required: {
                                                          value: true,
                                                        },
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="vr"></div>
                                                  <button
                                                    type="button"
                                                    className="btn btn-outline-danger"
                                                    onClick={e => {
                                                      let select = [
                                                        ...employment_contract_2,
                                                      ]
                                                      select[index].del = 0
                                                      setEmployment_contract_2(
                                                        select
                                                      )
                                                    }}
                                                  >
                                                    X
                                                  </button>
                                                </div>
                                              </Col>
                                            )
                                        )}
                                        <Button
                                          color="primary"
                                          className="btn btn-primary "
                                          size="sm"
                                          onClick={e =>
                                            addRow_employment_contract_2()
                                          }
                                        >
                                          เพิ่มใหม่
                                        </Button>
                                      </Row>
                                    </div>
                                  </Collapse>
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </CardBody>
                    <div style={{ textAlign: "right" }}>
                      <Button type="submit" color="secondary" block>
                        บันทึก
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </AvForm>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

// export default Message
export default withRouter(Message)
