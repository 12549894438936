/* ATTENDSS */
export const GET_ATTENDSS = "GET_ATTENDSS"
export const GET_ATTENDSS_SUCCESS = "GET_ATTENDSS_SUCCESS"
export const GET_ATTENDSS_FAIL = "GET_ATTENDSS_FAIL"

/* ATTENDSS PROFILE */
export const GET_ATTENDS_PROFILE = "GET_ATTENDS_PROFILE"
export const GET_ATTENDS_PROFILE_SUCCESS = "GET_ATTENDS_PROFILE_SUCCESS"
export const GET_ATTENDS_PROFILE_FAIL = "GET_ATTENDS_PROFILE_FAIL"

/**
 * add attend
 */
export const ADD_NEW_ATTENDS = "ADD_NEW_ATTENDS"
export const ADD_ATTENDS_SUCCESS = "ADD_ATTENDS_SUCCESS"
export const ADD_ATTENDS_FAIL = "ADD_ATTENDS_FAIL"

/**
 * Edit attend
 */
export const UPDATE_ATTENDS = "UPDATE_ATTENDS"
export const UPDATE_ATTENDS_SUCCESS = "UPDATE_ATTENDS_SUCCESS"
export const UPDATE_ATTENDS_FAIL = "UPDATE_ATTENDS_FAIL"

/**
 * Delete attend
 */
export const DELETE_ATTENDS = "DELETE_ATTENDS"
export const DELETE_ATTENDS_SUCCESS = "DELETE_ATTENDS_SUCCESS"
export const DELETE_ATTENDS_FAIL = "DELETE_ATTENDS_FAIL"
