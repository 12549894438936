import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { isEmpty, map } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import signature_manager from "assets/images/signature_manager.png"

import logo_s from "assets/images/logo_s.png"
import { getSlipprojectProfile as onGetSlipprojectProfile } from "store/slips-project/actions"
import THBText from "thai-baht-text" // for ES6
import moment from "moment"
import "moment/locale/th" // without this line it didn't work

import pdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

//ต้องระบุตามชื่อของ ไฟล์ font
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
}

const InvoiceSlip = props => {
  const dispatch = useDispatch()

  const { slipprojectProfile } = useSelector(state => ({
    slipprojectProfile: state.slipprojects.slipprojectProfile,
  }))

  const {
    match: { params },
  } = props

  const [formDataSlip, setFormDataSlip] = React.useState({})
  const [formDataVender, setFormDataVender] = React.useState({})

  React.useEffect(() => {
    if (params && params.id) {
      dispatch(onGetSlipprojectProfile(params.id))
    }
  }, [params])

  React.useEffect(() => {
    // console.log(slipprojectProfile)
    if (!isEmpty(slipprojectProfile)) {
      setFormDataSlip(slipprojectProfile)
      if (!isEmpty(slipprojectProfile.vender)) {
        setFormDataVender(slipprojectProfile.vender)
      }
    } else {
    }
  }, [slipprojectProfile])

  function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image()
      img.setAttribute("crossOrigin", "anonymous")

      img.onload = () => {
        var canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height

        var ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0)

        var dataURL = canvas.toDataURL("image/png")

        resolve(dataURL)
      }

      img.onerror = error => {
        reject(error)
      }

      img.src = url
    })
  }

  //Print the Invoice
  async function printInvoice() {
    var docDefinition = {
      watermark: {
        text: process.env.REACT_APP_NAMECOMPANY,
        color: "#e6e6e6",
        opacity: 0.5,
      },
      content: [
        {
          columns: [
            {
              style: "header",
              alignment: "left",
              width: "50%",
              text: "ติดต่อ:   Line @ : @pmh-m   Tel (รุจ) : 062 640 5432	",
            },
            {
              alignment: "right",
              width: "50%",
              text: "",
            },
          ],
        },
        {
          columns: [
            {
              style: "header",
              alignment: "left",
              width: "80%",
              text: [
                "(ต้นฉบับ / original)\n",
                {
                  text:
                    formDataSlip.vat_type == 1
                      ? "ใบเสร็จรับเงิน\n"
                      : "ใบเสร็จรับเงิน/ใบกำกับภาษี\n",
                  fontSize: 18,
                  bold: true,
                },
                " วันที่: " +
                  moment(formDataSlip.date_add).add(543, "year").format("LL"),
                " เลขที่:  " + formDataSlip.no,
              ],
            },

            {
              image: await getBase64ImageFromURL(logo_s),
              width: 40,
              height: 50,
            },
          ],
        },

        {
          margin: [0, 0, 0, 20],
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: ["50%", "50%"],
            body: [
              [
                {
                  border: [false, false, true, false],
                  text: [
                    "ชื่อผู้ขาย: ",
                    {
                      text: "บจก. พรีเมียม เฮ้าส์ โมดูลาร์\n",
                      fontSize: 13,
                      bold: true,
                    },
                    "ที่อยู่: 60/1 หมู่ที่ 1 ต.คลองโยง อ.พุทธมณฑล จ.นครปฐม 73170",
                    "\nเลขผู้เสียภาษี/ทะเบียนนิติ: 0735565006671",
                  ],
                },
                {
                  border: [false, false, false, false],

                  text: [
                    "ชื่อผู้ซื้อ: ",
                    {
                      border: [false, false, false, false],
                      text:
                        formDataVender.id_card == null
                          ? formDataVender.contact
                          : formDataVender.contact +
                            " (" +
                            formDataVender.id_card +
                            ")",
                      fontSize: 13,
                      bold: true,
                    },
                    " \nเบอร์: ",
                    {
                      text: formDataVender.tel,
                      fontSize: 13,
                      bold: true,
                    },

                    "\nที่อยู่: " + formDataVender.address,
                  ],
                },
              ],
            ],
          },
          layout: {
            defaultBorder: false,
          },
        },

        {
          margin: [0, 0, 0, 50],
          style: "tableExample",
          table: {
            widths: ["auto", "*", "auto", "auto"],
            heights: function (row) {
              return row == 1 ? 350 : 0
            },
            body:
              formDataSlip.vat_type == 1
                ? [
                    [
                      {
                        bold: true,
                        text: "ลำดับ\nOrd no",
                      },
                      {
                        bold: true,
                        text: "รายละเอียด\nDescription",
                      },
                      {
                        bold: true,
                        text: "ส่วนลด\nDiscount",
                        alignment: "right",
                      },
                      {
                        bold: true,
                        text: "เป็นเงิน\nAmount",
                        alignment: "right",
                      },
                    ],
                    [
                      {
                        text: "1",
                      },
                      {
                        text: formDataSlip.comment,
                      },
                      {
                        alignment: "right",
                        text:
                          Math.floor(parseFloat(formDataSlip.discount) * 100) /
                          100,
                      },
                      {
                        alignment: "right",
                        text: parseFloat(
                          Math.floor(
                            parseFloat(
                              formDataSlip.money - formDataSlip.discount
                            ) * 100
                          ) / 100
                        ).toLocaleString("th-TH", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }),
                      },
                    ],

                    [
                      {
                        alignment: "center",
                        text:
                          "(" +
                          THBText(
                            Math.floor(
                              parseFloat(
                                formDataSlip.money - formDataSlip.discount
                              ) * 100
                            ) / 100
                          ) +
                          ")",
                        colSpan: 2,
                      },
                      {},
                      {
                        alignment: "right",
                        text: "ยอดสุทธิ (บาท)",
                      },
                      {
                        alignment: "right",
                        text: parseFloat(
                          (parseFloat(
                            formDataSlip.money - formDataSlip.discount
                          ) *
                            100) /
                            100
                        ).toLocaleString("th-TH", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }),
                      },
                    ],
                  ]
                : [
                    [
                      {
                        bold: true,
                        text: "ลำดับ\nOrd no",
                      },
                      {
                        bold: true,
                        text: "รายละเอียด\nDescription",
                      },
                      {
                        bold: true,
                        text: "ส่วนลด\nDiscount",
                        alignment: "right",
                      },
                      {
                        bold: true,
                        text: "เป็นเงิน\nAmount",
                        alignment: "right",
                      },
                    ],
                    [
                      {
                        text: "1",
                      },
                      {
                        text: formDataSlip.comment,
                      },
                      {
                        alignment: "right",
                        text:
                          Math.floor(parseFloat(formDataSlip.discount) * 100) /
                          100,
                      },
                      {
                        alignment: "right",
                        text: parseFloat(
                          Math.floor(
                            parseFloat(
                              formDataSlip.money - formDataSlip.discount
                            ) * 100
                          ) / 100
                        ).toLocaleString("th-TH", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }),
                      },
                    ],
                    [
                      {
                        alignment: "right",
                        text: "vat 7%",
                        colSpan: 3,
                      },
                      {},
                      {},
                      {
                        alignment: "right",
                        text: parseFloat(
                          Math.round(
                            parseFloat(
                              parseFloat(formDataSlip.money * 0.07) -
                                parseFloat(formDataSlip.discount)
                            ) * 100
                          ) / 100
                        ).toLocaleString("th-TH", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }),
                      },
                    ],
                    [
                      {
                        alignment: "right",
                        text: "ก่อน Vat",
                        colSpan: 3,
                      },
                      {},
                      {},
                      {
                        alignment: "right",
                        text: parseFloat(
                          parseFloat(
                            formDataSlip.money - formDataSlip.discount
                          ) - parseFloat(formDataSlip.money * 0.07)
                        ).toLocaleString("th-TH", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }),
                      },
                    ],
                    [
                      {
                        alignment: "center",
                        text:
                          "(" +
                          THBText(
                            Math.round(
                              (parseFloat(
                                parseFloat(formDataSlip.money) * 7 -
                                  parseFloat(formDataSlip.discount) +
                                  parseFloat(
                                    formDataSlip.money - formDataSlip.discount
                                  )
                              ) *
                                100) /
                                100
                            )
                          ) +
                          ")",
                        colSpan: 2,
                      },
                      {},
                      {
                        alignment: "right",
                        text: "ยอดสุทธิ (บาท)",
                      },
                      {
                        alignment: "right",
                        text: parseFloat(
                          (parseFloat(
                            (parseFloat(formDataSlip.money) / 100) * 7 -
                              parseFloat(formDataSlip.discount) +
                              parseFloat(
                                formDataSlip.money - formDataSlip.discount
                              )
                          ) *
                            100) /
                            100
                        ).toLocaleString("th-TH", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }),
                      },
                    ],
                  ],
          },
        },

        {
          columns: [
            {
              style: "header",
              alignment: "left",
              width: "50%",
              text: "",
            },
            formDataSlip.vat_type == 1
              ? {
                  alignment: "center",
                  image: await getBase64ImageFromURL(signature_manager),
                  width: "50%",
                  fit: [100, 100],
                }
              : {
                  alignment: "center",
                  width: "50%",
                  text: "( ............................................ )\nบจก. พรีเมียม เฮ้าส์ โมดูลาร์ โดย\nรุจ อินทสุรัช \n(ผู้จัดการ)",
                },
          ],
        },
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        subheader: {
          fontSize: 13,
          decoration: "underline",
          decorationStyle: "solid",
          decorationColor: "black",
        },
        tableExample: {
          fontSize: 13,
        },
        tableHeader: {
          bold: true,
          fontSize: 14,
          color: "black",
        },
      },
      defaultStyle: {
        font: "THSarabunNew",
      },
      pageSize: "A4",
    }
    // pdfMake.createPdf(docDefinition).open()
    let quo_name =
      formDataSlip.vat_type == 1
        ? "ใบเสร็จรับเงิน"
        : "ใบเสร็จรับเงิน/ใบกำกับภาษี" +
          formDataVender.contact +
          " (" +
          formDataSlip.comment +
          ")" +
          ".pdf"
    // console.log(quo_name)
    pdfMake.createPdf(docDefinition).download(quo_name)
  }

  return (
    <React.Fragment>
      <style scoped>
        {
          "\
          .table-hide-border{\
            border-style: hidden;\
            border-right-style: hidden;\
          }\
          .table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {\
            border: 1px solid black;\
          }\
         .table > :not(:first-child){\
          border: 1px solid black;\
         }\
          .hidden {\
            overflow: hidden;\
          }\
          .td-note {\
            display: inline-block;\
            margin-bottom:1px;\
            padding-bottom:1px;\
          }\
          .hidden td {\
            display: inline-block;\
            border-style: hidden\
          }\
          @media print {\
            .pagebreak { page-break-before: always; }\
          }\
          "
        }
      </style>

      <div className="page-content">
        <MetaTags>
          <title>Slip Detail | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>

        <Container fluid style={{ fontFamily: "sans-serif" }}>
          {/* Render Breadcrumbs */}
          {!isEmpty(slipprojectProfile) && (
            <Row>
              <Col lg="12">
                {/* header */}
                <div className="d-print-none">
                  <div style={{ textAlignLast: "right" }}>
                    <Link
                      to="#"
                      onClick={printInvoice}
                      className="btn btn-success me-2"
                    >
                      <i className="fa fa-print" />
                    </Link>

                    <Link
                      to={`/projects-overview/${formDataSlip.project_id}`}
                      className="btn btn-secondary"
                    >
                      Back
                    </Link>
                  </div>
                  <hr />
                </div>
                {/* page 1 */}
                <Card className="pagebreak" style={{ boxShadow: "none" }}>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr>
                            <th
                              className="table-hide-border"
                              style={{ width: "50%" }}
                            >
                              ติดต่อ: &nbsp; Line @ : @pmh-m &nbsp; Tel (รุจ) :
                              062 640 5432 &nbsp;
                            </th>
                            <th
                              className="table-hide-border"
                              style={{
                                textAlign: "right",
                                width: "50%",
                              }}
                            ></th>
                          </tr>

                          <tr>
                            <th className="table-hide-border">
                              <p
                                className="font-size-13"
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  textAlign: "left",
                                  fontWeight: "normal",
                                }}
                              >
                                ( ต้นฉบับ / original )
                              </p>
                              <p
                                className="font-size-20"
                                style={{
                                  fontWeight: "bold",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                {formDataSlip.vat_type == 1
                                  ? "ใบเสร็จรับเงิน"
                                  : "ใบเสร็จรับเงิน/ใบกำกับภาษี"}
                              </p>
                              <p
                                className="font-size-14"
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                วันที่:&nbsp;
                                {moment(formDataSlip.date_add)
                                  .add(543, "year")
                                  .format("LL")}
                                &nbsp; เลขที่: {formDataSlip.no}
                              </p>
                            </th>
                            <th
                              className="table-hide-border"
                              style={{
                                textAlign: "right",
                              }}
                            >
                              <img
                                style={{ marginTop: "5px" }}
                                src={logo_s}
                                alt={process.env.REACT_APP_NAMECOMPANY}
                                height="80"
                              />
                            </th>
                          </tr>
                        </thead>
                      </Table>
                    </div>

                    <div className="table-responsive">
                      <Table className="table-nowarp">
                        <tbody>
                          <tr>
                            <td
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                width: "50%",
                                border: "1px solid white",
                                borderRight: "1px solid",
                              }}
                            >
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                ชื่อผู้ขาย:&nbsp;
                                <span
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  บจก. พรีเมียม เฮ้าส์ โมดูลาร์
                                </span>
                              </span>
                            </td>

                            <td
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                paddingLeft: "5px",
                                width: "50%",
                                border: "1px solid white",
                              }}
                            >
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                ชื่อผู้ซื้อ:&nbsp;
                                <span
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {formDataVender.id_card == null
                                    ? formDataVender.contact
                                    : formDataVender.contact +
                                      " (" +
                                      formDataVender.id_card +
                                      ")"}
                                </span>
                              </span>
                              <br />
                              {/* &emsp; &emsp; &emsp; */}
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                <br /> เบอร์:&nbsp;
                                {formDataVender.tel}
                              </span>
                            </td>
                          </tr>

                          <tr>
                            <td
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                width: "50%",
                                border: "1px solid white",
                                borderRight: "1px solid",
                              }}
                            >
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                ที่อยู่:&nbsp;60/1 หมู่ที่ 1 ต.คลองโยง
                                อ.พุทธมณฑล จ.นครปฐม 73170
                              </span>
                            </td>

                            <td
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                paddingLeft: "5px",
                                width: "50%",
                                border: "1px solid white",
                              }}
                            >
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                ที่อยู่:&nbsp;
                                {formDataVender.address}
                              </span>
                            </td>
                          </tr>

                          <tr>
                            <td
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                width: "50%",
                                border: "1px solid white",
                                borderRight: "1px solid",
                              }}
                            >
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              >
                                เลขผู้เสียภาษี/ทะเบียนนิติ:&nbsp;0735565006671
                              </span>
                            </td>

                            <td
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                paddingLeft: "5px",
                                width: "50%",
                                border: "1px solid white",
                              }}
                            >
                              <span
                                className="font-size-16"
                                style={{
                                  fontWeight: "normal",
                                }}
                              ></span>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>

                    <br />
                    <br />
                    <div className="table-responsive">
                      <Table className="table-nowarp" bordered>
                        <thead>
                          <tr style={{ borderBottom: "1px solid" }}>
                            <th style={{ width: "80px" }}>
                              ลำดับ
                              <br />
                              Ord no
                            </th>
                            <th>
                              รายละเอียด
                              <br />
                              Description
                            </th>
                            <th style={{ textAlign: "right", width: "100px" }}>
                              ส่วนลด
                              <br />
                              Discount
                            </th>

                            <th style={{ textAlign: "right", width: "100px" }}>
                              เป็นเงิน
                              <br />
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ height: "340px" }}>
                            <td
                              style={{
                                paddingTop: "3px",
                                paddingBottom: "3px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                1
                              </p>
                            </td>
                            <td
                              style={{
                                paddingTop: "3px",
                                paddingBottom: "3px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                {formDataSlip.comment}
                              </p>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                paddingTop: "3px",
                                paddingBottom: "3px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                {Math.floor(
                                  parseFloat(formDataSlip.discount) * 100
                                ) / 100}
                              </p>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                paddingTop: "3px",
                                paddingBottom: "3px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                {parseFloat(
                                  Math.floor(
                                    parseFloat(
                                      parseFloat(formDataSlip.money) -
                                        parseFloat(formDataSlip.discount)
                                    ) * 100
                                  ) / 100
                                ).toLocaleString("th-TH", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </p>
                            </td>
                          </tr>

                          {formDataSlip.vat_type == 1 ? (
                            <tr>
                              <td
                                colSpan={2}
                                className="table-hide-border"
                                style={{
                                  textAlign: "center",
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                }}
                              >
                                (
                                {THBText(
                                  Math.floor(
                                    parseFloat(
                                      formDataSlip.money - formDataSlip.discount
                                    ) * 100
                                  ) / 100
                                )}
                                )
                              </td>
                              <td
                                className="table-hide-border"
                                style={{
                                  textAlign: "center",
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                }}
                              >
                                ยอดสุทธิ
                              </td>

                              <td
                                className="table-hide-border"
                                style={{
                                  textAlign: "right",
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                }}
                              >
                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                  }}
                                >
                                  {parseFloat(
                                    Math.floor(
                                      parseFloat(
                                        formDataSlip.money -
                                          formDataSlip.discount
                                      ) * 100
                                    ) / 100
                                  ).toLocaleString("th-TH", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </p>
                              </td>
                            </tr>
                          ) : (
                            <>
                              <tr>
                                <td
                                  colSpan={3}
                                  className="table-hide-border"
                                  style={{
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    vat 7 %
                                  </p>
                                </td>
                                <td
                                  className="table-hide-border"
                                  style={{
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    {parseFloat(
                                      (parseFloat(
                                        (parseFloat(formDataSlip.money) / 100) *
                                          7 -
                                          parseFloat(formDataSlip.discount)
                                      ) *
                                        100) /
                                        100
                                    ).toLocaleString("th-TH", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan={3}
                                  className="table-hide-border"
                                  style={{
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  ก่อน Vat
                                </td>
                                <td
                                  className="table-hide-border"
                                  style={{
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    {parseFloat(
                                      Math.floor(
                                        parseFloat(
                                          formDataSlip.money -
                                            formDataSlip.discount
                                        ) * 100
                                      ) / 100
                                    ).toLocaleString("th-TH", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td
                                  colSpan={2}
                                  className="table-hide-border"
                                  style={{
                                    textAlign: "center",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  (&nbsp;
                                  {THBText(
                                    Math.round(
                                      parseFloat(
                                        (parseFloat(formDataSlip.money) / 100) *
                                          7 -
                                          parseFloat(formDataSlip.discount) +
                                          parseFloat(
                                            formDataSlip.money -
                                              formDataSlip.discount
                                          )
                                      ) * 100
                                    ) / 100
                                  )}
                                  &nbsp; )
                                </td>

                                <td
                                  className="table-hide-border"
                                  style={{
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  ยอดสุทธิ
                                </td>

                                <td
                                  className="table-hide-border"
                                  style={{
                                    textAlign: "right",
                                    paddingTop: "3px",
                                    paddingBottom: "3px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    {parseFloat(
                                      (parseFloat(
                                        (parseFloat(formDataSlip.money) / 100) *
                                          7 -
                                          parseFloat(formDataSlip.discount) +
                                          parseFloat(
                                            formDataSlip.money -
                                              formDataSlip.discount
                                          )
                                      ) *
                                        100) /
                                        100
                                    ).toLocaleString("th-TH", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </p>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>

                    <br />
                    <div className="table-responsive">
                      <Table className="table-nowarp text-center">
                        <thead>
                          <tr className="table-hide-border">
                            <th style={{ width: "50%" }}></th>
                            <th style={{ textAlign: "center", width: "50%" }}>
                              <p
                                style={{
                                  fontWeight: "nomal",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                ( ............................................ )
                              </p>
                              <p
                                style={{
                                  fontWeight: "nomal",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                บจก. พรีเมียม เฮ้าส์ โมดูลาร์ โดย <br />
                                รุจ อินทสุรัช
                              </p>
                              <p
                                style={{
                                  fontWeight: "nomal",
                                  marginTop: "0px",
                                  paddingTop: "0px",
                                }}
                              >
                                ผู้จัดการ
                              </p>
                            </th>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
                <hr />
                <div className="d-print-none">
                  <div style={{ textAlignLast: "right" }}>
                    <Link
                      to="#"
                      onClick={printInvoice}
                      className="btn btn-success me-2"
                    >
                      <i className="fa fa-print" />
                    </Link>

                    <Link
                      to={`/projects-overview/${formDataSlip.project_id}`}
                      className="btn btn-secondary"
                    >
                      Back
                    </Link>
                  </div>
                </div>
                <br />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoiceSlip.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceSlip)
