import {
  GET_CHECK_PROJECTS_BOQ_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_PROJECT_PROFILE_SUCCESS,
  GET_PROJECT_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  projects: [],
  projectProfile: {},
  lastInsertProject: null,
  check_boq_projects: [],
  error: {},
}

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }
    case GET_CHECK_PROJECTS_BOQ_SUCCESS:
      return {
        ...state,
        check_boq_projects: action.payload,
      }

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        lastInsertProject: action.payload.id,
        projects: [...state.projects, action.payload],
      }

    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_PROFILE_SUCCESS:
      return {
        ...state,
        projectProfile: action.payload,
        lastInsertProject: null,
      }

    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.map(project =>
          project.id.toString() === action.payload.id.toString()
            ? { project, ...action.payload }
            : project
        ),
      }

    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter(
          project => project.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default projects
