import {
  GET_SERWAYROJECTS_SUCCESS,
  GET_SERWAYROJECTS_FAIL,
  ADD_SERWAYROJECT_SUCCESS,
  ADD_SERWAYROJECT_FAIL,
  UPDATE_SERWAYROJECT_SUCCESS,
  UPDATE_SERWAYROJECT_FAIL,
  DELETE_SERWAYROJECT_SUCCESS,
  DELETE_SERWAYROJECT_FAIL,
  GET_SERWAYROJECT_PROFILE_SUCCESS,
  GET_SERWAYROJECT_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  serwayproject: [],
  serwayprojectProfile: [],
  error: {},
}

const serwayprojects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERWAYROJECTS_SUCCESS:
      return {
        ...state,
        serwayproject: action.payload,
      }

    case GET_SERWAYROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_SERWAYROJECT_SUCCESS:
      let newData = action.payload
      let oldData = state.serwayproject
      // console.log(newData)
      // console.log(oldData)

      for (let index = 0; index < newData.length; index++) {
        oldData.push(newData[index])
      }
      return { ...state, serwayproject: oldData }

    case ADD_SERWAYROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERWAYROJECT_PROFILE_SUCCESS:
      return {
        ...state,
        serwayprojectProfile: action.payload,
      }

    case UPDATE_SERWAYROJECT_SUCCESS:
      return {
        ...state,
        serwayproject: state.serwayproject.map(serwayproject =>
          serwayproject.id.toString() === action.payload.id.toString()
            ? { serwayproject, ...action.payload }
            : serwayproject
        ),
      }

    case UPDATE_SERWAYROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SERWAYROJECT_SUCCESS:
      return {
        ...state,
        serwayproject: state.serwayproject.filter(
          serwayproject =>
            serwayproject.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_SERWAYROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERWAYROJECT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default serwayprojects
