import React from "react"
import MetaTags from "react-meta-tags"
import PropTypes, { element } from "prop-types"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Col,
  Row,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import classnames from "classnames"
import { Link } from "react-router-dom"

import {
  getProjectProfile as onGetProjectProfile,
  updateProject as onUpdateProject,
} from "store/projects/actions"

import Dashboard from "./dashboard"
import InfoProject from "./infoProject"
import Plan from "./plan"
import Boq from "./boq"

//redux
import { useSelector, useDispatch } from "react-redux"

const ProjectsOverview = props => {
  const dispatch = useDispatch()
  const [projectID, setProjectID] = React.useState(null)

  const [activeTab, setactiveTab] = React.useState(2)
  const [passedSteps, setPassedSteps] = React.useState([])
  const [formData, setFormData] = React.useState({})

  const { projectProfile } = useSelector(state => ({
    projectProfile: state.projects.projectProfile,
  }))

  const {
    match: { params },
  } = props

  React.useEffect(() => {
    if (params && params.id) {
      dispatch(onGetProjectProfile(params.id))
      setProjectID(params.id)
    } else {
      dispatch(onGetProjectProfile(0)) //remove this after full integration
    }
  }, [])

  React.useEffect(() => {
    // setPassedSteps(JSON.parse(projectProfile.project_status))
    if (projectProfile.project_status != undefined) {
      setPassedSteps(JSON.parse(projectProfile.project_status))
    }
  }, [projectProfile])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      // console.log("passedStepsAll: " + passedSteps)
      // console.log("passedSteps: " + passedSteps[Number(passedSteps.length) - 1])
      // console.log("activeTab: " + activeTab)
      // console.log("tab: " + tab)
      if (tab >= 1 && tab <= passedSteps.length) {
        let x = passedSteps.find(element => element == tab)
        if (x != undefined) {
          setactiveTab(tab)
        }
      } else {
        if (projectProfile.status == 1 || projectProfile.status == 4) {
          let x = projectProfile
          x.status = 2
          dispatch(onUpdateProject(x))
        }
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Project Overview | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>
        <div>
          {/* Render Breadcrumbs */}
          <Row>
            <Col sm="6">
              <span className="font-size-18">Project Overview {projectID}</span>
            </Col>
            <Col sm="6" style={{ textAlign: "right" }}>
              <Link to="/projects-grid" style={{ color: "black" }}>
                <span
                  className="font-size-13"
                  style={{ textDecoration: "underline" }}
                >
                  Project
                </span>
                <span>/ Project Overview {projectID}</span>
              </Link>
            </Col>
          </Row>
          {/* <Link to="/projects-grid"> */}
          {/* <Breadcrumbs
              title="Projects"
              breadcrumbItem={"Project Overview " + projectID}
            /> */}
          {/* </Link> */}

          {!isEmpty(projectProfile) && (
            <>
              <Row>
                <Col lg="12">
                  <div>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="steps clearfix">
                          <ul>
                            {/* <NavItem>
                              <Link to="/projects-grid">
                                <span className="number">00</span> Back
                              </Link>
                            </NavItem>

                            <NavItem
                              className={classnames({
                                current: activeTab === 1,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  current: activeTab === 1,
                                })}
                                onClick={() => {
                                  setactiveTab(1)
                                }}
                                hidden={!(passedSteps || []).includes(1)}
                              >
                                <span className="number">01</span> Dashboard
                              </NavLink>
                            </NavItem> */}
                            <NavItem
                              className={classnames({
                                current: activeTab === 2,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 2,
                                })}
                                onClick={() => {
                                  setactiveTab(2)
                                }}
                                hidden={!(passedSteps || []).includes(2)}
                              >
                                <span className="number">02</span> Infos
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 3,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 3,
                                })}
                                onClick={() => {
                                  setactiveTab(3)
                                }}
                                hidden={!(passedSteps || []).includes(3)}
                              >
                                <span className="number">03</span> Plan
                              </NavLink>
                            </NavItem>

                            <NavItem
                              className={classnames({
                                current: activeTab === 4,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 4,
                                })}
                                onClick={() => {
                                  setactiveTab(4)
                                }}
                                hidden={!(passedSteps || []).includes(4)}
                              >
                                <span className="number">04</span> Boq
                              </NavLink>
                            </NavItem>

                            {/*  <NavItem
                              className={classnames({
                                current: activeTab === 5,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 5,
                                })}
                                onClick={() => {
                                  setactiveTab(5)
                                }}
                                hidden={!(passedSteps || []).includes(5)}
                              >
                                <span className="number">05</span> Wage
                              </NavLink>
                            </NavItem>

                            <NavItem
                              className={classnames({
                                current: activeTab === 6,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 6,
                                })}
                                onClick={() => {
                                  setactiveTab(6)
                                }}
                                hidden={!(passedSteps || []).includes(6)}
                              >
                                <span className="number">06</span> Update
                              </NavLink>
                            </NavItem> */}
                          </ul>
                        </div>
                        <div className="clearfix mt-4" >
                          <TabContent activeTab={activeTab} >
                            <TabPane tabId={1}>
                              <Dashboard projectID={projectID} />
                            </TabPane>
                            <TabPane tabId={2}>
                              <InfoProject projectID={projectID} />
                            </TabPane>
                            <TabPane tabId={3}>
                              <Plan projectID={projectID} />
                            </TabPane>

                            <TabPane tabId={4}>
                              <Boq projectID={projectID} />
                            </TabPane>

                            {/* <TabPane tabId={4}>
                              <Card>
                                <CardBody>
                                  <Form>
                                    <Row>
                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-namecard-input11">
                                            Name on Card
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-namecard-input11"
                                            placeholder="Enter Your Name on Card"
                                          />
                                        </div>
                                      </Col>

                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label>Credit Card Type</Label>
                                          <select className="form-select">
                                            <option defaultValue>
                                              Select Card Type
                                            </option>
                                            <option value="AE">
                                              American Express
                                            </option>
                                            <option value="VI">Visa</option>
                                            <option value="MC">
                                              MasterCard
                                            </option>
                                            <option value="DI">Discover</option>
                                          </select>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-cardno-input12">
                                            Credit Card Number
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-cardno-input12"
                                            placeholder="Credit Card Number"
                                          />
                                        </div>
                                      </Col>

                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-card-verification-input0">
                                            Card Verification Number
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-card-verification-input0"
                                            placeholder="Credit Verification Number"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-expiration-input13">
                                            Expiration Date
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-expiration-input13"
                                            placeholder="Card Expiration Date"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </CardBody>
                              </Card>
                            </TabPane>

                            <TabPane tabId={5}>
                              <Card>
                                <CardBody>
                                  <Form>
                                    <Row>
                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-namecard-input11">
                                            Name on Card
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-namecard-input11"
                                            placeholder="Enter Your Name on Card"
                                          />
                                        </div>
                                      </Col>

                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label>Credit Card Type</Label>
                                          <select className="form-select">
                                            <option defaultValue>
                                              Select Card Type
                                            </option>
                                            <option value="AE">
                                              American Express
                                            </option>
                                            <option value="VI">Visa</option>
                                            <option value="MC">
                                              MasterCard
                                            </option>
                                            <option value="DI">Discover</option>
                                          </select>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-cardno-input12">
                                            Credit Card Number
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-cardno-input12"
                                            placeholder="Credit Card Number"
                                          />
                                        </div>
                                      </Col>

                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-card-verification-input0">
                                            Card Verification Number
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-card-verification-input0"
                                            placeholder="Credit Verification Number"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-expiration-input13">
                                            Expiration Date
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-expiration-input13"
                                            placeholder="Card Expiration Date"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </CardBody>
                              </Card>
                            </TabPane>
                            <TabPane tabId={6}>
                              <Card>
                                <CardBody>
                                  <Form>
                                    <Row>
                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-namecard-input11">
                                            Name on Card
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-namecard-input11"
                                            placeholder="Enter Your Name on Card"
                                          />
                                        </div>
                                      </Col>

                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label>Credit Card Type</Label>
                                          <select className="form-select">
                                            <option defaultValue>
                                              Select Card Type
                                            </option>
                                            <option value="AE">
                                              American Express
                                            </option>
                                            <option value="VI">Visa</option>
                                            <option value="MC">
                                              MasterCard
                                            </option>
                                            <option value="DI">Discover</option>
                                          </select>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-cardno-input12">
                                            Credit Card Number
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-cardno-input12"
                                            placeholder="Credit Card Number"
                                          />
                                        </div>
                                      </Col>

                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-card-verification-input0">
                                            Card Verification Number
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-card-verification-input0"
                                            placeholder="Credit Verification Number"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <div className="mb-3">
                                          <Label for="basicpill-expiration-input13">
                                            Expiration Date
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-expiration-input13"
                                            placeholder="Card Expiration Date"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </CardBody>
                              </Card>
                            </TabPane> */}
                          </TabContent>
                        </div>

                        <div className="actions clearfix">
                          <ul>
                            <li className="previous">
                              {activeTab == 2 ? null : (
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab - 1)
                                  }}
                                >
                                  Previous
                                </Link>
                              )}
                            </li>
                            <li>
                              {activeTab == 4 ? null : !(
                                  passedSteps || []
                                ).includes(activeTab + 1) ? (
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab + 1)
                                  }}
                                >
                                  Check
                                </Link>
                              ) : (
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab + 1)
                                  }}
                                >
                                  {activeTab == 1 ? "Next" : "Confirm"}
                                </Link>
                              )}
                              {/* {passedSteps.length} */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CardBody>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

ProjectsOverview.propTypes = {
  match: PropTypes.object,
}

export default withRouter(ProjectsOverview)
