/* DESIGNHOMES */
export const GET_DESIGNHOMES = "GET_DESIGNHOMES"
export const GET_DESIGNHOMES_SUCCESS = "GET_DESIGNHOMES_SUCCESS"
export const GET_DESIGNACHIEVEMENTHOMES_SUCCESS = "GET_DESIGNACHIEVEMENTHOMES_SUCCESS"
export const GET_DESIGNPROMOTIONHOMES_SUCCESS = "GET_DESIGNPROMOTIONHOMES_SUCCESS"

export const GET_DESIGNHOMES_FAIL = "GET_DESIGNHOMES_FAIL"

/* DESIGNHOMES PROFILE */
export const GET_DESIGNHOME_PROFILE = "GET_DESIGNHOME_PROFILE"
export const GET_DESIGNHOME_PROFILE_SUCCESS = "GET_DESIGNHOME_PROFILE_SUCCESS"
export const GET_DESIGNHOME_PROFILE_FAIL = "GET_DESIGNHOME_PROFILE_FAIL"

/**
 * add designhome
 */
export const ADD_NEW_DESIGNHOME = "ADD_NEW_DESIGNHOME"
export const ADD_DESIGNHOME_SUCCESS = "ADD_DESIGNHOME_SUCCESS"
export const ADD_DESIGNHOME_FAIL = "ADD_DESIGNHOME_FAIL"

/**
 * Edit designhome
 */
export const UPDATE_DESIGNHOME = "UPDATE_DESIGNHOME"
export const UPDATE_DESIGNHOME_SUCCESS = "UPDATE_DESIGNHOME_SUCCESS"
export const UPDATE_DESIGNHOME_FAIL = "UPDATE_DESIGNHOME_FAIL"

/**
 * Delete designhome
 */
export const DELETE_DESIGNHOME = "DELETE_DESIGNHOME"
export const DELETE_DESIGNHOME_SUCCESS = "DELETE_DESIGNHOME_SUCCESS"
export const DELETE_DESIGNHOME_FAIL = "DELETE_DESIGNHOME_FAIL"

/**
 * Upload File
 */
export const GET_DESIGNHOMEIMGS_SUCCESS = "GET_DESIGNHOMEIMGS_SUCCESS"
export const UPLOAD_DESIGNHOMEIMG = "UPLOAD_DESIGNHOMEIMG"

export const CHANGEFIRST_DESIGNHOMEIMG = "CHANGEFIRST_DESIGNHOMEIMG"
export const CHANGEFIRST_DESIGNHOMEIMG_SUCCESS = "CHANGEFIRST_DESIGNHOMEIMG"

export const DELETE_DESIGNHOMEIMG = "DELETE_DESIGNHOMEIMG"
export const DELETE_DESIGNHOMEIMG_SUCCESS = "DELETE_DESIGNHOMEIMG"

