import {
  GET_MATERIAL_TYPE_PROFILE,
  GET_MATERIAL_TYPE_PROFILE_FAIL,
  GET_MATERIAL_TYPE_PROFILE_SUCCESS,
  GET_MATERIAL_TYPES,
  GET_MATERIAL_TYPES_FAIL,
  GET_MATERIAL_TYPES_SUCCESS,
  ADD_NEW_MATERIAL_TYPE,
  ADD_MATERIAL_TYPE_SUCCESS,
  ADD_MATERIAL_TYPE_FAIL,
  UPDATE_MATERIAL_TYPE,
  UPDATE_MATERIAL_TYPE_SUCCESS,
  UPDATE_MATERIAL_TYPE_FAIL,
  DELETE_MATERIAL_TYPE,
  DELETE_MATERIAL_TYPE_SUCCESS,
  DELETE_MATERIAL_TYPE_FAIL,
} from "./actionTypes"

export const getMaterialTypes = active => ({
  type: GET_MATERIAL_TYPES,
  payload: active,
})

export const getMaterialTypesSuccess = designgroups => ({
  type: GET_MATERIAL_TYPES_SUCCESS,
  payload: designgroups,
})

export const addNewMaterialType = designgroup => ({
  type: ADD_NEW_MATERIAL_TYPE,
  payload: designgroup,
})

export const addMaterialTypeSuccess = designgroup => ({
  type: ADD_MATERIAL_TYPE_SUCCESS,
  payload: designgroup,
})

export const addMaterialTypeFail = error => ({
  type: ADD_MATERIAL_TYPE_FAIL,
  payload: error,
})

export const getMaterialTypesFail = error => ({
  type: GET_MATERIAL_TYPES_FAIL,
  payload: error,
})

export const getMaterialTypeProfile = id => ({
  type: GET_MATERIAL_TYPE_PROFILE,
  payload: id,
})

export const getMaterialTypeProfileSuccess = designgroupProfile => ({
  type: GET_MATERIAL_TYPE_PROFILE_SUCCESS,
  payload: designgroupProfile,
})

export const getMaterialTypeProfileFail = error => ({
  type: GET_MATERIAL_TYPE_PROFILE_FAIL,
  payload: error,
})

export const updateMaterialType = designgroup => ({
  type: UPDATE_MATERIAL_TYPE,
  payload: designgroup,
})

// export const updateMaterialType = designgroup => console.log(designgroup);

export const updateMaterialTypeSuccess = designgroup => ({
  type: UPDATE_MATERIAL_TYPE_SUCCESS,
  payload: designgroup,
})

export const updateMaterialTypeFail = error => ({
  type: UPDATE_MATERIAL_TYPE_FAIL,
  payload: error,
})

export const deleteMaterialType = designgroup => ({
  type: DELETE_MATERIAL_TYPE,
  payload: designgroup,
})

export const deleteMaterialTypeSuccess = designgroup => ({
  type: DELETE_MATERIAL_TYPE_SUCCESS,
  payload: designgroup,
})

export const deleteMaterialTypeFail = error => ({
  type: DELETE_MATERIAL_TYPE_FAIL,
  payload: error,
})
