import PropTypes from "prop-types"
import React from "react"

import { Row, Container } from "reactstrap"
import { isEmpty } from "lodash"

const Dashboard = ({ projectID }) => {
  React.useEffect(() => {
    if (projectID != undefined) {
      // console.log(projectID)
    }
  }, [projectID])

  return (
    <React.Fragment>
      {!isEmpty(projectID) && (
        <div>
          <Row>Dashboard {projectID}</Row>
        </div>
      )}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  projectID: PropTypes.string,
}

export default Dashboard
