/* EMPLOYMENTCONPROJECTS */
export const GET_EMPLOYMENTCONPROJECTS = "GET_EMPLOYMENTCONPROJECTS"
export const GET_EMPLOYMENTCONPROJECTS_SUCCESS = "GET_EMPLOYMENTCONPROJECTS_SUCCESS"
export const GET_EMPLOYMENTCONPROJECTS_FAIL = "GET_EMPLOYMENTCONPROJECTS_FAIL"

/* EMPLOYMENTCONPROJECTS PROFILE */
export const GET_EMPLOYMENTCONPROJECT_PROFILE = "GET_EMPLOYMENTCONPROJECT_PROFILE"
export const GET_EMPLOYMENTCONPROJECT_PROFILE_SUCCESS = "GET_EMPLOYMENTCONPROJECT_PROFILE_SUCCESS"
export const GET_EMPLOYMENTCONPROJECT_PROFILE_FAIL = "GET_EMPLOYMENTCONPROJECT_PROFILE_FAIL"

/**
 * add Employmentconproject
 */
export const ADD_NEW_EMPLOYMENTCONPROJECT = "ADD_NEW_EMPLOYMENTCONPROJECT"
export const ADD_EMPLOYMENTCONPROJECT_SUCCESS = "ADD_EMPLOYMENTCONPROJECT_SUCCESS"
export const ADD_EMPLOYMENTCONPROJECT_FAIL = "ADD_EMPLOYMENTCONPROJECT_FAIL"

/**
 * Edit Employmentconproject
 */
export const UPDATE_EMPLOYMENTCONPROJECT = "UPDATE_EMPLOYMENTCONPROJECT"
export const UPDATE_EMPLOYMENTCONPROJECT_SUCCESS = "UPDATE_EMPLOYMENTCONPROJECT_SUCCESS"
export const UPDATE_EMPLOYMENTCONPROJECT_FAIL = "UPDATE_EMPLOYMENTCONPROJECT_FAIL"

/**
 * Delete Employmentconproject
 */
export const DELETE_EMPLOYMENTCONPROJECT = "DELETE_EMPLOYMENTCONPROJECT"
export const DELETE_EMPLOYMENTCONPROJECT_SUCCESS = "DELETE_EMPLOYMENTCONPROJECT_SUCCESS"
export const DELETE_EMPLOYMENTCONPROJECT_FAIL = "DELETE_EMPLOYMENTCONPROJECT_FAIL"

