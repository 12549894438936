import {
  GET_LIST_PRODUCT_PROFILE,
  GET_LIST_PRODUCT_PROFILE_FAIL,
  GET_LIST_PRODUCT_PROFILE_SUCCESS,
  GET_LIST_PRODUCTS,
  GET_LIST_PRODUCTS_FAIL,
  GET_LIST_PRODUCTS_SUCCESS,
  ADD_NEW_LIST_PRODUCT,
  ADD_LIST_PRODUCT_SUCCESS,
  ADD_LIST_PRODUCT_FAIL,
  UPDATE_LIST_PRODUCT,
  UPDATE_LIST_PRODUCT_SUCCESS,
  UPDATE_LIST_PRODUCT_FAIL,
  DELETE_LIST_PRODUCT,
  DELETE_LIST_PRODUCT_SUCCESS,
  DELETE_LIST_PRODUCT_FAIL,
} from "./actionTypes"

export const getListProducts = () => ({
  type: GET_LIST_PRODUCTS,
})

export const getListProductsSuccess = products => ({
  type: GET_LIST_PRODUCTS_SUCCESS,
  payload: products,
})

export const addNewListProduct = product => ({
  type: ADD_NEW_LIST_PRODUCT,
  payload: product,
})

export const addListProductSuccess = product => ({
  type: ADD_LIST_PRODUCT_SUCCESS,
  payload: product,
})

export const addListProductFail = error => ({
  type: ADD_LIST_PRODUCT_FAIL,
  payload: error,
})

export const getListProductsFail = error => ({
  type: GET_LIST_PRODUCTS_FAIL,
  payload: error,
})

export const getListProductProfile = id => ({
  type: GET_LIST_PRODUCT_PROFILE,
  payload: id,
})

export const getListProductProfileSuccess = productProfile => ({
  type: GET_LIST_PRODUCT_PROFILE_SUCCESS,
  payload: productProfile,
})

export const getListProductProfileFail = error => ({
  type: GET_LIST_PRODUCT_PROFILE_FAIL,
  payload: error,
})

export const updateListProduct = product => ({
  type: UPDATE_LIST_PRODUCT,
  payload: product,
})

// export const updateListProduct = product => console.log(product);

export const updateListProductSuccess = product => ({
  type: UPDATE_LIST_PRODUCT_SUCCESS,
  payload: product,
})

export const updateListProductFail = error => ({
  type: UPDATE_LIST_PRODUCT_FAIL,
  payload: error,
})

export const deleteListProduct = product => ({
  type: DELETE_LIST_PRODUCT,
  payload: product,
})

export const deleteListProductSuccess = product => ({
  type: DELETE_LIST_PRODUCT_SUCCESS,
  payload: product,
})

export const deleteListProductFail = error => ({
  type: DELETE_LIST_PRODUCT_FAIL,
  payload: error,
})
