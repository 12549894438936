import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
  Collapse,
} from "reactstrap"
import classnames from "classnames"

import { AvForm, AvInput } from "availity-reactstrap-validation"
import {
  getContactConProjects as onGetgetContactConProjects,
  updateContactConProject as onUpdateContactConProject,
} from "store/contacts-con-project/actions"

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()

  const { contactconprojects } = useSelector(state => ({
    contactconprojects: state.contactsConproject.contactconprojects,
  }))

  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [group_1, setGroup_1] = React.useState([])
  const [group_2, setGroup_2] = React.useState([])
  const [group_3, setGroup_3] = React.useState([])

  const [col1, setCol1] = React.useState(false)
  const [col2, setCol2] = React.useState(false)
  const [col3, setCol3] = React.useState(false)

  async function checkAction() {
    setGroup_1([])
    setGroup_2([])
    setGroup_3([])

    if (actionModal.action == "Edit") {
      // console.log('1');
      dispatch(onGetgetContactConProjects(actionModal.id))
    }

    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
  }

  const t_col1 = () => {
    setCol1(!col1)
    setCol2(false)
    setCol3(false)
  }

  const t_col2 = () => {
    setCol2(!col2)
    setCol1(false)
    setCol3(false)
  }

  const t_col3 = () => {
    setCol3(!col3)
    setCol1(false)
    setCol2(false)
  }

  const handleSubmit = async (event, errors, values) => {
    // console.log("1", 1)
    setButtonSubmit(true)
    if (errors.length > 0) {
    } else {
      if (action == "Add") {
      } else if (action == "Edit") {
        const data = new FormData()
        data.append("group_1", JSON.stringify(group_1))
        data.append("group_2", JSON.stringify(group_2))
        data.append("group_3", JSON.stringify(group_3))
        // update contractor
        // console.log("2", 2)

        dispatch(onUpdateContactConProject(data))
      }
      setModal(!modal)
    }
    setButtonSubmit(false)
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      // console.log("contactconprojects", contactconprojects)

      // if (isEmpty(contactconprojects)) {
      //   // setFormData(contactconprojects)
      let { group_1, group_2, group_3, ...rest } = contactconprojects
      // console.log("group_1", group_1)
      // console.log("group_2", group_2)
      // console.log("group_3", group_3)
      setGroup_1(group_1)
      setGroup_2(group_2)
      setGroup_3(group_3)
      // }
    }
    return () => {}
  }, [contactconprojects]) // Only re-run the effect if count changes

  function addRow_group_1() {
    const modifiedRows = [...group_1]
    modifiedRows.push({
      project_id: id,
      title: "",
      group: 1,
      del: 1,
    })
    setGroup_1(modifiedRows)
  }
  function addRow_group_2() {
    const modifiedRows = [...group_2]
    modifiedRows.push({
      project_id: id,
      title: "",
      group: 2,
      del: 1,
    })
    setGroup_2(modifiedRows)
  }
  function addRow_group_3() {
    const modifiedRows = [...group_3]
    modifiedRows.push({
      project_id: id,
      title: "",
      group: 3,
      del: 1,
    })
    setGroup_3(modifiedRows)
  }

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
      size="lg"
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}เงื่อนไขใบเสนอราคา{" "}
      </ModalHeader>
      <AvForm onSubmit={handleSubmit}>
        <ModalBody>
          <h5>สามารถใส่ได้สูงสุด 1,000 อักษร</h5>
          <hr />
          <div className="accordion" id="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: !col3,
                  })}
                  type="button"
                  onClick={t_col3}
                  style={{ cursor: "pointer" }}
                >
                  เงื่อนไขสัญญา (ข้อที่ 4 เป็นต้นไป)
                </button>
              </h2>

              <Collapse isOpen={col3} className="accordion-collapse">
                <div className="accordion-body">
                  <Row>
                    {(group_3 || []).map((item, index) =>
                      item.del == 0 ? null : (
                        <Col xs={12} key={index}>
                          <div className="hstack gap-3">
                            <div className="text-center">
                              <h4 style={{ width: "50px" }}>{index + 3 + 1}</h4>
                            </div>

                            <div style={{ width: "100%" }}>
                              <AvInput
                                name={"group_2_name" + index}
                                type="textarea"
                                className="form-control me-auto"
                                placeholder="หัวข้อเรื่อง"
                                value={group_3[index].title}
                                onChange={e => {
                                  let select = [...group_3]
                                  select[index].title = e.target.value
                                  setGroup_3(select)
                                }}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="vr"></div>
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={e => {
                                let select = [...group_3]
                                select[index].del = 0
                                setGroup_3(select)
                              }}
                            >
                              X
                            </button>
                          </div>
                        </Col>
                      )
                    )}
                    <Button
                      color="primary"
                      className="btn btn-primary "
                      size="sm"
                      onClick={e => addRow_group_3()}
                    >
                      เพิ่มใหม่
                    </Button>
                  </Row>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: !col1,
                  })}
                  type="button"
                  onClick={t_col1}
                  style={{ cursor: "pointer" }}
                >
                  บ้านสําเร็จรูปตามแบบสถาปัตย์
                </button>
              </h2>

              <Collapse isOpen={col1} className="accordion-collapse">
                <div className="accordion-body">
                  <Row>
                    {(group_1 || []).map((item, index) =>
                      item.del == 0 ? null : (
                        <Col xs={12} key={index}>
                          <div className="hstack gap-3">
                            <div className="text-center">
                              <h4 style={{ width: "50px" }}>{index + 1}</h4>
                            </div>

                            <div style={{ width: "100%" }}>
                              <AvInput
                                name={"group_2_name" + index}
                                type="textarea"
                                className="form-control me-auto"
                                placeholder="หัวข้อเรื่อง"
                                value={group_1[index].title}
                                onChange={e => {
                                  let select = [...group_1]
                                  select[index].title = e.target.value
                                  setGroup_1(select)
                                }}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="vr"></div>
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={e => {
                                let select = [...group_1]
                                select[index].del = 0
                                setGroup_1(select)
                              }}
                            >
                              X
                            </button>
                          </div>
                        </Col>
                      )
                    )}
                    <Button
                      color="primary"
                      className="btn btn-primary "
                      size="sm"
                      onClick={e => addRow_group_1()}
                    >
                      เพิ่มใหม่
                    </Button>
                  </Row>
                </div>
              </Collapse>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className={classnames("accordion-button", "fw-medium", {
                    collapsed: !col2,
                  })}
                  type="button"
                  onClick={t_col2}
                  style={{ cursor: "pointer" }}
                >
                  ห้องน้ําตามแบบสถาปัตย์ (แยกโซนเปียก-แห้ง)
                </button>
              </h2>

              <Collapse isOpen={col2} className="accordion-collapse">
                <div className="accordion-body">
                  <Row>
                    {(group_2 || []).map((item, index) =>
                      item.del == 0 ? null : (
                        <Col xs={12} key={index}>
                          <div className="hstack gap-3">
                            <div className="text-center">
                              <h4 style={{ width: "50px" }}>{index + 1}</h4>
                            </div>

                            <div style={{ width: "100%" }}>
                              <AvInput
                                name={"group_2_name" + index}
                                type="textarea"
                                className="form-control me-auto"
                                placeholder="หัวข้อเรื่อง"
                                value={group_2[index].title}
                                onChange={e => {
                                  let select = [...group_2]
                                  select[index].title = e.target.value
                                  setGroup_2(select)
                                }}
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                            <div className="vr"></div>
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={e => {
                                let select = [...group_2]
                                select[index].del = 0
                                setGroup_2(select)
                              }}
                            >
                              X
                            </button>
                          </div>
                        </Col>
                      )
                    )}
                    <Button
                      color="primary"
                      className="btn btn-primary "
                      size="sm"
                      onClick={e => addRow_group_2()}
                    >
                      เพิ่มใหม่
                    </Button>
                  </Row>
                </div>
              </Collapse>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            Save
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
