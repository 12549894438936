import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Col, Container, Row, Button } from "reactstrap"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Cards
import CardProject from "./card-project"

import { getProjects as onGetProjects } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

const ProjectsGrid = props => {
  const dispatch = useDispatch()

  const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }))

  useEffect(() => {
    dispatch(onGetProjects("list", null))
  }, [dispatch])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Projects | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Projects" />
          <Row>
            <Col lg="12">
              {/* Import Cards */}
              <CardProject projects={projects} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ProjectsGrid)
