import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_CUSTOM_HOMES,
  GET_CUSTOM_HOME_PROFILE,
  ADD_NEW_CUSTOM_HOME,
  DELETE_CUSTOM_HOME,
  UPDATE_CUSTOM_HOME,
} from "./actionTypes"

import {
  getCustomHomesSuccess,
  getCustomHomesFail,
  getCustomHomeProfileSuccess,
  getCustomHomeProfileFail,
  addCustomHomeFail,
  addCustomHomeSuccess,
  updateCustomHomeSuccess,
  updateCustomHomeFail,
  deleteCustomHomeSuccess,
  deleteCustomHomeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCustomHomes,
  getCustomHomeProfile,
  addNewCustomHome,
  updateCustomHome,
  deleteCustomHome,
} from "../../helpers/fakebackend_helper"

function* fetchCustomHomes({ payload: id }) {
  try {
    const response = yield call(getCustomHomes, id)
    // console.log(response);
    yield put(getCustomHomesSuccess(response))
  } catch (error) {
    yield put(getCustomHomesFail(error))
  }
}

function* fetchCustomHomeProfile({ payload: id }) {
  // console.log(id)updateCustomHome
  try {
    const response = yield call(getCustomHomeProfile, id)
    // console.log("Profile => ", response)
    yield put(getCustomHomeProfileSuccess(response))
  } catch (error) {
    yield put(getCustomHomeProfileFail(error))
  }
}

function* onUpdateCustomHome({ payload: designgroup }) {
  try {
    // let x = designgroup.get('formData')
    // console.log(x)
    const response = yield call(updateCustomHome, designgroup)
    yield put(updateCustomHomeSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateCustomHomeFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteCustomHome({ payload: designgroup }) {
  try {
    const response = yield call(deleteCustomHome, designgroup)
    yield put(deleteCustomHomeSuccess(response))
  } catch (error) {
    yield put(deleteCustomHomeFail(error))
  }
}

function* onAddNewCustomHome({ payload: designgroup }) {
  try {
    const response = yield call(addNewCustomHome, designgroup)
    yield put(getCustomHomesSuccess(response))
    // yield put(addCustomHomeSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addCustomHomeFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* listStructure() {
  yield takeEvery(GET_CUSTOM_HOMES, fetchCustomHomes)
  yield takeEvery(GET_CUSTOM_HOME_PROFILE, fetchCustomHomeProfile)
  yield takeEvery(ADD_NEW_CUSTOM_HOME, onAddNewCustomHome)
  yield takeEvery(UPDATE_CUSTOM_HOME, onUpdateCustomHome)
  yield takeEvery(DELETE_CUSTOM_HOME, onDeleteCustomHome)
}

export default listStructure
