import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { HashLink as Link } from "react-router-hash-link"

//Import Images

const navItems = [
  { id: 1, idnm: "home", navheading: "Home" },
  { id: 2, idnm: "about", navheading: "About" },
  { id: 3, idnm: "material", navheading: "Materials" },
  { id: 4, idnm: "prices", navheading: "Prices" },
  { id: 5, idnm: "flow", navheading: "Flows" },
  { id: 6, idnm: "project", navheading: "Projects" },

  { id: 7, idnm: "faqs", navheading: "FAQs" },
]

const Navbar_Page = props => {
  const [isOpenMenu, setisOpenMenu] = useState(false)

  const history = useHistory()

  //Store all NavigationbaFr Id into TargetID variable(Used for Scrollspy)
  let TargetId = navItems.map(item => {
    return item.idnm
  })

  return (
    <React.Fragment>
      <Navbar
        style={{ backgroundColor: "rgba(255, 255, 255,1)" }}
        expand="lg"
        fixed="top"
        className={"navigation sticky " + props.navClass}
      >
        <Container>
          <NavbarBrand
            className="navbar-logo-home"
            onClick={() => {
              history.push("/")
            }}
          >
            <img
              // src={logodark}
              src="willbehidden.png"
              alt={process.env.REACT_APP_NAMECOMPANY}
              height="30px"
              className="logo home-logo"
            />
          </NavbarBrand>

          <NavbarToggler
            className="p-0"
            onClick={() => {
              setisOpenMenu(!isOpenMenu)
            }}
          >
            <i className="fa fa-fw fa-bars" />
          </NavbarToggler>

          <Collapse id="topnav-menu-content" isOpen={isOpenMenu} navbar>
            <Nav className="ms-auto navbar-nav" id="topnav-menu">
              {navItems.map((item, key) => (
                <NavItem
                  key={key}
                  className={item.navheading === "Home" ? "active" : ""}
                >
                  <NavLink
                    to={"/#" + item.idnm}
                    tag={Link}
                    onClick={() => {
                      setisOpenMenu(!isOpenMenu)
                    }}
                  >
                    <span style={{ fontSize: "15px", color: "#000000" }}>
                      {item.navheading}
                    </span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>

            <div className="ms-lg-2">
              <Link to="/dashboard" className="btn btn-success w-xs">
                Sign in
              </Link>
            </div>
            <br />
          </Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  )
}

Navbar_Page.propTypes = {
  imglight: PropTypes.any,
  navClass: PropTypes.string,
}

export default Navbar_Page
