import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_LIST_STRUCTURES,
  GET_LIST_STRUCTURE_PROFILE,
  ADD_NEW_LIST_STRUCTURE,
  DELETE_LIST_STRUCTURE,
  UPDATE_LIST_STRUCTURE,
} from "./actionTypes"

import {
  getListStructuresSuccess,
  getListStructuresFail,
  getListStructureProfileSuccess,
  getListStructureProfileFail,
  addListStructureFail,
  addListStructureSuccess,
  updateListStructureSuccess,
  updateListStructureFail,
  deleteListStructureSuccess,
  deleteListStructureFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getListStructures,
  getListStructureProfile,
  addNewListStructure,
  updateListStructure,
  deleteListStructure,
} from "../../helpers/fakebackend_helper"

function* fetchListStructures({ payload: data }) {
  try {
    const response = yield call(getListStructures, data)
    // console.log(response);
    yield put(getListStructuresSuccess(response))
  } catch (error) {
    yield put(getListStructuresFail(error))
  }
}

function* fetchListStructureProfile({ payload: id }) {
  // console.log(id)updateListStructure
  try {
    const response = yield call(getListStructureProfile, id)
    // console.log("Profile => ", response)
    yield put(getListStructureProfileSuccess(response))
  } catch (error) {
    yield put(getListStructureProfileFail(error))
  }
}

function* onUpdateListStructure({ payload: structure }) {
  try {
    // let x = structure.get('formData')
    // console.log(x)
    const response = yield call(updateListStructure, structure)
    // console.log(response)
    yield put(updateListStructureSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateListStructureFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteListStructure({ payload: structure }) {
  try {
    const response = yield call(deleteListStructure, structure)
    yield put(deleteListStructureSuccess(response))
  } catch (error) {
    yield put(deleteListStructureFail(error))
  }
}

function* onAddNewListStructure({ payload: structure }) {
  try {
    const response = yield call(addNewListStructure, structure)
    yield put(addListStructureSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addListStructureFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* listStructure() {
  yield takeEvery(GET_LIST_STRUCTURES, fetchListStructures)
  yield takeEvery(GET_LIST_STRUCTURE_PROFILE, fetchListStructureProfile)
  yield takeEvery(ADD_NEW_LIST_STRUCTURE, onAddNewListStructure)
  yield takeEvery(UPDATE_LIST_STRUCTURE, onUpdateListStructure)
  yield takeEvery(DELETE_LIST_STRUCTURE, onDeleteListStructure)
}

export default listStructure
