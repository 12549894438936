import {
  GET_QUOTATIONPROJECTS_SUCCESS,
  GET_QUOTATIONPROJECTS_FAIL,
  ADD_QUOTATIONPROJECT_SUCCESS,
  ADD_QUOTATIONPROJECT_FAIL,
  UPDATE_QUOTATIONPROJECT_SUCCESS,
  UPDATE_QUOTATIONPROJECT_FAIL,
  DELETE_QUOTATIONPROJECT_SUCCESS,
  DELETE_QUOTATIONPROJECT_FAIL,
  GET_QUOTATIONPROJECT_PROFILE_SUCCESS,
  GET_QUOTATIONPROJECT_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  quotationprojects: [],
  quotationprojectProfile: {},
  error: {},
}

const quotationprojects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUOTATIONPROJECTS_SUCCESS:
      return {
        ...state,
        quotationprojects: action.payload,
      }

    case GET_QUOTATIONPROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_QUOTATIONPROJECT_SUCCESS:

      return {
        ...state,
        quotationprojects: [...state.quotationprojects, action.payload],
      }

    case ADD_QUOTATIONPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_QUOTATIONPROJECT_PROFILE_SUCCESS:
      return {
        ...state,
        quotationprojectProfile: action.payload,
      }

      case UPDATE_QUOTATIONPROJECT_SUCCESS:
        return {
          ...state,
          quotationprojects: state.quotationprojects.map(quotationproject =>
            quotationproject.id.toString() === action.payload.id.toString()
              ? { quotationproject, ...action.payload }
              : quotationproject
          ),
        }
  
      case UPDATE_QUOTATIONPROJECT_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case DELETE_QUOTATIONPROJECT_SUCCESS:
        return {
          ...state,
          quotationprojects: state.quotationprojects.filter(
            quotationproject => quotationproject.id.toString() !== action.payload.id.toString()
          ),
        }
  
      case DELETE_QUOTATIONPROJECT_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    case GET_QUOTATIONPROJECT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default quotationprojects
