import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_LIST_AIUMINIASS,
  GET_LIST_AIUMINIAS_PROFILE,
  ADD_NEW_LIST_AIUMINIAS,
  DELETE_LIST_AIUMINIAS,
  UPDATE_LIST_AIUMINIAS,
} from "./actionTypes"

import {
  getListAluminiasSuccess,
  getListAluminiasFail,
  getListAluminiaProfileSuccess,
  getListAluminiaProfileFail,
  addListAluminiaFail,
  addListAluminiaSuccess,
  updateListAluminiaSuccess,
  updateListAluminiaFail,
  deleteListAluminiaSuccess,
  deleteListAluminiaFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getListAluminias,
  getListAluminiaProfile,
  addNewListAluminia,
  updateListAluminia,
  deleteListAluminia,
} from "../../helpers/fakebackend_helper"

function* fetchListAluminias({ payload: data }) {
  // console.log("fetchListAluminias", data)
  try {
    const response = yield call(getListAluminias, data)
    // console.log(response);
    yield put(getListAluminiasSuccess(response))
  } catch (error) {
    yield put(getListAluminiasFail(error))
  }
}

function* fetchListAluminiaProfile({ payload: id }) {
  // console.log(id)updateListAluminia
  try {
    const response = yield call(getListAluminiaProfile, id)
    // console.log("Profile => ", response)
    yield put(getListAluminiaProfileSuccess(response))
  } catch (error) {
    yield put(getListAluminiaProfileFail(error))
  }
}

function* onUpdateListAluminia({ payload: aluminia }) {
  try {
    // let x = aluminia.get('formData')
    // console.log(x)
    const response = yield call(updateListAluminia, aluminia)
    // console.log(response)
    yield put(updateListAluminiaSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateListAluminiaFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteListAluminia({ payload: aluminia }) {
  try {
    const response = yield call(deleteListAluminia, aluminia)
    yield put(deleteListAluminiaSuccess(response))
  } catch (error) {
    yield put(deleteListAluminiaFail(error))
  }
}

function* onAddNewListAluminia({ payload: aluminia }) {
  try {
    const response = yield call(addNewListAluminia, aluminia)
    // console.log("onAddNewListAluminia", response)

    yield put(addListAluminiaSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addListAluminiaFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* listAluminia() {
  yield takeEvery(GET_LIST_AIUMINIASS, fetchListAluminias)
  yield takeEvery(GET_LIST_AIUMINIAS_PROFILE, fetchListAluminiaProfile)
  yield takeEvery(ADD_NEW_LIST_AIUMINIAS, onAddNewListAluminia)
  yield takeEvery(UPDATE_LIST_AIUMINIAS, onUpdateListAluminia)
  yield takeEvery(DELETE_LIST_AIUMINIAS, onDeleteListAluminia)
}

export default listAluminia
