import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_USERGROUPS } from "./actionTypes"

import { getUserGroupsSuccess, getUserGroupsFail } from "./actions"

//Include Both Helper File with needed methods
import { getUserGroups } from "../../helpers/fakebackend_helper"

function* fetchUserGroups() {
  try {
    const response = yield call(getUserGroups)
    // console.log(response);
    yield put(getUserGroupsSuccess(response))
  } catch (error) {
    yield put(getUserGroupsFail(error))
  }
}

function* userGroups() {
  yield takeEvery(GET_USERGROUPS, fetchUserGroups)
}

export default userGroups
