import {
  GET_MATERIAL_SHOPS_SUCCESS,
  GET_MATERIAL_SHOPS_FAIL,
  ADD_MATERIAL_SHOP_SUCCESS,
  ADD_MATERIAL_SHOP_FAIL,
  UPDATE_MATERIAL_SHOP_SUCCESS,
  UPDATE_MATERIAL_SHOP_FAIL,
  DELETE_MATERIAL_SHOP_SUCCESS,
  DELETE_MATERIAL_SHOP_FAIL,
  GET_MATERIAL_SHOP_PROFILE_SUCCESS,
  GET_MATERIAL_SHOP_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  materialShops: [],
  materialShopProfile: {},
  error: {},
}

const materialShops = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MATERIAL_SHOPS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        materialShops: action.payload,
      }

    case GET_MATERIAL_SHOPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_MATERIAL_SHOP_SUCCESS:
      return {
        ...state,
        materialShops: [...state.materialShops, action.payload],
      }

    case ADD_MATERIAL_SHOP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MATERIAL_SHOP_PROFILE_SUCCESS:
      // console.log("GET_MATERIAL_SHOP_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        materialShopProfile: action.payload,
      }

    case UPDATE_MATERIAL_SHOP_SUCCESS:
      return {
        ...state,
        materialShops: state.materialShops.map(materialshop =>
          materialshop.id.toString() === action.payload.id.toString()
            ? { materialshop, ...action.payload }
            : materialshop
        ),
      }

    case UPDATE_MATERIAL_SHOP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_MATERIAL_SHOP_SUCCESS:
      return {
        ...state,
        materialShops: state.materialShops.filter(
          materialshop =>
            materialshop.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_MATERIAL_SHOP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MATERIAL_SHOP_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default materialShops
