/* INFOPROJECTS */
export const GET_INFOPROJECTS = "GET_INFOPROJECTS"
export const GET_INFOPROJECTS_SUCCESS = "GET_INFOPROJECTS_SUCCESS"
export const GET_INFOPROJECTS_FAIL = "GET_INFOPROJECTS_FAIL"

/* INFOPROJECTS PROFILE */
export const GET_INFOPROJECT_PROFILE = "GET_INFOPROJECT_PROFILE"
export const GET_INFOPROJECT_PROFILE_SUCCESS = "GET_INFOPROJECT_PROFILE_SUCCESS"
export const GET_INFOPROJECT_PROFILE_FAIL = "GET_INFOPROJECT_PROFILE_FAIL"

/**
 * add infoproject
 */
export const ADD_NEW_INFOPROJECT = "ADD_NEW_INFOPROJECT"
export const ADD_INFOPROJECT_SUCCESS = "ADD_INFOPROJECT_SUCCESS"
export const ADD_INFOPROJECT_FAIL = "ADD_INFOPROJECT_FAIL"

/**
 * Edit infoproject
 */
export const UPDATE_INFOPROJECT = "UPDATE_INFOPROJECT"
export const UPDATE_INFOPROJECT_SUCCESS = "UPDATE_INFOPROJECT_SUCCESS"
export const UPDATE_INFOPROJECT_FAIL = "UPDATE_INFOPROJECT_FAIL"

/**
 * Delete infoproject
 */
export const DELETE_INFOPROJECT = "DELETE_INFOPROJECT"
export const DELETE_INFOPROJECT_SUCCESS = "DELETE_INFOPROJECT_SUCCESS"
export const DELETE_INFOPROJECT_FAIL = "DELETE_INFOPROJECT_FAIL"

/**
 * Upload File
 */

export const UPLOAD_INFOPROJECTFILEHOME = "UPLOAD_INFOPROJECTFILEHOME"
export const LOADING_INFOPROJECTIMG_SUCCESS = "LOADING_INFOPROJECTIMG_SUCCESS"
export const LOADING_INFOPROJECTIMG_FAIL = "LOADING_INFOPROJECTIMG_FAIL"