import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
  Table,
  InputGroup,
} from "reactstrap"
import { isEmpty } from "lodash"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  getListStructureProfile as onGetListStructureProfile,
  addNewListStructure as onAddNewListStructure,
  updateListStructure as onUpdateListStructure,
} from "store/list-structures/actions"
import { getMaterialStocks as onGetMaterialStocks } from "store/material-stocks/actions"
import { getCustomProjects as onGetCustomProjects } from "store/custom-projects/actions"

import Select from "react-select"

const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()

  const { listStructureProfile } = useSelector(state => ({
    listStructureProfile: state.listStructures.listStructureProfile,
  }))
  const { materialStocks } = useSelector(state => ({
    materialStocks: state.materialStocks.materialStocks,
  }))

  const { customprojects } = useSelector(state => ({
    customprojects: state.customProject.customprojects,
  }))

  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState(listStructureProfile)
  const [dataMaterialStocks, setDataMaterialStocks] = React.useState([])
  const [dataCustomProject, setDataCustomProject] = React.useState([])

  // Table Data
  const [tableData, setTableData] = React.useState([]) // set campaign as default

  async function checkAction() {
    setFormData({})
    setTableData([])
    if (actionModal.action == "Add") {
      setFormData({
        type_structures: 1,
        active: 1,
      })
    } else if (actionModal.action == "Edit") {
      dispatch(onGetListStructureProfile(actionModal.id))
    }
    dispatch(onGetMaterialStocks(1, 0))
    dispatch(onGetCustomProjects(1))
    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true)
    if (errors.length > 0) {
    } else {
      const data = new FormData()
      data.append("formData", JSON.stringify(formData))
      data.append("list_structure_details", JSON.stringify(tableData))

      if (action == "Add") {
        // save new contractor
        dispatch(onAddNewListStructure(data))
      } else if (action == "Edit") {
        // update contractor
        dispatch(onUpdateListStructure(data))
      }
      setModal(!modal)
    }
    setButtonSubmit(false)
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      let { list_structure_details, ...reset } = listStructureProfile

      setTableData(list_structure_details)
      setFormData(reset)
    }
    return () => {}
  }, [listStructureProfile]) // Only re-run the effect if count changes

  // React.useEffect(() => {
  //   console.log(tableData)
  //   return () => {}
  // }, [tableData]) // Only re-run the effect if count changes

  React.useEffect(async () => {
    // console.log(materialStocks)
    if (!isEmpty(materialStocks)) {
      const options = await materialStocks.map((d, index) => ({
        value: d.id,
        label: d.name,
        material_price: d.price,
        unit: d.unit,
        material_type: d.material_type,
      }))

      setDataMaterialStocks(options)
    }
    return () => {}
  }, [materialStocks]) // Only re-run the effect if count changes

  React.useEffect(async () => {
    // console.log(customprojects)
    if (!isEmpty(customprojects)) {
      const options = await customprojects.map((d, index) => ({
        value: d.id,
        label: d.title,
        detail: d.detail,
        group_id: d.group_id,
        type: d.type,
      }))

      setDataCustomProject(options)
    }
    return () => {}
  }, [customprojects]) // Only re-run the effect if count changes

  const onAddRow = () => {
    const modifiedRows = [...tableData]
    modifiedRows.push({
      key: modifiedRows.length + 1,
      material_id: null,
      material_price: 0,
      name: "",
      unit: "",
      use: 0,
      material_del: 1,
    })
    setTableData(modifiedRows)
  }

  function onDeletetRow(key) {
    var modifiedRows = [...tableData]
    for (let index = 0; index < modifiedRows.length; index++) {
      if (modifiedRows[index].key == key) {
        modifiedRows[index].material_del = 0
      }
    }
    setTableData(modifiedRows)
  }

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
      style={{ maxWidth: "90%" }}
      size="lg"
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}โครงสร้าง{" "}
      </ModalHeader>
      <AvForm onSubmit={handleSubmit} model={formData}>
        <ModalBody>
          <Row>
            <Col xs={12} sm={12} md={8} lg={8}>
              <FormGroup className="mb-3">
                <AvField
                  className="form-control"
                  id="label-name-Input"
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  onChange={e =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>

            <Col xs={12} sm={12} md={4} lg={4}>
              <FormGroup className="mb-3">
                <InputGroup>
                  <AvField
                    name="persen"
                    type="text"
                    placeholder="Persen"
                    value={formData.persen}
                    onChange={e =>
                      setFormData({ ...formData, persen: e.target.value })
                    }
                    validate={{
                      required: { value: true },
                    }}
                  />
                  <div className="input-group-text">%</div>
                </InputGroup>
              </FormGroup>
            </Col>

            {/* <Col xs={12} sm={12} md={6} lg={4}>
              <FormGroup className="mb-3">
                <AvField
                  name="price"
                  type="text"
                  placeholder="Price"
                  value={formData.price}
                  onChange={e =>
                    setFormData({ ...formData, price: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col> */}

            <Col xs={12} sm={12} md={6} lg={4}>
              <FormGroup className="mt-2">
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="radioTypeStructures"
                      checked={formData.type_structures == 1}
                      value={1}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          type_structures: e.target.value,
                        })
                      }
                    />
                    เริ่มต้น
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label>
                    <Input
                      type="radio"
                      name="radioTypeStructures"
                      checked={formData.type_structures == 2}
                      value={2}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          type_structures: e.target.value,
                        })
                      }
                    />
                    อื่น ๆ
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup className="mt-2">
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 1}
                      value={1}
                      onChange={e =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    เปิดใช้งาน
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 2}
                      value={2}
                      onChange={e =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    ปิดใช้งาน
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm="12">
              <p
                style={{ fontSize: "13px" }}
                onClick={() => onAddRow()}
                className="btn-block btn btn-primary"
              >
                Material Datatable
                <i className="mdi mdi-plus-circle-outline" />
              </p>
            </Col>
            <Col sm="12">
              <Table className="table mb-0" responsive>
                <thead>
                  <tr>
                    <th style={{ width: "30px" }}>#</th>
                    <th>วัสดุ</th>
                    <th style={{ width: "250px" }}>เพิ่มเติม</th>
                    <th style={{ width: "150px" }}>หน่วย</th>
                    {/* <th style={{ width: "150px", textAlign: "right" }}>ใช้</th> */}
                    <th style={{ width: "150px", textAlign: "right" }}>
                      ราคา (ขาย)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((v, k) => (
                    <tr key={k}>
                      {v.material_del == 0 ? null : (
                        <>
                          <th>
                            <Button
                              color="danger"
                              className="btn btn-danger "
                              onClick={() => onDeletetRow(v.key)}
                            >
                              <i className="bx bx-x"></i>
                            </Button>
                          </th>
                          <th>
                            <Select
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: base => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              type="select"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name={"material_id" + k}
                              value={dataMaterialStocks.filter(function (
                                option
                              ) {
                                return option.value == v.material_id
                              })}
                              onChange={e => {
                                let newData = [...tableData]
                                newData[k].material_id = e.value
                                newData[k].material_price = e.material_price
                                newData[k].name = e.label
                                newData[k].unit = e.unit
                                newData[k].material_type = e.material_type
                                setTableData(newData)
                              }}
                              options={dataMaterialStocks}
                            />
                          </th>
                          <th>
                            <Select
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: base => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              type="select"
                              className="react-select-container"
                              classNamePrefix="react-select"
                              name={"custom_project_id" + k}
                              value={dataCustomProject.filter(function (
                                option
                              ) {
                                return option.value == v.custom_project_id
                              })}
                              onChange={e => {
                                let newData = [...tableData]
                                newData[k].custom_project_id = e.value
                                newData[k].custom_project_name = e.label
                                newData[k].detail = e.detail
                                newData[k].group_id = e.group_id
                                newData[k].type = e.type
                                setTableData(newData)
                              }}
                              options={dataCustomProject}
                            />
                          </th>
                          <th>
                            <Input
                              type="text"
                              name={"unit" + k}
                              id={"unit" + k}
                              value={v.unit}
                              placeholder="หน่วย"
                              onChange={e => {
                                let newData = [...tableData]
                                newData[k].unit = e.target.value
                                setTableData(newData)
                              }}
                            />
                          </th>

                          {/* <th
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <Input
                              type="number"
                              name={"use" + k}
                              id={"use" + k}
                              value={v.use}
                              placeholder="ใช้"
                              onChange={e => {
                                let newData = [...tableData]
                                newData[k].use = e.target.value
                                setTableData(newData)
                              }}
                            />
                          </th> */}

                          <th
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <Input
                              type="number"
                              name={"material_price" + k}
                              id={"material_price" + k}
                              value={v.material_price}
                              placeholder="ราคา"
                              onChange={e => {
                                let newData = [...tableData]
                                newData[k].material_price = e.target.value
                                setTableData(newData)
                              }}
                            />
                          </th>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            Save
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
