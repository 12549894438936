import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import ExampleModalInfo from "components/Dialogs/dialogUser_info"

// users
import { isEmpty } from "lodash"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = React.useState(false)
  const [formData, setFormData] = React.useState({})
  const [actionModal_info_user, setActionModal_info_user] = React.useState({
    modal: false,
  })

  const [obj, setObj] = React.useState(
    JSON.parse(localStorage.getItem("authUser"))
  )

  React.useEffect(() => {
    if (!isEmpty(obj)) {
      setFormData(obj.me)
    }
  }, [obj])

  return (
    <React.Fragment>
      <ExampleModalInfo actionModal={actionModal_info_user} />

      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {!isEmpty(formData.img_profile) && (
            <img
              src={process.env.REACT_APP_SERVER + formData.path_img_profile}
              alt={process.env.REACT_APP_NAMECOMPANY}
              className="rounded-circle header-profile-user"
            />
          )}

          <span className="d-none d-xl-inline-block ms-2 me-1">
            {formData.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            onClick={e =>
              setActionModal_info_user({
                modal: true,
              })
            }
          >
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("ข้อมูลส่วนตัว")}
          </DropdownItem>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("ออกจากระบบ")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
