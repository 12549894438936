import {
  GET_BOQ_HOME_PROFILE,
  GET_BOQ_HOME_PROFILE_FAIL,
  GET_BOQ_HOME_PROFILE_SUCCESS,
  GET_BOQ_HOMES,
  GET_BOQ_HOMES_FAIL,
  GET_BOQ_HOMES_SUCCESS,
  ADD_NEW_BOQ_HOME,
  ADD_BOQ_HOME_SUCCESS,
  ADD_BOQ_HOME_FAIL,
  UPDATE_BOQ_HOME,
  UPDATE_BOQ_HOME_SUCCESS,
  UPDATE_BOQ_HOME_FAIL,
  DELETE_BOQ_HOME,
  DELETE_BOQ_HOME_SUCCESS,
  DELETE_BOQ_HOME_FAIL,
} from "./actionTypes"

export const getBoqHomes = (project_id, type) => ({
  type: GET_BOQ_HOMES,
  payload: { project_id, type },
})

export const getBoqHomesSuccess = boqhomes => ({
  type: GET_BOQ_HOMES_SUCCESS,
  payload: boqhomes,
})

export const addNewBoqHome = boqhome => ({
  type: ADD_NEW_BOQ_HOME,
  payload: boqhome,
})

export const addBoqHomeSuccess = boqhome => ({
  type: ADD_BOQ_HOME_SUCCESS,
  payload: boqhome,
})

export const addBoqHomeFail = error => ({
  type: ADD_BOQ_HOME_FAIL,
  payload: error,
})

export const getBoqHomesFail = error => ({
  type: GET_BOQ_HOMES_FAIL,
  payload: error,
})

export const getBoqHomeProfile = id => ({
  type: GET_BOQ_HOME_PROFILE,
  payload: id,
})

export const getBoqHomeProfileSuccess = boqhomeProfile => ({
  type: GET_BOQ_HOME_PROFILE_SUCCESS,
  payload: boqhomeProfile,
})

export const getBoqHomeProfileFail = error => ({
  type: GET_BOQ_HOME_PROFILE_FAIL,
  payload: error,
})

export const updateBoqHome = boqhome => ({
  type: UPDATE_BOQ_HOME,
  payload: boqhome,
})

// export const updateBoqHome = boqhome => console.log(boqhome);

export const updateBoqHomeSuccess = boqhome => ({
  type: UPDATE_BOQ_HOME_SUCCESS,
  payload: boqhome,
})

export const updateBoqHomeFail = error => ({
  type: UPDATE_BOQ_HOME_FAIL,
  payload: error,
})

export const deleteBoqHome = boqhome => ({
  type: DELETE_BOQ_HOME,
  payload: boqhome,
})

export const deleteBoqHomeSuccess = boqhome => ({
  type: DELETE_BOQ_HOME_SUCCESS,
  payload: boqhome,
})

export const deleteBoqHomeFail = error => ({
  type: DELETE_BOQ_HOME_FAIL,
  payload: error,
})
