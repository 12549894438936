import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_LIST_DESIGN_GROUPS,
  GET_LIST_DESIGN_GROUP_PROFILE,
  ADD_NEW_LIST_DESIGN_GROUP,
  DELETE_LIST_DESIGN_GROUP,
  UPDATE_LIST_DESIGN_GROUP,
} from "./actionTypes"

import {
  getListDesignGroupsSuccess,
  getListDesignGroupsFail,
  getListDesignGroupProfileSuccess,
  getListDesignGroupProfileFail,
  addListDesignGroupFail,
  addListDesignGroupSuccess,
  updateListDesignGroupSuccess,
  updateListDesignGroupFail,
  deleteListDesignGroupSuccess,
  deleteListDesignGroupFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getListDesignGroups,
  getListDesignGroupProfile,
  addNewListDesignGroup,
  updateListDesignGroup,
  deleteListDesignGroup,
} from "../../helpers/fakebackend_helper"

function* fetchListDesignGroups({ payload: active }) {
  try {
    const response = yield call(getListDesignGroups, active)
    // console.log(response);
    yield put(getListDesignGroupsSuccess(response))
  } catch (error) {
    yield put(getListDesignGroupsFail(error))
  }
}

function* fetchListDesignGroupProfile({ payload: id }) {
  // console.log(id)updateListDesignGroup
  try {
    const response = yield call(getListDesignGroupProfile, id)
    // console.log("Profile => ", response)
    yield put(getListDesignGroupProfileSuccess(response))
  } catch (error) {
    yield put(getListDesignGroupProfileFail(error))
  }
}

function* onUpdateListDesignGroup({ payload: designgroup }) {
  try {
    // let x = designgroup.get('formData')
    // console.log(x)
    const response = yield call(updateListDesignGroup, designgroup)
    yield put(updateListDesignGroupSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateListDesignGroupFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteListDesignGroup({ payload: designgroup }) {
  try {
    const response = yield call(deleteListDesignGroup, designgroup)
    yield put(deleteListDesignGroupSuccess(response))
  } catch (error) {
    yield put(deleteListDesignGroupFail(error))
  }
}

function* onAddNewListDesignGroup({ payload: designgroup }) {
  try {
    const response = yield call(addNewListDesignGroup, designgroup)
    yield put(addListDesignGroupSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addListDesignGroupFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* listStructure() {
  yield takeEvery(GET_LIST_DESIGN_GROUPS, fetchListDesignGroups)
  yield takeEvery(GET_LIST_DESIGN_GROUP_PROFILE, fetchListDesignGroupProfile)
  yield takeEvery(ADD_NEW_LIST_DESIGN_GROUP, onAddNewListDesignGroup)
  yield takeEvery(UPDATE_LIST_DESIGN_GROUP, onUpdateListDesignGroup)
  yield takeEvery(DELETE_LIST_DESIGN_GROUP, onDeleteListDesignGroup)
}

export default listStructure
