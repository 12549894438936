import {
  GET_SLIP_ON_PROJECT,
  GET_DETAIL_CARD,
  GET_SLIP_PROFILE,
  GET_SLIP_PROFILE_FAIL,
  GET_SLIP_PROFILE_SUCCESS,
  GET_SLIPS,
  GET_SLIPS_FAIL,
  GET_SLIPS_SUCCESS,
  GET_SLIPS_ONPROJECT_SUCCESS,
  ADD_NEW_SLIP,
  ADD_SLIP_SUCCESS,
  ADD_SLIP_FAIL,
  UPDATE_SLIP,
  UPDATE_SLIP_SUCCESS,
  UPDATE_SLIP_FAIL,
  DELETE_SLIP,
  DELETE_SLIP_SUCCESS,
  DELETE_SLIP_FAIL,
  UPLOAD_INFOPROJECTFILESLIP,
  GET_SLIP_SUM,
} from "./actionTypes"

export const getSlipOnProject = id => ({
  type: GET_SLIP_ON_PROJECT,
  payload: id,
})

export const getSlipprojects = id => ({
  type: GET_SLIPS,
  payload: id,
})

export const getSlipprojectsSuccess = slipprojects => ({
  type: GET_SLIPS_SUCCESS,
  payload: slipprojects,
})

export const getSlipOnProjectsSuccess = slipprojects => ({
  type: GET_SLIPS_ONPROJECT_SUCCESS,
  payload: slipprojects,
})

export const getSlipDetailCard = detailCard => ({
  type: GET_DETAIL_CARD,
  payload: detailCard,
})

export const getSlipSum = slipSum => ({
  type: GET_SLIP_SUM,
  payload: slipSum,
})

export const addNewSlipproject = slipproject => ({
  type: ADD_NEW_SLIP,
  payload: slipproject,
})

export const addSlipprojectSuccess = slipproject => ({
  type: ADD_SLIP_SUCCESS,
  payload: slipproject,
})

export const addSlipprojectFail = error => ({
  type: ADD_SLIP_FAIL,
  payload: error,
})

export const getSlipprojectsFail = error => ({
  type: GET_SLIPS_FAIL,
  payload: error,
})

export const getSlipprojectProfile = id => ({
  type: GET_SLIP_PROFILE,
  payload: id,
})

export const getSlipprojectProfileSuccess = slipprojectProfile => ({
  type: GET_SLIP_PROFILE_SUCCESS,
  payload: slipprojectProfile,
})

export const getSlipprojectProfileFail = error => ({
  type: GET_SLIP_PROFILE_FAIL,
  payload: error,
})

export const updateSlipproject = slipproject => ({
  type: UPDATE_SLIP,
  payload: slipproject,
})

// export const updateSlipproject = slipproject => console.log(slipproject);

export const updateSlipprojectSuccess = slipproject => ({
  type: UPDATE_SLIP_SUCCESS,
  payload: slipproject,
})

export const updateSlipprojectFail = error => ({
  type: UPDATE_SLIP_FAIL,
  payload: error,
})

export const deleteSlipproject = slipproject => ({
  type: DELETE_SLIP,
  payload: slipproject,
})

export const deleteSlipprojectSuccess = slipproject => ({
  type: DELETE_SLIP_SUCCESS,
  payload: slipproject,
})

export const deleteSlipprojectFail = error => ({
  type: DELETE_SLIP_FAIL,
  payload: error,
})

export const uploadImgInfoSlip = dataImg => ({
  type: UPLOAD_INFOPROJECTFILESLIP,
  payload: dataImg,
})
