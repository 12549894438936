import {
  GET_INFOPROJECT_PROFILE,
  GET_INFOPROJECT_PROFILE_FAIL,
  GET_INFOPROJECT_PROFILE_SUCCESS,
  GET_INFOPROJECTS,
  GET_INFOPROJECTS_FAIL,
  GET_INFOPROJECTS_SUCCESS,
  ADD_NEW_INFOPROJECT,
  ADD_INFOPROJECT_SUCCESS,
  ADD_INFOPROJECT_FAIL,
  UPDATE_INFOPROJECT,
  UPDATE_INFOPROJECT_SUCCESS,
  UPDATE_INFOPROJECT_FAIL,
  DELETE_INFOPROJECT,
  DELETE_INFOPROJECT_SUCCESS,
  DELETE_INFOPROJECT_FAIL,
  UPLOAD_INFOPROJECTFILEHOME,
  LOADING_INFOPROJECTIMG_SUCCESS,
  LOADING_INFOPROJECTIMG_FAIL,
} from "./actionTypes"

export const getInfoProjects = () => ({
  type: GET_INFOPROJECTS,
})

export const getInfoProjectsSuccess = infoprojects => ({
  type: GET_INFOPROJECTS_SUCCESS,
  payload: infoprojects,
})

export const addNewInfoProject = infoproject => ({
  type: ADD_NEW_INFOPROJECT,
  payload: infoproject,
})

export const addInfoProjectSuccess = infoproject => ({
  type: ADD_INFOPROJECT_SUCCESS,
  payload: infoproject,
})

export const addInfoProjectFail = error => ({
  type: ADD_INFOPROJECT_FAIL,
  payload: error,
})

export const getInfoProjectsFail = error => ({
  type: GET_INFOPROJECTS_FAIL,
  payload: error,
})

export const getInfoProjectProfile = id => ({
  type: GET_INFOPROJECT_PROFILE,
  payload: id,
})

export const getInfoProjectProfileSuccess = infoprojectProfile => ({
  type: GET_INFOPROJECT_PROFILE_SUCCESS,
  payload: infoprojectProfile,
})

export const getInfoProjectProfileFail = error => ({
  type: GET_INFOPROJECT_PROFILE_FAIL,
  payload: error,
})

export const updateInfoProject = infoproject => ({
  type: UPDATE_INFOPROJECT,
  payload: infoproject,
})

// export const updateInfoProject = infoproject => console.log(infoproject);

export const updateInfoProjectSuccess = infoproject => ({
  type: UPDATE_INFOPROJECT_SUCCESS,
  payload: infoproject,
})

export const updateInfoProjectFail = error => ({
  type: UPDATE_INFOPROJECT_FAIL,
  payload: error,
})

export const deleteInfoProject = infoproject => ({
  type: DELETE_INFOPROJECT,
  payload: infoproject,
})

export const deleteInfoProjectSuccess = infoproject => ({
  type: DELETE_INFOPROJECT_SUCCESS,
  payload: infoproject,
})

export const deleteInfoProjectFail = error => ({
  type: DELETE_INFOPROJECT_FAIL,
  payload: error,
})

//Upload File
export const uploadImgHome = dataImg => ({
  type: UPLOAD_INFOPROJECTFILEHOME,
  payload: dataImg,
})

export const uploadImgHomeSuccess = status => ({
  type: LOADING_INFOPROJECTIMG_SUCCESS,
  payload: status,
})

export const uploadImgHomeFail = status => ({
  type: LOADING_INFOPROJECTIMG_FAIL,
  payload: status,
})
