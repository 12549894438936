import {
  GET_BOQ_TITLE_HOMES_SUCCESS,
  GET_BOQ_TITLE_HOMES_FAIL,
  ADD_BOQ_TITLE_HOME_SUCCESS,
  ADD_BOQ_TITLE_HOME_FAIL,
  UPDATE_BOQ_TITLE_HOME_SUCCESS,
  UPDATE_BOQ_TITLE_HOME_FAIL,
  DELETE_BOQ_TITLE_HOME_SUCCESS,
  DELETE_BOQ_TITLE_HOME_FAIL,
  GET_BOQ_TITLE_HOME_PROFILE_SUCCESS,
  GET_BOQ_TITLE_HOME_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  boqTitleHomes: [],
  boqTitleHomeProfile: {},
  error: {},
}

const boqTitleHomes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BOQ_TITLE_HOMES_SUCCESS:
      // console.log(action)
      return {
        ...state,
        boqTitleHomes: action.payload,
      }

    case GET_BOQ_TITLE_HOMES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_BOQ_TITLE_HOME_SUCCESS:
      return {
        ...state,
        boqTitleHomes: [...state.boqTitleHomes, action.payload],
      }

    case ADD_BOQ_TITLE_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BOQ_TITLE_HOME_PROFILE_SUCCESS:
      // console.log("GET_BOQ_TITLE_HOME_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        boqTitleHomeProfile: action.payload,
      }

    case UPDATE_BOQ_TITLE_HOME_SUCCESS:
      return {
        ...state,
        boqTitleHomes: state.boqTitleHomes.map(boqtitlehome =>
          boqtitlehome.id.toString() === action.payload.id.toString()
            ? { boqtitlehome, ...action.payload }
            : boqtitlehome
        ),
      }

    case UPDATE_BOQ_TITLE_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BOQ_TITLE_HOME_SUCCESS:
      return {
        ...state,
        boqTitleHomes: state.boqTitleHomes.filter(
          boqtitlehome =>
            boqtitlehome.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_BOQ_TITLE_HOME_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BOQ_TITLE_HOME_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default boqTitleHomes
