import React from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardTitle,
  CardBody,
  CardImg,
  CardImgOverlay,
  Col,
  Label,
  InputGroup,
  Input,
  Container,
  Row,
} from "reactstrap"
import { isEmpty, map, set } from "lodash"

// RangeSlider
import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"

//Import Product Images
import Navbar from "../Navbar/Navbar"
import Footer from "../../Crypto/CryptoIcoLanding/Footer/footer"

import {
  getDesignHomes as onGetDesignHomes,
  addNewDesignHome as onAddNewDesignHome,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

const HomeProducts = props => {
  const [search, setSearch] = React.useState("")
  const [searchMoney, setSearchMoney] = React.useState([150000, 1000000])
  const dispatch = useDispatch()
  const obj = JSON.parse(localStorage.getItem("authUser"))
  const [creatNew, setCreateNew] = React.useState(false)

  const { designhomes } = useSelector(state => ({
    designhomes: state.designHomes.designhomes,
  }))

  const { designhomeProfile } = useSelector(state => ({
    designhomeProfile: state.designHomes.designhomeProfile,
  }))

  // eslint-disable-next-line no-unused-vars
  const [FilterClothes, setFilterClothes] = React.useState([
    { id: 1, name: "HashTag", link: "#" },
  ])
  const [productList, setProductList] = React.useState([])

  React.useEffect(() => {
    if (isEmpty(designhomes)) {
      dispatch(onGetDesignHomes("landing"))
    }
  }, [dispatch])

  React.useEffect(() => {
    if (!isEmpty(designhomes)) {
      setTimeout(() => {
        setProductList(designhomes)
      }, 500)
    }
  }, [designhomes])

  function onUpdate(render, handle, value) {
    // const searchStr = search.toLowerCase()
    setSearch("")
    setSearchMoney([value[0], value[1]])
    setProductList(
      designhomes.filter(
        product => product.price >= value[0] && product.price <= value[1]
      )
    )
    // console.log(search)
    // console.log(searchMoney)
  }

  React.useEffect(() => {
    // console.log(search)
    // console.log(searchMoney)

    const searchStr = search.toLowerCase()
    setProductList(
      designhomes.filter(
        product =>
          product.code.toLowerCase().includes(searchStr) ||
          product.name.toLowerCase().includes(searchStr)
      )
    )
  }, [search])

  React.useEffect(() => {
    if (creatNew == true) {
      // console.log(designhomeProfile.id)
      // if (!isEmpty(designhomeProfile.id)) {
      //   console.log(designhomeProfile.id);
      const win = window.open(
        `/home-product-detail/${designhomeProfile.id}`,
        "_blank"
      )
      win.focus()
      setCreateNew(false)
      // }
    }
  }, [designhomeProfile])

  // useEffect(() => {
  // console.log(action)
  // console.log(id)
  // }, [id])

  function handleHomeClick(type, id) {
    if (obj == undefined) {
      const win = window.open(`/home-product-detail/${id}`, "_blank")
      win.focus()
    } else {
      if (type == "Add") {
        if (obj != undefined) {
          const data = new FormData()
          data.append("formData", {})
          dispatch(onAddNewDesignHome(data))
          setCreateNew(true)
        }
      } else if (type == "Edit") {
        const win = window.open(`/home-product-detail/${id}`, "_blank")
        win.focus()
      }
    }
  }

  return (
    <React.Fragment>
      <style scoped>
        {
          "\
            @media (min-width: 200px) and (max-width: 500px) {\
              .box-custom{\
                margin-top: 20px;\
              }\
            }\
          }\
          "
        }
      </style>

      <div className="page-content">
        <MetaTags>
          {/* <title>Homes | Skote - React Admin & Dashboard Template</title> */}
          <title>Homes | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>
        <Navbar />

        <Container fluid>
          {/* <Breadcrumbs title="Home" breadcrumbItem="Products" /> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <div>
                    <h5 className="font-size-14 mb-3">Home Types</h5>
                    // Render Cloth Categories
                    <ul className="list-unstyled product-list">
                      {FilterClothes.map((cloth, key) => (
                        <li key={"_li_" + key}>
                          <Link to={cloth.link}>
                            <i className="mdi mdi-chevron-right me-1" />
                            {cloth.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div> */}

                  <Row>
                    <Col xs="12" sm="12" md="8" lg="8">
                      {/* {!isEmpty(designhomes) ? (
                        <Nouislider
                          style={{
                            transform: "scaleY(1.4)",
                            marginTop: "5px",
                          }}
                          handleAttributes={[
                            { "aria-label": "ราคาเริ่มต้น" },
                            { "aria-label": "ราคาสิ้นสุด" },
                          ]}
                          range={{ min: 150000, max: 1500000 }}
                          tooltips={true}
                          start={[150000, 1000000]}
                          connect
                          onSlide={onUpdate}
                        />
                      ) : null} */}
                    </Col>

                    <Col xs="12" sm="12" md="4" lg="4" className="box-custom">
                      <InputGroup
                        className="input-group-sm"
                        style={{
                          paddingRight: "8px",
                        }}
                      >
                        <Label className="input-group-text">
                          <i className="fas fa-search"></i>
                        </Label>
                        <Input
                          type="text"
                          value={search || ""}
                          onChange={e => {
                            setSearch(e.target.value)
                          }}
                          className="form-control"
                        />
                        <Label
                          className="input-group-text"
                          onClick={() => {
                            setSearch("")
                          }}
                        >
                          <i className="fas fa-ban"></i>
                        </Label>
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col lg="12">
              <div className="row-product">
                {!isEmpty(productList) &&
                  productList.map((product, key) => (
                    <div className="item-product" key={key}>
                      {/* <div className="well-product"> */}
                      <Card
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          // history.push(`/home-product-detail/${product.id}`)
                          handleHomeClick("Edit", product.id)
                        }
                      >
                        <CardImg
                          className="img-fluid rounded"
                          src={process.env.REACT_APP_SERVER + product.path_img}
                          alt={process.env.REACT_APP_NAMECOMPANY}
                        />
                        <CardImgOverlay>
                          <Link
                            to={"/home-product-detail/" + product.id}
                            className="text-white"
                          >
                            <CardTitle className="text-white mt-0">
                              {product.price_type == "ลดราคา" ? (
                                <div className="avatar-sm product-ribbon">
                                  <b
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "100",
                                    }}
                                    className="avatar-title rounded-circle bg-primary"
                                  >
                                    {`-${product.offer}%`}
                                  </b>
                                </div>
                              ) : null}
                            </CardTitle>
                            <div
                              style={
                                product.poisson == 1
                                  ? {
                                      position: "absolute",
                                      top: "0px",
                                      padding: "3px",
                                    }
                                  : product.poisson == 2
                                  ? {
                                      position: "absolute",
                                      bottom: "0px",
                                      padding: "3px",
                                    }
                                  : product.poisson == 3
                                  ? {
                                      position: "absolute",
                                      bottom: "0px",
                                      right: "0px",
                                      padding: "3px",
                                    }
                                  : null
                              }
                            >
                              <b
                                style={{ fontSize: "16px", fontWeight: "100" }}
                                className="text-white"
                              >
                                {product.name == "null" ? null : product.name}
                              </b>

                              <b
                                className="my-0 text-white"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "100",
                                  textAlignLast:
                                    product.poisson == 3 ? "right" : "left",
                                }}
                              >
                                {product.price_type == "ลดราคา" ? (
                                  <div>
                                    <b
                                      style={{
                                        fontSize: "13px",
                                        color: "#f46a6a",
                                        fontWeight: "100",
                                      }}
                                    >
                                      <del>
                                        {parseFloat(
                                          product.price
                                        ).toLocaleString("th-TH", {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                        })}
                                      </del>
                                    </b>
                                    <br />
                                    {!isEmpty(product.price) ? (
                                      <b
                                        style={{
                                          fontWeight: "100",
                                        }}
                                      >
                                        {parseFloat(
                                          (parseFloat(product.price) / 100) *
                                            (100 - product.offer)
                                        ).toLocaleString("th-TH", {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                        })}
                                        &nbsp; บ.
                                      </b>
                                    ) : (
                                      0
                                    )}
                                  </div>
                                ) : (
                                  <p
                                    style={{
                                      marginBottom: "0px",
                                      fontWeight: "100",
                                    }}
                                  >
                                    {parseFloat(product.price).toLocaleString(
                                      "th-TH",
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      }
                                    )}
                                    &nbsp; บ.
                                  </p>
                                )}
                              </b>

                              {/* <b
                                className="my-0 text-white"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "100",
                                  textAlignLast:
                                    product.poisson == 3 ? "right" : "left",
                                }}
                              >
                                {product.detail == "null"
                                  ? null
                                  : product.detail}
                              </b> */}
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "100",
                                  marginBottom: "0px",
                                  textAlignLast:
                                    product.poisson == 3 ? "right" : "left",
                                }}
                                className="text-dark"
                              >
                                {product.code == "null" ? null : product.code}
                              </p>
                            </div>
                          </Link>
                        </CardImgOverlay>
                      </Card>
                      {/* </div> */}
                    </div>
                  ))}

                {!isEmpty(obj) ? (
                  <div
                    className="item-product"
                    onClick={() => handleHomeClick("Add", null)}
                    style={{ cursor: "pointer" }}
                  >
                    {/* <div className="well-product"> */}
                    <Card onClick={() => handleHomeClick("Add", null)}>
                      <CardBody
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          style={{
                            fontSize: "-webkit-xxx-large",
                            color: "#34c38f",
                          }}
                          className="mdi mdi-plus-circle-outline"
                        />
                      </CardBody>
                    </Card>
                    {/* </div> */}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  )
}

HomeProducts.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  // onGetProducts: PropTypes.func,
}

export default withRouter(HomeProducts)
