import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap"

import classnames from "classnames"
import { isEmpty, set } from "lodash"
import Dropzone from "react-dropzone"
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import imageCompression from "browser-image-compression"
import Editable from "react-bootstrap-editable"
import Select from "react-select"
import ExampleModalProductGroups from "../../components/Dialogs/dialogProduct_groups"

import { getProductGroups as onGetProductGroups } from "store/product-groups/actions"

import {
  getDesignHomeProfile as onGetDesignHomeProfile,
  updateDesignHome as onUpdateDesignHome,
  uploadDesignHomeImg as onUploadImgHomeImg,
  changeFirstDesignHome as onChangeFirstDesignHome,
  delImgDesignHome as onDelImgDesignHome,
} from "store/design-homes/actions"

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const [activeTab, setActiveTab] = React.useState(0)
  const [product, setProduct] = React.useState({})
  const [option, setOption] = React.useState([])
  const [slide_imgs, setSlide_imgs] = React.useState([])
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [photoIndex, setphotoIndex] = React.useState(0)
  const [isGallery, setisGallery] = React.useState(false)
  const [isUpload, setIsUpload] = React.useState(false)
  const [modal, setModal] = React.useState(false)
  const [action, setAction] = React.useState("")
  const [groups, setGroups] = React.useState([])
  const dispatch = useDispatch()
  const { designhomeProfile } = useSelector(state => ({
    designhomeProfile: state.designHomes.designhomeProfile,
  }))
  const { productGroups } = useSelector(state => ({
    productGroups: state.productGroups.productGroups,
  }))

  const [actionModalProductGroups, setActionModalProductGroups] =
    React.useState({
      id: null,
      action: null,
      modal: false,
    })

  React.useEffect(async () => {
    // console.log(actionModal);
    if (actionModal.modal) {
      setAction(actionModal.action)
      setModal(actionModal.modal)
      dispatch(onGetProductGroups(1))
      dispatch(onGetDesignHomeProfile(actionModal.id))
    }
  }, [actionModal])

  React.useEffect(async () => {
    // console.log(actionModal);
    if (actionModal.modal) {
      const options = [
        {
          value: "-1",
          label: "เพิ่มใหม่",
        },
      ]
      for (let index = 0; index < productGroups.length; index++) {
        options.push({
          value: productGroups[index].id,
          label: productGroups[index].name,
        })
      }
      setGroups(options)
    }
  }, [productGroups])

  React.useEffect(() => {
    if (!isEmpty(designhomeProfile) && actionModal.modal) {
      let { slide_imgs, option, ...rest } = designhomeProfile
      // console.log(rest)
      setProduct(rest)
      setSlide_imgs(slide_imgs)
      setOption(option)
      setIsUpload(false)
      setisGallery(false)
    }
    return () => {}
  }, [designhomeProfile]) // Only re-run the effect if count changes

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  function handleSubmit() {
    setButtonSubmit(true)
    const data = new FormData()
    data.append("formData", JSON.stringify(product))
    data.append("option", JSON.stringify(option))
    dispatch(onUpdateDesignHome(data))
    setModal(!actionModal.modal)
    setButtonSubmit(false)
  }

  async function onUploadImg(e) {
    if (e.target.files[0]) {
      setIsUpload(true)
      const files = e.target.files
      const data = new FormData()
      data.append("slidershop_id", product.id)
      for (let i = 0; i < files.length; i++) {
        data.append(`slide_imgs[${i}]`, await resizeImageFn(files[i]))
      }
      dispatch(onUploadImgHomeImg(data))
    }
  }
  async function resizeImageFn(file) {
    // console.log("originalFile instanceof Blob", file instanceof Blob) // true
    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options)
      // console.log(
      //   "compressedFile instanceof Blob",
      //   compressedFile instanceof Blob
      // ) // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

      // await uploadToServer(compressedFile) // write your own logic
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }
  function addRow() {
    setOption([...option, { key: option.length, name: "null" }])
  }
  function handleRemoveItem(index) {
    // assigning the list to temp variable
    const temp = [...option]
    // removing the element using splice
    temp.splice(index, 1)
    // updating the list
    setOption(temp)
  }

  async function handleAcceptedFiles(files) {
    setIsUpload(true)
    const data = new FormData()
    data.append("slidershop_id", product.id)
    for (let i = 0; i < files.length; i++) {
      // data.append(`slide_imgs[${i}]`, files[i])
      data.append(`slide_imgs[${i}]`, await resizeImageFn(files[i]))
    }
    dispatch(onUploadImgHomeImg(data))
  }

  function changeImgFirst(id) {
    dispatch(onChangeFirstDesignHome(id))
  }

  function delImg(id) {
    dispatch(onDelImgDesignHome(id))
  }

  return (
    <React.Fragment>
      <style scoped>
        {
          "\
          .form-inline{\
            display: flex;\
          }\
          .mt-auto {\
            margin-left: 10px;\
          }\
          .upload-photo {\
            opacity: 0;\
            position: absolute;\
            z-index: -1;\
          }\
          .showme {\
            display: none;\
          }\
          .showhim:hover .showme {\
            display: block;\
          }\
          .upload-loding{\
            height: 100vh;\
            display: flex;\
            justify-content: center;\
            align-items: center;\
            }\
          "
        }
      </style>

      <ExampleModalProductGroups actionModal={actionModalProductGroups} />

      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        centered={true}
        size="lg"
        style={{ maxWidth: "90%" }}
      >
        <ModalHeader toggle={() => setModal(!modal)}>
          เพิ่ม/แก้ไข สินค้า
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            {/* <Breadcrumbs title="Home" breadcrumbItem="Product Detail" /> */}

            {isGallery ? (
              <Lightbox
                mainSrc={
                  process.env.REACT_APP_SERVER + slide_imgs[photoIndex].path_img
                }
                nextSrc={
                  process.env.REACT_APP_SERVER +
                  slide_imgs[(photoIndex + 1) % slide_imgs.length].path_img
                }
                prevSrc={
                  process.env.REACT_APP_SERVER +
                  slide_imgs[
                    (photoIndex + slide_imgs.length - 1) % slide_imgs.length
                  ].path_img
                }
                enableZoom={false}
                toolbarButtons={[
                  <div key={photoIndex}>
                    <h5
                      className="pb-1"
                      // onClick={() => console.log(slide_imgs[photoIndex].id)}
                      onClick={() => {
                        changeImgFirst(slide_imgs[photoIndex].id)
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i
                        style={{ fontSize: "20px", color: "white" }}
                        className="bx bx-images"
                      ></i>
                    </h5>
                  </div>,
                  <div key={photoIndex}>
                    <h5
                      className="pb-1"
                      // onClick={() => console.log(slide_imgs[photoIndex].id)}
                      onClick={() => {
                        delImg(slide_imgs[photoIndex].id)
                      }}
                      style={{
                        cursor: "pointer",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <i
                        style={{ fontSize: "20px", color: "white" }}
                        className="bx bxs-trash-alt"
                      ></i>
                    </h5>
                  </div>,
                ]}
                onCloseRequest={() => {
                  setisGallery(false)
                }}
                onMovePrevRequest={() => {
                  setphotoIndex(
                    (photoIndex + slide_imgs.length - 1) % slide_imgs.length
                  )
                }}
                onMoveNextRequest={() => {
                  setphotoIndex((photoIndex + 1) % slide_imgs.length)
                }}
                imageCaption={
                  process.env.REACT_APP_NAMECOMPANY +
                  ": " +
                  parseFloat(photoIndex + 1)
                }
              />
            ) : null}

            {!isEmpty(product) && (
              <Row>
                <Col>
                  <Row>
                    {/* {isUpload == true ? (
                        <Col xl="6">
                          <Spinner className="ms-2 " color="primary" />
                        </Col>
                      ) : ( */}
                    <Col xs="12">
                      <FormGroup className="mt-2" style={{ float: "right" }}>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="type"
                              checked={product.type == 1}
                              value={1}
                              onChange={e => {
                                setProduct([])
                                setSlide_imgs([])
                                setOption([])
                                setProduct({
                                  id: actionModal.id,
                                  type: e.target.value,
                                  poisson: 1,
                                })
                              }}
                            />
                            แบบบ้าน
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label>
                            <Input
                              type="radio"
                              name="type"
                              checked={product.type == 2}
                              value={2}
                              onChange={e => {
                                setProduct([])
                                setSlide_imgs([])
                                setOption([])
                                setProduct({
                                  id: actionModal.id,
                                  type: e.target.value,
                                  poisson: 1,
                                })
                              }}
                            />
                            แบบบ้านสำเร็จรูป
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label>
                            <Input
                              type="radio"
                              name="type"
                              checked={product.type == 3}
                              value={3}
                              onChange={e => {
                                setProduct([])
                                setSlide_imgs([])
                                setOption([])
                                setProduct({
                                  id: actionModal.id,
                                  type: e.target.value,
                                  poisson: 3,
                                })
                              }}
                            />
                            อื่น ๆ
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                    {product.type == 1 ? (
                      <Row>
                        <Col xl="6">
                          {isUpload == true ? (
                            <div className="upload-loding">
                              <Spinner className="ms-2 " color="primary" />
                            </div>
                          ) : (
                            <div className="product-detai-imgs">
                              <Row>
                                <Col md="12" xs="12">
                                  <TabContent activeTab={activeTab}>
                                    {slide_imgs &&
                                      slide_imgs.map((item, i) => (
                                        <TabPane tabId={i} key={i}>
                                          <img
                                            src={
                                              process.env.REACT_APP_SERVER +
                                              item.path_img
                                            }
                                            style={{ cursor: "pointer" }}
                                            alt={
                                              process.env.REACT_APP_NAMECOMPANY
                                            }
                                            id={"expandedImg" + i}
                                            name={"expandedImg" + i}
                                            onClick={() => {
                                              setisGallery(true)
                                              setphotoIndex(i)
                                            }}
                                            width="100%"
                                            className="img-fluid mx-auto d-block"
                                          />
                                        </TabPane>
                                      ))}
                                  </TabContent>
                                </Col>

                                {slide_imgs.length == 0 ? (
                                  <Col md="12" xs="12">
                                    <Dropzone
                                      multiple={true}
                                      accept="image/*"
                                      inputContent={(files, extra) =>
                                        extra.reject
                                          ? "Image files only"
                                          : "Drag Files"
                                      }
                                      onDrop={acceptedFiles => {
                                        handleAcceptedFiles(acceptedFiles)
                                      }}
                                      styles={{
                                        dropzoneReject: {
                                          borderColor: "red",
                                          backgroundColor: "#DAA",
                                        },
                                        inputLabel: (files, extra) =>
                                          extra.reject ? { color: "red" } : {},
                                      }}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="dz-message needsclick">
                                              <div className="mb-3">
                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                              </div>
                                              <h4>
                                                Drop files here or click to
                                                upload.
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                  </Col>
                                ) : (
                                  <Col md="12" xs="12">
                                    <Nav className="flex-row pl-10" pills>
                                      {slide_imgs &&
                                        slide_imgs.map((item, i) => (
                                          <NavItem key={i}>
                                            <NavLink
                                              className={classnames({
                                                active: activeTab == i,
                                              })}
                                              style={{
                                                padding: "3px",
                                                margin: "0xp",
                                              }}
                                              onClick={() => {
                                                toggleTab(i)
                                              }}
                                            >
                                              <img
                                                src={
                                                  process.env.REACT_APP_SERVER +
                                                  item.path_img
                                                }
                                                alt={
                                                  process.env
                                                    .REACT_APP_NAMECOMPANY
                                                }
                                                width="50px"
                                                height="50px"
                                                className="mx-auto d-block rounded"
                                              />
                                            </NavLink>
                                          </NavItem>
                                        ))}
                                      <NavItem>
                                        <NavLink>
                                          <Label
                                            htmlFor="formrow-InputZip"
                                            style={{ cursor: "pointer" }}
                                            color="primary"
                                            className="font-16 btn-block btn btn-primary"
                                          >
                                            <i className="mdi mdi-plus-circle-outline" />
                                          </Label>
                                          <Input
                                            style={{ display: "none" }}
                                            id="formrow-InputZip"
                                            accept="image/*"
                                            type="file"
                                            multiple={true}
                                            name="file_img"
                                            onChange={onUploadImg}
                                          />
                                        </NavLink>
                                      </NavItem>
                                    </Nav>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          )}
                        </Col>

                        <Col xl="6">
                          <div className="mt-4 mt-xl-3">
                            {/* {product.hashtag} */}

                            <p
                              className="mt-1 mb-3"
                              style={{
                                fontSize: "18px",
                                fontWeight: "100",
                              }}
                            >
                              {/* {product.code} */}
                              <div>
                                <Editable
                                  id="product.name"
                                  name="product.name"
                                  initialValue={product.name}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      name: e,
                                    })
                                  }}
                                  placement="top"
                                  label="ชื่อ"
                                  showText
                                  type="textfield"
                                />
                              </div>
                            </p>

                            <p
                              className="mt-1 mb-3"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              {/* {product.code} */}
                              <div>
                                <Editable
                                  id="product.code"
                                  name="product.code"
                                  initialValue={product.code}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      code: e,
                                    })
                                  }}
                                  placement="top"
                                  label="รหัส"
                                  showText
                                  type="textfield"
                                />
                              </div>
                            </p>

                            <p
                              className="mt-1 mb-3"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              <div>
                                <Editable
                                  id="product.hashtag"
                                  name="product.hashtag"
                                  initialValue={product.hashtag}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      hashtag: e,
                                    })
                                  }}
                                  placement="top"
                                  label="Hashtag"
                                  showText
                                  type="textfield"
                                />
                              </div>
                            </p>

                            <p
                              className="mt-1 mb-3"
                              style={{
                                fontSize: "16px",
                                fontWeight: "100",
                              }}
                            >
                              <div>
                                <Editable
                                  id="product.price"
                                  name="product.price"
                                  initialValue={product.price}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      price: e,
                                    })
                                  }}
                                  validate={value => {
                                    if (!Number(value)) {
                                      return "กรุณาใส่ตัวเลข"
                                    }
                                  }}
                                  onValidated={validValue => {}}
                                  placement="top"
                                  label="ราคา"
                                  showText
                                  type="textfield"
                                />
                              </div>
                            </p>
                            <p
                              className="mt-1 mb-3"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              <div>
                                <Editable
                                  id="product.offer"
                                  name="product.offer"
                                  initialValue={product.offer}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      offer: e,
                                    })
                                  }}
                                  validate={value => {
                                    if (!Number(value)) {
                                      return "กรุณาใส่ตัวเลข"
                                    }
                                  }}
                                  onValidated={validValue => {}}
                                  placement="top"
                                  label="ส่วนลด (%)"
                                  showText
                                  type="textfield"
                                />
                              </div>
                            </p>

                            <p
                              className="mt-1 mb-3"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              <div>
                                <span>
                                  ราคาหลังลด % : &nbsp;
                                  {Number(
                                    (parseInt(product.price) / 100) *
                                      (100 - product.offer)
                                  ).toLocaleString("th-TH", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  })}
                                </span>
                                <Editable
                                  id="product.reduced"
                                  name="product.reduced"
                                  initialValue={product.reduced}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      reduced: e,
                                    })
                                  }}
                                  validate={value => {
                                    if (!Number(value)) {
                                      return "กรุณาใส่ตัวเลข"
                                    }
                                  }}
                                  onValidated={validValue => {}}
                                  placement="top"
                                  label="ลดได้มากสุด (บาท)"
                                  showText
                                  type="textfield"
                                />
                              </div>
                              &emsp;
                            </p>

                            <p
                              className="mt-1 mb-3"
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              <div>
                                <Editable
                                  id="product.offer"
                                  name="product.offer"
                                  initialValue={product.detail}
                                  editText={<i className="fas fa-pen"></i>}
                                  mode="inline"
                                  onSubmit={e => {
                                    setProduct({
                                      ...product,
                                      detail: e,
                                    })
                                  }}
                                  placement="top"
                                  label="รายละเอียด"
                                  showText
                                  type="textarea"
                                />
                              </div>
                            </p>
                            <hr />
                            <Row className="mt2-3">
                              {option &&
                                option.map((item, i) => (
                                  <Col
                                    md="4"
                                    key={i}
                                    className="form-inline mt-1 mb-2"
                                  >
                                    <i
                                      className={classnames(
                                        "fa fa-caret-right",
                                        "font-size-16 align-middle text-primary me-2"
                                      )}
                                    />
                                    {/* {item.name} */}
                                    <div
                                      className="showhim"
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <Editable
                                        id="option.name"
                                        name="option.name"
                                        initialValue={item.name}
                                        editText={
                                          <i className="fas fa-pen"></i>
                                        }
                                        mode="inline"
                                        onSubmit={e => {
                                          let newArr = [...option]
                                          newArr[i].name = e
                                          setOption(newArr)
                                        }}
                                        placement="top"
                                        label="เพิ่มเติม"
                                        showText
                                        type="textfield"
                                      />
                                      <b
                                        className="text-danger showme"
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "2px",
                                          fontWeight: "100",
                                        }}
                                        onClick={() => handleRemoveItem(i)}
                                      >
                                        ลบ
                                      </b>
                                    </div>

                                    {/* </p> */}
                                  </Col>
                                ))}

                              <Col
                                md="4"
                                style={{ marginBottom: "-20px" }}
                                className="mb-1"
                              >
                                <i
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "13px",
                                  }}
                                  onClick={() => addRow()}
                                  className="mdi mdi-plus-circle-outline text-primary"
                                />
                              </Col>
                            </Row>
                          </div>
                          <hr />
                          <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                              <FormGroup className="mt-2">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioPrice_type"
                                      checked={product.poisson == 1}
                                      value={1}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          poisson: e.target.value,
                                        })
                                      }
                                    />
                                    <i className="fas fa-angle-up"></i>
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioPrice_type"
                                      checked={product.poisson == 2}
                                      value={2}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          poisson: e.target.value,
                                        })
                                      }
                                    />
                                    <i className="fas fa-angle-down"></i>
                                  </Label>
                                </FormGroup>

                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioPrice_type"
                                      checked={product.poisson == 3}
                                      value={3}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          poisson: e.target.value,
                                        })
                                      }
                                    />
                                    <i className="fas fa-angle-right"></i>
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>

                            {/* <Col xs={12} sm={12} md={6} lg={6}>
                              <FormGroup className="mt-2">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioPrice_type"
                                      checked={product.price_type == 1}
                                      value={1}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          price_type: e.target.value,
                                        })
                                      }
                                    />
                                    ไม่ลดราคา
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioPrice_type"
                                      checked={product.price_type == 2}
                                      value={2}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          price_type: e.target.value,
                                        })
                                      }
                                    />
                                    ลด
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col> */}

                            <Col xs={12} sm={12} md={6} lg={6}>
                              <FormGroup className="mt-2">
                                {/* <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radioActive"
                                      checked={product.active == 1}
                                      value={1}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          active: e.target.value,
                                        })
                                      }
                                    />
                                    แสดงหน้าร้าน
                                  </Label>
                                </FormGroup> */}
                                <FormGroup check inline>
                                  <Label>
                                    <Input
                                      type="radio"
                                      name="radioActive"
                                      checked={product.active == 2}
                                      value={2}
                                      onChange={e =>
                                        setProduct({
                                          ...product,
                                          active: e.target.value,
                                        })
                                      }
                                    />
                                    <i className="fas fa-trash-alt"></i>
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col xs="12" sm="12" md="8" lg="8"></Col>

                        <Col
                          xs="12"
                          sm="12"
                          md="4"
                          lg="4"
                          style={{ float: "right" }}
                        >
                          <Select
                            type="select"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            name="product_group_id"
                            value={groups.filter(function (option) {
                              return option.value == product.product_group_id
                            })}
                            onChange={e =>
                              e.value == "-1"
                                ? setActionModalProductGroups({
                                    action: "Add",
                                    id: null,
                                    modal: true,
                                  })
                                : setProduct({
                                    ...product,
                                    product_group_id: e.value,
                                  })
                            }
                            options={groups.map((item, i) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                          />
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="2">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              value={product.name}
                              id="product.name"
                              name="product.name"
                              placeholder="กรุณาใส่ชื่อ"
                              onChange={e =>
                                setProduct({
                                  ...product,
                                  name: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="product.name">ชื่อ</label>
                          </div>
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="2">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              value={product.unit}
                              id="product.unit"
                              name="product.unit"
                              placeholder="กรุณาใส่หน่วย"
                              onChange={e =>
                                setProduct({
                                  ...product,
                                  unit: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="product.unit">หน่วย</label>
                          </div>
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="3">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control"
                              value={product.cost}
                              id="product.cost"
                              name="product.cost"
                              placeholder="กรุณาใส่หน่วย"
                              onChange={e =>
                                setProduct({
                                  ...product,
                                  cost: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="product.cost">ราคาต้นทุน</label>
                          </div>
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="3">
                          <div className="form-floating">
                            <input
                              type="number"
                              value={product.price}
                              className="form-control"
                              id="product.price"
                              name="product.price"
                              placeholder="กรุณาใส่หน่วย"
                              onChange={e =>
                                setProduct({
                                  ...product,
                                  price: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="product.price">ราคาขาย</label>
                          </div>
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="2">
                          <div className="form-floating">
                            <input
                              type="number"
                              value={product.reduced}
                              className="form-control"
                              id="product.reduced"
                              name="product.reduced"
                              placeholder="กรุณาใส่หน่วย"
                              onChange={e =>
                                setProduct({
                                  ...product,
                                  reduced: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="product.reduced">ลดได้สุด</label>
                          </div>
                        </Col>
                      </Row>
                    )}

                    <div style={{ textAlign: "right", marginTop: 10 }}>
                      <Button
                        onClick={() => handleSubmit()}
                        type="button"
                        disabled={buttonSubmit}
                        color="secondary"
                      >
                        Save
                      </Button>
                    </div>
                  </Row>
                </Col>
              </Row>
            )}
            {/* <RecentProducts recentProducts={product.recentProducts} /> */}
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
