import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_EMPLOYMENTCONPROJECTS,
  GET_EMPLOYMENTCONPROJECT_PROFILE,
  ADD_NEW_EMPLOYMENTCONPROJECT,
  DELETE_EMPLOYMENTCONPROJECT,
  UPDATE_EMPLOYMENTCONPROJECT,
} from "./actionTypes"

import {
  getEmploymentConProjectsSuccess,
  getEmploymentConProjectsFail,
  getEmploymentConProjectProfileSuccess,
  getEmploymentConProjectProfileFail,
  addEmploymentConProjectFail,
  addEmploymentConProjectSuccess,
  updateEmploymentConProjectSuccess,
  updateEmploymentConProjectFail,
  deleteEmploymentConProjectSuccess,
  deleteEmploymentConProjectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getEmploymentConProjects,
  getEmploymentConProjectProfile,
  addNewEmploymentConProject,
  updateEmploymentConProject,
  deleteEmploymentConProject,
} from "../../helpers/fakebackend_helper"

function* fetchEmploymentConProjects({ payload: project_id }) {
  // console.log("fetchEmploymentConProjects", project_id)
  try {
    // console.log(1);
    const response = yield call(getEmploymentConProjects, project_id)
    // console.log("fetchEmploymentConProjects", response)
    yield put(getEmploymentConProjectsSuccess(response))
  } catch (error) {
    yield put(getEmploymentConProjectsFail(error))
  }
}

function* fetchEmploymentConProjectProfile({ payload: data }) {
  try {
    const response = yield call(getEmploymentConProjectProfile, data)
    // console.log(response);
    yield put(getEmploymentConProjectProfileSuccess(response))
  } catch (error) {
    yield put(getEmploymentConProjectProfileFail(error))
  }
}

function* onUpdateEmploymentConProject({ payload: employmentconproject }) {
  try {
    // let x = employmentconproject.get('formData')
    // console.log(x)
    const response = yield call(updateEmploymentConProject, employmentconproject)
    // console.log(response)
    // yield put(updateEmploymentConProjectSuccess({}))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateEmploymentConProjectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteEmploymentConProject({ payload: employmentconproject }) {
  try {
    const response = yield call(deleteEmploymentConProject, employmentconproject)
    yield put(deleteEmploymentConProjectSuccess(response))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ลบ",
      message: "ลบสำเร็จ",
    })
  } catch (error) {
    yield put(deleteEmploymentConProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ลบ",
      message: "ลบล้มเหลว",
    })
  }
}

function* onAddNewEmploymentConProject({ payload: employmentconproject }) {
  try {
    const response = yield call(addNewEmploymentConProject, employmentconproject)
    // console.log(response);
    yield put(addEmploymentConProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addEmploymentConProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* employmentconProjectsSaga() {
  yield takeEvery(GET_EMPLOYMENTCONPROJECTS, fetchEmploymentConProjects)
  yield takeEvery(
    GET_EMPLOYMENTCONPROJECT_PROFILE,
    fetchEmploymentConProjectProfile
  )
  yield takeEvery(ADD_NEW_EMPLOYMENTCONPROJECT, onAddNewEmploymentConProject)
  yield takeEvery(UPDATE_EMPLOYMENTCONPROJECT, onUpdateEmploymentConProject)
  yield takeEvery(DELETE_EMPLOYMENTCONPROJECT, onDeleteEmploymentConProject)
}

export default employmentconProjectsSaga
