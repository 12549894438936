import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

const subItems = [
  { id: 1, name: "* อิงราคาจากแบบมาตรฐาน สามารถปรับเปลี่ยนแบบแปลนได้" },
  { id: 2, name: "* ห้องน้ำ ระเบียง สั่งเพิ่มเติมได้" },
  { id: 3, name: "* ไม่รวมค่าขนส่ง ต่างจังหวัด คิดตามระยะทาง" },
  { id: 4, name: "* ประหยัดเวลาคุ้มค่า ไม่บานปลาย" },
  { id: 5, name: "* ใช้วัสดุเกรกพรีเมี่ยม ได้รับการรับรองมาตรฐานจากวิศวกร" },
  { id: 6, name: "* รับประกัน 1 ปี พร้อมบริการหลังการขาย" },
]

const Section = () => {
  return (
    <React.Fragment>
      {/* <section className="hero-section bg-ico-hero"  id="home"> */}
      <section className="hero-section bg-ico-hero" id="home">
        <Container>
          <Row className="align-items-center detail-screen-lg">
            <Col lg="12">
              <div className="text-white-50">
                <h3 className="text-white font-weight-semibold mb-3 hero-title">
                  Premium House
                </h3>
                <h4 className="text-white">
                  บ้านสำเร็จรูป โมดูล่า อาคารสำเร็จรูป สไตล์ มินิมอล
                </h4>

                <h4 className="text-white">
                  นอร์ดิก ทันสมัย ใส่ใจทุกดีไซน์ ราคาถูก
                </h4>

                <br />

                <h5
                  className="text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/PremiumHouseModular",
                      "_blank"
                    )
                  }}
                >
                  FB : PremiumHouseModular <span> (คลิก)</span>
                </h5>
                <br />
                {subItems.map(item => {
                  return (
                    <p
                      key={item.id}
                      className="text-white"
                      style={{
                        fontSize: "16px",
                        margin: "0",
                        marginBottom: "2px",
                      }}
                    >
                      {item.name}
                    </p>
                  )
                })}

                <br />
              </div>
              <div className="d-flex flex-wrap gap-2 mt-4">
                <Link to="/home-products" className="btn btn-success me-1">
                  ดูแบบบ้าน
                </Link>
                <Link to="/home-products" className="btn btn-light">
                  ดูแบบแปลน
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section pt-4 detail-screen-sm">
        <Container>
          <Row className="align-items-center p-2">
            <Col lg="12">
              <div className="text-text-50">
                <h3 className="text-text font-weight-semibold mb-3 hero-title">
                  Premium House
                </h3>
                <h4 className="text-text">
                  บ้านสำเร็จรูป โมดูล่า อาคารสำเร็จรูป สไตล์ มินิมอล นอร์ดิก
                  ทันสมัย ใส่ใจทุกดีไซน์ ราคาถูก
                </h4>
                <br />

                <h5
                  className="text-text"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/PremiumHouseModular",
                      "_blank"
                    )
                  }}
                >
                  FB : PremiumHouseModular
                </h5>
                <br />
                {subItems.map(item => {
                  return (
                    <p
                      key={item.id}
                      className="text-text"
                      style={{
                        fontSize: "16px",
                        margin: "0",
                        marginBottom: "2px",
                      }}
                    >
                      {item.name}
                    </p>
                  )
                })}

                <br />
              </div>
              <div className="d-flex flex-wrap gap-2 mt-4">
                <Link to="/home-products" className="btn btn-success me-1">
                  ดูแบบบ้าน
                </Link>
                <Link to="/home-products" className="btn btn-light">
                  ดูแบบแปลน
                </Link>
              </div>
              <br />
              <br />
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Section
