import {
  GET_LIST_AIUMINIASS_SUCCESS,
  GET_LIST_AIUMINIASS_FAIL,
  ADD_LIST_AIUMINIAS_SUCCESS,
  ADD_LIST_AIUMINIAS_FAIL,
  UPDATE_LIST_AIUMINIAS_SUCCESS,
  UPDATE_LIST_AIUMINIAS_FAIL,
  DELETE_LIST_AIUMINIAS_SUCCESS,
  DELETE_LIST_AIUMINIAS_FAIL,
  GET_LIST_AIUMINIAS_PROFILE_SUCCESS,
  GET_LIST_AIUMINIAS_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  listAluminias: [],
  listAluminiaProfile: {},
  error: {},
}

const listAluminias = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_AIUMINIASS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        listAluminias: action.payload,
      }

    case GET_LIST_AIUMINIASS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_LIST_AIUMINIAS_SUCCESS:
      return {
        ...state,
        listAluminias: [...state.listAluminias, action.payload],
      }

    case ADD_LIST_AIUMINIAS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_AIUMINIAS_PROFILE_SUCCESS:
      // console.log("GET_LIST_AIUMINIAS_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        listAluminiaProfile: action.payload,
      }

    case UPDATE_LIST_AIUMINIAS_SUCCESS:
      return {
        ...state,
        listAluminias: state.listAluminias.map(aluminia =>
          aluminia.id.toString() === action.payload.id.toString()
            ? { aluminia, ...action.payload }
            : aluminia
        ),
      }

    case UPDATE_LIST_AIUMINIAS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_LIST_AIUMINIAS_SUCCESS:
      return {
        ...state,
        listAluminias: state.listAluminias.filter(
          aluminia =>
            aluminia.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_LIST_AIUMINIAS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_AIUMINIAS_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default listAluminias
