/* CONTRACTCONPROJECTS */
export const GET_CONTRACTCONPROJECTS = "GET_CONTRACTCONPROJECTS"
export const GET_CONTRACTCONPROJECTS_SUCCESS = "GET_CONTRACTCONPROJECTS_SUCCESS"
export const GET_CONTRACTCONPROJECTS_FAIL = "GET_CONTRACTCONPROJECTS_FAIL"

/* CONTRACTCONPROJECTS PROFILE */
export const GET_CONTRACTCONPROJECT_PROFILE = "GET_CONTRACTCONPROJECT_PROFILE"
export const GET_CONTRACTCONPROJECT_PROFILE_SUCCESS = "GET_CONTRACTCONPROJECT_PROFILE_SUCCESS"
export const GET_CONTRACTCONPROJECT_PROFILE_FAIL = "GET_CONTRACTCONPROJECT_PROFILE_FAIL"

/**
 * add contactconproject
 */
export const ADD_NEW_CONTRACTCONPROJECT = "ADD_NEW_CONTRACTCONPROJECT"
export const ADD_CONTRACTCONPROJECT_SUCCESS = "ADD_CONTRACTCONPROJECT_SUCCESS"
export const ADD_CONTRACTCONPROJECT_FAIL = "ADD_CONTRACTCONPROJECT_FAIL"

/**
 * Edit contactconproject
 */
export const UPDATE_CONTRACTCONPROJECT = "UPDATE_CONTRACTCONPROJECT"
export const UPDATE_CONTRACTCONPROJECT_SUCCESS = "UPDATE_CONTRACTCONPROJECT_SUCCESS"
export const UPDATE_CONTRACTCONPROJECT_FAIL = "UPDATE_CONTRACTCONPROJECT_FAIL"

/**
 * Delete contactconproject
 */
export const DELETE_CONTRACTCONPROJECT = "DELETE_CONTRACTCONPROJECT"
export const DELETE_CONTRACTCONPROJECT_SUCCESS = "DELETE_CONTRACTCONPROJECT_SUCCESS"
export const DELETE_CONTRACTCONPROJECT_FAIL = "DELETE_CONTRACTCONPROJECT_FAIL"

