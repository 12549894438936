import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_REFPROJECTS,
  GET_REFPROJECT_PROFILE,
  ADD_NEW_REFPROJECT,
  DELETE_REFPROJECT,
  UPDATE_REFPROJECT,
  UPLOAD_INFOPROJECTFILEREF,
} from "./actionTypes"

import {
  getRefprojectsSuccess,
  getRefprojectsFail,
  getRefprojectProfileSuccess,
  getRefprojectProfileFail,
  addRefprojectFail,
  addRefprojectSuccess,
  updateRefprojectSuccess,
  updateRefprojectFail,
  deleteRefprojectSuccess,
  deleteRefprojectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getRefprojects,
  getRefprojectProfile,
  addNewRefproject,
  updateRefproject,
  deleteRefproject,
  uploadImgRefInfoProject,
} from "../../helpers/fakebackend_helper"

function* fetchRefprojects({ payload: id }) {
  try {
    const response = yield call(getRefprojects, id)
    // console.log(response);
    yield put(getRefprojectsSuccess(response))
  } catch (error) {
    yield put(getRefprojectsFail(error))
  }
}

function* fetchRefprojectProfile({ payload: id }) {
  // console.log(id)updateRefproject
  try {
    const response = yield call(getRefprojectProfile, id)
    yield put(getRefprojectProfileSuccess(response))
  } catch (error) {
    yield put(getRefprojectProfileFail(error))
  }
}

function* onUpdateRefproject({ payload: refproject }) {
  try {
    // let x = refproject.get('formData')
    // console.log(x)
    const response = yield call(updateRefproject, refproject)
    yield put(updateRefprojectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateRefprojectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
    // console.log("Error")
  }
}

function* onDeleteRefproject({ payload: refproject }) {
  try {
    const response = yield call(deleteRefproject, refproject)
    // console.log(response);
    yield put(deleteRefprojectSuccess(response))
    // yield put(getRefprojectProfileSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ลบ",
      message: "ลบสำเร็จ",
    })
  } catch (error) {
    yield put(deleteRefprojectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ลบ",
      message: "ลบล้มเหลว",
    })
  }
}

function* onAddNewRefproject({ payload: refproject }) {
  try {
    const response = yield call(addNewRefproject, refproject)
    yield put(addRefprojectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addRefprojectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* onUploadFileHomeRef({ payload: img }) {
  // console.log("load =>", img)
  try {
    const response = yield call(uploadImgRefInfoProject, img)
    // console.log(response)
    if (response.type == 4) {
      yield put(addRefprojectSuccess(response))
    } else {
      yield put(addRefprojectSuccess(response))
    }
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพโหลด",
      message: "อัพโหลดสำเร็จ",
    })
  } catch (error) {
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพโหลด",
      message: "อัพโหลดล้มเหลว",
    })
  }
}

function* refProjectsSaga() {
  yield takeEvery(GET_REFPROJECTS, fetchRefprojects)
  yield takeEvery(GET_REFPROJECT_PROFILE, fetchRefprojectProfile)
  yield takeEvery(ADD_NEW_REFPROJECT, onAddNewRefproject)
  yield takeEvery(UPDATE_REFPROJECT, onUpdateRefproject)
  yield takeEvery(DELETE_REFPROJECT, onDeleteRefproject)
  yield takeEvery(UPLOAD_INFOPROJECTFILEREF, onUploadFileHomeRef)
}

export default refProjectsSaga
