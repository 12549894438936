import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_BOQ_TITLE_HOMES,
  GET_BOQ_TITLE_HOME_PROFILE,
  ADD_NEW_BOQ_TITLE_HOME,
  DELETE_BOQ_TITLE_HOME,
  UPDATE_BOQ_TITLE_HOME,
} from "./actionTypes"

import {
  getBoqTitleHomesSuccess,
  getBoqTitleHomesFail,
  getBoqTitleHomeProfileSuccess,
  getBoqTitleHomeProfileFail,
  addBoqTitleHomeFail,
  addBoqTitleHomeSuccess,
  updateBoqTitleHomeSuccess,
  updateBoqTitleHomeFail,
  deleteBoqTitleHomeSuccess,
  deleteBoqTitleHomeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBoqTitleHomes,
  getBoqTitleHomeProfile,
  addNewBoqTitleHome,
  updateBoqTitleHome,
  deleteBoqTitleHome,
} from "../../helpers/fakebackend_helper"

function* fetchBoqTitleHomes({ payload: data }) {
  // console.log("data", data)
  try {
    const response = yield call(getBoqTitleHomes, data)
    // console.log("response", response)
    yield put(getBoqTitleHomesSuccess(response))
  } catch (error) {
    yield put(getBoqTitleHomesFail(error))
  }
}

function* fetchBoqTitleHomeProfile({ payload: id }) {
  // console.log("Profile => ", id)
  try {
    const response = yield call(getBoqTitleHomeProfile, id)
    // console.log(response)
    yield put(getBoqTitleHomeProfileSuccess(response))
  } catch (error) {
    yield put(getBoqTitleHomeProfileFail(error))
  }
}

function* onUpdateBoqTitleHome({ payload: boqtitlehome }) {
  try {
    // let x = boqtitlehome.get('formData')
    // console.log(x)
    const response = yield call(updateBoqTitleHome, boqtitlehome)
    // console.log("update", response)
    yield put(getBoqTitleHomesSuccess(response))
    // yield put(getBoqTitleHomesSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateBoqTitleHomeFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteBoqTitleHome({ payload: boqtitlehome }) {
  try {
    const response = yield call(deleteBoqTitleHome, boqtitlehome)
    // console.log("del", response)
    yield put(getBoqTitleHomesSuccess(response))
    // yield put(deleteBoqTitleHomeSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ลบ",
      message: "ลบสำเร็จ",
    })
  } catch (error) {
    yield put(deleteBoqTitleHomeFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ลบ",
      message: "ลบล้มเหลว",
    })
  }
}

function* onAddNewBoqTitleHome({ payload: boqtitlehome }) {
  try {
    const response = yield call(addNewBoqTitleHome, boqtitlehome)
    // console.log("response", response)
    yield put(getBoqTitleHomesSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addBoqTitleHomeFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* boqTitleHome() {
  yield takeEvery(GET_BOQ_TITLE_HOMES, fetchBoqTitleHomes)
  yield takeEvery(GET_BOQ_TITLE_HOME_PROFILE, fetchBoqTitleHomeProfile)
  yield takeEvery(ADD_NEW_BOQ_TITLE_HOME, onAddNewBoqTitleHome)
  yield takeEvery(UPDATE_BOQ_TITLE_HOME, onUpdateBoqTitleHome)
  yield takeEvery(DELETE_BOQ_TITLE_HOME, onDeleteBoqTitleHome)
}

export default boqTitleHome
