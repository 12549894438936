import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_LIST_PRODUCTS,
  GET_LIST_PRODUCT_PROFILE,
  ADD_NEW_LIST_PRODUCT,
  DELETE_LIST_PRODUCT,
  UPDATE_LIST_PRODUCT,
} from "./actionTypes"

import {
  getListProductsSuccess,
  getListProductsFail,
  getListProductProfileSuccess,
  getListProductProfileFail,
  addListProductFail,
  addListProductSuccess,
  updateListProductSuccess,
  updateListProductFail,
  deleteListProductSuccess,
  deleteListProductFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getListProducts,
  getListProductProfile,
  addNewListProduct,
  updateListProduct,
  deleteListProduct,
} from "../../helpers/fakebackend_helper"

function* fetchListProducts() {
  try {
    const response = yield call(getListProducts)
    // console.log(response);
    yield put(getListProductsSuccess(response))
  } catch (error) {
    yield put(getListProductsFail(error))
  }
}

function* fetchListProductProfile({ payload: id }) {
  // console.log(id)updateListProduct
  try {
    const response = yield call(getListProductProfile, id)
    // console.log("Profile => ", response)
    yield put(getListProductProfileSuccess(response))
  } catch (error) {
    yield put(getListProductProfileFail(error))
  }
}

function* onUpdateListProduct({ payload: product }) {
  try {
    // let x = product.get('formData')
    // console.log(x)
    const response = yield call(updateListProduct, product)
    // console.log(response);
    yield put(updateListProductSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateListProductFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteListProduct({ payload: product }) {
  try {
    const response = yield call(deleteListProduct, product)
    yield put(deleteListProductSuccess(response))
  } catch (error) {
    yield put(deleteListProductFail(error))
  }
}

function* onAddNewListProduct({ payload: product }) {
  try {
    const response = yield call(addNewListProduct, product)
    // console.log(response);
    yield put(addListProductSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addListProductFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* listProduct() {
  yield takeEvery(GET_LIST_PRODUCTS, fetchListProducts)
  yield takeEvery(GET_LIST_PRODUCT_PROFILE, fetchListProductProfile)
  yield takeEvery(ADD_NEW_LIST_PRODUCT, onAddNewListProduct)
  yield takeEvery(UPDATE_LIST_PRODUCT, onUpdateListProduct)
  yield takeEvery(DELETE_LIST_PRODUCT, onDeleteListProduct)
}

export default listProduct
