import {
  GET_MESSAGE_LIST_PROFILE,
  GET_MESSAGE_LIST_PROFILE_FAIL,
  GET_MESSAGE_LIST_PROFILE_SUCCESS,
  GET_MESSAGE_LISTS,
  GET_MESSAGE_LISTS_FAIL,
  GET_MESSAGE_LISTS_SUCCESS,
  ADD_NEW_MESSAGE_LIST,
  ADD_MESSAGE_LIST_SUCCESS,
  ADD_MESSAGE_LIST_FAIL,
  UPDATE_MESSAGE_LIST,
  UPDATE_MESSAGE_LIST_SUCCESS,
  UPDATE_MESSAGE_LIST_FAIL,
  DELETE_MESSAGE_LIST,
  DELETE_MESSAGE_LIST_SUCCESS,
  DELETE_MESSAGE_LIST_FAIL,
} from "./actionTypes"

export const getMessageLists = active => ({
  type: GET_MESSAGE_LISTS,
  payload: active,
})

export const getMessageListsSuccess = messagelist => ({
  type: GET_MESSAGE_LISTS_SUCCESS,
  payload: messagelist,
})

export const addNewMessageList = designgroup => ({
  type: ADD_NEW_MESSAGE_LIST,
  payload: designgroup,
})

export const addMessageListSuccess = designgroup => ({
  type: ADD_MESSAGE_LIST_SUCCESS,
  payload: designgroup,
})

export const addMessageListFail = error => ({
  type: ADD_MESSAGE_LIST_FAIL,
  payload: error,
})

export const getMessageListsFail = error => ({
  type: GET_MESSAGE_LISTS_FAIL,
  payload: error,
})

export const getMessageListProfile = id => ({
  type: GET_MESSAGE_LIST_PROFILE,
  payload: id,
})

export const getMessageListProfileSuccess = designgroupProfile => ({
  type: GET_MESSAGE_LIST_PROFILE_SUCCESS,
  payload: designgroupProfile,
})

export const getMessageListProfileFail = error => ({
  type: GET_MESSAGE_LIST_PROFILE_FAIL,
  payload: error,
})

export const updateMessageList = designgroup => ({
  type: UPDATE_MESSAGE_LIST,
  payload: designgroup,
})

// export const updateMessageList = designgroup => console.log(designgroup);

export const updateMessageListSuccess = designgroup => ({
  type: UPDATE_MESSAGE_LIST_SUCCESS,
  payload: designgroup,
})

export const updateMessageListFail = error => ({
  type: UPDATE_MESSAGE_LIST_FAIL,
  payload: error,
})

export const deleteMessageList = designgroup => ({
  type: DELETE_MESSAGE_LIST,
  payload: designgroup,
})

export const deleteMessageListSuccess = designgroup => ({
  type: DELETE_MESSAGE_LIST_SUCCESS,
  payload: designgroup,
})

export const deleteMessageListFail = error => ({
  type: DELETE_MESSAGE_LIST_FAIL,
  payload: error,
})
