/* SERWAYROJECTS */
export const GET_SERWAYROJECTS = "GET_SERWAYROJECTS"
export const GET_SERWAYROJECTS_SUCCESS = "GET_SERWAYROJECTS_SUCCESS"
export const GET_SERWAYROJECTS_FAIL = "GET_SERWAYROJECTS_FAIL"

/* SERWAYROJECTS PROFILE */
export const GET_SERWAYROJECT_PROFILE = "GET_SERWAYROJECT_PROFILE"
export const GET_SERWAYROJECT_PROFILE_SUCCESS = "GET_SERWAYROJECT_PROFILE_SUCCESS"
export const GET_SERWAYROJECT_PROFILE_FAIL = "GET_SERWAYROJECT_PROFILE_FAIL"

/**
 * add serwayproject
 */
export const ADD_NEW_SERWAYROJECT = "ADD_NEW_SERWAYROJECT"
export const ADD_SERWAYROJECT_SUCCESS = "ADD_SERWAYROJECT_SUCCESS"
export const ADD_SERWAYROJECT_FAIL = "ADD_SERWAYROJECT_FAIL"

/**
 * Edit serwayproject
 */
export const UPDATE_SERWAYROJECT = "UPDATE_SERWAYROJECT"
export const UPDATE_SERWAYROJECT_SUCCESS = "UPDATE_SERWAYROJECT_SUCCESS"
export const UPDATE_SERWAYROJECT_FAIL = "UPDATE_SERWAYROJECT_FAIL"

/**
 * Delete serwayproject
 */
export const DELETE_SERWAYROJECT = "DELETE_SERWAYROJECT"
export const DELETE_SERWAYROJECT_SUCCESS = "DELETE_SERWAYROJECT_SUCCESS"
export const DELETE_SERWAYROJECT_FAIL = "DELETE_SERWAYROJECT_FAIL"

/**
 * Upload File
 */
 export const UPLOAD_INFOPROJECTFILESERWAY = "UPLOAD_INFOPROJECTFILESERWAY"