import {
  GET_QUOTATIONCONPROJECT_PROFILE,
  GET_QUOTATIONCONPROJECT_PROFILE_FAIL,
  GET_QUOTATIONCONPROJECT_PROFILE_SUCCESS,
  GET_QUOTATIONCONPROJECTS,
  GET_QUOTATIONCONPROJECTS_FAIL,
  GET_QUOTATIONCONPROJECTS_SUCCESS,
  ADD_NEW_QUOTATIONCONPROJECT,
  ADD_QUOTATIONCONPROJECT_SUCCESS,
  ADD_QUOTATIONCONPROJECT_FAIL,
  UPDATE_QUOTATIONCONPROJECT,
  UPDATE_QUOTATIONCONPROJECT_SUCCESS,
  UPDATE_QUOTATIONCONPROJECT_FAIL,
  DELETE_QUOTATIONCONPROJECT,
  DELETE_QUOTATIONCONPROJECT_SUCCESS,
  DELETE_QUOTATIONCONPROJECT_FAIL,
} from "./actionTypes"

// export const getQuotationConProjects = project_id => ({
//   type: GET_QUOTATIONCONPROJECTS,
//   payload: project_id,
// })

export const getQuotationConProjects = project_id => ({
  type: GET_QUOTATIONCONPROJECTS,
  payload: project_id,
})

export const getQuotationConProjectsSuccess = quotationconprojects => ({
  type: GET_QUOTATIONCONPROJECTS_SUCCESS,
  payload: quotationconprojects,
})

export const addNewQuotationConProject = quotationconproject => ({
  type: ADD_NEW_QUOTATIONCONPROJECT,
  payload: quotationconproject,
})

export const addQuotationConProjectSuccess = quotationconproject => ({
  type: ADD_QUOTATIONCONPROJECT_SUCCESS,
  payload: quotationconproject,
})

export const addQuotationConProjectFail = error => ({
  type: ADD_QUOTATIONCONPROJECT_FAIL,
  payload: error,
})

export const getQuotationConProjectsFail = error => ({
  type: GET_QUOTATIONCONPROJECTS_FAIL,
  payload: error,
})

export const getQuotationConProjectProfile = (id, type) => ({
  type: GET_QUOTATIONCONPROJECT_PROFILE,
  payload: { id, type },
})

export const getQuotationConProjectProfileSuccess =
  quotationconprojectProfile => ({
    type: GET_QUOTATIONCONPROJECT_PROFILE_SUCCESS,
    payload: quotationconprojectProfile,
  })

export const getQuotationConProjectProfileFail = error => ({
  type: GET_QUOTATIONCONPROJECT_PROFILE_FAIL,
  payload: error,
})

export const updateQuotationConProject = quotationconproject => ({
  type: UPDATE_QUOTATIONCONPROJECT,
  payload: quotationconproject,
})

// export const updateQuotationConProject = quotationconproject => console.log(quotationconproject);

export const updateQuotationConProjectSuccess = quotationconproject => ({
  type: UPDATE_QUOTATIONCONPROJECT_SUCCESS,
  payload: quotationconproject,
})

export const updateQuotationConProjectFail = error => ({
  type: UPDATE_QUOTATIONCONPROJECT_FAIL,
  payload: error,
})

export const deleteQuotationConProject = quotationconproject => ({
  type: DELETE_QUOTATIONCONPROJECT,
  payload: quotationconproject,
})

export const deleteQuotationConProjectSuccess = quotationconproject => ({
  type: DELETE_QUOTATIONCONPROJECT_SUCCESS,
  payload: quotationconproject,
})

export const deleteQuotationConProjectFail = error => ({
  type: DELETE_QUOTATIONCONPROJECT_FAIL,
  payload: error,
})
