import React from "react"
import imageCompression from "browser-image-compression"
import { withRouter, Link } from "react-router-dom"
import { isEmpty, map } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import MetaTags from "react-meta-tags"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Table,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Input,
  CardTitle,
} from "reactstrap"
import Select from "react-select"
import { getMaterialStocks as onGetMaterialStocks } from "store/material-stocks/actions"
import { getProjects as onGetProjects } from "store/projects/actions"
import {
  getWithdrawMaterials as onGetWithdrawMaterials,
  addNewWithdrawMaterial as onAddNewWithdrawMaterial,
  getWithdrawMaterialProfile as onGetWithdrawMaterialProfile,
} from "store/withdraw-materials/actions"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Thai } from "flatpickr/dist/l10n/th.js"

//redux
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work

import pdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

//ต้องระบุตามชื่อของ ไฟล์ font
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
}

const PayMaterial = props => {
  const dispatch = useDispatch()

  const { materialStocks } = useSelector(state => ({
    materialStocks: state.materialStocks.materialStocks,
  }))

  const { check_boq_projects } = useSelector(state => ({
    check_boq_projects: state.projects.check_boq_projects,
  }))
  const { withdrawMaterials } = useSelector(state => ({
    withdrawMaterials: state.withdrawMaterials.withdrawMaterials,
  }))

  const { withdrawSummarizeMaterials } = useSelector(state => ({
    withdrawSummarizeMaterials:
      state.withdrawMaterials.withdrawSummarizeMaterials,
  }))

  const { withdrawMaterialProfile } = useSelector(state => ({
    withdrawMaterialProfile: state.withdrawMaterials.withdrawMaterialProfile,
  }))

  const [timeStart, setTimeStart] = React.useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm")
  )
  const [timeEnd, setTimeEnd] = React.useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm")
  )

  const [dataWithdraw_materials, setDataWithdraw_materials] = React.useState([])

  const [withdraw_materials, setWithdraw_materials] = React.useState("")
  const [name_contractor, setName_contractor] = React.useState(null)
  const [tel_contractor, setTel_contractor] = React.useState(null)

  const [action_withdraw_materials, setAction_withdraw_materials] =
    React.useState("Add")

  const [boq_title, setBoq_title] = React.useState([])
  const [boq_title_detail, setBoq_title_detail] = React.useState([])
  const [material_type_id, setMaterial_type_id] = React.useState(0)

  const [withdraw_material_details, setWithdraw_material_details] =
    React.useState([])
  const [modal_payMaterial, setModal_payMaterial] = React.useState("")
  const [optionSelectProjects, setOptionSelectProjects] = React.useState([])
  const [optionSelectMaterialStocks, setOptionSelectMaterialStocks] =
    React.useState([])

  const [tableDataCheckBoq, setTableDataCheckBoq] = React.useState([
    {
      dataField: "project_id",
      text: "โปรเจค",
      sort: true,
      headerStyle: () => {
        return { width: "180px" }
      },
      formatter: function action(cell, row) {
        // return row.project_name
        return (
          <Link to={`/projects-overview/${cell}`} className="text-dark">
            <p
              style={{
                color: "#556ee6",
              }}
            >
              {row.project_name}
            </p>
          </Link>
        )
      },
    },
    {
      dataField: "boq_title_name",
      text: "หมวดงานหลัก",
      sort: true,
      headerStyle: () => {
        return { width: "180px" }
      },
      sort: false,
    },

    {
      dataField: "user.name",
      text: "แจ้งเบิก",
      sort: true,
      headerStyle: () => {
        return { width: "180px" }
      },
      sort: false,
    },

    {
      dataField: "id",
      text: "ดู/แก้ไข",
      sort: true,
      headerStyle: () => {
        return { width: "80px" }
      },
      sort: false,
      formatter: function action(cell, row) {
        return (
          <Button
            size="sm"
            onClick={() => {
              getProjectPayMaterial(row.id)
            }}
            color="primary"
          >
            <i className="mdi mdi-pencil" />
          </Button>
        )
      },
    },
  ])

  const onChangeTableData = v => {
    const data = new FormData()

    data.append("type_second", "select")
    // console.log("type", v.type)
    // console.log("onChangeTableData_timeStart", v[0])
    // console.log("onChangeTableData_timeEnd", v[1])
    if (v.type == undefined) {
      if (isEmpty(v[0])) {
        setTimeStart(moment(v[0]).format("YYYY-MM-DD"))
      }
      if (isEmpty(v[1])) {
        setTimeEnd(moment(v[1]).format("YYYY-MM-DD"))
      }
      data.append(
        "timeStart",
        moment(v[0]).startOf("day").format("YYYY-MM-DD HH:mm")
      )
      data.append(
        "timeEnd",
        moment(v[1]).endOf("day").format("YYYY-MM-DD HH:mm")
      )
    } else {
      data.append(
        "timeStart",
        moment(v.timeStart).startOf("day").format("YYYY-MM-DD HH:mm")
      )
      data.append(
        "timeEnd",
        moment(v.timeEnd).endOf("day").format("YYYY-MM-DD HH:mm")
      )
    }

    dispatch(onGetWithdrawMaterials(data))
  }

  React.useMemo(() => {
    onChangeTableData({
      type: "first",
      timeStart: moment(timeStart).startOf("day").format("YYYY-MM-DD HH:mm"),
      timeEnd: moment(timeEnd).endOf("day").format("YYYY-MM-DD HH:mm"),
    })
    // const data = new FormData()
    // data.append("timeStart", timeStart)
    // data.append("timeEnd", timeEnd)
    // dispatch(onGetWithdrawMaterials(data))
    // console.log("projects", projects)
  }, [])

  React.useEffect(() => {
    dispatch(onGetMaterialStocks(1, material_type_id))
  }, [material_type_id])

  React.useEffect(() => {
    // console.log("withdrawMaterials", withdrawMaterials)
    setDataWithdraw_materials(withdrawMaterials)
  }, [withdrawMaterials])

  React.useEffect(() => {
    // console.log("check_boq_projects", check_boq_projects)
    if (!isEmpty(check_boq_projects)) {
      let temp = []
      for (let index = 0; index < check_boq_projects.length; index++) {
        temp.push({
          value: check_boq_projects[index].id,
          label: check_boq_projects[index].name,
          boq_title: check_boq_projects[index].boq_title,
        })
      }
      setOptionSelectProjects(temp)
    }
  }, [check_boq_projects])

  React.useEffect(() => {
    // console.log("optionSelectProjects", optionSelectProjects)
    if (!isEmpty(optionSelectProjects)) {
      if (action_withdraw_materials == "Edit") {
        for (let index = 0; index < optionSelectProjects.length; index++) {
          if (
            optionSelectProjects[index].value == withdraw_materials.project_id
          ) {
            selectProject(optionSelectProjects[index])
          }
        }
        // selectProject({
        //   value: withdraw_materials.project_id,
        //   label: withdraw_materials.project_name,
        // })
      }
    }
  }, [optionSelectProjects])

  React.useEffect(() => {
    // console.log("check_boq_projects", check_boq_projects)
    if (!isEmpty(materialStocks)) {
      let temp = []
      for (let index = 0; index < materialStocks.length; index++) {
        temp.push({
          value: materialStocks[index].id,
          label:
            "( " +
            materialStocks[index].code +
            " ) " +
            materialStocks[index].name,
          unit: materialStocks[index].unit,
          price: materialStocks[index].price,
        })
      }
      setOptionSelectMaterialStocks(temp)
    }
  }, [materialStocks])

  React.useEffect(() => {
    // console.log("withdrawMaterialProfile", withdrawMaterialProfile)
    if (!isEmpty(withdrawMaterialProfile)) {
      // dispatch(onGetMaterialStocks(1, material_type_id))
      openDialogPayMaterial("Edit")
      if (!isEmpty(withdrawMaterialProfile.withdraw_materials)) {
        // selectProject(withdrawMaterialProfile.withdraw_materials.project_id)
        setWithdraw_materials(withdrawMaterialProfile.withdraw_materials)
      }
      if (!isEmpty(withdrawMaterialProfile.withdraw_material_details)) {
        setWithdraw_material_details(
          withdrawMaterialProfile.withdraw_material_details
        )
      }
    }
  }, [withdrawMaterialProfile])

  //pagination customization
  const pageOptions = {
    sizePerPage: 6,
    totalSize: dataWithdraw_materials.length, // replace later with size(dataCheckBoq),
    custom: true,
  }
  const { SearchBar } = Search

  const styleSelect = {
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
    }),
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]
  function openDialogPayMaterial(action) {
    if (action == "Add") {
      setWithdraw_materials({})
      setWithdraw_material_details([])
    }
    setAction_withdraw_materials(action)
    setModal_payMaterial(!modal_payMaterial)
    dispatch(onGetProjects("check_boq", null))
  }

  function getProjectPayMaterial(e) {
    dispatch(onGetWithdrawMaterialProfile(e))
    // console.log("getProjectPayMaterial", e)
  }

  async function downloadPDF() {
    const docDefinition = {
      content: [
        {
          text: "โปรเจค " + withdraw_materials.project_name,
          style: "header",
        },
        {
          text: "ช่าง " + name_contractor + "\nเบอร์ติดต่อ" + tel_contractor,
          style: "header",
        },

        {
          text: "เบิกวันสดุวันที่: " + moment().format("DD/MM/YYYY HH:mm"),
          style: "subheader",
        },
        {
          text: "หมายเหตุ: " + withdraw_materials.note,
          style: "subheader",
        },
        {
          style: "tableExample",
          table: {
            widths: [30, "*", 30, 30, 30, 30, 30],
            body: newDataLists(withdraw_material_details),
          },
        },
        ,
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: "center",
        },
        subheader: {
          fontSize: 16,
          alignment: "center",
        },
        tableHeader: {
          bold: true,
          fontSize: 14,
          color: "black",
        },
        detailTable: {
          fontSize: 14,
        },
      },
      defaultStyle: {
        font: "THSarabunNew",
      },
      pageSize: "A4",
    }

    let namePDF =
      "สรุปเบิกวัสดุ " +
      withdraw_materials.project_name +
      " ( " +
      name_contractor +
      " )" +
      ".pdf"
    // pdfMake.createPdf(docDefinition).open()
    pdfMake.createPdf(docDefinition).download(namePDF)
    // pdfMake.createPdf(docDefinition).open({}, window);
  }
  function newDataLists(data) {
    var headers = {
      fila_0: {
        col_0: {
          text: "ลำดับ",
          style: "tableHeader",
        },
        col_1: {
          text: "วัสดุ",
          style: "tableHeader",
        },
        col_2: {
          text: "หน่วย",
          style: "tableHeader",
        },
        col_3: {
          text: "เบิก",
          style: "tableHeader",
          alignment: "center",
        },

        col_4: {
          text: "ใช้จริง",
          style: "tableHeader",
          alignment: "center",
        },
        col_5: {
          text: "คืน",
          style: "tableHeader",
          alignment: "center",
        },
        col_6: {
          text: "ราคา/ชิ้น",
          style: "tableHeader",
          alignment: "center",
        },
      },
    }

    var body = []
    for (var key in headers) {
      if (headers.hasOwnProperty(key)) {
        var header = headers[key]
        var row = new Array()
        row.push(header.col_0)
        row.push(header.col_1)
        row.push(header.col_2)
        row.push(header.col_3)
        row.push(header.col_4)
        row.push(header.col_5)
        // row.push(header.col_6)

        body.push(row)
      }
    }

    for (let key = 0; key < data.length; key++) {
      var row = new Array()
      row.push({ text: key + 1, style: "detailTable" })
      row.push({ text: data[key].material_name, style: "detailTable" })
      row.push({ text: data[key].unit, style: "detailTable" })
      row.push({
        text: data[key].qta,
        style: "detailTable",
        alignment: "right",
      })
      row.push({
        text: data[key].qta_use,
        style: "detailTable",
        alignment: "right",
      })
      row.push({
        text: data[key].qta_return,
        style: "detailTable",
        alignment: "right",
      })
      row.push({
        text: data[key].price,
        style: "detailTable",
        alignment: "right",
      })

      body.push(row)
    }
    return body
  }

  const onChangeTableData_summarize = () => {
    const data = new FormData()
    data.append("type_second", "summarize")
    data.append(
      "timeStart",
      moment(timeStart).startOf("day").format("YYYY-MM-DD HH:mm")
    )
    data.append(
      "timeEnd",
      moment(timeEnd).endOf("day").format("YYYY-MM-DD HH:mm")
    )
    dispatch(onGetWithdrawMaterials(data))
  }

  React.useEffect(() => {
    // console.log("withdrawSummarizeMaterials", withdrawSummarizeMaterials)
    if (!isEmpty(withdrawSummarizeMaterials)) {
      downloadPDF_summarize()
    } else {
    }
  }, [withdrawSummarizeMaterials])

  async function downloadPDF_summarize() {
    const docDefinition = {
      content: [
        {
          text:
            "สรุปเบิกของ\n " +
            moment(timeStart).format("DD/MM/YYYY") +
            " - " +
            moment(timeEnd).format("DD/MM/YYYY"),
          style: "subheader",
        },
        {
          style: "tableExample",
          table: {
            widths: [30, "*", "*", 30, 30, 30, 30, 30],
            body: newDataLists_summarize(withdrawSummarizeMaterials),
          },
        },
        ,
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: "center",
        },
        subheader: {
          fontSize: 16,
          alignment: "center",
        },
        tableHeader: {
          bold: true,
          fontSize: 14,
          color: "black",
        },
        detailTable: {
          fontSize: 14,
        },
      },
      defaultStyle: {
        font: "THSarabunNew",
      },
      pageSize: "A4",
    }

    let namePDF =
      "สรุปเบิกของวันที่: " +
      moment(timeStart).format("DD/MM/YYYY HH:mm") +
      "-" +
      moment(timeEnd).format("DD/MM/YYYY HH:mm") +
      ".pdf"
    // pdfMake.createPdf(docDefinition).open()
    pdfMake.createPdf(docDefinition).download(namePDF)
    // pdfMake.createPdf(docDefinition).open({}, window);
  }
  function newDataLists_summarize(data) {
    var headers = {
      fila_0: {
        col_0: {
          text: "ลำดับ",
          style: "tableHeader",
        },
        col_1: {
          text: "โปรเจค",
          style: "tableHeader",
        },
        col_2: {
          text: "วัสดุ",
          style: "tableHeader",
        },
        col_3: {
          text: "หน่วย",
          style: "tableHeader",
        },
        col_4: {
          text: "เบิก",
          style: "tableHeader",
          alignment: "center",
        },

        col_5: {
          text: "ใช้จริง",
          style: "tableHeader",
          alignment: "center",
        },
        col_6: {
          text: "คืน",
          style: "tableHeader",
          alignment: "center",
        },
        col_7: {
          text: "เกิน",
          style: "tableHeader",
          alignment: "center",
        },
      },
    }

    var body = []
    for (var key in headers) {
      if (headers.hasOwnProperty(key)) {
        var header = headers[key]
        var row = new Array()
        row.push(header.col_0)
        row.push(header.col_1)
        row.push(header.col_2)
        row.push(header.col_3)
        row.push(header.col_4)
        row.push(header.col_5)
        row.push(header.col_6)
        row.push(header.col_7)

        body.push(row)
      }
    }

    for (let key = 0; key < data.length; key++) {
      var row = new Array()
      row.push({ text: key + 1, style: "detailTable" })
      row.push({ text: data[key].project_name, style: "detailTable" })
      row.push({ text: data[key].material_name, style: "detailTable" })
      row.push({ text: data[key].unit, style: "detailTable" })
      row.push({
        text: data[key].total_qta,
        style: "detailTable",
        alignment: "right",
      })
      row.push({
        text: data[key].total_qta_use,
        style: "detailTable",
        alignment: "right",
      })
      row.push({
        text: data[key].total_qta_return,
        style: "detailTable",
        alignment: "right",
      })
      row.push({
        text: data[key].total_qta_over,
        style: "detailTable",
        alignment: "right",
      })

      body.push(row)
    }
    return body
  }

  function selectProject(e) {
    setWithdraw_materials({
      ...withdraw_materials,
      project_id: e.value,
      project_name: e.label,
    })
    setTimeout(() => {
      setWithdraw_materials({
        ...withdraw_materials,
        project_id: e.value,
        project_name: e.label,
      })
    }, 500)
    // setBoq_title(e.boq_title)
    let boq_title = e.boq_title
    let temp = []
    for (let index = 0; index < boq_title.length; index++) {
      if (boq_title[index].contractor_id != null) {
        let new_label = boq_title[index].quotation_list_name.split(" ")
        temp.push({
          value: boq_title[index].id,
          label:
            new_label[0] + " ( ช่าง " + boq_title[index].name_contractor + " )",
          label_full: boq_title[index].quotation_list_name,
          boq_title_detail: boq_title[index].boq_title_detail,
        })
        // console.log("selectProject", boq_title[index])
        if (action_withdraw_materials == "Edit") {
          if (boq_title[index].id == withdraw_materials.boq_title_id) {
            setName_contractor(boq_title[index].name_contractor)
            setTel_contractor(boq_title[index].number_phone_contractor)

            selectBoqTitle(temp[index])
          }
        }
      }
    }
    setBoq_title(temp)

    // console.log("selectProject", e)
  }
  function selectBoqTitle(e) {
    // console.log("selectBoqTitle", e)
    if (!isEmpty(e)) {
      let boq_title_detail = e.boq_title_detail
      let temp = []
      for (let index = 0; index < boq_title_detail.length; index++) {
        temp.push({
          value: boq_title_detail[index].id,
          label: boq_title_detail[index].list_structure_name,
        })
      }
      setBoq_title_detail(temp)

      setWithdraw_materials({
        ...withdraw_materials,
        boq_title_id: e.value,
        boq_title_name: e.label_full,
      })
    }
  }

  function addPayMaterial() {
    const data = new FormData()
    data.append("action", action_withdraw_materials)
    data.append("withdraw_materials", JSON.stringify(withdraw_materials))
    data.append(
      "withdraw_material_details",
      JSON.stringify(withdraw_material_details)
    )
    // data.append("withdraw_materials", JSON.stringify(withdraw_materials))
    // data.append(
    //   "withdraw_material_details",
    //   JSON.stringify(withdraw_material_details)
    // )
    dispatch(onAddNewWithdrawMaterial(data))

    setModal_payMaterial(!modal_payMaterial)
  }
  function selectBoqTitleDetail(e) {
    if (!isEmpty(e)) {
      setWithdraw_materials({
        ...withdraw_materials,
        boq_title_detail_id: e.value,
        boq_title_detail_name: e.label,
      })
      addRow()
    }
    // console.log("selectBoqTitleDetail", e)
  }
  function addRow() {
    setWithdraw_material_details([
      ...withdraw_material_details,
      {
        key: withdraw_material_details.length,
        withdraw_material_id: "",
        material_id: "",
        material_name: "",
        qta: 1,
        qta_use: 0,
        qta_return: 0,
        qta_over: 0,
        price: 0,
        unit: "",
        price_sum: 0,
        del: 0,
      },
    ])
  }

  function selectMaterialStocks(v, k) {
    // console.log("selectMaterialStocks_v", v)
    // console.log("selectMaterialStocks_k", k)
    let temp = [...withdraw_material_details]
    temp[k].material_id = v.value
    temp[k].material_name = v.label
    temp[k].unit = v.unit
    temp[k].price = Number(v.price)
    setWithdraw_material_details(temp)
  }

  function handleRemoveItem(item) {
    const temp = [...withdraw_material_details]
    for (let index = 0; index < temp.length; index++) {
      if (temp[index]["key"] != undefined) {
        // console.log("exists", temp[index])
        if (temp[index]["key"] == item.key) {
          temp[index].del = 1
        }
      } else {
        if (temp[index].id == item.id) {
          temp[index].del = 1
        }
        // console.log(temp[index])
      }
    }
    setWithdraw_material_details(temp)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Material Pays Tables | {process.env.REACT_APP_NAMECOMPANY}
          </title>
        </MetaTags>
        <Modal
          size="lg"
          style={{ maxWidth: "70%" }}
          centered
          isOpen={modal_payMaterial}
          toggle={() => {
            setModal_payMaterial(!modal_payMaterial)
          }}
        >
          <div className="modal-body">
            <Row>
              <Col sm="12" lg="4">
                <Select
                  styles={styleSelect}
                  placeholder="เลือกโปรเจคที่สั่งซื้อ"
                  name={"project_id"}
                  value={optionSelectProjects.filter(
                    option => option.value == withdraw_materials.project_id
                  )}
                  options={optionSelectProjects}
                  onChange={e => {
                    selectProject(e)
                  }}
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                />
              </Col>
              {boq_title.length > 0 ? (
                <Col sm="12" lg="4">
                  <Select
                    styles={styleSelect}
                    placeholder="หมวดงานหลัก"
                    name={"boq_title_id"}
                    value={boq_title.filter(
                      option => option.value == withdraw_materials.boq_title_id
                    )}
                    options={boq_title}
                    onChange={e => {
                      selectBoqTitle(e)
                    }}
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                  />
                </Col>
              ) : null}

              {boq_title_detail.length > 0 ? (
                <Col sm="12" lg="4">
                  <Select
                    styles={styleSelect}
                    placeholder="หมวดงานย่อย"
                    name={"boq_title_detail_id"}
                    value={boq_title_detail.filter(
                      option =>
                        option.value == withdraw_materials.boq_title_detail_id
                    )}
                    options={boq_title_detail}
                    onChange={e => {
                      selectBoqTitleDetail(e)
                    }}
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                  />
                </Col>
              ) : null}
              {boq_title_detail.length > 0 ? (
                <Col sm="12">
                  <Input
                    placeholder="หมายเหตุ"
                    type="textarea"
                    name={"note"}
                    rows="2"
                    value={withdraw_materials.note}
                    onChange={e => {
                      setWithdraw_materials({
                        ...withdraw_materials,
                        note: e.target.value,
                      })
                    }}
                  />
                </Col>
              ) : null}

              <hr className="mt-2 mb-2" />
              {withdraw_materials.boq_title_id != null ? (
                <Col sm="12">
                  <Table
                    responsive
                    className="table-nowrap align-middle table-bordered"
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "10%", textAlign: "center" }}>
                          ลำดับ
                        </th>
                        <th style={{ width: "40%" }}>วัสดุ</th>
                        <th style={{ width: "10%" }}>เบิก</th>
                        <th style={{ width: "10%" }}>ใช้</th>
                        <th style={{ width: "10%" }}>คืน</th>
                        <th style={{ width: "10%" }}>ราคา/ชิ้น</th>
                        <th style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {(withdraw_material_details || []).map((v, k) =>
                        v.del == 1 ? null : (
                          <tr key={k}>
                            <th style={{ width: "10%", textAlign: "center" }}>
                              {k + 1}
                            </th>
                            <th style={{ width: "30%" }}>
                              <Select
                                styles={styleSelect}
                                placeholder="วัสดุ"
                                name={"material_id_" + k}
                                value={optionSelectMaterialStocks.filter(
                                  option => option.value == v.material_id
                                )}
                                options={optionSelectMaterialStocks}
                                onChange={e => {
                                  selectMaterialStocks(e, k)
                                }}
                                classNamePrefix="select"
                                menuPortalTarget={document.body}
                              />
                            </th>
                            <th style={{ width: "10%" }}>
                              <Input
                                type="number"
                                name={"qta_" + k}
                                value={v.qta}
                                min="1"
                                onChange={e => {
                                  let temp = [...withdraw_material_details]
                                  temp[k].qta = e.target.value
                                  temp[k].price_sum =
                                    Number(temp[k].price) *
                                    Number(e.target.value)

                                  setWithdraw_material_details(temp)
                                }}
                              />
                            </th>
                            <th style={{ width: "10%" }}>
                              <Input
                                type="number"
                                name={"qta_use" + k}
                                value={v.qta_use}
                                min="0"
                                onChange={e => {
                                  let temp = [...withdraw_material_details]
                                  temp[k].qta_use = e.target.value

                                  setWithdraw_material_details(temp)
                                }}
                              />
                            </th>

                            <th style={{ width: "10%" }}>
                              <Input
                                type="number"
                                name={"qta_return" + k}
                                value={v.qta_return}
                                min="0"
                                onChange={e => {
                                  let temp = [...withdraw_material_details]
                                  temp[k].qta_return = e.target.value

                                  setWithdraw_material_details(temp)
                                }}
                              />
                            </th>
                            <th style={{ width: "10%" }}>
                              <Input
                                type="number"
                                name={"price" + k}
                                value={v.price}
                                min="0"
                                onChange={e => {
                                  let temp = [...withdraw_material_details]
                                  temp[k].price = e.target.value
                                  temp[k].price_sum =
                                    Number(temp[k].qta) * Number(e.target.value)

                                  setWithdraw_material_details(temp)
                                }}
                              />
                            </th>
                            <th style={{ width: "10%" }}>
                              <Button
                                color="danger"
                                className="font-16 btn-block btn btn-danger"
                                onClick={() => handleRemoveItem(v)}
                              >
                                <i className="mdi mdi-close-thick" />
                              </Button>
                            </th>
                          </tr>
                        )
                      )}

                      <tr>
                        <td colSpan={6}></td>
                        <td>
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={() => addRow()}
                          >
                            <i className="mdi mdi-plus-circle-outline" />
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              ) : null}
            </Row>
          </div>
          <div className="modal-footer">
            {action_withdraw_materials == "Edit" &&
            !isEmpty(name_contractor) ? (
              <button
                type="button"
                className="btn btn-warning "
                // data-dismiss="modal"
                onClick={() => {
                  downloadPDF()
                  // setModal_payMaterial(!modal_payMaterial)
                }}
              >
                Print
              </button>
            ) : null}

            {withdraw_material_details.length > 0 ? (
              <button
                type="button"
                onClick={() => {
                  addPayMaterial()
                  // setModal_payMaterial(!modal_payMaterial)
                }}
                className="btn btn-success "
                // data-dismiss="modal"
              >
                Save
              </button>
            ) : null}

            <button
              type="button"
              onClick={() => {
                setModal_payMaterial(!modal_payMaterial)
              }}
              className="btn btn-danger "
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
        <div className="container-fluid">
          <Row>
            <Card>
              <CardBody>
                <CardTitle className="h4">
                  <Row>
                    <Col sm="6" lg="6">
                      <div className="d-grid gap-2">
                        <Button
                          block
                          size="sm"
                          color="primary"
                          onClick={e => {
                            openDialogPayMaterial("Add")
                          }}
                        >
                          เพิ่มรายการเบิก
                        </Button>
                      </div>
                    </Col>
                    <Col sm="6" lg="6">
                      {!isEmpty(withdrawMaterials) && (
                        <div className="d-grid gap-2">
                          <Button
                            block
                            type="button"
                            size="sm"
                            color="warning"
                            onClick={e => {
                              onChangeTableData_summarize()
                            }}
                          >
                            สรุปเบิกวัสดุ
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                  {/* <span
                    style={{
                      cursor: "pointer",
                      color: "#556ee6",
                      textDecoration: "underline",
                    }}
                    onClick={e => {
                      openDialogPayMaterial("Add")
                    }}
                  >
                    
                  </span> */}
                </CardTitle>

                <Col sm="12">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={tableDataCheckBoq}
                    data={dataWithdraw_materials}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={dataWithdraw_materials}
                        columns={tableDataCheckBoq}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col sm="12" lg="6">
                                <div className="search-box ms-2 mb-2 ">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>

                              <Col sm="12" lg="6">
                                <Flatpickr
                                  style={{ width: "100%" }}
                                  className="form-control"
                                  placeholder="วันที่ค้นหา"
                                  options={{
                                    locale: Thai,
                                    mode: "range",
                                    time_24hr: true,
                                    dateFormat: "d/M/Y",
                                  }}
                                  onClose={e => {
                                    onChangeTableData(e)
                                  }}
                                />
                              </Col>

                              <Col xl="12">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle"}
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </Col>
                            </Row>
                            <div className="pagination pagination-rounded justify-content-end">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Col>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PayMaterial)
