import {
  GET_USERGROUPS,
  GET_USERGROUPS_FAIL,
  GET_USERGROUPS_SUCCESS,
} from "./actionTypes"

export const getUserGroups = () => ({
  type: GET_USERGROUPS,
})

export const getUserGroupsSuccess = users => ({
  type: GET_USERGROUPS_SUCCESS,
  payload: users,
})

export const getUserGroupsFail = error => ({
  type: GET_USERGROUPS_FAIL,
  payload: error,
})
