import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_QUOTATIONPROJECTS,
  GET_QUOTATIONPROJECT_PROFILE,
  ADD_NEW_QUOTATIONPROJECT,
  DELETE_QUOTATIONPROJECT,
  UPDATE_QUOTATIONPROJECT,
} from "./actionTypes"

import {
  getQuotationProjectsSuccess,
  getQuotationProjectsFail,
  getQuotationProjectProfileSuccess,
  getQuotationProjectProfileFail,
  addQuotationProjectFail,
  addQuotationProjectSuccess,
  updateQuotationProjectSuccess,
  updateQuotationProjectFail,
  deleteQuotationProjectSuccess,
  deleteQuotationProjectFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getQuotationProjects,
  getQuotationProjectProfile,
  addNewQuotationProject,
  updateQuotationProject,
  deleteQuotationProject,
  uploadImgQuotationProject,
} from "../../helpers/fakebackend_helper"

function* fetchQuotationProjects({ payload: project_id }) {
  try {
    // console.log(1);
    const response = yield call(getQuotationProjects, project_id)
    // console.log(response)
    yield put(getQuotationProjectsSuccess(response))
  } catch (error) {
    yield put(getQuotationProjectsFail(error))
  }
}

function* fetchQuotationProjectProfile({ payload: data }) {
  try {
    const response = yield call(getQuotationProjectProfile, data)
    // console.log(response);
    yield put(getQuotationProjectProfileSuccess(response))
  } catch (error) {
    yield put(getQuotationProjectProfileFail(error))
  }
}

function* onUpdateQuotationProject({ payload: quotationproject }) {
  try {
    // let x = quotationproject.get('formData')
    // console.log(x)
    const response = yield call(updateQuotationProject, quotationproject)
    // console.log(response);
    yield put(updateQuotationProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateQuotationProjectFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteQuotationProject({ payload: quotationproject }) {
  try {
    const response = yield call(deleteQuotationProject, quotationproject)
    yield put(deleteQuotationProjectSuccess(response))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "ลบ",
      message: "ลบสำเร็จ",
    })
  } catch (error) {
    yield put(deleteQuotationProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "ลบ",
      message: "ลบล้มเหลว",
    })
  }
}

function* onAddNewQuotationProject({ payload: quotationproject }) {
  try {
    const response = yield call(addNewQuotationProject, quotationproject)
    // console.log(response);
    yield put(addQuotationProjectSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addQuotationProjectFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* quotationProjectsSaga() {
  yield takeEvery(GET_QUOTATIONPROJECTS, fetchQuotationProjects)
  yield takeEvery(GET_QUOTATIONPROJECT_PROFILE, fetchQuotationProjectProfile)
  yield takeEvery(ADD_NEW_QUOTATIONPROJECT, onAddNewQuotationProject)
  yield takeEvery(UPDATE_QUOTATIONPROJECT, onUpdateQuotationProject)
  yield takeEvery(DELETE_QUOTATIONPROJECT, onDeleteQuotationProject)
}

export default quotationProjectsSaga
