import React, { useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

const AboutUs = () => {
  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)

  return (
    <React.Fragment>
      <section className="section pt-4 bg-white" id="about">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <p style={{fontSize:'20px'}}>About us</p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="12 text-center">
              <h3 className="text-muted" style={{ marginBottom: "20px" }}>
                &emsp;พรีเมี่ยมเฮ้าส์ โมดูร่า
                รับออกแบบบ้านสำเร็จรูปเพื่อตอบโจทย์
              </h3>
              <h3 className="text-muted" style={{ marginBottom: "20px" }}>
                ลูกค้าสมัยใหม่เรามีแนวคิดว่าทำไมบ้านสำเร็จรูปต้องมีลักษณะที่เชย
              </h3>
              <h3 className="text-muted" style={{ marginBottom: "20px" }}>
                เราจึงออกแบบโดยได้แรงบันดานใจมากจากต่างประเทศ
              </h3>
              <h3 className="text-muted" style={{ marginBottom: "20px" }}>
                เพื่อให้บ้านที่สร้างจากเรามีดีไซย์ที่สวยงาม
                ทางเราใช้วัสดุที่ได้มาตรฐาน
              </h3>
              <h3 className="text-muted" style={{ marginBottom: "20px" }}>
                ทำให้บ้านพรีเมียมเฮ้าโมดูล่านั้น มีอายุการใช้งานยืนยาว เป็น 10
                ปีขึ้นไป
              </h3>
              <h3 className="text-muted" style={{ marginBottom: "20px" }}>
                หากดูแลรักษาบ้านตลอดโดย
                เหมาะสำหรับผู้ที่ต้องการสร้างบ้านอย่างรวดเร็ว และประหยัด
              </h3>
              <h3 className="text-muted" style={{ marginBottom: "20px" }}>
                หากมีพื้น หรือที่ดินต่างจังหวัด สามารถเอาบ้าน
                พรีเมี่ยมเฮ้าส์โมดูร่า
              </h3>
              <h3 className="text-muted" style={{ marginBottom: "20px" }}>
                ไปตั้งเผื่อพักผ่อน ทำรีสอร์ท หรือห้องรับรองต่าง ๆ ได้
              </h3>
            </Col>
          </Row>

          <hr className="my-5" />
        </Container>
      </section>
    </React.Fragment>
  )
}

export default AboutUs
