import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_MATERIAL_PRICES,
  GET_MATERIAL_PRICE_PROFILE,
  ADD_NEW_MATERIAL_PRICE,
  DELETE_MATERIAL_PRICE,
  UPDATE_MATERIAL_PRICE,
} from "./actionTypes"

import {
  getMaterialPricesSuccess,
  getMaterialPricesFail,
  getMaterialPriceProfileSuccess,
  getMaterialPriceProfileFail,
  addMaterialPriceFail,
  addMaterialPriceSuccess,
  updateMaterialPriceSuccess,
  updateMaterialPriceFail,
  deleteMaterialPriceSuccess,
  deleteMaterialPriceFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMaterialPrices,
  getMaterialPriceProfile,
  addNewMaterialPrice,
  updateMaterialPrice,
  deleteMaterialPrice,
} from "../../helpers/fakebackend_helper"

function* fetchMaterialPrices({ payload: material_id }) {
  // console.log("material_id", data.material_id)
  // console.log("material_type_id", data.material_type_id)

  try {
    const response = yield call(getMaterialPrices, material_id)
    // console.log(response)
    yield put(getMaterialPricesSuccess(response))
  } catch (error) {
    yield put(getMaterialPricesFail(error))
  }
}

function* fetchMaterialPriceProfile({ payload: id }) {
  // console.log(id)
  try {
    const response = yield call(getMaterialPriceProfile, id)
    // console.log("Profile => ", response)
    yield put(getMaterialPriceProfileSuccess(response))
  } catch (error) {
    yield put(getMaterialPriceProfileFail(error))
  }
}

function* onUpdateMaterialPrice({ payload: materialshop }) {
  try {
    // let x = materialshop.get('formData')
    // console.log(x)
    const response = yield call(updateMaterialPrice, materialshop)
    yield put(updateMaterialPriceSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateMaterialPriceFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteMaterialPrice({ payload: materialshop }) {
  try {
    const response = yield call(deleteMaterialPrice, materialshop)
    yield put(deleteMaterialPriceSuccess(response))
  } catch (error) {
    yield put(deleteMaterialPriceFail(error))
  }
}

function* onAddNewMaterialPrice({ payload: materialshop }) {
  try {
    const response = yield call(addNewMaterialPrice, materialshop)
    yield put(addMaterialPriceSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addMaterialPriceFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* materialType() {
  yield takeEvery(GET_MATERIAL_PRICES, fetchMaterialPrices)
  yield takeEvery(GET_MATERIAL_PRICE_PROFILE, fetchMaterialPriceProfile)
  yield takeEvery(ADD_NEW_MATERIAL_PRICE, onAddNewMaterialPrice)
  yield takeEvery(UPDATE_MATERIAL_PRICE, onUpdateMaterialPrice)
  yield takeEvery(DELETE_MATERIAL_PRICE, onDeleteMaterialPrice)
}

export default materialType
