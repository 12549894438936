import {
  GET_SERWAYROJECT_PROFILE,
  GET_SERWAYROJECT_PROFILE_FAIL,
  GET_SERWAYROJECT_PROFILE_SUCCESS,
  GET_SERWAYROJECTS,
  GET_SERWAYROJECTS_FAIL,
  GET_SERWAYROJECTS_SUCCESS,
  ADD_NEW_SERWAYROJECT,
  ADD_SERWAYROJECT_SUCCESS,
  ADD_SERWAYROJECT_FAIL,
  UPDATE_SERWAYROJECT,
  UPDATE_SERWAYROJECT_SUCCESS,
  UPDATE_SERWAYROJECT_FAIL,
  DELETE_SERWAYROJECT,
  DELETE_SERWAYROJECT_SUCCESS,
  DELETE_SERWAYROJECT_FAIL,
  UPLOAD_INFOPROJECTFILESERWAY,
} from "./actionTypes"

export const getSerwayprojects = id => ({
  type: GET_SERWAYROJECTS,
  payload: id,
})

export const getSerwayprojectsSuccess = serwayprojects => ({
  type: GET_SERWAYROJECTS_SUCCESS,
  payload: serwayprojects,
})

export const addNewSerwayproject = serwayproject => ({
  type: ADD_NEW_SERWAYROJECT,
  payload: serwayproject,
})

export const addSerwayprojectSuccess = serwayproject => ({
  type: ADD_SERWAYROJECT_SUCCESS,
  payload: serwayproject,
})

export const addSerwayprojectFail = error => ({
  type: ADD_SERWAYROJECT_FAIL,
  payload: error,
})

export const getSerwayprojectsFail = error => ({
  type: GET_SERWAYROJECTS_FAIL,
  payload: error,
})

export const getSerwayprojectProfile = id => ({
  type: GET_SERWAYROJECT_PROFILE,
  payload: id,
})

export const getSerwayprojectProfileSuccess = serwayprojectProfile => ({
  type: GET_SERWAYROJECT_PROFILE_SUCCESS,
  payload: serwayprojectProfile,
})

export const getSerwayprojectProfileFail = error => ({
  type: GET_SERWAYROJECT_PROFILE_FAIL,
  payload: error,
})

export const updateSerwayproject = serwayproject => ({
  type: UPDATE_SERWAYROJECT,
  payload: serwayproject,
})

// export const updateSerwayproject = serwayproject => console.log(serwayproject);

export const updateSerwayprojectSuccess = serwayproject => ({
  type: UPDATE_SERWAYROJECT_SUCCESS,
  payload: serwayproject,
})

export const updateSerwayprojectFail = error => ({
  type: UPDATE_SERWAYROJECT_FAIL,
  payload: error,
})

export const deleteSerwayproject = serwayproject => ({
  type: DELETE_SERWAYROJECT,
  payload: serwayproject,
})

export const deleteSerwayprojectSuccess = serwayproject => ({
  type: DELETE_SERWAYROJECT_SUCCESS,
  payload: serwayproject,
})

export const deleteSerwayprojectFail = error => ({
  type: DELETE_SERWAYROJECT_FAIL,
  payload: error,
})

export const uploadImgInfoSerway = dataImg => ({
  type: UPLOAD_INFOPROJECTFILESERWAY,
  payload: dataImg,
})
