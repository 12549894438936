import React from "react"
import { Container, Row } from "reactstrap"

//Import Components
import CardBox from "./card-box"
import { useDispatch, useSelector } from "react-redux"
import { getDesignHomes as onGetDesignHomes } from "store/actions"
import { isEmpty } from "lodash"

const CardsMini = () => {
  const dispatch = useDispatch()
  const [designPromotionhomes, setDesignPromotionhome] = React.useState([])

  const { designPromotionhome } = useSelector(state => ({
    designPromotionhome: state.designHomes.designPromotionhome,
  }))

  React.useEffect(() => {
    if (!isEmpty(designPromotionhome)) {
      setDesignPromotionhome(designPromotionhome)
    }
  }, [designPromotionhome])

  React.useEffect(() => {
    if (isEmpty(designPromotionhome)) {
      dispatch(onGetDesignHomes())
    }
  }, [dispatch])

  return (
    <React.Fragment>
      <section className="section bg-white p-0">
        <Container>
          <div className="currency-price">
            <Row>
              {/* reder card boxes */}
              <CardBox homes={designPromotionhomes} />
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default CardsMini
