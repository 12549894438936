/* BANKS */
export const GET_BANKS = "GET_BANKS"
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS"
export const GET_BANKS_FAIL = "GET_BANKS_FAIL"

/* BANKS PROFILE */
export const GET_BANK_PROFILE = "GET_BANK_PROFILE"
export const GET_BANK_PROFILE_SUCCESS = "GET_BANK_PROFILE_SUCCESS"
export const GET_BANK_PROFILE_FAIL = "GET_BANK_PROFILE_FAIL"

/**
 * add designgroup
 */
export const ADD_NEW_BANK = "ADD_NEW_BANK"
export const ADD_BANK_SUCCESS = "ADD_BANK_SUCCESS"
export const ADD_BANK_FAIL = "ADD_BANK_FAIL"

/**
 * Edit designgroup
 */
export const UPDATE_BANK = "UPDATE_BANK"
export const UPDATE_BANK_SUCCESS = "UPDATE_BANK_SUCCESS"
export const UPDATE_BANK_FAIL = "UPDATE_BANK_FAIL"

/**
 * Delete designgroup
 */
export const DELETE_BANK = "DELETE_BANK"
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS"
export const DELETE_BANK_FAIL = "DELETE_BANK_FAIL"
