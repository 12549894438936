import {
  GET_QUOTATIONCONPROJECTS_SUCCESS,
  GET_QUOTATIONCONPROJECTS_FAIL,
  ADD_QUOTATIONCONPROJECT_SUCCESS,
  ADD_QUOTATIONCONPROJECT_FAIL,
  UPDATE_QUOTATIONCONPROJECT_SUCCESS,
  UPDATE_QUOTATIONCONPROJECT_FAIL,
  DELETE_QUOTATIONCONPROJECT_SUCCESS,
  DELETE_QUOTATIONCONPROJECT_FAIL,
  GET_QUOTATIONCONPROJECT_PROFILE_SUCCESS,
  GET_QUOTATIONCONPROJECT_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  quotationconprojects: [
    {
      material: [],
      note: [],
      standard: [],
    },
  ],
  quotationconprojectProfile: {},
  error: {},
}

const quotationconprojects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUOTATIONCONPROJECTS_SUCCESS:
      return {
        ...state,
        quotationconprojects: action.payload,
      }

    case GET_QUOTATIONCONPROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_QUOTATIONCONPROJECT_SUCCESS:
      return {
        ...state,
        quotationconprojects: [...state.quotationconprojects, action.payload],
      }

    case ADD_QUOTATIONCONPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_QUOTATIONCONPROJECT_PROFILE_SUCCESS:
      return {
        ...state,
        quotationconprojectProfile: action.payload,
      }

    case UPDATE_QUOTATIONCONPROJECT_SUCCESS:
      return {
        ...state,
        quotationconprojects: state.quotationconprojects.map(
          quotationconproject =>
            quotationconproject.id.toString() === action.payload.id.toString()
              ? { quotationconproject, ...action.payload }
              : quotationconproject
        ),
      }

    case UPDATE_QUOTATIONCONPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_QUOTATIONCONPROJECT_SUCCESS:
      return {
        ...state,
        quotationconprojects: state.quotationconprojects.filter(
          quotationconproject =>
            quotationconproject.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_QUOTATIONCONPROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_QUOTATIONCONPROJECT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default quotationconprojects
