import {
  GET_LIST_STRUCTURE_PROFILE,
  GET_LIST_STRUCTURE_PROFILE_FAIL,
  GET_LIST_STRUCTURE_PROFILE_SUCCESS,
  GET_LIST_STRUCTURES,
  GET_LIST_STRUCTURES_FAIL,
  GET_LIST_STRUCTURES_SUCCESS,
  ADD_NEW_LIST_STRUCTURE,
  ADD_LIST_STRUCTURE_SUCCESS,
  ADD_LIST_STRUCTURE_FAIL,
  UPDATE_LIST_STRUCTURE,
  UPDATE_LIST_STRUCTURE_SUCCESS,
  UPDATE_LIST_STRUCTURE_FAIL,
  DELETE_LIST_STRUCTURE,
  DELETE_LIST_STRUCTURE_SUCCESS,
  DELETE_LIST_STRUCTURE_FAIL,
} from "./actionTypes"

export const getListStructures = (active, type) => ({
  type: GET_LIST_STRUCTURES,
  payload: { active, type },
})

export const getListStructuresSuccess = structures => ({
  type: GET_LIST_STRUCTURES_SUCCESS,
  payload: structures,
})

export const addNewListStructure = structure => ({
  type: ADD_NEW_LIST_STRUCTURE,
  payload: structure,
})

export const addListStructureSuccess = structure => ({
  type: ADD_LIST_STRUCTURE_SUCCESS,
  payload: structure,
})

export const addListStructureFail = error => ({
  type: ADD_LIST_STRUCTURE_FAIL,
  payload: error,
})

export const getListStructuresFail = error => ({
  type: GET_LIST_STRUCTURES_FAIL,
  payload: error,
})

export const getListStructureProfile = id => ({
  type: GET_LIST_STRUCTURE_PROFILE,
  payload: id,
})

export const getListStructureProfileSuccess = structureProfile => ({
  type: GET_LIST_STRUCTURE_PROFILE_SUCCESS,
  payload: structureProfile,
})

export const getListStructureProfileFail = error => ({
  type: GET_LIST_STRUCTURE_PROFILE_FAIL,
  payload: error,
})

export const updateListStructure = structure => ({
  type: UPDATE_LIST_STRUCTURE,
  payload: structure,
})

// export const updateListStructure = structure => console.log(structure);

export const updateListStructureSuccess = structure => ({
  type: UPDATE_LIST_STRUCTURE_SUCCESS,
  payload: structure,
})

export const updateListStructureFail = error => ({
  type: UPDATE_LIST_STRUCTURE_FAIL,
  payload: error,
})

export const deleteListStructure = structure => ({
  type: DELETE_LIST_STRUCTURE,
  payload: structure,
})

export const deleteListStructureSuccess = structure => ({
  type: DELETE_LIST_STRUCTURE_SUCCESS,
  payload: structure,
})

export const deleteListStructureFail = error => ({
  type: DELETE_LIST_STRUCTURE_FAIL,
  payload: error,
})
