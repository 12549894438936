import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody, Col, Media } from "reactstrap"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import home3 from "../../../../assets/images/homes/IMG_0301.jpg"

const CardBox = props => {
  return (
    <React.Fragment>
      {props.homes.map((item, key) => (
        <Col md="4" key={key}>
          <Card>
            <CardBody>
              <div className="blog-box mb-4 mb-xl-0">
                <div className="position-relative">
                  <Link
                    to={"/home-product-detail/" + item.id}
                    className="text-white"
                  >
                    <div
                      className="text-center"
                      style={{
                        width: "316px",
                        height: "156px",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_SERVER + item.path_img
                          })`,
                          backgroundSize: "cover",
                          position: "absolute",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          height: "100%",
                          width: "100%",
                        }}
                      ></div>

                      {/* <img
                        style={{
                          objectFit: "contain",
                          // object-fit: contain;
                          maxWidth: "100%",
                          maxHeight: "100%",
                          width: "auto",
                          height: "auto",
                        }}
                        src={process.env.REACT_APP_SERVER + item.path_img}
                        alt={process.env.REACT_APP_NAMECOMPANY}
                      /> */}
                      <div
                        className="badge blog-badge text-dark font-size-18"
                        style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                      >
                        <p
                          style={{
                            fontWeight: "100",
                          }}
                        >
                          โปรโมชั่น
                        </p>
                        <p>
                          {!isEmpty(item.price) ? (
                            <b
                              style={{
                                fontWeight: "100",
                              }}
                            >
                              {parseFloat(
                                (parseFloat(item.price) / 100) *
                                  (100 - item.offer)
                              ).toLocaleString("th-TH", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}
                            </b>
                          ) : (
                            0
                          )}
                        </p>
                        <p
                          className="text-dark mb-0"
                          style={{
                            fontSize: "13px",
                            fontWeight: "100",
                          }}
                        >
                          {item.code == "null" ? null : item.code}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
      <Col md="4">
        <Card>
          <CardBody>
            <div className="blog-box mb-4 mb-xl-0">
              <div className="position-relative">
                <Link to={"/home-products"} className="text-white ">
                  <div
                    style={{
                      width: "316px",
                      height: "156px",
                    }}
                    className="text-center"
                  >
                    <div
                      style={{
                        backgroundImage: `url(${home3})`,
                        backgroundSize: "cover",
                        position: "absolute",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        height: "100%",
                        width: "100%",
                      }}
                    ></div>

                    <div
                      className="badge blog-badge font-size-18 text-dark"
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                    >
                      <p
                        style={{
                          fontWeight: "100",
                        }}
                      >
                        โปรโมชั่น
                      </p>
                      <p
                        style={{
                          fontWeight: "100",
                        }}
                      >
                        {Number(30000).toLocaleString("th-TH", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardBox.propTypes = {
  homes: PropTypes.array,
}

export default CardBox
