import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"

import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "../../pages/Tables/datatables.scss"

import ExampleModalProduct from "./dialogList_Product"
import { getProductGroups as onGetProductGroups } from "store/product-groups/actions"
// API store
import {
  getDesignHomes as onGetDesignHomes,
  addNewDesignHome as onAddNewDesignHome,
} from "store/actions"
// const ExampleModal(props) {
const ExampleModal = ({ actionModal, callBack }) => {
  const dispatch = useDispatch()

  const { productGroups } = useSelector(state => ({
    productGroups: state.productGroups.productGroups,
  }))

  const { designhomes } = useSelector(state => ({
    designhomes: state.designHomes.designhomes,
  }))

  const { designhomeProfile } = useSelector(state => ({
    designhomeProfile: state.designHomes.designhomeProfile,
  }))

  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "select",
      text: "เลือก",
      sort: false,

      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return (
          <div className="d-flex">
            <Button
              onClick={() => selectOnCallBack(row)}
              className="mr-1 mb-1 "
              color="success"
              outline
            >
              <i className="bx bx-comment-check"></i>
            </Button>
          </div>
        )
      },
    },

    // {
    //   dataField: "no",
    //   text: "ลำดับ",
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: "80px" }
    //   },
    // },
    {
      dataField: "path_img",
      text: "รูป",
      sort: true,
      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return !isEmpty(cell) ? (
          <img
            onClick={() => eventAction("Edit", row.id, true)}
            src={process.env.REACT_APP_SERVER + cell}
            alt={process.env.REACT_APP_NAMECOMPANY}
            title={process.env.REACT_APP_NAMECOMPANY}
            className="avatar-md"
          />
        ) : null
      },
    },
    {
      dataField: "code",
      text: "รหัส",
      sort: true,
      headerStyle: () => {
        return { width: "200px" }
      },
    },
    {
      dataField: "price",
      text: "ราคาขาย (บาท)",
      sort: true,
      headerStyle: () => {
        return { width: "120px" }
      },
      formatter: function action(cell, row) {
        return (
          <span>
            {parseFloat(cell).toLocaleString("th-TH", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </span>
        )
      },
    },

    {
      dataField: "product_groups.name",
      text: "กลุ่ม",
      sort: true,
      headerStyle: () => {
        return { width: "100px" }
      },
    },
    {
      dataField: "reduced",
      text: "ลดได้สุด (บาท)",
      sort: true,
      headerStyle: () => {
        return { width: "120px" }
      },
      formatter: function action(cell, row) {
        return (
          <span>
            {parseFloat(cell).toLocaleString("th-TH", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </span>
        )
      },
    },
    {
      dataField: "cost",
      text: "ต้นทุน (บาท)",
      sort: true,
      headerStyle: () => {
        return { width: "120px" }
      },
      formatter: function action(cell, row) {
        return (
          <span>
            {parseFloat(cell).toLocaleString("th-TH", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </span>
        )
      },
    },

    // {
    //   dataField: "type",
    //   text: "ประเภท",
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: "100px" }
    //   },
    //   formatter: function action(cell, row) {
    //     if (cell == 1) {
    //       return <span>แบบบ้าน</span>
    //     } else if (cell == 2) {
    //       return <span>บ้านสำเร็จรูป</span>
    //     } else if (cell == 3) {
    //       return <span>อื่น ๆ</span>
    //     }
    //   },
    // },

    {
      dataField: "id",
      text: "จัดการ",
      sort: true,

      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return (
          <div className="d-flex">
            <Button
              onClick={() => eventAction("Edit", cell, true)}
              className="mr-1 mb-1"
              color="primary"
              outline
            >
              <i className="bx bx-pencil"></i>
            </Button>
            {/* &nbsp;
            <Button
              onClick={() => selectOnCallBack(row)}
              className="mr-1 mb-1 "
              color="success"
              outline
            >
              <i className="bx bx-comment-check"></i>
            </Button> */}
          </div>
        )
      },
    },
  ])
  const [selectGroups, setSelectGroups] = React.useState(0)
  // Table Data
  const [tableData, setTableData] = React.useState([]) // set campaign as default
  const [modal, setModal] = React.useState(false)
  const [creatNew, setCreateNew] = React.useState(false)
  const [selectProduct, setSelectProduct] = React.useState(1)
  const [dataProductGroups, setDataProductGroups] = React.useState([])

  const [actionModalProduct, setActionModelProduct] = React.useState({
    id: null,
    action: null,
    modal: false,
  })

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  React.useEffect(() => {
    // console.log(productGroups);
    if (!isEmpty(productGroups)) {
      setDataProductGroups(productGroups)
    } else {
      setDataProductGroups([])
    }
  }, [productGroups])

  function selectOnProduct(value) {
    setSelectProduct(value)
    setTableData(designhomes.filter(product => product.type == value))
  }

  function selectOnCallBack(data) {
    callBack(data)
    setModal(false)
  }

  React.useEffect(() => {
    if (!isEmpty(designhomes)) {
      designhomes.map(function (item) {
        item.code = item.type == 2 || item.type == 3 ? item.name : item.code
      })
      setTableData(designhomes)
      selectOnProduct(selectProduct)
      selectOnGroups(0)
    } else {
      setTableData([])
    }
  }, [designhomes])

  React.useMemo(() => {
    if (actionModal.modal) {
      setModal(actionModal.modal)
      dispatch(onGetDesignHomes("backend"))
      dispatch(onGetProductGroups(1))
    }
    return () => {}
  }, [actionModal]) // Only re-run the effect if count changes

  const { SearchBar } = Search

  function eventAction(action, id, modal) {
    if (action == "Add") {
      let createData = {
        type: selectProduct,
      }
      const data = new FormData()
      data.append("formData", JSON.stringify(createData))
      dispatch(onAddNewDesignHome(data))
      setCreateNew(true)
    } else if (action == "Edit") {
      setActionModelProduct({
        id: id,
        action: action,
        modal: modal,
      })
    }
  }
  React.useEffect(() => {
    // console.log(designhomeProfile.id)
    if (creatNew == true) {
      eventAction("Edit", designhomeProfile.id, true)
      setCreateNew(false)
      // }
    }
  }, [designhomeProfile])

  function selectOnGroups(id) {
    if (id == 0) {
      setSelectGroups(id)
      setTableData(designhomes.filter(product => product.type == selectProduct))
    } else {
      setSelectGroups(id)
      setTableData(
        designhomes.filter(product => product.product_group_id == id)
      )
    }
  }

  return (
    <>
      <ExampleModalProduct actionModal={actionModalProduct} />
      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        centered={true}
        size="lg"
        style={{ maxWidth: "90%" }}
      >
        <ModalHeader toggle={() => setModal(!modal)}>
          designhomes Datatable
          <FormGroup className="mt-2">
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  name="selectProduct"
                  checked={selectProduct == 1}
                  value={1}
                  onChange={e => {
                    selectOnProduct(e.target.value)
                  }}
                />
                แบบบ้าน
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label>
                <Input
                  type="radio"
                  name="selectProduct"
                  checked={selectProduct == 2}
                  value={2}
                  onChange={e => {
                    selectOnProduct(e.target.value)
                  }}
                />
                บ้านสำเร็จรูป
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label>
                <Input
                  type="radio"
                  name="selectProduct"
                  checked={selectProduct == 3}
                  value={3}
                  onChange={e => {
                    selectOnProduct(e.target.value)
                  }}
                />
                อื่น ๆ
              </Label>
            </FormGroup>
          </FormGroup>
          {selectProduct == 3 ? (
            <FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="selectGroups"
                    checked={selectGroups == 0}
                    value={0}
                    onChange={e => {
                      selectOnGroups(e.target.value)
                    }}
                  />
                  ทั้งหมด
                </Label>
              </FormGroup>

              {dataProductGroups.map((v, k) => (
                <FormGroup check inline key={k}>
                  <Label check>
                    <Input
                      type="radio"
                      name="selectGroups"
                      checked={v.id == selectGroups}
                      value={v.id}
                      onChange={e => {
                        selectOnGroups(e.target.value)
                      }}
                    />
                    {v.name}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
          ) : null}
        </ModalHeader>

        <ModalBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={tableColumns}
            data={tableData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                columns={tableColumns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={() => eventAction("Add", null, null)}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Add
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered
                            striped
                            defaultSorted={defaultSorted}
                            classes={"table align-middle"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </ModalBody>
      </Modal>
    </>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
  callBack: PropTypes.object,
}

export default ExampleModal
