import {
  GET_CUSTOM_HOME_PROFILE,
  GET_CUSTOM_HOME_PROFILE_FAIL,
  GET_CUSTOM_HOME_PROFILE_SUCCESS,
  GET_CUSTOM_HOMES,
  GET_CUSTOM_HOMES_FAIL,
  GET_CUSTOM_HOMES_SUCCESS,
  ADD_NEW_CUSTOM_HOME,
  ADD_CUSTOM_HOME_SUCCESS,
  ADD_CUSTOM_HOME_FAIL,
  UPDATE_CUSTOM_HOME,
  UPDATE_CUSTOM_HOME_SUCCESS,
  UPDATE_CUSTOM_HOME_FAIL,
  DELETE_CUSTOM_HOME,
  DELETE_CUSTOM_HOME_SUCCESS,
  DELETE_CUSTOM_HOME_FAIL,
} from "./actionTypes"

export const getCustomHomes = id => ({
  type: GET_CUSTOM_HOMES,
  payload: id,
})

export const getCustomHomesSuccess = designgroups => ({
  type: GET_CUSTOM_HOMES_SUCCESS,
  payload: designgroups,
})

export const addNewCustomHome = designgroup => ({
  type: ADD_NEW_CUSTOM_HOME,
  payload: designgroup,
})

export const addCustomHomeSuccess = designgroup => ({
  type: ADD_CUSTOM_HOME_SUCCESS,
  payload: designgroup,
})

export const addCustomHomeFail = error => ({
  type: ADD_CUSTOM_HOME_FAIL,
  payload: error,
})

export const getCustomHomesFail = error => ({
  type: GET_CUSTOM_HOMES_FAIL,
  payload: error,
})

export const getCustomHomeProfile = id => ({
  type: GET_CUSTOM_HOME_PROFILE,
  payload: id,
})

export const getCustomHomeProfileSuccess = designgroupProfile => ({
  type: GET_CUSTOM_HOME_PROFILE_SUCCESS,
  payload: designgroupProfile,
})

export const getCustomHomeProfileFail = error => ({
  type: GET_CUSTOM_HOME_PROFILE_FAIL,
  payload: error,
})

export const updateCustomHome = designgroup => ({
  type: UPDATE_CUSTOM_HOME,
  payload: designgroup,
})

// export const updateCustomHome = designgroup => console.log(designgroup);

export const updateCustomHomeSuccess = designgroup => ({
  type: UPDATE_CUSTOM_HOME_SUCCESS,
  payload: designgroup,
})

export const updateCustomHomeFail = error => ({
  type: UPDATE_CUSTOM_HOME_FAIL,
  payload: error,
})

export const deleteCustomHome = designgroup => ({
  type: DELETE_CUSTOM_HOME,
  payload: designgroup,
})

export const deleteCustomHomeSuccess = designgroup => ({
  type: DELETE_CUSTOM_HOME_SUCCESS,
  payload: designgroup,
})

export const deleteCustomHomeFail = error => ({
  type: DELETE_CUSTOM_HOME_FAIL,
  payload: error,
})
