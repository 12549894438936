import {
  GET_BANK_PROFILE,
  GET_BANK_PROFILE_FAIL,
  GET_BANK_PROFILE_SUCCESS,
  GET_BANKS,
  GET_BANKS_FAIL,
  GET_BANKS_SUCCESS,
  ADD_NEW_BANK,
  ADD_BANK_SUCCESS,
  ADD_BANK_FAIL,
  UPDATE_BANK,
  UPDATE_BANK_SUCCESS,
  UPDATE_BANK_FAIL,
  DELETE_BANK,
  DELETE_BANK_SUCCESS,
  DELETE_BANK_FAIL,
} from "./actionTypes"

export const getBanks = active => ({
  type: GET_BANKS,
  payload: active,
})

export const getBanksSuccess = designgroups => ({
  type: GET_BANKS_SUCCESS,
  payload: designgroups,
})

export const addNewBank = designgroup => ({
  type: ADD_NEW_BANK,
  payload: designgroup,
})

export const addBankSuccess = designgroup => ({
  type: ADD_BANK_SUCCESS,
  payload: designgroup,
})

export const addBankFail = error => ({
  type: ADD_BANK_FAIL,
  payload: error,
})

export const getBanksFail = error => ({
  type: GET_BANKS_FAIL,
  payload: error,
})

export const getBankProfile = id => ({
  type: GET_BANK_PROFILE,
  payload: id,
})

export const getBankProfileSuccess = designgroupProfile => ({
  type: GET_BANK_PROFILE_SUCCESS,
  payload: designgroupProfile,
})

export const getBankProfileFail = error => ({
  type: GET_BANK_PROFILE_FAIL,
  payload: error,
})

export const updateBank = designgroup => ({
  type: UPDATE_BANK,
  payload: designgroup,
})

// export const updateBank = designgroup => console.log(designgroup);

export const updateBankSuccess = designgroup => ({
  type: UPDATE_BANK_SUCCESS,
  payload: designgroup,
})

export const updateBankFail = error => ({
  type: UPDATE_BANK_FAIL,
  payload: error,
})

export const deleteBank = designgroup => ({
  type: DELETE_BANK,
  payload: designgroup,
})

export const deleteBankSuccess = designgroup => ({
  type: DELETE_BANK_SUCCESS,
  payload: designgroup,
})

export const deleteBankFail = error => ({
  type: DELETE_BANK_FAIL,
  payload: error,
})
