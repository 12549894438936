import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
} from "reactstrap"
import { isEmpty } from "lodash"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  getListQcProfile as onGetListQcProfile,
  addNewListQc as onAddNewListQc,
  updateListQc as onUpdateListQc,
} from "store/list-qcs/actions"
import Select from "react-select"
import { getListStructures as onGetList_structures } from "store/actions"

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()

  const { listQcProfile } = useSelector(state => ({
    listQcProfile: state.listQcs.listQcProfile,
  }))

  const { ListStructures } = useSelector(state => ({
    ListStructures: state.listStructures.listStructures,
  }))

  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState(listQcProfile)
  const [dataListStructures, setDataListStructures] = React.useState([])

  const [q_c_detail, setQ_c_details] = React.useState([])

  async function checkAction() {
    setFormData({})
    setQ_c_details([])
    if (actionModal.action == "Add") {
      setFormData({
        active: 1,
      })
    } else if (actionModal.action == "Edit") {
      dispatch(onGetListQcProfile(actionModal.id))
    }

    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true)
    const data = new FormData()
    data.append("formData", JSON.stringify(formData))
    data.append("q_c_detail", JSON.stringify(q_c_detail))

    if (errors.length > 0) {
    } else {
      if (action == "Add") {
        // save new QC
        dispatch(onAddNewListQc(data))
      } else if (action == "Edit") {
        // update QC
        dispatch(onUpdateListQc(data))
      }
      setModal(!modal)
    }
    setButtonSubmit(false)
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  React.useEffect(() => {
    dispatch(onGetList_structures(1, 0))
  }, [dispatch])

  React.useEffect(async () => {
    if (!isEmpty(ListStructures)) {
      const options = await ListStructures.map(d => ({
        value: d.id,
        label: d.name,
      }))

      setDataListStructures(options)
    } else {
      setDataListStructures([])
    }
  }, [ListStructures])

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      let { q_c_detail, ...reset } = listQcProfile
      setFormData(reset)
      setQ_c_details(q_c_detail)
    }
    return () => {}
  }, [listQcProfile]) // Only re-run the effect if count changes

  function addRow() {
    setQ_c_details([
      ...q_c_detail,
      { key: q_c_detail.length, name: null, del: 1 },
    ])
  }
  function handleRemoveItem(key) {
    var modifiedRows = [...q_c_detail]
    for (let index = 0; index < modifiedRows.length; index++) {
      if (modifiedRows[index].key == key) {
        modifiedRows[index].del = 0
      }
    }
    setQ_c_details(modifiedRows)
  }

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
      size="lg"
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}การตรวจเช็คงาน{" "}
      </ModalHeader>
      <AvForm onSubmit={handleSubmit} model={formData}>
        <ModalBody>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Select
                type="select"
                className="react-select-container"
                classNamePrefix="react-select"
                name="list_structure_id"
                value={dataListStructures.filter(function (option) {
                  return option.value == formData.list_structure_id
                })}
                onChange={e =>
                  setFormData({
                    ...formData,
                    list_structure_id: e.value,
                  })
                }
                options={dataListStructures}
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup className="mb-3">
                <AvField
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  onChange={e =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup className="mb-3">
                <AvField
                  name="detail"
                  type="text"
                  placeholder="Detail"
                  value={formData.detail}
                  onChange={e =>
                    setFormData({ ...formData, detail: e.target.value })
                  }
                  validate={{
                    required: { value: false },
                  }}
                />
              </FormGroup>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup className="mt-2">
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 1}
                      value={1}
                      onChange={e =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    เปิดใช้งาน
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 2}
                      value={2}
                      onChange={e =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    ปิดใช้งาน
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>

          {q_c_detail.map((item, index) =>
            item.del == 0 ? null : (
              <Row key={index} className="mt-2">
                <Col xs={12} sm={12} md={5} lg={5}>
                  <AvField
                    name={"q_c_detail_name" + [index]}
                    type="text"
                    placeholder="ชื่อการตรวจ"
                    onChange={e => {
                      let select = [...q_c_detail]
                      select[index].name = e.target.value
                      setQ_c_details(select)
                    }}
                    value={q_c_detail[index].name}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>

                <Col xs={10} sm={10} md={5} lg={5}>
                  <AvField
                    name={"detail" + [index]}
                    type="text"
                    placeholder="รายละเอียด"
                    onChange={e => {
                      let select = [...q_c_detail]
                      select[index].detail = e.target.value
                      setQ_c_details(select)
                    }}
                    value={q_c_detail[index].detail}
                    validate={{
                      required: { value: false },
                    }}
                  />
                </Col>

                <Col xs={2} sm={2} md={2} lg={2}>
                  <Button
                    color="danger"
                    onClick={() => handleRemoveItem(item.key)}
                  >
                    <i className="mdi mdi-block-helper " />
                  </Button>
                </Col>
              </Row>
            )
          )}
          <Row className="mt-2">
            <Col xs={10} sm={10} md={10} lg={10}></Col>

            <Col xs={2} sm={2} md={2} lg={2}>
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={() => addRow()}
              >
                <i className="mdi mdi-plus-circle-outline" />
              </Button>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            Save
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
