import {
  GET_REFPROJECT_PROFILE,
  GET_REFPROJECT_PROFILE_FAIL,
  GET_REFPROJECT_PROFILE_SUCCESS,
  GET_REFPROJECTS,
  GET_REFPROJECTS_FAIL,
  GET_REFPROJECTS_SUCCESS,
  ADD_NEW_REFPROJECT,
  ADD_REFPROJECT_SUCCESS,
  ADD_REFPROJECT_FAIL,
  UPDATE_REFPROJECT,
  UPDATE_REFPROJECT_SUCCESS,
  UPDATE_REFPROJECT_FAIL,
  DELETE_REFPROJECT,
  DELETE_REFPROJECT_SUCCESS,
  DELETE_REFPROJECT_FAIL,
  UPLOAD_INFOPROJECTFILEREF,
} from "./actionTypes"

export const getRefprojects = id => ({
  type: GET_REFPROJECTS,
  payload: id,
})

export const getRefprojectsSuccess = refprojects => ({
  type: GET_REFPROJECTS_SUCCESS,
  payload: refprojects,
})

export const addNewRefproject = refproject => ({
  type: ADD_NEW_REFPROJECT,
  payload: refproject,
})

export const addRefprojectSuccess = refproject => ({
  type: ADD_REFPROJECT_SUCCESS,
  payload: refproject,
})

export const addRefprojectFail = error => ({
  type: ADD_REFPROJECT_FAIL,
  payload: error,
})

export const getRefprojectsFail = error => ({
  type: GET_REFPROJECTS_FAIL,
  payload: error,
})

export const getRefprojectProfile = id => ({
  type: GET_REFPROJECT_PROFILE,
  payload: id,
})

export const getRefprojectProfileSuccess = refprojectProfile => ({
  type: GET_REFPROJECT_PROFILE_SUCCESS,
  payload: refprojectProfile,
})

export const getRefprojectProfileFail = error => ({
  type: GET_REFPROJECT_PROFILE_FAIL,
  payload: error,
})

export const updateRefproject = refproject => ({
  type: UPDATE_REFPROJECT,
  payload: refproject,
})

// export const updateRefproject = refproject => console.log(refproject);

export const updateRefprojectSuccess = refproject => ({
  type: UPDATE_REFPROJECT_SUCCESS,
  payload: refproject,
})

export const updateRefprojectFail = error => ({
  type: UPDATE_REFPROJECT_FAIL,
  payload: error,
})

export const deleteRefproject = refproject => ({
  type: DELETE_REFPROJECT,
  payload: refproject,
})

export const deleteRefprojectSuccess = refproject => ({
  type: DELETE_REFPROJECT_SUCCESS,
  payload: refproject,
})

export const deleteRefprojectFail = error => ({
  type: DELETE_REFPROJECT_FAIL,
  payload: error,
})

export const uploadImgInfoRef = dataImg => ({
  type: UPLOAD_INFOPROJECTFILEREF,
  payload: dataImg,
})

// export const uploadImgInfoRef = dataImg => console.log(dataImg)
