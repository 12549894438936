/* LIST_DESIGN_GROUPS */
export const GET_LIST_DESIGN_GROUPS = "GET_LIST_DESIGN_GROUPS"
export const GET_LIST_DESIGN_GROUPS_SUCCESS = "GET_LIST_DESIGN_GROUPS_SUCCESS"
export const GET_LIST_DESIGN_GROUPS_FAIL = "GET_LIST_DESIGN_GROUPS_FAIL"

/* LIST_DESIGN_GROUPS PROFILE */
export const GET_LIST_DESIGN_GROUP_PROFILE = "GET_LIST_DESIGN_GROUP_PROFILE"
export const GET_LIST_DESIGN_GROUP_PROFILE_SUCCESS = "GET_LIST_DESIGN_GROUP_PROFILE_SUCCESS"
export const GET_LIST_DESIGN_GROUP_PROFILE_FAIL = "GET_LIST_DESIGN_GROUP_PROFILE_FAIL"

/**
 * add designgroup
 */
export const ADD_NEW_LIST_DESIGN_GROUP = "ADD_NEW_LIST_DESIGN_GROUP"
export const ADD_LIST_DESIGN_GROUP_SUCCESS = "ADD_LIST_DESIGN_GROUP_SUCCESS"
export const ADD_LIST_DESIGN_GROUP_FAIL = "ADD_LIST_DESIGN_GROUP_FAIL"

/**
 * Edit designgroup
 */
export const UPDATE_LIST_DESIGN_GROUP = "UPDATE_LIST_DESIGN_GROUP"
export const UPDATE_LIST_DESIGN_GROUP_SUCCESS = "UPDATE_LIST_DESIGN_GROUP_SUCCESS"
export const UPDATE_LIST_DESIGN_GROUP_FAIL = "UPDATE_LIST_DESIGN_GROUP_FAIL"

/**
 * Delete designgroup
 */
export const DELETE_LIST_DESIGN_GROUP = "DELETE_LIST_DESIGN_GROUP"
export const DELETE_LIST_DESIGN_GROUP_SUCCESS = "DELETE_LIST_DESIGN_GROUP_SUCCESS"
export const DELETE_LIST_DESIGN_GROUP_FAIL = "DELETE_LIST_DESIGN_GROUP_FAIL"
