import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
//pass new generated access token here
const token = accessToken
//apply base url for axios
const API_URL = process.env.REACT_APP_SERVER + "api"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = "Bearer " + token

axiosApi.interceptors.request.use(request => {
  // console.log(request.method)
  if (request.method === "put" && request.data instanceof FormData) {
    let x = request.data
    let getID = JSON.parse(x.get("formData"))
    request.url = request.url + "/" + getID.id
    request.method = "post"
    request.data.append("_method", "put")
  } else if (request.method === "put") {
    // console.log("data =>", request.data.id)
    let getID = request.data
    request.url = request.url + "/" + getID.id
  }
  return request
})

axiosApi.interceptors.response.use(
  response => response,
  async function (error) {
    if (error.response.status === 401) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (obj.accessToken != token) {
        window.location.reload()
      } else {
        const myPromise = new Promise((resolve, reject) => {
          localStorage.clear()
          resolve()
        })
        myPromise.then(() => {
          window.location = "/login"
        })
      }
    }
    return Promise.reject(error)
  }
)

// axiosApi.interceptors.response.use(
//   response => {
//     return response
//   },
//   error => {
//     if (error.response.status === 401) {
//       window.location = "/login"
//     }
//     return error
//   }
// )

export async function get(url, config = {}) {
  if (url != undefined) {
    return await axiosApi
      .get(url, { ...config })
      .then(response => response.data)
  }
}

export async function post(url, data, config = {}) {
  // console.log(data);
  if (url != undefined) {
    return axiosApi
      .post(url, data, { ...config })
      .then(response => response.data)
  }
}

export async function put(url, data, config = {}) {
  if (url != undefined) {
    return axiosApi
      .put(url, data, { ...config })
      .then(response => response.data)
  }
}

export async function del(url, config = {}) {
  if (url != undefined) {
    return await axiosApi
      .delete(url, { ...config })
      .then(response => response.data)
  }
}
