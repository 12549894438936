import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_LIST_AIUMINIA_PROJECTS,
  GET_LIST_AIUMINIA_PROJECTS_PROFILE,
  ADD_NEW_LIST_AIUMINIA_PROJECTS,
  DELETE_LIST_AIUMINIA_PROJECTS,
  UPDATE_LIST_AIUMINIA_PROJECTS,
} from "./actionTypes"

import {
  getListAluminiaProjectsSuccess,
  getListAluminiaProjectsFail,
  getListAluminiaProjectsProfileSuccess,
  getListAluminiaProjectsProfileFail,
  addListAluminiaProjectsFail,
  addListAluminiaProjectsSuccess,
  updateListAluminiaProjectsSuccess,
  updateListAluminiaProjectsFail,
  deleteListAluminiaProjectsSuccess,
  deleteListAluminiaProjectsFail,
} from "./actions"

import { getBoqTitleHomesSuccess } from "../boq-title-homes/actions"

//Include Both Helper File with needed methods
import {
  getListAluminiaProjects,
  getListAluminiaProjectsProfile,
  addNewListAluminiaProjects,
  updateListAluminiaProjects,
  deleteListAluminiaProjects,
} from "../../helpers/fakebackend_helper"

function* fetchListAluminiaProjects({ payload: data }) {
  // console.log("fetchListAluminiaProjects", data)
  try {
    const response = yield call(getListAluminiaProjects, data)
    // console.log(response);
    yield put(getListAluminiaProjectsSuccess(response))
  } catch (error) {
    yield put(getListAluminiaProjectsFail(error))
  }
}

function* fetchListAluminiaProjectsProfile({ payload: id }) {
  // console.log(id)updateListAluminiaProjects
  try {
    const response = yield call(getListAluminiaProjectsProfile, id)
    // console.log("Profile => ", response)
    yield put(getListAluminiaProjectsProfileSuccess(response))
  } catch (error) {
    yield put(getListAluminiaProjectsProfileFail(error))
  }
}

function* onUpdateListAluminiaProjects({ payload: aluminia }) {
  try {
    // let x = aluminia.get('formData')
    // console.log(x)
    const response = yield call(updateListAluminiaProjects, aluminia)
    // console.log(response)
    // yield put(updateListAluminiaProjectsSuccess(response))
    yield put(getBoqTitleHomesSuccess(response))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateListAluminiaProjectsFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteListAluminiaProjects({ payload: aluminia }) {
  try {
    const response = yield call(deleteListAluminiaProjects, aluminia)
    yield put(deleteListAluminiaProjectsSuccess(response))
  } catch (error) {
    yield put(deleteListAluminiaProjectsFail(error))
  }
}

function* onAddNewListAluminiaProjects({ payload: aluminia }) {
  try {
    const response = yield call(addNewListAluminiaProjects, aluminia)
    // console.log("onAddNewListAluminiaProjects", response)
    yield put(getBoqTitleHomesSuccess(response))
    // yield put(addListAluminiaProjectsSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addListAluminiaProjectsFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* listAluminiaProject() {
  yield takeEvery(GET_LIST_AIUMINIA_PROJECTS, fetchListAluminiaProjects)
  yield takeEvery(
    GET_LIST_AIUMINIA_PROJECTS_PROFILE,
    fetchListAluminiaProjectsProfile
  )
  yield takeEvery(ADD_NEW_LIST_AIUMINIA_PROJECTS, onAddNewListAluminiaProjects)
  yield takeEvery(UPDATE_LIST_AIUMINIA_PROJECTS, onUpdateListAluminiaProjects)
  yield takeEvery(DELETE_LIST_AIUMINIA_PROJECTS, onDeleteListAluminiaProjects)
}

export default listAluminiaProject
