import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"
import infoprojects from "./info-project/reducer"
import refprojects from "./refs-project/reducer"
import serwayprojects from "./serways-project/reducer"
import slipprojects from "./slips-project/reducer"
import quotationprojects from "./quotations-project/reducer"
import profitlossprojects from "./profit-loss/reducer"

//tasks
import tasks from "./tasks/reducer"

// user
import users from "./users/reducer"
import userGroups from "./user-groups/reducer"

// money
import listProducts from "./list-products/reducer"
import productGroups from "./product-groups/reducer"
import banks from "./banks/reducer"

// stock
import materialTypes from "./material-types/reducer"
import materialPrices from "./material-prices/reducer"

import materialStocks from "./material-stocks/reducer"
import withdrawMaterials from "./withdraw-materials/reducer"

import materialShops from "./material-shops/reducer"
import messageLists from "./message-lists/reducer"

import attends from "./attends/reducer"

// contractor
import contractors from "./contractors/reducer"
import boqs from "./boqs/reducer"
import listStructures from "./list-structures/reducer"
import listAluminias from "./list-aluminias/reducer"
import listAluminiasProjects from "./list-aluminias-projects/reducer"

import quotationsConProject from "./quotations-con-project/reducer"
import contactsConproject from "./contacts-con-project/reducer"
import employmentsConproject from "./employments-con-project/reducer"


import listQcs from "./list-qcs/reducer"
import listDesigngroup from "./list-design-groups/reducer"
import designs from "./designs/reducer"
import commentHomes from "./comment-homes/reducer"
import customHomes from "./custom-homes/reducer"
import boqHomes from "./boq-homes/reducer"
import boqTitleHomes from "./boq-title-homes/reducer"

// visitor
import designHomes from "./design-homes/reducer"
import customProject from "./custom-projects/reducer"

//mails
import mails from "./mails/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,

  projects,
  infoprojects,
  refprojects,
  serwayprojects,
  slipprojects,
  quotationprojects,
  profitlossprojects,
  tasks,

  //visitors
  designHomes,

  // money
  listProducts,
  productGroups,
  banks,
  // stock
  materialPrices,
  materialTypes,
  materialStocks,
  materialShops,
  messageLists,
  withdrawMaterials,
  attends,
  // contractor
  contractors,
  boqs,
  listStructures,
  listAluminias,
  listAluminiasProjects,
  quotationsConProject,
  contactsConproject,
  employmentsConproject,

  

  boqHomes,
  boqTitleHomes,
  listQcs,
  listDesigngroup,
  designs,
  customProject,
  commentHomes,
  customHomes,
  // setting
  users,
  userGroups,

  Dashboard,
  DashboardSaas,
})

export default rootReducer
