import {
  GET_MATERIAL_SHOP_PROFILE,
  GET_MATERIAL_SHOP_PROFILE_FAIL,
  GET_MATERIAL_SHOP_PROFILE_SUCCESS,
  GET_MATERIAL_SHOPS,
  GET_MATERIAL_SHOPS_FAIL,
  GET_MATERIAL_SHOPS_SUCCESS,
  ADD_NEW_MATERIAL_SHOP,
  ADD_MATERIAL_SHOP_SUCCESS,
  ADD_MATERIAL_SHOP_FAIL,
  UPDATE_MATERIAL_SHOP,
  UPDATE_MATERIAL_SHOP_SUCCESS,
  UPDATE_MATERIAL_SHOP_FAIL,
  DELETE_MATERIAL_SHOP,
  DELETE_MATERIAL_SHOP_SUCCESS,
  DELETE_MATERIAL_SHOP_FAIL,
} from "./actionTypes"

export const getMaterialShops = active => ({
  type: GET_MATERIAL_SHOPS,
  payload: active,
})

export const getMaterialShopsSuccess = materialshops => ({
  type: GET_MATERIAL_SHOPS_SUCCESS,
  payload: materialshops,
})

export const addNewMaterialShop = materialshop => ({
  type: ADD_NEW_MATERIAL_SHOP,
  payload: materialshop,
})

export const addMaterialShopSuccess = materialshop => ({
  type: ADD_MATERIAL_SHOP_SUCCESS,
  payload: materialshop,
})

export const addMaterialShopFail = error => ({
  type: ADD_MATERIAL_SHOP_FAIL,
  payload: error,
})

export const getMaterialShopsFail = error => ({
  type: GET_MATERIAL_SHOPS_FAIL,
  payload: error,
})

export const getMaterialShopProfile = id => ({
  type: GET_MATERIAL_SHOP_PROFILE,
  payload: id,
})

export const getMaterialShopProfileSuccess = materialshopProfile => ({
  type: GET_MATERIAL_SHOP_PROFILE_SUCCESS,
  payload: materialshopProfile,
})

export const getMaterialShopProfileFail = error => ({
  type: GET_MATERIAL_SHOP_PROFILE_FAIL,
  payload: error,
})

export const updateMaterialShop = materialshop => ({
  type: UPDATE_MATERIAL_SHOP,
  payload: materialshop,
})

// export const updateMaterialShop = materialshop => console.log(materialshop);

export const updateMaterialShopSuccess = materialshop => ({
  type: UPDATE_MATERIAL_SHOP_SUCCESS,
  payload: materialshop,
})

export const updateMaterialShopFail = error => ({
  type: UPDATE_MATERIAL_SHOP_FAIL,
  payload: error,
})

export const deleteMaterialShop = materialshop => ({
  type: DELETE_MATERIAL_SHOP,
  payload: materialshop,
})

export const deleteMaterialShopSuccess = materialshop => ({
  type: DELETE_MATERIAL_SHOP_SUCCESS,
  payload: materialshop,
})

export const deleteMaterialShopFail = error => ({
  type: DELETE_MATERIAL_SHOP_FAIL,
  payload: error,
})
