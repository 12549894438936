/* REFPROJECTS */
export const GET_REFPROJECTS = "GET_REFPROJECTS"
export const GET_REFPROJECTS_SUCCESS = "GET_REFPROJECTS_SUCCESS"
export const GET_REFPROJECTS_FAIL = "GET_REFPROJECTS_FAIL"

/* REFPROJECTS PROFILE */
export const GET_REFPROJECT_PROFILE = "GET_REFPROJECT_PROFILE"
export const GET_REFPROJECT_PROFILE_SUCCESS = "GET_REFPROJECT_PROFILE_SUCCESS"
export const GET_REFPROJECT_PROFILE_FAIL = "GET_REFPROJECT_PROFILE_FAIL"

/**
 * add refproject
 */
export const ADD_NEW_REFPROJECT = "ADD_NEW_REFPROJECT"
export const ADD_REFPROJECT_SUCCESS = "ADD_REFPROJECT_SUCCESS"
export const ADD_REFPROJECT_FAIL = "ADD_REFPROJECT_FAIL"

/**
 * Edit refproject
 */
export const UPDATE_REFPROJECT = "UPDATE_REFPROJECT"
export const UPDATE_REFPROJECT_SUCCESS = "UPDATE_REFPROJECT_SUCCESS"
export const UPDATE_REFPROJECT_FAIL = "UPDATE_REFPROJECT_FAIL"

/**
 * Delete refproject
 */
export const DELETE_REFPROJECT = "DELETE_REFPROJECT"
export const DELETE_REFPROJECT_SUCCESS = "DELETE_REFPROJECT_SUCCESS"
export const DELETE_REFPROJECT_FAIL = "DELETE_REFPROJECT_FAIL"

/**
 * Upload File
 */
 export const UPLOAD_INFOPROJECTFILEREF = "UPLOAD_INFOPROJECTFILEREF"