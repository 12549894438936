import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
  Table,
  InputGroup,
  Card,
} from "reactstrap"
import { isEmpty } from "lodash"
import { AvForm, AvField } from "availity-reactstrap-validation"
import imageCompression from "browser-image-compression"

import Dropzone from "react-dropzone"
import {
  getListAluminiaProfile as onGetListAluminiaProfile,
  addNewListAluminia as onAddNewListAluminia,
  updateListAluminia as onUpdateListAluminia,
} from "store/list-aluminias/actions"
import { getMaterialStocks as onGetMaterialStocks } from "store/material-stocks/actions"
import ExampleModalStock from "../../components/Dialogs/dialogMaterial_stock"

import Select from "react-select"

const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()

  const { listAluminiaProfile } = useSelector(state => ({
    listAluminiaProfile: state.listAluminias.listAluminiaProfile,
  }))
  const { materialStocks } = useSelector(state => ({
    materialStocks: state.materialStocks.materialStocks,
  }))

  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState(listAluminiaProfile)
  const [dataMaterialStocks, setDataMaterialStocks] = React.useState([])
  const [selectedFiles, setselectedFiles] = React.useState([])
  const [img_slip, setImg_slip] = React.useState(null)
  const [img_preview, setImg_preview] = React.useState(null)

  const [dataImg_slip, setDataImg_slip] = React.useState({})
  const [headerTitle, setHeaderTitle] = React.useState([])
  const [subHeaderTitle, setSubHeaderTitle] = React.useState([])
  const [dataType_1, setDataType_1] = React.useState([])
  const [dataCalType_name_1, setDataCalType_name_1] = React.useState([])

  const [dataEquations, setDataEquations] = React.useState([
    {
      value: 0,
      label: "ไม่มี",
    },
    {
      value: 1,
      label: "+ (บวก)",
    },
    {
      value: 2,
      label: "- (ลบ)",
    },
    {
      value: 3,
      label: "* (คูณ)",
    },
    {
      value: 4,
      label: "/ (หาร)",
    },
  ])
  const [dataEquationTypes, setDataEquationTypes] = React.useState([
    {
      value: 0,
      label: "คงที",
    },
    {
      value: 1,
      label: "ไม่คงที่",
    },
  ])

  const [dataCalType_name, setDataCalType_name] = React.useState([
    {
      value: 0,
      label: "หัวข้อ",
    },
    {
      value: 1,
      label: "รายการ",
    },
    {
      value: 2,
      label: "ไม่มี",
    },
  ])

  const [actionModalMaterialStock, setActionModalMaterialStock] =
    React.useState({
      id: null,
      action: null,
      modal: false,
    })
  // Table Data
  const [tableData, setTableData] = React.useState([]) // set campaign as default

  async function checkAction() {
    setFormData({})
    setHeaderTitle([])
    setSubHeaderTitle([])
    setImg_preview(null)
    setImg_slip(null)
    setDataImg_slip(null)
    if (actionModal.action == "Add") {
      setFormData({
        del: 1,
        active: 1,
      })
    } else if (actionModal.action == "Edit") {
      dispatch(onGetListAluminiaProfile(actionModal.id))
    }
    dispatch(onGetMaterialStocks(1, 0))
    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true)
    if (errors.length > 0) {
    } else {
      const data = new FormData()
      data.append("formData", JSON.stringify(formData))
      data.append("headerTitle", JSON.stringify(headerTitle))
      data.append("subHeaderTitle", JSON.stringify(subHeaderTitle))

      data.append("img", dataImg_slip)

      if (action == "Add") {
        // save new contractor
        dispatch(onAddNewListAluminia(data))
      } else if (action == "Edit") {
        // update contractor
        dispatch(onUpdateListAluminia(data))
      }
      setModal(!modal)
    }
    setButtonSubmit(false)
  }

  async function handleAcceptedFiles(file) {
    file.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setDataImg_slip(await resizeImageFn(file[0]))
    // console.log("file", file[0].preview)
    setImg_preview(file[0].preview)
    setselectedFiles(file)
  }

  async function resizeImageFn(file) {
    // console.log("originalFile instanceof Blob", file instanceof Blob) // true
    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options)
      // console.log(
      //   "compressedFile instanceof Blob",
      //   compressedFile instanceof Blob
      // ) // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

      // await uploadToServer(compressedFile) // write your own logic
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseInt((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      let { headerTitle, subHeaderTitle, ...reset } = listAluminiaProfile
      // console.log("listAluminiaProfile", process.env.REACT_APP_SERVER + reset.path_img)
      if (reset.img != null) {
        setImg_slip(reset.path_img)
      }
      setHeaderTitle(headerTitle)
      setSubHeaderTitle(subHeaderTitle)
      setFormData(reset)
    }
    return () => {}
  }, [listAluminiaProfile]) // Only re-run the effect if count changes

  // React.useEffect(() => {
  //   console.log(tableData)
  //   return () => {}
  // }, [tableData]) // Only re-run the effect if count changes

  React.useEffect(async () => {
    // console.log(materialStocks)
    if (!isEmpty(materialStocks)) {
      let options = [
        {
          value: 0,
          label: "เพิ่มใหม่",
        },
      ]
      for (let index = 0; index < materialStocks.length; index++) {
        if (materialStocks[index].material_type_id == 13) {
          options.push({
            value: materialStocks[index].id,
            label: materialStocks[index].name,
            material_price: materialStocks[index].price,
            unit: materialStocks[index].unit,
            material_type: materialStocks[index].material_type,
          })
        }
      }
      // console.log("options", options)
      // const options = await materialStocks.map((d, index) =>
      //   d.material_type_id != 13
      //     ? null
      //     : {
      //         value: d.id,
      //         label: d.name,
      //         material_price: d.price,
      //         unit: d.unit,
      //         material_type: d.material_type,
      //       }
      // )

      setDataMaterialStocks(options)
    }
    return () => {}
  }, [materialStocks]) // Only re-run the effect if count changes

  React.useEffect(async () => {
    // console.log(materialStocks)
    if (!isEmpty(headerTitle)) {
      const options = await headerTitle.map((d, index) => ({
        value: d.key,
        label: d.type_name,
      }))
      setDataType_1(options)
    }
    return () => {}
  }, [headerTitle]) // Only re-run the effect if count changes

  React.useEffect(async () => {
    // console.log(materialStocks)
    if (!isEmpty(subHeaderTitle)) {
      // console.log("subHeaderTitle", subHeaderTitle)
      const options = await subHeaderTitle.map((d, index) => ({
        value: d.name,
        label: d.name,
      }))
      setDataCalType_name_1(options)
    }
    return () => {}
  }, [subHeaderTitle]) // Only re-run the effect if count changes

  const onAddRowTitle = () => {
    const modifiedRows = [...headerTitle]
    modifiedRows.push({
      key: modifiedRows.length + 1,
      type_name: "",
      name: "",
      widthorheight: 1,
      del: 1,
      equation_type: 0,
      // subTitle: [
      //   {
      //     key: 0,
      //     name: "",
      //     use: "",
      //     del: 1,
      //   },
      // ],
    })
    setHeaderTitle(modifiedRows)
  }

  function onDeletetRowTitle(key) {
    var modifiedRows = [...headerTitle]
    for (let index = 0; index < modifiedRows.length; index++) {
      if (modifiedRows[index].key == key) {
        modifiedRows[index].del = 0
      }
    }
    setHeaderTitle(modifiedRows)
  }

  const onAddRowTitleSubtitle = () => {
    const modifiedRows = [...subHeaderTitle]
    modifiedRows.push({
      key: modifiedRows.length + 1,
      name: modifiedRows.length + 1,
      material_id: null,
      type_name_1: "",
      equation: 0,
      equation_type: 1,
      type_name_2: "",
      caltype_name: 0,
      del: 1,
      // subTitle: [
      //   {
      //     key: 0,
      //     name: "",
      //     use: "",
      //     del: 1,
      //   },
      // ],
    })
    setSubHeaderTitle(modifiedRows)
  }

  function onDeleteRowTitleSubtitle(key) {
    var modifiedRows = [...subHeaderTitle]
    for (let index = 0; index < modifiedRows.length; index++) {
      if (modifiedRows[index].key == key) {
        modifiedRows[index].del = 0
      }
    }
    setSubHeaderTitle(modifiedRows)
  }

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
      style={{ maxWidth: "90%" }}
      size="lg"
    >
      <ExampleModalStock actionModal={actionModalMaterialStock} />

      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}อลูมิเนียม{" "}
      </ModalHeader>
      <AvForm onSubmit={handleSubmit} model={formData}>
        <ModalBody>
          <Row>
            <Col xs={12} sm={12} md={12} lg={6}>
              {img_preview == null ? (
                img_slip == null ? null : (
                  <a
                    href={process.env.REACT_APP_SERVER + img_slip}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* คลิก */}
                    <img
                      // src={slip}
                      src={process.env.REACT_APP_SERVER + img_slip}
                      // className="img-responsive"
                      style={{
                        width: "100%",
                      }}
                      alt={process.env.REACT_APP_NAMECOMPANY}
                      title={process.env.REACT_APP_NAMECOMPANY}
                    />
                  </a>
                )
              ) : (
                <a href={img_preview} target="_blank" rel="noreferrer">
                  <img
                    // src={slip}
                    src={img_preview}
                    // className="img-responsive"
                    style={{
                      width: "100%",
                    }}
                    alt={process.env.REACT_APP_NAMECOMPANY}
                    title={process.env.REACT_APP_NAMECOMPANY}
                  />
                </a>
              )}
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Dropzone
                    multiple={false}
                    accept="image/*"
                    inputContent={(files, extra) =>
                      extra.reject ? "Image files only" : "Drag Files"
                    }
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                    styles={{
                      dropzoneReject: {
                        borderColor: "red",
                        backgroundColor: "#DAA",
                      },
                      inputLabel: (files, extra) =>
                        extra.reject ? { color: "red" } : {},
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>เลือกไฟล์หรือลากไฟล์มาตรงนี้เพื่ออัพโหลดรูป</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>

                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <a
                                  href={f.preview}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </a>
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    })}
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <FormGroup className="mb-3">
                    <AvField
                      className="form-control"
                      id="label-name-Input"
                      name="name"
                      type="text"
                      placeholder="ชื่อ"
                      value={formData.name}
                      onChange={e =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={8} sm={8}>
                  <FormGroup className="mt-2">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="radioActive"
                          checked={formData.active == 1}
                          value={1}
                          onChange={e =>
                            setFormData({ ...formData, active: e.target.value })
                          }
                        />
                        เปิดใช้งาน
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label>
                        <Input
                          type="radio"
                          name="radioActive"
                          checked={formData.active == 2}
                          value={2}
                          onChange={e =>
                            setFormData({ ...formData, active: e.target.value })
                          }
                        />
                        ปิดใช้งาน
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>

                <Col xs={4} sm={4} style={{ textAlign: "right" }}>
                  <FormGroup className="mt-2">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="radioDel"
                          checked={formData.del == 0}
                          value={0}
                          onChange={e =>
                            setFormData({ ...formData, del: e.target.value })
                          }
                        />
                        ลบ
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row>
            {headerTitle.length > 0 ? (
              <Col sm="12">
                <Table className="table mb-0" responsive>
                  <thead>
                    <tr>
                      <th style={{ width: "30px" }}>#</th>
                      <th style={{ width: "120px" }}>ตัวแปร</th>
                      <th>ชื่อ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {headerTitle.map((v, k) => (
                      <tr key={k}>
                        {v.del == 0 ? null : (
                          <>
                            <th>
                              <Button
                                color="danger"
                                className="btn btn-danger "
                                onClick={() => onDeletetRowTitle(v.key)}
                              >
                                <i className="bx bx-x"></i>
                              </Button>
                            </th>

                            <th>
                              <Input
                                type="text"
                                name={"type_name" + k}
                                id={"type_name" + k}
                                value={v.type_name}
                                placeholder="หัวข้อหลัก"
                                onChange={e => {
                                  let newData = [...headerTitle]
                                  newData[k].type_name = e.target.value
                                  setHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </th>
                            <th>
                              <Input
                                type="text"
                                name={"name" + k}
                                id={"name" + k}
                                value={v.name}
                                placeholder="หัวข้อหลัก"
                                onChange={e => {
                                  let newData = [...headerTitle]
                                  newData[k].name = e.target.value
                                  setHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </th>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            ) : null}

            <Col sm="12">
              <Button
                size="sm"
                color="primary"
                className="btn btn-primary "
                onClick={() => onAddRowTitle()}
                style={{ width: "100%" }}
              >
                เพิ่มหัวข้อ/ตัวแปร
              </Button>
            </Col>

            {subHeaderTitle.length > 0 ? (
              <Col sm="12">
                <Table className="table mb-0" responsive>
                  <thead>
                    <tr>
                      <th style={{ width: "30px" }}>#</th>
                      <th style={{ width: "120px" }}>ชื่อ</th>
                      <th>วัสดุ</th>
                      <th style={{ width: "120px" }}>ใช้ตัวแปรที่ 1</th>
                      <th style={{ width: "120px" }}>ตัวแปร 1</th>
                      <th style={{ width: "150px" }}>สมาการ</th>
                      <th style={{ width: "120px" }}>ตัวแปร 2</th>
                      <th style={{ width: "120px" }}>คำนวณ</th>
                      <th style={{ width: "120px" }}>จำนวน</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subHeaderTitle.map((v, k) => (
                      <tr key={k}>
                        {v.del == 0 ? null : (
                          <>
                            <th>
                              <Button
                                color="danger"
                                className="btn btn-danger "
                                onClick={() => onDeleteRowTitleSubtitle(v.key)}
                              >
                                <i className="bx bx-x"></i>
                              </Button>
                            </th>

                            <th>
                              <Input
                                type="text"
                                name={"name" + k}
                                id={"name" + k}
                                value={v.name}
                                placeholder="ชื่อ"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].name = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: true,
                                  },
                                }}
                              />
                            </th>

                            <th>
                              <Select
                                isDisabled={subHeaderTitle[k].caltype_name == 1 ? true:false}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"material_id" + k}
                                value={dataMaterialStocks.filter(function (
                                  option
                                ) {
                                  return option.value == v.material_id
                                })}
                                onChange={e => {
                                  if (e.value == 0) {
                                    setActionModalMaterialStock({
                                      id: null,
                                      action: "Add",
                                      modal: true,
                                    })
                                  } else {
                                    let newData = [...subHeaderTitle]
                                    newData[k].material_id = e.value
                                    newData[k].material_name = e.label
                                    setSubHeaderTitle(newData)
                                  }
                                }}
                                options={dataMaterialStocks}
                              />
                            </th>

                            <th>
                              <Select
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"caltype_name" + k}
                                value={dataCalType_name.filter(function (
                                  option
                                ) {
                                  return option.value == v.caltype_name
                                })}
                                onChange={e => {
                                  let newData = [...subHeaderTitle]

                                  newData[k].caltype_name = e.value
                                  if (e.value == 1) {
                                    if (newData[k - 1] != null) {
                                      newData[k].material_id =
                                        newData[k - 1].material_id
                                      newData[k].material_name =
                                        newData[k - 1].material_name
                                    }
                                  }
                                  setSubHeaderTitle(newData)
                                }}
                                options={dataCalType_name}
                              />

                              {/* <Input
                                type="text"
                                name={"type_name_1" + k}
                                id={"type_name_1" + k}
                                value={v.type_name_1}
                                placeholder="ตัวแปร 1"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].type_name_1 = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: { value: true },
                                }}
                              /> */}
                            </th>

                            <th style={{ textAlign: "center" }}>
                              {v.caltype_name == 0 ? (
                                <Select
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name={"type_name_1" + k}
                                  value={dataType_1.filter(function (option) {
                                    return option.label == v.type_name_1
                                  })}
                                  onChange={e => {
                                    let newData = [...subHeaderTitle]
                                    newData[k].type_name_1 = e.label
                                    setSubHeaderTitle(newData)
                                  }}
                                  options={dataType_1}
                                />
                              ) : v.caltype_name == 1 ? (
                                <Select
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  type="select"
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name={"type_name_1" + k}
                                  value={dataCalType_name_1.filter(function (
                                    option
                                  ) {
                                    return option.label == v.type_name_1
                                  })}
                                  onChange={e => {
                                    let newData = [...subHeaderTitle]
                                    newData[k].type_name_1 = e.label
                                    setSubHeaderTitle(newData)
                                  }}
                                  options={dataCalType_name_1}
                                />
                              ) : (
                                <p>จำนวนชุด</p>
                              )}
                            </th>

                            <th>
                              <Select
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"equation" + k}
                                value={dataEquations.filter(function (option) {
                                  return option.value == v.equation
                                })}
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].equation = e.value
                                  setSubHeaderTitle(newData)
                                }}
                                options={dataEquations}
                              />

                              {/* <Input
                                type="text"
                                name={"equation" + k}
                                id={"equation" + k}
                                value={v.equation}
                                placeholder="สมาการ"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].equation = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                              /> */}
                            </th>

                            <th>
                              <Input
                                readOnly={v.equation == 0 ? true : false}
                                type="text"
                                name={"type_name_2" + k}
                                id={"type_name_2" + k}
                                value={v.type_name_2}
                                placeholder="ตัวแปร 2"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].type_name_2 = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: v.equation == 0 ? false : true,
                                  },
                                }}
                              />
                            </th>

                            <th>
                              <Select
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                type="select"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                name={"equation_type" + k}
                                value={dataEquationTypes.filter(function (
                                  option
                                ) {
                                  return option.value == v.equation_type
                                })}
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].equation_type = e.value
                                  setSubHeaderTitle(newData)
                                }}
                                options={dataEquationTypes}
                              />

                              {/* <Input
                                type="text"
                                name={"equation" + k}
                                id={"equation" + k}
                                value={v.equation}
                                placeholder="สมาการ"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].equation = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                              /> */}
                            </th>

                            <th>
                              <Input
                                readOnly={v.equation_type == 1 ? true : false}
                                type="number"
                                name={"qta" + k}
                                id={"qta" + k}
                                value={v.qta}
                                placeholder="จำนวน"
                                onChange={e => {
                                  let newData = [...subHeaderTitle]
                                  newData[k].qta = e.target.value
                                  setSubHeaderTitle(newData)
                                }}
                                validate={{
                                  required: {
                                    value: v.equation == 0 ? false : true,
                                  },
                                }}
                              />
                            </th>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            ) : null}
            {headerTitle.length > 0 ? (
              <Col sm="12">
                <Button
                  color="info"
                  size="sm"
                  className="btn btn-info "
                  onClick={() => onAddRowTitleSubtitle()}
                  style={{ width: "100%" }}
                >
                  เพิ่มรายการ/วัสดุ/สมาการ
                </Button>
              </Col>
            ) : null}
          </Row>
        </ModalBody>
        <ModalFooter>
          {formData.name == null ? (
            <p color="red">กรุณาตั้งชื่องาน</p>
          ) : (
            <Button type="submit" disabled={buttonSubmit} color="secondary">
              Save
            </Button>
          )}
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
