import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_MATERIAL_STOCKS,
  GET_MATERIAL_STOCK_PROFILE,
  ADD_NEW_MATERIAL_STOCK,
  DELETE_MATERIAL_STOCK,
  UPDATE_MATERIAL_STOCK,
} from "./actionTypes"

import {
  getMaterialStocksSuccess,
  getMaterialStocksFail,
  getMaterialStockProfileSuccess,
  getMaterialStockProfileFail,
  addMaterialStockFail,
  addMaterialStockSuccess,
  updateMaterialStockSuccess,
  updateMaterialStockFail,
  deleteMaterialStockSuccess,
  deleteMaterialStockFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMaterialStocks,
  getMaterialStockProfile,
  addNewMaterialStock,
  updateMaterialStock,
  deleteMaterialStock,
} from "../../helpers/fakebackend_helper"

function* fetchMaterialStocks({ payload: data }) {
  // console.log("active", data.active)
  // console.log("material_type_id", data.material_type_id)

  try {
    const response = yield call(getMaterialStocks, data)
    // console.log(response);
    yield put(getMaterialStocksSuccess(response))
  } catch (error) {
    yield put(getMaterialStocksFail(error))
  }
}

function* fetchMaterialStockProfile({ payload: id }) {
  // console.log(id)
  try {
    const response = yield call(getMaterialStockProfile, id)
    // console.log("Profile => ", response)
    yield put(getMaterialStockProfileSuccess(response))
  } catch (error) {
    yield put(getMaterialStockProfileFail(error))
  }
}

function* onUpdateMaterialStock({ payload: materialstock }) {
  try {
    // let x = materialstock.get('formData')
    // console.log(x)
    const response = yield call(updateMaterialStock, materialstock)
    yield put(updateMaterialStockSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateMaterialStockFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteMaterialStock({ payload: materialstock }) {
  try {
    const response = yield call(deleteMaterialStock, materialstock)
    yield put(deleteMaterialStockSuccess(response))
  } catch (error) {
    yield put(deleteMaterialStockFail(error))
  }
}

function* onAddNewMaterialStock({ payload: materialstock }) {
  try {
    const response = yield call(addNewMaterialStock, materialstock)
    yield put(addMaterialStockSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addMaterialStockFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* materialType() {
  yield takeEvery(GET_MATERIAL_STOCKS, fetchMaterialStocks)
  yield takeEvery(GET_MATERIAL_STOCK_PROFILE, fetchMaterialStockProfile)
  yield takeEvery(ADD_NEW_MATERIAL_STOCK, onAddNewMaterialStock)
  yield takeEvery(UPDATE_MATERIAL_STOCK, onUpdateMaterialStock)
  yield takeEvery(DELETE_MATERIAL_STOCK, onDeleteMaterialStock)
}

export default materialType
