import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
} from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  getListDesignGroupProfile as onGetListDesignGroupProfile,
  addNewListDesignGroup as onAddNewListDesignGroup,
  updateListDesignGroup as onUpdateListDesignGroup,
} from "store/list-design-groups/actions"

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const dispatch = useDispatch()

  const { listDesignGroupProfile } = useSelector(state => ({
    listDesignGroupProfile: state.listDesigngroup.listDesignGroupProfile,
  }))

  const [id, setID] = React.useState("")
  const [action, setAction] = React.useState("")
  const [modal, setModal] = React.useState(false)
  const [buttonSubmit, setButtonSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState(listDesignGroupProfile)

  async function checkAction() {
    setFormData({})

    if (actionModal.action == "Add") {
      setFormData({
        active: 1,
      })
    } else if (actionModal.action == "Edit") {
      dispatch(onGetListDesignGroupProfile(actionModal.id))
    }

    setID(actionModal.id)
    setAction(actionModal.action)
    setModal(actionModal.modal)
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true)
    if (errors.length > 0) {
    } else {
      if (action == "Add") {
        // save new contractor
        dispatch(onAddNewListDesignGroup(formData))
      } else if (action == "Edit") {
        // update contractor
        dispatch(onUpdateListDesignGroup(formData))
      }
      setModal(!modal)
    }
    setButtonSubmit(false)
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction()
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  React.useEffect(() => {
    if (actionModal.id != null && actionModal.action == "Edit") {
      setFormData(listDesignGroupProfile)
      // console.log(listDesignGroupProfile)
    }
    return () => {}
  }, [listDesignGroupProfile]) // Only re-run the effect if count changes

  return (
    <Modal isOpen={modal} toggle={() => setModal(!modal)} centered={true}>
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}โครงสร้าง{" "}
      </ModalHeader>
      <AvForm onSubmit={handleSubmit} model={formData}>
        <ModalBody>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <FormGroup className="mb-3">
                <AvField
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={formData.name}
                  onChange={e =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup className="mt-2">
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 1}
                      value={1}
                      onChange={e =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    เปิดใช้งาน
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 2}
                      value={2}
                      onChange={e =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    ปิดใช้งาน
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            Save
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
