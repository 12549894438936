import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  ModalFooter,
  Table,
  Spinner,
} from "reactstrap"
import imageCompression from "browser-image-compression"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { updateUser as onUpdateUser } from "store/users/actions"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import profile1 from "assets/images/profile-img.png"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work
import Editable from "react-bootstrap-editable"

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const { SearchBar } = Search

  const dispatch = useDispatch()

  const [obj, setObj] = React.useState(
    JSON.parse(localStorage.getItem("authUser"))
  )

  const [loadingFile, setLoadingFile] = React.useState(false)
  const [dataImage_profile, setDataImage_profile] = React.useState(null)
  const [project_success, setProject_success] = React.useState(0)
  const [project_progress, setProject_progress] = React.useState([])
  const [modal, setModal] = React.useState(false)
  const [formData, setFormData] = React.useState({})
  const [typeUpload, setTypeUpload] = React.useState(1)

  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "id",
      text: "ชื่อ",
      sort: true,
      headerStyle: () => {
        return { width: "100px" }
      },
      formatter: function action(cell, row) {
        return (
          <Link to={`/projects-overview/${row.id}`}>
            <span>{row.name}</span>
          </Link>
        )
      },
    },

    {
      dataField: "created_at",
      text: "วันที่สร้าง",
      sort: true,
      headerStyle: () => {
        return { width: "100px" }
      },
      formatter: function action(cell, row) {
        return <span>{moment(cell).add(543, "year").format("LL")}</span>
      },
    },

    {
      dataField: "updated_at",
      text: "อัพเดตล่าสุด",
      sort: true,
      headerStyle: () => {
        return { width: "100px" }
      },
      formatter: function action(cell, row) {
        return <span>{moment(cell).add(543, "year").format("LL")}</span>
      },
    },
  ])

  React.useMemo(() => {
    if (actionModal.modal) {
      setFormData(obj.me)
      setProject_progress(obj.project_progress)
      setProject_success(obj.project_success)
      setModal(actionModal.modal)
    }
    return () => {}
  }, [actionModal, dispatch]) // Only re-run the effect if count changes

  const handleSubmit = async () => {
    const data = new FormData()
    data.append("formData", JSON.stringify(formData))
    data.append("Image_profile", dataImage_profile)
    dispatch(onUpdateUser(data))
    setModal(!modal)
  }

  async function handleAcceptedFiles(files) {
    setLoadingFile(true)
    // console.log(files[0])
    if (typeUpload == 1) {
      setDataImage_profile(await resizeImageFn(files.target.files[0]))
    }
    setLoadingFile(false)
  }

  async function resizeImageFn(file) {
    // console.log("originalFile instanceof Blob", file instanceof Blob) // true
    // console.log(`originalFile size ${file.size / 1024 / 1024} MB`)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options)
      // console.log(
      //   "compressedFile instanceof Blob",
      //   compressedFile instanceof Blob
      // ) // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB

      // await uploadToServer(compressedFile) // write your own logic
      return compressedFile
    } catch (error) {
      console.log(error)
    }
  }

  const pageOptions_project_progress = {
    sizePerPage: 10,
    totalSize: project_progress.length, // replace later with size(customers),
    custom: true,
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  return (
    <>
      <style scoped>
        {
          "\
          .image-upload>input {\
            display: none;\
          }\
          "
        }
      </style>

      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        centered={true}
        size="xl"
      >
        <ModalHeader
          close={
            <button className="close" onClick={() => setModal(!modal)}>
              ×
            </button>
          }
        >
          ข้อมูลผู้ใช้งาน
        </ModalHeader>
        <ModalBody>
          {loadingFile == true ? (
            <Row>
              <Col sm="12" style={{ textAlign: "center" }}>
                <Spinner color="warning">กำลังอัพโหลดรูปภาพ</Spinner>
              </Col>
            </Row>
          ) : (
            <>
              {/* <AvForm onSubmit={handleSubmit} model={formData}> */}
              <Row>
                <Col sm="12">
                  <div className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col xs="7">
                          <div className="text-primary p-3">
                            <h5 className="text-primary">Welcome Back !</h5>
                          </div>
                        </Col>
                        <Col xs="5" className="align-self-end">
                          <img src={profile1} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <div className="pt-0">
                      <Row>
                        <Col sm="12" md="4">
                          <div className="avatar-md profile-user-wid mb-4">
                            <div className="image-upload">
                              <label htmlFor="file-input">
                                <img
                                  src={
                                    process.env.REACT_APP_SERVER +
                                    formData.path_img_profile
                                  }
                                  alt={process.env.REACT_APP_NAMECOMPANY}
                                  className="avatar-md rounded-circle"
                                />
                              </label>

                              {/* <input id="file-input" type="file" /> */}
                              <input
                                id="file-input"
                                type="file"
                                // value={dataImage_profile}
                                onChange={e => handleAcceptedFiles(e)}
                              />
                            </div>
                          </div>
                          <h5 className="font-size-15 text-truncate">
                            {/* {userProfile.name} */}
                          </h5>
                          <p className="text-muted mb-0 text-truncate">
                            {/* {userProfile.designation} */}
                          </p>
                        </Col>

                        <Col sm="12" md="8">
                          <div className="pt-4">
                            <Row>
                              <Col xs="5">
                                <h5 className="font-size-15">
                                  {project_success}
                                </h5>
                                <p className="text-muted mb-0">
                                  โปรเจคที่เสร็จแล้ว
                                </p>
                              </Col>
                              <Col xs="5">
                                <h5 className="font-size-15">
                                  $
                                  {parseInt(formData.money).toLocaleString(
                                    "th-TH",
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                    }
                                  )}
                                </h5>
                                <p className="text-muted mb-0">เงินเดือน</p>
                              </Col>

                              <Col xs="2" style={{ textAlign: "right" }}>
                                <Button
                                  type="button"
                                  color="primary"
                                  size="sm"
                                  onClick={() => handleSubmit()}
                                >
                                  บันทึก
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <hr />
                </Col>
                <Col
                  sm="12"
                  md="6"
                  style={{
                    borderRight: "1px solid #EFF2F7",
                  }}
                >
                  <div>
                    <div>
                      <h5 className="mb-4">ข้อมูลส่วนตัว</h5>
                      <p className="text-muted mb-4">
                        {/* {userProfile.personalDetail} */}
                      </p>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row" style={{ width: "20%" }}>
                                ชื่อผู้ใช้งาน :
                              </th>
                              <td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={formData.email}
                                  isValueClickable
                                  label=""
                                  mode="inline"
                                  onSubmit={e => {
                                    setFormData({
                                      ...formData,
                                      email: e,
                                    })
                                  }}
                                  onValidated={null}
                                  options={null}
                                  placement="top"
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText={false}
                                  type="textfield"
                                  validate={null}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ width: "20%" }}>
                                รหัสผ่าน :
                              </th>
                              <td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="รหัสผ่าน"
                                  id={null}
                                  initialValue={formData.password}
                                  label=""
                                  mode="inline"
                                  onSubmit={e => {
                                    setFormData({
                                      ...formData,
                                      password: e,
                                    })
                                  }}
                                  onValidated={null}
                                  options={null}
                                  placement="top"
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText
                                  type="textfield"
                                  validate={null}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ width: "20%" }}>
                                ชื่อจริง :
                              </th>
                              <td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={formData.name}
                                  isValueClickable
                                  label=""
                                  mode="inline"
                                  onSubmit={e => {
                                    setFormData({
                                      ...formData,
                                      name: e,
                                    })
                                  }}
                                  onValidated={null}
                                  options={null}
                                  placement="top"
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText={false}
                                  type="textfield"
                                  validate={null}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style={{ width: "20%" }}>
                                นามสกุล :
                              </th>
                              <td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={formData.lastname}
                                  isValueClickable
                                  label=""
                                  mode="inline"
                                  onSubmit={e => {
                                    setFormData({
                                      ...formData,
                                      lastname: e,
                                    })
                                  }}
                                  onValidated={null}
                                  options={null}
                                  placement="top"
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText={false}
                                  type="textfield"
                                  validate={null}
                                />
                              </td>
                            </tr>

                            <tr>
                              <th scope="row" style={{ width: "20%" }}>
                                เบอร์มือถือ :
                              </th>
                              <td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={formData.number_phone}
                                  isValueClickable
                                  label=""
                                  mode="inline"
                                  onSubmit={e => {
                                    setFormData({
                                      ...formData,
                                      number_phone: e,
                                    })
                                  }}
                                  onValidated={null}
                                  options={null}
                                  placement="top"
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText={false}
                                  type="textfield"
                                  validate={null}
                                />
                              </td>
                            </tr>

                            <tr>
                              <th scope="row" style={{ width: "20%" }}>
                                เลขบัตรประชาชน :
                              </th>
                              <td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={formData.card_number}
                                  isValueClickable
                                  label=""
                                  mode="inline"
                                  onSubmit={e => {
                                    setFormData({
                                      ...formData,
                                      card_number: e,
                                    })
                                  }}
                                  onValidated={null}
                                  options={null}
                                  placement="top"
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText={false}
                                  type="textfield"
                                  validate={null}
                                />
                              </td>
                            </tr>

                            <tr>
                              <th scope="row" style={{ width: "20%" }}>
                                ธนาคาร :
                              </th>
                              <td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={formData.bank}
                                  isValueClickable
                                  label=""
                                  mode="inline"
                                  onSubmit={e => {
                                    setFormData({
                                      ...formData,
                                      bank: e,
                                    })
                                  }}
                                  onValidated={null}
                                  options={null}
                                  placement="top"
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText={false}
                                  type="textfield"
                                  validate={null}
                                />
                              </td>
                            </tr>

                            <tr>
                              <th scope="row" style={{ width: "20%" }}>
                                บัญชีธนาคาร :
                              </th>
                              <td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={formData.bank_no}
                                  isValueClickable
                                  label=""
                                  mode="inline"
                                  onSubmit={e => {
                                    setFormData({
                                      ...formData,
                                      bank_no: e,
                                    })
                                  }}
                                  onValidated={null}
                                  options={null}
                                  placement="top"
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText={false}
                                  type="textfield"
                                  validate={null}
                                />
                              </td>
                            </tr>

                            <tr>
                              <th scope="row" style={{ width: "20%" }}>
                                ที่อยู่ :
                              </th>
                              <td>
                                <Editable
                                  ajax={null}
                                  alwaysEditing={false}
                                  className={null}
                                  disabled={false}
                                  editText="Edit"
                                  id={null}
                                  initialValue={formData.address}
                                  isValueClickable
                                  label=""
                                  mode="inline"
                                  onSubmit={e => {
                                    setFormData({
                                      ...formData,
                                      address: e,
                                    })
                                  }}
                                  onValidated={null}
                                  options={null}
                                  placement="top"
                                  renderCancelElement={null}
                                  renderConfirmElement={null}
                                  showText={false}
                                  type="textfield"
                                  validate={null}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  sm="12"
                  md="6"

                  // style={{
                  //   borderRight: "1px solid black",
                  // }}
                >
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions_project_progress)}
                    keyField="id"
                    columns={tableColumns}
                    data={project_progress}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={tableColumns}
                        data={project_progress}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col sm="8">
                                <h5>โปรเจคที่กำลังดำเนินการ</h5>
                              </Col>
                              <Col sm="4" style={{ textAlign: "right" }}>
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <br />
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered
                                    striped
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Col>
              </Row>
              {/* </AvForm> */}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {loadingFile == true ? null : (
            <Button color="danger" onClick={() => setModal(!modal)}>
              ปิด
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}

ExampleModal.propTypes = {
  actionModal: PropTypes.object,
}

export default ExampleModal
