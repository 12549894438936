import React from "react"
import { Container, Row, Col, Button } from "reactstrap"

//Import Images
import { Link } from "react-router-dom"
import { isEmpty, map } from "lodash"

import { useDispatch, useSelector } from "react-redux"
import { getDesignHomes as onGetDesignHomes } from "store/actions"

const Project = () => {
  const dispatch = useDispatch()
  const [step1, setStep1] = React.useState(true)
  const [step2, setStep2] = React.useState(false)

  const [achievementStep1, setAchievementStep1] = React.useState([])
  const [achievementStep2, setAchievementStep2] = React.useState([])

  const { designAchievementhomes } = useSelector(state => ({
    designAchievementhomes: state.designHomes.designAchievementhomes,
  }))

  React.useEffect(() => {
    if (!isEmpty(designAchievementhomes)) {
      let array1 = []
      let array2 = []
      for (let index = 0; index < designAchievementhomes.length; index++) {
        if (index <= 2) {
          array1.push(designAchievementhomes[index])
        } else {
          array2.push(designAchievementhomes[index])
        }
      }
      setAchievementStep1(array1)
      setAchievementStep2(array2)
      // console.log(array1);
    }
  }, [designAchievementhomes])

  React.useEffect(() => {
    if (isEmpty(designAchievementhomes)) {
      dispatch(onGetDesignHomes())
    }
  }, [dispatch])

  return (
    <React.Fragment>
      <section className="section bg-white" id="project">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">Projects</div>
                <h4>Achievement</h4>
                <Link to="/home-products">
                  <button
                    type="button"
                    className="btn btn-link"
                    style={{ fontSize: "18px" }}
                  >
                    ดูผลงานที่ผ่านมา
                  </button>
                </Link>
              </div>
            </Col>
          </Row>

          <Col lg="12">
            <div className="hori-timeline">
              <div
                className="owl-carousel owl-theme  navs-carousel events"
                id="timeline-carousel"
              >
                {step1 ? (
                  <>
                    <Row>
                      {achievementStep1 &&
                        achievementStep1.map((item, i) => (
                          <Col md="4" key={i}>
                            <Link
                              to={"/home-product-detail/" + item.id}
                              className="text-white"
                            >
                              <div className="item">
                                <div className="card text-center team-box">
                                  <div className="card-body">
                                    <div
                                      style={{
                                        // content: `url(${
                                        //   process.env.REACT_APP_SERVER +
                                        //   item.path_img
                                        // })`,
                                        // backgroundPosition: "center",
                                        // backgroundSize: "contain",
                                        // backgroundRepeat: "no-repeat",
                                        width: "316px",
                                        height: "156px",
                                      }}
                                    >
                                      <img
                                        style={{
                                          objectFit: "contain",
                                          // object-fit: contain;
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                          width: "auto",
                                          height: "auto",
                                        }}
                                        src={
                                          process.env.REACT_APP_SERVER +
                                          item.path_img
                                        }
                                        alt={process.env.REACT_APP_NAMECOMPANY}
                                        className="mx-auto d-block"
                                      />
                                    </div>

                                    <div className="mt-3">
                                      <h5>{item.name}</h5>
                                      <p
                                        className="text-muted mb-0"
                                        style={{
                                          fontWeight: "100",
                                        }}
                                      >
                                        {!isEmpty(item.price) ? (
                                          <b
                                            style={{
                                              fontWeight: "100",
                                            }}
                                          >
                                            {parseFloat(
                                              (parseFloat(item.price) / 100) *
                                                (100 - item.offer)
                                            ).toLocaleString("th-TH", {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                            })}
                                            &nbsp; บ.
                                          </b>
                                        ) : (
                                          0
                                        )}
                                      </p>
                                      <p
                                        className="text-muted mb-0"
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "100",
                                        }}
                                      >
                                        {item.code == "null" ? null : item.code}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </Col>
                        ))}
                    </Row>
                  </>
                ) : null}

                {step2 ? (
                  <>
                    <Row>
                      {achievementStep2 &&
                        achievementStep2.map((item, i) => (
                          <Col md="4" key={i}>
                            <Link
                              to={"/home-product-detail/" + item.id}
                              className="text-white"
                            >
                              <div className="item">
                                <div className="card text-center team-box">
                                  <div className="card-body">
                                    <div
                                      style={{
                                        width: "316px",
                                        height: "156px",
                                      }}
                                    >
                                      <img
                                        style={{
                                          objectFit: "contain",
                                          // object-fit: contain;
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                          width: "auto",
                                          height: "auto",
                                        }}
                                        src={
                                          process.env.REACT_APP_SERVER +
                                          item.path_img
                                        }
                                        alt={process.env.REACT_APP_NAMECOMPANY}
                                        className="mx-auto d-block"
                                      />
                                    </div>

                                    <div className="mt-3">
                                      <h5>{item.name}</h5>

                                      <p
                                        className="text-muted mb-0"
                                        style={{
                                          fontWeight: "100",
                                        }}
                                      >
                                        {!isEmpty(item.price) ? (
                                          <b
                                            style={{
                                              fontWeight: "100",
                                            }}
                                          >
                                            {parseFloat(
                                              (parseFloat(item.price) / 100) *
                                                (100 - item.offer)
                                            ).toLocaleString("th-TH", {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                            })}
                                            &nbsp; บ.
                                          </b>
                                        ) : (
                                          0
                                        )}
                                      </p>
                                      <p
                                        className="text-muted mb-0"
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "100",
                                        }}
                                      >
                                        {item.code == "null" ? null : item.code}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </Col>
                        ))}
                    </Row>
                  </>
                ) : null}

                <div className="owl-nav" style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    onClick={() => {
                      setStep1(true)
                      setStep2(false)
                    }}
                    className="border-0"
                    disabled={step1}
                  >
                    <i className="mdi mdi-chevron-left" />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setStep1(false)
                      setStep2(true)
                    }}
                    className="border-0"
                    disabled={step2}
                  >
                    <i className="mdi mdi-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Project
