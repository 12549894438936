import React from "react"
import MetaTags from "react-meta-tags"

//Import Components
import Navbar from "./Navbar/Navbar"
import Section from "./HeroSection/Section"
import CardsMini from "./HeroSection/cards-mini"
import AboutUs from "./AboutUs/about-us"
import Materials from "./Materials/material"
import Prices from "./Prices/prices"
import Flows from "./Flows/flow"
import Projects from "./Project/project"
import FAQs from "./Faqs/FAQs"
import Footer from "./Footer/footer"
import { isEmpty, map } from "lodash"
// import { useDispatch, useSelector } from "react-redux"
// import { getDesignHomes as onGetDesignHomes } from "store/actions"
const CryptoIcoLanding = () => {
  // const dispatch = useDispatch()
  const [imglight, setimglight] = React.useState(true)
  const [navClass, setnavClass] = React.useState("")

  // const { designhomes } = useSelector(state => ({
  //   designhomes: state.designHomes.designhomes,
  // }))

  // const { designAchievementhomes } = useSelector(state => ({
  //   designAchievementhomes: state.designHomes.designAchievementhomes,
  // }))

  // Use ComponentDidMount
  React.useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true)
    return () => {
      window.removeEventListener("scroll", scrollNavigation, true)
    }
  }, [])

  // React.useEffect(() => {
  //   console.log("designhomes", designhomes)
  //   console.log("designAchievementhomes", designAchievementhomes)
  // }, [designhomes, designAchievementhomes])

  // React.useEffect(() => {
  //   if (isEmpty(designhomes)) {
  //     dispatch(onGetDesignHomes())
  //   }
  // }, [dispatch])

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop
    if (scrollup > 80) {
      setimglight(false)
      setnavClass("nav-sticky")
    } else {
      setimglight(true)
      setnavClass("")
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>PMH | {process.env.REACT_APP_NAMECOMPANY}</title>
      </MetaTags>
      {/* import navbar */}
      <Navbar navClass={navClass} imglight={imglight} />

      {/* Hero section */}
      <Section />

      {/* mini cards */}
      <CardsMini />

      {/* aboutus */}
      <AboutUs />

      {/* materials */}
      <Materials />

      {/* prices */}
      <Prices />

      {/* flows */}
      <Flows />

      {/* projects */}
      <Projects />

      {/* faqs */}
      <FAQs />

      {/* footer */}
      <Footer />
    </React.Fragment>
  )
}

export default CryptoIcoLanding
