import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { isEmpty, map } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import logo_s from "assets/images/logo_s.png"
import { getBoqTitleHomeProfile as onGetBoqTitleHomeProfile } from "store/boq-title-homes/actions"
import THBText from "thai-baht-text" // for ES6
import moment from "moment"
import "moment/locale/th" // without this line it didn't work

import pdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

//ต้องระบุตามชื่อของ ไฟล์ font
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
}

const InvoiceCheck = props => {
  const dispatch = useDispatch()
  const {
    match: { params },
  } = props
  const { boqTitleHomeProfile } = useSelector(state => ({
    boqTitleHomeProfile: state.boqTitleHomes.boqTitleHomeProfile,
  }))

  const [formData, setFormData] = React.useState({
    employment_contract_1: [],
    employment_contract_2: [],
  })

  const [tableDataStructure, setTableDataStructure] = React.useState([])
  const [base64_logo_f, setBase64_logo_f] = React.useState(null)
  const [base64_logo_s, setBase64_logo_s] = React.useState(null)
  React.useEffect(async () => {
    dispatch(onGetBoqTitleHomeProfile(params.id))
  }, [dispatch])

  React.useEffect(async () => {
    // console.log("boqTitleHomeProfile", boqTitleHomeProfile)
    if (!isEmpty(boqTitleHomeProfile)) {
      setFormData(boqTitleHomeProfile)
      setBase64_logo_s(await getBase64ImageFromURL(logo_s))
    }
  }, [boqTitleHomeProfile])

  async function printInvoice() {
    var docDefinition = {
      // watermark: {
      //   text: process.env.REACT_APP_NAMECOMPANY,
      //   color: "#e6e6e6",
      //   opacity: 0.5,
      // },
      content: [
        {
          columns: [
            {
              style: "header",
              alignment: "left",
              width: "50%",
              text:
                "ทำที่ Premiurn house ตำบล คลองโยง วันที่: " +
                moment().add(543, "year").format("LL"),
            },
            {
              alignment: "right",
              width: "50%",
              text: "1/2",
            },
          ],
        },
        {
          columns: [
            {
              style: "header",
              alignment: "left",
              width: "100%",
              text: [
                "(ต้นฉบับ / original)\n",
                {
                  text: "หนังสือสัญญาจ้างเหมาก่อสร้าง Employment Contract\n",
                  fontSize: 18,
                  bold: true,
                },
              ],
            },
          ],
        },
        {
          style: "tableExample",
          table: {
            widths: ["100%"],
            body: [
              [
                {
                  preserveLeadingSpaces: true,
                  text:
                    "\tหนังสือสัญญาฉบับนี้ทำ ขึ้นระหว่าง บจก. พรีเมียม เฮ้าส์ โมดูลาร์ โดย นายรุจ อินทสุรัช หุ้นส่วนผู้จัดการสำนักงานตั้งอยู่ เลขที่ 60/1 หมู่ที่ 1 ต.คลองโยง อ.พุทธมณฑล จ.นครปฐม 73170 สถานที่ก่อสร้างอยู่ที่ ศาลายานครปฐม ซึ่งต่อไป ในสัญญานี้จะเรียกว่า 'ผู้ว่าจ้าง'\n" +
                    " ฝ่ายหนึ่งกับ " +
                    formData.name_contractor +
                    "\t" +
                    formData.lastname_contractor +
                    "\t(" +
                    formData.idcard_contractor +
                    ")" +
                    "\tที่อยู่" +
                    formData.address_contractor +
                    "\nซึ่งต่อไปในสัญญานี้จะเรียกว่า\tผู้ว่าจ้าง\tอีก ฝ่ายหนึ่งทั้งสองฝ่ายได้ตกลงทำสัญญาจ้างเหมาก่อสร้างกันโดยมีข้อความดังต่อไปนี้",
                },
              ],
              [
                {
                  text:
                    "ข้อ 1 ต้องให้ถูกต้องตามรูปแบบ และรายละเอียดที่แนบท้ายสัญญานี้ทุกประการ สัญญานี้มีรูปแบบและรายละเอียดที่แนบสัญญา และให้ถือว่าเป็นส่วนหนึ่งของสัญญานี้ คือ\n" +
                    formData.employment_contract_1.map((v, k) => {
                      return "\t" + Number(k + 1) + ". " + v.name
                    })
                  // "\n\t๑.๑ สัญญาจ้างเหมาก่อสร้าง\t๑.๒ แบบแปลนทางสถาปัตยกรรม\t๑.๓ แบบแปลนทางวิศวกรรมโครงสร้าง\t๑.๔ แบบแปลนทางสุขาภิบาล\t๑.๕ แบบที่ใช้สำหรับการก่อสร้าง\t",
                },
              ],

              [
                {
                  preserveLeadingSpaces: true,
                  text:
                    "ข้อ 2 การตกลงของผู้รับจ้าง" +
                    "\n\tผู้รับจ้างตกลงรับจ้างก่อสร้างอาคารตามแบบที่กำหนดในสัญญาข้อ" +
                    formData.employment_contract_2.map((v, k) => {
                      return "\n\t" + Number(k + 1) + ". " + v.name
                    })
                    // "\n\t๑. โดยสัญญาว่าจะเพื่อทำการก่อสร้างตามแบบสัญญานี้จนแล้วเสร็จโดยผู้รับจ้างจะต้องเตรียมวัสดุสิ้นเปลืองทุกอย่างยกเว้น เหล็ก แผ่นหลังคา ฉนวนกันความร้อน ชีลายแผ่นไฟเบอร์ซีเมนต์ กระจกอลูมิเนียม ประตูหน้าต่างอุปกรณ์ประตู สุขภัณฑ์ อ่างล้างหน้า ก๊อกกระเบื้องเซรามิค อุปกรณ์ตกแต่งทุกชนิด(ยกเว้นน็อต)รวมถึง สายไฟฟ้าและท่อโดยทางบริษัทจะออกค่าใช้จ่ายในส่วนต่างๆอ้างอิงราคากลางที่กำหนด" +
                    // "\n\t๒. หากผู้รับเหมาไม่เข้างาน โดยไม่แจ้งโดยไม่มีเหตุจำเป็น หรือไม่ได้บอกกล่าว เกิน ๓ วันจำนวน ๒ ครั้ง ผู้ว่าจ้างมีสิทธิ์ไม่จ่ายค่าแรงและสามารถเปลี่ยน ผู้ดำเนินการได้" +
                    // "\n\t๓. วัสดุอุปกรณ์ที่เหลือจากการปฏิบัติงานไม่อนุญาตให้นำออกไปโดยไม่บอกกล่าว หากฝ่าฝืน ผู้ว่าจ้างสามารถดำเนินคดีข้อหาลักทรัพย์ได้" +
                    // "\n\t๔. ผู้รับจ้างจะต้องถ่ายรูปความคืบหน้าของงานทุกวันโดยส่งให้ทาง ไลน์กลุ่มทุกวันเพื่อทราบความคืบหน้าของงานจะได้เตรียมจ่ายค่าแรงและจัดหาวัสดุสำหรับงานลำดับถัดไปหากไม่ส่งมีผลต่อการโอนเงินเพราะจะต้องสอบถามความเรียบร้อยก่อนอนุมัติจ่ายเงิน" +
                    // "\n\t๕. การแจ้งเบิกค่างาน หากผู้ควบคุมงานตรวจงานเรียบร้อยแล้ว ผู้ว่าจ้าง จะโอนเงินให้ หลังจากตรวจ ถัดไป ๑ วัน (นับเวลาทำการ)" +
                    // "\n\t๖. หากวัสดุและอุปกรณ์ที่จำเป็นต้องใช้ผู้รับจ้าง ต้องบอกล่วงหน้า ๒-๓ วัน เพื่อให้ผู้ว่าจ้างจัดหาวัสดุโดยจะจัดเตรียมให้เฉพาะงานที่ทำต่อไปจากงวดงานต่อไปเท่านั้น จะไม่จัดให้ล่วงหน้าเพื่อจะได้ ตรวจงานก่อนที่จะดำเนินการต่อไป" +
                    // "\n\t๗. ผู้รับจ้างจะต้องทาสีกันสนิมบริเวรรอยเชื่อมในกรณีที่เชื่อมเต็มแล้วทุกครั้งเผื่อลูกค้ามาดูจะได้เห็น ถึงความเรียบร้อย และฉนวนกันความร้อนจะต้องม้วน หัวกันน้ำเข้า หากตรวจพบผู้รับจ้างมีสิทธิ์หักค่าแรง บริเวณดังกล่าวจุดละ ๑,๐๐๐ บาท" +
                    // "\n\t๘. ผู้รับจ้างต้องแจ้งให้ช่างคุมงานไปตรวจงาน โดยบอกล่วงหน้า ๓ วันทั้งนี้ขึ้นอยู่กับตารางผู้คุมงานหากแจ้งก่อนจะไปตรวจให้ตามคิว" +
                    // "\n\t๙. หากผู้ควบคุมงานตรวจงานแล้วไม่ตรงไปตามแบบจะต้องแก้ไขและถ่ายรูปส่งมาให้ผู้ควบคุมงานในจุดดังกล่าว" +
                    // "\n\t๑๐. ในกรณีที่ผู้รับจ้างทำงานหน้างานหากลูกค้าสั่งให้ทำตามลูกค้าผู้ว่าจ้างจะไม่รับผิดชอบหรือจ่ายค่าแรงเพิ่มในกรณีดังกล่าว หากไม่ได้แจ้งผู้ว่าจ้างก่อนหากมีการแก้ไขนอกเหนือจากแบบหรือเปลี่ยนจากแบบผู้ว่าจ้างยินดีจ่ายค่างานหรือค่าแก้งานให้ โดยจะต้องตกลงกันก่อน" +
                    // "\n\t๑๑. ขอสงวนสิทธิ์รูปภาพและผลงานห้ามนำไปเผยแพร่ หรือนำไปแสดงเพื่อการค้าหรือนำไปเป็นผลงานของตัวเอง หรือหากมีการกระทำดังกล่าวยินดีชดเชยค่าเสียให้ ๕๐,๐๐๐ บาท" +
                    // "\n\t๑๒. ระยะเวลาก่อสร้าง ไม่เกิน ๔๕ วันยกเว้นในกรณี เพิ่ม-ปรับเปลี่ยนแบบเปลี่ยนแปลงของลูกค้าเหตุผลทางสภาพอากาศ หรือเหตุผลใดๆที่เกี่ยวข้องทำให้เกิดการทำงานที่ล่าช้า (ยกเว้นเหตุผลส่วนตัวทุกกรณี)" +
                    // "\n\t๑๓. หากผิดสัญญาตามที่ตกลงกับผู้ว่าจ้างมีสิทธิ์ไม่จ่ายค่าแรงหรือ ปรับเปลี่ยนตัวผู้รับจ้างได้" +
                    // "\n\t๑๔. หากต้องการที่จะเบิกค่าแรงสามารถเบิกได้ตามผลงาน แต่ไม่สามารถเบิกเกิน 50% ของราคางาน ส่วนที่เหลือสามารถเบิกได้ตอนส่งมอบงาน",
               
                  },
              ],
              [
                {
                  margin: [0, 20, 0, 0],
                  alignment: "center",
                  style: "header",

                  columns: [
                    {
                      width: "50%",
                      text:
                        "( ............................................ )\n" +
                        " รุจ อินทสุรัช (ผู้ว่าจ้าง)",
                    },
                    {
                      width: "50%",
                      text:
                        "( ............................................ )\n" +
                        formData.name_contractor +
                        "\t" +
                        formData.lastname_contractor +
                        " (ผู้รับจ้าง)",
                    },
                  ],
                },
              ],
            ],
          },
          layout: "noBorders",
        },
        { text: "", pageBreak: "before" },
        (formData.boq_title_detail || []).map((v, k) =>
          isEmpty(v.boqs) == true
            ? null
            : [
                {
                  columns: [
                    {
                      style: "header",
                      alignment: "left",
                      width: "50%",
                      text: "",
                    },
                    {
                      alignment: "right",
                      width: "50%",
                      text: "2." + Number(k + 1) + "/2",
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: "header",
                      alignment: "left",
                      width: "80%",
                      text: [
                        "(ต้นฉบับ / original)\n",
                        {
                          text:
                            "ใบรายการวัสดุ Boq (" +
                            v.list_structure_name +
                            ")\n",
                          fontSize: 18,
                          bold: true,
                        },
                        " วันที่: " +
                          moment().add(543, "year").format("LL") +
                          "\n" +
                          "ราคางานรวม: " +
                          parseInt(v.list_structure_price).toLocaleString(
                            "th-TH"
                          ) +
                          " บ. (" +
                          THBText(parseInt(v.list_structure_price)) +
                          ")",
                      ],
                    },

                    {
                      image: base64_logo_s,
                      width: 40,
                      height: 50,
                    },
                  ],
                },
                rowType4(v.boqs),
                {
                  margin: [0, 50, 0, 0],
                  columns: [
                    {
                      style: "header",
                      alignment: "left",
                      width: "50%",
                      text: "",
                    },
                    {
                      width: "50%",
                      alignment: "right",
                      text:
                        "( ............................................ )\n" +
                        formData.name_contractor +
                        "\t" +
                        formData.lastname_contractor +
                        " (ผู้รับจ้าง)",
                    },
                  ],
                  pageBreak: "after",
                },
              ]
        ),
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 20],
        },
        subheader: {
          fontSize: 13,
          decoration: "underline",
          decorationStyle: "solid",
          decorationColor: "black",
        },
        tableExample: {
          fontSize: 12.5,
        },
        tableHeader: {
          bold: true,
          fontSize: 14,
          color: "black",
        },
      },
      defaultStyle: {
        font: "THSarabunNew",
      },
      pageSize: "A4",
    }
    // pdfMake.createPdf(docDefinition).open()
    let quo_name = "ใบสัญญาจ้างช่าง " + formData.name_contractor + ".pdf"
    //// console.log(quo_name)
    pdfMake.createPdf(docDefinition).download(quo_name)
  }

  function rowType4(data) {
    var headers = {
      fila_0: {
        col_1: {
          text: "ลำดับ",
          style: "tableHeader",
          fontSize: 20,
          alignment: "left",
        },
        col_2: {
          text: "วัสดุ",
          style: "tableHeader",
          fontSize: 20,
          alignment: "left",
        },
        col_3: {
          text: "หน่วย",
          style: "tableHeader",
          fontSize: 20,
          alignment: "center",
        },
        col_4: {
          text: "ใช้ทั้งหมด",
          style: "tableHeader",
          fontSize: 20,
          alignment: "right",
        },
      },
    }

    return {
      table: {
        widths: ["auto", "*", "auto", "auto"],
        // widths: ["auto", "*", "*", "*"],
        headerRows: 1,
        body: buildTableBody(data, headers),
      },
    }
  }

  function buildTableBody(rows, headers) {
    let body = []
    for (const key in headers) {
      if (headers.hasOwnProperty(key)) {
        let header = headers[key]
        let row = []
        row.push(header.col_1)
        row.push(header.col_2)
        row.push(header.col_3)
        row.push(header.col_4)
        body.push(row)
      }
    }

    for (const key in rows) {
      if (rows.hasOwnProperty(key)) {
        let data = rows[key]
        let row = []
        row.push({ text: Number(key) + 1, fontSize: 18 })
        row.push({ text: data.material_name, fontSize: 18 })
        row.push({ text: data.unit, alignment: "center", fontSize: 18 })
        row.push({ text: data.qta, alignment: "right", fontSize: 18 })
        body.push(row)
      }
    }

    return body
  }

  function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image()
      img.setAttribute("crossOrigin", "anonymous")

      img.onload = () => {
        var canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height

        var ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0)

        var dataURL = canvas.toDataURL("image/png")

        resolve(dataURL)
      }

      img.onerror = error => {
        reject(error)
      }

      img.src = url
    })
  }
  return (
    <React.Fragment>
      <style scoped>
        {
          "\
          .table-hide-border{\
            border-style: hidden;\
            border-right-style: hidden;\
          }\
          .table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {\
            border: 1px solid black;\
          }\
          .table > :not(:first-child){\
            border: 1px solid black;\
           }\
          tbody tr{\
            cursor:initial;\
          }\
          .hidden {\
            overflow: hidden;\
          }\
          .td-note {\
            display: inline-block;\
            margin-bottom:1px;\
            padding-bottom:1px;\
          }\
          .hidden td {\
            display: inline-block;\
            border-style: hidden\
          }\
          @media print {\
            .pagebreak { page-break-before: always; }\
          }\
          "
        }
      </style>

      <div className="page-content">
        <MetaTags>
          <title>BOQ Detail | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>

        <Container fluid style={{ fontFamily: "sans-serif" }}>
          <Row>
            <Col lg="12">
              {/* header */}
              <div className="d-print-none">
                <div style={{ textAlignLast: "right" }}>
                  <Link
                    to="#"
                    onClick={printInvoice}
                    className="btn btn-success me-2"
                  >
                    <i className="fa fa-print" />
                  </Link>

                  <Link
                    to={`/projects-overview/${formData.project_id}`}
                    className="btn btn-secondary"
                  >
                    Back
                  </Link>
                </div>
                <hr />
              </div>
              {/* page 1 */}
              <Card className="pagebreak" style={{ boxShadow: "none" }}>
                <CardBody>
                  <Table className="table-nowrap" responsive>
                    <thead>
                      <tr>
                        <th
                          className="table-hide-border"
                          style={{
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            width: "50%",
                          }}
                        >
                          ทำที่ Premiurn house ตำบล คลองโยง &emsp; วันที่:&nbsp;
                          {moment().add(543, "year").format("LL")}
                        </th>
                        <th
                          className="table-hide-border"
                          style={{
                            textAlign: "right",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            width: "50%",
                          }}
                        >
                          1/2
                        </th>
                      </tr>

                      <tr>
                        <th
                          className="table-hide-border"
                          style={{
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                        >
                          <p
                            className="font-size-13"
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              marginTop: "0px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            ( ต้นฉบับ / original )
                          </p>
                          <p
                            className="font-size-20"
                            style={{
                              fontWeight: "bold",
                              marginTop: "0px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            หนังสือสัญญาจ้างเหมาก่อสร้าง Employment Contract
                          </p>
                        </th>
                      </tr>
                    </thead>
                  </Table>

                  <Table responsive>
                    <thead>
                      <tr>
                        <th
                          colSpan={2}
                          className="table-hide-border"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <p
                            className="font-size-13"
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              marginTop: "0px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            &emsp;&emsp;หนังสือสัญญาฉบับนี้ทำ ขึ้นระหว่าง บจก.
                            พรีเมียม เฮ้าส์ โมดูลาร์ โดย นายรุจ อินทสุรัช
                            หุ้นส่วนผู้จัดการสำนักงานตั้งอยู่ เลขที่ 60/1
                            หมู่ที่ 1 ต.คลองโยง อ.พุทธมณฑล จ.นครปฐม 73170
                            สถานที่ก่อสร้างอยู่ที่ ศาลายานครปฐม ซึ่งต่อไป
                            ในสัญญานี้จะเรียกว่า &#34;ผู้ว่าจ้าง&#34;
                          </p>
                        </th>
                      </tr>
                      <tr>
                        <th
                          colSpan={2}
                          className="table-hide-border"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <p
                            className="font-size-13"
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              marginTop: "5px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            &emsp;&emsp;ฝ่ายหนึ่งกับ&nbsp;
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {formData.name_contractor} &nbsp;{" "}
                              {formData.lastname_contractor} &nbsp; (
                              {formData.idcard_contractor})
                            </span>
                            &emsp;ที่อยู่&nbsp;
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {formData.address_contractor}
                            </span>
                          </p>
                        </th>
                      </tr>
                      <tr>
                        <th
                          colSpan={2}
                          className="table-hide-border"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <p
                            className="font-size-13"
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              marginTop: "5px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            ซึ่งต่อไปในสัญญานี้จะเรียกว่า &#34;ผู้ว่าจ้าง&#34;
                            อีก ฝ่ายหนึ่ง
                            ทั้งสองฝ่ายได้ตกลงทำสัญญาจ้างเหมาก่อสร้างกันโดยมีข้อความดังต่อไปนี้
                          </p>
                        </th>
                      </tr>
                      <tr>
                        <th
                          colSpan={2}
                          className="table-hide-border"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <p
                            className="font-size-13"
                            style={{
                              textAlign: "left",
                              fontWeight: "bold",
                              marginTop: "5px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            ข้อ 1
                            ต้องให้ถูกต้องตามรูปแบบและรายละเอียดที่แนบท้ายสัญญานี้ทุกประการ
                            สัญญานี้มีรูปแบบและรายละเอียดที่แนบสัญญาและให้ถือว่าเป็นส่วนหนึ่งของสัญญานี้ คือ
                          </p>

                          <p
                            className="font-size-13"
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              marginTop: "5px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            {formData.employment_contract_1.map((v, k) => {
                              return (
                                <span key={k}>
                                  &emsp;&emsp;{Number(k + 1)}. {v.name}
                                </span>
                              )
                            })}
                            {/* &emsp;&emsp;๑.๑ สัญญาจ้างเหมาก่อสร้าง
                            &emsp;&emsp;๑.๒ แบบแปลนทางสถาปัตยกรรม
                            &emsp;&emsp;๑.๓ แบบแปลนทางวิศวกรรมโครงสร้าง
                            &emsp;&emsp;๑.๔ แบบแปลนทางสุขาภิบาล &emsp;&emsp;๑.๕
                            แบบที่ใช้สำหรับการก่อสร้าง */}
                          </p>
                        </th>
                      </tr>
                      <tr>
                        <th
                          colSpan={2}
                          className="table-hide-border"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <p
                            className="font-size-13"
                            style={{
                              textAlign: "left",
                              fontWeight: "bold",
                              marginTop: "5px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            ข้อ 2 การตกลงของผู้รับจ้าง
                          </p>

                          <p
                            className="font-size-13"
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              marginTop: "5px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            &emsp;&emsp;ผู้รับจ้างตกลงรับจ้างก่อสร้างอาคารตามแบบที่กำหนดในสัญญาข้อ
                          </p>

                          <p
                            className="font-size-13"
                            style={{
                              textAlign: "left",
                              fontWeight: "normal",
                              marginTop: "5px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            {formData.employment_contract_2.map((v, k) => {
                              return (
                                <span key={k}>
                                  &emsp;&emsp;{Number(k + 1)}. {v.name} <br />
                                </span>
                              )
                            })}
                            {/* &emsp;&emsp;๑. โดยสัญญาว่าจะ
                            เพื่อทำการก่อสร้างตามแบบสัญญานี้จนแล้วเสร็จ
                            โดยผู้รับจ้างจะต้องเตรียมวัสดุสิ้นเปลืองทุกอย่าง
                            ยกเว้น เหล็ก แผ่นหลังคา ฉนวนกันความร้อน ชีลาย
                            แผ่นไฟเบอร์ซีเมนต์ กระจกอลูมิเนียม ประตูหน้าต่าง
                            อุปกรณ์ประตู สุขภัณฑ์ อ่างล้างหน้า ก๊อก
                            กระเบื้องเซรามิค อุปกรณ์ตกแต่งทุกชนิด(ยกเว้นน็อต)
                            รวมถึง สายไฟฟ้าและท่อ
                            โดยทางบริษัทจะออกค่าใช้จ่ายในส่วนต่างๆอ้างอิงราคากลางที่กำหนด
                            <br />
                            &emsp;&emsp;๒. หากผู้รับเหมาไม่เข้างาน โดยไม่แจ้ง
                            โดยไม่มีเหตุจำเป็น หรือไม่ได้บอกกล่าว เกิน ๓ วัน
                            จำนวน ๒
                            <br />
                            &emsp;&emsp;ครั้ง ผู้ว่าจ้างมีสิทธิ์ไม่จ่ายค่าแรง
                            และสามารถเปลี่ยน ผู้ดำเนินการได้
                            <br />
                            &emsp;&emsp;๓.
                            วัสดุอุปกรณ์ที่เหลือจากการปฏิบัติงานไม่อนุญาตให้นำออกไป
                            โดยไม่บอกกล่าว หากฝ่าฝืน ผู้ว่าจ้าง
                            สามารถดำเนินคดีข้อหาลักทรัพย์ได้
                            <br />
                            &emsp;&emsp;๔.
                            ผู้รับจ้างจะต้องถ่ายรูปความคืบหน้าของงานทุกวัน
                            โดยส่งให้ทาง ไลน์กลุ่มทุกวัน
                            เพื่อทราบความคืบหน้าของงาน
                            จะได้เตรียมจ่ายค่าแรงและจัดหาวัสดุสำหรับงานลำดับถัดไป
                            หากไม่ส่งมีผลต่อการโอนเงิน
                            เพราะจะต้องสอบถามความเรียบร้อยก่อนอนุมัติจ่ายเงิน
                            <br />
                            &emsp;&emsp;๕. การแจ้งเบิกค่างาน หากผู้ควบคุมงาน
                            ตรวจงานเรียบร้อยแล้ว ผู้ว่าจ้าง จะโอนเงินให้ หลังจาก
                            <br />
                            &emsp;&emsp;ตรวจ ถัดไป ๑ วัน (นับเวลาทำการ)
                            <br />
                            &emsp;&emsp;๖. หากวัสดุและอุปกรณ์ที่จำเป็นต้องใช้
                            ผู้รับจ้าง ต้องบอกล่วงหน้า ๒-๓ วัน
                            เพื่อให้ผู้ว่าจ้างจัดหาวัสดุโดยจะจัดเตรียมให้เฉพาะงานที่ทำต่อไป
                            จากงวดงานต่อไปเท่านั้น จะไม่จัดให้ล่วงหน้า
                            เพื่อจะได้ ตรวจงานก่อนที่จะดำเนินการต่อไป
                            <br />
                            &emsp;&emsp;๗.ผู้รับจ้างจะต้องทาสีกันสนิมบริเวรรอยเชื่อมในกรณีที่เชื่อมเต็มแล้วทุกครั้ง
                            เผื่อลูกค้ามาดูจะได้เห็น ถึงความเรียบร้อย และ
                            ฉนวนกันความร้อนจะต้องม้วน หัวกันน้ำเข้า หากตรวจพบ
                            ผู้รับจ้างมีสิทธิ์หักค่าแรง บริเวณดังกล่าวจุดละ
                            ๑,๐๐๐ บาท
                            <br />
                            &emsp;&emsp;๘.ผู้รับจ้างต้องแจ้งให้ช่างคุมงานไปตรวจงาน
                            โดยบอกล่วงหน้า ๓ วัน
                            ทั้งนี้ขึ้นอยู่กับตารางผู้คุมงานหากแจ้งก่อนจะไปตรวจให้ตามคิว
                            <br />
                            &emsp;&emsp;๙.หากผู้ควบคุมงานตรวจงานแล้วไม่ตรงไปตามแบบจะต้องแก้ไขและถ่ายรูปส่งมาให้ผู้ควบคุมงานในจุดดังกล่าว
                            <br />
                            &emsp;&emsp;๑๐. ในกรณีที่ผู้รับจ้างทำงานหน้างาน
                            หากลูกค้าสั่งให้ทำตามลูกค้าผู้ว่าจ้างจะไม่รับผิดชอบหรือจ่ายค่าแรงเพิ่มใน
                            กรณีดังกล่าว หากไม่ได้แจ้งผู้ว่าจ้างก่อน
                            หากมีการแก้ไขนอกเหนือจากแบบหรือเปลี่ยนจากแบบผู้ว่าจ้างยินดี
                            จ่ายค่างานหรือค่าแก้งานให้ โดยจะต้องตกลงกันก่อน
                            <br />
                            &emsp;&emsp;๑๑.
                            ขอสงวนสิทธิ์รูปภาพและผลงานห้ามนำไปเผยแพร่หรือนำไปแสดงเพื่อการค้าหรือนำไปเป็น
                            ผลงานของตัวเองหรือหากมีการกระทำดังกล่าวยินดีชดเชยค่าเสียให้
                            ๕๐,๐๐๐ บาท
                            <br />
                            &emsp;&emsp;๑๒. ระยะเวลาก่อสร้าง ไม่เกิน ๔๕ วัน
                            ยกเว้นในกรณี เพิ่ม-ปรับเปลี่ยนแบบ
                            เปลี่ยนแปลงของลูกค้าเหตุผลทางสภาพอากาศ หรือเหตุผลใดๆ
                            ที่เกี่ยวข้องทำให้เกิดการทำงานที่ล่าช้า
                            (ยกเว้นเหตุผลส่วนตัวทุกกรณี)
                            <br />
                            &emsp;&emsp;๑๓. หาก
                            ผิดสัญญาตามที่ตกลงกับผู้ว่าจ้างมีสิทธิ์ไม่จ่ายค่าแรง
                            หรือ ปรับเปลี่ยนตัวผู้รับจ้างได้
                            <br />
                            &emsp;&emsp;๑๔.
                            หากต้องการที่จะเบิกค่าแรงสามารถเบิกได้ตามผลงาน
                            แต่ไม่สามารถเบิกเกิน 50% ของราคางาน
                            ส่วนที่เหลือสามารถเบิกได้ตอนส่งมอบงาน
                            <br /> */}
                          </p>
                        </th>
                      </tr>
                      <br />
                      <tr>
                        <th
                          className="table-hide-border"
                          style={{
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            textAlign: "center",
                          }}
                        >
                          <p
                            className="font-size-13"
                            style={{
                              fontWeight: "normal",
                              marginTop: "5px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            ลงชื่อ...............................................ผู้ว่าจ้าง
                            <br />
                            (............................................................)
                          </p>
                        </th>

                        <th
                          className="table-hide-border"
                          style={{
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            textAlign: "center",
                          }}
                        >
                          <p
                            className="font-size-13"
                            style={{
                              fontWeight: "normal",
                              marginTop: "5px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            ลงชื่อ...............................................ผู้รับจ้าง
                            <br />
                            (............................................................)
                          </p>
                        </th>
                      </tr>
                      <tr>
                        <th
                          className="table-hide-border"
                          style={{
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            textAlign: "center",
                          }}
                        >
                          <p
                            className="font-size-13"
                            style={{
                              fontWeight: "normal",
                              marginTop: "5px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            ลงชื่อ...............................................พยาน
                            <br />
                            (............................................................)
                          </p>
                        </th>
                        <th
                          className="table-hide-border"
                          style={{
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            textAlign: "center",
                          }}
                        >
                          <p
                            className="font-size-13"
                            style={{
                              fontWeight: "normal",
                              marginTop: "5px",
                              marginBottom: "0px",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                          >
                            ลงชื่อ...............................................พยาน
                            <br />
                            (............................................................)
                          </p>
                        </th>
                      </tr>
                    </thead>
                  </Table>
                </CardBody>
              </Card>

              {!isEmpty(formData.boq_title_detail) &&
                (formData.boq_title_detail || []).map(
                  (v, k) =>
                    !isEmpty(v.boqs) && (
                      <Card
                        key={k}
                        className="pagebreak"
                        style={{ boxShadow: "none" }}
                      >
                        <CardBody>
                          <Table className="table-nowrap" responsive>
                            <thead>
                              <tr>
                                <th
                                  className="table-hide-border"
                                  style={{ width: "50%" }}
                                ></th>
                                <th
                                  className="table-hide-border"
                                  style={{
                                    textAlign: "right",
                                    width: "50%",
                                  }}
                                >
                                  2.{k + 1}/2
                                </th>
                              </tr>

                              <tr>
                                <th className="table-hide-border">
                                  <p
                                    className="font-size-13"
                                    style={{
                                      textAlign: "left",
                                      fontWeight: "normal",
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    ( ต้นฉบับ / original )
                                  </p>
                                  <p
                                    className="font-size-20"
                                    style={{
                                      fontWeight: "bold",
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    ใบรายการวัสดุ Boq ({v.list_structure_name})
                                  </p>

                                  <p
                                    className="font-size-14"
                                    style={{
                                      fontWeight: "normal",
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    วันที่:&nbsp;
                                    {moment().add(543, "year").format("LL")}
                                  </p>

                                  <p
                                    className="font-size-14"
                                    style={{
                                      fontWeight: "normal",
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    ราคางานรวม:&nbsp;
                                    {parseInt(
                                      v.list_structure_price
                                    ).toLocaleString("th-TH")}
                                    &nbsp;บ.
                                  </p>
                                </th>
                                <th
                                  className="table-hide-border"
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  <img
                                    style={{ marginTop: "5px" }}
                                    src={logo_s}
                                    alt={process.env.REACT_APP_NAMECOMPANY}
                                    height="80"
                                  />
                                </th>
                              </tr>
                            </thead>
                          </Table>

                          <Table className="table-nowrap" bordered responsive>
                            <thead>
                              <tr>
                                <th width="80px">ลำดับ</th>
                                <th>วัสดุ</th>
                                <th width="80px">หน่วย</th>
                                <th style={{ textAlign: "right" }}>
                                  ใช้ทั้งหมด
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {(v.boqs || []).map((j, i) => (
                                <tr key={i}>
                                  <td scope="row">{i + 1}</td>
                                  <td>{j.material_name}</td>
                                  <td>{j.unit}</td>
                                  <td style={{ textAlign: "right" }}>
                                    {j.qta}
                                  </td>
                                </tr>
                              ))}

                              <tr>
                                <td
                                  colSpan={4}
                                  style={{
                                    paddingTop: "20px",
                                    paddingBottom: "0px",
                                    textAlign: "right",
                                    borderRight: "1px solid white",
                                    borderLeft: "1px solid white",
                                    borderBottom: "1px solid white",
                                  }}
                                >
                                  <p
                                    className="font-size-13"
                                    style={{
                                      fontWeight: "normal",
                                      marginTop: "5px",
                                      marginBottom: "0px",
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    ลงชื่อ...............................................ผู้รับจ้าง
                                    <br />
                                    (............................................................)
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    )
                )}

              <hr />
              <div className="d-print-none">
                <div style={{ textAlignLast: "right" }}>
                  <Link
                    to="#"
                    onClick={printInvoice}
                    className="btn btn-success me-2"
                  >
                    <i className="fa fa-print" />
                  </Link>

                  <Link
                    to={`/projects-overview/${formData.project_id}`}
                    className="btn btn-secondary"
                  >
                    Back
                  </Link>
                </div>
              </div>
              <br />
            </Col>
          </Row>
          {/* {!isEmpty(formData) && (
           
          )} */}
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoiceCheck.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceCheck)
