import React from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import Select from "react-select"
import ReactExport from "react-export-excel"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "../Tables/datatables.scss"
import { getUsers as onGetUsers } from "store/actions"

import ExampleModal from "../../components/Dialogs/dialogAttends"

import Flatpickr from "react-flatpickr"
import { Thai } from "flatpickr/dist/l10n/th.js"

//redux
import moment from "moment"
import "moment/locale/th" // without this line it didn't work

// API store
import { getAttends as onGetAttends } from "store/attends/actions"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ReportAttedns = () => {
  const dispatch = useDispatch()

  const { attends } = useSelector(state => ({
    attends: state.attends.attends,
  }))

  const { users } = useSelector(state => ({
    users: state.users.users,
  }))

  const [nameUser, setNameUser] = React.useState(null)
  const [reloadFlatpickr, setReloadFlatpickr] = React.useState(false)
  const [user_id, setUser_id] = React.useState(0)
  const [optionSelectUsers, setOptionSelectUsers] = React.useState([])

  const [timeStart, setTimeStart] = React.useState(
    moment().startOf("day").format("YYYY-MM-DD")
  )
  const [timeEnd, setTimeEnd] = React.useState(
    moment().endOf("day").format("YYYY-MM-DD")
  )
  const [reports, setReports] = React.useState([
    {
      title: "เข้างานในเวลา",
      bgColor: "rgba(52, 195, 143,0.3)",
      iconClass: "bx bx-timer",
      description: 0,
    },
    {
      title: "เข้างานสาย",
      bgColor: "rgba(241, 180, 76,0.3)",
      iconClass: "bx bx-calendar-exclamation",
      description: 0,
    },
    {
      title: "ลา",
      bgColor: "rgba(244, 106, 106,0.3)",
      iconClass: "bx bx-window-close",
      description: 0,
    },
    {
      title: "หน้างาน",
      bgColor: "rgba(116, 120, 141,0.3)",
      iconClass: "bx bx-home-alt",
      description: 0,
    },
    {
      title: "ส่งของ",
      bgColor: "rgba(116, 120, 141,0.3)",
      iconClass: "bx bxs-truck",
      description: 0,
    },
    {
      title: "รวม",
      bgColor: "rgba(80, 165, 241,0.3)",
      iconClass: "bx bx-caret-down-circle",
      description: 0,
    },
  ])

  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "user.name",
      text: "ชื่อ",
      sort: true,
      headerStyle: () => {
        return { width: "80px" }
      },
    },
    {
      dataField: "note",
      text: "หมายเหตุ",
      sort: true,
      headerStyle: () => {
        return { width: "100px" }
      },
    },
    {
      dataField: "status_check",
      text: "สถานะ",
      sort: true,
      headerStyle: () => {
        return { width: "80px" }
      },
    },

    {
      dataField: "created_at",
      text: "วันที่",
      sort: true,
      headerStyle: () => {
        return { width: "80px" }
      },
      formatter: function action(cell, row) {
        return moment(cell).add(543, "years").format("DD/MM/YYYY HH:mm")
      },
    },

    // {
    //   dataField: "id",
    //   text: "จัดการ",
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: "80px" }
    //   },
    //   formatter: function action(cell, row) {
    //     return (
    //       <div>
    //         <Button
    //           onClick={() => eventAction("Edit", cell, true)}
    //           className="mr-1 mb-1"
    //           color="primary"
    //           outline
    //         >
    //           <i className="bx bx-pencil"></i>
    //         </Button>
    //         {/* <Button
    //           onClick={() => eventAction("Del", cell)}
    //           className="mr-1 mb-1"
    //           color="danger"
    //           outline
    //         >
    //           <Trash className="align-middle" size={18} />
    //         </Button> */}
    //       </div>
    //     )
    //   },
    // },
  ])

  // Table Data
  const [tableData, setTableData] = React.useState([]) // set campaign as default
  const [excelTableData, setExcelTableData] = React.useState([]) // set campaign as default

  const styleSelect = {
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
    }),
  }
  const [actionModal, setActionModel] = React.useState({
    id: null,
    action: null,
    modal: false,
  })

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  React.useEffect(() => {
    // console.log("users", users)
    if (!isEmpty(users)) {
      let temp = []
      for (let index = 0; index < users.length; index++) {
        if (users[index].user_group_id > 1) {
          temp.push({
            value: users[index].id,
            label: users[index].name,
          })
        }
      }
      let newOpeion = [
        {
          value: 0,
          label: "ทั้งหมด",
        },
      ].concat(temp)

      setOptionSelectUsers(newOpeion)
    }
  }, [users])

  const pageOptions = {
    sizePerPage: 31,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  }

  const onChangeTableData = v => {
    setReloadFlatpickr(true)

    const data = new FormData()

    if (v.user_id == undefined) {
      data.append("user_id", user_id)
      if (isEmpty(v[0])) {
        setTimeStart(moment(v[0]).format("YYYY-MM-DD"))
      }
      if (isEmpty(v[1])) {
        setTimeEnd(moment(v[1]).format("YYYY-MM-DD"))
      }
      data.append("timeStart", moment(v[0]).startOf("day").format("YYYY-MM-DD"))
      data.append("timeEnd", moment(v[1]).endOf("day").format("YYYY-MM-DD"))
    } else {
      setUser_id(v.user_id)
      data.append("user_id", v.user_id)
      data.append("timeStart", v.timeStart)
      data.append("timeEnd", v.timeEnd)
    }
    setTimeout(() => {
      dispatch(onGetAttends(data))

      setReloadFlatpickr(false)
    }, 500)
  }

  React.useEffect(() => {
    dispatch(onGetUsers(1))

    onChangeTableData({
      user_id: user_id,
      timeStart: timeStart,
      timeEnd: timeEnd,
    })
  }, [dispatch])

  React.useEffect(async () => {
    if (!isEmpty(attends)) {
      setReloadFlatpickr(true)
      let report_1 = 0
      let report_2 = 0
      let report_3 = 0
      let report_4 = 0
      let report_5 = 0
      let report_6 = 0
      for (let index = 0; index < attends.length; index++) {
        if (attends[index].status == 1) {
          report_1 += 1
        } else if (attends[index].status == 2) {
          report_2 += 1
        } else if (attends[index].status == 3) {
          report_3 += 1
        } else if (attends[index].status == 4) {
          report_4 += 1
        } else if (attends[index].status == 5) {
          report_5 += 1
        }
        report_6 += 1
      }

      let temp = reports
      temp[0].description = report_1
      temp[1].description = report_2
      temp[2].description = report_3
      temp[3].description = report_4
      temp[4].description = report_5
      temp[5].description = report_6
      setReports(temp)
      // console.log("reports", reports)
      setTableData(attends)
    } else {
      setTableData([])
    }
    setReloadFlatpickr(false)
  }, [attends])
  React.useEffect(async () => {
    if (!isEmpty(tableData) && nameUser != null) {
      // console.log('1');

      let start = moment(tableData[0].created_at).startOf("month").format("DD")
      let end = moment(tableData[0].created_at).endOf("month").format("DD")
      let month = moment(tableData[0].created_at).startOf("month").format("MM")

      let dateData = []
      for (let index = Number(start); index <= end; index++) {
        let nDD = index < 10 ? "0" + index : index

        // console.log("startD", startD)
        const x = tableData.filter(v => {
          return (
            moment(v.created_at).format("DD/MM/YYYY") ==
            moment().format(nDD + "/" + month + "/YYYY")
          )
        })
        // console.log("x", x)
        if (!isEmpty(x)) {
          let newD = moment(x[0].created_at)
            .add(543, "years")
            .format("DD/MM/YYYY HH:mm")
          let myStringParts = newD.split(":")
          let hh = myStringParts[0]
          let mm = myStringParts[1]
          if (hh > 9) {
            hh = Number(hh) - 9
          } else {
            hh = "00"
          }
          mm = 0 - Number(mm)
          x[0].created_at = newD
          x[0].hh = Math.abs(hh) == 0 ? "00" : Math.abs(hh)
          x[0].mm = Math.abs(mm) < 10 ? "0" + Math.abs(mm) : Math.abs(mm)
          dateData.push(x[0])
        } else {
          let newD = moment()
            .add(543, "years")
            .format(nDD + "/" + month + "/YYYY 09:00")
          dateData.push({
            created_at: newD,
            hh: "00",
            mm: "00",
            note: "",
            status_check: "",
          })
        }
      }
      // console.log("dateData", dateData)
      setExcelTableData(dateData)
    }
  }, [tableData])

  // function eventAction(action, id, modal) {
  //   setActionModel({
  //     id: id,
  //     action: action,
  //     modal: modal,
  //   })
  // }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Attend Tables | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>
        <div className="container-fluid">
          <ExampleModal actionModal={actionModal} />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Attend Datatable </CardTitle>
                  {nameUser == null ? null : (
                    <ExcelFile
                      filename={"เวลาเข้างาน " + nameUser}
                      element={
                        <Button className="mr-1 mb-1" color="success" outline>
                          ดาวน์โหลด EXCEL
                        </Button>
                      }
                    >
                      <ExcelSheet data={excelTableData} name="Sheet1">
                        {/* <ExcelColumn label="เวลาเข้างาน" value="created_at" /> */}
                        <ExcelColumn label="เวลาเข้างาน" value="created_at" />
                        <ExcelColumn
                          label="หักสาย ชม./น."
                          value={col => col.hh + ":" + col.mm}
                        />
                        <ExcelColumn label="หมายเหตุ" value="note" />
                        <ExcelColumn label="สถานะ" value="status_check" />
                      </ExcelSheet>
                    </ExcelFile>
                  )}

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={tableColumns}
                    data={tableData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={tableColumns}
                        data={tableData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="12" lg="6">
                                <Select
                                  styles={styleSelect}
                                  placeholder="เลือกผู้ใช้งาน"
                                  name={"user_id"}
                                  value={optionSelectUsers.filter(
                                    option => option.value == user_id
                                  )}
                                  options={optionSelectUsers}
                                  onChange={e => {
                                    let obj = {
                                      user_id: e.value,
                                      timeStart: timeStart,
                                      timeEnd: timeEnd,
                                    }
                                    onChangeTableData(obj)
                                    if (e.value == 0) {
                                      setNameUser(null)
                                    } else {
                                      setNameUser(e.label)
                                    }
                                  }}
                                  classNamePrefix="select"
                                  menuPortalTarget={document.body}
                                />
                              </Col>
                              {reloadFlatpickr == true ? null : (
                                <Col
                                  sm="12"
                                  lg="6"
                                  style={{ textAlign: "right" }}
                                >
                                  <Flatpickr
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    placeholder="วันที่ค้นหา"
                                    options={{
                                      locale: Thai,
                                      mode: "range",
                                      time_24hr: true,
                                      dateFormat: "d/M/Y",
                                    }}
                                    onClose={e => {
                                      onChangeTableData(e)
                                    }}
                                  />
                                </Col>
                              )}

                              <hr className="mb-2 mt-2" />
                              <Col sm="12" style={{ textAlign: "right" }}>
                                <div className="search-box ms-2 mb-2 ">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>

                              <Col sm="12">
                                <Row className="mb-2">
                                  {reports.map((report, key) => (
                                    <Col md="4" key={"_col_" + key}>
                                      <Card
                                        className="mini-stats-wid"
                                        style={{
                                          backgroundColor: report.bgColor,
                                        }}
                                      >
                                        <CardBody>
                                          <div className="d-flex">
                                            <div className="flex-grow-1">
                                              <p className="text-muted fw-medium">
                                                {report.title}
                                              </p>
                                              <h4 className="mb-0">
                                                {report.description}
                                              </h4>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                              <span className="avatar-title rounded-circle bg-primary">
                                                <i
                                                  className={
                                                    "bx " +
                                                    report.iconClass +
                                                    " font-size-24"
                                                  }
                                                ></i>
                                              </span>
                                            </div>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  rowStyle={(row, rowIndex) => {
                                    // console.log("rowStyle", row)
                                    const style = {}
                                    if (row.status == 1) {
                                      style.backgroundColor =
                                        "rgba(52, 195, 143,0.3)"
                                    } else if (row.status == 2) {
                                      style.backgroundColor =
                                        "rgba(241, 180, 76,0.3)"
                                    } else if (row.status == 3) {
                                      style.backgroundColor =
                                        "rgba(244, 106, 106,0.3)"
                                    } else if (
                                      row.status == 4 ||
                                      row.status == 5
                                    ) {
                                      style.backgroundColor =
                                        "rgba(116, 120, 141,0.3)"
                                    }
                                    style.borderTop = "none"

                                    return style
                                  }}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

// export default ReportAttedns
export default withRouter(ReportAttedns)
