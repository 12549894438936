import {
  GET_LIST_AIUMINIAS_PROFILE,
  GET_LIST_AIUMINIAS_PROFILE_FAIL,
  GET_LIST_AIUMINIAS_PROFILE_SUCCESS,
  GET_LIST_AIUMINIASS,
  GET_LIST_AIUMINIASS_FAIL,
  GET_LIST_AIUMINIASS_SUCCESS,
  ADD_NEW_LIST_AIUMINIAS,
  ADD_LIST_AIUMINIAS_SUCCESS,
  ADD_LIST_AIUMINIAS_FAIL,
  UPDATE_LIST_AIUMINIAS,
  UPDATE_LIST_AIUMINIAS_SUCCESS,
  UPDATE_LIST_AIUMINIAS_FAIL,
  DELETE_LIST_AIUMINIAS,
  DELETE_LIST_AIUMINIAS_SUCCESS,
  DELETE_LIST_AIUMINIAS_FAIL,
} from "./actionTypes"

export const getListAluminias = (active, type) => ({
  type: GET_LIST_AIUMINIASS,
  payload: { active, type },
})

export const getListAluminiasSuccess = aluminias => ({
  type: GET_LIST_AIUMINIASS_SUCCESS,
  payload: aluminias,
})

export const addNewListAluminia = aluminia => ({
  type: ADD_NEW_LIST_AIUMINIAS,
  payload: aluminia,
})

export const addListAluminiaSuccess = aluminia => ({
  type: ADD_LIST_AIUMINIAS_SUCCESS,
  payload: aluminia,
})

export const addListAluminiaFail = error => ({
  type: ADD_LIST_AIUMINIAS_FAIL,
  payload: error,
})

export const getListAluminiasFail = error => ({
  type: GET_LIST_AIUMINIASS_FAIL,
  payload: error,
})

export const getListAluminiaProfile = id => ({
  type: GET_LIST_AIUMINIAS_PROFILE,
  payload: id,
})

export const getListAluminiaProfileSuccess = aluminiaProfile => ({
  type: GET_LIST_AIUMINIAS_PROFILE_SUCCESS,
  payload: aluminiaProfile,
})

export const getListAluminiaProfileFail = error => ({
  type: GET_LIST_AIUMINIAS_PROFILE_FAIL,
  payload: error,
})

export const updateListAluminia = aluminia => ({
  type: UPDATE_LIST_AIUMINIAS,
  payload: aluminia,
})

// export const updateListAluminia = aluminia => console.log(aluminia);

export const updateListAluminiaSuccess = aluminia => ({
  type: UPDATE_LIST_AIUMINIAS_SUCCESS,
  payload: aluminia,
})

export const updateListAluminiaFail = error => ({
  type: UPDATE_LIST_AIUMINIAS_FAIL,
  payload: error,
})

export const deleteListAluminia = aluminia => ({
  type: DELETE_LIST_AIUMINIAS,
  payload: aluminia,
})

export const deleteListAluminiaSuccess = aluminia => ({
  type: DELETE_LIST_AIUMINIAS_SUCCESS,
  payload: aluminia,
})

export const deleteListAluminiaFail = error => ({
  type: DELETE_LIST_AIUMINIAS_FAIL,
  payload: error,
})
