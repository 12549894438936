import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_ATTENDSS,
  GET_ATTENDS_PROFILE,
  ADD_NEW_ATTENDS,
  DELETE_ATTENDS,
  UPDATE_ATTENDS,
} from "./actionTypes"

import {
  getAttendsSuccess,
  getAttendsFail,
  getAttendProfileSuccess,
  getAttendProfileFail,
  addAttendFail,
  addAttendSuccess,
  updateAttendSuccess,
  updateAttendFail,
  deleteAttendSuccess,
  deleteAttendFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAttends,
  getAttendProfile,
  addNewAttend,
  updateAttend,
  deleteAttend,
} from "../../helpers/fakebackend_helper"

function* fetchAttends({ payload: active }) {
  try {
    const response = yield call(getAttends, active)
    // console.log("fetchAttends", response)
    yield put(getAttendsSuccess(response))
  } catch (error) {
    yield put(getAttendsFail(error))
  }
}

function* fetchAttendProfile({ payload: id }) {
  // console.log(id)updateAttend
  try {
    const response = yield call(getAttendProfile, id)
    // console.log("Profile => ", response)
    yield put(getAttendProfileSuccess(response))
  } catch (error) {
    yield put(getAttendProfileFail(error))
  }
}

function* onUpdateAttend({ payload: attend }) {
  try {
    // let x = attend.get('formData')
    // console.log(x)
    const response = yield call(updateAttend, attend)
    yield put(updateAttendSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateAttendFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteAttend({ payload: attend }) {
  try {
    const response = yield call(deleteAttend, attend)
    yield put(deleteAttendSuccess(response))
  } catch (error) {
    yield put(deleteAttendFail(error))
  }
}

function* onAddNewAttend({ payload: attend }) {
  try {
    // let x = user.get('formData')
    // console.log(x)
    const response = yield call(addNewAttend, attend)
    // console.log("onAddNewAttend", response)
    if (response.check_in) {
      yield put(addAttendSuccess(response))
      Notifications({
        alert: true,
        positionClass: "toast-top-right",
        toastType: "success",
        title: "เช็คอิน",
        message: "เช็คอินสำเร็จ",
      })
    } else {
      Notifications({
        alert: true,
        positionClass: "toast-top-right",
        toastType: "error",
        title: "เช็คอิน",
        message: "เช็คอินล้มเหลว",
      })
    }
    // console.log("onAddNewAttend", response.check_in)
  } catch (error) {
    yield put(addAttendFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เช็คอิน",
      message: "เช็คอินล้มเหลว",
    })
  }
}

function* attend() {
  yield takeEvery(GET_ATTENDSS, fetchAttends)
  yield takeEvery(GET_ATTENDS_PROFILE, fetchAttendProfile)
  yield takeEvery(ADD_NEW_ATTENDS, onAddNewAttend)
  yield takeEvery(UPDATE_ATTENDS, onUpdateAttend)
  yield takeEvery(DELETE_ATTENDS, onDeleteAttend)
}

export default attend
