import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_BANKS,
  GET_BANK_PROFILE,
  ADD_NEW_BANK,
  DELETE_BANK,
  UPDATE_BANK,
} from "./actionTypes"

import {
  getBanksSuccess,
  getBanksFail,
  getBankProfileSuccess,
  getBankProfileFail,
  addBankFail,
  addBankSuccess,
  updateBankSuccess,
  updateBankFail,
  deleteBankSuccess,
  deleteBankFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBanks,
  getBankProfile,
  addNewBank,
  updateBank,
  deleteBank,
} from "../../helpers/fakebackend_helper"

function* fetchBanks({ payload: active }) {
  try {
    const response = yield call(getBanks, active)
    // console.log(active);
    yield put(getBanksSuccess(response))
  } catch (error) {
    yield put(getBanksFail(error))
  }
}

function* fetchBankProfile({ payload: id }) {
  // console.log(id)updateBank
  try {
    const response = yield call(getBankProfile, id)
    // console.log("Profile => ", response)
    yield put(getBankProfileSuccess(response))
  } catch (error) {
    yield put(getBankProfileFail(error))
  }
}

function* onUpdateBank({ payload: designgroup }) {
  try {
    // let x = designgroup.get('formData')
    // console.log(x)
    const response = yield call(updateBank, designgroup)
    yield put(updateBankSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateBankFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteBank({ payload: designgroup }) {
  try {
    const response = yield call(deleteBank, designgroup)
    yield put(deleteBankSuccess(response))
  } catch (error) {
    yield put(deleteBankFail(error))
  }
}

function* onAddNewBank({ payload: designgroup }) {
  try {
    const response = yield call(addNewBank, designgroup)
    yield put(addBankSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addBankFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* bank() {
  yield takeEvery(GET_BANKS, fetchBanks)
  yield takeEvery(GET_BANK_PROFILE, fetchBankProfile)
  yield takeEvery(ADD_NEW_BANK, onAddNewBank)
  yield takeEvery(UPDATE_BANK, onUpdateBank)
  yield takeEvery(DELETE_BANK, onDeleteBank)
}

export default bank
