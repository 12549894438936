import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_BOQ_HOMES,
  GET_BOQ_HOME_PROFILE,
  ADD_NEW_BOQ_HOME,
  DELETE_BOQ_HOME,
  UPDATE_BOQ_HOME,
} from "./actionTypes"

import {
  getBoqHomesSuccess,
  getBoqHomesFail,
  getBoqHomeProfileSuccess,
  getBoqHomeProfileFail,
  addBoqHomeFail,
  addBoqHomeSuccess,
  updateBoqHomeSuccess,
  updateBoqHomeFail,
  deleteBoqHomeSuccess,
  deleteBoqHomeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBoqHomes,
  getBoqHomeProfile,
  addNewBoqHome,
  updateBoqHome,
  deleteBoqHome,
} from "../../helpers/fakebackend_helper"

function* fetchBoqHomes({ payload: data }) {
  try {
    const response = yield call(getBoqHomes, data)
    // console.log(response);
    yield put(getBoqHomesSuccess(response))
  } catch (error) {
    yield put(getBoqHomesFail(error))
  }
}

function* fetchBoqHomeProfile({ payload: id }) {
  // console.log(id)updateBoqHome
  try {
    const response = yield call(getBoqHomeProfile, id)
    // console.log("Profile => ", response)
    yield put(getBoqHomeProfileSuccess(response))
  } catch (error) {
    yield put(getBoqHomeProfileFail(error))
  }
}

function* onUpdateBoqHome({ payload: boqhome }) {
  try {
    // let x = boqhome.get('formData')
    // console.log(x)
    const response = yield call(updateBoqHome, boqhome)
    // console.log(response);
    yield put(getBoqHomesSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateBoqHomeFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteBoqHome({ payload: boqhome }) {
  try {
    const response = yield call(deleteBoqHome, boqhome)
    yield put(deleteBoqHomeSuccess(response))
  } catch (error) {
    yield put(deleteBoqHomeFail(error))
  }
}

function* onAddNewBoqHome({ payload: boqhome }) {
  try {
    const response = yield call(addNewBoqHome, boqhome)
    yield put(addBoqHomeSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addBoqHomeFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* boqHome() {
  yield takeEvery(GET_BOQ_HOMES, fetchBoqHomes)
  yield takeEvery(GET_BOQ_HOME_PROFILE, fetchBoqHomeProfile)
  yield takeEvery(ADD_NEW_BOQ_HOME, onAddNewBoqHome)
  yield takeEvery(UPDATE_BOQ_HOME, onUpdateBoqHome)
  yield takeEvery(DELETE_BOQ_HOME, onDeleteBoqHome)
}

export default boqHome
