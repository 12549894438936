import React from "react"
import { Row, Col } from "reactstrap"

//Import Images
import logolight from "../../../../assets/images/logo-light.png"

const FooterLink = () => {
  return (
    <React.Fragment>
      <Row>
        <Col lg="6">
          <div className="mb-4">
            <img
              src={logolight}
              alt={process.env.REACT_APP_NAMECOMPANY}
              height="30"
            />
          </div>
        </Col>

        <Col lg="6">
          <p className="mb-2" style={{ float: "right" }}>
            {new Date().getFullYear()} © PMH. Design & Develop
          </p>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FooterLink
