/* WITHDRAW_MATERIALS */
export const GET_WITHDRAW_MATERIALS = "GET_WITHDRAW_MATERIALS"
export const GET_WITHDRAW_MATERIALS_SUCCESS = "GET_WITHDRAW_MATERIALS_SUCCESS"
export const GET_WITHDRAW_MATERIALS_SUCCESS_SUMMARIZE = "GET_WITHDRAW_MATERIALS_SUCCESS_SUMMARIZE"
export const GET_WITHDRAW_MATERIALS_FAIL = "GET_WITHDRAW_MATERIALS_FAIL"

/* WITHDRAW_MATERIALS PROFILE */
export const GET_WITHDRAW_MATERIAL_PROFILE = "GET_WITHDRAW_MATERIAL_PROFILE"
export const GET_WITHDRAW_MATERIAL_PROFILE_SUCCESS = "GET_WITHDRAW_MATERIAL_PROFILE_SUCCESS"
export const GET_WITHDRAW_MATERIAL_PROFILE_FAIL = "GET_WITHDRAW_MATERIAL_PROFILE_FAIL"

/**
 * add withdrawmaterial
 */
export const ADD_NEW_WITHDRAW_MATERIAL = "ADD_NEW_WITHDRAW_MATERIAL"
export const ADD_WITHDRAW_MATERIAL_SUCCESS = "ADD_WITHDRAW_MATERIAL_SUCCESS"
export const ADD_WITHDRAW_MATERIAL_FAIL = "ADD_WITHDRAW_MATERIAL_FAIL"

/**
 * Edit withdrawmaterial
 */
export const UPDATE_WITHDRAW_MATERIAL = "UPDATE_WITHDRAW_MATERIAL"
export const UPDATE_WITHDRAW_MATERIAL_SUCCESS = "UPDATE_WITHDRAW_MATERIAL_SUCCESS"
export const UPDATE_WITHDRAW_MATERIAL_FAIL = "UPDATE_WITHDRAW_MATERIAL_FAIL"

/**
 * Delete withdrawmaterial
 */
export const DELETE_WITHDRAW_MATERIAL = "DELETE_WITHDRAW_MATERIAL"
export const DELETE_WITHDRAW_MATERIAL_SUCCESS = "DELETE_WITHDRAW_MATERIAL_SUCCESS"
export const DELETE_WITHDRAW_MATERIAL_FAIL = "DELETE_WITHDRAW_MATERIAL_FAIL"
