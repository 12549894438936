import {
  GET_CONTRACTCONPROJECT_PROFILE,
  GET_CONTRACTCONPROJECT_PROFILE_FAIL,
  GET_CONTRACTCONPROJECT_PROFILE_SUCCESS,
  GET_CONTRACTCONPROJECTS,
  GET_CONTRACTCONPROJECTS_FAIL,
  GET_CONTRACTCONPROJECTS_SUCCESS,
  ADD_NEW_CONTRACTCONPROJECT,
  ADD_CONTRACTCONPROJECT_SUCCESS,
  ADD_CONTRACTCONPROJECT_FAIL,
  UPDATE_CONTRACTCONPROJECT,
  UPDATE_CONTRACTCONPROJECT_SUCCESS,
  UPDATE_CONTRACTCONPROJECT_FAIL,
  DELETE_CONTRACTCONPROJECT,
  DELETE_CONTRACTCONPROJECT_SUCCESS,
  DELETE_CONTRACTCONPROJECT_FAIL,
} from "./actionTypes"

// export const getContactConProjects = project_id => ({
//   type: GET_CONTRACTCONPROJECTS,
//   payload: project_id,
// })

export const getContactConProjects = project_id => ({
  type: GET_CONTRACTCONPROJECTS,
  payload: project_id,
})

export const getContactConProjectsSuccess = contactconprojects => ({
  type: GET_CONTRACTCONPROJECTS_SUCCESS,
  payload: contactconprojects,
})

export const addNewContactConProject = contactconproject => ({
  type: ADD_NEW_CONTRACTCONPROJECT,
  payload: contactconproject,
})

export const addContactConProjectSuccess = contactconproject => ({
  type: ADD_CONTRACTCONPROJECT_SUCCESS,
  payload: contactconproject,
})

export const addContactConProjectFail = error => ({
  type: ADD_CONTRACTCONPROJECT_FAIL,
  payload: error,
})
export const getContactConProjectsFail = error => ({
  type: GET_CONTRACTCONPROJECTS_FAIL,
  payload: error,
})

export const getContactConProjectProfile = (id, type) => ({
  type: GET_CONTRACTCONPROJECT_PROFILE,
  payload: { id, type },
})

export const getContactConProjectProfileSuccess =
  contactconprojectProfile => ({
    type: GET_CONTRACTCONPROJECT_PROFILE_SUCCESS,
    payload: contactconprojectProfile,
  })

export const getContactConProjectProfileFail = error => ({
  type: GET_CONTRACTCONPROJECT_PROFILE_FAIL,
  payload: error,
})

export const updateContactConProject = contactconproject => ({
  type: UPDATE_CONTRACTCONPROJECT,
  payload: contactconproject,
})

// export const updateContactConProject = contactconproject => console.log(contactconproject);

export const updateContactConProjectSuccess = contactconproject => ({
  type: UPDATE_CONTRACTCONPROJECT_SUCCESS,
  payload: contactconproject,
})

export const updateContactConProjectFail = error => ({
  type: UPDATE_CONTRACTCONPROJECT_FAIL,
  payload: error,
})

export const deleteContactConProject = contactconproject => ({
  type: DELETE_CONTRACTCONPROJECT,
  payload: contactconproject,
})

export const deleteContactConProjectSuccess = contactconproject => ({
  type: DELETE_CONTRACTCONPROJECT_SUCCESS,
  payload: contactconproject,
})

export const deleteContactConProjectFail = error => ({
  type: DELETE_CONTRACTCONPROJECT_FAIL,
  payload: error,
})
