/* LIST_AIUMINIA_PROJECTS */
export const GET_LIST_AIUMINIA_PROJECTS = "GET_LIST_AIUMINIA_PROJECTS"
export const GET_LIST_AIUMINIA_PROJECTS_SUCCESS = "GET_LIST_AIUMINIA_PROJECTS_SUCCESS"
export const GET_LIST_AIUMINIA_PROJECTS_FAIL = "GET_LIST_AIUMINIA_PROJECTS_FAIL"

/* LIST_AIUMINIA_PROJECTS PROFILE */
export const GET_LIST_AIUMINIA_PROJECTS_PROFILE = "GET_LIST_AIUMINIA_PROJECTS_PROFILE"
export const GET_LIST_AIUMINIA_PROJECTS_PROFILE_SUCCESS = "GET_LIST_AIUMINIA_PROJECTS_PROFILE_SUCCESS"
export const GET_LIST_AIUMINIA_PROJECTS_PROFILE_FAIL = "GET_LIST_AIUMINIA_PROJECTS_PROFILE_FAIL"

/**
 * add aluminia
 */
export const ADD_NEW_LIST_AIUMINIA_PROJECTS = "ADD_NEW_LIST_AIUMINIA_PROJECTS"
export const ADD_LIST_AIUMINIA_PROJECTS_SUCCESS = "ADD_LIST_AIUMINIA_PROJECTS_SUCCESS"
export const ADD_LIST_AIUMINIA_PROJECTS_FAIL = "ADD_LIST_AIUMINIA_PROJECTS_FAIL"

/**
 * Edit aluminia
 */
export const UPDATE_LIST_AIUMINIA_PROJECTS = "UPDATE_LIST_AIUMINIA_PROJECTS"
export const UPDATE_LIST_AIUMINIA_PROJECTS_SUCCESS = "UPDATE_LIST_AIUMINIA_PROJECTS_SUCCESS"
export const UPDATE_LIST_AIUMINIA_PROJECTS_FAIL = "UPDATE_LIST_AIUMINIA_PROJECTS_FAIL"

/**
 * Delete aluminia
 */
export const DELETE_LIST_AIUMINIA_PROJECTS = "DELETE_LIST_AIUMINIA_PROJECTS"
export const DELETE_LIST_AIUMINIA_PROJECTS_SUCCESS = "DELETE_LIST_AIUMINIA_PROJECTS_SUCCESS"
export const DELETE_LIST_AIUMINIA_PROJECTS_FAIL = "DELETE_LIST_AIUMINIA_PROJECTS_FAIL"
