import {
  GET_BANKS_SUCCESS,
  GET_BANKS_FAIL,
  ADD_BANK_SUCCESS,
  ADD_BANK_FAIL,
  UPDATE_BANK_SUCCESS,
  UPDATE_BANK_FAIL,
  DELETE_BANK_SUCCESS,
  DELETE_BANK_FAIL,
  GET_BANK_PROFILE_SUCCESS,
  GET_BANK_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  banks: [],
  bankProfile: {},
  error: {},
}

const banks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BANKS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        banks: action.payload,
      }

    case GET_BANKS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_BANK_SUCCESS:
      return {
        ...state,
        banks: [...state.banks, action.payload],
      }

    case ADD_BANK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BANK_PROFILE_SUCCESS:
      // console.log("GET_BANK_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        bankProfile: action.payload,
      }

    case UPDATE_BANK_SUCCESS:
      return {
        ...state,
        banks: state.banks.map(designgroup =>
          designgroup.id.toString() === action.payload.id.toString()
            ? { designgroup, ...action.payload }
            : designgroup
        ),
      }

    case UPDATE_BANK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BANK_SUCCESS:
      return {
        ...state,
        banks: state.banks.filter(
          designgroup =>
            designgroup.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_BANK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BANK_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default banks
