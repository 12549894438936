import { call, put, takeEvery } from "redux-saga/effects"
import { Notifications } from "../../components/Common/Notifications"

// Crypto Redux States
import {
  GET_LIST_QCS,
  GET_LIST_QC_PROFILE,
  ADD_NEW_LIST_QC,
  DELETE_LIST_QC,
  UPDATE_LIST_QC,
} from "./actionTypes"

import {
  getListQcsSuccess,
  getListQcsFail,
  getListQcProfileSuccess,
  getListQcProfileFail,
  addListQcFail,
  addListQcSuccess,
  updateListQcSuccess,
  updateListQcFail,
  deleteListQcSuccess,
  deleteListQcFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getListQcs,
  getListQcProfile,
  addNewListQc,
  updateListQc,
  deleteListQc,
} from "../../helpers/fakebackend_helper"

function* fetchListQcs({ payload: active }) {
  try {
    const response = yield call(getListQcs, active)
    // console.log(response);
    yield put(getListQcsSuccess(response))
  } catch (error) {
    yield put(getListQcsFail(error))
  }
}

function* fetchListQcProfile({ payload: id }) {
  // console.log(id)updateListQc
  try {
    const response = yield call(getListQcProfile, id)
    // console.log("Profile => ", response)
    yield put(getListQcProfileSuccess(response))
  } catch (error) {
    yield put(getListQcProfileFail(error))
  }
}

function* onUpdateListQc({ payload: qc }) {
  try {
    // let x = qc.get('formData')
    // console.log(x)
    const response = yield call(updateListQc, qc)
    yield put(updateListQcSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "อัพเดต",
      message: "อัพเดตสำเร็จ",
    })
  } catch (error) {
    yield put(updateListQcFail(error))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "อัพเดต",
      message: "อัพเดตล้มเหลว",
    })
  }
}

function* onDeleteListQc({ payload: qc }) {
  try {
    const response = yield call(deleteListQc, qc)
    yield put(deleteListQcSuccess(response))
  } catch (error) {
    yield put(deleteListQcFail(error))
  }
}

function* onAddNewListQc({ payload: qc }) {
  try {
    const response = yield call(addNewListQc, qc)
    yield put(addListQcSuccess(response))
    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "success",
      title: "เพิ่ม",
      message: "เพิ่มสำเร็จ",
    })
  } catch (error) {
    yield put(addListQcFail(error))

    Notifications({
      alert: true,
      positionClass: "toast-top-right",
      toastType: "error",
      title: "เพิ่ม",
      message: "เพิ่มล้มเหลว",
    })
  }
}

function* listStructure() {
  yield takeEvery(GET_LIST_QCS, fetchListQcs)
  yield takeEvery(GET_LIST_QC_PROFILE, fetchListQcProfile)
  yield takeEvery(ADD_NEW_LIST_QC, onAddNewListQc)
  yield takeEvery(UPDATE_LIST_QC, onUpdateListQc)
  yield takeEvery(DELETE_LIST_QC, onDeleteListQc)
}

export default listStructure
