import React from "react"
import { ToastContainer } from "react-toastr"
import PropTypes from "prop-types"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"
import CookieConsent, {
  Cookies,
  resetCookieConsentValue,
  getCookieConsentValue,
} from "react-cookie-consent"

import publicIp from "react-public-ip"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
// fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.                      ,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {
  const [widthDimensions, setWidthDimensions] = React.useState(540)

  function getLayout() {
    let layoutCls = widthDimensions < 800 ? HorizontalLayout : VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  async function keepCookie() {
    let housename = window.location.hostname
    const ipv4 = (await publicIp.v4()) || ""
    const ipv6 = (await publicIp.v6()) || ""

    Cookies.set("PMHCookie", {
      housename: housename,
      ipv4: ipv4,
      ipv6: ipv6,
    })
    // setTimeout(() => {
    //   console.log(getCookieConsentValue("PMHCookie"))
    // }, 1000)
  }

  function getWindowDimensions() {
    // const { innerWidth: width, innerHeight: height } = window
    const { innerWidth: width } = window
    return width
  }

  React.useEffect(() => {
    function handleResize() {
      setWidthDimensions(getWindowDimensions())
    }
    getLayout()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [widthDimensions])

  // async function useWindowDimensions() {
  //   const [windowDimensions, setWindowDimensions] = useState(
  //     getWindowDimensions()
  //   )

  //   useEffect(() => {
  //     function handleResize() {
  //       setWindowDimensions(getWindowDimensions())
  //     }

  //     window.addEventListener("resize", handleResize)
  //     return () => window.removeEventListener("resize", handleResize)
  //   }, [])

  //   return windowDimensions
  // }

  const Layout = getLayout()
  return (
    <React.Fragment>
      <CookieConsent
        location="none"
        cookieName="CookiePremiumHouse"
        style={{
          borderRadius: "5px",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
          color: "#000000",
          top: "50%",
          right: "20%",
          left: "20%",
          width: "auto",
          textAlign: "center",
          display: "inline",
        }}
        buttonStyle={{
          backgroundColor: "#5cb85c",
          color: "#FFFFFF",
          fontSize: "13px",
        }}
        expires={365}
        overlay
        buttonText="ยินยอม"
        enableDeclineButton
        declineButtonText="ไม่ยินยอม"
        onAccept={() => keepCookie()}
        onDecline={() => {
          // alert("nay!")
        }}
      >
        <p style={{ fontSize: "13px" }}>
          เว็บไซต์ของเรามีการเก็บ cookies เพื่อทำการตลาดเช่น google analytics
          และนำมาสู่การทำให้ประสบการณ์การใช้เว็บไซต์ดียิ่งขึ้น
          <a href="#">&nbsp; อ่านเพิ่มเติมคลิก (Privacy Policy)</a>
        </p>
      </CookieConsent>

      <ToastContainer />

      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
