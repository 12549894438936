/* MATERIAL_STOCKS */
export const GET_MATERIAL_STOCKS = "GET_MATERIAL_STOCKS"
export const GET_MATERIAL_STOCKS_SUCCESS = "GET_MATERIAL_STOCKS_SUCCESS"
export const GET_MATERIAL_STOCKS_FAIL = "GET_MATERIAL_STOCKS_FAIL"

/* MATERIAL_STOCKS PROFILE */
export const GET_MATERIAL_STOCK_PROFILE = "GET_MATERIAL_STOCK_PROFILE"
export const GET_MATERIAL_STOCK_PROFILE_SUCCESS = "GET_MATERIAL_STOCK_PROFILE_SUCCESS"
export const GET_MATERIAL_STOCK_PROFILE_FAIL = "GET_MATERIAL_STOCK_PROFILE_FAIL"

/**
 * add materialstock
 */
export const ADD_NEW_MATERIAL_STOCK = "ADD_NEW_MATERIAL_STOCK"
export const ADD_MATERIAL_STOCK_SUCCESS = "ADD_MATERIAL_STOCK_SUCCESS"
export const ADD_MATERIAL_STOCK_FAIL = "ADD_MATERIAL_STOCK_FAIL"

/**
 * Edit materialstock
 */
export const UPDATE_MATERIAL_STOCK = "UPDATE_MATERIAL_STOCK"
export const UPDATE_MATERIAL_STOCK_SUCCESS = "UPDATE_MATERIAL_STOCK_SUCCESS"
export const UPDATE_MATERIAL_STOCK_FAIL = "UPDATE_MATERIAL_STOCK_FAIL"

/**
 * Delete materialstock
 */
export const DELETE_MATERIAL_STOCK = "DELETE_MATERIAL_STOCK"
export const DELETE_MATERIAL_STOCK_SUCCESS = "DELETE_MATERIAL_STOCK_SUCCESS"
export const DELETE_MATERIAL_STOCK_FAIL = "DELETE_MATERIAL_STOCK_FAIL"
