import React from "react"
import "../Tables/datatables.scss"
import { Row, Col, Card, CardBody, Button, CardTitle, Table } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { isEmpty, map } from "lodash"
import ExampleModal from "components/Dialogs/dialogProject"
import ExampleModalInfo from "components/Dialogs/dialogUser_info"
import profileImg from "../../assets/images/profile-img.png"
import { getProjects as onGetProjects } from "store/projects/actions"
import moment from "moment"
import "moment/locale/th" // without this line it didn't work
const WelcomeComp = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [lastInsert, setLastIntert] = React.useState(null)
  const [formData, setFormData] = React.useState({})

  const [obj, setObj] = React.useState(
    JSON.parse(localStorage.getItem("authUser"))
  )

  const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }))

  const { lastInsertProject } = useSelector(state => ({
    lastInsertProject: state.projects.lastInsertProject,
  }))

  const [tableData, setTableData] = React.useState([])
  const [project_progress, setProject_progress] = React.useState([])

  const [actionModal, setActionModel] = React.useState({
    id: null,
    action: null,
    modal: false,
  })

  const [actionModal_info_user, setActionModal_info_user] = React.useState({
    modal: false,
  })

  React.useEffect(() => {
    // console.log(lastInsertProject)
    if (lastInsertProject != null && lastInsertProject != lastInsert) {
      history.push("/projects-overview/" + lastInsertProject)
      setLastIntert(lastInsertProject)
    } else {
      setLastIntert(null)
    }
  }, [lastInsertProject])

  React.useEffect(() => {
    // console.log("obj", obj)
    if (!isEmpty(obj.me)) {
      setFormData(obj.me)
      setProject_progress(obj.project_progress)
    }
  }, [obj])

  React.useEffect(() => {
    // console.log(lastInsertProject)
    if (!isEmpty(projects)) {
      setTableData(projects)
    } else {
      setTableData(null)
    }
  }, [projects])

  React.useEffect(() => {
    dispatch(onGetProjects("quickList", null))
  }, [dispatch])

  function eventAction(action, id, modal) {
    setActionModel({
      id: id,
      action: action,
      modal: modal,
    })
  }

  const pageOptions_project_progress = {
    sizePerPage: 5,
    totalSize: project_progress.length, // replace later with size(customers),
    custom: true,
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  return (
    <React.Fragment>
      <ExampleModal actionModal={actionModal} />
      <ExampleModalInfo actionModal={actionModal_info_user} />

      <Card className="overflow-hidden">
        <CardTitle className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">
                  Welcome Back ! <span>PMH Dashboard</span>
                </h5>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img
                src={profileImg}
                alt={process.env.REACT_APP_NAMECOMPANY}
                className="img-fluid"
              />
            </Col>
          </Row>
        </CardTitle>
        <CardBody className="pt-0">
          <Row>
            <Col
              sm="4"
              style={{
                borderRight: "1px solid #EFF2F7",
              }}
            >
              <div className="avatar-md profile-user-wid mb-4">
                {!isEmpty(formData.img_profile) && (
                  <img
                    src={
                      process.env.REACT_APP_SERVER + formData.path_img_profile
                    }
                    alt={process.env.REACT_APP_NAMECOMPANY}
                    className="avatar-md rounded-circle"
                  />
                )}
              </div>
              <Row>
                <Col xs="12">
                  <span className="font-size-15">
                    ชื่อเข้าใช้งาน/email: &nbsp;
                    <span>{obj.me.email}</span>
                  </span>
                </Col>

                <Col xs="6">
                  <h5 className="font-size-15">ชื่อ</h5>
                  <p
                    style={{ color: "#556EE6", cursor: "pointer" }}
                    className="mb-0"
                    onClick={e =>
                      setActionModal_info_user({
                        modal: true,
                      })
                    }
                  >
                    {obj.me.name}
                  </p>
                </Col>
                <Col xs="6" className="mt-2">
                  <h5 className="font-size-15 text-truncate">ตำแหน่ง</h5>
                  <p className="text-muted mb-0 text-truncate">
                    {obj.group.name}
                  </p>
                </Col>
                <Col xs="6" className="mt-2">
                  <h5 className="font-size-15">เบอร์ติดต่อ</h5>
                  <p className="text-muted mb-0">{obj.me.number_phone}</p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col
                  sm="12"
                  // style={{
                  //   borderRight: "1px solid black",
                  // }}
                >
                  <div
                    style={{
                      height: 350,
                      overflow: "auto",
                    }}
                  >
                    <Table className="table mb-0" responsive>
                      {/* <thead> */}
                      {/* <tr> */}
                      {/* <th style={{ width: "100%" }}>ชื่อ</th> */}
                      {/* <th style={{ width: "10%" }}>สร้าง</th> */}
                      {/* <th style={{ width: "20%" }}>ระยะเวลา</th> */}
                      {/* </tr> */}
                      {/* </thead> */}
                      <tbody>
                        {map(project_progress, (v, k) => (
                          <tr key={k}>
                            <td>
                              <Link
                                to={`/projects-overview/${v.id}`}
                                className="text-dark"
                              >
                                <span style={{ color: "blue" }}>{v.name}</span>
                              </Link>
                            </td>

                            {/* <td>
                              <span>
                                {moment(v.created_at)
                                  .add(543, "year")
                                  .format("LL")}
                              </span>
                            </td>

                            <td>
                              <span>
                                {moment(v.updated_at)
                                  .add(543, "year")
                                  .format("LL")}
                              </span>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  {/* <PaginationProvider
                    pagination={paginationFactory(pageOptions_project_progress)}
                    keyField="id"
                    columns={tableColumns}
                    data={project_progress}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={tableColumns}
                        data={project_progress}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col sm="8">
                                <h5>โปรเจคที่กำลังดำเนินการ</h5>
                              </Col>
                              <Col sm="4" style={{ textAlign: "right" }}>
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <br />
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered
                                    striped
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider> */}
                </Col>
              </Row>
            </Col>

            <Col sm="8">
              <div className="text-center">
                <div className="mb-4">
                  <i className="bx bx-map-pin text-primary display-4" />
                </div>
              </div>

              <div className="d-flex">
                <Button
                  color="primary"
                  className="btn btn-primary  btn-sm"
                  onClick={() => eventAction("Add", null, true)}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  เพิ่มโปรเจค
                </Button>
                &nbsp;
                <Link to={`/projects-grid`} className="btn btn-primary  btn-sm">
                  ไปโปรเจคทั้งหมด
                </Link>
              </div>
              <div
                style={{
                  height: 350,
                  overflow: "auto",
                }}
              >
                <Table className="table mb-0" responsive>
                  <thead>
                    <tr>
                      <th>โปรเจค</th>
                      <th>เบอร์ติดต่อ</th>
                      <th>สถานที่</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(tableData, (v, k) => (
                      <tr key={k}>
                        <td>
                          <Link
                            to={`/projects-overview/${v.id}`}
                            className="text-dark"
                          >
                            <span style={{ color: "blue" }}>{v.name}</span>
                          </Link>
                        </td>
                        <td>
                          {v.add_info == undefined ? (
                            ""
                          ) : (
                            <a
                              href={"tel:" + v.add_info.tel}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {v.add_info.tel}
                            </a>
                          )}
                        </td>

                        <td>
                          {v.add_info == undefined ? (
                            ""
                          ) : (
                            <a
                              href={"tel:" + v.add_info.location}
                              target="_blank"
                              rel="noreferrer"
                            >
                              ดูสถานที่
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
