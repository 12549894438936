import {
  GET_LIST_PRODUCTS_SUCCESS,
  GET_LIST_PRODUCTS_FAIL,
  ADD_LIST_PRODUCT_SUCCESS,
  ADD_LIST_PRODUCT_FAIL,
  UPDATE_LIST_PRODUCT_SUCCESS,
  UPDATE_LIST_PRODUCT_FAIL,
  DELETE_LIST_PRODUCT_SUCCESS,
  DELETE_LIST_PRODUCT_FAIL,
  GET_LIST_PRODUCT_PROFILE_SUCCESS,
  GET_LIST_PRODUCT_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  listProducts: [],
  listProductProfile: {},
  error: {},
}

const listProducts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_PRODUCTS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        listProducts: action.payload,
      }

    case GET_LIST_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        listProducts: [...state.listProducts, action.payload],
      }

    case ADD_LIST_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_PRODUCT_PROFILE_SUCCESS:
      // console.log("GET_LIST_PRODUCT_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        listProductProfile: action.payload,
      }

    case UPDATE_LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        listProducts: state.listProducts.map(product =>
          product.id.toString() === action.payload.id.toString()
            ? { product, ...action.payload }
            : product
        ),
      }

    case UPDATE_LIST_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        listProducts: state.listProducts.filter(
          product =>
            product.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_LIST_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_PRODUCT_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default listProducts
