import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { isEmpty, map } from "lodash"
import { useSelector, useDispatch } from "react-redux"

import logo_s from "assets/images/logo_s.png"
import { getSlipprojectProfile as onGetSlipprojectProfile } from "store/slips-project/actions"
import THBText from "thai-baht-text" // for ES6
import moment from "moment"
import "moment/locale/th" // without this line it didn't work
const InvoiceCheck = props => {
  const {
    match: { params },
  } = props
  const [formData, setFormData] = React.useState(params.id)

  const printInvoice = () => {
    window.print()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Check Detail | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>

        <Container fluid style={{ fontFamily: "sans-serif" }}>
          {!isEmpty(formData) && (
            <Row>
              <Col lg="12">
                {/* header */}
                <div className="d-print-none">
                  <div style={{ textAlignLast: "right" }}>
                    <Link
                      to="#"
                      onClick={printInvoice}
                      className="btn btn-success me-2"
                    >
                      <i className="fa fa-print" />
                    </Link>

                    <Link
                      to={`/projects-overview/${formDataSlip.project_id}`}
                      className="btn btn-secondary"
                    >
                      Back
                    </Link>
                  </div>
                  <hr />
                </div>
                {/* page 1 */}
                <Card className="pagebreak" style={{ boxShadow: "none" }}>
                  <CardBody></CardBody>
                </Card>
                <hr />
                <div className="d-print-none">
                  <div style={{ textAlignLast: "right" }}>
                    <Link
                      to="#"
                      onClick={printInvoice}
                      className="btn btn-success me-2"
                    >
                      <i className="fa fa-print" />
                    </Link>

                    <Link
                      to={`/projects-overview/${formDataSlip.project_id}`}
                      className="btn btn-secondary"
                    >
                      Back
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoiceCheck.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceCheck)
