/* CONTRACTORS */
export const GET_CONTRACTORS = "GET_CONTRACTORS"
export const GET_CONTRACTORS_SUCCESS = "GET_CONTRACTORS_SUCCESS"
export const GET_CONTRACTORS_FAIL = "GET_CONTRACTORS_FAIL"

/* CONTRACTORS PROFILE */
export const GET_CONTRACTOR_PROFILE = "GET_CONTRACTOR_PROFILE"
export const GET_CONTRACTOR_PROFILE_SUCCESS = "GET_CONTRACTOR_PROFILE_SUCCESS"
export const GET_CONTRACTOR_PROFILE_FAIL = "GET_CONTRACTOR_PROFILE_FAIL"

/**
 * add contractor
 */
export const ADD_NEW_CONTRACTOR = "ADD_NEW_CONTRACTOR"
export const ADD_CONTRACTOR_SUCCESS = "ADD_CONTRACTOR_SUCCESS"
export const ADD_CONTRACTOR_FAIL = "ADD_CONTRACTOR_FAIL"

/**
 * Edit contractor
 */
export const UPDATE_CONTRACTOR = "UPDATE_CONTRACTOR"
export const UPDATE_CONTRACTOR_SUCCESS = "UPDATE_CONTRACTOR_SUCCESS"
export const UPDATE_CONTRACTOR_FAIL = "UPDATE_CONTRACTOR_FAIL"

/**
 * Delete contractor
 */
export const DELETE_CONTRACTOR = "DELETE_CONTRACTOR"
export const DELETE_CONTRACTOR_SUCCESS = "DELETE_CONTRACTOR_SUCCESS"
export const DELETE_CONTRACTOR_FAIL = "DELETE_CONTRACTOR_FAIL"
