import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { isEmpty, map } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { getQuotationProjectProfile as onGetQuotationProjectProfile } from "store/quotations-project/actions"
import logo_home_reg from "assets/images/logo_home_reg.jpg"
import logo_dbd from "assets/images/logo_dbd.jpg"
import pdfMake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

//ต้องระบุตามชื่อของ ไฟล์ font
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
}

const InvoiceCheck = props => {
  const dispatch = useDispatch()

  const {
    match: { params },
  } = props

  const { quotationprojectProfile } = useSelector(state => ({
    quotationprojectProfile: state.quotationprojects.quotationprojectProfile,
  }))

  const { refprojectProfile } = useSelector(state => ({
    refprojectProfile: state.refprojects.refprojectProfile,
  }))

  const [formData, setFormData] = React.useState([])
  const [quotation_lists, setQuotation_lists] = React.useState([])
  const [customhome, setCustomhome] = React.useState([])
  const [quoCon, setQuoCon] = React.useState([])
  const [quotation_cons, setQuotation_cons] = React.useState([])
  const [base64_logo_home_reg, setBase64_logo_home_reg] = React.useState(null)
  const [base64_logo_dbd, setBase64_logo_dbd_reg] = React.useState(null)

  const [fileVender, setFileVender] = React.useState([])
  const [quotation_notes, setQuotation_notes] = React.useState([])
  const [project_id, setProject_id] = React.useState(params.id)

  React.useEffect(async () => {
    // console.log("quotationprojectProfile", quotationprojectProfile)
    let ArrayFormDatas = []
    let ArrayQuotation_cons = []
    let ArrayQuotation_lists = []
    let ArrayQuotation_notes = []
    let ArrayQuotation_customhomes = []
    let ArrayQuotation_quoCon = []

    setBase64_logo_home_reg(await getBase64ImageFromURL(logo_home_reg))
    setBase64_logo_dbd_reg(await getBase64ImageFromURL(logo_dbd))

    if (!isEmpty(quotationprojectProfile)) {
      for (let index = 0; index < quotationprojectProfile.length; index++) {
        // if (index == 0) {
        ArrayFormDatas.push(quotationprojectProfile[index].formData)
        ArrayQuotation_cons.push(quotationprojectProfile[index].quotation_cons)
        ArrayQuotation_lists.push(
          quotationprojectProfile[index].quotation_lists
        )
        ArrayQuotation_notes.push(
          quotationprojectProfile[index].quotation_notes
        )
        ArrayQuotation_customhomes.push(
          quotationprojectProfile[index].customhome
        )
        ArrayQuotation_quoCon.push(quotationprojectProfile[index].con)

        // }
      }
      // console.log("ArrayQuotation_quoCon", ArrayQuotation_quoCon)
      setFormData(ArrayFormDatas)
      setQuotation_cons(ArrayQuotation_cons)
      setQuotation_lists(ArrayQuotation_lists)
      setQuotation_notes(ArrayQuotation_notes)
      setCustomhome(ArrayQuotation_customhomes)
      setQuoCon(ArrayQuotation_quoCon)
    } else {
      setFormData([])
      setQuotation_cons([])
      setQuotation_lists([])
      setQuotation_notes([])
      setCustomhome([])
      setQuoCon([])
    }
  }, [quotationprojectProfile])

  React.useEffect(async () => {
    // console.log("refprojectProfile", refprojectProfile)
    if (!isEmpty(refprojectProfile)) {
      if (refprojectProfile.mapVender.length > 0) {
        let img = refprojectProfile.mapVender
        let newArray = []
        if (!isEmpty(img)) {
          for (let index = 0; index < img.length; index++) {
            await toDataUrl(
              process.env.REACT_APP_SERVER + img[index].img_path,
              function (myBase64) {
                // console.log(myBase64) // myBase64 is the base64 string
                // setFileVender(myBase64)
                img[index].myBase64 = myBase64
              }
            )
            newArray.push(img[index])
            // console.log("newArray", newArray)
          }
        }
        setFileVender(newArray)
      }
    } else {
      setFileVender([])
    }
    // setFormData(infoprojectProfile)
  }, [refprojectProfile])

  React.useEffect(() => {
    dispatch(onGetQuotationProjectProfile(project_id, "contract"))
  }, [project_id])

  async function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest()
    xhr.onload = function () {
      var reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open("GET", url)
    xhr.responseType = "blob"
    xhr.send()
  }

  function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image()
      img.setAttribute("crossOrigin", "anonymous")

      img.onload = () => {
        var canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height

        var ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0)

        var dataURL = canvas.toDataURL("image/png")

        resolve(dataURL)
      }

      img.onerror = error => {
        reject(error)
      }

      img.src = url
    })
  }

  async function printInvoice() {
    // console.log(fileVender.length)
    if (fileVender.length > 0) {
      var docDefinition = {
        // watermark: {
        //   text: process.env.REACT_APP_NAMECOMPANY,
        //   color: "#e6e6e6",
        //   opacity: 0.5,
        // },
        content: (formData || []).map((v, k) => [
          {
            columns: [
              {
                style: "header",
                alignment: "left",
                width: "50%",
                text: "",
              },
              {
                alignment: "right",
                width: "50%",
                text: "1/4",
              },
            ],
          },
          {
            columns: [
              {
                alignment: "left",
                bold: true,
                width: "50%",
                text: "ติดต่อ:   Line @ : @pmh-m   Tel (รุจ) : 062 640 5432	",
              },
              {
                alignment: "right",
                bold: true,
                width: "50%",
                text:
                  "อ้างอิงใบเสนอราคา เลขที่ " +
                  v.code +
                  v.year +
                  v.month +
                  v.day +
                  v.term,
              },
            ],
          },
          {
            bold: true,
            text: "สัญญารับจ้างก่อสร้าง\nสัญญาฉบับนี้ ทำที่ Premium House",
            alignment: "center",
          },
          {
            style: "tableExample",
            table: {
              widths: ["100%"],
              body: [
                [
                  {
                    preserveLeadingSpaces: true,
                    text:
                      "\tสัญญาฉบับนี้ทำขึ้นระหว่าง คุณ" +
                      v.name +
                      " เลขบัตรประชาชน " +
                      v.id_card +
                      " ที่อยู่ " +
                      v.address +
                      " ซึ่งต่อไปในสัญญาฉบับนี้เรียกว่า “ผู้ว่าจ้าง” ฝ่ายหนึ่ง กับ บจก. พรีเมียม เฮ้าส์ โมดูลาร์ โดย นายรุจ อินทสุรัช อยู่บ้านเลขที่ 60/1 หมู่ที่ 1 ต.คลองโยง อ.พุทธมณฑล จ.นครปฐม 73170 ต่อไปในสัญญาฉบับนี้จะเรียกว่า “ผู้รับจ้าง” อีกฝ่ายหนึ่งทั้งสองฝ่ายตกลงทำสัญญากันโดยมีข้อความ ดังต่อไปนี้",
                  },
                ],
                [
                  {
                    text: "1. ผู้ว่าจ้างตกลงจ้างและผู้รับจ้างทำการก่อสร้างบ้านพักอาศัย ซึ่งมีรายละเอียดตามเอกสารที่แนบท้ายสัญญานี้และยึดตามใบเสนอราคาเป็นหลัก หากมีการเปลี่ยนแปลงต้องมีการแจ้งรู้เห็นกันทั้งสองฝ่าย",
                  },
                ],

                [
                  {
                    text:
                      "2. ผู้รับจ้างตกลงรับจ้างทำงานที่ว่าจ้างตามที่กำหนดในข้อ 1 โดยสัญญาว่าจะดำเนินงานตามสัญญาและจะเริ่มงานหลังจากผู้ว่าจ้างโอนเงินงวดแรก 7-15 วัน เพื่อจัดเตรียมอุปกรณ์และจัดทีมงานเพื่อเริ่มก่อสร้างโดยเวลาก่อสร้าง 45-" +
                      v.dateBuild +
                      " วันนับถัดจากวันที่เริ่มก่อสร้าง (หากวันเสาร์-อาทิตย์ไม่สามารถเข้าปฏิบัติงานได้ให้นับทบวันทำงานเพิ่มออกไป)",
                  },
                ],
                [
                  {
                    text: [
                      {
                        text:
                          "3. ผู้ว่าจ้างตกลงชำระเงินค่าจ้างงานก่อสร้างตามใบเสนอราคา " +
                          quotation_lists[k].map((j, i) => {
                            return j.detail.length > 0
                              ? j.name + " (" + j.detail + ") "
                              : j.name
                          }),
                      },
                      {
                        text: " เป็นจำนวนเงิน ",
                      },
                      {
                        text: [
                          {
                            text:
                              v.vat_type == 1
                                ? parseFloat(v.price_sum).toLocaleString(
                                    "th-TH",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  ) + " บาท "
                                : (
                                    parseFloat(v.price_sum) +
                                    (parseFloat(v.price_sum) / 100) * 7
                                  ).toLocaleString("th-TH", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }) + " บาท ",
                          },
                        ],
                      },
                      {
                        text:
                          v.vat_type == 2
                            ? "(ราคาดังกล่าวรวมภาษีมูลค่าเพิ่ม 7%) "
                            : " (ราคาดังกล่าว เป็นราคาสุทธิรวมค่าใช้จ่ายทั้งหมดแล้ว) ",
                      },
                      {
                        text: "ราคาดังกล่าวไม่รวมค่าแบบขอญาติก่อสร้าง,ติดตั้งแอร์,วอลล์เปเปอร์,มุ้งลวดและเฟอร์นิเจอร์ต่างๆ (หรือตามข้อตกลงกับผู้ว่าจ้าง สามารถดูได้ที่รายละเอียดเพิ่มเติม) โดยราคานี้เป็นราคารวมค่าวัสดุอุปกรณ์และค่าแรงตามรายการประมาณราคาที่ได้ให้ไว้แล้ว",
                      },
                    ],
                  },
                ],
                [
                  quotation_cons[k].map((j, i) => {
                    return {
                      margins: [5, 40, 0, 40],
                      columns: [
                        {
                          preserveLeadingSpaces: true,
                          width: 170,
                          bold: true,
                          text: "\tงวดที่ " + (i + 1) + "\t" + j.name,
                        },
                        {
                          width: 120,
                          bold: true,
                          text: "\tจ่าย " + j.value + "% \tยอด\t",
                        },
                        {
                          width: 80,
                          bold: true,
                          alignment: "right",
                          text:
                            v.vat_type == 1
                              ? quotation_cons[k].length - 1 == i
                                ? parseFloat(
                                    parseFloat(j.sum_all) -
                                      parseFloat(v.input_deposit_value)
                                  ).toLocaleString("th-TH", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }) + "\tบาท"
                                : parseFloat(j.sum_all).toLocaleString(
                                    "th-TH",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  ) + "\tบาท"
                              : quotation_cons[k].length - 1 == i
                              ? parseFloat(
                                  parseFloat(
                                    parseFloat(j.sum_all) +
                                      parseFloat((j.sum_all / 100) * 7)
                                  ) - v.input_deposit_value
                                ).toLocaleString("th-TH", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) + "\tบาท"
                              : parseFloat(
                                  parseFloat(j.sum_all) +
                                    parseFloat((j.sum_all / 100) * 7)
                                ).toLocaleString("th-TH", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) + "\tบาท",
                        },
                        {
                          width: "auto",
                          bold: true,
                          preserveLeadingSpaces: true,
                          text:
                            quotation_cons[k].length - 1 == i
                              ? "\t( หักค่ามัดจำ " +
                                parseInt(v.input_deposit_value).toLocaleString(
                                  "th-TH"
                                ) +
                                " บ. แล้ว )"
                              : "",
                        },
                      ],
                    }
                  }),
                ],
                [
                  quoCon[k].map((j, i) => [
                    {
                      text: Number(i + 3 + 1) + ". " + j.title + "\n",
                    },
                  ]),
                ],

                // [
                //   {
                //     text: "4. ถ้าผู้รับจ้างนำงานไปให้ผู้อื่นรับจ้างช่วงอีกทอดหนึ่งผู้รับจ้างยังคงต้องรับผิดชอบงานที่ให้ช่วงนั้นไปทุกประการหากในกรณีช่างรับงานช่วงต่อ ทำงานไม่เรียบร้อยหรือทำความเสียหายให้กับผู้ว่าจ้างผู้รับจ้างยินดีรับใช้ค่าเสียหายดังกล่าว แล้วเปลี่ยนผู้รับจ้างช่วงโดยใช้ระยะเวลาไม่เกิน 10 วัน",
                //   },
                // ],
                // [
                //   {
                //     text: "5. ผู้รับจ้างจะต้องควบคุมงานที่รับจ้างนี้ตลอดเวลาที่งานยังไม่แล้วเสร็จหรือจะมอบหมายให้ผู้แทนของผู้รับจ้างควบคุมงาน โดยจะต้องแจ้งผู้แทนผู้ได้รับอำนาจให้ผู้ว่าจ้างทราบเป็นหนังสือคำสั่งต่างๆที่ได้แจ้งแก่ผู้แทนผู้ได้รับมอบหมายนั้นให้ถือว่าได้แจ้งแก่ผู้รับจ้างแล้ว",
                //   },
                // ],
                // [
                //   {
                //     text: "6. ผู้รับจ้างจะต้องรับผิดชอบต่ออุบัติเหตุหรือความเสียหายใดๆที่เกิดขึ้นจากการทำงานของผู้รับจ้างเอง และจะต้องรับผิดชอบในความเสียหายอันเกิดแก่ทรัพย์สินของผู้ว่าจ้างและบุคคลอื่นโดยการกระทำของคนงาน ช่างหรือบริวารของผู้รับจ้างซึ่งผู้รับจ้างจะต้องรับผิดชอบซ่อมแซมให้คืนดีหรือเปลี่ยนให้ใหม่ด้วยค่าใช้จ่ายของผู้รับจ้างเอง",
                //   },
                // ],
                // [
                //   {
                //     text: "7. ในกรณีที่มีเหตุสุดวิสัยหรือเหตุอื่นใดๆ อันเนื่องจากความผิดจากการปรับแก้ไขงานให้ดีขึ้น,ปรับเปลี่ยนผู้รับจ้างช่วง หรือพฤติการณ์อันใดอันหนึ่งที่ผู้รับจ้าง ทำให้ผู้รับจ้างไม่สามารถทำงานให้แล้วเสร็จตามเงื่อนไขและกำหนดตามสัญญา ผู้รับจ้างต้องแจ้งเหตุหรือพฤติการณ์ดังกล่าวให้ผู้ว่าจ้างทราบ เพื่อขยายระยะเวลาการทำงานออกไป โดยวันที่เลื่อนออกไปต้องสมเหตุผล และยินยอมจากผู้ว่าจ้าง",
                //   },
                // ],
                // [
                //   {
                //     text: "8. ขอสงวนสิทธิ์ขั้นตอนการทำงานและวัสดุอุปกรณ์ต่างๆให้เป็นไปตามรูปแบบการทำงานของผู้รับจ้าง ยกเว้น ห้องน้ำ ลูกค้าสามารถเลือกวัสดุเองได้",
                //   },
                // ],
                // [
                //   {
                //     text: "9. หากมีการแก้ไขหรือเปลี่ยนแปลงแบบก่อสร้าง อันเกิดจากผู้ว่าจ้าง จะต้องจ้างในส่วนที่เพิ่มเติมหรือเปลี่ยนแปลง หรือให้เป็นไปตามข้อตกลงที่ยอมรับได้ทั้ง 2 ฝ่ายและต้องเพิ่มระยะเวลาการก่อสร้างออกไปอีก",
                //   },
                // ],
                // [
                //   {
                //     text: "10. รับประกันโครงสร้างเป็นระยะเวลา 5 ปีและการรั่วซึมเป็นระยะเวลา 1 ปี นับจากวันที่ติดตั้งแล้วเสร็จอันไม่ได้เกิดจากแรงการกระแทกหรือการกระทำใดๆ หรือไม่มีการต่อเติมดัดแปลงโครงสร้างใดๆ การกระทำที่เกิดจากการขนส่งเคลื่อนย้ายของผู้รับจ้างผู้รับจ้างจะต้องรับผิดชอบเอง",
                //   },
                // ],
                // [
                //   {
                //     text: "11. การขนส่งจะส่งตามสภาพภูมิอากาศทางผู้รับจ้างจะทำการขนส่งต่อเมื่อไม่มีฝนตกหรือเหตุการใดๆที่ทำให้ไม่สามารถขนส่งได้ ผู้รับจ้างจะต้องแจ้งผู้ว่าจ้างต่อเมื่อไม่สามารถขนส่งได้ตามกำหนดหากการขนส่งไม่สามารถเข้าถึงสถานที่หน้างานได้โดยผิดไปจากการสำรวจทาง ผู้ว่าจ้างจะต้องรับผิดชอบค่าใช้จ่ายในส่วนของรถเครนหรือค่ายกย้ายเข้าสถานที่ตามค่าใช้จ่ายที่เกิดขึ้นจริง",
                //   },
                // ],
                // [
                //   {
                //     text: "12. การติดตั้งบ้าน “ผู้ว่าจ้าง” จะต้องดำเนินการจัดหาไฟฟ้าและน้ำประปาเพื่อใช้ในการติดตั้งบ้านโดยไม่มีค่าใช้จ่าย รวมถึงในกรณีก่อสร้างหน้างานด้วย",
                //   },
                // ],
                // [
                //   {
                //     text: "13. ถ้าคู่สัญญาฝ่ายใดฝ่ายหนึ่งผิดสัญญาไม่ว่าข้อใดข้อหนึ่งให้อีกฝ่ายหนึงมีสิทธิ์บอกเลิกสัญญาได้และมีสิทธิ์เรียกร้องค่าเสียหายที่เกิดขึ้นตามความเป็นจริง",
                //   },
                // ],
                [
                  {
                    alignment: "center",
                    bold: true,
                    fontSize: 10,
                    text: "สัญญาฉบับนี้ทำขึ้นเป็นสองฉบับมีความถูกต้องตรงกันทั้งสองฝ่ายได้อ่านและเข้าใจข้อความในสัญญานี้ โดยตลอดดีแล้วจึงได้ลงลายมือชื่อพร้อมประทับตราสำคัญ (ถ้ามี)ไว้เป็นหลักฐานต่อหน้าพยานและต่างเก็บไว้ฝ่ายละหนึ่งฉบับ",
                  },
                ],
              ],
            },
            layout: "noBorders",
          },
          { text: "", pageBreak: "before" },
          {
            columns: [
              {
                style: "header",
                alignment: "left",
                width: "50%",
                text: "",
              },
              {
                alignment: "right",
                width: "50%",
                text: "2/4",
              },
            ],
          },
          quotation_notes[k].length > 0
            ? {
                text: "รายละเอียด (เพิ่มเติม)",
                bold: true,
              }
            : {
                text: "",
              },
          quotation_notes[k].length > 0
            ? {
                style: "tableExample",
                table: {
                  dontBreakRows: true,
                  body: quotation_notes[k].map((j, i) => {
                    return [
                      {
                        preserveLeadingSpaces: true,
                        text: "\t" + (i + 1) + ". " + j.name,
                      },
                    ]
                  }),
                },
                layout: "noBorders",
              }
            : {},

          customhome[k].map((j, i) => {
            return {
              text: [
                {
                  text: i + 1 + ". " + j.title,
                },
                {
                  text: j.firstData.map((c, v) => {
                    return "\n\t-\t" + c.title
                  }),
                },
                {
                  text: j.data.map((c, v) => {
                    return c.detail.length <= 1
                      ? ""
                      : c.type == 11
                      ? "\n\t-\tอลุมิเนียมหนา 1.2 mm ตามแบบสถาปัตย์ " + c.title
                      : c.type == 12
                      ? "\n\t-\tหลังคาเมทัลชีทความหนา 0.35 มิลลิเมตร สี" +
                        c.detail +
                        " ตามแบบสถาปัตย์"
                      : c.type == 13
                      ? "\n\t-\tแผ่นพื้นไฟเบอร์ซีเมนต์ หนา 15 มิลลิเมตร พื้นปูทับด้วยกระเบื้องยาง PVC หนา 2 มิลลิเมตร " +
                        c.detail
                      : c.type == 21
                      ? "\n\t-\tกระเบื้องพื้นสี " +
                        c.detail +
                        " (หรือลายอื่นเทียบเท่า)  "
                      : c.type == 22
                      ? "\n\t-\tกระเบื้องพนังสี " +
                        c.detail +
                        " (หรือรุ่นอื่นเทียบเท่า) ความสูง 2.4 เมตร จากพื้น "
                      : "\n\t-\t" + c.title + " " + c.detail
                  }),
                },
              ],
            }
          }),
          {
            text: "** ไม่ว่ากรณีใด ๆ ก็ตาม ผู้รับจ้างมีสิทธิ์ถ่ายรูปตัวบ้าน เพื่อใช้เป็นหลักฐานส่งมอบบ้าน",
          },
          {
            margin: [0, 20, 0, 0],
            alignment: "center",
            style: "header",

            columns: [
              {
                width: "50%",
                text:
                  "( ............................................ )\n" +
                  formData[0].sign_ower +
                  " (ผู้ว่าจ้าง)",
              },
              {
                width: "50%",
                text:
                  "( ............................................ )\n" +
                  "บจก. พรีเมียม เฮ้าส์ โมดูลาร์ โดย\n" +
                  formData[0].sign_manager +
                  " (ผู้รับจ้าง)",
              },
            ],
          },
          { text: "", pageBreak: "before" },
          {
            columns: [
              {
                style: "header",
                alignment: "left",
                width: "50%",
                text: "",
              },
              {
                alignment: "right",
                width: "50%",
                text: "3/4",
              },
            ],
          },
          {
            image: base64_logo_home_reg,
            fit: [350, 350],
            alignment: "center",
            margin: [0, 0, 0, 20],
          },

          {
            image: base64_logo_dbd,
            fit: [350, 350],
            alignment: "center",
          },
          { text: "", pageBreak: "before" },
          {
            columns: [
              {
                style: "header",
                alignment: "left",
                width: "50%",
                text: "",
              },
              {
                alignment: "right",
                width: "50%",
                text: "4/4",
              },
            ],
          },

          fileVender.map((j, i) => {
            if (isEmpty(j.myBase64) == true) {
            } else {
              return {
                image: j.myBase64,
                fit: [350, 350],
                alignment: "center",
                margin: [0, 0, 0, 20],
                pageBreak: "after",
              }
            }
          }),
        ]),
        styles: {
          header: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 20],
          },
          subheader: {
            fontSize: 13,
            decoration: "underline",
            decorationStyle: "solid",
            decorationColor: "black",
          },
          tableExample: {
            fontSize: 12.5,
          },
          tableHeader: {
            bold: true,
            fontSize: 14,
            color: "black",
          },
        },
        defaultStyle: {
          font: "THSarabunNew",
        },
        pageSize: "A4",
      }
      // pdfMake.createPdf(docDefinition).open()
      let quo_name =
        "สัญญารับจ้างก่อสร้างลูกค้า คุณ" + formData[0].sign_ower + ".pdf"
      //// console.log(quo_name)
      pdfMake.createPdf(docDefinition).download(quo_name)
    }
  }

  return (
    <React.Fragment>
      <style scoped>
        {
          "\
          .table-hide-border{\
            border-style: hidden;\
            border-right-style: hidden;\
          }\
          .table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {\
            border: 1px solid black;\
          }\
         .table > :not(:first-child){\
          border: 1px solid black;\
         }\
          .hidden {\
            overflow: hidden;\
          }\
          .td-note {\
            display: inline-block;\
            margin-bottom:1px;\
            padding-bottom:1px;\
          }\
          .hidden td {\
            display: inline-block;\
            border-style: hidden\
          }\
          .noBorderBottom{\
            border-bottom: 1px solid white;\
          }\
          @media print {\
            .pagebreak { page-break-before: always; }\
          }\
          "
        }
      </style>

      <div className="page-content">
        <MetaTags>
          <title>Check Detail | {process.env.REACT_APP_NAMECOMPANY}</title>
        </MetaTags>

        <Container fluid style={{ fontFamily: "sans-serif" }}>
          {!isEmpty(formData) && (
            <>
              {/* header */}
              {fileVender.length > 0 ? (
                <div className="d-print-none">
                  <div style={{ textAlignLast: "right" }}>
                    <Link
                      to="#"
                      onClick={() => printInvoice()}
                      className="btn btn-success me-2"
                    >
                      <i className="fa fa-print" />
                    </Link>

                    <Link
                      to={`/projects-overview/${params.id}`}
                      className="btn btn-secondary"
                    >
                      Back
                    </Link>
                  </div>
                  <hr />
                </div>
              ) : null}
              {(formData || []).map((v, k) => (
                <div key={k}>
                  <Card className="pagebreak" style={{ boxShadow: "none" }}>
                    <CardBody>
                      {/* page 1 */}
                      <Table responsive>
                        <thead>
                          <tr style={{ border: "1px solid black;" }}>
                            <th
                              colSpan={2}
                              className="table-hide-border"
                              style={{
                                textAlign: "right",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              1/4
                            </th>
                          </tr>

                          <tr style={{ border: "1px solid black;" }}>
                            <th
                              className="table-hide-border"
                              style={{
                                paddingTop: "0px",
                              }}
                            >
                              ติดต่อ: &nbsp; Line @ : @pmh-m &nbsp; Tel (รุจ) :
                              062 640 5432
                            </th>
                            <th
                              className="table-hide-border"
                              style={{
                                textAlign: "right",
                                paddingTop: "0px",
                              }}
                            >
                              อ้างอิงใบเสนอราคา เล่มที่: &nbsp;
                              {v.code + v.year + v.month + v.day + v.term}
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            border: "1px",
                            fontSize: "13px",
                          }}
                        >
                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                textAlign: "center",
                                paddingTop: "0px",
                                paddingBottom: "2px",
                              }}
                            >
                              <p
                                // onClick={e => {
                                //   dispatch(
                                //     onGetQuotationProjectProfile(
                                //       project_id,
                                //       "contract"
                                //     )
                                //   )
                                // }}
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                }}
                              >
                                สัญญารับจ้างก่อสร้าง
                              </p>
                            </th>
                          </tr>
                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                textAlign: "center",
                                paddingTop: "0px",
                                paddingBottom: "2px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                }}
                              >
                                สัญญาฉบับนี้ ทำที่ Premium House
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "3px",
                                paddingBottom: "3px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;&emsp;สัญญาฉบับนี้ทำขึ้นระหว่าง &nbsp;
                                {v.name}&nbsp;เลขบัตรประชาชน&nbsp;
                                {v.id_card}
                                &nbsp;ที่อยู่ {v.address}
                                &nbsp;ซึ่งต่อไปในสัญญาฉบับนี้เรียกว่า
                                “ผู้ว่าจ้าง” ฝ่ายหนึ่ง กับ บจก. พรีเมียม เฮ้าส์
                                โมดูลาร์ โดย นายรุจ อินทสุรัช อยู่บ้านเลขที่
                                60/1 หมู่ที่ 1 ต.คลองโยง อ.พุทธมณฑล จ.นครปฐม
                                73170 ต่อไปในสัญญาฉบับนี้จะเรียกว่า “ผู้รับจ้าง”
                                อีกฝ่ายหนึ่ง
                                ทั้งสองฝ่ายตกลงทำสัญญากันโดยมีข้อความ
                                &emsp;ดังต่อไปนี้
                              </p>
                            </th>
                          </tr>
                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;1.
                                ผู้ว่าจ้างตกลงจ้างและผู้รับจ้างทำการก่อสร้างบ้านพักอาศัยซึ่งมีรายละเอียด
                                ตามเอกสารที่แนบท้ายสัญญานี้และยึดตาม
                                ใบเสนอราคาเป็นหลัก
                                หากมีการเปลี่ยนแปลงต้องมีการแจ้งรู้เห็นกันทั้งสองฝ่าย
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;2.
                                ผู้รับจ้างตกลงรับจ้างทำงานที่ว่าจ้างตามที่กำหนดในข้อ
                                1
                                โดยสัญญาว่าจะดำเนินงานตามสัญญาและจะเริ่มงานหลังจากผู้ว่าจ้างโอนเงินงวดแรก
                                7-15 วัน
                                เพื่อจัดเตรียมอุปกรณ์และจัดทีมงานเพื่อเริ่มก่อสร้าง
                                โดยเวลาก่อสร้าง 45-{v.dateBuild} วัน
                                นับถัดจากวันที่เริ่มก่อสร้าง
                                (หากวันเสาร์-อาทิตย์ ไม่สามารถเข้าปฏิบัติงานได้
                                ให้นับทบวันทำงานเพิ่มออกไป)
                              </p>
                            </th>
                          </tr>
                          {!isEmpty(quotation_lists[k]) && (
                            <tr className="noBorderBottom">
                              <th
                                colSpan={2}
                                style={{
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                <p
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  &emsp;3.
                                  ผู้ว่าจ้างตกลงชำระเงินค่าจ้างงานก่อสร้างตามใบเสนอราคา&nbsp;
                                  {quotation_lists[k].map((j, i) => (
                                    <span key={i}>
                                      {v.name}&nbsp;
                                      {isEmpty(j.detail) == true
                                        ? null
                                        : "(" + j.detail + ")"}
                                      &nbsp;
                                    </span>
                                  ))}
                                  เป็นจำนวนเงิน&emsp;
                                  {v.vat_type == 1
                                    ? parseFloat(v.price_sum).toLocaleString(
                                        "th-TH",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )
                                    : (
                                        parseFloat(v.price_sum) +
                                        (parseFloat(v.price_sum) / 100) * 7
                                      ).toLocaleString("th-TH", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  &nbsp;บาท&emsp;
                                  {v.vat_type == 2
                                    ? " (ราคาดังกล่าวรวมภาษีมูลค่าเพิ่ม 7%) "
                                    : " (ราคาดังกล่าว เป็นราคาสุทธิรวมค่าใช้จ่ายทั้งหมดแล้ว) "}
                                  &emsp;
                                  ราคาดังกล่าวไม่รวมค่าแบบขอญาติก่อสร้าง,ติดตั้งแอร์,วอลล์เปเปอร์,มุ้งลวดและเฟอร์นิเจอร์ต่างๆ
                                  โดยราคานี้เป็นราคารวมค่าวัสดุอุปกรณ์และค่าแรงตามรายการประมาณราคาที่ได้ให้ไว้แล้ว
                                </p>
                              </th>
                            </tr>
                          )}
                          {!isEmpty(quotation_cons[k]) && (
                            <tr className="noBorderBottom">
                              <th
                                colSpan={2}
                                style={{
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  textAlign: "left",
                                }}
                              >
                                <Table className="table-nowrap" responsive>
                                  <thead>
                                    {quotation_cons[k].map((j, i) => (
                                      <tr key={i}>
                                        <th
                                          className="table-hide-border"
                                          style={{
                                            width: "10%",
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginLeft: "50px",
                                            }}
                                          >
                                            <span>งวดที่ {i + 1}</span>&emsp;
                                            <span>- {j.name}</span>&emsp;
                                            <span>{j.value}%</span>&emsp;=&emsp;
                                          </span>
                                        </th>
                                        <th
                                          className="table-hide-border"
                                          style={{
                                            paddingTop: "1px",
                                            paddingBottom: "1px",
                                            width: "5%",
                                            textAlign: "right",
                                          }}
                                        >
                                          <span>
                                            {v.vat_type == 1
                                              ? quotation_cons[k].length - 1 ==
                                                i
                                                ? parseFloat(
                                                    j.sum_all -
                                                      v.input_deposit_value
                                                  ).toLocaleString("th-TH", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  })
                                                : parseFloat(
                                                    j.sum_all
                                                  ).toLocaleString("th-TH", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  })
                                              : quotation_cons[k].length - 1 ==
                                                i
                                              ? parseFloat(
                                                  parseFloat(j.sum_all) +
                                                    parseFloat(
                                                      (j.sum_all / 100) * 7
                                                    ) -
                                                    v.input_deposit_value
                                                ).toLocaleString("th-TH", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })
                                              : (
                                                  parseFloat(j.sum_all) +
                                                  parseFloat(
                                                    (j.sum_all / 100) * 7
                                                  )
                                                ).toLocaleString("th-TH", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                            &emsp;
                                          </span>
                                        </th>

                                        <th
                                          className="table-hide-border"
                                          style={{
                                            paddingTop: "1px",
                                            paddingBottom: "1px",
                                          }}
                                        >
                                          <span>บาท</span>
                                          {quotation_cons[k].length - 1 == i
                                            ? "*** หักค่ามัดจำ " +
                                              parseInt(
                                                v.input_deposit_value
                                              ).toLocaleString("th-TH") +
                                              " บ. แล้ว *** "
                                            : null}
                                        </th>
                                      </tr>
                                    ))}
                                  </thead>
                                </Table>
                              </th>
                            </tr>
                          )}
                          {quoCon.length > 0 &&
                            quoCon[0].map((f, k) => (
                              <tr className="noBorderBottom" key={k}>
                                <th
                                  colSpan={2}
                                  style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    &emsp;{k + 3 + 1}. {f.title}
                                  </p>
                                </th>
                              </tr>
                            ))}

                          {/* <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;4.ถ้าผู้รับจ้างนำงานไปให้ผู้อื่นรับจ้างช่วงอีกทอดหนึ่ง
                                ผู้รับจ้างยังคงต้องรับผิดชอบงานที่ให้ช่วงนั้นไปทุกประการ
                                หากในกรณีช่างรับงานช่วงต่อทำงานไม่เรียบร้อยหรือทำความเสียหายให้กับผู้ว่าจ้าง
                                ผู้รับจ้างยินดีรับใช้ค่าเสียหายดังกล่าวแล้วเปลี่ยนผู้รับจ้างช่วงโดยใช้ระยะเวลาไม่เกิน
                                10 วัน
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;5.
                                ผู้รับจ้างจะต้องควบคุมงานที่รับจ้างนี้ตลอดเวลาที่งานยังไม่แล้วเสร็จหรือจะมอบหมายให้ผู้แทนของผู้รับจ้างควบคุมงาน
                                โดยจะต้องแจ้งผู้แทนผู้ได้รับอำนาจให้ผู้ว่าจ้างทราบเป็นหนังสือ
                                คำสั่งต่างๆที่ได้แจ้งแก่ผู้แทนผู้ได้รับมอบหมายนั้น
                                ให้ถือว่าได้แจ้งแก่ผู้รับจ้างแล้ว
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;6.
                                ผู้รับจ้างจะต้องรับผิดชอบต่ออุบัติเหตุหรือความเสียหายใดๆที่เกิดขึ้นจากการทำงานของผู้รับจ้างเองและจะต้องรับผิดชอบในความเสียหายอันเกิดแก่ทรัพย์สินของผู้ว่าจ้างและบุคคลอื่น
                                โดยการกระทำของคนงาน ช่างหรือบริวารของผู้รับจ้าง
                                ซึ่งผู้รับจ้างจะต้องรับผิดชอบซ่อมแซมให้คืนดีหรือเปลี่ยนให้ใหม่ด้วยค่าใช้จ่ายของผู้รับจ้างเอง
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;7. ในกรณีที่มีเหตุสุดวิสัยหรือเหตุอื่นใดๆ
                                อันเนื่องจากความผิดจากการปรับแก้ไขงานให้ดีขึ้น,ปรับเปลี่ยนผู้รับจ้างช่วง
                                หรือพฤติการณ์อันใดอันหนึ่งที่ผู้รับจ้าง
                                ทำให้ผู้รับจ้างไม่สามารถทำงานให้แล้วเสร็จตามเงื่อนไขและกำหนดตามสัญญา
                                ผู้รับจ้างต้องแจ้งเหตุหรือพฤติการณ์ดังกล่าวให้ผู้ว่าจ้างทราบ
                                เพื่อขยายระยะเวลาการทำงานออกไป
                                โดยวันที่เลื่อนออกไปต้องสมเหตุผล
                                และยินยอมจากผู้ว่าจ้าง
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;8.
                                ขอสงวนสิทธิ์ขั้นตอนการทำงานและวัสดุอุปกรณ์ต่างๆ
                                ให้เป็นไปตามรูปแบบการทำงานของผู้รับจ้าง
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;9. หากมีการแก้ไขหรือเปลี่ยนแปลงแบบก่อสร้าง
                                อันเกิดจากผู้ว่าจ้าง
                                จะต้องจ้างในส่วนที่เพิ่มเติมหรือเปลี่ยนแปลง
                                หรือให้เป็นไปตามข้อตกลงที่ยอมรับได้ทั้ง 2
                                ฝ่ายและต้องเพิ่มระยะเวลาการก่อสร้างออกไปอีก
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;10. รับประกันโครงสร้างเป็นระยะเวลา 5
                                ปีและการรั่วซึมเป็นระยะเวลา 1 ปี
                                นับจากวันที่ติดตั้งแล้วเสร็จ
                                อันไม่ได้เกิดจากแรงการกระแทกหรือการกระทำใดๆหรือไม่มีการต่อเติมดัดแปลงโครงสร้างใดๆ
                                การกระทำที่เกิดจากการขนส่งเคลื่อนย้ายของผู้รับจ้างผู้รับจ้างจะต้องรับผิดชอบเอง
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;11.การขนส่งจะส่งตามสภาพภูมิอากาศ
                                ทางผู้รับจ้างจะทำการขนส่งต่อเมื่อไม่มีฝนตกหรือเหตุการใดๆที่ทำให้ไม่สามารถขนส่งได้
                                ผู้รับจ้างจะต้องแจ้งผู้ว่าจ้างต่อเมื่อไม่สามารถขนส่งได้ตามกำหนด
                                หากการขนส่งไม่สามารถเข้าถึงสถานที่หน้างานได้โดยผิดไปจากการสำรวจ
                                ทางผู้ว่าจ้างจะต้องรับผิดชอบค่าใช้จ่ายในส่วนของรถเครนหรือค่ายกย้ายเข้าสถานที่ตามค่าใช้จ่ายที่เกิดขึ้นจริง
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;12. การติดตั้งบ้าน “ผู้ว่าจ้าง”
                                จะต้องดำเนินการจัดหาไฟฟ้าและน้ำประปาเพื่อใช้ในการติดตั้งบ้านโดยไม่มีค่าใช้จ่าย
                                รวมถึงในกรณีก่อสร้างหน้างานด้วย
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontWeight: "normal",
                                }}
                              >
                                &emsp;13.
                                ถ้าคู่สัญญาฝ่ายใดฝ่ายหนึ่งผิดสัญญาไม่ว่าข้อใดข้อหนึ่ง
                                ให้อีกฝ่ายหนึงมีสิทธิ์บอกเลิกสัญญาได้และมีสิทธิ์เรียกร้องค่าเสียหายที่เกิดขึ้นตามความเป็นจริง
                              </p>
                            </th>
                          </tr>

                          <tr className="noBorderBottom">
                            <th
                              colSpan={2}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                textAlign: "center",
                              }}
                            >
                              <p
                                style={{
                                  marginTop: "2px",
                                  marginBottom: "0px",
                                }}
                              >
                                &emsp;
                                สัญญาฉบับนี้ทำขึ้นเป็นสองฉบับมีความถูกต้องตรงกัน
                                ทั้งสองฝ่ายได้อ่านและเข้าใจข้อความในสัญญานี้โดยตลอดดีแล้ว
                                จึงได้ลงลายมือชื่อพร้อมประทับตราสำคัญ(ถ้ามี)
                                ไว้เป็นหลักฐานต่อหน้า
                                พยานและต่างเก็บไว้ฝ่ายละหนึ่งฉบับ
                              </p>
                            </th>
                          </tr> */}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>

                  <Card className="pagebreak" style={{ boxShadow: "none" }}>
                    <CardBody>
                      <Table responsive>
                        <thead>
                          <tr style={{ border: "1px solid black;" }}>
                            <th
                              colSpan={2}
                              className="table-hide-border"
                              style={{
                                textAlign: "right",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              2/4
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr className="table-hide-border noBorderBottom">
                            <p
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                marginTop: "0px",
                                marginBottom: "0px",
                              }}
                            >
                              รายละเอียดเพิ่มเติม
                            </p>
                          </tr>
                          {!isEmpty(quotation_notes[k]) &&
                            quotation_notes[k].map((j, i) => (
                              <tr
                                key={i}
                                className="table-hide-border noBorderBottom"
                              >
                                <p
                                  style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  - {j.name}
                                </p>
                              </tr>
                            ))}
                          {!isEmpty(customhome[k]) &&
                            customhome[k].map((j, i) => (
                              <tr
                                className="table-hide-border noBorderBottom"
                                key={i}
                              >
                                {i + 1}.&nbsp;{j.title}
                                {j.firstData.map((c, v) => (
                                  <p
                                    key={v}
                                    style={{
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    ⁃ {c.title}&nbsp;
                                  </p>
                                ))}
                                {j.data.map((c, v) =>
                                  c.detail.length <= 1 ? null : (
                                    <p
                                      key={v}
                                      style={{
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                        marginTop: "0px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      -{" "}
                                      {c.type == 11
                                        ? "อลุมิเนียมหนา 1.2 mm ตามแบบสถาปัตย์ " +
                                          c.title
                                        : c.type == 12
                                        ? "หลังคาเมทัลชีท ความหนา 0.35 มิลลิเมตร สี" +
                                          c.detail +
                                          " ตามแบบสถาปัตย์"
                                        : c.type == 13
                                        ? "แผ่นพื้นไฟเบอร์ซีเมนต์ หนา 15 มิลลิเมตร พื้นปูทับด้วยกระเบื้องยาง PVC หนา 2 มิลลิเมตร " +
                                          c.detail
                                        : c.type == 21
                                        ? "กระเบื้องพื้นสี " +
                                          c.detail +
                                          " (หรือลายอื่นเทียบเท่า)  "
                                        : c.type == 22
                                        ? "กระเบื้องพนังสี " +
                                          c.detail +
                                          " (หรือรุ่นอื่นเทียบเท่า) ความสูง 2.4 เมตร จากพื้น "
                                        : c.title + " " + c.detail}
                                    </p>
                                  )
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <br />
                      <span>
                        ** ไม่ว่ากรณีใด ๆ ก็ตาม ผู้รับจ้างมีสิทธิ์ถ่ายรูปตัวบ้าน
                        เพื่อใช้เป็นหลักฐานส่งมอบบ้าน
                      </span>
                      <br />

                      <Table className="table-nowarp text-center">
                        <thead>
                          <tr className="table-hide-border">
                            <th>
                              <p
                                style={{
                                  fontWeight: "nomal",
                                }}
                              >
                                ( ............................................ )
                              </p>
                              <p
                                style={{
                                  fontWeight: "nomal",
                                }}
                              >
                                {formData[0].sign_ower} &nbsp;(ผู้ว่างจ้าง)
                              </p>
                            </th>
                            <th>
                              <p
                                style={{
                                  fontWeight: "nomal",
                                }}
                              >
                                ( ............................................ )
                              </p>
                              <p
                                style={{
                                  fontWeight: "nomal",
                                }}
                              >
                                บจก. พรีเมียม เฮ้าส์ โมดูลาร์ โดย
                                <br />
                                {formData[0].sign_manager}&nbsp;(ผู้รับจ้าง)
                              </p>
                            </th>
                          </tr>
                        </thead>
                      </Table>
                    </CardBody>
                  </Card>
                </div>
              ))}
              <hr />
              {fileVender.length > 0 ? (
                <div className="d-print-none">
                  <div style={{ textAlignLast: "right" }}>
                    <Link
                      to="#"
                      onClick={() => printInvoice()}
                      className="btn btn-success me-2"
                    >
                      <i className="fa fa-print" />
                    </Link>

                    <Link
                      to={`/projects-overview/${params.id}`}
                      className="btn btn-secondary"
                    >
                      Back
                    </Link>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoiceCheck.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceCheck)
