import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { isEmpty } from "lodash"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [report, setReport] = useState(false)

  const [obj, setObj] = useState({})

  const [stock, setstock] = useState(false)
  const [money, setmoney] = useState(false)

  const [construction, setconstruction] = useState(false)
  const [setting, setsetting] = useState(false)

  React.useMemo

  useMemo(() => {
    setObj(JSON.parse(localStorage.getItem("authUser")))
  }, [])

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      {!isEmpty(obj) && (
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={props.leftMenu}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/dashboard"
                    >
                      <i className="bx bx-home-circle me-2"></i>
                      {props.t("หน้าหลัก")}
                    </Link>
                  </li>
                  {obj.group.permission == 1 || obj.group.permission == 6 ? (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        to="/audit"
                      >
                        <i className="bx bx-money me-2"></i>
                        {props.t("การเงิน")}
                      </Link>
                    </li>
                  ) : null}

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setReport(!report)
                      }}
                      to="/reports"
                    >
                      <i className="bx bx bxs-report me-2"></i>
                      {props.t("รายงาน")} {props.menuOpen}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: report,
                      })}
                    >
                      <Link to="/report-attend" className="dropdown-item">
                        {props.t("เวลาเข้างาน")}
                      </Link>

                      <Link to="/report-paymaterial" className="dropdown-item">
                        {props.t("การเบิกวัสดุ")}
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setdashboard(!dashboard)
                      }}
                      to="/dashboard"
                    >
                      <i className="bx bx bxs-dashboard me-2"></i>
                      {props.t("รวมโปรเจค")} {props.menuOpen}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: dashboard,
                      })}
                    >
                      {/* <Link to="/dashboard" className="dropdown-item">
                      {props.t("Default")}
                    </Link> */}

                      <Link to="/projects-grid" className="dropdown-item">
                        {props.t("ทั้งหมด")}
                      </Link>

                      <Link to="/projects-list" className="dropdown-item">
                        {props.t("ที่รอการยืนยันสถานะ")}
                      </Link>

                      <Link to="/home-products" className="dropdown-item">
                        {props.t("ที่แสดงหน้าเว็บ")}
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setstock(!stock)
                      }}
                      to="/dashboard"
                    >
                      <i className="bx bx-store me-2"></i>
                      {props.t("คลังวัสดุ")} {props.menuOpen}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", { show: stock })}
                    >
                      <Link
                        to="/table-Material-Types"
                        className="dropdown-item"
                      >
                        {props.t("ประเภทวัสดุ")}
                      </Link>
                      <Link
                        to="/table-Material-Stocks"
                        className="dropdown-item"
                      >
                        {props.t("วัสดุ")}
                      </Link>
                      <Link
                        to="/table-Material-Shops"
                        className="dropdown-item"
                      >
                        {props.t("ร้านค้าวัสดุ")}
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setmoney(!money)
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-dollar-circle me-2"></i>
                      {props.t("สินค้า")} <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", { show: money })}
                    >
                      <Link
                        to="/table-Money-Product-Groups"
                        className="dropdown-item"
                      >
                        {props.t("กลุ่มสินค้า")}
                      </Link>

                      <Link
                        to="/table-Money-Products"
                        className="dropdown-item"
                      >
                        {props.t("สินค้า")}
                      </Link>

                      <Link to="/table-Money-Banks" className="dropdown-item">
                        {props.t("ธนาคาร")}
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setconstruction(!construction)
                      }}
                    >
                      <i className="bx bx-collection me-2"></i>
                      {props.t("การก่อสร้าง")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: construction,
                      })}
                    >
                      <Link to="/table-aluminia" className="dropdown-item">
                        {props.t("กลุ่ม อลูมิเนียม")}
                      </Link>

                      <Link to="/table-design-groups" className="dropdown-item">
                        {props.t("กลุ่ม ออกแบบ")}
                      </Link>

                      <Link to="/table-qcs" className="dropdown-item">
                        {props.t("กลุ่ม ตรวจสอบ")}
                      </Link>

                      <Link
                        to="/table-custom-projects"
                        className="dropdown-item"
                      >
                        {props.t("กลุ่ม ปรับแต่ง")}
                      </Link>

                      <Link
                        to="/table-list-structure"
                        className="dropdown-item"
                      >
                        {props.t("กลุ่ม Boq")}
                      </Link>

                      <Link to="/table-contractors" className="dropdown-item">
                        {props.t("กลุ่ม ช่าง")}
                      </Link>
                    </div>
                  </li>

                  {obj.group.permission == 1 ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setsetting(!setting)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-cog me-2"></i>
                        {props.t("ตั้งค่า")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: setting,
                        })}
                      >
                        <Link to="/table-messages" className="dropdown-item">
                          {props.t("ข้อความเริ่มต้น")}
                        </Link>
                        <Link to="/table-users" className="dropdown-item">
                          {props.t("ผู้ใช้งาน")}
                        </Link>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
