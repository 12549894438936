import {
  GET_ATTENDS_PROFILE,
  GET_ATTENDS_PROFILE_FAIL,
  GET_ATTENDS_PROFILE_SUCCESS,
  GET_ATTENDSS,
  GET_ATTENDSS_FAIL,
  GET_ATTENDSS_SUCCESS,
  ADD_NEW_ATTENDS,
  ADD_ATTENDS_SUCCESS,
  ADD_ATTENDS_FAIL,
  UPDATE_ATTENDS,
  UPDATE_ATTENDS_SUCCESS,
  UPDATE_ATTENDS_FAIL,
  DELETE_ATTENDS,
  DELETE_ATTENDS_SUCCESS,
  DELETE_ATTENDS_FAIL,
} from "./actionTypes"

export const getAttends = active => ({
  type: GET_ATTENDSS,
  payload: active,
})

export const getAttendsSuccess = attends => ({
  type: GET_ATTENDSS_SUCCESS,
  payload: attends,
})

export const addNewAttend = attend => ({
  type: ADD_NEW_ATTENDS,
  payload: attend,
})

export const addAttendSuccess = attend => ({
  type: ADD_ATTENDS_SUCCESS,
  payload: attend,
})

export const addAttendFail = error => ({
  type: ADD_ATTENDS_FAIL,
  payload: error,
})

export const getAttendsFail = error => ({
  type: GET_ATTENDSS_FAIL,
  payload: error,
})

export const getAttendProfile = id => ({
  type: GET_ATTENDS_PROFILE,
  payload: id,
})

export const getAttendProfileSuccess = attendProfile => ({
  type: GET_ATTENDS_PROFILE_SUCCESS,
  payload: attendProfile,
})

export const getAttendProfileFail = error => ({
  type: GET_ATTENDS_PROFILE_FAIL,
  payload: error,
})

export const updateAttend = attend => ({
  type: UPDATE_ATTENDS,
  payload: attend,
})

// export const updateAttend = attend => console.log(attend);

export const updateAttendSuccess = attend => ({
  type: UPDATE_ATTENDS_SUCCESS,
  payload: attend,
})

export const updateAttendFail = error => ({
  type: UPDATE_ATTENDS_FAIL,
  payload: error,
})

export const deleteAttend = attend => ({
  type: DELETE_ATTENDS,
  payload: attend,
})

export const deleteAttendSuccess = attend => ({
  type: DELETE_ATTENDS_SUCCESS,
  payload: attend,
})

export const deleteAttendFail = error => ({
  type: DELETE_ATTENDS_FAIL,
  payload: error,
})
