import {
  GET_COMMENT_HOME_PROFILE,
  GET_COMMENT_HOME_PROFILE_FAIL,
  GET_COMMENT_HOME_PROFILE_SUCCESS,
  GET_COMMENT_HOMES,
  NOTI_COMMENT_HOMES,
  GET_COMMENT_HOMES_FAIL,
  GET_COMMENT_HOMES_SUCCESS,
  ADD_NEW_COMMENT_HOME,
  ADD_COMMENT_HOME_SUCCESS,
  ADD_COMMENT_HOME_FAIL,
  UPDATE_COMMENT_HOME,
  UPDATE_COMMENT_HOME_SUCCESS,
  UPDATE_COMMENT_HOME_FAIL,
  DELETE_COMMENT_HOME,
  DELETE_COMMENT_HOME_SUCCESS,
  DELETE_COMMENT_HOME_FAIL,
} from "./actionTypes"

export const getCommentHomes = id => ({
  type: GET_COMMENT_HOMES,
  payload: id,
})

export const notiLineCommentHome = commentHomes => ({
  type: NOTI_COMMENT_HOMES,
  payload: commentHomes,
})

export const getCommentHomesSuccess = commentHomes => ({
  type: GET_COMMENT_HOMES_SUCCESS,
  payload: commentHomes,
})

export const addNewCommentHome = commentHome => ({
  type: ADD_NEW_COMMENT_HOME,
  payload: commentHome,
})

export const addCommentHomeSuccess = commentHome => ({
  type: ADD_COMMENT_HOME_SUCCESS,
  payload: commentHome,
})

export const addCommentHomeFail = error => ({
  type: ADD_COMMENT_HOME_FAIL,
  payload: error,
})

export const getCommentHomesFail = error => ({
  type: GET_COMMENT_HOMES_FAIL,
  payload: error,
})

export const getCommentHomeProfile = id => ({
  type: GET_COMMENT_HOME_PROFILE,
  payload: id,
})

export const getCommentHomeProfileSuccess = commentHomeProfile => ({
  type: GET_COMMENT_HOME_PROFILE_SUCCESS,
  payload: commentHomeProfile,
})

export const getCommentHomeProfileFail = error => ({
  type: GET_COMMENT_HOME_PROFILE_FAIL,
  payload: error,
})

export const updateCommentHome = commentHome => ({
  type: UPDATE_COMMENT_HOME,
  payload: commentHome,
})

// export const updateCommentHome = commentHome => console.log(commentHome);

export const updateCommentHomeSuccess = commentHome => ({
  type: UPDATE_COMMENT_HOME_SUCCESS,
  payload: commentHome,
})

export const updateCommentHomeFail = error => ({
  type: UPDATE_COMMENT_HOME_FAIL,
  payload: error,
})

export const deleteCommentHome = commentHome => ({
  type: DELETE_COMMENT_HOME,
  payload: commentHome,
})

export const deleteCommentHomeSuccess = commentHome => ({
  type: DELETE_COMMENT_HOME_SUCCESS,
  payload: commentHome,
})

export const deleteCommentHomeFail = error => ({
  type: DELETE_COMMENT_HOME_FAIL,
  payload: error,
})
