import {
  GET_PRODUCT_GROUPS_SUCCESS,
  GET_PRODUCT_GROUPS_FAIL,
  ADD_PRODUCT_GROUP_SUCCESS,
  ADD_PRODUCT_GROUP_FAIL,
  UPDATE_PRODUCT_GROUP_SUCCESS,
  UPDATE_PRODUCT_GROUP_FAIL,
  DELETE_PRODUCT_GROUP_SUCCESS,
  DELETE_PRODUCT_GROUP_FAIL,
  GET_PRODUCT_GROUP_PROFILE_SUCCESS,
  GET_PRODUCT_GROUP_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  productGroups: [],
  productGroupProfile: {},
  error: {},
}

const productGroups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_GROUPS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        productGroups: action.payload,
      }

    case GET_PRODUCT_GROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        productGroups: [...state.productGroups, action.payload],
      }

    case ADD_PRODUCT_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_GROUP_PROFILE_SUCCESS:
      // console.log("GET_PRODUCT_GROUP_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        productGroupProfile: action.payload,
      }

    case UPDATE_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        productGroups: state.productGroups.map(designgroup =>
          designgroup.id.toString() === action.payload.id.toString()
            ? { designgroup, ...action.payload }
            : designgroup
        ),
      }

    case UPDATE_PRODUCT_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        productGroups: state.productGroups.filter(
          designgroup =>
            designgroup.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_PRODUCT_GROUP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_GROUP_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default productGroups
