import {
  GET_LIST_QCS_SUCCESS,
  GET_LIST_QCS_FAIL,
  ADD_LIST_QC_SUCCESS,
  ADD_LIST_QC_FAIL,
  UPDATE_LIST_QC_SUCCESS,
  UPDATE_LIST_QC_FAIL,
  DELETE_LIST_QC_SUCCESS,
  DELETE_LIST_QC_FAIL,
  GET_LIST_QC_PROFILE_SUCCESS,
  GET_LIST_QC_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  listQcs: [],
  listQcProfile: {},
  error: {},
}

const listQcs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_QCS_SUCCESS:
      // console.log(action)
      return {
        ...state,
        listQcs: action.payload,
      }

    case GET_LIST_QCS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_LIST_QC_SUCCESS:
      return {
        ...state,
        listQcs: [...state.listQcs, action.payload],
      }

    case ADD_LIST_QC_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_QC_PROFILE_SUCCESS:
      // console.log("GET_LIST_QC_PROFILE_SUCCESS =>", action.payload)
      return {
        ...state,
        listQcProfile: action.payload,
      }

    case UPDATE_LIST_QC_SUCCESS:
      return {
        ...state,
        listQcs: state.listQcs.map(qc =>
          qc.id.toString() === action.payload.id.toString()
            ? { qc, ...action.payload }
            : qc
        ),
      }

    case UPDATE_LIST_QC_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_LIST_QC_SUCCESS:
      return {
        ...state,
        listQcs: state.listQcs.filter(
          qc =>
            qc.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_LIST_QC_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LIST_QC_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default listQcs
