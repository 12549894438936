import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)
const postJwtLogout = () => post(url.POST_FAKE_JWT_LOGOUT)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = project_id => get(url.GET_EVENTS + "/" + project_id)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = id => get(url.DELETE_EVENT + "/delete/" + id)

// get Categories
// export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = data =>
  get(url.GET_PROJECTS + "/preview/" + data.type + "/" + data.name)

// get project details
export const getProjectProfile = id => get(url.GET_PROJECT_DETAIL + "/" + id)

// get info project
export const getInfoProjects = () => get(url.GET_INFOPROJECTS)
export const getInfoContract = id => get(url.GET_INFOCONTRACT + "/" + id)

export const uploadImgInfoProject = File =>
  post(url.UPLOAD_FILEHOMEINFOPROJECT, File)

export const getInfoProjectProfile = id =>
  get(url.GET_INFOPROJECT_PROFILE + "/check_add_info/" + id)
// add infoproject
export const addNewInfoProject = infoproject =>
  post(url.ADD_NEW_INFOPROJECT, infoproject)
// update infoproject
export const updateInfoProject = infoproject =>
  put(url.UPDATE_INFOPROJECT, infoproject)
// delete infoproject
export const deleteInfoProject = id => del(url.DELETE_INFOPROJECT + "/" + id)

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get users
export const getUsers = active => get(url.GET_USERS + "/get/" + active)
export const getUserProfile = id => get(url.GET_USER_PROFILE + "/" + id)
// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)
// update user
export const updateUser = user => put(url.UPDATE_USER, user)
// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

// get refproject
export const getRefprojects = id => get(url.GET_REFPROJECTS + "/" + id)
export const getRefprojectProfile = id =>
  get(url.GET_REFPROJECT_PROFILE + "/" + id)
// add refproject
export const addNewRefproject = refproject =>
  post(url.ADD_NEW_REFPROJECT, refproject)
// update refproject
export const updateRefproject = refproject =>
  put(url.UPDATE_REFPROJECT, refproject)
// delete refproject
export const deleteRefproject = refproject =>
  del(url.DELETE_REFPROJECT + "/" + refproject)
export const uploadImgRefInfoProject = File =>
  post(url.UPLOAD_INFOPROJECTFILEREF, File)

// get serwayproject
export const getSerwayprojects = id => get(url.GET_SERWAYPROJECTS + "/" + id)
export const getSerwayprojectProfile = id =>
  get(url.GET_SERWAYPROJECT_PROFILE + "/" + id)
// add serwayproject
export const addNewSerwayproject = serwayproject =>
  post(url.ADD_NEW_SERWAYPROJECT, serwayproject)
// update serwayproject
export const updateSerwayproject = serwayproject =>
  put(url.UPDATE_SERWAYPROJECT, serwayproject)
// delete serwayproject
export const deleteSerwayproject = serwayproject =>
  del(url.DELETE_SERWAYPROJECT + "/" + serwayproject)
export const uploadImgSerwayInfoProject = File =>
  post(url.UPLOAD_INFOPROJECTFILESERWAY, File)

// get slipproject
export const getSlipOnProject = id =>
  get(url.GET_SLIPPROJECT_PROFILE + "/get_on_slip/" + id)
export const getSlipprojects = data => post(url.GET_SLIPPROJECTS, data)
export const getSlipprojectProfile = id =>
  get(url.GET_SLIPPROJECT_PROFILE + "/" + id)
// add slipproject
export const addNewSlipproject = slipproject =>
  post(url.ADD_NEW_SLIPPROJECT, slipproject)
// update slipproject
export const updateSlipproject = slipproject =>
  put(url.UPDATE_SLIPPROJECT, slipproject)
// delete slipproject
export const deleteSlipproject = slipproject =>
  del(url.DELETE_SLIPPROJECT + "/" + slipproject)
export const uploadImgSlipInfoProject = File =>
  post(url.UPLOAD_INFOPROJECTFILESLIP, File)

// get quotationproject
export const getQuotationProjects = project_id =>
  get(url.GET_QUOTATIONPROJECTS + "/" + project_id)
export const getQuotationProjectProfile = data =>
  get(url.GET_QUOTATIONPROJECT_PROFILE + "/" + data.id + "/" + data.type)
// add quotationproject
export const addNewQuotationProject = quotationproject =>
  post(url.ADD_NEW_QUOTATIONPROJECT, quotationproject)
// update quotationproject
export const updateQuotationProject = quotationproject =>
  put(url.UPDATE_QUOTATIONPROJECT, quotationproject)
// delete quotationproject
export const deleteQuotationProject = quotationproject =>
  del(url.DELETE_QUOTATIONPROJECT + "/" + quotationproject)

// get quotationconproject
export const getQuotationConProjects = project_id =>
  get(url.GET_QUOTATIONCONPROJECTS + "/onGet/" + project_id)
export const getQuotationConProjectProfile = data =>
  get(url.GET_QUOTATIONCONPROJECT_PROFILE + "/" + data.id + "/" + data.type)
// add quotationconproject
export const addNewQuotationConProject = quotationconproject =>
  post(url.ADD_NEW_QUOTATIONCONPROJECT, quotationconproject)
// update quotationconproject
export const updateQuotationConProject = quotationconproject =>
  post(url.UPDATE_QUOTATIONCONPROJECT + "/onUpdate", quotationconproject)
// delete quotationconproject
export const deleteQuotationConProject = quotationconproject =>
  del(url.DELETE_QUOTATIONCONPROJECT + "/" + quotationconproject)

// get contactconproject
export const getContactConProjects = project_id =>
  get(url.GET_CONTACTCONPROJECTS + "/onGet/" + project_id)
export const getContactConProjectProfile = data =>
  get(url.GET_CONTACTCONPROJECT_PROFILE + "/" + data.id + "/" + data.type)
// add contactconproject
export const addNewContactConProject = contactconproject =>
  post(url.ADD_NEW_CONTACTCONPROJECT, contactconproject)
// update contactconproject
export const updateContactConProject = contactconproject =>
  post(url.UPDATE_CONTACTCONPROJECT + "/onUpdate", contactconproject)
// delete contactconproject
export const deleteContactConProject = contactconproject =>
  del(url.DELETE_CONTACTCONPROJECT + "/" + contactconproject)

// get employmentconproject
export const getEmploymentConProjects = project_id =>
  get(url.GET_EMPLOYMENTCONPROJECTS + "/onGet/" + project_id)
export const getEmploymentConProjectProfile = data =>
  get(url.GET_EMPLOYMENTCONPROJECT_PROFILE + "/" + data.id + "/" + data.type)
// add employmentconproject

export const addNewEmploymentConProject = employmentconproject =>
  post(url.ADD_NEW_EMPLOYMENTCONPROJECT, employmentconproject)
// update employmentconproject
export const updateEmploymentConProject = employmentconproject =>
  post(url.UPDATE_EMPLOYMENTCONPROJECT + "/onUpdate", employmentconproject)
// delete employmentconproject
export const deleteEmploymentConProject = employmentconproject =>
  del(url.DELETE_EMPLOYMENTCONPROJECT + "/" + employmentconproject)

// get profitlossproject
export const getProfitLossProjects = project_id =>
  get(url.GET_PROFITLOSSS + "/" + project_id)
export const getProfitLossProjectProfile = data =>
  get(url.GET_PROFITLOSS_PROFILE + "/" + data.id + "/" + data.type)
// add profitlossproject
export const addNewProfitLossProject = profitlossproject =>
  post(url.ADD_NEW_PROFITLOSS, profitlossproject)
// update profitlossproject
export const updateProfitLossProject = profitlossproject =>
  put(url.UPDATE_PROFITLOSS, profitlossproject)
// delete profitlossproject
export const deleteProfitLossProject = profitlossproject =>
  del(url.DELETE_PROFITLOSS + "/" + profitlossproject)

// get user groups
export const getUserGroups = () => get(url.GET_USERGROUPS)

// get contractors
export const getContractors = active =>
  get(url.GET_CONSTRUCTIONS + "/get/" + active)
export const getContractorProfile = id =>
  get(url.GET_CONTRACTOR_PROFILE + "/" + id)

// add contractors
export const addNewContractor = contractor =>
  post(url.ADD_NEW_CONTRACTOR, contractor)
// update contractor
export const updateContractor = contractor =>
  put(url.UPDATE_CONTRACTOR, contractor)
// delete contractor
export const deleteContractor = contractor =>
  del(url.DELETE_CONTRACTOR, { headers: { contractor } })

// get desigmhomes
export const getDesignHomes = type => get(url.GET_DESIGNHOMES + "/" + type)
// export const getDesignHomes = type => get(url.GET_DESIGNHOMES)

export const getDesignHomeProfile = id =>
  get(url.GET_DESIGNHOME_PROFILE + "/" + id)

// add desigmhomes
export const addNewDesignHome = desigmhome =>
  post(url.ADD_NEW_DESIGNHOME, desigmhome)
// update desigmhome
export const updateDesignHome = desigmhome =>
  put(url.UPDATE_DESIGNHOME, desigmhome)
// delete desigmhome
export const deleteDesignHome = desigmhome =>
  del(url.DELETE_DESIGNHOME, { headers: { desigmhome } })

export const changeFirstDesignHome = id =>
  get(url.CHANGEFIRST_DESIGNHOMEIMG + "/" + id)

export const delImgDesignHome = id => del(url.DELETE_DESIGNHOMEIMG + "/" + id)

export const uploadDesigmHomeImg = File => post(url.UPLOAD_DESIGNHOMEIMG, File)

export const getListStructures = data =>
  get(url.GET_LIST_STRUCTURES + "/get/" + data.active + "/" + data.type)
export const getListStructureProfile = id =>
  get(url.GET_LIST_STRUCTURE_PROFILE + "/" + id)
// add listStructures
export const addNewListStructure = listStructure =>
  post(url.ADD_NEW_LIST_STRUCTURE, listStructure)
// update listStructure
export const updateListStructure = listStructure =>
  put(url.UPDATE_LIST_STRUCTURE, listStructure)
// delete listStructure
export const deleteListStructure = listStructure =>
  del(url.DELETE_LIST_STRUCTURE, { headers: { listStructure } })

export const getListAluminias = data =>
  get(url.GET_LIST_AIUMINIAS + "/get/" + data.active + "/" + data.type)
export const getListAluminiaProfile = id =>
  get(url.GET_LIST_AIUMINIA_PROFILE + "/" + id)
// add listAluminias
export const addNewListAluminia = listAluminia =>
  post(url.ADD_NEW_LIST_AIUMINIA, listAluminia)
// update listAluminia
export const updateListAluminia = listAluminia =>
  put(url.UPDATE_LIST_AIUMINIA, listAluminia)
// delete listAluminia
export const deleteListAluminia = listAluminia =>
  del(url.DELETE_LIST_AIUMINIA, { headers: { listAluminia } })

export const getListAluminiaProjects = data =>
  get(url.GET_LIST_AIUMINIA_PROJECTS + "/get/" + data.active + "/" + data.type)
export const getListAluminiaProjectsProfile = id =>
  get(url.GET_LIST_AIUMINIA_PROJECT_PROFILE + "/" + id)
// add listAluminias
export const addNewListAluminiaProjects = listAluminia =>
  post(url.ADD_NEW_LIST_AIUMINIA_PROJECT, listAluminia)
// update listAluminia
export const updateListAluminiaProjects = listAluminia =>
  put(url.UPDATE_LIST_AIUMINIA_PROJECT, listAluminia)
// delete listAluminia
export const deleteListAluminiaProjects = listAluminia =>
  del(url.DELETE_LIST_AIUMINIA_PROJECT, { headers: { listAluminia } })

export const getBoqHomes = data =>
  get(url.GET_BOQ_HOMES + "/get/" + data.project_id + "/" + data.type)
export const getBoqHomeProfile = id => get(url.GET_BOQ_HOME_PROFILE + "/" + id)
// add boqHomes
export const addNewBoqHome = boqHome => post(url.ADD_NEW_BOQ_HOME, boqHome)
// update boqHome
export const updateBoqHome = boqHome => put(url.UPDATE_BOQ_HOME, boqHome)
// delete boqHome
export const deleteBoqHome = boqHome =>
  del(url.DELETE_BOQ_HOME, { headers: { boqHome } })

export const getBoqTitleHomes = data =>
  get(url.GET_BOQ_TITLE_HOMES + "/get/" + data.project_id + "/" + data.type)
export const getBoqTitleHomeProfile = id =>
  get(url.GET_BOQ_TITLE_HOME_PROFILE + "/" + id)
// add boqTitleHomes
export const addNewBoqTitleHome = boqTitleHome =>
  post(url.ADD_NEW_BOQ_TITLE_HOME, boqTitleHome)
// update boqTitleHome
export const updateBoqTitleHome = boqTitleHome =>
  put(url.UPDATE_BOQ_TITLE_HOME, boqTitleHome)
// delete boqTitleHome
export const deleteBoqTitleHome = boqTitleHome =>
  get(url.DELETE_BOQ_TITLE_HOME + "/destroy/" + boqTitleHome)

export const getListProducts = () => get(url.GET_LIST_PRODUCTS)
export const getListProductProfile = id =>
  get(url.GET_LIST_PRODUCT_PROFILE + "/" + id)
// add listProducts
export const addNewListProduct = listProduct =>
  post(url.ADD_NEW_LIST_PRODUCT, listProduct)
// update listProduct
export const updateListProduct = listProduct =>
  put(url.UPDATE_LIST_PRODUCT, listProduct)
// delete listProduct
export const deleteListProduct = listProduct =>
  del(url.DELETE_LIST_PRODUCT, { headers: { listProduct } })

export const getListDesignGroups = active =>
  get(url.GET_LIST_DESIGNGROUPS + "/get/" + active)
export const getListDesignGroupProfile = id =>
  get(url.GET_LIST_DESIGNGROUP_PROFILE + "/" + id)
// add listDesigngroups
export const addNewListDesignGroup = listDesigngroup =>
  post(url.ADD_NEW_LIST_DESIGNGROUP, listDesigngroup)
// update listDesigngroup
export const updateListDesignGroup = listDesigngroup =>
  put(url.UPDATE_LIST_DESIGNGROUP, listDesigngroup)
// delete listDesigngroup
export const deleteListDesignGroup = listDesigngroup =>
  del(url.DELETE_LIST_DESIGNGROUP, { headers: { listDesigngroup } })

export const getMaterialPrices = material_id =>
  get(url.GET_MATERIAL_PRICES + "/get/" + material_id)
export const getMaterialPriceProfile = id =>
  get(url.GET_MATERIAL_PRICE_PROFILE + "/" + id)
// add materialPrices
export const addNewMaterialPrice = materialPrice =>
  post(url.ADD_NEW_MATERIAL_PRICE, materialPrice)
// update materialPrice
export const updateMaterialPrice = materialPrice =>
  put(url.UPDATE_MATERIAL_PRICE, materialPrice)
// delete materialPrice
export const deleteMaterialPrice = materialPrice =>
  del(url.DELETE_MATERIAL_PRICE, { headers: { materialPrice } })

export const getMessageLists = id => get(url.GET_MESSAGE_LISTS)
export const getMessageListProfile = id =>
  get(url.GET_MESSAGE_LIST_PROFILE + "/" + id)
// add messageLists
export const addNewMessageList = messageList =>
  post(url.ADD_NEW_MESSAGE_LIST, messageList)
// update messageList
export const updateMessageList = messageList =>
  post(url.UPDATE_MESSAGE_LIST + "/update", messageList)
// delete messageList
export const deleteMessageList = messageList =>
  del(url.DELETE_MESSAGE_LIST, { headers: { messageList } })

export const getMaterialTypes = active =>
  get(url.GET_MATERIAL_TYPES + "/get/" + active)
export const getMaterialTypeProfile = id =>
  get(url.GET_MATERIAL_TYPE_PROFILE + "/" + id)
// add materialTypes
export const addNewMaterialType = materialType =>
  post(url.ADD_NEW_MATERIAL_TYPE, materialType)
// update materialType
export const updateMaterialType = materialType =>
  put(url.UPDATE_MATERIAL_TYPE, materialType)
// delete materialType
export const deleteMaterialType = materialType =>
  del(url.DELETE_MATERIAL_TYPE, { headers: { materialType } })

export const getProductGroups = active =>
  get(url.GET_PRODUCT_GROUPS + "/get/" + active)
export const getProductGroupProfile = id =>
  get(url.GET_PRODUCT_GROUP_PROFILE + "/" + id)
// add productGroups
export const addNewProductGroup = productGroup =>
  post(url.ADD_NEW_PRODUCT_GROUP, productGroup)
// update productGroup
export const updateProductGroup = productGroup =>
  put(url.UPDATE_PRODUCT_GROUP, productGroup)
// delete productGroup
export const deleteProductGroup = productGroup =>
  del(url.DELETE_PRODUCT_GROUP, { headers: { productGroup } })

export const getAttends = data => post(url.GET_ATTENDS + "/get", data)
export const getAttendProfile = id => get(url.GET_ATTEND_PROFILE + "/" + id)
// add attends
export const addNewAttend = attend => post(url.ADD_NEW_ATTEND, attend)
// update attend
export const updateAttend = attend => put(url.UPDATE_ATTEND, attend)
// delete attend
export const deleteAttend = attend =>
  del(url.DELETE_ATTEND, { headers: { attend } })

export const getBanks = active => get(url.GET_BANKS + "/get/" + active)
export const getBankProfile = id => get(url.GET_BANK_PROFILE + "/" + id)
// add banks
export const addNewBank = bank => post(url.ADD_NEW_BANK, bank)
// update bank
export const updateBank = bank => put(url.UPDATE_BANK, bank)
// delete bank
export const deleteBank = bank => del(url.DELETE_BANK, { headers: { bank } })

export const getMaterialShops = active =>
  get(url.GET_MATERIAL_SHOPS + "/get/" + active)
export const getMaterialShopProfile = id =>
  get(url.GET_MATERIAL_SHOP_PROFILE + "/" + id)
// add materialShops
export const addNewMaterialShop = materialShop =>
  post(url.ADD_NEW_MATERIAL_SHOP, materialShop)
// update materialShop
export const updateMaterialShop = materialShop =>
  put(url.UPDATE_MATERIAL_SHOP, materialShop)
// delete materialShop
export const deleteMaterialShop = materialShop =>
  del(url.DELETE_MATERIAL_SHOP, { headers: { materialShop } })

export const getMaterialStocks = data =>
  get(
    url.GET_MATERIAL_STOCKS +
      "/get/" +
      data.active +
      "/" +
      data.material_type_id
  )
export const getMaterialStockProfile = id =>
  get(url.GET_MATERIAL_STOCK_PROFILE + "/" + id)
// add materialTypes
export const addNewMaterialStock = materialType =>
  post(url.ADD_NEW_MATERIAL_STOCK, materialType)
// update materialType
export const updateMaterialStock = materialType =>
  put(url.UPDATE_MATERIAL_STOCK, materialType)
// delete materialType
export const deleteMaterialStock = materialType =>
  del(url.DELETE_MATERIAL_STOCK, { headers: { materialType } })

export const getWithdrawMaterials = data =>
  post(url.GET_WITHDRAW_MATERIALS + "/get", data)
export const getWithdrawMaterialProfile = id =>
  get(url.GET_WITHDRAW_MATERIAL_PROFILE + "/" + id)
// add datas
export const addNewWithdrawMaterial = data =>
  post(url.ADD_NEW_WITHDRAW_MATERIAL, data)
// update data
export const updateWithdrawMaterial = data =>
  put(url.UPDATE_WITHDRAW_MATERIAL, data)
// export const updateWithdrawMaterial = data =>
// console.log("updateWithdrawMaterial", data)

// delete data
export const deleteWithdrawMaterial = data =>
  del(url.DELETE_WITHDRAW_MATERIAL, { headers: { data } })

export const getCustomHomes = id => get(url.GET_CUSTOM_HOMES + "/" + id)
export const getCustomHomeProfile = id =>
  get(url.GET_CUSTOM_HOME_PROFILE + "/" + id)
// add customHomes
export const addNewCustomHome = customHome =>
  post(url.ADD_NEW_CUSTOM_HOME, customHome)
// update customHome
export const updateCustomHome = customHome =>
  put(url.UPDATE_CUSTOM_HOME, customHome)
// delete customHome
export const deleteCustomHome = customHome =>
  del(url.DELETE_CUSTOM_HOME, { headers: { customHome } })

export const getDesigns = id => get(url.GET_DESIGNS + "/" + id)
export const getDesignProfile = id => get(url.GET_DESIGN_PROFILE + "/" + id)
// add Designs
export const addNewDesign = Design => post(url.ADD_NEW_DESIGN, Design)
// update Design
export const updateDesign = Design => put(url.UPDATE_DESIGN, Design)
// delete Design
export const deleteDesign = Design => get(url.DELETE_DESIGN + "/" + Design)

export const getCustomProjects = id => get(url.GET_CUSTOM_PROJECTS + "/" + id)
export const getCustomProjectProfile = id =>
  get(url.GET_CUSTOM_PROJECT_PROFILE + "/" + id)
// add CustomProjects
export const addNewCustomProject = CustomProject =>
  post(url.ADD_NEW_CUSTOM_PROJECT, CustomProject)
// update CustomProject
export const updateCustomProject = CustomProject =>
  put(url.UPDATE_CUSTOM_PROJECT, CustomProject)
// delete CustomProject
export const deleteCustomProject = CustomProject =>
  get(url.DELETE_CUSTOM_PROJECT + "/" + CustomProject)``

export const getCommentHomes = id => get(url.GET_COMMENT_HOMES + "/" + id)
export const getCommentHomeProfile = id =>
  get(url.GET_COMMENT_HOME_PROFILE + "/" + id)
// add CommentHomes
export const addNewCommentHome = CommentHome =>
  post(url.ADD_NEW_COMMENT_HOME, CommentHome)
// update CommentHome
export const updateCommentHome = CommentHome =>
  put(url.UPDATE_COMMENT_HOME, CommentHome)
// delete CommentHome
export const deleteCommentHome = CommentHome =>
  del(url.DELETE_COMMENT_HOME, { headers: { CommentHome } })

export const notiLineCommentHome = commentHomes =>
  post(url.NOTI_COMMENT_HOMES, commentHomes)

export const getListQcs = active => get(url.GET_LIST_QCS + "/get/" + active)
export const getListQcProfile = id => get(url.GET_LIST_QC_PROFILE + "/" + id)
// add listQcs
export const addNewListQc = listQc => post(url.ADD_NEW_LIST_QC, listQc)
// update listQc
export const updateListQc = listQc => put(url.UPDATE_LIST_QC, listQc)
// delete listQc
export const deleteListQc = listQc =>
  del(url.DELETE_LIST_QC, { headers: { listQc } })

export const getBoqs = data =>
  get(url.GET_BOQS + "/get/" + data.type + "/" + data.boq_title_detail_id)
export const getBoqProfile = id => get(url.GET_BOQ_PROFILE + "/" + id)
// add boqs
export const addNewBoq = boq => post(url.ADD_NEW_BOQ, boq)
export const confirmBuyMaterial = data =>
  post(url.ADD_NEW_BOQ + "/confirm_buy_material", data)

export const uploadImgCheckBoq = boq => post(url.UPLOAD_IMG_CHECK_BOQ, boq)

// update boq
export const updateBoq = boq => put(url.UPDATE_BOQ, boq)
// delete boq
export const deleteBoq = boq => del(url.DELETE_BOQ, { headers: { boq } })

/** PROJECT */

// add project
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update project
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete project
export const deleteProject = project => del(url.DELETE_PROJECT + "/" + project)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtLogout,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
}
